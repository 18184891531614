import i18next from 'i18next';

import { CertificateRenewalState } from '../common/model/enumerations/certificateRenewalState.model';
import { getKeyByValue } from './enum.utils';

export const getLocalizedText = (i18n: i18next.i18n, key: string): string => {
  return i18n.t(`enums.certificateRenewalState.${key}`);
};

export const getLocalizedTextByValue = (i18n: i18next.i18n, renewalState: number): string => {
  const key = getKeyByValue(CertificateRenewalState, renewalState);
  if (!key) {
    return '';
  }
  return getLocalizedText(i18n, key);
};

export const getIconCss = (renewalState: number): string => {
  switch (renewalState) {
    case CertificateRenewalState.ALL:
      return 'icon icon-app-item-list';
    case CertificateRenewalState.PENDING:
      return 'icon icon-none';
    case CertificateRenewalState.REQUESTED:
      return 'icon icon-report-action-advance';
    case CertificateRenewalState.CANCELLED:
      return 'icon icon-app-item-cancel';
    default:
      return '';
  }
};
