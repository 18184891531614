import i18next from 'i18next';

import { RequestChangeHistoryType } from '../common/model/enumerations/requestChangeHistoryType.model';
import { getKeyByValue } from './enum.utils';

export const getLocalizedText = (i18n: i18next.i18n, key: string): string => {
  return i18n.t(`enums.requestChangeHistoryType.${key}`);
};

export const getLocalizedTextByValue = (i18n: i18next.i18n, type: number): string => {
  const key = getKeyByValue(RequestChangeHistoryType, type);
  if (!key) {
    return '';
  }
  return getLocalizedText(i18n, key);
};
