import { Action, Reducer, ActionCreator, combineReducers } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { DataManager, Query } from '@syncfusion/ej2-data';
import moment from 'moment';

import { RootState } from '..';
import * as notificationsListStore from './notificationsList.store';
import * as listGridStore from './listGrid.store';
import { INotificationsListNotificationsViewStatusState } from '../../interfaces/states/INotificationsListState';
import { INotificationUser } from '../../common/model/notification.model';
import { IODataService } from '../../services';

enum Type {
  SET_NOTIFICATION_USERS = '@@notificationsListNotificationsViewStatus/SET_NOTIFICATION_USERS'
}

const initialState: INotificationsListNotificationsViewStatusState = {
  notificationUsers: []
};

// Actions

export type Actions = notificationsListStore.ResetAction | notificationsListStore.SetFilterAction | notificationsListStore.SetFilterSubjectAction | notificationsListStore.SetFilterScopeAction
  | notificationsListStore.SetFilterActiveAction | notificationsListStore.SetFilterCompaniesIdsAction | notificationsListStore.SetFilterEmailAction | listGridStore.SetIsFetchingAction
  | listGridStore.SetSearchDateAction | listGridStore.SetCurrentPageAction | listGridStore.SetPageSizeAction | listGridStore.SetTotalCountAction | listGridStore.SetSortingAction
  | SetNotificationUsersAction;

export interface SetNotificationUsersAction extends Action<Type.SET_NOTIFICATION_USERS> {
  notificationUsers: INotificationUser[];
}

export const setNotificationUsers: ActionCreator<SetNotificationUsersAction> = (notificationUsersLocal: INotificationUser[]) => ({
  type: Type.SET_NOTIFICATION_USERS,
  notificationUsers: notificationUsersLocal
});

export const fetchNotificationUsers: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (
  oDataService: IODataService,
  notificationUsersDataManager: DataManager,
  notificationUsersQuery: Query
) => (
    async (dispatch, getState) => {
      dispatch(listGridStore.setIsFetching(notificationsListStore.NotificationsViewStatusListType.SET_IS_FETCHING, true));

      const currentPageLocal = getState().notificationsViewStatusListStore.grid.currentPage;
      const pageSizeLocal = getState().notificationsViewStatusListStore.grid.pageSize;
      const sortFieldLocal = getState().notificationsViewStatusListStore.grid.sortField;
      const sortDirectionLocal = getState().notificationsViewStatusListStore.grid.sortDirection;

      let query = notificationUsersQuery.page(currentPageLocal, pageSizeLocal);
      if (sortFieldLocal && sortDirectionLocal) {
        query = sortDirectionLocal === 'Ascending' ? query.sortBy(sortFieldLocal) : query.sortByDesc(sortFieldLocal);
      }
      const notificationUsersResponse = await oDataService.executeQueryWithCount<INotificationUser>(notificationUsersDataManager, query);
      const notificationUsersLocal = notificationUsersResponse.result.map((notificationUser) => ({
        ...notificationUser,
        createdDate: moment(notificationUser.createdDate),
        viewDate: moment(notificationUser.viewDate)
      }));
      dispatch(setNotificationUsers(notificationUsersLocal));
      dispatch(listGridStore.setSearchDate(notificationsListStore.NotificationsViewStatusListType.SET_SEARCH_DATE, moment()));
      dispatch(listGridStore.setTotalCount(notificationsListStore.NotificationsViewStatusListType.SET_TOTAL_COUNT, notificationUsersResponse.count));
      dispatch(listGridStore.setIsFetching(notificationsListStore.NotificationsViewStatusListType.SET_IS_FETCHING, false));
    }
  );

// Reducers

const notificationUsers: Reducer<INotificationUser[]> = (state = initialState.notificationUsers, action) => {
  switch (action.type) {
    case notificationsListStore.NotificationsViewStatusListType.RESET:
      return state;
    case Type.SET_NOTIFICATION_USERS:
      return (action as SetNotificationUsersAction).notificationUsers;
    default:
      return state;
  }
};

export const notificationsListNotificationsViewStatusStore = combineReducers({ notificationUsers });
