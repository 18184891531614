import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { IClientsListFilterProps } from '../../../interfaces/props/IClientsListProps';
import TextBox from '../../../components/filters/textBox';

type ClientsListFilterPropsType = IClientsListFilterProps & WithTranslation;

class ClientsListFilter extends Component<ClientsListFilterPropsType> {
  public render() {
    const {
      filter,
      isFetching,
      onCodeChange,
      onNameChange,
      onFilterButtonClick,
      i18n
    } = this.props;

    return (
      <form onSubmit={(e: React.FormEvent) => { e.preventDefault(); onFilterButtonClick(); }}>
        <div className='row'>
          <div className='col-12 col-md-6'>
            <div className='form-group'>
              <div className='autocomplete-input'>
                <div className='autocomplete-label'>{i18n.t('company.code')}</div>
                <TextBox
                  name='code'
                  value={filter.code}
                  showClearButton={true}
                  onChange={(value) => onCodeChange(value)}
                />
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <div className='form-group'>
              <div className='autocomplete-input'>
                <div className='autocomplete-label'>{i18n.t('company.name')}</div>
                <TextBox
                  name='name'
                  value={filter.name}
                  showClearButton={true}
                  onChange={(value) => onNameChange(value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='row justify-content-end'>
          <div className='col-auto'>
            <button type='submit' className='btn btn-secondary' title={i18n.t('actions.filter')} disabled={isFetching}>
              {isFetching ?
                <span className='spinner-border spinner-border-sm' />
                :
                <span className='icon icon-app-item-search' />
              }
            </button>
          </div>
        </div>
      </form>
    );
  }
}

export default withTranslation()(ClientsListFilter);
