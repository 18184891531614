import React, { Component, ComponentType } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { compose } from 'redux';
import { DataManager, Query } from '@syncfusion/ej2-data';

import { RootState } from '../../store';
import { IMeasurementUnitsListContainerStateProps, IMeasurementUnitsListContainerDispatchProps } from '../../interfaces/props/IMeasurementUnitsListProps';
import * as measurementUnitsListStore from '../../store/modules/measurementUnitsList.store';
import { URL } from '../../common/constants';
import { IMeasurementUnitEnglishSpanish } from '../../common/model/measurementUnit.model';
import { measurementUnitsService, oDataService, dataManagerService } from '../../services';
import { notifyError } from '../../utils/toast.utils';
import MeasurementUnitsGrid from './measurementUnitsGrid/measurementUnitsGrid';

type MeasurementUnitsListContainerPropsType = PropsFromRedux & RouteComponentProps & WithTranslation;

class MeasurementUnitsListContainer extends Component<MeasurementUnitsListContainerPropsType> {
  public measurementUnitsDataManager: DataManager = null;
  public measurementUnitsQuery = new Query()
    .select(['id', 'code', 'name', 'active'])
    .expand('translations');

  public constructor(props: MeasurementUnitsListContainerPropsType) {
    super(props);

    const { reset } = this.props;

    this.measurementUnitsDataManager = dataManagerService.buildDataManager(URL.ODATA.MEASUREMENT_UNIT_FILTERS);

    reset();
  }

  public componentDidMount() {
    setTimeout(() => this.props.fetchMeasurementUnits(oDataService, this.measurementUnitsDataManager, this.measurementUnitsQuery));
  }

  public componentDidUpdate(prevProps: MeasurementUnitsListContainerPropsType) {
    if (!Object.keys(prevProps.errors).length && Object.keys(this.props.errors).length) {
      if (this.props.errors.ControlledError) {
        notifyError(this.props.errors.ControlledError[0]);
      }
      if (this.props.errors.UnknownError || (!this.props.errors.UnknownError && !this.props.errors.ControlledError)) {
        notifyError(this.props.i18n.t('measurementUnitsList.error'));
      }
    }
  }

  public onMeasurementUnitSave(measurementUnit: IMeasurementUnitEnglishSpanish) {
    this.props.saveMeasurementUnit(measurementUnit, measurementUnitsService, oDataService, this.measurementUnitsDataManager, this.measurementUnitsQuery);
  }

  public render() {
    const { measurementUnits, isFetching, i18n } = this.props;

    return (
      <div>
        <div className='row'>
          <div className='col'>
            <h2>{i18n.t('measurementUnitsList.title')}</h2>
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <MeasurementUnitsGrid
              measurementUnits={measurementUnits}
              isFetching={isFetching}
              onMeasurementUnitSave={(measurementUnit) => this.onMeasurementUnitSave(measurementUnit)}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMeasurementUnitsListContainerStateProps => ({
  measurementUnits: state.measurementUnitsListStore.measurementUnits,
  isFetching: state.measurementUnitsListStore.isFetching,
  errors: state.measurementUnitsListStore.errors
});

const mapDispatchToProps: IMeasurementUnitsListContainerDispatchProps = {
  reset: measurementUnitsListStore.reset,
  fetchMeasurementUnits: measurementUnitsListStore.fetchMeasurementUnits,
  saveMeasurementUnit: measurementUnitsListStore.saveMeasurementUnit
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default compose(
  withRouter,
  withTranslation(),
  connector
)(MeasurementUnitsListContainer) as ComponentType;
