import i18next from 'i18next';

import { ReportChangeHistoryType } from '../common/model/enumerations/reportChangeHistoryType.model';
import { getKeyByValue } from './enum.utils';

export const getLocalizedText = (i18n: i18next.i18n, key: string): string => {
  return i18n.t(`enums.reportChangeHistoryType.${key}`);
};

export const getLocalizedTextByValue = (i18n: i18next.i18n, type: number): string => {
  const key = getKeyByValue(ReportChangeHistoryType, type);
  if (!key) {
    return '';
  }
  return getLocalizedText(i18n, key);
};
