import React from 'react';
import { TextBoxComponent, ChangedEventArgs, FocusOutEventArgs } from '@syncfusion/ej2-react-inputs';

import { Filter } from './filter';

interface ITextBoxFilterProps {
  id?: string;
  enabled?: boolean;
  placeholder?: string;
  readonly?: boolean;
  showClearButton?: boolean;
  type?: string;
  value?: string;
  onBlur?: (value: string) => void;
  onChange?: (value: string) => void;
}

class TextBox extends Filter<ITextBoxFilterProps, unknown> {
  private textBoxComponent: any;

  public onBlur(e: FocusOutEventArgs): void {
    const isEmpty = this.manageClearButton(e);

    const value = isEmpty ? '' : (e.value ? e.value : '');
    if (this.props.onBlur) {
      this.props.onBlur(value);
    }
  }

  public onChange(e: ChangedEventArgs): void {
    const isEmpty = this.manageClearButton(e);

    const value = isEmpty ? '' : (e.value ? e.value : '');
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  }

  public render(): JSX.Element {
    const readonly = this.props.readonly || false;

    return (
      <TextBoxComponent
        id={this.props.id}
        name={this.props.name}
        ref={(scope: any) => { this.textBoxComponent = scope; }}
        blur={(e) => this.onBlur(e)}
        input={(e) => this.onChange(e)}
        change={(e) => this.onChange(e)}
        enabled={this.props.enabled || true}
        placeholder={this.props.placeholder || ''}
        readOnly={readonly}
        showClearButton={this.props.showClearButton || !readonly}
        type={this.props.type || 'text'}
        value={this.props.value || ''}
        htmlAttributes={{ name: this.props.name }}
      />
    );
  }

  private manageClearButton(e: any): boolean {
    let isEmpty = !e.value;
    if (this.textBoxComponent.showClearButton) {
      this.textBoxComponent.textboxWrapper.container.querySelectorAll('.e-clear-icon')[0].style.display = 'flex';

      const inputValue = e.container && e.container.firstChild ? e.container.firstChild.value : '';
      if (e.value === undefined || e.value == null || e.value === '' || inputValue === '') {
        this.textBoxComponent.textboxWrapper.container.querySelectorAll('.e-clear-icon')[0].style.display = 'none';

        isEmpty = true;
      }
    }

    return isEmpty;
  }
}

export default TextBox;
