import React, { Component, Fragment } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { IFormErrorProps } from '../../interfaces/props/IFormErrorProps';

type FormErrorPropsType = IFormErrorProps & WithTranslation;

class FormError extends Component<FormErrorPropsType> {
  public render() {
    const { errors, i18n } = this.props;

    return (
      <Fragment>
        {(errors['400'] || errors['500']) ?
          <div className='row mb-3'>
            <div className='col'>
              <div>
                <strong className='form-text text-danger'>
                  <span>{i18n.t(`apiErrors.${errors['400'] ? '400' : '500'}`)}</span>
                </strong>
              </div>
              {errors.ControlledError && <div>
                <span className='form-text text-danger'>{errors.ControlledError[0]}</span>
              </div>}
            </div>
          </div> : null}
      </Fragment>
    );
  }
}

export default withTranslation()(FormError);
