import { Action, Reducer, ActionCreator, combineReducers } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { batch } from 'react-redux';
import { DataManager, Query } from '@syncfusion/ej2-data';
import moment from 'moment';

import { RootState } from '..';
import * as listGridStore from './listGrid.store';
import { IEmailsNotReceivedListState, IEmailsNotReceivedListFilterState } from '../../interfaces/states/IEmailsNotReceivedListState';
import { IEmailNotReceived } from '../../common/model/emailNotReceived.model';
import { IODataService } from '../../services';

export enum Type {
  RESET = '@@emailsNotReceivedList/RESET',
  RESET_HARD = '@@emailsNotReceivedList/RESET_HARD',
  SET_EMAILS_NOT_RECEIVED = '@@emailsNotReceivedList/SET_EMAILS_NOT_RECEIVED',
  SET_MODE = '@@emailsNotReceivedList/SET_MODE',
  SET_IS_CHANGING_MODE = '@@emailsNotReceivedList/SET_IS_CHANGING_MODE',
  SET_FILTER = '@@emailsNotReceivedList/SET_FILTER',
  SET_FILTER_ACTIVE = '@@emailsNotReceivedList/SET_FILTER_ACTIVE',
  SET_FILTER_EMAIL = '@@emailsNotReceivedList/SET_FILTER_EMAIL',
  SET_FILTER_COMPANY_ID = '@@emailsNotReceivedList/SET_FILTER_COMPANY_ID',
  SET_FILTER_RESULT_ID = '@@emailsNotReceivedList/SET_FILTER_RESULT_ID',
  SET_IS_FETCHING = '@@emailsNotReceivedList/SET_IS_FETCHING',
  SET_SEARCH_DATE = '@@emailsNotReceivedList/SET_SEARCH_DATE',
  SET_CURRENT_PAGE = '@@emailsNotReceivedList/SET_CURRENT_PAGE',
  SET_PAGE_SIZE = '@@emailsNotReceivedList/SET_PAGE_SIZE',
  SET_TOTAL_COUNT = '@@emailsNotReceivedList/SET_TOTAL_COUNT',
  SET_SORTING = '@@emailsNotReceivedList/SET_SORTING',
  SET_EMAIL_NOT_RECEIVED = '@@emailsNotReceivedList/SET_EMAIL_NOT_RECEIVED'
}

const initialState: IEmailsNotReceivedListState = {
  emailsNotReceived: [],
  mode: null,
  isChangingMode: true,
  filter: {
    active: true,
    email: null,
    companyId: null,
    resultId: null
  },
  grid: listGridStore.initialState
};


/////////////
// Actions //
/////////////

export type Actions = ResetAction | ResetHardAction | SetEmailsNotReceivedAction | SetFilterAction | SetFilterActiveAction | SetFilterEmailAction
  | SetFilterCompanyIdAction | SetFilterResultIdAction | listGridStore.SetIsFetchingAction
  | listGridStore.SetSearchDateAction | listGridStore.SetCurrentPageAction | listGridStore.SetPageSizeAction | listGridStore.SetTotalCountAction | listGridStore.SetSortingAction
  | SetEmailNotReceivedAction;

export type ResetAction = Action<Type.RESET>

export type ResetHardAction = Action<Type.RESET_HARD>

export interface SetEmailsNotReceivedAction extends Action<Type.SET_EMAILS_NOT_RECEIVED> {
  emailsNotReceived: IEmailNotReceived[];
}

export interface SetFilterAction extends Action<Type.SET_FILTER> {
  filter: IEmailsNotReceivedListFilterState;
}

export interface SetFilterActiveAction extends Action<Type.SET_FILTER_ACTIVE> {
  active?: boolean;
}

export interface SetFilterEmailAction extends Action<Type.SET_FILTER_EMAIL> {
  email: string;
}

export interface SetFilterCompanyIdAction extends Action<Type.SET_FILTER_COMPANY_ID> {
  companyId: string;
}

export interface SetFilterResultIdAction extends Action<Type.SET_FILTER_RESULT_ID> {
  resultId: string;
}

export interface SetEmailNotReceivedAction extends Action<Type.SET_EMAIL_NOT_RECEIVED> {
  emailNotReceived: IEmailNotReceived;
}

export const reset: ActionCreator<ResetAction> = () => ({
  type: Type.RESET
});

export const resetHard: ActionCreator<ResetHardAction> = () => ({
  type: Type.RESET_HARD
});

export const setEmailsNotReceived: ActionCreator<SetEmailsNotReceivedAction> = (emailsNotReceivedLocal: IEmailNotReceived[]) => ({
  type: Type.SET_EMAILS_NOT_RECEIVED,
  emailsNotReceived: emailsNotReceivedLocal
});

export const setFilter: ActionCreator<SetFilterAction> = (filterLocal: IEmailsNotReceivedListFilterState) => ({
  type: Type.SET_FILTER,
  filter: filterLocal
});

export const setFilterActive: ActionCreator<SetFilterActiveAction> = (active?: boolean) => ({
  type: Type.SET_FILTER_ACTIVE,
  active
});

export const setFilterEmail: ActionCreator<SetFilterEmailAction> = (email: string) => ({
  type: Type.SET_FILTER_EMAIL,
  email
});

export const setFilterCompanyId: ActionCreator<SetFilterCompanyIdAction> = (companyId: string) => ({
  type: Type.SET_FILTER_COMPANY_ID,
  companyId
});

export const setFilterResultId: ActionCreator<SetFilterResultIdAction> = (resultId: string) => ({
  type: Type.SET_FILTER_RESULT_ID,
  resultId
});

export const fetchEmailsNotReceived: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (
  oDataService: IODataService,
  emailsNotReceivedDataManager: DataManager,
  emailsNotReceivedQuery: Query
) => (
    async (dispatch, getState) => {
      dispatch(listGridStore.setIsFetching(Type.SET_IS_FETCHING, true));

      const currentPageLocal = getState().emailsNotReceivedListStore.grid.currentPage;
      const pageSizeLocal = getState().emailsNotReceivedListStore.grid.pageSize;
      const sortFieldLocal = getState().emailsNotReceivedListStore.grid.sortField;
      const sortDirectionLocal = getState().emailsNotReceivedListStore.grid.sortDirection;

      let query = emailsNotReceivedQuery.page(currentPageLocal, pageSizeLocal);
      if (sortFieldLocal && sortDirectionLocal) {
        query = sortDirectionLocal === 'Ascending' ? query.sortBy(sortFieldLocal) : query.sortByDesc(sortFieldLocal);
      }
      const emailsNotReceivedResponse = await oDataService.executeQueryWithCount<IEmailNotReceived>(emailsNotReceivedDataManager, query);
      const emailsNotReceivedLocal = emailsNotReceivedResponse.result.map((emailNotReceived) => emailNotReceived);
      batch(() => {
        dispatch(setEmailsNotReceived(emailsNotReceivedLocal));
        dispatch(listGridStore.setSearchDate(Type.SET_SEARCH_DATE, moment()));
        dispatch(listGridStore.setTotalCount(Type.SET_TOTAL_COUNT, emailsNotReceivedResponse.count));
      });
      dispatch(listGridStore.setIsFetching(Type.SET_IS_FETCHING, false));
    }
);

export const setEmailNotReceived: ActionCreator<SetEmailNotReceivedAction> = (emailNotReceivedLocal: IEmailNotReceived) => ({
  type: Type.SET_EMAIL_NOT_RECEIVED,
  emailNotReceived: emailNotReceivedLocal
});

export const saveEmailNotReceived: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (
  emailNotReceived: IEmailNotReceived
) => (
  async (dispatch) => {
    dispatch(listGridStore.setIsFetching(Type.SET_IS_FETCHING, true));

    dispatch(setEmailNotReceived(emailNotReceived));

    dispatch(listGridStore.setIsFetching(Type.SET_IS_FETCHING, false));
  }
);

//////////////
// Reducers //
//////////////

const emailsNotReceived: Reducer<IEmailNotReceived[]> = (state = initialState.emailsNotReceived, action) => {
  switch (action.type) {
    case Type.RESET:
      return state;
    case Type.SET_MODE:
      return initialState.emailsNotReceived;
    case Type.SET_EMAILS_NOT_RECEIVED:
      return (action as SetEmailsNotReceivedAction).emailsNotReceived;
    case Type.SET_EMAIL_NOT_RECEIVED: {
      const emailNotReceived = (action as SetEmailNotReceivedAction).emailNotReceived;
      let emailsNotReceived = state.filter(value => (value as any).emailsNotReceived === undefined || (value as any).emailNotReceived == null);
      if (emailsNotReceived.some(u => u.id === emailNotReceived.id)) {
        const newEmailsNotReceived = emailsNotReceived.map((item: IEmailNotReceived) => {
          if (item.id == emailNotReceived.id) {
            return emailNotReceived;
          } else {
            return item;
          }
        });

        emailsNotReceived = newEmailsNotReceived;
      } else {
        emailsNotReceived.unshift(emailNotReceived);
      }

      return emailsNotReceived;
    }
    default:
      return state;
  }
};

const mode: Reducer<string> = (state = initialState.mode, action) => {
  switch (action.type) {
    case Type.RESET:
      return state;
    case Type.RESET_HARD:
      return initialState.mode;
    default:
      return state;
  }
};

const isChangingMode: Reducer<boolean> = (state = initialState.isChangingMode, action) => {
  switch (action.type) {
    case Type.RESET:
    case Type.RESET_HARD:
      return initialState.isChangingMode;
    case Type.SET_MODE:
      return false;
    default:
      return state;
  }
}

const filter: Reducer<IEmailsNotReceivedListFilterState> = (state = initialState.filter, action) => {
  switch (action.type) {
    case Type.RESET:
      return state;
    case Type.SET_FILTER:
      return (action as SetFilterAction).filter;
    case Type.SET_FILTER_ACTIVE:
      return { ...state, active: (action as SetFilterActiveAction).active };
    case Type.SET_FILTER_EMAIL:
      return { ...state, email: (action as SetFilterEmailAction).email };
    case Type.SET_FILTER_COMPANY_ID:
      return { ...state, companyId: (action as SetFilterCompanyIdAction).companyId };
    case Type.SET_FILTER_RESULT_ID:
      return { ...state, resultId: (action as SetFilterResultIdAction).resultId };
    default:
      return state;
  }
};

export const emailsNotReceivedListStore = combineReducers({
  emailsNotReceived,
  mode,
  isChangingMode,
  filter,
  grid: listGridStore.createListGridStore(Type, 'email', 'Ascending')
});
