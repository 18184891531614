import i18next from 'i18next';

import { ReportStatus } from '../common/model/enumerations/reportStatus.model';

export const getLocalizedText = (i18n: i18next.i18n, status: string): string => {
  return i18n.t(`enums.reportStatus.${status}`);
};

export const getIconCss = (status: string): string => {
  switch (status) {
    case ReportStatus.ALL:
      return 'icon icon-app-item-list';
    case ReportStatus.IN_PROGRESS:
      return 'icon icon-state-in-progress';
    case ReportStatus.FINALIZED:
      return 'icon icon-report-state-finalized';
    case ReportStatus.CANCELLED:
      return 'icon icon-app-item-cancel';
    default:
      return '';
  }
};
