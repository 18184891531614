import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { DropDownListComponent, ChangeEventArgs, FieldSettingsModel } from '@syncfusion/ej2-react-dropdowns';
import { DialogComponent } from '@aitex/tsx-extranet-dialogs';

import { IEditAnnexStateDialogProps } from '../../../interfaces/props/IDialogsProps';
import { IAnnex } from '../../../common/model/annex.model';
import { IErrors } from '../../../common/model/errors.model';
import { IStringToAnyDictionary } from '../../../common/model/stringToAnyDictionary.model';
import { AnnexedState } from '../../../common/model/enumerations/annexedState.model';
import { annexesService } from '../../../services';
import * as dialogUtils from '../../../utils/dialog.utils';
import * as annexedStateUtils from '../../../utils/annexedState.utils';
import { enumToArray } from '../../../utils/enum.utils';

type EditAnnexStateDialogPropsType = IEditAnnexStateDialogProps & WithTranslation;

type EditAnnexStateDialogStateType = IEditAnnexStateDialogState;

class EditAnnexStateDialog extends Component<EditAnnexStateDialogPropsType, EditAnnexStateDialogStateType> {
  public annexedStatesOptions: IStringToAnyDictionary[] = [];
  public annexedStatesFields: FieldSettingsModel = { text: 'text', value: 'value' };

  private readonly CANCEL_BUTTON_ID = 'editAnnexStateDialogCancel';
  private readonly OK_BUTTON_ID = 'editAnnexStateDialogOk';

  private readonly CANNOT_EDIT_ANNEX_STATE_ERROR: IErrors = { UnknownError: ['dialogs.editAnnexStateDialog.error'] };

  private initialState: IEditAnnexStateDialogState = {
    annex: null,
    isLoading: false
  };

  public constructor(props: EditAnnexStateDialogPropsType) {
    super(props);

    this.annexedStatesOptions = enumToArray(AnnexedState, 'number', 'text', 'value')
      .map((item) => ({ ...item, text: annexedStateUtils.getLocalizedText(this.props.i18n, item.text as string) }));

    this.state = this.initialState;
  }

  public componentDidUpdate(prevProps: EditAnnexStateDialogPropsType) {
    const { visible, annex } = this.props;

    if (!prevProps.visible && visible) {
      this.setState({ annex: { ...annex, state: typeof annex.state === 'number' ? annex.state : AnnexedState.PENDING, observations: annex.observations || null } });
    }
    if (prevProps.visible && !visible) {
      this.setState(this.initialState);
    }

    dialogUtils.manageButtonsClick(this.OK_BUTTON_ID, () => this.updateAnnex(), this.CANCEL_BUTTON_ID, () => this.dismiss());
  }

  public async updateAnnex() {
    try {
      this.setState({ isLoading: true });

      if (!this.state.annex) {
        throw this.CANNOT_EDIT_ANNEX_STATE_ERROR;
      }

      await annexesService.updateState(this.state.annex.id, this.state.annex.state, this.state.annex.observations);
      this.close();
    } catch (error) {
      let errors = error as IErrors;
      if (errors.UnknownError || (!errors.UnknownError && !errors.ControlledError)) {
        errors = this.CANNOT_EDIT_ANNEX_STATE_ERROR;
      }
      this.dismiss(errors);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  public close() {
    this.props.onClose();
  }

  public dismiss(reason?: IErrors) {
    this.props.onDismiss(reason);
  }

  public onStateChange(e: ChangeEventArgs) {
    const state = e.value as number;
    this.setState({ annex: { ...this.state.annex, state } });
  }

  public onObservationsChange(observations: string) {
    this.setState({ annex: { ...this.state.annex, observations } });
  }

  public render() {
    const { visible, i18n } = this.props;

    return (
      <DialogComponent
        header={i18n.t('dialogs.editAnnexStateDialog.title')}
        visible={visible}
        width='480px'
        footerTemplate={dialogUtils.computeFooterTemplate(
          this.OK_BUTTON_ID,
          i18n.t('actions.ok'),
          this.CANCEL_BUTTON_ID,
          i18n.t('actions.cancel'),
          this.state.isLoading
        )}
        onDismiss={() => this.dismiss()}
      >
        <div>
          {this.state.annex && <form>
            <div className='row'>
              <div className='col'>
                <div className='form-group'>
                  <div className='autocomplete-input'>
                    <div className='autocomplete-label'>{i18n.t('annex.state')}</div>
                    <DropDownListComponent
                      dataSource={this.annexedStatesOptions}
                      fields={this.annexedStatesFields}
                      name='State'
                      value={this.state.annex.state}
                      change={(e) => this.onStateChange(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col'>
                <div className='form-group'>
                  <label htmlFor='observations'>{i18n.t('annex.observations')}</label>
                  <textarea
                    className='form-control'
                    id='observations'
                    rows={5}
                    value={this.state.annex.observations}
                    onChange={(e) => this.onObservationsChange(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </form>}
        </div>
      </DialogComponent>
    );
  }
}

interface IEditAnnexStateDialogState {
  annex: IAnnex;
  isLoading: boolean;
}

export default withTranslation()(EditAnnexStateDialog);
