import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, Sort, Page, Toolbar, Resize, PageSettingsModel } from '@syncfusion/ej2-react-grids';

import { IRequestChangesHistoryTabProps } from '../../../../interfaces/props/IRequestDetailProps';
import { IRequestChangeHistory } from '../../../../common/model/requestChangeHistory.model';
import * as pagerUtils from '../../../../utils/pager.utils';
import * as requestChangeHistoryUtils from '../../../../utils/requestChangeHistoryType.utils';
import * as dateHelpers from '../../../../helpers/date.helpers';
import './requestChangesHistoryTab.scss';

type RequestChangesHistoryTabPropsType = IRequestChangesHistoryTabProps & WithTranslation;

class RequestChangesHistoryTab extends Component<RequestChangesHistoryTabPropsType> {
  public pageSettings: PageSettingsModel = {
    pageSize: pagerUtils.getMaxPageSize(),
    pageCount: pagerUtils.getPageCount(),
    pageSizes: pagerUtils.getPageSizes()
  };

  public constructor(props: RequestChangesHistoryTabPropsType) {
    super(props);

  }

  public getCreatedDateLabel(requestChangeHistory: IRequestChangeHistory): string {
    return `${dateHelpers.toShortDate(requestChangeHistory.createdDate, true)} ${dateHelpers.toDateWithoutZones(requestChangeHistory.createdDate, 'HH:mm:ss')}`;
  }

  public getUserColumnLabel(requestChangeHistory: IRequestChangeHistory): string {
    return `${requestChangeHistory.userFirstName} ${requestChangeHistory.userLastName}`;
  }

  public getTypeColumnLabel(requestChangeHistory: IRequestChangeHistory): string {
    return requestChangeHistoryUtils.getLocalizedTextByValue(this.props.i18n, requestChangeHistory.type);
  }

  public render() {
    const { requestChangesHistory, i18n } = this.props;

    return (
      <div className='request-changes-history-grid'>
        <GridComponent
          dataSource={requestChangesHistory}
          allowSorting={true}
          allowPaging={true}
          allowResizing={true}
          pageSettings={this.pageSettings}
          clipMode='EllipsisWithTooltip'
          locale={i18n.language}
        >
          <ColumnsDirective>
            <ColumnDirective
              headerText={this.props.i18n.t('requestChangeHistory.createdDate')}
              field='createdDate'
              width='100'
              valueAccessor={(_field, data) => this.getCreatedDateLabel(data as IRequestChangeHistory)}
            />
            <ColumnDirective
              headerText={i18n.t('requestChangeHistory.user')}
              field='userFirstName'
              width='100'
              valueAccessor={(_field, data) => this.getUserColumnLabel(data as IRequestChangeHistory)}
            />
            <ColumnDirective
              headerText={i18n.t('requestChangeHistory.type')}
              field='type'
              width='100'
              valueAccessor={(_field, data) => this.getTypeColumnLabel(data as IRequestChangeHistory)}
            />
            <ColumnDirective
              headerText={this.props.i18n.t('requestChangeHistory.detail')}
              field='detail'
              width='250'
            />
          </ColumnsDirective>
          <Inject services={[Sort, Page, Toolbar, Resize]} />
        </GridComponent>
      </div>
    );
  }
}

export default withTranslation()(RequestChangesHistoryTab);
