import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import { SwitchComponent } from '@syncfusion/ej2-react-buttons';
import { DropDownListComponent, FieldSettingsModel } from '@syncfusion/ej2-react-dropdowns';
import { ActionBarComponent, IButton } from '@aitex/tsx-extranet-action-bar';

import { IInfoCardProps } from '../../../interfaces/props/IReportDetailProps';
import { ICustomFieldPermission } from '../../../common/model/customFieldPermission.model';
import { IStringToAnyDictionary } from '../../../common/model/stringToAnyDictionary.model';
import { IErrors } from '../../../common/model/errors.model';
import { reportsService } from '../../../services';
import * as customFieldPermissionUtils from '../../../utils/customFieldPermission.utils';
import * as reportUtils from '../../../utils/report.utils';
import { notifyError } from '../../../utils/toast.utils';
import * as reportStatusUtils from '../../../utils/reportStatus.utils';
import * as dateHelpers from '../../../helpers/date.helpers';
import TextBox from '../../../components/filters/textBox';
import './infoCard.scss';
import { ReportStatus } from '../../../common/model/enumerations/reportStatus.model';

type InfoCardPropsType = IInfoCardProps & WithTranslation;

type InfoCardStateType = IInfoCardState;

class InfoCard extends Component<InfoCardPropsType, InfoCardStateType> {
  public customFieldFields: FieldSettingsModel = { text: 'name', value: 'id' };

  public constructor(props: InfoCardPropsType) {
    super(props);

    const { currentUserEmail } = this.props;

    this.state = {
      showModal: false,
      requestAdvanceEmail: currentUserEmail,
      downloadButtonLoading: false,
      closeButtonLoading: false,
      cancelReportButtonLoading: false
    };
  }

  public showRequestAdvanceModal() {
    this.setState({ showModal: true });
  }

  public async onSubmit() {
    try {
      await reportsService.requestAdvance(this.props.report.id, this.state.requestAdvanceEmail);
      this.setState({ showModal: false });
    } catch (error) {
      const errors = error as IErrors;
      if (errors.AdvanceEmails) {
        notifyError(this.props.i18n.t(`apiErrors.${errors.AdvanceEmails[0]}`));
        return;
      }
      if (errors.ControlledError) {
        notifyError(errors.ControlledError[0]);
        return;
      }
      if (errors.UnknownError || (!errors.UnknownError && !errors.ControlledError)) {
        notifyError(this.props.i18n.t('detail.requestAdvanceError'));
        return;
      }
    }
  }

  public handleClose() {
    this.setState({ showModal: false });
  }

  public getCustomFieldPermissionByCode(code: string): ICustomFieldPermission {
    return customFieldPermissionUtils.getCustomFieldPermissionByCode(this.props.customFieldPermissions, code);
  }

  public render() {
    const {
      report,
      isView,
      customFieldPermissions: customFieldPermissions,
      qualityControlOptions,
      buyerOptions,
      isSaving,
      isCurrentUserInternal,
      isEciUser,
      onCustomFieldsChange,
      onEditButtonClick,
      onSaveButtonClick,
      onCancelButtonClick,
      onCloseButtonClick,
      onCancelReportButtonClick,
      onDownloadButtonClick,
      onSendEmailButtonClick,
      i18n
    } = this.props;

    const columnContainerClasses: string[] = ['meta-list_item pt-1 pb-1', 'meta-list_item ml-auto pt-1 pb-1'];
    const columnContainerNumber = columnContainerClasses.length;

    let entryDateOrEndDate: JSX.Element = null;
    let expectedDateOrExpirationDate: JSX.Element = null;
    const buttons: IButton[] = [];

    if (report.status === ReportStatus.FINALIZED) {
      if (report.endDate) {
        entryDateOrEndDate = (
          <div className='meta-list_item ml-auto pt-1 pb-1'>
            <span className='meta-label'>
              {i18n.t('report.endDate')}:
            </span>
            <span>{dateHelpers.toShortDate(report.endDate, true)}</span>
          </div>
        );
      }

      if (!isEciUser && report.expirationDate) {
        expectedDateOrExpirationDate = (
          <div className='meta-list_item pt-1 pb-1'>
            <span className='meta-label'>
              {i18n.t('report.expirationDate')}:
            </span>
            <span>{dateHelpers.toShortDate(report.expirationDate, true)}</span>
          </div>
        );
      }

      if (report.fileName && report.resultIsVisible && report.published) {
        buttons.push({
          text: i18n.t('reportDetail.actions.download'),
          iconCss: 'icon icon-app-file-download',
          iconCssAndText: true,
          disabled: false,
          loading: this.state.downloadButtonLoading,
          onClick: () => {
            this.setState({ downloadButtonLoading: true });
            onDownloadButtonClick().finally(() => this.setState({ downloadButtonLoading: false }));
          }
        });
        if (isEciUser) {
          buttons.push({
            text: i18n.t('reportDetail.actions.sendEmail'),
            iconCss: 'icon icon-app-action-send-email',
            iconCssAndText: true,
            disabled: false,
            loading: false,
            onClick: () => onSendEmailButtonClick()
          });
        }
      }
    }
    else {
      if (report.entryDate) {
        entryDateOrEndDate = (
          <div className='meta-list_item ml-auto pt-1 pb-1'>
            <span className='meta-label'>
              {i18n.t('report.entryDate')}:
            </span>
            <span>{dateHelpers.toShortDate(report.entryDate, true)}</span>
          </div>
        );
      }

      if (!isEciUser && report.expectedDate) {
        expectedDateOrExpirationDate = (
          <div className='meta-list_item pt-1 pb-1'>
            <span className='meta-label'>
              {i18n.t('report.expectedDate')}:
            </span>
            <span>{dateHelpers.toShortDate(report.expectedDate, true)}</span>
          </div>
        );
      }

      const showRequestAdvanceButton = reportUtils.canRequestAdvance(report);

      if (showRequestAdvanceButton && report.resultIsVisible && report.published) {
        buttons.push({
          text: i18n.t('reportDetail.actions.requestAdvance'),
          iconCss: 'icon icon-report-action-advance',
          iconCssAndText: true,
          disabled: false,
          loading: false,
          onClick: () => this.showRequestAdvanceModal()
        });
      }
    }

    const canWriteCustomFieldPermissions = customFieldPermissions.filter((p) => p.canWrite);
    if (canWriteCustomFieldPermissions.some((p) => p.code === 'closed')) {
      buttons.push({
        text: i18n.t('reportDetail.actions.close'),
        iconCss: 'icon icon-report-state-finalized',
        iconCssAndText: true,
        disabled: false,
        loading: this.state.closeButtonLoading,
        onClick: () => {
          this.setState({ closeButtonLoading: true });
          onCloseButtonClick().finally(() => this.setState({ closeButtonLoading: false }));
        }
      });
    }
    const buyerOptionCancelado = buyerOptions.find((o) => o.name.toLowerCase() === 'cancelado');
    if (buyerOptionCancelado && canWriteCustomFieldPermissions.some((p) => p.code === 'buyerId-cancel')) {
      buttons.push({
        text: i18n.t('reportDetail.actions.cancelReport'),
        iconCss: 'icon icon-app-item-cancel',
        iconCssAndText: true,
        disabled: false,
        loading: this.state.cancelReportButtonLoading,
        onClick: () => {
          this.setState({ cancelReportButtonLoading: true })
          onCancelReportButtonClick(buyerOptionCancelado.id).finally(() => this.setState({ cancelReportButtonLoading: false }));
        }
      });
    }

    const info: JSX.Element[] = [];
    const rows: JSX.Element[] = [];

    if (report.status) {
      info.push((
        <div key={'C' + info.length} className='col align-self-end'>
          <div className={(info.length + 1) % columnContainerNumber === 0 ? columnContainerClasses[0] : columnContainerClasses[1]}>
            <span className='meta-label'>
              {i18n.t('report.status')}:
            </span>
            <span>
              <span className={reportStatusUtils.getIconCss(report.status)} />
              <span>{reportStatusUtils.getLocalizedText(this.props.i18n, report.status)}</span>
            </span>
          </div>
        </div>
      ));
    }

    if (report.version) {
      info.push((
        <div key={'C' + info.length} className='col align-self-end'>
          <div className={(info.length + 1) % columnContainerNumber === 0 ? columnContainerClasses[0] : columnContainerClasses[1]}>
            <span className='meta-label'>
              {i18n.t('report.version')}:
            </span>
            <span>{report.version}</span>
          </div>
        </div>
      ));
    }

    if (entryDateOrEndDate) {
      info.push((
        <div key={'C' + info.length} className='col align-self-end'>
          {entryDateOrEndDate}
        </div>
      ));
    }

    if (expectedDateOrExpirationDate) {
      info.push((
        <div key={'C' + info.length} className='col align-self-end'>
          {expectedDateOrExpirationDate}
        </div>
      ));
    }

    const seasonIdPermission = this.getCustomFieldPermissionByCode('seasonId');
    if (seasonIdPermission) {
      info.push((
        <div key={'C' + info.length} className='col align-self-end'>
          <div className={(info.length + 1) % columnContainerNumber === 0 ? columnContainerClasses[0] : columnContainerClasses[1]}>
            <span className='meta-label'>
              {i18n.t('reportCustomFields.seasonId')}:
            </span>
            <span>{report.customFields.seasonName}</span>
          </div>
        </div>
      ));
    }

    if (isCurrentUserInternal || isEciUser) {
      info.push((
        <div key={'C' + info.length} className='col align-self-end'>
          <div className={(info.length + 1) % columnContainerNumber === 0 ? columnContainerClasses[0] : columnContainerClasses[1]}>
            <span className='meta-label'>
              {i18n.t('report.client')}:
            </span>
            <span>{report.client}</span>
          </div>
        </div>
      ));
    }

    if (!isEciUser) {
      if (report.yourReference) {
        info.push((
          <div key={'C' + info.length} className='col align-self-end'>
            <div className={(info.length + 1) % columnContainerNumber === 0 ? columnContainerClasses[0] : columnContainerClasses[1]}>
              <span className='meta-label'>
                {i18n.t('report.yourReference')}:
              </span>
              <span>{report.yourReference}</span>
            </div>
          </div>
        ));
      }

      if (report.offerNumber) {
        info.push((
          <div key={'C' + info.length} className='col align-self-end'>
            <div className={(info.length + 1) % columnContainerNumber === 0 ? columnContainerClasses[0] : columnContainerClasses[1]}>
              <span className='meta-label'>
                {i18n.t('report.offerNumber')}:
              </span>
              <span>{report.offerNumber}</span>
            </div>
          </div>
        ));
      }

      if (report.proforma) {
        info.push((
          <div key={'C' + info.length} className='col align-self-end'>
            <div className={(info.length + 1) % columnContainerNumber === 0 ? columnContainerClasses[0] : columnContainerClasses[1]}>
              <span className='meta-label'>
                {i18n.t('report.proforma')}:
              </span>
              <span>{report.proforma}</span>
            </div>
          </div>
        ));
      }

      if (report.requerimentStandard) {
        info.push((
          <div key={'C' + info.length} className='col align-self-end'>
            <div className={(info.length + 1) % columnContainerNumber === 0 ? columnContainerClasses[0] : columnContainerClasses[1]}>
              <span className='meta-label'>
                {i18n.t('report.requerimentStandard')}:
              </span>
              <span>{report.requerimentStandard}</span>
            </div>
          </div>
        ));
      }

      if (report.seriesName) {
        info.push((
          <div key={'C' + info.length} className='col align-self-end'>
            <div className={(info.length + 1) % columnContainerNumber === 0 ? columnContainerClasses[0] : columnContainerClasses[1]}>
              <span className='meta-label'>
                {i18n.t('report.series')}:
              </span>
              <span>{report.seriesName}</span>
            </div>
          </div>
        ));
      }
    }

    const eciOrderNumberPermission = this.getCustomFieldPermissionByCode('eciOrderNumber');
    if (eciOrderNumberPermission) {
      info.push((
        <div key={'C' + info.length} className='col align-self-end'>
          <div className={(info.length + 1) % columnContainerNumber === 0 ? columnContainerClasses[0] : columnContainerClasses[1]}>
            <span className='meta-label'>
              {i18n.t('reportCustomFields.eciOrderNumber')}:
            </span>
            <span>{report.customFields.eciOrderNumber}</span>
          </div>
        </div>
      ));
    }

    const modelPermission = this.getCustomFieldPermissionByCode('model');
    if (modelPermission) {
      info.push((
        <div key={'C' + info.length} className='col align-self-end'>
          <div className={(info.length + 1) % columnContainerNumber === 0 ? columnContainerClasses[0] : columnContainerClasses[1]}>
            <span className='meta-label'>
              {i18n.t('reportCustomFields.model')}:
            </span>
            <span>{report.customFields.model}</span>
          </div>
        </div>
      ));
    }

    const brandIdPermission = this.getCustomFieldPermissionByCode('brandId');
    if (brandIdPermission) {
      info.push((
        <div key={'C' + info.length} className='col align-self-end'>
          <div className={(info.length + 1) % columnContainerNumber === 0 ? columnContainerClasses[0] : columnContainerClasses[1]}>
            <span className='meta-label'>
              {i18n.t('reportCustomFields.brandId')}:
            </span>
            <span>{report.customFields.brandName}</span>
          </div>
        </div>
      ));
    }

    const supplierPermission = this.getCustomFieldPermissionByCode('supplier');
    if (supplierPermission) {
      info.push((
        <div key={'C' + info.length} className='col align-self-end'>
          <div className={(info.length + 1) % columnContainerNumber === 0 ? columnContainerClasses[0] : columnContainerClasses[1]}>
            <span className='meta-label'>
              {i18n.t('reportCustomFields.supplier')}:
            </span>
            <span>{report.customFields.supplier}</span>
          </div>
        </div>
      ));
    }

    const officeIdPermission = this.getCustomFieldPermissionByCode('officeId');
    if (officeIdPermission) {
      info.push((
        <div key={'C' + info.length} className='col align-self-end'>
          <div className={(info.length + 1) % columnContainerNumber === 0 ? columnContainerClasses[0] : columnContainerClasses[1]}>
            <span className='meta-label'>
              {i18n.t('reportCustomFields.officeId')}:
            </span>
            <span>{report.customFields.officeName}</span>
          </div>
        </div>
      ));
    }

    const divisionIdPermission = this.getCustomFieldPermissionByCode('divisionId');
    if (divisionIdPermission) {
      info.push((
        <div key={'C' + info.length} className='col align-self-end'>
          <div className={(info.length + 1) % columnContainerNumber === 0 ? columnContainerClasses[0] : columnContainerClasses[1]}>
            <span className='meta-label'>
              {i18n.t('reportCustomFields.divisionId')}:
            </span>
            <span>{report.customFields.divisionName}</span>
          </div>
        </div>
      ));
    }

    const unecoPermission = this.getCustomFieldPermissionByCode('uneco');
    if (unecoPermission) {
      info.push((
        <div key={'C' + info.length} className='col align-self-end'>
          <div className={(info.length + 1) % columnContainerNumber === 0 ? columnContainerClasses[0] : columnContainerClasses[1]}>
            <span className='meta-label'>
              {i18n.t('reportCustomFields.uneco')}:
            </span>
            <span>{report.customFields.uneco}</span>
          </div>
        </div>
      ));
    }

    const sectionIdPermission = this.getCustomFieldPermissionByCode('sectionId');
    if (sectionIdPermission) {
      info.push((
        <div key={'C' + info.length} className='col align-self-end'>
          <div className={(info.length + 1) % columnContainerNumber === 0 ? columnContainerClasses[0] : columnContainerClasses[1]}>
            <span className='meta-label'>
              {i18n.t('reportCustomFields.sectionId')}:
            </span>
            <span>{report.customFields.sectionName}</span>
          </div>
        </div>
      ));
      if ((info.length + 1) % columnContainerNumber !== 0) {
        info.push(<div key={'C' + info.length} className='col align-self-end' />);
      }
    }

    const aitexResultIdPermission = this.getCustomFieldPermissionByCode('aitexResultId');
    if (aitexResultIdPermission) {
      info.push((
        <div key={'C' + info.length} className='col align-self-end'>
          <div className={(info.length + 1) % columnContainerNumber === 0 ? columnContainerClasses[0] : columnContainerClasses[1]}>
            <span className='meta-label'>
              {i18n.t('reportCustomFields.aitexResultId')}:
            </span>
            <span>{report.customFields.aitexResultName}</span>
          </div>
        </div>
      ));
    }

    const incidencePermission = this.getCustomFieldPermissionByCode('incidence');
    if (incidencePermission) {
      info.push((
        <div key={'C' + info.length} className='col align-self-end'>
          <div className={(info.length + 1) % columnContainerNumber === 0 ? columnContainerClasses[0] : columnContainerClasses[1]}>
            <span className='meta-label'>
              {i18n.t('reportCustomFields.incidence')}:
            </span>
            <span>{report.customFields.incidence}</span>
          </div>
        </div>
      ));
    }

    const qualityControlIdPermission = this.getCustomFieldPermissionByCode('qualityControlId');
    if (qualityControlIdPermission) {
      info.push((
        <div key={'C' + info.length} className='col align-self-end'>
          <div className={(info.length + 1) % columnContainerNumber === 0 ? columnContainerClasses[0] : columnContainerClasses[1]}>
            <span className='meta-label'>
              {i18n.t('reportCustomFields.qualityControlId')}:
            </span>
            {isView || !qualityControlIdPermission.canWrite ? (
              <span>{report.customFields.qualityControlName}</span>
            ) : (
              <DropDownListComponent
                dataSource={qualityControlOptions as IStringToAnyDictionary[]}
                fields={this.customFieldFields}
                value={report.customFields.qualityControlId}
                change={(e) => onCustomFieldsChange({ ...report.customFields, qualityControlId: e.value as string })}
              />
            )}
          </div>
        </div>
      ));
    }

    const qualityControlDatePermission = this.getCustomFieldPermissionByCode('qualityControlDate');
    if (qualityControlDatePermission) {
      info.push((
        <div key={'C' + info.length} className='col align-self-end'>
          <div className={(info.length + 1) % columnContainerNumber === 0 ? columnContainerClasses[0] : columnContainerClasses[1]}>
            <span className='meta-label'>
              {i18n.t('reportCustomFields.qualityControlDate')}:
            </span>
            <span>{report.customFields.qualityControlDate && dateHelpers.toShortDate(report.customFields.qualityControlDate, true)}</span>
          </div>
        </div>
      ));
    }

    const buyerIdPermission = this.getCustomFieldPermissionByCode('buyerId');
    if (buyerIdPermission) {
      info.push((
        <div key={'C' + info.length} className='col align-self-end'>
          <div className={(info.length + 1) % columnContainerNumber === 0 ? columnContainerClasses[0] : columnContainerClasses[1]}>
            <span className='meta-label'>
              {i18n.t('reportCustomFields.buyerId')}:
            </span>
            {isView || !buyerIdPermission.canWrite ? (
              <span>{report.customFields.buyerName}</span>
            ) : (
                <DropDownListComponent
                  dataSource={buyerOptions as IStringToAnyDictionary[]}
                  fields={this.customFieldFields}
                  value={report.customFields.buyerId}
                  change={(e) => onCustomFieldsChange({ ...report.customFields, buyerId: e.value as string })}
                />
              )}
          </div>
        </div>
      ));
    }

    const buyerDatePermission = this.getCustomFieldPermissionByCode('buyerDate');
    if (buyerDatePermission) {
      info.push((
        <div key={'C' + info.length} className='col align-self-end'>
          <div className={(info.length + 1) % columnContainerNumber === 0 ? columnContainerClasses[0] : columnContainerClasses[1]}>
            <span className='meta-label'>
              {i18n.t('reportCustomFields.buyerDate')}:
            </span>
            <span>{report.customFields.buyerDate && dateHelpers.toShortDate(report.customFields.buyerDate, true)}</span>
          </div>
        </div>
      ));
    }

    const closedPermission = this.getCustomFieldPermissionByCode('closed');
    if (closedPermission) {
      info.push((
        <div key={'C' + info.length} className='col align-self-end'>
          <div className={(info.length + 1) % columnContainerNumber === 0 ? columnContainerClasses[0] : columnContainerClasses[1]}>
            <span className='meta-label'>
              {i18n.t('reportCustomFields.closed')}:
            </span>
            <div style={{ marginTop: '0.15rem' }}>
              <SwitchComponent
                checked={report.customFields.closed}
                disabled={true} /* To close a report, there is a specific "close" button */
              />
            </div>
          </div>
        </div>
      ));
    }

    const automaticGenerationPermission = this.getCustomFieldPermissionByCode('automaticGeneration');
    if (automaticGenerationPermission) {
      info.push((
        <div key={'C' + info.length} className='col align-self-end'>
          <div className={(info.length + 1) % columnContainerNumber === 0 ? columnContainerClasses[0] : columnContainerClasses[1]}>
            <span className='meta-label'>
              {i18n.t('reportCustomFields.automaticGeneration')}:
            </span>
            <div style={{ marginTop: '0.15rem' }}>
              <SwitchComponent
                checked={report.customFields.automaticGeneration}
                disabled={isView || !automaticGenerationPermission.canWrite}
                change={(e) => onCustomFieldsChange({ ...report.customFields, automaticGeneration: e.checked })}
              />
            </div>
          </div>
        </div>
      ));
    }

    const commentsForReportPermission = this.getCustomFieldPermissionByCode('commentsForReport');
    if (commentsForReportPermission) {
      info.push((
        <div key={'C' + info.length} className='col align-self-end'>
          <div className={(info.length + 1) % columnContainerNumber === 0 ? columnContainerClasses[0] : columnContainerClasses[1]}>
            <span className='meta-label'>
              {i18n.t('reportCustomFields.commentsForReport')}:
            </span>
            {isView || !commentsForReportPermission.canWrite ? (
              <span>{report.customFields.commentsForReport}</span>
            ) : (
              <textarea
                className='form-control'
                rows={5}
                value={report.customFields.commentsForReport}
                onChange={(e) => onCustomFieldsChange({ ...report.customFields, commentsForReport: e.target.value })}
              />
            )}
          </div>
        </div>
      ));
    }

    const collectionPermission = this.getCustomFieldPermissionByCode('collection');
    if (collectionPermission) {
      info.push((
        <div key={'C' + info.length} className='col align-self-end'>
          <div className={(info.length + 1) % columnContainerNumber === 0 ? columnContainerClasses[0] : columnContainerClasses[1]}>
            <span className='meta-label'>
              {i18n.t('reportCustomFields.collection')}:
            </span>
            <span>{report.customFields.collection}</span>
          </div>
        </div>
      ));
    }

    const colourPermission = this.getCustomFieldPermissionByCode('colour');
    if (colourPermission) {
      info.push((
        <div key={'C' + info.length} className='col align-self-end'>
          <div className={(info.length + 1) % columnContainerNumber === 0 ? columnContainerClasses[0] : columnContainerClasses[1]}>
            <span className='meta-label'>
              {i18n.t('reportCustomFields.colour')}:
            </span>
            <span>{report.customFields.colour}</span>
          </div>
        </div>
      ));
    }

    const targetPermission = this.getCustomFieldPermissionByCode('target');
    if (targetPermission) {
      info.push((
        <div key={'C' + info.length} className='col align-self-end'>
          <div className={(info.length + 1) % columnContainerNumber === 0 ? columnContainerClasses[0] : columnContainerClasses[1]}>
            <span className='meta-label'>
              {i18n.t('reportCustomFields.target')}:
            </span>
            <span>{report.customFields.target}</span>
          </div>
        </div>
      ));
    }

    const identifierPermission = this.getCustomFieldPermissionByCode('identifier');
    if (identifierPermission) {
      info.push((
        <div key={'C' + info.length} className='col align-self-end'>
          <div className={(info.length + 1) % columnContainerNumber === 0 ? columnContainerClasses[0] : columnContainerClasses[1]}>
            <span className='meta-label'>
              {i18n.t('reportCustomFields.identifier')}:
            </span>
            <span>{report.customFields.identifier}</span>
          </div>
        </div>
      ));
    }

    const eciBuyerPermission = this.getCustomFieldPermissionByCode('eciBuyer');
    if (eciBuyerPermission) {
      info.push((
        <div key={'C' + info.length} className='col align-self-end'>
          <div className={(info.length + 1) % columnContainerNumber === 0 ? columnContainerClasses[0] : columnContainerClasses[1]}>
            <span className='meta-label'>
              {i18n.t('reportCustomFields.eciBuyer')}:
            </span>
            <span>{report.customFields.eciBuyer}</span>
          </div>
        </div>
      ));
    }

    const copyPermission = this.getCustomFieldPermissionByCode('copy');
    if (copyPermission) {
      info.push((
        <div key={'C' + info.length} className='col align-self-end'>
          <div className={(info.length + 1) % columnContainerNumber === 0 ? columnContainerClasses[0] : columnContainerClasses[1]}>
            <span className='meta-label'>
              {i18n.t('reportCustomFields.copy')}:
            </span>
            <div style={{ marginTop: '0.15rem' }}>
              <SwitchComponent
                checked={report.customFields.copy}
                disabled={isView || !copyPermission.canWrite}
                change={(e) => onCustomFieldsChange({ ...report.customFields, copy: e.checked })}
              />
            </div>
          </div>
        </div>
      ));
    }

    const validationDatePermission = this.getCustomFieldPermissionByCode('validationDate');
    if (validationDatePermission) {
      info.push((
        <div key={'C' + info.length} className='col align-self-end'>
          <div className={(info.length + 1) % columnContainerNumber === 0 ? columnContainerClasses[0] : columnContainerClasses[1]}>
            <span className='meta-label'>
              {i18n.t('reportCustomFields.validationDate')}:
            </span>
            <span>{report.customFields.validationDate && dateHelpers.toShortDate(report.customFields.validationDate, true)}</span>
          </div>
        </div>
      ));
    }

    const countryIdPermission = this.getCustomFieldPermissionByCode('countryId');
    if (countryIdPermission) {
      info.push((
        <div key={'C' + info.length} className='col align-self-end'>
          <div className={(info.length + 1) % columnContainerNumber === 0 ? columnContainerClasses[0] : columnContainerClasses[1]}>
            <span className='meta-label'>
              {i18n.t('reportCustomFields.countryId')}:
            </span>
            <span>{report.customFields.countryName}</span>
          </div>
        </div>
      ));
    }

    const campaignIdPermission = this.getCustomFieldPermissionByCode('campaignId');
    if (campaignIdPermission) {
      info.push((
        <div key={'C' + info.length} className='col align-self-end'>
          <div className={(info.length + 1) % columnContainerNumber === 0 ? columnContainerClasses[0] : columnContainerClasses[1]}>
            <span className='meta-label'>
              {i18n.t('reportCustomFields.campaignId')}:
            </span>
            <span>{report.customFields.campaignName}</span>
          </div>
        </div>
      ));
    }

    let elementsInRow: JSX.Element[] = [];
    for (const element of info) {
      elementsInRow.push(element);

      if (elementsInRow.length % 2 === 0) {
        rows.push((
          <div key={rows.length} className='row mt-3 mb-3'>
            {elementsInRow}
          </div>
        ));
        elementsInRow = [];
      }
    }

    if (elementsInRow.length > 0) {
      elementsInRow.push((
        <div key={'C' + elementsInRow.length} className='col'>
          {null}
        </div>
      ));

      rows.push((
        <div key={rows.length} className='row mt-3 mb-3'>
          {elementsInRow}
        </div>
      ));
    }

    return (
      <div className='mb-3'>
        <div className={`meta-container${(canWriteCustomFieldPermissions.length > 0 || buttons.length > 0) ? '' : ' no-action-bar'}`}>
          <div className='row'>
            <div className='col'>
              <div className='meta-header m-auto'>
                <span className='icon icon-report' />
                <span className='meta-label'>
                  {i18n.t('report.reportNumber')}
                </span>
                : {report.reportNumber}
              </div>
            </div>
          </div>
          {rows}
        </div>
        {(canWriteCustomFieldPermissions.length > 0 || buttons.length > 0) && <ActionBarComponent
          disabled={false}
          editing={isView === false}
          loading={isSaving}
          withoutTopBorder={true}
          buttons={buttons}
          onEditButtonClick={canWriteCustomFieldPermissions.length === 0 ? undefined : onEditButtonClick}
          onSaveButtonClick={canWriteCustomFieldPermissions.length === 0 ? undefined : onSaveButtonClick}
          onCancelButtonClick={canWriteCustomFieldPermissions.length === 0 ? undefined : onCancelButtonClick}
        />}
        <Modal className='modal-apply' show={this.state.showModal} onHide={() => this.handleClose()}>
          <Modal.Header closeButton={true}>
            <Modal.Title>{i18n.t('icons.requestAdvance')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={(e: React.FormEvent) => { e.preventDefault(); this.onSubmit(); }}>
              <div className='row mb-3'>
                <div className='col'>
                  <small className='text-muted'>{i18n.t('detail.requestAdvanceInfo')}</small>
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <div className='form-group'>
                    <div className='autocomplete-input'>
                      <div className='autocomplete-label'>{i18n.t('emails')}</div>
                      <TextBox
                        name='code'
                        value={this.state.requestAdvanceEmail}
                        showClearButton={true}
                        onChange={(value) => this.setState({ requestAdvanceEmail: value })}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row justify-content-end'>
                <div className='col-auto'>
                  <button type='submit' className='btn btn-secondary' title={i18n.t('reportDetail.actions.requestAdvance')}>
                    <span className='icon icon-app-action-send-email' />
                  </button>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

interface IInfoCardState {
  showModal: boolean;
  requestAdvanceEmail: string;
  downloadButtonLoading: boolean;
  closeButtonLoading: boolean;
  cancelReportButtonLoading: boolean;
}

export default withTranslation()(InfoCard);
