import { URL } from '../common/constants';
import { IProduct } from '../common/model/product.model';
import * as services from '.';

export interface IProductsService {
  create(Product: IProduct): Promise<IProduct>;
  update(Product: IProduct): Promise<IProduct>;
}

class ProductsService implements IProductsService {
  public constructor(private httpClient: services.IHttpClient) {

  }

  public create(product: IProduct): Promise<IProduct> {
    return this.httpClient.postCustom(`${URL.WEB.BASE}${URL.WEB.PRODUCTS}`, product);
  }

  public update(product: IProduct): Promise<IProduct> {
    return this.httpClient.putCustom(`${URL.WEB.BASE}${URL.WEB.PRODUCTS}/${product.id}`, product);
  }
}

export const productsService = new ProductsService(services.httpClient);
