import { URL } from '../common/constants';
import * as services from '.';
import { sortAZ } from '../utils/sort.utils';

export interface IServicesService {
    getServicesByReportId(reportId: string): Promise<any>;
}

class ServicesService implements IServicesService {
  public constructor(private httpClient: services.IHttpClient) {

  }

  public async getServicesByReportId(reportId: string) {
    const servicesLocal = await this.httpClient.get(`${URL.WEB.BASE}${URL.WEB.SERVICES}?reportId=${reportId}`);
    return sortAZ(servicesLocal, 'description');
  }
}

export const servicesService = new ServicesService(services.httpClient);
