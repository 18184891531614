import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Moment } from 'moment';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Sort,
  Page,
  Toolbar,
  ExcelExport,
  Resize,
  DetailRow,
  GridModel,
  RowDataBoundEventArgs,
  SortSettingsModel,
  PagerComponent,
  SortEventArgs
} from '@syncfusion/ej2-react-grids';
import { ItemModel, ClickEventArgs } from '@syncfusion/ej2-navigations';

import { IRequestsGridProps } from '../../../interfaces/props/IRequestsListProps';
import { EXCEL_EXPORT_ACTION_ID } from '../../../common/constants/gridActionId.constants';
import { IRequestWithFlattenedCustomFields } from '../../../common/model/request.model';
import { RequestsListMode } from '../../../common/model/enumerations/requestsListMode.model';
import * as requestStatusUtils from '../../../utils/requestStatus.utils';
import * as gridUtils from '../../../utils/grid.utils';
import * as pagerUtils from '../../../utils/pager.utils';
import * as sortUtils from '../../../utils/sort.utils';
import * as dateHelpers from '../../../helpers/date.helpers';
import './requestsGrid.scss';

type RequestsGridPropsType = IRequestsGridProps & RouteComponentProps & WithTranslation;

class RequestsGrid extends Component<RequestsGridPropsType> {
  public gridComponent: GridComponent = null;
  public toolbarOptions: ItemModel[] = [];
  public sortOptions: SortSettingsModel = null;
  public childGrid: GridModel = null;

  public pagerComponent: PagerComponent = null;

  private readonly CREATE_REQUEST_ACTION_ID = 'createRequest';

  public constructor(props: RequestsGridPropsType) {
    super(props);

    const { requests, sortField, sortDirection, isCurrentUserInternal, i18n } = this.props;

    this.toolbarOptions = [{
      tooltipText: i18n.t('syncfusion.grid.Excelexport'),
      id: EXCEL_EXPORT_ACTION_ID,
      prefixIcon: 'icon icon-file-excel-o'
    }];
    if (!isCurrentUserInternal) {
      this.toolbarOptions.push({
        tooltipText: i18n.t('requestsList.actions.createRequest'),
        id: this.CREATE_REQUEST_ACTION_ID,
        template: `
          <button type="button" class="e-tbar-btn e-tbtn-txt e-control e-btn e-lib">
            <span class="icon icon-request"></span>
            <span class="ml-1 icon icon-app-item-add"></span>
          </button>
        `
      });
    }

    this.sortOptions = {
      columns: [{ field: sortField, direction: sortDirection }]
    };

    this.childGrid = {
      dataSource: requests,
      queryString: 'id',
      columns: [
        { field: 'referenceNames', headerText: i18n.t('request.reference') }
      ]
    };
  }

  public componentDidUpdate(prevProps: RequestsGridPropsType) {
    gridUtils.manageSpinner(this.gridComponent, prevProps.isFetching, this.props.isFetching);
    gridUtils.manageSorting(this.gridComponent, prevProps.isFetching, this.props.isFetching, this.props.sortField, this.props.sortDirection);
  }

  public onToolbarClick(args: ClickEventArgs) {
    if (args.item.id === EXCEL_EXPORT_ACTION_ID) {
      this.gridComponent.excelExport();
    }

    if (args.item.id === this.CREATE_REQUEST_ACTION_ID) {
      this.props.history.push('/request/new');
    }
  }

  public onGridComponentDataBound() {
    const { currentPage, pageSize, totalCount, searchDate, i18n } = this.props;

    pagerUtils.refreshPager(this.pagerComponent, currentPage, pageSize, totalCount, searchDate, i18n);
  }

  public onGridComponentActionBegin(args: SortEventArgs) {
    if (args.requestType === 'sorting') {
      if (args.columnName === 'createdDate') {
        this.gridComponent.getColumnByField('createdDate').sortComparer = (reference, comparer) =>
          sortUtils.gridDateSortComparer(reference as unknown as Moment, comparer as unknown as Moment, args.direction);
      } else {
        this.gridComponent.getColumnByField(args.columnName).sortComparer = (reference, comparer) =>
          sortUtils.gridSortComparer(reference, comparer, args.direction);
      }

      gridUtils.manageSortingChange(args, this.props.sortField, this.props.sortDirection, this.props.onSortingChange);
    }
  }

  public onRowDataBound(args: RowDataBoundEventArgs) {
    gridUtils.manageDetailRowExpansion(this.gridComponent, args, !!(args.data as IRequestWithFlattenedCustomFields).referenceNames, true);
  }

  public getSubjectText(subject: string): string {
    let value = '---';
    if (subject !== undefined && subject !== null && subject.trim().length > 0)
      value = subject;

    return value;
  }

  public getSubjectColumnTemplate(request: IRequestWithFlattenedCustomFields): JSX.Element {
    return <span className='e-link' onClick={() => this.props.history.push(`/request/${request.id}/edit`)}>{this.getSubjectText(request.subject)}</span>;
  }

  public getCodeColumnTemplate(request: IRequestWithFlattenedCustomFields): JSX.Element {
    return <span className='e-link' onClick={() => this.props.history.push(`/request/${request.id}/edit`)}>{request.code}</span>;
  }

  public getTypeColumnTemplate(request: IRequestWithFlattenedCustomFields): JSX.Element {
    return <span title={requestStatusUtils.getLocalizedText(this.props.i18n, request.status)} className={requestStatusUtils.getIconCss(request.status)} />;
  }

  public getCreatedDateLabel(request: IRequestWithFlattenedCustomFields): string {
    return request.createdDate && dateHelpers.toShortDate(request.createdDate, true);
  }

  public onPagerComponentClick(e: any) {
    if (e.currentPage !== this.props.currentPage) {
      this.props.onCurrentPageChange(e.currentPage);
    }
  }

  public onPageSizesDropdownChange(e: any) {
    if (e.pageSize !== this.props.pageSize) {
      this.props.onPageSizeChange(e.pageSize);
    }
  }

  public shouldShowCustomFieldColumn(customFieldCode: string): boolean {
    const { listMode, requestCustomFieldSettings, isCurrentUserInternal } = this.props;

    if (isCurrentUserInternal) {
      return true;
    }

    const settings = requestCustomFieldSettings.find((s) => s.customFieldCode === customFieldCode);

    if (!settings) {
      return false;
    }

    switch (listMode) {
      case RequestsListMode.ALL:
        return settings.listAll;
      case RequestsListMode.OPEN:
        return settings.listOpen;
      case RequestsListMode.IN_PROGRESS:
        return settings.listInProgress;
      default:
        return false;
    }
  }

  public render() {
    const { requests, isCurrentUserInternal, i18n } = this.props;

    if (this.gridComponent && this.gridComponent.childGrid) {
      this.gridComponent.childGrid.dataSource = requests.filter((request) => !!request.referenceNames);
    }

    return (
      <div className='requests-grid'>
        <GridComponent
          dataSource={requests}
          childGrid={this.childGrid}
          toolbar={this.toolbarOptions}
          allowSorting={true}
          allowPaging={true}   /* Si no se incluye este paginador (oculto por css), no se muestra el dropdown en el customizado */
          allowExcelExport={true}
          allowResizing={true}
          sortSettings={this.sortOptions}
          pageSettings={{ pageSize: pagerUtils.getMaxPageSize() }}
          locale={i18n.language}
          ref={(grid: GridComponent) => this.gridComponent = grid}
          toolbarClick={(args) => this.onToolbarClick(args)}
          dataBound={() => this.onGridComponentDataBound()}
          actionBegin={(args) => this.onGridComponentActionBegin(args as SortEventArgs)}
          rowDataBound={(args) => this.onRowDataBound(args)}
        >
          <ColumnsDirective>
            <ColumnDirective
              headerText={this.props.i18n.t('request.subject')}
              field='subject'
              width='225'
              template={(request: IRequestWithFlattenedCustomFields) => this.getSubjectColumnTemplate(request)}
            />
            <ColumnDirective
              headerText={this.props.i18n.t('request.code')}
              field='code'
              width='125'
              template={(request: IRequestWithFlattenedCustomFields) => this.getCodeColumnTemplate(request)}
            />
            {isCurrentUserInternal && <ColumnDirective
              headerText={this.props.i18n.t('request.company')}
              field='companyName'
              width='200'
            />}
            <ColumnDirective
              headerText={this.props.i18n.t('request.status')}
              field='status'
              textAlign='Center'
              width='75'
              allowSorting={false}
              template={(request: IRequestWithFlattenedCustomFields) => this.getTypeColumnTemplate(request)}
            />
            <ColumnDirective
              headerText={this.props.i18n.t('request.applicant')}
              field='applicantEmail'
              width='200'
            />
            <ColumnDirective
              headerText={this.props.i18n.t('request.createdDate')}
              field='createdDate'
              width='100'
              valueAccessor={(_field, data) => this.getCreatedDateLabel(data as IRequestWithFlattenedCustomFields)}
            />
            <ColumnDirective
              headerText={this.props.i18n.t('request.order')}
              field='order'
              width='200'
            />
            {this.shouldShowCustomFieldColumn('eciRequest') && <ColumnDirective
              headerText={i18n.t('requestCustomFields.eciRequest')}
              field='eciRequest'
              width='100'
            />}
            {this.shouldShowCustomFieldColumn('bidderId') && <ColumnDirective
              headerText={i18n.t('requestCustomFields.bidderId')}
              field='bidderName'
              width='100'
            />}
            {this.shouldShowCustomFieldColumn('centerId') && <ColumnDirective
              headerText={i18n.t('requestCustomFields.centerId')}
              field='centerName'
              width='100'
            />}
            {this.shouldShowCustomFieldColumn('unecoId') && <ColumnDirective
              headerText={i18n.t('requestCustomFields.unecoId')}
              field='unecoName'
              width='100'
            />}
          </ColumnsDirective>
          <Inject services={[Sort, Page, Toolbar, ExcelExport, Resize, DetailRow]} />
        </GridComponent>
        {requests.length ?
          <PagerComponent
            pageSize={pagerUtils.getDefaultPageSize()}
            pageCount={pagerUtils.getPageCount()}
            pageSizes={pagerUtils.getPageSizes()}
            ref={(pagerComponent) => this.pagerComponent = pagerComponent}
            click={(e) => this.onPagerComponentClick(e)}
            dropDownChanged={(e) => this.onPageSizesDropdownChange(e)}
          />
          :
          null
        }
      </div>
    );
  }
}

export default withRouter(withTranslation()(RequestsGrid));
