import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { DropDownListComponent, ChangeEventArgs, FieldSettingsModel } from '@syncfusion/ej2-react-dropdowns';
import { UploaderComponent, FormValidator, AsyncSettingsModel, UploadingEventArgs, FormEventArgs } from '@syncfusion/ej2-react-inputs';
import { DialogComponent } from '@aitex/tsx-extranet-dialogs';

import { IUploadAnnexDialogProps } from '../../../interfaces/props/IDialogsProps';
import { IAnnex } from '../../../common/model/annex.model';
import { IAnnexedCategory } from '../../../common/model/annexedCategory.model';
import { IErrors } from '../../../common/model/errors.model';
import { IStringToAnyDictionary } from '../../../common/model/stringToAnyDictionary.model';
import { AnnexedState } from '../../../common/model/enumerations/annexedState.model';
import { Validator } from '../../../common/model/enumerations/validator.model';
import { annexesService } from '../../../services';
import * as dialogUtils from '../../../utils/dialog.utils';
import * as formValidatorUtils from '../../../utils/formValidator.utils';
import * as annexedCategoryUtils from '../../../utils/annexedCategory.utils';
import { notifyError } from '../../../utils/toast.utils';
import ValidationError from '../../validationError/validationError';
import TextBox from '../../filters/textBox';
import './uploadAnnexDialog.scss';

type UploadAnnexDialogPropsType = IUploadAnnexDialogProps & WithTranslation;

type UploadAnnexDialogStateType = IUploadAnnexDialogState;

class UploadAnnexDialog extends Component<UploadAnnexDialogPropsType, UploadAnnexDialogStateType> {
  public readonly UPLOAD_ANNEX_DIALOG_FORM_ID = 'uploadAnnexDialogForm';

  public annexedCategoriesFields: FieldSettingsModel = { text: 'value', value: 'id' };

  public uploaderComponent: UploaderComponent = null;
  public asyncSettings: AsyncSettingsModel = {
    saveUrl: annexesService.getUploadUrl(),
    retryCount: 0
  };

  private readonly CANCEL_BUTTON_ID = 'uploadAnnexDialogCancel';
  private readonly OK_BUTTON_ID = 'uploadAnnexDialogOk';

  private readonly CANNOT_UPLOAD_ANNEX_ERROR: IErrors = { UnknownError: ['dialogs.uploadAnnexDialog.errors.cannotUploadAnnex'] };

  private formValidator: FormValidator = null;
  private isValidatingAll = false;
  private auxErrors: IErrors = {};

  private initialState: UploadAnnexDialogStateType = {
    annex: {
      id: null,
      description: null,
      annexedCategoryId: null,
      annexedCategoryValue: null,
      state: AnnexedState.PENDING,
      observations: null,
      sourceType: null,
      sourceId: null,
      userId: null,
      userName: null,
      isUserInternal: false
    },
    annexedCategories: [],
    isLoading: false,
    errors: {}
  };

  public constructor(props: UploadAnnexDialogPropsType) {
    super(props);

    this.state = this.initialState;
  }

  public componentDidUpdate(prevProps: UploadAnnexDialogPropsType): void {
    const { sourceType, sourceId, visible, i18n } = this.props;

    if (!prevProps.visible && visible) {
      this.setState({ annex: { ...this.state.annex, sourceType, sourceId } });

      annexesService.fetchAnnexedCategories().then((annexedCategories): void => this.setState({
        annex: { ...this.state.annex, annexedCategoryId: annexedCategories.length === 1 ? annexedCategories[0].id : this.state.annex.annexedCategoryId },
        annexedCategories: annexedCategories.map((annexedCategory: IAnnexedCategory): IAnnexedCategory => {
          annexedCategory.value = annexedCategoryUtils.getLocalizedText(i18n, annexedCategory.value);
          return annexedCategory;
        })
      }));
    }
    if (prevProps.visible && !visible) {
      this.setState(this.initialState);
      if (this.uploaderComponent) {
        this.uploaderComponent.clearAll();
      }
      this.resetFormValidator();
    }

    dialogUtils.manageButtonsClick(this.OK_BUTTON_ID, (): void => this.uploadAnnex(), this.CANCEL_BUTTON_ID, (): void => this.dismiss());
  }

  public uploadAnnex(): void {
    try {
      if (!this.validateForm()) {
        return;
      }

      if (!this.uploaderComponent) {
        throw this.CANNOT_UPLOAD_ANNEX_ERROR;
      }
      if (typeof this.state.annex.sourceType !== 'number' || !this.state.annex.sourceId) {
        throw this.CANNOT_UPLOAD_ANNEX_ERROR;
      }
      if (this.uploaderComponent.fileList.length === 0) {
        notifyError(this.props.i18n.t('dialogs.uploadAnnexDialog.errors.emptyFiles'));
        return;
      }
      if (document.getElementsByClassName('e-icons e-file-reload-btn').length) {
        this.uploaderComponent.retry();
      } else {
        this.uploaderComponent.upload();
      }
    } catch (error) {
      const errors = error as IErrors;
      this.dismiss(errors);
    }
  }

  public close(): void {
    this.formValidator = null;
    this.props.onClose();
  }

  public dismiss(reason?: IErrors): void {
    this.props.onDismiss(reason);
  }

  public configureFormValidator(): void {
    if (this.props.visible) {
      if (!this.formValidator) {
        this.formValidator = formValidatorUtils.configureFormValidator(this.UPLOAD_ANNEX_DIALOG_FORM_ID, {
          Description: { required: [true, Validator.NOT_EMPTY] },
          AnnexedCategoryId: { required: [true, Validator.NOT_EMPTY] }
        });
        this.formValidator.validationComplete = (args) => {
          formValidatorUtils.validationComplete(args as FormEventArgs, this.isValidatingAll, this.state.errors, this.auxErrors, (errors): void => this.setState({ errors }));
        };
      }
    } else {
      this.formValidator = null;
    }
  }

  public onDescriptionChange(description: string): void {
    this.setState({ annex: { ...this.state.annex, description } });
  }

  public onAnnexedCategoryChange(e: ChangeEventArgs): void {
    const annexedCategoryId = e.value as string;
    this.setState({ annex: { ...this.state.annex, annexedCategoryId } });
    if (this.formValidator) {
      this.formValidator.validate('AnnexedCategoryId');
    }
  }

  public onUploading(args: UploadingEventArgs): void {
    this.setState({ isLoading: true, errors: this.initialState.errors });

    args.currentRequest.setRequestHeader('Authorization', `Bearer ${this.props.accessToken}`);
    args.customFormData = [
      { description: this.state.annex.description },
      { annexedCategoryId: this.state.annex.annexedCategoryId },
      { sourceType: this.state.annex.sourceType },
      { sourceId: this.state.annex.sourceId }
    ];
  }

  public onUploadSuccess(): void {
    this.setState({ isLoading: false });
    this.close();
  }

  public onUploadFailure(e: any): void {
    this.setState({ isLoading: false });

    let errors: IErrors = JSON.parse((e.e as ProgressEvent<XMLHttpRequest>).target.responseText).errors as IErrors;
    if (!errors || errors.UnknownError) {
      errors = this.CANNOT_UPLOAD_ANNEX_ERROR;
    }
    if (errors.Description || errors.AnnexedCategoryId) {
      this.setState({ errors });
      return;
    }
    this.dismiss(errors);
  }

  public render(): JSX.Element {
    const { visible, i18n } = this.props;
    const defaultAnnexedCategoryId = 'b497555e-8b40-4466-a1c1-f714084cbbb3';

    return (
      <DialogComponent
        header={i18n.t('dialogs.uploadAnnexDialog.title')}
        visible={visible}
        width='480px'
        footerTemplate={dialogUtils.computeFooterTemplate(
          this.OK_BUTTON_ID,
          i18n.t('actions.ok'),
          this.CANCEL_BUTTON_ID,
          i18n.t('actions.cancel'),
          this.state.isLoading,
          this.isUploadDisabled()
        )}
        onDismiss={(): void => this.dismiss()}
      >
        <div className='upload-annex'>
          <form id={this.UPLOAD_ANNEX_DIALOG_FORM_ID} ref={() => this.configureFormValidator()}>
            <div className='row'>
              <div className='col'>
                <div className='form-group'>
                  <div className='autocomplete-input'>
                    <div className='autocomplete-label'>{i18n.t('annex.description')}</div>
                    <TextBox
                      name='Description'
                      value={this.state.annex.description}
                      onChange={(value): void => this.onDescriptionChange(value)}
                    />
                  </div>
                  <ValidationError errors={this.state.errors} errorKey={'Description'} />
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col'>
                <div className='form-group'>
                  <div className='autocomplete-input'>
                    <div className='autocomplete-label'>{i18n.t('annex.annexedCategory')}</div>
                    <DropDownListComponent
                      fields={this.annexedCategoriesFields}
                      dataSource={this.state.annexedCategories as IStringToAnyDictionary[]}
                      name='AnnexedCategoryId'
                      value={this.state.annex.annexedCategoryId ?? defaultAnnexedCategoryId}
                      change={(e): void => this.onAnnexedCategoryChange(e)}
                    />
                  </div>
                  <ValidationError errors={this.state.errors} errorKey={'AnnexedCategoryId'} />
                </div>
              </div>
            </div>
          </form>
          <div className='row'> {/* Must be outside <form> (https://www.syncfusion.com/forums/144130/all-files-are-deleted-from-uploader-list) */}
            <div className='col'>
              <UploaderComponent
                name='Files'
                autoUpload={false}
                asyncSettings={this.asyncSettings}
                allowedExtensions='.pdf,.jpg,.jpeg,.png,.doc,.docx,.xls,.xlsx'
                maxFileSize={25000000}
                locale={i18n.language}
                ref={(uploader: UploaderComponent): UploaderComponent => this.uploaderComponent = uploader}
                uploading={(args): void => this.onUploading(args)}
                success={(): void => this.onUploadSuccess()}
                failure={(e): void => this.onUploadFailure(e as any)}
              />
            </div>
          </div>
        </div>
      </DialogComponent>
    );
  }

  private isUploadDisabled(): boolean {
    return !!this.state.errors.Description || !!this.state.errors.AnnexedCategoryId || this.state.isLoading;
  }

  private resetFormValidator(): void {
    if (!this.formValidator) {
      return;
    }

    if (this.formValidator.getInputElement('Description')) {
      this.formValidator.getInputElement('Description').parentElement.className = this.formValidator.getInputElement('Description').parentElement.className.replace('e-error', '');
    }
    if (this.formValidator.getInputElement('AnnexedCategoryId')) {
      this.formValidator.getInputElement('AnnexedCategoryId').parentElement.className = this.formValidator.getInputElement('AnnexedCategoryId').parentElement.className.replace('e-error', '');
    }
    this.formValidator.removeRules();
    this.formValidator.validationComplete = undefined;
  }

  private validateForm(): boolean {
    if (!this.formValidator) {
      return true;
    }

    this.isValidatingAll = true;
    const isFormValid = this.formValidator.validate();
    this.isValidatingAll = false;
    if (!isFormValid) {
      this.setState({ errors: this.auxErrors });
      this.auxErrors = {};
    }
    return isFormValid;
  }
}

interface IUploadAnnexDialogState {
  annex: IAnnex;
  annexedCategories: IAnnexedCategory[];
  isLoading: boolean;
  errors: IErrors;
}

export default withTranslation()(UploadAnnexDialog);
