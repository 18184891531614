// tslint:disable: no-bitwise
export enum ReportCustomFieldSettingsOption {
  LIST_ALL = 0x01 << 7,
  LIST_IN_PROGRESS = 0x01 << 6,
  LIST_FINALIZED = 0x01 << 5,
  FILTER_ALL = 0x01 << 4,
  FILTER_IN_PROGRESS = 0x01 << 3,
  FILTER_FINALIZED = 0x01 << 2,
  ADVANCED_FILTER = 0x01 << 1,
  FORM = 0x01
}
