import { Action, Reducer } from 'redux';

export function combineAllReducers<S>(reducers: Array<Reducer<S, Action>>, defaultState: S): Reducer<S, Action> {
    return (state: S = defaultState, action: Action) => {
        reducers.forEach((reducer) => {
            state = reducer(state, action);
        });

        return state;
    };
}
