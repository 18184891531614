import React, { Component } from 'react';
import DateRangeBase, { IDateRangeFilterProps, IDateRangeFilterState } from './dateRangeBase';
import { getDate } from '../../helpers';
import { PresetsModel } from '@syncfusion/ej2-calendars';

interface IDateRangeProps extends IDateRangeFilterProps {
  name: string;
}

class DateRange extends Component<IDateRangeProps, IDateRangeFilterState> {
  private today: Date = new Date(getDate.today().toDateString());
  private weekStart: Date = new Date(getDate.weekStart().toDateString());
  private weekEnd: Date = new Date(getDate.weekEnd().toDateString());
  private monthStart: Date = new Date(getDate.monthStart().toDateString());
  private monthEnd: Date = new Date(getDate.monthEnd().toDateString());
  private lastMonthStart: Date = new Date(getDate.lastMonthStart().toDateString());
  private lastMonthEnd: Date = new Date(getDate.lastMonthEnd().toDateString());
  private lastYearStart: Date = new Date(getDate.lastYearStart().toDateString());
  private lastYearEnd: Date = new Date(getDate.lastYearEnd().toDateString());

  private presets: PresetsModel[] = [
    { label: this.props.i18n.t('home.dateRange.today'), start: this.today, end: this.today },
    { label: this.props.i18n.t('home.dateRange.thisWeek'), start: this.weekStart, end: this.weekEnd },
    { label: this.props.i18n.t('home.dateRange.thisMonth'), start: this.monthStart, end: this.monthEnd },
    { label: this.props.i18n.t('home.dateRange.lastMonth'), start: this.lastMonthStart, end: this.lastMonthEnd },
    { label: this.props.i18n.t('home.dateRange.lastYear'), start: this.lastYearStart, end: this.lastYearEnd }
  ];

  public render(): JSX.Element {
    return (
      <DateRangeBase
        name={this.props.name}
        label={this.props.label}
        value={this.props.value}
        i18n={this.props.i18n}
        placeholder={this.props.placeholder || ''}
        onChangeInput={this.props.onChangeInput}
        options={this.presets}
      />
    );
  }
}

export default DateRange;
