import React, { Component, Fragment } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Moment } from 'moment';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Sort,
  Page,
  Toolbar,
  ExcelExport,
  Resize,
  DetailRow,
  GridModel,
  RowDataBoundEventArgs,
  SortSettingsModel,
  PagerComponent,
  SortEventArgs,
  Reorder
} from '@syncfusion/ej2-react-grids';
import { ItemModel, ClickEventArgs } from '@syncfusion/ej2-navigations';
import { notifyError, notifySuccess } from '../../../utils/toast.utils';
import { IErrors } from '../../../common/model/errors.model';
import { reportsService, userSettingsService } from '../../../services';

import { IReportsGridProps } from '../../../interfaces/props/IReportsListProps';
import { EXCEL_EXPORT_ACTION_ID } from '../../../common/constants/gridActionId.constants';
import { IReportWithFlattenedCustomFields } from '../../../common/model/report.model';
import { ReportsListMode } from '../../../common/model/enumerations/reportsListMode.model';
import { ReportStatus } from '../../../common/model/enumerations/reportStatus.model';
import * as gridUtils from '../../../utils/grid.utils';
import * as pagerUtils from '../../../utils/pager.utils';
import * as reportStatusUtils from '../../../utils/reportStatus.utils';
import * as reportUtils from '../../../utils/report.utils';
import * as arrayUtils from '../../../utils/array.utils';
import * as sortUtils from '../../../utils/sort.utils';
import * as stringUtils from '../../../utils/string.utils';
import * as dateHelpers from '../../../helpers/date.helpers';
import ReportsGridActionsColumn from './reportsGridActionsColumn';
import './reportsGrid.scss';
import { IOrderColumn } from '../../../interfaces/IOrderColumn';
import * as userSettings from '../../../utils/userSetting.utils';

interface IRequestAdvanceModalState {
  reportId?: string;
  reportNumber?: string;
  showModal: boolean;
  requestAdvanceEmail: string;
}

type ReportsGridPropsType = IReportsGridProps & RouteComponentProps & WithTranslation;

class ReportsGrid extends Component<ReportsGridPropsType, IRequestAdvanceModalState> {
  public gridComponent: GridComponent = null;
  public toolbarOptions: ItemModel[] = [];
  public sortOptions: SortSettingsModel = null;
  public childGrid: GridModel = null;
  public pagerComponent: PagerComponent = null;
  public columns: IOrderColumn[] = null;

  private readonly DOWNLOAD_SELECTED_REPORTS_ACTION_ID = 'downloadSelectedReports';
  private readonly SAVE_COLUMNS_ACTION_ID = 'saveColumnsReports';
  private readonly CLEAN_COLUMNS_ACTION_ID = 'cleanColumnsReports';

  public constructor(props: ReportsGridPropsType) {
    super(props);

    const { reports, sortField, sortDirection, i18n } = this.props;

    this.toolbarOptions = [
      {
        tooltipText: i18n.t('syncfusion.grid.Excelexport'),
        id: EXCEL_EXPORT_ACTION_ID,
        prefixIcon: 'icon icon-file-excel-o'
      },
      {
        tooltipText: i18n.t('grid.customButtons.saveColumns'),
        id: this.SAVE_COLUMNS_ACTION_ID,
        prefixIcon: 'icon icon-app-item-save'
      },
      {
        tooltipText: i18n.t('grid.customButtons.cleanColumns'),
        id: this.CLEAN_COLUMNS_ACTION_ID,
        prefixIcon: 'icon icon-tuning',
        align: 'Right'
      }
    ];

    this.sortOptions = {
      columns: [{ field: sortField, direction: sortDirection }]
    };

    this.childGrid = {
      dataSource: reports,
      queryString: 'id',
      columns: [
        { field: 'reference', headerText: i18n.t('report.reference'), disableHtmlEncode: false }
      ]
    };

    this.state = {
      reportId: null,
      reportNumber: null,
      showModal: false,
      requestAdvanceEmail: props.currentUserEmail
    };
  }

  public componentDidUpdate(prevProps: ReportsGridPropsType) {
    gridUtils.manageSpinner(this.gridComponent, prevProps.isFetching, this.props.isFetching);
    gridUtils.manageSorting(this.gridComponent, prevProps.isFetching, this.props.isFetching, this.props.sortField, this.props.sortDirection);
  }

  public async componentDidMount() {
    const { currentUserId } = this.props;

    if (this.columns === undefined || this.columns == null || this.columns.length === 0) {
      gridUtils.manageSpinner(this.gridComponent, false, true);
      this.columns = gridUtils.getColumnsGridFromLocalStorage(currentUserId, this.gridComponent);

      if (this.columns === undefined || this.columns == null || this.columns.length === 0) {
        const result = await userSettingsService.getUserSetting(this.props.currentUserId, userSettings.getKeyToUserSettingFromlocalStorageKey(currentUserId, gridUtils.getKeyToLocalStorage(currentUserId, this.gridComponent), gridUtils.gridLocalStorageKeySeparator));

        if (result !== undefined && result != null && (result as any != '')) {
          this.columns = JSON.parse(result.jsonValue) as IOrderColumn[];
          gridUtils.saveColumnsGridInLocalStorage(currentUserId, this.gridComponent, this.columns);
        }
      }

      gridUtils.manageSpinner(this.gridComponent, true, false);
    }
  }

  public async onToolbarClick(args: ClickEventArgs) {
    const { currentUserId } = this.props;

    if (args.item.id === EXCEL_EXPORT_ACTION_ID) {
      this.gridComponent.excelExport();
    } else if (args.item.id === this.DOWNLOAD_SELECTED_REPORTS_ACTION_ID) {
      this.props.onDownloadMultipleButtonClick();
    } else if (args.item.id === this.SAVE_COLUMNS_ACTION_ID) {
      this.props.onSaveColumnsButtonClick(userSettings.getInfoToSaveUserSetting(currentUserId, gridUtils.getKeyToLocalStorage(currentUserId, this.gridComponent), gridUtils.gridLocalStorageKeySeparator, this.columns));
    } else if (args.item.id === this.CLEAN_COLUMNS_ACTION_ID) {
      this.props.onCleanColumnsButtonClick(userSettings.getKeyToUserSettingFromlocalStorageKey(currentUserId, gridUtils.getKeyToLocalStorage(currentUserId, this.gridComponent), gridUtils.gridLocalStorageKeySeparator));
    }
  }

  public onGridComponentDataBound() {
    const { currentPage, pageSize, totalCount, searchDate, i18n } = this.props;

    pagerUtils.refreshPager(this.pagerComponent, currentPage, pageSize, totalCount, searchDate, i18n);
  }

  public onGridComponentActionBegin(args: SortEventArgs) {
    if (args.requestType === 'sorting') {
      if (args.columnName === 'entryDate') {
        this.gridComponent.getColumnByField('entryDate').sortComparer = (reference, comparer) =>
          sortUtils.gridDateSortComparer(reference as unknown as Moment, comparer as unknown as Moment, args.direction);
      } else if (args.columnName === 'expectedDate') {
        this.gridComponent.getColumnByField('expectedDate').sortComparer = (reference, comparer) =>
          sortUtils.gridDateSortComparer(reference as unknown as Moment, comparer as unknown as Moment, args.direction);
      } else if (args.columnName === 'endDate') {
        this.gridComponent.getColumnByField('endDate').sortComparer = (reference, comparer) =>
          sortUtils.gridDateSortComparer(reference as unknown as Moment, comparer as unknown as Moment, args.direction);
      } else if (args.columnName === 'expirationDate') {
        this.gridComponent.getColumnByField('expirationDate').sortComparer = (reference, comparer) =>
          sortUtils.gridDateSortComparer(reference as unknown as Moment, comparer as unknown as Moment, args.direction);
      } else {
        this.gridComponent.getColumnByField(args.columnName).sortComparer = (reference, comparer) =>
          sortUtils.gridSortComparer(reference, comparer, args.direction);
      }

      gridUtils.manageSortingChange(args, this.props.sortField, this.props.sortDirection, this.props.onSortingChange);
    }
  }

  public onGridComponentActionComplete(args: any) {
    if (args.requestType === 'refresh') {
      gridUtils.orderColumns(this.props.currentUserId, this.gridComponent);
    } else if (args.requestType === 'reorder') {
      this.onChangeColumns();
    }
  }

  public onChangeColumns() {
    this.columns = gridUtils.mapToLocalStorage(this.gridComponent.columns);
    gridUtils.saveColumnsGridInLocalStorage(this.props.currentUserId, this.gridComponent, this.columns);
  }

  public onRowDataBound(args: RowDataBoundEventArgs) {
    gridUtils.manageDetailRowExpansion(this.gridComponent, args, !!(args.data as IReportWithFlattenedCustomFields).reference, true);
  }

  public getReportNumberColumnTemplate(report: IReportWithFlattenedCustomFields): JSX.Element {
    const { onReportNumberClick } = this.props;

    if (onReportNumberClick !== undefined && onReportNumberClick !== null) {
      return <span className='e-link' onClick={() => {
        this.props.history.push(`/report/${report.id}/view`);
        onReportNumberClick();
      }}>{report.reportNumber}</span>;
    }

    return <span className='e-link' onClick={() => this.props.history.push(`/report/${report.id}/view`)}>{report.reportNumber}</span>;
  }

  public getVersionColumnTemplate(report: IReportWithFlattenedCustomFields): JSX.Element {
    return (
      <span
        className='e-link'
        onClick={() => {
          this.props.onVersionClick(report);
        }}
      >
        {report.version}
      </span>
    );
  }

  public shouldShowClientColumn(): boolean {
    return (this.props.isCurrentUserInternal || this.props.hasCurrentUserChain) && this.props.listMode !== ReportsListMode.ECI;
  }

  public shouldShowRequerimentStandardColumn(): boolean {
    return this.props.listMode !== ReportsListMode.ECI && !this.props.isEciUser;
  }

  public getDateLabel(report: IReportWithFlattenedCustomFields, fieldName: string): string {
    const date = (report as any)[fieldName];
    return date && dateHelpers.toShortDate(date, true);
  }

  public shouldShowEntryDateColumn(): boolean {
    return this.props.listMode === ReportsListMode.IN_PROGRESS || this.props.isEciUser;
  }

  public shouldShowExpectedDateColumn(): boolean {
    return this.props.listMode === ReportsListMode.IN_PROGRESS && !this.props.isEciUser;
  }

  public shouldShowEndDateColumn(): boolean {
    return this.props.listMode === ReportsListMode.FINALIZED || this.props.isEciUser;
  }

  public shouldShowExpirationDateColumn(): boolean {
    return this.props.listMode === ReportsListMode.FINALIZED && !this.props.isEciUser;
  }

  public getStatusColumnTemplate(report: IReportWithFlattenedCustomFields): JSX.Element {
    const { i18n } = this.props;

    if (report.totalServices === 0) {
      return <Fragment>
        <span title={reportStatusUtils.getLocalizedText(i18n, report.status)} className={reportStatusUtils.getIconCss(report.status)} />
      </Fragment>;
    }

    return <Fragment>
      <span title={reportStatusUtils.getLocalizedText(i18n, report.status)} className={reportStatusUtils.getIconCss(report.status)}/>
      {
        report.status === ReportStatus.IN_PROGRESS && 
        <span title={`(${i18n.t('reportsList.serviceStatusLabel.totalAnalyzedServices')}/${i18n.t('reportsList.serviceStatusLabel.totalServices')})`}>
          {` (${report.totalAnalyzedServices}/${report.totalServices})`}
        </span>
      }
    </Fragment>;
  }

  public shouldShowCustomFieldColumn(customFieldCode: string): boolean {
    const { listMode, reportCustomFieldSettings } = this.props;

    const settings = reportCustomFieldSettings.find((s) => s.customFieldCode === customFieldCode);

    if (!settings) {
      return false;
    }

    switch (listMode) {
      case ReportsListMode.ALL:
        return settings.listAll;
      case ReportsListMode.IN_PROGRESS:
        return settings.listInProgress;
      case ReportsListMode.FINALIZED:
        return settings.listFinalized;
      default:
        return false;
    }
  }

  public shouldShowModelColumn(): boolean {
    return this.props.isEciUser || this.shouldShowCustomFieldColumn('model');
  }

  public shouldShowIncidenceColumn(): boolean {
    return this.props.isEciUser || this.shouldShowCustomFieldColumn('incidence');
  }

  public shouldShowClosedColumn(): boolean {
    return this.props.isEciUser && this.props.listMode !== ReportsListMode.ECI;
  }

  public shouldShowEciOrderNumberColumn(): boolean {
    return this.props.isEciUser && this.props.listMode !== ReportsListMode.ECI;
  }

  public shouldShowCopyColumn(): boolean {
    return this.props.listMode === ReportsListMode.ECI;
  }

  public shouldShowAitexResultIdColumn(): boolean {
    return this.props.isEciUser || this.shouldShowCustomFieldColumn('aitexResultId');
  }

  public shouldShowSeasonIdColumn(): boolean {
    return this.props.isEciUser || this.shouldShowCustomFieldColumn('seasonId');
  }

  public shouldShowSectionIdColumn(): boolean {
    return this.props.isEciUser && this.props.listMode !== ReportsListMode.ECI;
  }

  public getActionsColumnTemplate(report: IReportWithFlattenedCustomFields, onRequestAdvanceClick: (reportId: string, reportNumber: string) => Promise<void>): JSX.Element {
    const { onDownloadButtonClick } = this.props;

    return (
      <ReportsGridActionsColumn
        showDownloadButton={report.status === ReportStatus.FINALIZED && report.fileName && report.resultIsVisible && report.published}
        onIsSelectedChange={(isSelected) => this.onReportIsSelectedChange(report, isSelected)}
        onDownloadButtonClick={() => onDownloadButtonClick(report)}
        showRequestAdvanceButton={reportUtils.canRequestAdvance(report)}
        onRequestAdvanceClick={() => onRequestAdvanceClick(report.id, report.reportNumber)}
      />
    );
  }

  public onPagerComponentClick(e: any) {
    if (e.currentPage !== this.props.currentPage) {
      this.props.onCurrentPageChange(e.currentPage);
    }
  }

  public onPageSizesDropdownChange(e: any) {
    if (e.pageSize !== this.props.pageSize) {
      this.props.onPageSizeChange(e.pageSize);
    }
  }

  public render(): JSX.Element {
    const { reports, selectedReportsIds, isEciUser, gridRef, i18n, currentUserId } = this.props;

    const downloadSelectedReportsActionIndex = this.toolbarOptions.findIndex((option) => (option).id === this.DOWNLOAD_SELECTED_REPORTS_ACTION_ID);
    if (selectedReportsIds.length > 0 && downloadSelectedReportsActionIndex < 0) {
      this.toolbarOptions = arrayUtils.insertItem(this.toolbarOptions, this.computeDownloadSelectedReportsActionItemModel());
    }
    if (selectedReportsIds.length === 0 && downloadSelectedReportsActionIndex >= 0) {
      this.toolbarOptions = arrayUtils.removeItem(this.toolbarOptions, downloadSelectedReportsActionIndex);
    }

    if (this.gridComponent && this.gridComponent.childGrid) {
      this.gridComponent.childGrid.dataSource = reports.filter((report) => !!report.reference);
    }

    gridUtils.setWidthToColumns(currentUserId, this.gridComponent, this.columns);

    return (
      <div className='reports-grid'>
        <GridComponent
          created={() => gridUtils.orderColumns(currentUserId, this.gridComponent)}
          dataSource={reports}
          childGrid={this.childGrid}
          toolbar={this.toolbarOptions}
          allowSorting={true}
          allowPaging={true}   /* Si no se incluye este paginador (oculto por css), no se muestra el dropdown en el customizado */
          allowExcelExport={true}
          allowReordering={true}
          allowResizing={true}
          sortSettings={this.sortOptions}
          pageSettings={{ pageSize: pagerUtils.getMaxPageSize() }}
          clipMode='EllipsisWithTooltip'
          locale={i18n.language}
          ref={(grid: GridComponent) => { this.gridComponent = grid; gridRef(grid); }}
          toolbarClick={(args) => this.onToolbarClick(args)}
          dataBound={() => this.onGridComponentDataBound()}
          actionBegin={(args) => this.onGridComponentActionBegin(args as SortEventArgs)}
          actionComplete={(args) => this.onGridComponentActionComplete(args as any)}
          resizeStop={() => this.onChangeColumns()}
          rowDataBound={(args) => this.onRowDataBound(args)}
          height='100%'
          width='100%'
          id='reportsGrid'
        >
          <ColumnsDirective>
            <ColumnDirective
              headerText={i18n.t('report.reportNumber')}
              field='reportNumber'
              isPrimaryKey={true}
              width='150'
              template={(report: IReportWithFlattenedCustomFields) => this.getReportNumberColumnTemplate(report)}
              lockColumn={true}
            />
            <ColumnDirective
              headerText={i18n.t('report.version')}
              field='version'
              width='75'
              template={(report: IReportWithFlattenedCustomFields) => this.getVersionColumnTemplate(report)}
            />
            {this.shouldShowRequerimentStandardColumn() && <ColumnDirective
              headerText={i18n.t('report.requerimentStandard')}
              field='requerimentStandard'
              width='195'
              disableHtmlEncode={false}
            />}
            {this.shouldShowEntryDateColumn() && <ColumnDirective
              headerText={i18n.t('report.entryDate')}
              field='entryDate'
              width='100'
              valueAccessor={(_field, data) => this.getDateLabel(data as IReportWithFlattenedCustomFields, 'entryDate')}
            />}
            {this.shouldShowExpectedDateColumn() && <ColumnDirective
              headerText={i18n.t('report.expectedDate')}
              field='expectedDate'
              width='110'
              valueAccessor={(_field, data) => this.getDateLabel(data as IReportWithFlattenedCustomFields, 'expectedDate')}
            />}
            {this.shouldShowEndDateColumn() && <ColumnDirective
              headerText={i18n.t('report.endDate')}
              field='endDate'
              width='100'
              valueAccessor={(_field, data) => this.getDateLabel(data as IReportWithFlattenedCustomFields, 'endDate')}
            />}
            {this.shouldShowExpirationDateColumn() && <ColumnDirective
              headerText={i18n.t('report.expirationDate')}
              field='expirationDate'
              width='100'
              valueAccessor={(_field, data) => this.getDateLabel(data as IReportWithFlattenedCustomFields, 'expirationDate')}
            />}
            <ColumnDirective
              headerText={i18n.t('report.status')}
              textAlign='Center'
              field='status'
              width='110'
              template={(report: IReportWithFlattenedCustomFields) => this.getStatusColumnTemplate(report)}
            />
            {this.shouldShowAitexResultIdColumn() && <ColumnDirective
              headerText={i18n.t('reportCustomFields.aitexResultId')}
              field='aitexResultName'
              width='100'
              disableHtmlEncode={false}
            />}
            {this.shouldShowIncidenceColumn() && <ColumnDirective
              headerText={i18n.t('reportCustomFields.incidence')}
              field='incidence'
              width='100'
              disableHtmlEncode={false}
            />}
            {isEciUser && <ColumnDirective
              headerText={i18n.t('reportCustomFields.qualityControlId')}
              field='qualityControlName'
              width='100'
              disableHtmlEncode={false}
            />}
            {isEciUser && <ColumnDirective
              headerText={i18n.t('reportCustomFields.qualityControlDate')}
              field='qualityControlDate'
              width='100'
              valueAccessor={(_field, data) => this.getDateLabel(data as IReportWithFlattenedCustomFields, 'qualityControlDate')}
            />}
            {isEciUser && <ColumnDirective
              headerText={i18n.t('reportCustomFields.buyerId')}
              field='buyerName'
              width='100'
              disableHtmlEncode={false}
            />}
            {this.shouldShowClosedColumn() && <ColumnDirective
              headerText={i18n.t('reportCustomFields.closed')}
              field='closed'
              width='80'
              type='boolean'
              displayAsCheckBox={true}
            />}
            {this.shouldShowSeasonIdColumn() && <ColumnDirective
              headerText={i18n.t('reportCustomFields.seasonId')}
              field='seasonName'
              width='100'
              disableHtmlEncode={false}
            />}
            {this.shouldShowClientColumn() && <ColumnDirective
              headerText={i18n.t('report.client')}
              field='client'
              width='350'
              disableHtmlEncode={false}
            />}
            {this.shouldShowEciOrderNumberColumn() && <ColumnDirective
              headerText={i18n.t('reportCustomFields.eciOrderNumber')}
              field='eciOrderNumber'
              width='100'
              disableHtmlEncode={false}
            />}
            {this.shouldShowModelColumn() && <ColumnDirective
              headerText={i18n.t('reportCustomFields.model')}
              field='model'
              width='100'
              disableHtmlEncode={false}
            />}
            {isEciUser && <ColumnDirective
              headerText={i18n.t('reportCustomFields.divisionId')}
              field='divisionName'
              width='100'
              disableHtmlEncode={false}
            />}
            {isEciUser && <ColumnDirective
              headerText={i18n.t('reportCustomFields.uneco')}
              field='uneco'
              width='100'
              disableHtmlEncode={false}
            />}
            {this.shouldShowSectionIdColumn() && <ColumnDirective
              headerText={i18n.t('reportCustomFields.sectionId')}
              field='sectionName'
              width='100'
              disableHtmlEncode={false}
            />}
            {isEciUser && <ColumnDirective
              headerText={i18n.t('reportCustomFields.officeId')}
              field='officeName'
              width='100'
              disableHtmlEncode={false}
            />}
            {isEciUser && <ColumnDirective
              headerText={i18n.t('reportCustomFields.brandId')}
              field='brandName'
              width='100'
              disableHtmlEncode={false}
            />}
            {isEciUser && <ColumnDirective
              headerText={i18n.t('reportCustomFields.supplier')}
              field='supplier'
              width='100'
              disableHtmlEncode={false}
            />}
            {this.shouldShowCustomFieldColumn('collection') && <ColumnDirective
              headerText={i18n.t('reportCustomFields.collection')}
              field='collection'
              width='100'
              disableHtmlEncode={false}
            />}
            {this.shouldShowCustomFieldColumn('colour') && <ColumnDirective
              headerText={i18n.t('reportCustomFields.colour')}
              field='colour'
              width='100'
              disableHtmlEncode={false}
            />}
            {this.shouldShowCustomFieldColumn('target') && <ColumnDirective
              headerText={i18n.t('reportCustomFields.target')}
              field='target'
              width='100'
              disableHtmlEncode={false}
            />}
            {this.shouldShowCopyColumn() && <ColumnDirective
              headerText={i18n.t('reportCustomFields.copy')}
              field='copy'
              width='80'
              type='boolean'
              displayAsCheckBox={true}
            />}
            {this.shouldShowCustomFieldColumn('countryId') && <ColumnDirective
              headerText={i18n.t('reportCustomFields.countryId')}
              field='countryName'
              width='100'
              disableHtmlEncode={false}
            />}
            <ColumnDirective
              headerText={i18n.t('syncfusion.grid.Actions')}
              template={(report: IReportWithFlattenedCustomFields) => this.getActionsColumnTemplate(report, this.sendRequestAdvance)}
            />
          </ColumnsDirective>
          <Inject services={[Sort, Page, Toolbar, ExcelExport, Resize, DetailRow, Reorder]} />
        </GridComponent>
        {reports.length ?
          <PagerComponent
            pageSize={pagerUtils.getDefaultPageSize()}
            pageCount={pagerUtils.getPageCount()}
            pageSizes={pagerUtils.getPageSizes()}
            ref={(pagerComponent: PagerComponent): PagerComponent => this.pagerComponent = pagerComponent}
            click={(e) => this.onPagerComponentClick(e)}
            dropDownChanged={(e) => this.onPageSizesDropdownChange(e)}
          />
          :
          null
        }
      </div>
    );
  }

  private sendRequestAdvance = async (reportId: string, reportNumber: string): Promise<void> => {
    try {
      await reportsService.requestAdvance(reportId, this.state.requestAdvanceEmail);
      notifySuccess(stringUtils.format(this.props.i18n.t('detail.requestAdvanceSuccess'), [reportNumber]));
    } catch (error) {
      const errors = error as IErrors;
      if (errors.AdvanceEmails) {
        notifyError(this.props.i18n.t(`apiErrors.${errors.AdvanceEmails[0]}`));
        return;
      }
      if (errors.ControlledError) {
        notifyError(errors.ControlledError[0]);
        return;
      }
      if (errors.UnknownError || (!errors.UnknownError && !errors.ControlledError)) {
        notifyError(this.props.i18n.t('detail.requestAdvanceError'));
        return;
      }
    }
  }

  private computeDownloadSelectedReportsActionItemModel(): ItemModel {
    return {
      tooltipText: this.props.i18n.t('reportsList.actions.downloadSelectedReports'),
      id: this.DOWNLOAD_SELECTED_REPORTS_ACTION_ID,
      prefixIcon: 'icon-app-file-download'
    };
  }

  private onReportIsSelectedChange(report: IReportWithFlattenedCustomFields, isSelected: boolean): void {
    const { selectedReportsIds, onSelectedReportsIdsChange } = this.props;

    let newSelectedReportsIds = selectedReportsIds;
    const index = selectedReportsIds.findIndex((selectedReportId) => selectedReportId === report.id);
    if (isSelected && index === -1) {
      newSelectedReportsIds = arrayUtils.insertItem(newSelectedReportsIds, report.id);
    }
    if (!isSelected && index > -1) {
      newSelectedReportsIds = arrayUtils.removeItem(newSelectedReportsIds, index);
    }

    onSelectedReportsIdsChange(newSelectedReportsIds);
  }
}

export default withRouter(withTranslation()(ReportsGrid));
