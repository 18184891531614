import React, { Component, ComponentType } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { compose } from 'redux';
import { Tabs, Tab } from 'react-bootstrap';
import { DataManager, Query } from '@syncfusion/ej2-data';
import { SortDirection } from '@syncfusion/ej2-grids';

import { RootState } from '../../store';
import { INotificationsViewStatusListContainerStateProps, INotificationsViewStatusListContainerDispatchProps } from '../../interfaces/props/INotificationsViewStatusListProps';
import * as notificationsListStore from '../../store/modules/notificationsList.store';
import * as notificationsListNotificationsViewStatusStore from '../../store/modules/notificationsListNotificationsViewStatus.store';
import * as listGridStore from '../../store/modules/listGrid.store';
import { URL } from '../../common/constants';
import { IMyRule } from '../../common/model/myRule.model';
import { NotificationScope } from '../../common/model/enumerations/notificationScope.model';
import { BooleanFilter } from '../../common/model/enumerations/booleanFilter.model';
import { RuleCondition } from '../../common/model/enumerations/ruleCondition.model';
import { RuleOperator } from '../../common/model/enumerations/ruleOperator.model';
import { FilterMode } from '../../common/model/enumerations/filterMode.model';
import { queryBuilderService, oDataService, dataManagerService } from '../../services';
import NotificationsListFilter from './notificationsListFilter/notificationsListFilter';
import NotificationsGrid from './notificationsGrid/notificationsGrid';

type NotificationsViewStatusListContainerPropsType = PropsFromRedux & RouteComponentProps & WithTranslation;

class NotificationsViewStatusListContainer extends Component<NotificationsViewStatusListContainerPropsType> {
  public notificationUsersDataManager: DataManager = null;
  public notificationUsersQuery: Query = new Query()
    .select(['id', 'notificationId', 'subject', 'scope', 'createdDate', 'deletedDate', 'companyName', 'userId', 'userEmail', 'viewDate'])
    .expand(['notificationsReports', 'notificationsRequests']);

  public companiesDataManager: DataManager = null;

  public constructor(props: NotificationsViewStatusListContainerPropsType) {
    super(props);

    const { reset } = this.props;

    this.notificationUsersDataManager = dataManagerService.buildDataManager(URL.ODATA.NOTIFICATION_USER_FILTERS);

    this.companiesDataManager = dataManagerService.buildDataManager(URL.ODATA.COMPANY_FILTERS);

    reset(notificationsListStore.NotificationsViewStatusListType.RESET);
  }

  public componentWillUnmount() {
    this.props.reset(notificationsListStore.NotificationsViewStatusListType.RESET);
  }

  public onFilterSubjectChange(subject: string) {
    this.props.setFilterSubject(notificationsListStore.NotificationsViewStatusListType.SET_FILTER_SUBJECT, subject);
  }

  public onFilterScopeChange(scope: NotificationScope) {
    this.props.setFilterScope(notificationsListStore.NotificationsViewStatusListType.SET_FILTER_SCOPE, scope);
  }

  public onFilterActiveChange(active: BooleanFilter) {
    this.props.setFilterActive(notificationsListStore.NotificationsViewStatusListType.SET_FILTER_ACTIVE, active);
  }

  public onFilterCompaniesIdsChange(companiesIds: string[]) {
    this.props.setFilterCompaniesIds(notificationsListStore.NotificationsViewStatusListType.SET_FILTER_COMPANIES_IDS, companiesIds);
  }

  public onFilterEmailChange(email: BooleanFilter) {
    this.props.setFilterEmail(notificationsListStore.NotificationsViewStatusListType.SET_FILTER_EMAIL, email);
  }

  public onFilterButtonClick() {
    this.props.setCurrentPage(notificationsListStore.NotificationsViewStatusListType.SET_CURRENT_PAGE, 1);
    this.fetchNotificationUsers();
  }

  public onCurrentPageChange(currentPage: number) {
    this.props.setCurrentPage(notificationsListStore.NotificationsViewStatusListType.SET_CURRENT_PAGE, currentPage);
    this.fetchNotificationUsers();
  }

  public onPageSizeChange(pageSize: number) {
    this.props.setPageSize(notificationsListStore.NotificationsViewStatusListType.SET_PAGE_SIZE, pageSize);
    this.fetchNotificationUsers();
  }

  public onSortingChange(field: string, direction: SortDirection) {
    this.props.setSorting(notificationsListStore.NotificationsViewStatusListType.SET_SORTING, field, direction);
    this.fetchNotificationUsers();
  }

  public render() {
    const { notificationUsers, filter, isFetching, searchDate, currentPage, pageSize, totalCount, sortField, sortDirection, i18n } = this.props;

    return (
      <div>
        <div className='row'>
          <div className='col'>
            <h2>{i18n.t('notificationsViewStatusList.title')}</h2>
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <div className='mb-3'>
              <Tabs id='notificationsViewStatusListTabs' className='mb-3'>
                <Tab eventKey={FilterMode.SIMPLE} title={i18n.t('enums.filterMode.SIMPLE')}>
                  <NotificationsListFilter
                    filter={filter}
                    isFetching={isFetching}
                    companiesDataManager={this.companiesDataManager}
                    onSubjectChange={(subject) => this.onFilterSubjectChange(subject)}
                    onScopeChange={(scope) => this.onFilterScopeChange(scope)}
                    onActiveChange={(active) => this.onFilterActiveChange(active)}
                    onCompaniesIdsChange={(companiesIds) => this.onFilterCompaniesIdsChange(companiesIds)}
                    onEmailChange={(email) => this.onFilterEmailChange(email)}
                    onFilterButtonClick={() => this.onFilterButtonClick()}
                  />
                </Tab>
              </Tabs>
            </div>
            <NotificationsGrid
              notifications={notificationUsers}
              isViewStatus={true}
              isFetching={isFetching}
              searchDate={searchDate}
              currentPage={currentPage}
              pageSize={pageSize}
              totalCount={totalCount}
              sortField={sortField}
              sortDirection={sortDirection}
              onCurrentPageChange={(cp) => this.onCurrentPageChange(cp)}
              onPageSizeChange={(ps) => this.onPageSizeChange(ps)}
              onSortingChange={(field, direction) => this.onSortingChange(field, direction)}
            />
          </div>
        </div>
      </div>
    );
  }

  private fetchNotificationUsers() {
    const { isFetching, fetchNotificationUsers } = this.props;

    if (!isFetching) {
      fetchNotificationUsers(oDataService, this.notificationUsersDataManager, this.buildQuery());
    }
  }

  private buildQuery(): Query {
    const { filter } = this.props;

    const myRule: IMyRule = {
      condition: RuleCondition.AND,
      myRules: []
    };
    if (filter.subject) {
      myRule.myRules.push({
        field: 'subject',
        operator: RuleOperator.CONTAINS,
        value: filter.subject,
        ignoreCase: true
      });
    }
    if (filter.scope !== NotificationScope.ALL) {
      myRule.myRules.push({
        field: 'scope',
        operator: RuleOperator.EQUAL,
        value: filter.scope
      });
    }
    if (filter.active !== BooleanFilter.ALL) {
      myRule.myRules.push({
        field: 'deletedDate',
        operator: filter.active === BooleanFilter.YES ? RuleOperator.EQUAL : RuleOperator.NOT_EQUAL,
        value: null
      });
    }
    if (filter.companiesIds.length) {
      myRule.myRules.push({
        field: 'companyId',
        operator: RuleOperator.IN,
        value: filter.companiesIds
      });
    }
    if (filter.email !== BooleanFilter.ALL) {
      myRule.myRules.push({
        field: 'emailDestinations',
        operator: filter.email === BooleanFilter.YES ? RuleOperator.NOT_EQUAL : RuleOperator.EQUAL,
        value: null
      });
    }

    return queryBuilderService.buildQuery(this.notificationUsersDataManager, myRule, this.notificationUsersQuery.clone());
  }
}

const mapStateToProps = (state: RootState): INotificationsViewStatusListContainerStateProps => ({
  notificationUsers: state.notificationsListNotificationsViewStatusStore.notificationUsers,
  filter: state.notificationsViewStatusListStore.filter,
  isFetching: state.notificationsViewStatusListStore.grid.isFetching,
  searchDate: state.notificationsViewStatusListStore.grid.searchDate,
  currentPage: state.notificationsViewStatusListStore.grid.currentPage,
  pageSize: state.notificationsViewStatusListStore.grid.pageSize,
  totalCount: state.notificationsViewStatusListStore.grid.totalCount,
  sortField: state.notificationsViewStatusListStore.grid.sortField,
  sortDirection: state.notificationsViewStatusListStore.grid.sortDirection
});

const mapDispatchToProps: INotificationsViewStatusListContainerDispatchProps = {
  reset: notificationsListStore.reset,
  setFilterSubject: notificationsListStore.setFilterSubject,
  setFilterScope: notificationsListStore.setFilterScope,
  setFilterActive: notificationsListStore.setFilterActive,
  setFilterCompaniesIds: notificationsListStore.setFilterCompaniesIds,
  setFilterEmail: notificationsListStore.setFilterEmail,
  setCurrentPage: listGridStore.setCurrentPage,
  setPageSize: listGridStore.setPageSize,
  setSorting: listGridStore.setSorting,
  fetchNotificationUsers: notificationsListNotificationsViewStatusStore.fetchNotificationUsers
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default compose(
  withRouter,
  withTranslation(),
  connector
)(NotificationsViewStatusListContainer) as ComponentType;
