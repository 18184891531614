import { URL } from '../common/constants';
import { IUserSetting } from '../common/model/userSetting.model';
import * as services from '.';

export interface IUserSettingsService {
  getUserSetting(userId: string, key: string): Promise<IUserSetting>;
  getUserSettings(userId: string): Promise<IUserSetting[]>;
  refreshUserSetting(setting: IUserSetting): Promise<IUserSetting>;
}

class UserSettingsService implements IUserSettingsService {
  public constructor(private httpClient: services.IHttpClient) {
  }

  public async getUserSetting(userId: string, key: string): Promise<IUserSetting> {
    return await this.httpClient.getCustom(`${URL.WEB.BASE}${URL.WEB.USER_SETTINGS}?userId=${userId}&url=${key}`);
  }

  public async getUserSettings(userId: string): Promise<IUserSetting[]> {
    return await this.httpClient.getCustom(`${URL.WEB.BASE}${URL.WEB.USER_SETTINGS}/${userId}`);
  }

  public async refreshUserSetting(setting: IUserSetting): Promise<IUserSetting> {
    return await this.httpClient.postCustom(`${URL.WEB.BASE}${URL.WEB.USER_SETTINGS}`, setting);
  }
}

export const userSettingsService = new UserSettingsService(services.httpClient);
