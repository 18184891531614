import { Action, Reducer, ActionCreator, combineReducers } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { RootState } from '..';
import { IUserSettingsState } from '../../interfaces/states/IUserSettingState';
import { IUserSetting } from '../../common/model/userSetting.model';
import { IUserSettingsService } from '../../services';

export enum Type {
  SET_USER_SETTINGS = '@@userSettings/SET_USER_SETTINGS',
  SET_USER_SETTING = '@@userSettings/SET_USER_SETTING'
}

const initialState: IUserSettingsState = {
  userSettings: []
};


/////////////
// Actions //
/////////////

export type Actions = SetUserSettingsAction | SetUserSettingAction;

export interface SetUserSettingsAction extends Action<Type.SET_USER_SETTINGS> {
  userSettings: IUserSetting[];
}

export interface SetUserSettingAction extends Action<Type.SET_USER_SETTING> {
  userSetting: IUserSetting;
}

export const setUserSettingsAction: ActionCreator<SetUserSettingsAction> = (userSettingsLocal: IUserSetting[]) => ({
  type: Type.SET_USER_SETTINGS,
  userSettings: userSettingsLocal
});

export const fetchUserSettings: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (
  userId: string,
  userSettingsService: IUserSettingsService
) => (
  async (dispatch) => {
    const userSettingsResponse = await userSettingsService.getUserSettings(userId);
    const userSettingsLocal = userSettingsResponse.map((userSetting: IUserSetting) => userSetting);

    dispatch(setUserSettingsAction(userSettingsLocal));
  }
);

export const setUserSettingAction: ActionCreator<SetUserSettingAction> = (userSettingLocal: IUserSetting) => ({
  type: Type.SET_USER_SETTING,
  userSetting: userSettingLocal
});

export const saveUserSetting: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (
  userSetting: IUserSetting
) => (
  async (dispatch) => {
    dispatch(setUserSettingAction(userSetting));
  }
);

//////////////
// Reducers //
//////////////

const userSettings: Reducer<IUserSetting[]> = (state = initialState.userSettings, action) => {
  switch (action.type) {
    case Type.SET_USER_SETTINGS:
      return (action as SetUserSettingsAction).userSettings;
    case Type.SET_USER_SETTING: {
      const userSetting = (action as SetUserSettingAction).userSetting;
      let userSettings = state.filter(value => (value as any) === undefined || (value as any) == null);
      if (userSettings.some(u => u.id === userSetting.id)) {
        const newEmailsNotReceived = userSettings.map((item: IUserSetting) => {
          if (item.id == userSetting.id) {
            return userSetting;
          } else {
            return item;
          }
        });

        userSettings = newEmailsNotReceived;
      } else {
        userSettings.unshift(userSetting);
      }

      return userSettings;
    }
    default:
      return state;
  }
};

export const userSettingStore = combineReducers({
  userSettings
});
