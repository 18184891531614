import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { DropDownListComponent, MultiSelectComponent, ChangeEventArgs, MultiSelectChangeEventArgs, Inject, CheckBoxSelection, FieldSettingsModel } from '@syncfusion/ej2-react-dropdowns';
import { Query } from '@syncfusion/ej2-data';
import { SortOrder } from '@syncfusion/ej2-lists';

import { INotificationsListFilterProps } from '../../../interfaces/props/INotificationsListProps';
import { IStringToAnyDictionary } from '../../../common/model/stringToAnyDictionary.model';
import { NotificationScope } from '../../../common/model/enumerations/notificationScope.model';
import { BooleanFilter } from '../../../common/model/enumerations/booleanFilter.model';
import { enumToArray } from '../../../utils/enum.utils';
import TextBox from '../../../components/filters/textBox';

type NotificationsListFilterPropsType = INotificationsListFilterProps & WithTranslation;

class NotificationsListFilter extends Component<NotificationsListFilterPropsType> {
  public scopeOptions: IStringToAnyDictionary[] = [];
  public activeOptions: IStringToAnyDictionary[] = [];
  public emailOptions: IStringToAnyDictionary[] = [];
  public enumToArrayFields: FieldSettingsModel = { text: 'text', value: 'value' };

  public companiesQuery = new Query().select(['Id', 'Name']).take(10);
  public companiesFields: FieldSettingsModel = { text: 'name', value: 'id' };
  public companiesSortOrder: SortOrder = 'Ascending';

  public constructor(props: NotificationsListFilterPropsType) {
    super(props);

    const { i18n } = this.props;

    this.scopeOptions = enumToArray(NotificationScope, 'number', 'text', 'value').map((item) => ({ ...item, text: i18n.t('enums.notificationScope.' + item.text) }));
    this.activeOptions = enumToArray(BooleanFilter, 'number', 'text', 'value').map((item) => ({ ...item, text: i18n.t('enums.booleanFilter.' + item.text) }));
    this.emailOptions = enumToArray(BooleanFilter, 'number', 'text', 'value').map((item) => ({ ...item, text: i18n.t('enums.booleanFilter.' + item.text) }));
  }

  public onScopeChange(e: ChangeEventArgs) {
    const scope = e.value as NotificationScope;
    this.props.onScopeChange(scope);
  }

  public onActiveChange(e: ChangeEventArgs) {
    const active = e.value as BooleanFilter;
    this.props.onActiveChange(active);
  }

  public onCompaniesIdsChange(e: MultiSelectChangeEventArgs) {
    this.props.onCompaniesIdsChange(e.value as string[]);
  }

  public onEmailChange(e: ChangeEventArgs) {
    const email = e.value as BooleanFilter;
    this.props.onEmailChange(email);
  }

  public render() {
    const {
      filter,
      isFetching,
      companiesDataManager,
      onSubjectChange,
      onFilterButtonClick,
      i18n
    } = this.props;

    return (
      <form onSubmit={(e: React.FormEvent) => { e.preventDefault(); onFilterButtonClick(); }}>
        <div className='row'>
          <div className='col-12 col-md-6'>
            <div className='form-group'>
              <div className='autocomplete-input'>
                <div className='autocomplete-label'>{i18n.t('notification.subject')}</div>
                <TextBox
                  name='subject'
                  value={filter.subject}
                  showClearButton={true}
                  onChange={(value) => onSubjectChange(value)}
                />
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <div className='form-group'>
              <div className='autocomplete-input'>
                <div className='autocomplete-label'>{i18n.t('notification.scope')}</div>
                <DropDownListComponent
                  fields={this.enumToArrayFields}
                  dataSource={this.scopeOptions}
                  value={filter.scope}
                  change={(e) => this.onScopeChange(e)}
                />
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <div className='form-group'>
              <div className='autocomplete-input'>
                <div className='autocomplete-label'>{i18n.t('notificationsList.columns.active')}</div>
                <DropDownListComponent
                  fields={this.enumToArrayFields}
                  dataSource={this.activeOptions}
                  value={filter.active}
                  change={(e) => this.onActiveChange(e)}
                />
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <div className='form-group'>
              <div className='autocomplete-input'>
                <div className='autocomplete-label' style={{ minWidth: 'auto' }}>
                  <span className='icon icon-company' title={i18n.t('notification.company')} />
                </div>
                <MultiSelectComponent
                  query={this.companiesQuery}
                  dataSource={companiesDataManager}
                  fields={this.companiesFields}
                  sortOrder={this.companiesSortOrder}
                  mode='CheckBox'
                  filterType='Contains'
                  showSelectAll={true}
                  selectAllText={i18n.t('actions.selectAll')}
                  unSelectAllText={i18n.t('actions.unselectAll')}
                  value={filter.companiesIds}
                  change={(e) => this.onCompaniesIdsChange(e)}
                >
                  <Inject services={[CheckBoxSelection]} />
                </MultiSelectComponent>
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <div className='form-group'>
              <div className='autocomplete-input'>
                <div className='autocomplete-label'>{i18n.t('notificationsList.columns.email')}</div>
                <DropDownListComponent
                  fields={this.enumToArrayFields}
                  dataSource={this.emailOptions}
                  value={filter.email}
                  change={(e) => this.onEmailChange(e)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='row justify-content-end'>
          <div className='col-auto'>
            <button type='submit' className='btn btn-secondary' title={i18n.t('actions.filter')} disabled={isFetching}>
              {isFetching ?
                <span className='spinner-border spinner-border-sm' />
                :
                <span className='icon icon-app-item-search' />
              }
            </button>
          </div>
        </div>
      </form>
    );
  }
}

export default withTranslation()(NotificationsListFilter);
