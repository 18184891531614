import i18next from 'i18next';

import { INotification } from '../common/model/notification.model';
import { IAdvancedFilter } from '../common/model/advancedFilter.model';
import { NotificationScope } from '../common/model/enumerations/notificationScope.model';

export const computeFromSharedAdvancedFilterNotification = (notification: INotification, advancedFilter: IAdvancedFilter, webUrl: string, i18n: i18next.i18n): INotification => {
  const url = `${webUrl}reports-list/all?advancedFilterShareId=${advancedFilter.shareId}`;
  return {
    ...notification,
    subject: i18n.t('notificationDetail.messages.fromSharedAdvancedFilterSubject'),
    body: i18n.t('notificationDetail.messages.fromSharedAdvancedFilterBody', { url }),
    scope: NotificationScope.COMPANY,
    companyId: advancedFilter.companyId,
    isImportant: true
  }
}

export const computeReportsIds = (notification: INotification): string[] => {
  return notification.notificationsReports.map((notificationReport) => notificationReport.reportId);
}

export const computeRequestsIds = (notification: INotification): string[] => {
  return notification.notificationsRequests.map((notificationRequest) => notificationRequest.requestId);
}

export const computeSendEmail = (notification: INotification): boolean => {
  return !!notification.emailDestinations;
};

export const computeSelectedEmailDestinations = (notification: INotification): string[] => {
  return notification.emailDestinations ? notification.emailDestinations.split(';').filter((email) => email.trim().length > 0) : [];
};
