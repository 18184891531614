import React, { Component, ComponentType } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { compose } from 'redux';
import { DataManager, Query } from '@syncfusion/ej2-data';
import { SortDirection } from '@syncfusion/ej2-grids';
import { Tabs, Tab } from 'react-bootstrap';

import { RootState } from '../../store';
import { IUnecosListContainerStateProps, IUnecosListContainerDispatchProps } from '../../interfaces/props/IUnecosListProps';
import * as unecosListStore from '../../store/modules/unecosList.store';
import * as listGridStore from '../../store/modules/listGrid.store';
import { URL } from '../../common/constants';
import { IMyRule } from '../../common/model/myRule.model';
import { FilterMode } from '../../common/model/enumerations/filterMode.model';
import { RuleCondition } from '../../common/model/enumerations/ruleCondition.model';
import { RuleOperator } from '../../common/model/enumerations/ruleOperator.model';
import { dataManagerService, oDataService, queryBuilderService } from '../../services';
import UnecosListFilter from './unecosListFilter/unecosListFilter';
import UnecosGrid from './unecosGrid/unecosGrid';
import { IUneco } from '../../common/model/uneco.model';

type UnecosListContainerPropsType = PropsFromRedux & RouteComponentProps & WithTranslation;

class UnecosListContainer extends Component<UnecosListContainerPropsType> {
  public unecosDataManager: DataManager = null;
  public unecosQuery: Query = new Query()
    .select([
      'id', 'active', 'code', 'categoryName', 'emails', 'emailCoordinator'
    ]);

  public constructor(props: UnecosListContainerPropsType) {
    super(props);

    this.unecosDataManager = dataManagerService.buildDataManager(URL.ODATA.UNECOS_FILTERS);

    this.props.reset();
  }

  public componentDidMount() {
    this.fetchUnecos();
  }

  public componentWillUnmount() {
    this.props.reset();
  }

  public onFilterButtonClick() {
    this.props.setCurrentPage(unecosListStore.Type.SET_CURRENT_PAGE, 1);
    this.fetchUnecos();
  }

  public onCurrentPageChange(currentPage: number) {
    this.props.setCurrentPage(unecosListStore.Type.SET_CURRENT_PAGE, currentPage);
    this.fetchUnecos();
  }

  public onPageSizeChange(pageSize: number) {
    this.props.setPageSize(unecosListStore.Type.SET_PAGE_SIZE, pageSize);
    this.fetchUnecos();
  }

  public onSortingChange(field: string, direction: SortDirection) {
    this.props.setSorting(unecosListStore.Type.SET_SORTING, field, direction);
    this.fetchUnecos();
  }

  public saveUneco(uneco: IUneco) {
    const { isFetching, saveUneco } = this.props;

    if (!isFetching) {
      saveUneco(uneco);
    }
  }

  public render() {
    const {
      unecos,
      filter,
      isFetching,
      searchDate,
      currentPage,
      pageSize,
      totalCount,
      sortField,
      sortDirection,
      setFilterActive,
      setFilterCode,
      setFilterCategoryName,
      setFilterEmails,
      setFilterEmailCoordinator,
      i18n
    } = this.props;

    return (
      <div>
        <div className='row'>
          <div className='col'>
            <h2>{i18n.t('unecosList.title')}</h2>
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <div className='mb-3'>
              <Tabs id='unecosListTabs' className='mb-3'>
                <Tab eventKey={FilterMode.SIMPLE} title={i18n.t('enums.filterMode.SIMPLE')}>
                  <UnecosListFilter
                    filter={filter}
                    isFetching={isFetching}
                    onActiveChange={setFilterActive}
                    onCodeChange={setFilterCode}
                    onCategoryNameChange={setFilterCategoryName}
                    onEmailsChange={setFilterEmails}
                    onEmailCoordinatorChange={setFilterEmailCoordinator}
                    onFilterButtonClick={() => this.onFilterButtonClick()}
                  />
                </Tab>
              </Tabs>
            </div>
            <UnecosGrid
              unecos={unecos}
              isFetching={isFetching}
              searchDate={searchDate}
              currentPage={currentPage}
              pageSize={pageSize}
              totalCount={totalCount}
              sortField={sortField}
              sortDirection={sortDirection}
              onCurrentPageChange={(cp: number) => this.onCurrentPageChange(cp)}
              onPageSizeChange={(ps: number) => this.onPageSizeChange(ps)}
              onSortingChange={(field: string, direction: SortDirection) => this.onSortingChange(field, direction)}
              saveUneco={(uneco: IUneco) => this.saveUneco(uneco)}
            />
          </div>
        </div>
      </div>
    );
  }

  private fetchUnecos() {
    const { isFetching, fetchUnecos } = this.props;

    if (!isFetching) {
      fetchUnecos(oDataService, this.unecosDataManager, this.buildQuery());
    }
  }

  private buildQuery(): Query {
    const { filter } = this.props;

    const myRule: IMyRule = {
      condition: RuleCondition.AND,
      myRules: []
    };
    if (filter.code) {
      myRule.myRules.push({
        field: 'code',
        operator: RuleOperator.CONTAINS,
        value: filter.code,
        ignoreCase: true
      });
    }
    if (filter.categoryName && filter.categoryName !== 'TODOS') {
      myRule.myRules.push({
        field: 'categoryName',
        operator: RuleOperator.CONTAINS,
        value: filter.categoryName,
        ignoreCase: true
      });
    }
    if (filter.active != null) {
      myRule.myRules.push({
        field: 'active',
        operator: RuleOperator.EQUAL,
        value: filter.active
      });
    }
    if (filter.emails) {
      myRule.myRules.push({
        field: 'emails',
        operator: RuleOperator.CONTAINS,
        value: filter.emails
      });
    }
    if (filter.emailCoordinator) {
      myRule.myRules.push({
        field: 'emailCoordinator',
        operator: RuleOperator.CONTAINS,
        value: filter.emailCoordinator
      });
    }

    return queryBuilderService.buildQuery(this.unecosDataManager, myRule, this.unecosQuery.clone());
  }
}

const mapStateToProps = (state: RootState): IUnecosListContainerStateProps => ({
  unecos: state.unecosListStore.unecos,
  filter: state.unecosListStore.filter,
  isFetching: state.unecosListStore.grid.isFetching,
  searchDate: state.unecosListStore.grid.searchDate,
  currentPage: state.unecosListStore.grid.currentPage,
  pageSize: state.unecosListStore.grid.pageSize,
  totalCount: state.unecosListStore.grid.totalCount,
  sortField: state.unecosListStore.grid.sortField,
  sortDirection: state.unecosListStore.grid.sortDirection
});

const mapDispatchToProps: IUnecosListContainerDispatchProps = {
  reset: unecosListStore.reset,
  resetHard: unecosListStore.resetHard,
  setFilterActive: unecosListStore.setFilterActive,
  setFilterCode: unecosListStore.setFilterCode,
  setFilterCategoryName: unecosListStore.setFilterCategoryName,
  setFilterEmails: unecosListStore.setFilterEmails,
  setFilterEmailCoordinator: unecosListStore.setFilterEmailCoordinator,
  setCurrentPage: listGridStore.setCurrentPage,
  setPageSize: listGridStore.setPageSize,
  setSorting: listGridStore.setSorting,
  fetchUnecos: unecosListStore.fetchUnecos,
  saveUneco: unecosListStore.saveUneco
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default compose(
  withRouter,
  withTranslation(),
  connector
)(UnecosListContainer) as ComponentType;
