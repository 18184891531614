import { Action, Reducer, ActionCreator, combineReducers } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { batch } from 'react-redux';
import { DataManager, Query } from '@syncfusion/ej2-data';
import { History, UnregisterCallback } from 'history';
import i18next from 'i18next';

import { RootState } from '..';
import { INotificationDetailState } from '../../interfaces/states/INotificationDetailState';
import { INotification } from '../../common/model/notification.model';
import { INotificationClassification } from '../../common/model/notificationClassification.model';
import { ICompanyCombo } from '../../common/model/company.model';
import { IUser } from '../../common/model/user.model';
import { IErrors } from '../../common/model/errors.model';
import { NotificationScope } from '../../common/model/enumerations/notificationScope.model';
import { IODataService, INotificationsService } from '../../services';
import * as notificationUtils from '../../utils/notification.utils';

enum Type {
  RESET = '@@notificationDetail/RESET',
  MAKE_READY_FOR_RENDERING = '@@notificationDetail/MAKE_READY_FOR_RENDERING',
  SET_NOTIFICATION = '@@notificationDetail/SET_NOTIFICATION',
  SET_NOTIFICATION_SUBJECT = '@@notificationDetail/SET_NOTIFICATION_SUBJECT',
  SET_NOTIFICATION_SCOPE = '@@notificationDetail/SET_NOTIFICATION_SCOPE',
  SET_NOTIFICATION_CLASSIFICATION = '@@notificationDetail/SET_NOTIFICATION_CLASSIFICATION',
  SET_NOTIFICATION_IS_IMPORTANT = '@@notificationDetail/SET_NOTIFICATION_IS_IMPORTANT',
  SET_NOTIFICATION_BODY = '@@notificationDetail/SET_NOTIFICATION_BODY',
  SET_NOTIFICATION_INITIAL = '@@notificationDetail/SET_NOTIFICATION_INITIAL',
  SET_IS_VIEW = '@@notificationDetail/SET_IS_VIEW',
  SET_IS_NEW = '@@notificationDetail/SET_IS_NEW',
  SET_COMPANY = '@@notificationDetail/SET_COMPANY',
  SET_REPORTS_IDS = '@@notificationDetail/SET_REPORTS_IDS',
  SET_REQUESTS_IDS = '@@notificationDetail/SET_REQUESTS_IDS',
  SET_SEND_EMAIL = '@@notificationDetail/SET_SEND_EMAIL',
  SET_EMAIL_DESTINATIONS = '@@notificationDetail/SET_EMAIL_DESTINATIONS',
  SET_SELECTED_EMAIL_DESTINATIONS = '@@notificationDetail/SET_SELECTED_EMAIL_DESTINATIONS',
  SET_IS_SAVING = '@@notificationDetail/SET_IS_SAVING',
  SET_ERRORS = '@@notificationDetail/SET_ERRORS',
  ON_UPLOADING = '@@notificationDetail/ON_UPLOADING',
  ON_UPLOAD_SUCCESS = '@@notificationDetail/ON_UPLOAD_SUCCESS',
  ON_UPLOAD_FAILURE = '@@notificationDetail/ON_UPLOAD_FAILURE'
}

const defaultNotification: INotification = {
  id: null,
  subject: null,
  scope: NotificationScope.GLOBAL,
  classificationId: null,
  classificationTitle: null,
  isImportant: false,
  body: null,
  companyId: null,
  companyName: null,
  emailDestinations: null,
  attachedDocumentPath: null,
  createdBy: null,
  createdDate: null,
  deletedBy: null,
  deletedDate: null,
  notificationsReports: [],
  notificationsRequests: []
};
const initialState: INotificationDetailState = {
  isReadyForRendering: false,
  notification: defaultNotification,
  notificationInitial: defaultNotification,
  isView: null, // must be !== null when isReadyForRendering === true
  isNew: null, // must be !== null when isReadyForRendering === true
  company: null,
  reportsIds: [],
  requestsIds: [],
  sendEmail: false,
  emailDestinations: [],
  selectedEmailDestinations: [],
  isSaving: false,
  errors: {}
};

// Actions

export type Actions = ResetAction | MakeReadyForRenderingAction | SetNotificationAction | SetNotificationSubjectAction | SetNotificationScopeAction | SetNotificationClassificationAction
  | SetNotificationIsImportantAction | SetNotificationBodyAction | SetNotificationInitialAction | SetIsViewAction | SetCompanyAction | SetReportsIdsAction | SetRequestsIdsAction
  | SetSendEmailAction | SetEmailDestinationsAction | SetSelectedEmailDestinationsAction | SetIsSavingAction | SetErrorsAction | OnUploadingAction | OnUploadSuccessAction | OnUploadFailureAction;

export type ResetAction = Action<Type.RESET>

export interface MakeReadyForRenderingAction extends Action<Type.MAKE_READY_FOR_RENDERING> {
  isView: boolean;
  isNew: boolean;
}

export interface SetNotificationAction extends Action<Type.SET_NOTIFICATION> {
  notification: INotification;
}

export interface SetNotificationSubjectAction extends Action<Type.SET_NOTIFICATION_SUBJECT> {
  subject: string;
}

export interface SetNotificationScopeAction extends Action<Type.SET_NOTIFICATION_SCOPE> {
  scope: number;
}

export interface SetNotificationClassificationAction extends Action<Type.SET_NOTIFICATION_CLASSIFICATION> {
  classification: INotificationClassification;
}

export interface SetNotificationIsImportantAction extends Action<Type.SET_NOTIFICATION_IS_IMPORTANT> {
  isImportant: boolean;
}

export interface SetNotificationBodyAction extends Action<Type.SET_NOTIFICATION_BODY> {
  body: string;
}

export interface SetNotificationInitialAction extends Action<Type.SET_NOTIFICATION_INITIAL> {
  notificationInitial: INotification;
}

export interface SetIsViewAction extends Action<Type.SET_IS_VIEW> {
  isView: boolean;
}

export interface SetIsNewAction extends Action<Type.SET_IS_NEW> {
  isNew: boolean;
}

export interface SetCompanyAction extends Action<Type.SET_COMPANY> {
  company: ICompanyCombo;
}

export interface SetReportsIdsAction extends Action<Type.SET_REPORTS_IDS> {
  reportsIds: string[];
}

export interface SetRequestsIdsAction extends Action<Type.SET_REQUESTS_IDS> {
  requestsIds: string[];
}

export interface SetSendEmailAction extends Action<Type.SET_SEND_EMAIL> {
  sendEmail: boolean;
}

export interface SetEmailDestinationsAction extends Action<Type.SET_EMAIL_DESTINATIONS> {
  emailDestinations: string[];
}

export interface SetSelectedEmailDestinationsAction extends Action<Type.SET_SELECTED_EMAIL_DESTINATIONS> {
  selectedEmailDestinations: string[];
}

export interface SetIsSavingAction extends Action<Type.SET_IS_SAVING> {
  isSaving: boolean;
}

export interface SetErrorsAction extends Action<Type.SET_ERRORS> {
  errors: IErrors;
}

export type OnUploadingAction = Action<Type.ON_UPLOADING>

export interface OnUploadSuccessAction extends Action<Type.ON_UPLOAD_SUCCESS> {
  createdNotification: INotification;
}

export interface OnUploadFailureAction extends Action<Type.ON_UPLOAD_FAILURE> {
  errors: IErrors;
}

export const reset: ActionCreator<ResetAction> = () => ({
  type: Type.RESET
});

export const makeReadyForRendering: ActionCreator<MakeReadyForRenderingAction> = (isViewLocal: boolean, isNewLocal: boolean) => ({
  type: Type.MAKE_READY_FOR_RENDERING,
  isView: isViewLocal,
  isNew: isNewLocal
});

export const setNotification: ActionCreator<SetNotificationAction> = (notificationLocal: INotification) => ({
  type: Type.SET_NOTIFICATION,
  notification: notificationLocal
});

export const setNotificationSubject: ActionCreator<SetNotificationSubjectAction> = (subject: string) => ({
  type: Type.SET_NOTIFICATION_SUBJECT,
  subject
});

export const setNotificationScope: ActionCreator<SetNotificationScopeAction> = (scope: number) => ({
  type: Type.SET_NOTIFICATION_SCOPE,
  scope
});

export const setNotificationClassification: ActionCreator<SetNotificationClassificationAction> = (classification: INotificationClassification) => ({
  type: Type.SET_NOTIFICATION_CLASSIFICATION,
  classification
});

export const setNotificationIsImportant: ActionCreator<SetNotificationIsImportantAction> = (isImportant: boolean) => ({
  type: Type.SET_NOTIFICATION_IS_IMPORTANT,
  isImportant
});

export const setNotificationBody: ActionCreator<SetNotificationBodyAction> = (body: string) => ({
  type: Type.SET_NOTIFICATION_BODY,
  body
});

export const setNotificationInitial: ActionCreator<SetNotificationInitialAction> = (notificationInitialLocal: INotification) => ({
  type: Type.SET_NOTIFICATION_INITIAL,
  notificationInitial: notificationInitialLocal
});

export const setIsView: ActionCreator<SetIsViewAction> = (isViewLocal: boolean) => ({
  type: Type.SET_IS_VIEW,
  isView: isViewLocal
});

export const setIsNew: ActionCreator<SetIsNewAction> = (isNewLocal: boolean) => ({
  type: Type.SET_IS_NEW,
  isNew: isNewLocal
});

export const setCompany: ActionCreator<SetCompanyAction> = (companyLocal: ICompanyCombo) => ({
  type: Type.SET_COMPANY,
  company: companyLocal
});

export const setReportsIds: ActionCreator<SetReportsIdsAction> = (reportsIdsLocal: string[]) => ({
  type: Type.SET_REPORTS_IDS,
  reportsIds: reportsIdsLocal
});

export const setRequestsIds: ActionCreator<SetRequestsIdsAction> = (requestsIdsLocal: string[]) => ({
  type: Type.SET_REQUESTS_IDS,
  requestsIds: requestsIdsLocal
});

export const setSendEmail: ActionCreator<SetSendEmailAction> = (sendEmailLocal: boolean) => ({
  type: Type.SET_SEND_EMAIL,
  sendEmail: sendEmailLocal
});

export const setEmailDestinations: ActionCreator<SetEmailDestinationsAction> = (emailDestinationsLocal: string[]) => ({
  type: Type.SET_EMAIL_DESTINATIONS,
  emailDestinations: emailDestinationsLocal
});

export const setSelectedEmailDestinations: ActionCreator<SetSelectedEmailDestinationsAction> = (selectedEmailDestinationsLocal: string[]) => ({
  type: Type.SET_SELECTED_EMAIL_DESTINATIONS,
  selectedEmailDestinations: selectedEmailDestinationsLocal
});

export const setIsSaving: ActionCreator<SetIsSavingAction> = (isSavingLocal: boolean) => ({
  type: Type.SET_IS_SAVING,
  isSaving: isSavingLocal
});

export const setErrors: ActionCreator<SetErrorsAction> = (errorsLocal: IErrors) => ({
  type: Type.SET_ERRORS,
  errors: errorsLocal
});

export const onUploading: ActionCreator<OnUploadingAction> = () => ({
  type: Type.ON_UPLOADING
});

export const onUploadSuccess: ActionCreator<OnUploadSuccessAction> = (createdNotification: INotification) => ({
  type: Type.ON_UPLOAD_SUCCESS,
  createdNotification
});

export const onUploadFailure: ActionCreator<OnUploadFailureAction> = (errorsLocal: IErrors) => ({
  type: Type.ON_UPLOAD_FAILURE,
  errors: errorsLocal
});

export const fetchNotification: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (id: string, notificationsService: INotificationsService) => (
  async (dispatch) => {
    try {
      const notificationLocal = await notificationsService.fetch(id);
      batch(() => {
        dispatch(setNotification(notificationLocal));
        dispatch(setNotificationInitial(notificationLocal));
        dispatch(setCompany({ id: notificationLocal.companyId, name: notificationLocal.companyId })); // Syncfusion's AutoCompleteComponent uses name field
        dispatch(setReportsIds(notificationUtils.computeReportsIds(notificationLocal)));
        dispatch(setRequestsIds(notificationUtils.computeRequestsIds(notificationLocal)));
        dispatch(setSendEmail(notificationUtils.computeSendEmail(notificationLocal)));
        dispatch(setSelectedEmailDestinations(notificationUtils.computeSelectedEmailDestinations(notificationLocal)));
      });
    } catch (error) {
      // TODO: manejar error
    }
  }
);

export const loadFromSharedAdvancedFilterNotification: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (i18n: i18next.i18n) => (
  async (dispatch, getState) => {
    const advancedFilter = getState().reportsListStore.advancedFilter;
    const webUrl = getState().settingsStore.settings.web;
    if (advancedFilter && advancedFilter.companyId) {
      const notificationLocal = notificationUtils.computeFromSharedAdvancedFilterNotification(defaultNotification, advancedFilter, webUrl, i18n);
      dispatch(setNotification(notificationLocal));
      dispatch(setNotificationInitial(notificationLocal));
      dispatch(setCompany({ id: notificationLocal.companyId, name: notificationLocal.companyId })); // Syncfusion's AutoCompleteComponent uses name field
      dispatch(setSendEmail(true));
    }
  }
);

export const resetNotification: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = () => (
  async (dispatch, getState) => {
    dispatch(setNotification(getState().notificationDetailStore.notificationInitial));
  }
);

export const fetchEmailDestinations: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (oDataService: IODataService, userDataManager: DataManager, userQuery: Query) => (
  async (dispatch, getState) => {
    const users = await oDataService.executeQuery<IUser>(userDataManager, userQuery);
    const emailDestinationsLocal = users.map((user) => user.email);
    batch(() => {
      dispatch(setEmailDestinations(emailDestinationsLocal));
      if (getState().notificationDetailStore.selectedEmailDestinations.length === 0) {
        dispatch(setSelectedEmailDestinations(emailDestinationsLocal));
      }
    });
  }
);

export const createNotification: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (
  notificationLocal: INotification,
  notificationsService: INotificationsService,
  unregisterCallback: UnregisterCallback,
  history: History
) => (
  async (dispatch) => {
    try {
      dispatch(setErrors(initialState.errors));

      dispatch(setIsSaving(true));

      const createdNotification = await notificationsService.create(notificationLocal);
      dispatch(setNotification(createdNotification));
      dispatch(setNotificationInitial(createdNotification));

      if (unregisterCallback) {
        unregisterCallback();
      }
      history.push(`/notification/${createdNotification.id}/view`);
    } catch (error) {
      dispatch(setErrors(error as IErrors));
    } finally {
      dispatch(setIsSaving(false));
    }
  }
);

// Reducers

const isReadyForRendering: Reducer<boolean> = (state = initialState.isReadyForRendering, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.isReadyForRendering;
    case Type.MAKE_READY_FOR_RENDERING:
      return true;
    default:
      return state;
  }
};

const notification: Reducer<INotification> = (state = initialState.notification, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.notification;
    case Type.SET_NOTIFICATION:
      return (action as SetNotificationAction).notification;
    case Type.ON_UPLOAD_SUCCESS:
      return (action as OnUploadSuccessAction).createdNotification;
    case Type.SET_NOTIFICATION_SUBJECT:
      return { ...state, subject: (action as SetNotificationSubjectAction).subject };
    case Type.SET_NOTIFICATION_SCOPE:
      return { ...state, scope: (action as SetNotificationScopeAction).scope };
    case Type.SET_NOTIFICATION_CLASSIFICATION: {
      const classification = (action as SetNotificationClassificationAction).classification;
      return { ...state, classificationId: classification.id, classificationTitle: classification.title };
    }
    case Type.SET_NOTIFICATION_IS_IMPORTANT:
      return { ...state, isImportant: (action as SetNotificationIsImportantAction).isImportant };
    case Type.SET_NOTIFICATION_BODY:
      return { ...state, body: (action as SetNotificationBodyAction).body };
    default:
      return state;
  }
};

const notificationInitial: Reducer<INotification> = (state = initialState.notificationInitial, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.notificationInitial;
    case Type.SET_NOTIFICATION_INITIAL:
      return (action as SetNotificationInitialAction).notificationInitial;
    case Type.ON_UPLOAD_SUCCESS:
      return (action as OnUploadSuccessAction).createdNotification;
    default:
      return state;
  }
};

const isView: Reducer<boolean> = (state = initialState.isView, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.isView;
    case Type.MAKE_READY_FOR_RENDERING:
      return (action as MakeReadyForRenderingAction).isView;
    case Type.SET_IS_VIEW:
      return (action as SetIsViewAction).isView;
    default:
      return state;
  }
};

const isNew: Reducer<boolean> = (state = initialState.isNew, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.isNew;
    case Type.MAKE_READY_FOR_RENDERING:
      return (action as MakeReadyForRenderingAction).isNew;
    case Type.SET_IS_NEW:
      return (action as SetIsNewAction).isNew;
    default:
      return state;
  }
};

const company: Reducer<ICompanyCombo> = (state = initialState.company, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.company;
    case Type.SET_COMPANY:
      return (action as SetCompanyAction).company;
    default:
      return state;
  }
};

const reportsIds: Reducer<string[]> = (state = initialState.reportsIds, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.reportsIds;
    case Type.SET_REPORTS_IDS:
      return (action as SetReportsIdsAction).reportsIds;
    default:
      return state;
  }
};

const requestsIds: Reducer<string[]> = (state = initialState.requestsIds, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.requestsIds;
    case Type.SET_REQUESTS_IDS:
      return (action as SetRequestsIdsAction).requestsIds;
    default:
      return state;
  }
};

const sendEmail: Reducer<boolean> = (state = initialState.sendEmail, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.sendEmail;
    case Type.SET_SEND_EMAIL:
      return (action as SetSendEmailAction).sendEmail;
    default:
      return state;
  }
};

const emailDestinations: Reducer<string[]> = (state = initialState.emailDestinations, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.emailDestinations;
    case Type.SET_EMAIL_DESTINATIONS:
      return (action as SetEmailDestinationsAction).emailDestinations;
    default:
      return state;
  }
};

const selectedEmailDestinations: Reducer<string[]> = (state = initialState.selectedEmailDestinations, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.selectedEmailDestinations;
    case Type.SET_SELECTED_EMAIL_DESTINATIONS:
      return (action as SetSelectedEmailDestinationsAction).selectedEmailDestinations;
    default:
      return state;
  }
};

const isSaving: Reducer<boolean> = (state = initialState.isSaving, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.isSaving;
    case Type.SET_IS_SAVING:
      return (action as SetIsSavingAction).isSaving;
    case Type.ON_UPLOADING:
      return true;
    case Type.ON_UPLOAD_SUCCESS:
    case Type.ON_UPLOAD_FAILURE:
      return false;
    default:
      return state;
  }
};

const errors: Reducer<IErrors> = (state = initialState.errors, action) => {
  switch (action.type) {
    case Type.RESET:
    case Type.ON_UPLOADING:
      return initialState.errors;
    case Type.SET_ERRORS:
      return (action as SetErrorsAction).errors;
    case Type.ON_UPLOAD_FAILURE:
      return (action as OnUploadFailureAction).errors;
    default:
      return state;
  }
};

export const notificationDetailStore = combineReducers({
  isReadyForRendering,
  notification,
  notificationInitial,
  isView,
  isNew,
  company,
  reportsIds,
  requestsIds,
  sendEmail,
  emailDestinations,
  selectedEmailDestinations,
  isSaving,
  errors
});
