import React, { createRef, MouseEvent } from 'react';
import { Button, Form } from 'react-bootstrap';
import logo from '../../../assets/images/logo.svg';
import './loginForm.scss';
import i18n from '../../../i18n';
import Captcha from '../../../components/captcha';
import { ILoginFormState, IForm } from '../../../interfaces/states/ILoginFormState';
import { LoginFormProps } from './iLoginForm';
import FormInput from './input';
import FormSelect from './select';
import { reportStatusService } from '../../../services';
import { notifyError } from '../../../utils/toast.utils';

type LoginFormState = ILoginFormState;

class LoginForm extends React.Component<LoginFormProps, LoginFormState> {
  public captcha = createRef<any>();

  public constructor(props: LoginFormProps) {
    super(props);
    this.state = {
      validatedForm: false,
      validatedCaptcha: null,
      form: {
        model: null,
        provider: null,
        season: null
      }
    };
  }

  public componentDidMount(): void {
    if (this.props.seasons) {
      this.setState({ form: { ...this.state.form, season: this.props.seasons[0].code } });
    }
  }

  public static getDerivedStateFromProps(props: LoginFormProps, state: LoginFormState): LoginFormState {
    if (props.seasons && !state.form.season) {
      return {
        ...state, form: {
          ...state.form,
          season: props.seasons[0].code
        }
      };
    }

    return null;
  }

  public render(): JSX.Element {
    return (
      <div className='container login-form mt-5'>
        <h3 className='mb-3 d-flex flex-column login-form-title pb-2 ml-3 mr-3'>
          <img className='mr-3 login-form-logo' src={logo} alt='logo' />
          <p className='mt-2'>{i18n.t('login.suppliers.title')}</p>
        </h3>
        <Form noValidate={true} validated={this.state.validatedForm} onSubmit={this.submitForm}>
          <FormInput
            controlId={'model'}
            onChange={this.onChange}
            label={i18n.t('login.suppliers.model')}
            placeholder={i18n.t('login.suppliers.modelPlaceholder')}
            required={true}
            feedback={i18n.t('login.suppliers.errorMessage.model')}
          />
          <FormInput
            controlId={'provider'}
            onChange={this.onChange}
            label={i18n.t('login.suppliers.provider')}
            placeholder={i18n.t('login.suppliers.providerPlaceholder')}
            required={true}
            feedback={i18n.t('login.suppliers.errorMessage.provider')}
          />
          <FormSelect
            onChange={this.onChange}
            controlId={'season'}
            label={i18n.t('login.suppliers.season')}
            required={true}
            seasons={this.props.seasons}
          />
          <Captcha ref={this.captcha} validatedCaptcha={this.state.validatedCaptcha} />
          <div className='col'>
            <Button className='login-form-button' variant='secondary' type='submit'>
              {i18n.t('login.suppliers.button')}
            </Button>
          </div>
        </Form>
      </div >
    );
  }

  private onChange = (e: IForm) => {
    const form: IForm = { ...this.state.form };
    form[e.target.id] = e.currentTarget.value;
    this.setState({ form });
  }

  private padLeft = (text: string, places: number, character: string): string => {
    return text.padStart(places, character);
  }

  private setValidated = (isValidated: boolean) => {
    this.setState({ validatedForm: isValidated });
  }

  private validateForm = (e: MouseEvent<HTMLFormElement>) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }

    this.setValidated(true);
  }

  private submitForm = async (e: MouseEvent<HTMLFormElement>) => {
    e.preventDefault();

    // form validation
    this.validateForm(e);
    if (!this.state.form.model || !this.state.form.provider || !this.state.form.season) {
      return;
    }

    // captcha validation
    const validatedCaptcha = await this.captcha.current.validateCaptcha();
    this.setState({ validatedCaptcha });
    if (this.state.validatedCaptcha === false) {
      return;
    }

    try {
      const currentForm: IForm = { ...this.state.form, provider: this.padLeft(this.state.form.provider, 6, '0') };

      // retrieve data
      const reportStatus = await reportStatusService.getReportStatus(currentForm);

      // sending formData
      this.props.handleSubmit(reportStatus);
    } catch (error) {
      this.captcha.current.refreshCaptcha();
      notifyError(i18n.t('login.suppliers.errorMessage.noReports'));
      return;
    }
  }
}

export default LoginForm;
