import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { WithTranslation, withTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Sort,
  Page,
  Toolbar,
  ExcelExport,
  Resize,
  DetailRow,
  GridModel,
  RowDataBoundEventArgs,
  SortSettingsModel,
  PagerComponent,
  SortEventArgs
} from '@syncfusion/ej2-react-grids';
import { ItemModel, ClickEventArgs } from '@syncfusion/ej2-navigations';

import { ICertificatesGridProps } from '../../../interfaces/props/ICertificatesListProps';
import { EXCEL_EXPORT_ACTION_ID } from '../../../common/constants/gridActionId.constants';
import { ICertificate } from '../../../common/model/certificate.model';
import { CertificateStatus } from '../../../common/model/enumerations/certificateStatus.model';
import { CertificateRenewalState } from '../../../common/model/enumerations/certificateRenewalState.model';
import * as certificateTypeUtils from '../../../utils/certificateType.utils';
import * as certificateStatusUtils from '../../../utils/certificateStatus.utils';
import * as certificateUtils from '../../../utils/certificate.utils';
import * as certificateRenewalStateUtils from '../../../utils/certificateRenewalState.utils';
import * as gridUtils from '../../../utils/grid.utils';
import * as pagerUtils from '../../../utils/pager.utils';
import * as arrayUtils from '../../../utils/array.utils';
import * as sortUtils from '../../../utils/sort.utils';
import * as dateHelpers from '../../../helpers/date.helpers';
import CertificatesGridActionsColumn from './certificatesGridActionsColumn';
import './certificatesGrid.scss';

type CertificatesGridPropsType = ICertificatesGridProps & RouteComponentProps & WithTranslation;

class CertificatesGrid extends Component<CertificatesGridPropsType> {
  public gridComponent: GridComponent = null;
  public toolbarOptions: ItemModel[] = [];
  public sortOptions: SortSettingsModel = null;
  public childGrid: GridModel = null;

  public pagerComponent: PagerComponent = null;

  private readonly CHECK_RENEWAL_STATUS_ACTION_ID = 'checkRenewalStatus';
  private readonly RENEW_SELECTED_CERTIFICATES_ACTION_ID = 'renewSelectedCertificates';
  private readonly CANCEL_RENEWAL_OF_SELECTED_CERTIFICATES_ACTION_ID = 'cancelRenewalOfSelectedCertificates';

  public constructor(props: CertificatesGridPropsType) {
    super(props);

    const { certificates, sortField, sortDirection, isCurrentUserInternal, i18n } = this.props;

    this.toolbarOptions = [{
      tooltipText: i18n.t('syncfusion.grid.Excelexport'),
      id: EXCEL_EXPORT_ACTION_ID,
      prefixIcon: 'icon icon-file-excel-o'
    }];
    if (isCurrentUserInternal) {
      this.toolbarOptions.push({ tooltipText: i18n.t('certificatesList.actions.checkRenewalStatus'), id: this.CHECK_RENEWAL_STATUS_ACTION_ID, prefixIcon: 'icon icon icon-progress' });
    }

    this.sortOptions = {
      columns: [{ field: sortField, direction: sortDirection }]
    };

    this.childGrid = {
      dataSource: certificates,
      queryString: 'id',
      columns: [
        { field: 'referenceNames', headerText: i18n.t('report.reference') }
      ]
    };
  }

  public componentDidUpdate(prevProps: CertificatesGridPropsType) {
    gridUtils.manageSpinner(this.gridComponent, prevProps.isFetching, this.props.isFetching);
    gridUtils.manageSorting(this.gridComponent, prevProps.isFetching, this.props.isFetching, this.props.sortField, this.props.sortDirection);
  }

  public onToolbarClick(args: ClickEventArgs) {
    const { onCheckRenewalStatusButtonClick, onRequestRenewalMultipleButtonClick, onRequestRenewalCancellationMultipleButtonClick } = this.props;

    if (args.item.id === EXCEL_EXPORT_ACTION_ID) {
      this.gridComponent.excelExport();
    }
    if (args.item.id === this.CHECK_RENEWAL_STATUS_ACTION_ID) {
      onCheckRenewalStatusButtonClick();
    }
    if (args.item.id === this.RENEW_SELECTED_CERTIFICATES_ACTION_ID) {
      onRequestRenewalMultipleButtonClick();
    }
    if (args.item.id === this.CANCEL_RENEWAL_OF_SELECTED_CERTIFICATES_ACTION_ID) {
      onRequestRenewalCancellationMultipleButtonClick();
    }
  }

  public onGridComponentDataBound() {
    const { currentPage, pageSize, totalCount, searchDate, i18n } = this.props;

    pagerUtils.refreshPager(this.pagerComponent, currentPage, pageSize, totalCount, searchDate, i18n);
  }

  public onGridComponentActionBegin(args: SortEventArgs) {
    if (args.requestType === 'sorting') {
      if (args.columnName === 'expirationDate') {
        this.gridComponent.getColumnByField('expirationDate').sortComparer = (reference, comparer) =>
          sortUtils.gridDateSortComparer(reference as unknown as Moment, comparer as unknown as Moment, args.direction);
      } else {
        this.gridComponent.getColumnByField(args.columnName).sortComparer = (reference, comparer) =>
          sortUtils.gridSortComparer(reference, comparer, args.direction);
      }

      gridUtils.manageSortingChange(args, this.props.sortField, this.props.sortDirection, this.props.onSortingChange);
    }
  }

  public onRowDataBound(args: RowDataBoundEventArgs) {
    gridUtils.manageDetailRowExpansion(this.gridComponent, args, !!(args.data as ICertificate).referenceNames, true);
  }

  public getReportNumberColumnTemplate(certificate: ICertificate): JSX.Element {
    return <span className='e-link' onClick={() => this.props.history.push(`/report/${certificate.reportId}/view?tab=certificates`)}>{certificate.reportNumber}</span>;
  }

  public getExpirationDateLabel(certificate: ICertificate): string {
    return certificate.expirationDate && dateHelpers.toShortDate(certificate.expirationDate, true);
  }

  public getTypeColumnTemplate(certificate: ICertificate): JSX.Element {
    return certificate.certificateTypeValue && <span title={certificateTypeUtils.getLocalizedText(this.props.i18n, certificate.certificateTypeValue)} className={certificateTypeUtils.getIconCss(certificate.certificateTypeValue)} />;
  }

  public getStatusColumnTemplate(certificate: ICertificate): JSX.Element {
    return (!certificate.expirationDate || certificate.expirationDate.isSameOrAfter(moment())) ?
      <span title={certificateStatusUtils.getLocalizedText(this.props.i18n, CertificateStatus.VALID)} className={certificateStatusUtils.getIconCss(CertificateStatus.VALID)} />
      :
      <span title={certificateStatusUtils.getLocalizedText(this.props.i18n, CertificateStatus.EXPIRED)} className={certificateStatusUtils.getIconCss(CertificateStatus.EXPIRED)} />;
  }

  public getRenewalStateColumnTemplate(certificate: ICertificate): JSX.Element {
    return (
      <span title={certificateRenewalStateUtils.getLocalizedTextByValue(this.props.i18n, certificate.renewalState)} className={certificateRenewalStateUtils.getIconCss(certificate.renewalState)} />
    );
  }

  public getActionsColumnTemplate(certificate: ICertificate): JSX.Element {
    const { isCurrentUserInternal, onRequestRenewalButtonClick, onRequestRenewalCancellationButtonClick, onShareCertificateButtonClick } = this.props;

    const showShareCertificateButton = certificateUtils.canShareCertificate(certificate);

    return (
      <CertificatesGridActionsColumn
        certificate={certificate}
        showRequestRenewalButtons={(
          !isCurrentUserInternal && certificate.renewalState === CertificateRenewalState.PENDING && certificate.expirationDate && certificate.expirationDate.isSameOrBefore(moment().add(6, 'months'))
        )}
        showShareCertificateButton={showShareCertificateButton}
        onIsSelectedChange={(isSelected) => this.onCertificateIsSelectedChange(certificate, isSelected)}
        onRequestRenewalButtonClick={() => onRequestRenewalButtonClick(certificate.id)}
        onRequestRenewalCancellationButtonClick={() => onRequestRenewalCancellationButtonClick(certificate.id)}
        onShareCertificateButtonClick={(cert) => onShareCertificateButtonClick(cert)}
      />
    );
  }

  public onPagerComponentClick(e: any) {
    if (e.currentPage !== this.props.currentPage) {
      this.props.onCurrentPageChange(e.currentPage);
    }
  }

  public onPageSizesDropdownChange(e: any) {
    if (e.pageSize !== this.props.pageSize) {
      this.props.onPageSizeChange(e.pageSize);
    }
  }

  public render() {
    const { certificates, selectedCertificatesIds, i18n, isCurrentUserInternal } = this.props;

    const renewSelectedCertificatesActionIndex = this.toolbarOptions.findIndex((option) => (option).id === this.RENEW_SELECTED_CERTIFICATES_ACTION_ID);
    if (selectedCertificatesIds.length > 0 && renewSelectedCertificatesActionIndex < 0) {
      this.toolbarOptions = arrayUtils.insertItem(this.toolbarOptions, this.computeRenewSelectedCertificatesActionItemModel());
    }
    if (selectedCertificatesIds.length === 0 && renewSelectedCertificatesActionIndex >= 0) {
      this.toolbarOptions = arrayUtils.removeItem(this.toolbarOptions, renewSelectedCertificatesActionIndex);
    }
    const cancelRenewalOfSelectedCertificatesActionIndex = this.toolbarOptions.findIndex((option) => (option).id === this.CANCEL_RENEWAL_OF_SELECTED_CERTIFICATES_ACTION_ID);
    if (selectedCertificatesIds.length > 0 && cancelRenewalOfSelectedCertificatesActionIndex < 0) {
      this.toolbarOptions = arrayUtils.insertItem(this.toolbarOptions, this.computeCancelRenewalOfSelectedCertificatesActionItemModel());
    }
    if (selectedCertificatesIds.length === 0 && cancelRenewalOfSelectedCertificatesActionIndex >= 0) {
      this.toolbarOptions = arrayUtils.removeItem(this.toolbarOptions, cancelRenewalOfSelectedCertificatesActionIndex);
    }

    if (this.gridComponent && this.gridComponent.childGrid) {
      this.gridComponent.childGrid.dataSource = certificates.filter((certificate) => !!certificate.referenceNames);
    }

    return (
      <div className='certificates-grid'>
        <GridComponent
          dataSource={certificates}
          childGrid={this.childGrid}
          toolbar={this.toolbarOptions}
          allowSorting={true}
          allowPaging={true}   /* Si no se incluye este paginador (oculto por css), no se muestra el dropdown en el customizado */
          allowExcelExport={true}
          allowResizing={true}
          sortSettings={this.sortOptions}
          pageSettings={{ pageSize: pagerUtils.getMaxPageSize() }}
          locale={i18n.language}
          ref={(grid: GridComponent): GridComponent => this.gridComponent = grid}
          toolbarClick={(args) => this.onToolbarClick(args)}
          dataBound={() => this.onGridComponentDataBound()}
          actionBegin={(args) => this.onGridComponentActionBegin(args as SortEventArgs)}
          rowDataBound={(args) => this.onRowDataBound(args)}
          height='100%'
          width='100%'
        >
          <ColumnsDirective>
            <ColumnDirective
              headerText={this.props.i18n.t('report.reportNumber')}
              field='reportNumber'
              width='150'
              template={(certificate: ICertificate) => this.getReportNumberColumnTemplate(certificate)}
            />
            <ColumnDirective
              headerText={this.props.i18n.t('certificate.identificationCode')}
              field='identificationCode'
              width='100'
            />
            <ColumnDirective
              headerText={this.props.i18n.t('certificate.certificateType')}
              field='certificateTypeValue'
              width='100'
              allowSorting={false}
              template={(certificate: ICertificate) => this.getTypeColumnTemplate(certificate)}
            />
            <ColumnDirective
              headerText={this.props.i18n.t('report.requerimentStandard')}
              field='offerRequerimentStandard'
              width='250'
            />
            <ColumnDirective
              headerText={this.props.i18n.t('certificate.expirationDate')}
              field='expirationDate'
              width='100'
              valueAccessor={(_field, data) => this.getExpirationDateLabel(data as ICertificate)}
            />
            <ColumnDirective
              headerText={this.props.i18n.t('certificate.status')}
              textAlign='Center'
              field='status'
              width='100'
              allowSorting={false}
              template={(certificate: ICertificate) => this.getStatusColumnTemplate(certificate)}
            />
            <ColumnDirective
              headerText={this.props.i18n.t('certificate.renewalState')}
              textAlign='Center'
              field='renewalState'
              width='150'
              template={(certificate: ICertificate) => this.getRenewalStateColumnTemplate(certificate)}
            />
            {isCurrentUserInternal && <ColumnDirective
              headerText={this.props.i18n.t('report.client')}
              field='companyName'
              width='250'
            />}
            <ColumnDirective
              headerText={this.props.i18n.t('syncfusion.grid.Actions')}
              template={(certificate: ICertificate) => this.getActionsColumnTemplate(certificate)}
            />
          </ColumnsDirective>
          <Inject services={[Sort, Page, Toolbar, ExcelExport, Resize, DetailRow]} />
        </GridComponent>
        {certificates.length ?
          <PagerComponent
            pageSize={pagerUtils.getDefaultPageSize()}
            pageCount={pagerUtils.getPageCount()}
            pageSizes={pagerUtils.getPageSizes()}
            ref={(pagerComponent: PagerComponent): PagerComponent => this.pagerComponent = pagerComponent}
            click={(e) => this.onPagerComponentClick(e)}
            dropDownChanged={(e) => this.onPageSizesDropdownChange(e)}
          />
          :
          null
        }
      </div>
    );
  }

  private computeRenewSelectedCertificatesActionItemModel(): ItemModel {
    return {
      tooltipText: this.props.i18n.t('certificatesList.actions.requestRenewalMultiple'),
      id: this.RENEW_SELECTED_CERTIFICATES_ACTION_ID,
      prefixIcon: 'icon-compose'
    };
  }

  private computeCancelRenewalOfSelectedCertificatesActionItemModel(): ItemModel {
    return {
      tooltipText: this.props.i18n.t('certificatesList.actions.requestRenewalCancellationMultiple'),
      id: this.CANCEL_RENEWAL_OF_SELECTED_CERTIFICATES_ACTION_ID,
      prefixIcon: 'icon-app-item-close'
    };
  }

  private onCertificateIsSelectedChange(certificate: ICertificate, isSelected: boolean) {
    const { selectedCertificatesIds, onSelectedCertificatesIdsChange } = this.props;

    let newSelectedCertificatesIds = selectedCertificatesIds;
    const index = selectedCertificatesIds.findIndex((selectedCertificateId) => selectedCertificateId === certificate.id);
    if (isSelected && index === -1) {
      newSelectedCertificatesIds = arrayUtils.insertItem(newSelectedCertificatesIds, certificate.id);
    }
    if (!isSelected && index > -1) {
      newSelectedCertificatesIds = arrayUtils.removeItem(newSelectedCertificatesIds, index);
    }

    onSelectedCertificatesIdsChange(newSelectedCertificatesIds);
  }
}

export default withRouter(withTranslation()(CertificatesGrid));
