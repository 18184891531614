import { DataManager, Query } from '@syncfusion/ej2-data';

import { RESPONSE_STATUS } from '../common/constants';
import { authService } from '.';

export interface ODataResponse<T = any> {
  result: T[];
}

export interface ODataResponseWithCount<T = any> {
  count: number;
  result: T[];
}

export interface IODataService {
  executeQuery<T = any>(dataManager: DataManager, query: Query): Promise<T[]>;
  executeQueryWithCount<T = any>(dataManager: DataManager, query: Query): Promise<ODataResponseWithCount<T>>;
  getMaxFilterDepth(): number;
}

class ODataService implements IODataService {
  private readonly MAX_FILTER_DEPTH = 20;

  public async executeQuery<T = any>(dataManager: DataManager, query: Query): Promise<T[]> {
    try {
      const response = await dataManager.executeQuery(query) as unknown as ODataResponse<T>;
      return response.result;
    } catch (error: any) {
      if (error.error.status === RESPONSE_STATUS.UNAUTHORIZED) {
        authService.login();
      }

      return [];
    }
  }

  public async executeQueryWithCount<T = any>(dataManager: DataManager, query: Query): Promise<ODataResponseWithCount<T>> {
    try {
      query.isCountRequired = true;
      return await dataManager.executeQuery(query) as unknown as ODataResponseWithCount<T>;
    } catch (error: any) {
      if (error.error.status === RESPONSE_STATUS.UNAUTHORIZED) {
        authService.login();
      }

      return {
        count: 0,
        result: []
      };
    }
  }

  public getMaxFilterDepth(): number {
    return this.MAX_FILTER_DEPTH;
  }
}

export const oDataService: IODataService = new ODataService();
