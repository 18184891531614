import { Action, Reducer, ActionCreator, combineReducers } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { DataManager, Query, Predicate } from '@syncfusion/ej2-data';

import { RootState } from '..';
import {
  IDialogsState,
  IGenericConfirmationDialogState,
  IDiscardChangesDialogState,
  IDeactivateEntityDialogState,
  IEditAnnexDialogState,
  IEditAnnexStateDialogState,
  ISaveAdvancedFilterDialogState,
  IApplyAdvancedFiltersDialogState,
  IShareAdvancedFilterDialogState,
  IUploadAnnexDialogState,
  IChangeCompanyDialogState,
  IContextualHelpDialogState,
  ISimulateExternalUserDialogState,
  IEditCompanySupplierPurchaseProductsDialogState,
  IEditReportCustomFieldSettingsDialogState,
  IRequestCertificateRenewalDialogState,
  IReportVersionsDialogState,
  IEditChainSeriesDialogState,
  ICreateNoteDialogState,
  ISendReportEmailDialogState,
  IShareCertificateEmailDialogState,
  IUserFeedbackDialogState
} from '../../interfaces/states/IDialogsState';
import { IAnnex } from '../../common/model/annex.model';
import { ICompanyCombo } from '../../common/model/company.model';
import { ISupplierProduct } from '../../common/model/supplierProduct.model';
import { IReportCustomFieldSettingsWithCheckboxes } from '../../common/model/customFieldSettings.model';
import { IReport, IReportCombo } from '../../common/model/report.model';
import { IChain } from '../../common/model/chain.model';
import { IErrors } from '../../common/model/errors.model';
import { ICertificate } from '../../common/model/certificate.model';
import { RuleOperator } from '../../common/model/enumerations/ruleOperator.model';
import { IODataService, ICompaniesService, ISupplierProductsService, IReportCustomFieldSettingsService, IReportsService } from '../../services';
import * as reportCustomFieldSettingsUtils from '../../utils/reportCustomFieldSettings.utils';

enum Type {
  RESET = '@@dialogs/RESET',
  SHOW_GENERIC_CONFIRMATION_DIALOG = '@@dialogs/SHOW_GENERIC_CONFIRMATION_DIALOG',
  HIDE_GENERIC_CONFIRMATION_DIALOG = '@@dialogs/HIDE_GENERIC_CONFIRMATION_DIALOG',
  SHOW_DISCARD_CHANGES_DIALOG = '@@dialogs/SHOW_DISCARD_CHANGES_DIALOG',
  HIDE_DISCARD_CHANGES_DIALOG = '@@dialogs/HIDE_DISCARD_CHANGES_DIALOG',
  PREPARE_DEACTIVATE_ENTITY_DIALOG = '@@dialogs/PREPARE_DEACTIVATE_ENTITY_DIALOG',
  SHOW_DEACTIVATE_ENTITY_DIALOG = '@@dialogs/SHOW_DEACTIVATE_ENTITY_DIALOG',
  HIDE_DEACTIVATE_ENTITY_DIALOG = '@@dialogs/HIDE_DEACTIVATE_ENTITY_DIALOG',
  PREPARE_EDIT_ANNEX_DIALOG = '@@dialogs/PREPARE_EDIT_ANNEX_DIALOG',
  SHOW_EDIT_ANNEX_DIALOG = '@@dialogs/SHOW_EDIT_ANNEX_DIALOG',
  HIDE_EDIT_ANNEX_DIALOG = '@@dialogs/HIDE_EDIT_ANNEX_DIALOG',
  PREPARE_EDIT_ANNEX_STATE_DIALOG = '@@dialogs/PREPARE_EDIT_ANNEX_STATE_DIALOG',
  SHOW_EDIT_ANNEX_STATE_DIALOG = '@@dialogs/SHOW_EDIT_ANNEX_STATE_DIALOG',
  HIDE_EDIT_ANNEX_STATE_DIALOG = '@@dialogs/HIDE_EDIT_ANNEX_STATE_DIALOG',
  SHOW_SAVE_ADVANCED_FILTER_DIALOG = '@@dialogs/SHOW_SAVE_ADVANCED_FILTER_DIALOG',
  HIDE_SAVE_ADVANCED_FILTER_DIALOG = '@@dialogs/HIDE_SAVE_ADVANCED_FILTER_DIALOG',
  SHOW_APPLY_ADVANCED_FILTERS_DIALOG = '@@dialogs/SHOW_APPLY_ADVANCED_FILTERS_DIALOG',
  HIDE_APPLY_ADVANCED_FILTERS_DIALOG = '@@dialogs/HIDE_APPLY_ADVANCED_FILTERS_DIALOG',
  SHOW_SHARE_ADVANCED_FILTER_DIALOG = '@@dialogs/SHOW_SHARE_ADVANCED_FILTER_DIALOG',
  HIDE_SHARE_ADVANCED_FILTER_DIALOG = '@@dialogs/HIDE_SHARE_ADVANCED_FILTER_DIALOG',
  SHOW_LOAD_ANNEX_DIALOG = '@@dialogs/SHOW_LOAD_ANNEX_DIALOG',
  HIDE_LOAD_ANNEX_DIALOG = '@@dialogs/HIDE_LOAD_ANNEX_DIALOG',
  SHOW_CHANGE_COMPANY_DIALOG = '@@dialogs/SHOW_CHANGE_COMPANY_DIALOG',
  HIDE_CHANGE_COMPANY_DIALOG = '@@dialogs/HIDE_CHANGE_COMPANY_DIALOG',
  SET_CHANGE_COMPANY_DIALOG_COMPANIES = '@@dialogs/SET_CHANGE_COMPANY_DIALOG_COMPANIES',
  SHOW_CONTEXTUAL_HELP_DIALOG = '@@dialogs/SHOW_CONTEXTUAL_HELP_DIALOG',
  HIDE_CONTEXTUAL_HELP_DIALOG = '@@dialogs/HIDE_CONTEXTUAL_HELP_DIALOG',
  SET_CONTEXTUAL_HELP_DIALOG_CODE = '@@dialogs/SET_CONTEXTUAL_HELP_DIALOG_CODE',
  SHOW_SIMULATE_EXTERNAL_USER_DIALOG = '@@dialogs/SHOW_SIMULATE_EXTERNAL_USER_DIALOG',
  HIDE_SIMULATE_EXTERNAL_USER_DIALOG = '@@dialogs/HIDE_SIMULATE_EXTERNAL_USER_DIALOG',
  SHOW_EDIT_COMPANY_SUPPLIER_PURCHASE_PRODUCTS_DIALOG = '@@dialogs/SHOW_EDIT_COMPANY_SUPPLIER_PURCHASE_PRODUCTS_DIALOG',
  HIDE_EDIT_COMPANY_SUPPLIER_PURCHASE_PRODUCTS_DIALOG = '@@dialogs/HIDE_EDIT_COMPANY_SUPPLIER_PURCHASE_PRODUCTS_DIALOG',
  SET_EDIT_COMPANY_SUPPLIER_PURCHASE_PRODUCTS_DIALOG_COMPANY_ID = '@@dialogs/SET_EDIT_COMPANY_SUPPLIER_PURCHASE_PRODUCTS_DIALOG_COMPANY_ID',
  SET_EDIT_COMPANY_SUPPLIER_PURCHASE_PRODUCTS_DIALOG_SUPPLIER_ID = '@@dialogs/SET_EDIT_COMPANY_SUPPLIER_PURCHASE_PRODUCTS_DIALOG_SUPPLIER_ID',
  SET_EDIT_COMPANY_SUPPLIER_PURCHASE_PRODUCTS_DIALOG_SUPPLIER_PRODUCTS = '@@dialogs/SET_EDIT_COMPANY_SUPPLIER_PURCHASE_PRODUCTS_DIALOG_SUPPLIER_PRODUCTS',
  SET_EDIT_COMPANY_SUPPLIER_PURCHASE_PRODUCTS_DIALOG_IS_FETCHING = '@@dialogs/SET_EDIT_COMPANY_SUPPLIER_PURCHASE_PRODUCTS_DIALOG_IS_FETCHING',
  SHOW_EDIT_REPORT_CUSTOM_FIELD_SETTINGS_DIALOG = '@@dialogs/SHOW_EDIT_REPORT_CUSTOM_FIELD_SETTINGS_DIALOG',
  HIDE_EDIT_REPORT_CUSTOM_FIELD_SETTINGS_DIALOG = '@@dialogs/HIDE_EDIT_REPORT_CUSTOM_FIELD_SETTINGS_DIALOG',
  SET_EDIT_REPORT_CUSTOM_FIELD_SETTINGS_DIALOG_COMPANY_ID = '@@dialogs/SET_EDIT_REPORT_CUSTOM_FIELD_SETTINGS_DIALOG_COMPANY_ID',
  SET_EDIT_REPORT_CUSTOM_FIELD_SETTINGS_DIALOG_REPORT_CUSTOM_FIELD_CODES = '@@dialogs/SET_EDIT_REPORT_CUSTOM_FIELD_SETTINGS_DIALOG_REPORT_CUSTOM_FIELD_CODES',
  SET_EDIT_REPORT_CUSTOM_FIELD_SETTINGS_DIALOG_REPORT_CUSTOM_FIELD_SETTINGS_WITH_CHECKBOXES = '@@dialogs/SET_EDIT_REPORT_CUSTOM_FIELD_SETTINGS_DIALOG_REPORT_CUSTOM_FIELD_SETTINGS_WITH_CHECKBOXES',
  SET_EDIT_REPORT_CUSTOM_FIELD_SETTINGS_DIALOG_IS_FETCHING = '@@dialogs/SET_EDIT_REPORT_CUSTOM_FIELD_SETTINGS_DIALOG_IS_FETCHING',
  SET_EDIT_REPORT_CUSTOM_FIELD_SETTINGS_DIALOG_ERRORS = '@@dialogs/SET_EDIT_REPORT_CUSTOM_FIELD_SETTINGS_DIALOG_ERRORS',
  PREPARE_REQUEST_CERTIFICATE_RENEWAL_DIALOG = '@@dialogs/PREPARE_REQUEST_CERTIFICATE_RENEWAL_DIALOG',
  SHOW_REQUEST_CERTIFICATE_RENEWAL_DIALOG = '@@dialogs/SHOW_REQUEST_CERTIFICATE_RENEWAL_DIALOG',
  HIDE_REQUEST_CERTIFICATE_RENEWAL_DIALOG = '@@dialogs/HIDE_REQUEST_CERTIFICATE_RENEWAL_DIALOG',
  PREPARE_REPORT_VERSIONS_DIALOG = '@@dialogs/PREPARE_REPORT_VERSIONS_DIALOG',
  SHOW_REPORT_VERSIONS_DIALOG = '@@dialogs/SHOW_REPORT_VERSIONS_DIALOG',
  HIDE_REPORT_VERSIONS_DIALOG = '@@dialogs/HIDE_REPORT_VERSIONS_DIALOG',
  SET_REPORT_VERSIONS_DIALOG_REPORTS = '@@dialogs/SET_REPORT_VERSIONS_DIALOG_REPORTS',
  SET_REPORT_VERSIONS_DIALOG_IS_FETCHING = '@@dialogs/SET_REPORT_VERSIONS_DIALOG_IS_FETCHING',
  SHOW_EDIT_CHAIN_SERIES_DIALOG = '@@dialogs/SHOW_EDIT_CHAIN_SERIES_DIALOG',
  HIDE_EDIT_CHAIN_SERIES_DIALOG = '@@dialogs/HIDE_EDIT_CHAIN_SERIES_DIALOG',
  SET_EDIT_CHAIN_SERIES_DIALOG_CHAIN = '@@dialogs/SET_EDIT_CHAIN_SERIES_DIALOG_CHAIN',
  PREPARE_CREATE_NOTE_DIALOG = '@@dialogs/PREPARE_CREATE_NOTE_DIALOG',
  SHOW_CREATE_NOTE_DIALOG = '@@dialogs/SHOW_CREATE_NOTE_DIALOG',
  HIDE_CREATE_NOTE_DIALOG = '@@dialogs/HIDE_CREATE_NOTE_DIALOG',
  PREPARE_SEND_REPORT_EMAIL_DIALOG = '@@dialogs/PREPARE_SEND_REPORT_EMAIL_DIALOG',
  SHOW_SEND_REPORT_EMAIL_DIALOG = '@@dialogs/SHOW_SEND_REPORT_EMAIL_DIALOG',
  HIDE_SEND_REPORT_EMAIL_DIALOG = '@@dialogs/HIDE_SEND_REPORT_EMAIL_DIALOG',
  PREPARE_SHARE_CERTIFICATE_EMAIL_DIALOG = '@@dialogs/PREPARE_SHARE_CERTIFICATE_EMAIL_DIALOG',
  SHOW_SHARE_CERTIFICATE_EMAIL_DIALOG = '@@dialogs/SHOW_SHARE_CERTIFICATE_EMAIL_DIALOG',
  HIDE_SHARE_CERTIFICATE_EMAIL_DIALOG = '@@dialogs/HIDE_SHARE_CERTIFICATE_EMAIL_DIALOG',
  SHOW_USER_FEEDBACK_DIALOG = '@@dialogs/SHOW_USER_FEEDBACK_DIALOG',
  HIDE_USER_FEEDBACK_DIALOG = '@@dialogs/HIDE_USER_FEEDBACK_DIALOG'
}

const initialState: IDialogsState = {
  genericConfirmationDialog: {
    visible: false
  },
  discardChangesDialog: {
    visible: false
  },
  deactivateEntityDialog: {
    visible: false,
    entityId: null,
    entityName: '',
    deactivate: (_entityId: string) => new Promise((resolve) => { resolve(); })
  },
  editAnnexDialog: {
    visible: false,
    annex: null
  },
  editAnnexStateDialog: {
    visible: false,
    annex: null
  },
  saveAdvancedFilterDialog: {
    visible: false
  },
  applyAdvancedFiltersDialog: {
    visible: false
  },
  shareAdvancedFilterDialog: {
    visible: false
  },
  uploadAnnexDialog: {
    visible: false
  },
  changeCompanyDialog: {
    visible: false,
    companies: []
  },
  contextualHelpDialog: {
    visible: false,
    code: null
  },
  simulateExternalUserDialog: {
    visible: false
  },
  editCompanySupplierPurchaseProductsDialog: {
    visible: false,
    companyId: null,
    supplierId: null,
    supplierProducts: [],
    isFetching: false
  },
  editReportCustomFieldSettingsDialog: {
    visible: false,
    companyId: null,
    reportCustomFieldCodes: [],
    reportCustomFieldSettingsWithCheckboxes: [],
    isFetching: false,
    errors: {}
  },
  requestCertificateRenewalDialog: {
    visible: false,
    cancellationMode: false,
    certificatesIds: []
  },
  reportVersionsDialog: {
    visible: false,
    reportCombo: null,
    reports: [],
    isFetching: false
  },
  editChainSeriesDialog: {
    visible: false,
    chain: null
  },
  createNoteDialog: {
    visible: false
  },
  sendReportEmailDialog: {
    visible: false,
    report: null
  },
  shareCertificateEmailDialog: {
    visible: false,
    certificate: null
  },
  userFeedbackDialog: {
    visible: false
  }
};

// Actions

export type Actions = ResetAction | ShowGenericConfirmationDialogAction | HideGenericConfirmationDialogAction | ShowDiscardChangesDialogAction | HideDiscardChangesDialogAction
  | PrepareDeactivateEntityDialogAction | ShowDeactivateEntityDialogAction | HideDeactivateEntityDialogAction | PrepareEditAnnexDialogAction | ShowEditAnnexDialogAction
  | HideEditAnnexDialogAction | PrepareEditAnnexStateDialogAction | ShowEditAnnexStateDialogAction | HideEditAnnexStateDialogAction | ShowSaveAdvancedFilterDialogAction
  | HideSaveAdvancedFilterDialogAction | ShowApplyAdvancedFiltersDialogAction | HideApplyAdvancedFiltersDialogAction | ShowShareAdvancedFilterDialogAction | HideShareAdvancedFilterDialogAction
  | ShowUploadAnnexDialogAction | HideUploadAnnexDialogAction | ShowChangeCompanyDialogAction | HideChangeCompanyDialogAction | SetChangeCompanyDialogCompaniesAction
  | ShowContextualHelpDialogAction | HideContextualHelpDialogAction | SetContextualHelpDialogCodeAction | ShowSimulateExternalUserDialogAction | HideSimulateExternalUserDialogAction
  | ShowEditCompanySupplierPurchaseProductsDialogAction | HideEditCompanySupplierPurchaseProductsDialogAction | SetEditCompanySupplierPurchaseProductsDialogCompanyIdAction
  | SetEditCompanySupplierPurchaseProductsDialogSupplierIdAction | SetEditCompanySupplierPurchaseProductsDialogSupplierProductsAction | SetEditCompanySupplierPurchaseProductsDialogIsFetchingAction
  | ShowEditReportCustomFieldSettingsDialogAction | HideEditReportCustomFieldSettingsDialogAction | SetEditReportCustomFieldSettingsDialogCompanyIdAction
  | SetEditReportCustomFieldSettingsDialogReportCustomFieldCodesAction | SetEditReportCustomFieldSettingsDialogReportCustomFieldSettingsWithCheckboxesAction
  | SetEditReportCustomFieldSettingsDialogIsFetchingAction | SetEditReportCustomFieldSettingsDialogErrorsAction | PrepareRequestCertificateRenewalDialogAction
  | ShowRequestCertificateRenewalDialogAction | HideRequestCertificateRenewalDialogAction | PrepareReportVersionsDialogAction | ShowReportVersionsDialogAction
  | HideReportVersionsDialogAction | SetReportVersionsDialogReportsAction | SetReportVersionsDialogIsFetchingAction | ShowEditChainSeriesDialogAction | HideEditChainSeriesDialogAction
  | SetEditChainSeriesDialogChainAction | ShowCreateNoteDialogAction | HideCreateNoteDialogAction | PrepareSendReportEmailDialogAction | ShowSendReportEmailDialogAction
  | HideSendReportEmailDialogAction | PrepareShareCertificateEmailDialogAction | ShowShareCertificateEmailDialogAction | HideShareCertificateEmailDialogAction
  | ShowUserFeedbackDialogAction | HideUserFeedbackDialogAction;

export type ResetAction = Action<Type.RESET>;

export type ShowGenericConfirmationDialogAction = Action<Type.SHOW_GENERIC_CONFIRMATION_DIALOG>;

export type HideGenericConfirmationDialogAction = Action<Type.HIDE_GENERIC_CONFIRMATION_DIALOG>;

export type ShowDiscardChangesDialogAction = Action<Type.SHOW_DISCARD_CHANGES_DIALOG>;

export type HideDiscardChangesDialogAction = Action<Type.HIDE_DISCARD_CHANGES_DIALOG>;

export interface PrepareDeactivateEntityDialogAction extends Action<Type.PREPARE_DEACTIVATE_ENTITY_DIALOG> {
  entityId: string;
  entityName: string;
  deactivate: (entityId: string) => Promise<void>;
}

export type ShowDeactivateEntityDialogAction = Action<Type.SHOW_DEACTIVATE_ENTITY_DIALOG>;

export type HideDeactivateEntityDialogAction = Action<Type.HIDE_DEACTIVATE_ENTITY_DIALOG>;

export interface PrepareEditAnnexDialogAction extends Action<Type.PREPARE_EDIT_ANNEX_DIALOG> {
  annex: IAnnex;
}

export type ShowEditAnnexDialogAction = Action<Type.SHOW_EDIT_ANNEX_DIALOG>;

export type HideEditAnnexDialogAction = Action<Type.HIDE_EDIT_ANNEX_DIALOG>;

export interface PrepareEditAnnexStateDialogAction extends Action<Type.PREPARE_EDIT_ANNEX_STATE_DIALOG> {
  annex: IAnnex;
}

export type ShowEditAnnexStateDialogAction = Action<Type.SHOW_EDIT_ANNEX_STATE_DIALOG>;

export type HideEditAnnexStateDialogAction = Action<Type.HIDE_EDIT_ANNEX_STATE_DIALOG>;

export type ShowSaveAdvancedFilterDialogAction = Action<Type.SHOW_SAVE_ADVANCED_FILTER_DIALOG>;

export type HideSaveAdvancedFilterDialogAction = Action<Type.HIDE_SAVE_ADVANCED_FILTER_DIALOG>;

export type ShowApplyAdvancedFiltersDialogAction = Action<Type.SHOW_APPLY_ADVANCED_FILTERS_DIALOG>;

export type HideApplyAdvancedFiltersDialogAction = Action<Type.HIDE_APPLY_ADVANCED_FILTERS_DIALOG>;

export type ShowShareAdvancedFilterDialogAction = Action<Type.SHOW_SHARE_ADVANCED_FILTER_DIALOG>;

export type HideShareAdvancedFilterDialogAction = Action<Type.HIDE_SHARE_ADVANCED_FILTER_DIALOG>;

export type ShowUploadAnnexDialogAction = Action<Type.SHOW_LOAD_ANNEX_DIALOG>;

export type HideUploadAnnexDialogAction = Action<Type.HIDE_LOAD_ANNEX_DIALOG>;

export type ShowChangeCompanyDialogAction = Action<Type.SHOW_CHANGE_COMPANY_DIALOG>;

export type HideChangeCompanyDialogAction = Action<Type.HIDE_CHANGE_COMPANY_DIALOG>;

export interface SetChangeCompanyDialogCompaniesAction extends Action<Type.SET_CHANGE_COMPANY_DIALOG_COMPANIES> {
  companies: ICompanyCombo[];
}

export type ShowContextualHelpDialogAction = Action<Type.SHOW_CONTEXTUAL_HELP_DIALOG>;

export type HideContextualHelpDialogAction = Action<Type.HIDE_CONTEXTUAL_HELP_DIALOG>;

export interface SetContextualHelpDialogCodeAction extends Action<Type.SET_CONTEXTUAL_HELP_DIALOG_CODE> {
  code: string;
}

export type ShowSimulateExternalUserDialogAction = Action<Type.SHOW_SIMULATE_EXTERNAL_USER_DIALOG>;

export type HideSimulateExternalUserDialogAction = Action<Type.HIDE_SIMULATE_EXTERNAL_USER_DIALOG>;

export type ShowEditCompanySupplierPurchaseProductsDialogAction = Action<Type.SHOW_EDIT_COMPANY_SUPPLIER_PURCHASE_PRODUCTS_DIALOG>;

export type HideEditCompanySupplierPurchaseProductsDialogAction = Action<Type.HIDE_EDIT_COMPANY_SUPPLIER_PURCHASE_PRODUCTS_DIALOG>;

export interface SetEditCompanySupplierPurchaseProductsDialogCompanyIdAction extends Action<Type.SET_EDIT_COMPANY_SUPPLIER_PURCHASE_PRODUCTS_DIALOG_COMPANY_ID> {
  companyId: string;
}

export interface SetEditCompanySupplierPurchaseProductsDialogSupplierIdAction extends Action<Type.SET_EDIT_COMPANY_SUPPLIER_PURCHASE_PRODUCTS_DIALOG_SUPPLIER_ID> {
  supplierId: string;
}

export interface SetEditCompanySupplierPurchaseProductsDialogSupplierProductsAction extends Action<Type.SET_EDIT_COMPANY_SUPPLIER_PURCHASE_PRODUCTS_DIALOG_SUPPLIER_PRODUCTS> {
  supplierProducts: ISupplierProduct[];
}

export interface SetEditCompanySupplierPurchaseProductsDialogIsFetchingAction extends Action<Type.SET_EDIT_COMPANY_SUPPLIER_PURCHASE_PRODUCTS_DIALOG_IS_FETCHING> {
  isFetching: boolean;
}

export type ShowEditReportCustomFieldSettingsDialogAction = Action<Type.SHOW_EDIT_REPORT_CUSTOM_FIELD_SETTINGS_DIALOG>;

export type HideEditReportCustomFieldSettingsDialogAction = Action<Type.HIDE_EDIT_REPORT_CUSTOM_FIELD_SETTINGS_DIALOG>;

export interface SetEditReportCustomFieldSettingsDialogCompanyIdAction extends Action<Type.SET_EDIT_REPORT_CUSTOM_FIELD_SETTINGS_DIALOG_COMPANY_ID> {
  companyId: string;
}

export interface SetEditReportCustomFieldSettingsDialogReportCustomFieldCodesAction extends Action<Type.SET_EDIT_REPORT_CUSTOM_FIELD_SETTINGS_DIALOG_REPORT_CUSTOM_FIELD_CODES> {
  reportCustomFieldCodes: string[];
}

// tslint:disable-next-line: max-line-length
export interface SetEditReportCustomFieldSettingsDialogReportCustomFieldSettingsWithCheckboxesAction extends Action<Type.SET_EDIT_REPORT_CUSTOM_FIELD_SETTINGS_DIALOG_REPORT_CUSTOM_FIELD_SETTINGS_WITH_CHECKBOXES> {
  reportCustomFieldSettingsWithCheckboxes: IReportCustomFieldSettingsWithCheckboxes[];
}

export interface SetEditReportCustomFieldSettingsDialogIsFetchingAction extends Action<Type.SET_EDIT_REPORT_CUSTOM_FIELD_SETTINGS_DIALOG_IS_FETCHING> {
  isFetching: boolean;
}

export interface SetEditReportCustomFieldSettingsDialogErrorsAction extends Action<Type.SET_EDIT_REPORT_CUSTOM_FIELD_SETTINGS_DIALOG_ERRORS> {
  errors: IErrors;
}

export interface PrepareRequestCertificateRenewalDialogAction extends Action<Type.PREPARE_REQUEST_CERTIFICATE_RENEWAL_DIALOG> {
  cancellationMode: boolean;
  certificatesIds: string[];
}

export type ShowRequestCertificateRenewalDialogAction = Action<Type.SHOW_REQUEST_CERTIFICATE_RENEWAL_DIALOG>;

export type HideRequestCertificateRenewalDialogAction = Action<Type.HIDE_REQUEST_CERTIFICATE_RENEWAL_DIALOG>;

export interface PrepareReportVersionsDialogAction extends Action<Type.PREPARE_REPORT_VERSIONS_DIALOG> {
  reportCombo: IReportCombo;
}

export type ShowReportVersionsDialogAction = Action<Type.SHOW_REPORT_VERSIONS_DIALOG>;

export type HideReportVersionsDialogAction = Action<Type.HIDE_REPORT_VERSIONS_DIALOG>;

export interface SetReportVersionsDialogReportsAction extends Action<Type.SET_REPORT_VERSIONS_DIALOG_REPORTS> {
  reports: IReport[];
}

export interface SetReportVersionsDialogIsFetchingAction extends Action<Type.SET_REPORT_VERSIONS_DIALOG_IS_FETCHING> {
  isFetching: boolean;
}

export type ShowEditChainSeriesDialogAction = Action<Type.SHOW_EDIT_CHAIN_SERIES_DIALOG>;

export type HideEditChainSeriesDialogAction = Action<Type.HIDE_EDIT_CHAIN_SERIES_DIALOG>;

export interface SetEditChainSeriesDialogChainAction extends Action<Type.SET_EDIT_CHAIN_SERIES_DIALOG_CHAIN> {
  chain: IChain;
}

export type ShowCreateNoteDialogAction = Action<Type.SHOW_CREATE_NOTE_DIALOG>;

export type HideCreateNoteDialogAction = Action<Type.HIDE_CREATE_NOTE_DIALOG>;

export interface PrepareSendReportEmailDialogAction extends Action<Type.PREPARE_SEND_REPORT_EMAIL_DIALOG> {
  report: IReport;
}

export type ShowSendReportEmailDialogAction = Action<Type.SHOW_SEND_REPORT_EMAIL_DIALOG>;

export type HideSendReportEmailDialogAction = Action<Type.HIDE_SEND_REPORT_EMAIL_DIALOG>;

export interface PrepareShareCertificateEmailDialogAction extends Action<Type.PREPARE_SHARE_CERTIFICATE_EMAIL_DIALOG> {
  certificate: ICertificate;
}

export type ShowShareCertificateEmailDialogAction = Action<Type.SHOW_SHARE_CERTIFICATE_EMAIL_DIALOG>;

export type HideShareCertificateEmailDialogAction = Action<Type.HIDE_SHARE_CERTIFICATE_EMAIL_DIALOG>;

export type ShowUserFeedbackDialogAction = Action<Type.SHOW_USER_FEEDBACK_DIALOG>;

export type HideUserFeedbackDialogAction = Action<Type.HIDE_USER_FEEDBACK_DIALOG>;

export const reset: ActionCreator<ResetAction> = () => ({
  type: Type.RESET
});

export const showGenericConfirmationDialog: ActionCreator<ShowGenericConfirmationDialogAction> = () => ({
  type: Type.SHOW_GENERIC_CONFIRMATION_DIALOG
});

export const hideGenericConfirmationDialog: ActionCreator<HideGenericConfirmationDialogAction> = () => ({
  type: Type.HIDE_GENERIC_CONFIRMATION_DIALOG
});

export const showDiscardChangesDialog: ActionCreator<ShowDiscardChangesDialogAction> = () => ({
  type: Type.SHOW_DISCARD_CHANGES_DIALOG
});

export const hideDiscardChangesDialog: ActionCreator<HideDiscardChangesDialogAction> = () => ({
  type: Type.HIDE_DISCARD_CHANGES_DIALOG
});

export const prepareDeactivateEntityDialog: ActionCreator<PrepareDeactivateEntityDialogAction> = (
  entityId: string,
  entityName: string,
  deactivate: (entityId: string) => Promise<void>
) => ({
  type: Type.PREPARE_DEACTIVATE_ENTITY_DIALOG,
  entityId,
  entityName,
  deactivate
});

export const showDeactivateEntityDialog: ActionCreator<ShowDeactivateEntityDialogAction> = () => ({
  type: Type.SHOW_DEACTIVATE_ENTITY_DIALOG
});

export const hideDeactivateEntityDialog: ActionCreator<HideDeactivateEntityDialogAction> = () => ({
  type: Type.HIDE_DEACTIVATE_ENTITY_DIALOG
});

export const prepareEditAnnexDialog: ActionCreator<PrepareEditAnnexDialogAction> = (annex: IAnnex) => ({
  type: Type.PREPARE_EDIT_ANNEX_DIALOG,
  annex
});

export const showEditAnnexDialog: ActionCreator<ShowEditAnnexDialogAction> = () => ({
  type: Type.SHOW_EDIT_ANNEX_DIALOG
});

export const hideEditAnnexDialog: ActionCreator<HideEditAnnexDialogAction> = () => ({
  type: Type.HIDE_EDIT_ANNEX_DIALOG
});

export const prepareEditAnnexStateDialog: ActionCreator<PrepareEditAnnexStateDialogAction> = (annex: IAnnex) => ({
  type: Type.PREPARE_EDIT_ANNEX_STATE_DIALOG,
  annex
});

export const showEditAnnexStateDialog: ActionCreator<ShowEditAnnexStateDialogAction> = () => ({
  type: Type.SHOW_EDIT_ANNEX_STATE_DIALOG
});

export const hideEditAnnexStateDialog: ActionCreator<HideEditAnnexStateDialogAction> = () => ({
  type: Type.HIDE_EDIT_ANNEX_STATE_DIALOG
});

export const showSaveAdvancedFilterDialog: ActionCreator<ShowSaveAdvancedFilterDialogAction> = () => ({
  type: Type.SHOW_SAVE_ADVANCED_FILTER_DIALOG
});

export const hideSaveAdvancedFilterDialog: ActionCreator<HideSaveAdvancedFilterDialogAction> = () => ({
  type: Type.HIDE_SAVE_ADVANCED_FILTER_DIALOG
});

export const showApplyAdvancedFiltersDialog: ActionCreator<ShowApplyAdvancedFiltersDialogAction> = () => ({
  type: Type.SHOW_APPLY_ADVANCED_FILTERS_DIALOG
});

export const hideApplyAdvancedFiltersDialog: ActionCreator<HideApplyAdvancedFiltersDialogAction> = () => ({
  type: Type.HIDE_APPLY_ADVANCED_FILTERS_DIALOG
});

export const showShareAdvancedFilterDialog: ActionCreator<ShowShareAdvancedFilterDialogAction> = () => ({
  type: Type.SHOW_SHARE_ADVANCED_FILTER_DIALOG
});

export const hideShareAdvancedFilterDialog: ActionCreator<HideShareAdvancedFilterDialogAction> = () => ({
  type: Type.HIDE_SHARE_ADVANCED_FILTER_DIALOG
});

export const showUploadAnnexDialog: ActionCreator<ShowUploadAnnexDialogAction> = () => ({
  type: Type.SHOW_LOAD_ANNEX_DIALOG
});

export const hideUploadAnnexDialog: ActionCreator<HideUploadAnnexDialogAction> = () => ({
  type: Type.HIDE_LOAD_ANNEX_DIALOG
});

export const showChangeCompanyDialog: ActionCreator<ShowChangeCompanyDialogAction> = () => ({
  type: Type.SHOW_CHANGE_COMPANY_DIALOG
});

export const hideChangeCompanyDialog: ActionCreator<HideChangeCompanyDialogAction> = () => ({
  type: Type.HIDE_CHANGE_COMPANY_DIALOG
});

export const setChangeCompanyDialogCompanies: ActionCreator<SetChangeCompanyDialogCompaniesAction> = (companies: ICompanyCombo[]) => ({
  type: Type.SET_CHANGE_COMPANY_DIALOG_COMPANIES,
  companies
});

export const showContextualHelpDialog: ActionCreator<ShowContextualHelpDialogAction> = () => ({
  type: Type.SHOW_CONTEXTUAL_HELP_DIALOG
});

export const hideContextualHelpDialog: ActionCreator<HideContextualHelpDialogAction> = () => ({
  type: Type.HIDE_CONTEXTUAL_HELP_DIALOG
});

export const setContextualHelpDialogCode: ActionCreator<SetContextualHelpDialogCodeAction> = (code: string) => ({
  type: Type.SET_CONTEXTUAL_HELP_DIALOG_CODE,
  code
});

export const showSimulateExternalUserDialog: ActionCreator<ShowSimulateExternalUserDialogAction> = () => ({
  type: Type.SHOW_SIMULATE_EXTERNAL_USER_DIALOG
});

export const hideSimulateExternalUserDialog: ActionCreator<HideSimulateExternalUserDialogAction> = () => ({
  type: Type.HIDE_SIMULATE_EXTERNAL_USER_DIALOG
});

export const showEditCompanySupplierPurchaseProductsDialog: ActionCreator<ShowEditCompanySupplierPurchaseProductsDialogAction> = () => ({
  type: Type.SHOW_EDIT_COMPANY_SUPPLIER_PURCHASE_PRODUCTS_DIALOG
});

export const hideEditCompanySupplierPurchaseProductsDialog: ActionCreator<HideEditCompanySupplierPurchaseProductsDialogAction> = () => ({
  type: Type.HIDE_EDIT_COMPANY_SUPPLIER_PURCHASE_PRODUCTS_DIALOG
});

export const setEditCompanySupplierPurchaseProductsDialogCompanyId: ActionCreator<SetEditCompanySupplierPurchaseProductsDialogCompanyIdAction> = (companyId: string) => ({
  type: Type.SET_EDIT_COMPANY_SUPPLIER_PURCHASE_PRODUCTS_DIALOG_COMPANY_ID,
  companyId
});

export const setEditCompanySupplierPurchaseProductsDialogSupplierId: ActionCreator<SetEditCompanySupplierPurchaseProductsDialogSupplierIdAction> = (supplierId: string) => ({
  type: Type.SET_EDIT_COMPANY_SUPPLIER_PURCHASE_PRODUCTS_DIALOG_SUPPLIER_ID,
  supplierId
});

export const setEditCompanySupplierPurchaseProductsDialogSupplierProducts: ActionCreator<SetEditCompanySupplierPurchaseProductsDialogSupplierProductsAction> = (
  supplierProducts: ISupplierProduct[]
) => ({
  type: Type.SET_EDIT_COMPANY_SUPPLIER_PURCHASE_PRODUCTS_DIALOG_SUPPLIER_PRODUCTS,
  supplierProducts
});

export const setEditCompanySupplierPurchaseProductsDialogIsFetching: ActionCreator<SetEditCompanySupplierPurchaseProductsDialogIsFetchingAction> = (isFetching: boolean) => ({
  type: Type.SET_EDIT_COMPANY_SUPPLIER_PURCHASE_PRODUCTS_DIALOG_IS_FETCHING,
  isFetching
});

export const showEditReportCustomFieldSettingsDialog: ActionCreator<ShowEditReportCustomFieldSettingsDialogAction> = () => ({
  type: Type.SHOW_EDIT_REPORT_CUSTOM_FIELD_SETTINGS_DIALOG
});

export const hideEditReportCustomFieldSettingsDialog: ActionCreator<HideEditReportCustomFieldSettingsDialogAction> = () => ({
  type: Type.HIDE_EDIT_REPORT_CUSTOM_FIELD_SETTINGS_DIALOG
});

export const setEditReportCustomFieldSettingsDialogCompanyId: ActionCreator<SetEditReportCustomFieldSettingsDialogCompanyIdAction> = (companyId: string) => ({
  type: Type.SET_EDIT_REPORT_CUSTOM_FIELD_SETTINGS_DIALOG_COMPANY_ID,
  companyId
});

export const setEditReportCustomFieldSettingsDialogReportCustomFieldCodes: ActionCreator<SetEditReportCustomFieldSettingsDialogReportCustomFieldCodesAction> = (reportCustomFieldCodes: string[]) => ({
  type: Type.SET_EDIT_REPORT_CUSTOM_FIELD_SETTINGS_DIALOG_REPORT_CUSTOM_FIELD_CODES,
  reportCustomFieldCodes
});

export const setEditReportCustomFieldSettingsDialogReportCustomFieldSettingsWithCheckboxes: ActionCreator<SetEditReportCustomFieldSettingsDialogReportCustomFieldSettingsWithCheckboxesAction> =
  (reportCustomFieldSettingsWithCheckboxes: IReportCustomFieldSettingsWithCheckboxes[]) => ({
    type: Type.SET_EDIT_REPORT_CUSTOM_FIELD_SETTINGS_DIALOG_REPORT_CUSTOM_FIELD_SETTINGS_WITH_CHECKBOXES,
    reportCustomFieldSettingsWithCheckboxes
  });

export const setEditReportCustomFieldSettingsDialogIsFetching: ActionCreator<SetEditReportCustomFieldSettingsDialogIsFetchingAction> = (isFetching: boolean) => ({
  type: Type.SET_EDIT_REPORT_CUSTOM_FIELD_SETTINGS_DIALOG_IS_FETCHING,
  isFetching
});

export const setEditReportCustomFieldSettingsDialogErrors: ActionCreator<SetEditReportCustomFieldSettingsDialogErrorsAction> = (errors: IErrors) => ({
  type: Type.SET_EDIT_REPORT_CUSTOM_FIELD_SETTINGS_DIALOG_ERRORS,
  errors
});

export const prepareRequestCertificateRenewalDialog: ActionCreator<PrepareRequestCertificateRenewalDialogAction> = (cancellationMode: boolean, certificatesIds: string[]) => ({
  type: Type.PREPARE_REQUEST_CERTIFICATE_RENEWAL_DIALOG,
  cancellationMode,
  certificatesIds
});

export const showRequestCertificateRenewalDialog: ActionCreator<ShowRequestCertificateRenewalDialogAction> = () => ({
  type: Type.SHOW_REQUEST_CERTIFICATE_RENEWAL_DIALOG
});

export const hideRequestCertificateRenewalDialog: ActionCreator<HideRequestCertificateRenewalDialogAction> = () => ({
  type: Type.HIDE_REQUEST_CERTIFICATE_RENEWAL_DIALOG
});

export const prepareReportVersionsDialog: ActionCreator<PrepareReportVersionsDialogAction> = (reportCombo: IReportCombo) => ({
  type: Type.PREPARE_REPORT_VERSIONS_DIALOG,
  reportCombo
});

export const showReportVersionsDialog: ActionCreator<ShowReportVersionsDialogAction> = () => ({
  type: Type.SHOW_REPORT_VERSIONS_DIALOG
});

export const hideReportVersionsDialog: ActionCreator<HideReportVersionsDialogAction> = () => ({
  type: Type.HIDE_REPORT_VERSIONS_DIALOG
});

export const setReportVersionsDialogReports: ActionCreator<SetReportVersionsDialogReportsAction> = (reports: IReport[]) => ({
  type: Type.SET_REPORT_VERSIONS_DIALOG_REPORTS,
  reports
});

export const setReportVersionsDialogIsFetching: ActionCreator<SetReportVersionsDialogIsFetchingAction> = (isFetching: boolean) => ({
  type: Type.SET_REPORT_VERSIONS_DIALOG_IS_FETCHING,
  isFetching
});

export const showEditChainSeriesDialog: ActionCreator<ShowEditChainSeriesDialogAction> = () => ({
  type: Type.SHOW_EDIT_CHAIN_SERIES_DIALOG
});

export const hideEditChainSeriesDialog: ActionCreator<HideEditChainSeriesDialogAction> = () => ({
  type: Type.HIDE_EDIT_CHAIN_SERIES_DIALOG
});

export const setEditChainSeriesDialogChain: ActionCreator<SetEditChainSeriesDialogChainAction> = (chain: IChain) => ({
  type: Type.SET_EDIT_CHAIN_SERIES_DIALOG_CHAIN,
  chain
});

export const showCreateNoteDialog: ActionCreator<ShowCreateNoteDialogAction> = () => ({
  type: Type.SHOW_CREATE_NOTE_DIALOG
});

export const hideCreateNoteDialog: ActionCreator<HideCreateNoteDialogAction> = () => ({
  type: Type.HIDE_CREATE_NOTE_DIALOG
});

export const prepareSendReportEmailDialog: ActionCreator<PrepareSendReportEmailDialogAction> = (report: IReport) => ({
  type: Type.PREPARE_SEND_REPORT_EMAIL_DIALOG,
  report
});

export const showSendReportEmailDialog: ActionCreator<ShowSendReportEmailDialogAction> = () => ({
  type: Type.SHOW_SEND_REPORT_EMAIL_DIALOG
});

export const hideSendReportEmailDialog: ActionCreator<HideSendReportEmailDialogAction> = () => ({
  type: Type.HIDE_SEND_REPORT_EMAIL_DIALOG
});

export const prepareShareCertificateEmailDialog: ActionCreator<PrepareShareCertificateEmailDialogAction> = (certificate: ICertificate) => ({
  type: Type.PREPARE_SHARE_CERTIFICATE_EMAIL_DIALOG,
  certificate
});

export const showShareCertificateEmailDialog: ActionCreator<ShowShareCertificateEmailDialogAction> = () => ({
  type: Type.SHOW_SHARE_CERTIFICATE_EMAIL_DIALOG
});

export const hideShareCertificateEmailDialog: ActionCreator<HideShareCertificateEmailDialogAction> = () => ({
  type: Type.HIDE_SHARE_CERTIFICATE_EMAIL_DIALOG
});

export const showUserFeedbackDialog: ActionCreator<ShowUserFeedbackDialogAction> = () => ({
  type: Type.SHOW_USER_FEEDBACK_DIALOG
});

export const hideUserFeedbackDialog: ActionCreator<HideUserFeedbackDialogAction> = () => ({
  type: Type.HIDE_USER_FEEDBACK_DIALOG
});

export const fetchCompanies: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (oDataService: IODataService, companiesDataManager: DataManager) => (
  async (dispatch, getState) => {
    let companies: ICompanyCombo[] = [];

    const currentUser = getState().currentUserStore.user;
    const currentUserCompany = getState().currentUserStore.company;

    if (currentUser && currentUserCompany) {
      const predicates = currentUser.companies
        .filter((userCompany) => userCompany.companyId !== currentUserCompany.id)
        .map((userCompany) => new Predicate('Id', RuleOperator.EQUAL, userCompany.companyId));
      if (predicates.length) {
        const query = new Query()
          .select(['Id', 'Name'])
          .where(predicates.reduce((previousValue: Predicate, currentValue: Predicate) => previousValue.or(currentValue)))
          .where('active', RuleOperator.EQUAL, true)
          .where('isLocked', RuleOperator.EQUAL, false);
        companies = await oDataService.executeQuery(companiesDataManager, query);
      }
    }

    dispatch(setChangeCompanyDialogCompanies(companies));
  }
);

export const fetchSupplierProducts: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (companiesService: ICompaniesService) => (
  async (dispatch, getState) => {
    dispatch(setEditCompanySupplierPurchaseProductsDialogIsFetching(true));

    let supplierProducts: ISupplierProduct[] = [];

    const companyId = getState().dialogsStore.editCompanySupplierPurchaseProductsDialog.companyId;
    const supplierId = getState().dialogsStore.editCompanySupplierPurchaseProductsDialog.supplierId;

    if (companyId && supplierId) {
      supplierProducts = await companiesService.fetchSupplierProducts(companyId, supplierId);
      dispatch(setEditCompanySupplierPurchaseProductsDialogSupplierProducts(supplierProducts));
    }

    dispatch(setEditCompanySupplierPurchaseProductsDialogIsFetching(false));
  }
);

export const saveSupplierProduct: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (
  supplierProduct: ISupplierProduct,
  supplierProductsService: ISupplierProductsService,
  companiesService: ICompaniesService
) => (
    async (dispatch, getState) => {
      try {
        dispatch(setEditCompanySupplierPurchaseProductsDialogIsFetching(true));

        supplierProduct.id ? await supplierProductsService.update(supplierProduct) : await supplierProductsService.create(supplierProduct);
      } catch (error) {
        // TODO: manejar error
      } finally {
        let supplierProducts: ISupplierProduct[] = [];

        const companyId = getState().dialogsStore.editCompanySupplierPurchaseProductsDialog.companyId;
        const supplierId = getState().dialogsStore.editCompanySupplierPurchaseProductsDialog.supplierId;

        if (companyId && supplierId) {
          supplierProducts = await companiesService.fetchSupplierProducts(companyId, supplierId);
          dispatch(setEditCompanySupplierPurchaseProductsDialogSupplierProducts(supplierProducts));
        }

        dispatch(setEditCompanySupplierPurchaseProductsDialogIsFetching(false));
      }
    }
  );

export const fetchReportCustomFieldCodes: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (reportCustomFieldSettingsService: IReportCustomFieldSettingsService) => (
  async (dispatch) => {
    dispatch(setEditReportCustomFieldSettingsDialogReportCustomFieldCodes(await reportCustomFieldSettingsService.fetchCodes()));
  }
);

export const fetchReportCustomFieldSettings: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (companiesService: ICompaniesService) => (
  async (dispatch, getState) => {
    dispatch(setEditReportCustomFieldSettingsDialogIsFetching(true));

    let reportCustomFieldSettingsWithCheckboxes: IReportCustomFieldSettingsWithCheckboxes[] = [];

    const companyId = getState().dialogsStore.editReportCustomFieldSettingsDialog.companyId;

    if (companyId) {
      reportCustomFieldSettingsWithCheckboxes = (await companiesService.fetchReportCustomFieldSettings(companyId))
        .map((reportCustomFieldSettings) => reportCustomFieldSettingsUtils.toWithCheckboxes(reportCustomFieldSettings));
      dispatch(setEditReportCustomFieldSettingsDialogReportCustomFieldSettingsWithCheckboxes(reportCustomFieldSettingsWithCheckboxes));
    }

    dispatch(setEditReportCustomFieldSettingsDialogIsFetching(false));
  }
);

export const saveReportCustomFieldSettings: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (
  reportCustomFieldSettingsWithCheckboxes: IReportCustomFieldSettingsWithCheckboxes,
  reportCustomFieldSettingsService: IReportCustomFieldSettingsService,
  companiesService: ICompaniesService
) => (
    async (dispatch, getState) => {
      try {
        dispatch(setEditReportCustomFieldSettingsDialogErrors(initialState.editReportCustomFieldSettingsDialog.errors));

        dispatch(setEditReportCustomFieldSettingsDialogIsFetching(true));

        const reportCustomFieldSettings = reportCustomFieldSettingsUtils.fromWithCheckboxes(reportCustomFieldSettingsWithCheckboxes);
        reportCustomFieldSettings.id ? await reportCustomFieldSettingsService.update(reportCustomFieldSettings) : await reportCustomFieldSettingsService.create(reportCustomFieldSettings);
      } catch (error) {
        dispatch(setEditReportCustomFieldSettingsDialogErrors(error as IErrors));
      } finally {
        let reportCustomFieldSettingsWithCheckboxesList: IReportCustomFieldSettingsWithCheckboxes[] = [];

        const companyId = getState().dialogsStore.editReportCustomFieldSettingsDialog.companyId;

        if (companyId) {
          reportCustomFieldSettingsWithCheckboxesList = (await companiesService.fetchReportCustomFieldSettings(companyId))
            .map((reportCustomFieldSettings) => reportCustomFieldSettingsUtils.toWithCheckboxes(reportCustomFieldSettings));
          dispatch(setEditReportCustomFieldSettingsDialogReportCustomFieldSettingsWithCheckboxes(reportCustomFieldSettingsWithCheckboxesList));
        }

        dispatch(setEditReportCustomFieldSettingsDialogIsFetching(false));
      }
    }
  );

export const fetchReportVersions: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (reportsService: IReportsService) => (
  async (dispatch, getState) => {
    dispatch(setReportVersionsDialogIsFetching(true));

    let reports: IReport[] = [];

    const reportCombo = getState().dialogsStore.reportVersionsDialog.reportCombo;

    if (reportCombo) {
      reports = await reportsService.fetchVersions(reportCombo.id);
      dispatch(setReportVersionsDialogReports(reports));
    }

    dispatch(setReportVersionsDialogIsFetching(false));
  }
);

// Reducers

const genericConfirmationDialog: Reducer<IGenericConfirmationDialogState> = (state = initialState.genericConfirmationDialog, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.genericConfirmationDialog;
    case Type.SHOW_GENERIC_CONFIRMATION_DIALOG:
      return { ...state, visible: true };
    case Type.HIDE_GENERIC_CONFIRMATION_DIALOG:
      return { ...state, visible: false };
    default:
      return state;
  }
};

const discardChangesDialog: Reducer<IDiscardChangesDialogState> = (state = initialState.discardChangesDialog, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.discardChangesDialog;
    case Type.SHOW_DISCARD_CHANGES_DIALOG:
      return { ...state, visible: true };
    case Type.HIDE_DISCARD_CHANGES_DIALOG:
      return { ...state, visible: false };
    default:
      return state;
  }
};

const deactivateEntityDialog: Reducer<IDeactivateEntityDialogState> = (state = initialState.deactivateEntityDialog, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.deactivateEntityDialog;
    case Type.PREPARE_DEACTIVATE_ENTITY_DIALOG: {
      const prepareDeactivateEntityDialogAction = action as PrepareDeactivateEntityDialogAction;
      return {
        ...state,
        entityId: prepareDeactivateEntityDialogAction.entityId,
        entityName: prepareDeactivateEntityDialogAction.entityName,
        deactivate: prepareDeactivateEntityDialogAction.deactivate
      };
    }
    case Type.SHOW_DEACTIVATE_ENTITY_DIALOG:
      return { ...state, visible: true };
    case Type.HIDE_DEACTIVATE_ENTITY_DIALOG:
      return {
        ...state,
        visible: false,
        entityId: initialState.deactivateEntityDialog.entityId,
        entityName: initialState.deactivateEntityDialog.entityName,
        deactivate: initialState.deactivateEntityDialog.deactivate
      };
    default:
      return state;
  }
};

const editAnnexDialog: Reducer<IEditAnnexDialogState> = (state = initialState.editAnnexDialog, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.editAnnexDialog;
    case Type.PREPARE_EDIT_ANNEX_DIALOG:
      return { ...state, annex: (action as PrepareEditAnnexDialogAction).annex };
    case Type.SHOW_EDIT_ANNEX_DIALOG:
      return { ...state, visible: true };
    case Type.HIDE_EDIT_ANNEX_DIALOG:
      return { ...state, visible: false, annex: initialState.editAnnexDialog.annex };
    default:
      return state;
  }
};

const editAnnexStateDialog: Reducer<IEditAnnexStateDialogState> = (state = initialState.editAnnexStateDialog, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.editAnnexStateDialog;
    case Type.PREPARE_EDIT_ANNEX_STATE_DIALOG:
      return { ...state, annex: (action as PrepareEditAnnexStateDialogAction).annex };
    case Type.SHOW_EDIT_ANNEX_STATE_DIALOG:
      return { ...state, visible: true };
    case Type.HIDE_EDIT_ANNEX_STATE_DIALOG:
      return { ...state, visible: false, annex: initialState.editAnnexStateDialog.annex };
    default:
      return state;
  }
};

const saveAdvancedFilterDialog: Reducer<ISaveAdvancedFilterDialogState> = (state = initialState.saveAdvancedFilterDialog, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.saveAdvancedFilterDialog;
    case Type.SHOW_SAVE_ADVANCED_FILTER_DIALOG:
      return { ...state, visible: true };
    case Type.HIDE_SAVE_ADVANCED_FILTER_DIALOG:
      return { ...state, visible: false };
    default:
      return state;
  }
};

const applyAdvancedFiltersDialog: Reducer<IApplyAdvancedFiltersDialogState> = (state = initialState.applyAdvancedFiltersDialog, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.applyAdvancedFiltersDialog;
    case Type.SHOW_APPLY_ADVANCED_FILTERS_DIALOG:
      return { ...state, visible: true };
    case Type.HIDE_APPLY_ADVANCED_FILTERS_DIALOG:
      return { ...state, visible: false };
    default:
      return state;
  }
};

const shareAdvancedFilterDialog: Reducer<IShareAdvancedFilterDialogState> = (state = initialState.shareAdvancedFilterDialog, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.shareAdvancedFilterDialog;
    case Type.SHOW_SHARE_ADVANCED_FILTER_DIALOG:
      return { ...state, visible: true };
    case Type.HIDE_SHARE_ADVANCED_FILTER_DIALOG:
      return { ...state, visible: false };
    default:
      return state;
  }
};

const uploadAnnexDialog: Reducer<IUploadAnnexDialogState> = (state = initialState.uploadAnnexDialog, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.uploadAnnexDialog;
    case Type.SHOW_LOAD_ANNEX_DIALOG:
      return { ...state, visible: true };
    case Type.HIDE_LOAD_ANNEX_DIALOG:
      return { ...state, visible: false };
    default:
      return state;
  }
};

const changeCompanyDialog: Reducer<IChangeCompanyDialogState> = (state = initialState.changeCompanyDialog, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.changeCompanyDialog;
    case Type.SHOW_CHANGE_COMPANY_DIALOG:
      return { ...state, visible: true };
    case Type.HIDE_CHANGE_COMPANY_DIALOG:
      return { ...state, visible: false, companies: initialState.changeCompanyDialog.companies };
    case Type.SET_CHANGE_COMPANY_DIALOG_COMPANIES:
      return { ...state, companies: (action as SetChangeCompanyDialogCompaniesAction).companies };
    default:
      return state;
  }
};

const contextualHelpDialog: Reducer<IContextualHelpDialogState> = (state = initialState.contextualHelpDialog, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.contextualHelpDialog;
    case Type.SHOW_CONTEXTUAL_HELP_DIALOG:
      return { ...state, visible: true };
    case Type.HIDE_CONTEXTUAL_HELP_DIALOG:
      return { ...state, visible: false, code: initialState.contextualHelpDialog.code };
    case Type.SET_CONTEXTUAL_HELP_DIALOG_CODE:
      return { ...state, code: (action as SetContextualHelpDialogCodeAction).code };
    default:
      return state;
  }
};

const simulateExternalUserDialog: Reducer<ISimulateExternalUserDialogState> = (state = initialState.simulateExternalUserDialog, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.simulateExternalUserDialog;
    case Type.SHOW_SIMULATE_EXTERNAL_USER_DIALOG:
      return { ...state, visible: true };
    case Type.HIDE_SIMULATE_EXTERNAL_USER_DIALOG:
      return { ...state, visible: false };
    default:
      return state;
  }
};

const editCompanySupplierPurchaseProductsDialog: Reducer<IEditCompanySupplierPurchaseProductsDialogState> = (state = initialState.editCompanySupplierPurchaseProductsDialog, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.editCompanySupplierPurchaseProductsDialog;
    case Type.SHOW_EDIT_COMPANY_SUPPLIER_PURCHASE_PRODUCTS_DIALOG:
      return { ...state, visible: true };
    case Type.HIDE_EDIT_COMPANY_SUPPLIER_PURCHASE_PRODUCTS_DIALOG:
      return {
        ...state,
        visible: false,
        companyId: initialState.editCompanySupplierPurchaseProductsDialog.companyId,
        supplierId: initialState.editCompanySupplierPurchaseProductsDialog.supplierId,
        supplierProducts: initialState.editCompanySupplierPurchaseProductsDialog.supplierProducts,
        isFetching: initialState.editCompanySupplierPurchaseProductsDialog.isFetching
      };
    case Type.SET_EDIT_COMPANY_SUPPLIER_PURCHASE_PRODUCTS_DIALOG_COMPANY_ID:
      return { ...state, companyId: (action as SetEditCompanySupplierPurchaseProductsDialogCompanyIdAction).companyId };
    case Type.SET_EDIT_COMPANY_SUPPLIER_PURCHASE_PRODUCTS_DIALOG_SUPPLIER_ID:
      return { ...state, supplierId: (action as SetEditCompanySupplierPurchaseProductsDialogSupplierIdAction).supplierId };
    case Type.SET_EDIT_COMPANY_SUPPLIER_PURCHASE_PRODUCTS_DIALOG_SUPPLIER_PRODUCTS:
      return { ...state, supplierProducts: (action as SetEditCompanySupplierPurchaseProductsDialogSupplierProductsAction).supplierProducts };
    case Type.SET_EDIT_COMPANY_SUPPLIER_PURCHASE_PRODUCTS_DIALOG_IS_FETCHING:
      return { ...state, isFetching: (action as SetEditCompanySupplierPurchaseProductsDialogIsFetchingAction).isFetching };
    default:
      return state;
  }
};

const editReportCustomFieldSettingsDialog: Reducer<IEditReportCustomFieldSettingsDialogState> = (state = initialState.editReportCustomFieldSettingsDialog, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.editReportCustomFieldSettingsDialog;
    case Type.SHOW_EDIT_REPORT_CUSTOM_FIELD_SETTINGS_DIALOG:
      return { ...state, visible: true };
    case Type.HIDE_EDIT_REPORT_CUSTOM_FIELD_SETTINGS_DIALOG:
      return {
        ...state,
        visible: false,
        companyId: initialState.editReportCustomFieldSettingsDialog.companyId,
        reportCustomFieldCodes: initialState.editReportCustomFieldSettingsDialog.reportCustomFieldCodes,
        reportCustomFieldSettingsWithCheckboxes: initialState.editReportCustomFieldSettingsDialog.reportCustomFieldSettingsWithCheckboxes,
        isFetching: initialState.editReportCustomFieldSettingsDialog.isFetching
      };
    case Type.SET_EDIT_REPORT_CUSTOM_FIELD_SETTINGS_DIALOG_COMPANY_ID:
      return { ...state, companyId: (action as SetEditReportCustomFieldSettingsDialogCompanyIdAction).companyId };
    case Type.SET_EDIT_REPORT_CUSTOM_FIELD_SETTINGS_DIALOG_REPORT_CUSTOM_FIELD_CODES:
      return { ...state, reportCustomFieldCodes: (action as SetEditReportCustomFieldSettingsDialogReportCustomFieldCodesAction).reportCustomFieldCodes };
    case Type.SET_EDIT_REPORT_CUSTOM_FIELD_SETTINGS_DIALOG_REPORT_CUSTOM_FIELD_SETTINGS_WITH_CHECKBOXES:
      return {
        ...state,
        reportCustomFieldSettingsWithCheckboxes: (action as SetEditReportCustomFieldSettingsDialogReportCustomFieldSettingsWithCheckboxesAction).reportCustomFieldSettingsWithCheckboxes
      };
    case Type.SET_EDIT_REPORT_CUSTOM_FIELD_SETTINGS_DIALOG_IS_FETCHING:
      return { ...state, isFetching: (action as SetEditReportCustomFieldSettingsDialogIsFetchingAction).isFetching };
    case Type.SET_EDIT_REPORT_CUSTOM_FIELD_SETTINGS_DIALOG_ERRORS:
      return { ...state, errors: (action as SetEditReportCustomFieldSettingsDialogErrorsAction).errors };
    default:
      return state;
  }
};

const requestCertificateRenewalDialog: Reducer<IRequestCertificateRenewalDialogState> = (state = initialState.requestCertificateRenewalDialog, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.requestCertificateRenewalDialog;
    case Type.PREPARE_REQUEST_CERTIFICATE_RENEWAL_DIALOG: {
      const prepareRequestCertificateRenewalDialogAction = action as PrepareRequestCertificateRenewalDialogAction;
      return {
        ...state,
        cancellationMode: prepareRequestCertificateRenewalDialogAction.cancellationMode,
        certificatesIds: prepareRequestCertificateRenewalDialogAction.certificatesIds
      };
    }
    case Type.SHOW_REQUEST_CERTIFICATE_RENEWAL_DIALOG:
      return { ...state, visible: true };
    case Type.HIDE_REQUEST_CERTIFICATE_RENEWAL_DIALOG:
      return {
        ...state,
        visible: false,
        cancellationMode: initialState.requestCertificateRenewalDialog.cancellationMode,
        certificatesIds: initialState.requestCertificateRenewalDialog.certificatesIds
      };
    default:
      return state;
  }
};

const reportVersionsDialog: Reducer<IReportVersionsDialogState> = (state = initialState.reportVersionsDialog, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.reportVersionsDialog;
    case Type.PREPARE_REPORT_VERSIONS_DIALOG:
      return { ...state, reportCombo: (action as PrepareReportVersionsDialogAction).reportCombo };
    case Type.SHOW_REPORT_VERSIONS_DIALOG:
      return { ...state, visible: true };
    case Type.HIDE_REPORT_VERSIONS_DIALOG:
      return {
        ...state,
        visible: false,
        reportCombo: initialState.reportVersionsDialog.reportCombo,
        reports: initialState.reportVersionsDialog.reports,
        isFetching: initialState.reportVersionsDialog.isFetching
      };
    case Type.SET_REPORT_VERSIONS_DIALOG_REPORTS:
      return { ...state, reports: (action as SetReportVersionsDialogReportsAction).reports };
    case Type.SET_REPORT_VERSIONS_DIALOG_IS_FETCHING:
      return { ...state, isFetching: (action as SetReportVersionsDialogIsFetchingAction).isFetching };
    default:
      return state;
  }
};

const editChainSeriesDialog: Reducer<IEditChainSeriesDialogState> = (state = initialState.editChainSeriesDialog, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.editChainSeriesDialog;
    case Type.SHOW_EDIT_CHAIN_SERIES_DIALOG:
      return { ...state, visible: true };
    case Type.HIDE_EDIT_CHAIN_SERIES_DIALOG:
      return { ...state, visible: false, chain: initialState.editChainSeriesDialog.chain };
    case Type.SET_EDIT_CHAIN_SERIES_DIALOG_CHAIN:
      return { ...state, chain: (action as SetEditChainSeriesDialogChainAction).chain };
    default:
      return state;
  }
};

const createNoteDialog: Reducer<ICreateNoteDialogState> = (state = initialState.createNoteDialog, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.createNoteDialog;
    case Type.SHOW_CREATE_NOTE_DIALOG:
      return { ...state, visible: true };
    case Type.HIDE_CREATE_NOTE_DIALOG:
      return { ...state, visible: false };
    default:
      return state;
  }
};

const sendReportEmailDialog: Reducer<ISendReportEmailDialogState> = (state = initialState.sendReportEmailDialog, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.sendReportEmailDialog;
    case Type.PREPARE_SEND_REPORT_EMAIL_DIALOG:
      return { ...state, report: (action as PrepareSendReportEmailDialogAction).report };
    case Type.SHOW_SEND_REPORT_EMAIL_DIALOG:
      return { ...state, visible: true };
    case Type.HIDE_SEND_REPORT_EMAIL_DIALOG:
      return { ...state, visible: false, report: initialState.sendReportEmailDialog.report };
    default:
      return state;
  }
};

const shareCertificateEmailDialog: Reducer<IShareCertificateEmailDialogState> = (state = initialState.shareCertificateEmailDialog, action) => {
  switch (action.type) {
  case Type.RESET:
      return initialState.shareCertificateEmailDialog;
  case Type.PREPARE_SHARE_CERTIFICATE_EMAIL_DIALOG:
    return { ...state, certificate: (action as PrepareShareCertificateEmailDialogAction).certificate };
  case Type.SHOW_SHARE_CERTIFICATE_EMAIL_DIALOG:
    return { ...state, visible: true };
  case Type.HIDE_SHARE_CERTIFICATE_EMAIL_DIALOG:
    return { ...state, visible: false, certificate: initialState.shareCertificateEmailDialog.certificate };
  default:
    return state;
  }
};

const userFeedbackDialog: Reducer<IUserFeedbackDialogState> = (state = initialState.userFeedbackDialog, action) => {
  switch (action.type) {
  case Type.RESET:
      return initialState.userFeedbackDialog;
  case Type.SHOW_USER_FEEDBACK_DIALOG:
    return { ...state, visible: true };
  case Type.HIDE_USER_FEEDBACK_DIALOG:
    return { ...state, visible: false };
  default:
    return state;
  }
};

export const dialogsStore = combineReducers({
  genericConfirmationDialog,
  discardChangesDialog,
  deactivateEntityDialog,
  editAnnexDialog,
  editAnnexStateDialog,
  saveAdvancedFilterDialog,
  applyAdvancedFiltersDialog,
  shareAdvancedFilterDialog,
  uploadAnnexDialog,
  changeCompanyDialog,
  contextualHelpDialog,
  simulateExternalUserDialog,
  editCompanySupplierPurchaseProductsDialog,
  editReportCustomFieldSettingsDialog,
  requestCertificateRenewalDialog,
  reportVersionsDialog,
  editChainSeriesDialog,
  createNoteDialog,
  sendReportEmailDialog,
  shareCertificateEmailDialog,
  userFeedbackDialog
});
