import { Action, Reducer, ActionCreator, combineReducers } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { History } from 'history';

import { RootState } from '..';
import { ISupplierDetailState } from '../../interfaces/states/ISupplierDetailState';
import { ISupplier } from '../../common/model/supplier.model';
import { IErrors } from '../../common/model/errors.model';
import { ISuppliersService } from '../../services';

enum Type {
  RESET = '@@supplierDetail/RESET',
  MAKE_READY_FOR_RENDERING = '@@supplierDetail/MAKE_READY_FOR_RENDERING',
  SET_SUPPLIER = '@@supplierDetail/SET_SUPPLIER',
  SET_SUPPLIER_CODE = '@@supplierDetail/SET_SUPPLIER_CODE',
  SET_SUPPLIER_NAME = '@@supplierDetail/SET_SUPPLIER_NAME',
  SET_SUPPLIER_CONTRACT_NUMBER = '@@supplierDetail/SET_SUPPLIER_CONTRACT_NUMBER',
  SET_SUPPLIER_ADDRESS = '@@supplierDetail/SET_SUPPLIER_ADDRESS',
  SET_SUPPLIER_CITY = '@@supplierDetail/SET_SUPPLIER_CITY',
  SET_SUPPLIER_PROVINCE = '@@supplierDetail/SET_SUPPLIER_PROVINCE',
  SET_SUPPLIER_POSTAL_CODE = '@@supplierDetail/SET_SUPPLIER_POSTAL_CODE',
  SET_SUPPLIER_COUNTRY = '@@supplierDetail/SET_SUPPLIER_COUNTRY',
  SET_SUPPLIER_ACTIVE = '@@supplierDetail/SET_SUPPLIER_ACTIVE',
  SET_ERRORS = '@@supplierDetail/SET_ERRORS'
}

const initialState: ISupplierDetailState = {
  isReadyForRendering: false,
  supplier: {
    id: null,
    code: null,
    name: null,
    contractNumber: null,
    address: null,
    city: null,
    province: null,
    postalCode: null,
    country: null,
    active: true
  },
  isView: null, // must be !== null when isReadyForRendering === true
  isNew: null, // must be !== null when isReadyForRendering === true
  errors: {}
};

// Actions

export type Actions = ResetAction | MakeReadyForRenderingAction | SetSupplierAction | SetErrorsAction;

export type ResetAction = Action<Type.RESET>

export interface MakeReadyForRenderingAction extends Action<Type.MAKE_READY_FOR_RENDERING> {
  isView: boolean;
  isNew: boolean;
}

export interface SetSupplierAction extends Action<Type.SET_SUPPLIER> {
  supplier: ISupplier;
}

export interface SetSupplierCodeAction extends Action<Type.SET_SUPPLIER_CODE> {
  code: string;
}

export interface SetSupplierNameAction extends Action<Type.SET_SUPPLIER_NAME> {
  name: string;
}

export interface SetSupplierContractNumberAction extends Action<Type.SET_SUPPLIER_CONTRACT_NUMBER> {
  contractNumber: string;
}

export interface SetSupplierAddressAction extends Action<Type.SET_SUPPLIER_ADDRESS> {
  address: string;
}

export interface SetSupplierCityAction extends Action<Type.SET_SUPPLIER_CITY> {
  city: string;
}

export interface SetSupplierProvinceAction extends Action<Type.SET_SUPPLIER_PROVINCE> {
  province: string;
}

export interface SetSupplierPostalCodeAction extends Action<Type.SET_SUPPLIER_POSTAL_CODE> {
  postalCode: string;
}

export interface SetSupplierCountryAction extends Action<Type.SET_SUPPLIER_COUNTRY> {
  country: string;
}

export interface SetSupplierActiveAction extends Action<Type.SET_SUPPLIER_ACTIVE> {
  active: boolean;
}

export interface SetErrorsAction extends Action<Type.SET_ERRORS> {
  errors: IErrors;
}

export const reset: ActionCreator<ResetAction> = () => ({
  type: Type.RESET
});

export const makeReadyForRendering: ActionCreator<MakeReadyForRenderingAction> = (isViewLocal: boolean, isNewLocal: boolean) => ({
  type: Type.MAKE_READY_FOR_RENDERING,
  isView: isViewLocal,
  isNew: isNewLocal
});

export const setSupplier: ActionCreator<SetSupplierAction> = (supplierLocal: ISupplier) => ({
  type: Type.SET_SUPPLIER,
  supplier: supplierLocal
});

export const setSupplierCode: ActionCreator<SetSupplierCodeAction> = (code: string) => ({
  type: Type.SET_SUPPLIER_CODE,
  code
});

export const setSupplierName: ActionCreator<SetSupplierNameAction> = (name: string) => ({
  type: Type.SET_SUPPLIER_NAME,
  name
});

export const setSupplierContractNumber: ActionCreator<SetSupplierContractNumberAction> = (contractNumber: string) => ({
  type: Type.SET_SUPPLIER_CONTRACT_NUMBER,
  contractNumber
});

export const setSupplierAddress: ActionCreator<SetSupplierAddressAction> = (address: string) => ({
  type: Type.SET_SUPPLIER_ADDRESS,
  address
});

export const setSupplierCity: ActionCreator<SetSupplierCityAction> = (city: string) => ({
  type: Type.SET_SUPPLIER_CITY,
  city
});

export const setSupplierProvince: ActionCreator<SetSupplierProvinceAction> = (province: string) => ({
  type: Type.SET_SUPPLIER_PROVINCE,
  province
});

export const setSupplierPostalCode: ActionCreator<SetSupplierPostalCodeAction> = (postalCode: string) => ({
  type: Type.SET_SUPPLIER_POSTAL_CODE,
  postalCode
});

export const setSupplierCountry: ActionCreator<SetSupplierCountryAction> = (country: string) => ({
  type: Type.SET_SUPPLIER_COUNTRY,
  country
});

export const setSupplierActive: ActionCreator<SetSupplierActiveAction> = (active: boolean) => ({
  type: Type.SET_SUPPLIER_ACTIVE,
  active
});

export const setErrors: ActionCreator<SetErrorsAction> = (errorsLocal: IErrors) => ({
  type: Type.SET_ERRORS,
  errors: errorsLocal
});

export const fetchSupplier: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (id: string, suppliersService: ISuppliersService) => (
  async (dispatch) => {
    try {
      const supplierLocal = await suppliersService.fetch(id);
      dispatch(setSupplier(supplierLocal));
    } catch (error) {
      // TODO: manejar error
    }
  }
);

export const saveSupplier: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (supplierLocal: ISupplier, suppliersService: ISuppliersService, history: History) => (
  async (dispatch) => {
    try {
      dispatch(setErrors(initialState.errors));

      const savedSupplier = supplierLocal.id ? await suppliersService.update(supplierLocal) : await suppliersService.create(supplierLocal);
      dispatch(setSupplier(savedSupplier));
      history.push('/suppliers-list');
    } catch (error) {
      dispatch(setErrors(error as IErrors));
    }
  }
);

// Reducers

const isReadyForRendering: Reducer<boolean> = (state = initialState.isReadyForRendering, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.isReadyForRendering;
    case Type.MAKE_READY_FOR_RENDERING:
      return true;
    default:
      return state;
  }
};

const supplier: Reducer<ISupplier> = (state = initialState.supplier, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.supplier;
    case Type.SET_SUPPLIER:
      return (action as SetSupplierAction).supplier;
      case Type.SET_SUPPLIER_CODE:
        return { ...state, code: (action as SetSupplierCodeAction).code };
      case Type.SET_SUPPLIER_NAME:
        return { ...state, name: (action as SetSupplierNameAction).name };
        case Type.SET_SUPPLIER_CONTRACT_NUMBER:
          return { ...state, contractNumber: (action as SetSupplierContractNumberAction).contractNumber };
      case Type.SET_SUPPLIER_ADDRESS:
        return { ...state, address: (action as SetSupplierAddressAction).address };
      case Type.SET_SUPPLIER_CITY:
        return { ...state, city: (action as SetSupplierCityAction).city };
      case Type.SET_SUPPLIER_PROVINCE:
        return { ...state, province: (action as SetSupplierProvinceAction).province };
      case Type.SET_SUPPLIER_POSTAL_CODE:
        return { ...state, postalCode: (action as SetSupplierPostalCodeAction).postalCode };
      case Type.SET_SUPPLIER_COUNTRY:
        return { ...state, country: (action as SetSupplierCountryAction).country };
        case Type.SET_SUPPLIER_ACTIVE:
          return { ...state, active: (action as SetSupplierActiveAction).active };
    default:
      return state;
  }
};

const isView: Reducer<boolean> = (state = initialState.isView, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.isView;
    case Type.MAKE_READY_FOR_RENDERING:
      return (action as MakeReadyForRenderingAction).isView;
    default:
      return state;
  }
};

const isNew: Reducer<boolean> = (state = initialState.isNew, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.isNew;
    case Type.MAKE_READY_FOR_RENDERING:
      return (action as MakeReadyForRenderingAction).isNew;
    default:
      return state;
  }
};

const errors: Reducer<IErrors> = (state = initialState.errors, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.errors;
    case Type.SET_ERRORS:
      return (action as SetErrorsAction).errors;
    default:
      return state;
  }
};

export const supplierDetailStore = combineReducers({ isReadyForRendering, supplier, isView, isNew, errors });
