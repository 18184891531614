export const insertItem = <T = any>(array: T[], item: T): T[] => {
  return [
    ...array,
    item
  ];
};

export const removeItem = <T = any>(array: T[], index: number): T[] => {
  return [...array.slice(0, index), ...array.slice(index + 1)];
};

export const updateItem = <T = any>(array: T[], itemToUpdateIndex: number, newItem: T): T[] => {
  return array.map((item, index) => {
    if (index !== itemToUpdateIndex) {
      return item;
    }

    return {
      ...item,
      ...newItem
    };
  });
};

export const chunk = <T = any>(array: T[], chunkSize: number): T[][] => Array(Math.ceil(array.length / chunkSize)).fill(0)
  .map((_, index) => index * chunkSize)
  .map((begin) => array.slice(begin, begin + chunkSize));
