import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { FormValidator, FormEventArgs } from '@syncfusion/ej2-react-inputs';
import { DialogComponent } from '@aitex/tsx-extranet-dialogs';
import moment from 'moment';

import { IShareCertificateEmailDialogProps } from '../../../interfaces/props/IDialogsProps';
import { ICertificate, IShareCertificate } from '../../../common/model/certificate.model';
import { IErrors } from '../../../common/model/errors.model';
import { Validator } from '../../../common/model/enumerations/validator.model';
import { certificatesService } from '../../../services';
import * as dialogUtils from '../../../utils/dialog.utils';
import * as formValidatorUtils from '../../../utils/formValidator.utils';
import ValidationError from '../../validationError/validationError';
import TextBox from '../../filters/textBox';

type ShareCertificateEmailDialogPropsType = IShareCertificateEmailDialogProps & WithTranslation;

type ShareCertificateEmailDialogStateType = IShareCertificateEmailDialogState;

class ShareCertificateEmailDialog extends Component<ShareCertificateEmailDialogPropsType, ShareCertificateEmailDialogStateType> {
  public readonly SHARE_CERTIFICATE_EMAIL_DIALOG_FORM_ID = 'shareCertificateEmailDialogForm';

  private readonly CANCEL_BUTTON_ID = 'shareCertificateEmailDialogCancel';
  private readonly SHARE_BUTTON_ID = 'shareCertificateEmailDialogSend';

  private readonly CANNOT_SHARE_CERTIFICATE_EMAIL_ERROR: IErrors = { UnknownError: ['dialogs.shareCertificateEmailDialog.error'] };

  private formValidator: FormValidator = null;
  private isValidatingAll = false;
  private auxErrors: IErrors = {};

  private emails: string = null;

  private initialState: ShareCertificateEmailDialogStateType = {
    certificate: null,
    isSending: false,
    errors: {}
  };

  public constructor(props: ShareCertificateEmailDialogPropsType) {
    super(props);

    const currentInitialState = this.initialState;
    currentInitialState.certificate = props.certificate;
    this.state = currentInitialState;
  }

  public componentDidUpdate(prevProps: ShareCertificateEmailDialogPropsType): void {
    const { visible } = this.props;

    if (prevProps.visible && !visible) {
      this.setState(this.initialState);
      this.resetFormValidator();
    }

    dialogUtils.manageButtonsClick(this.SHARE_BUTTON_ID, (): Promise<void> => this.sendCertificateEmail(), this.CANCEL_BUTTON_ID, (): void => this.dismiss());
  }

  public async sendCertificateEmail(): Promise<void> {
    try {
      if (!this.validateForm()) {
        return;
      }

      this.setState({ isSending: true });

      if (!this.props.certificate) {
        throw this.CANNOT_SHARE_CERTIFICATE_EMAIL_ERROR;
      }

      const currentObject: IShareCertificate = {
        id: this.props.certificate.id,
        emails: this.emails,
        expirationDate: moment().add(1, 'month')
      };

      await certificatesService.shareCertification(currentObject);
      this.close();
    } catch (error) {
      let errors = error as IErrors;
      if (errors.Addresses) {
        this.setState({ errors });
        return;
      }
      if (errors.UnknownError || (!errors.UnknownError && !errors.ControlledError)) {
        errors = this.CANNOT_SHARE_CERTIFICATE_EMAIL_ERROR;
      }
      this.dismiss(errors);
    } finally {
      this.setState({ isSending: false });
    }
  }

  public close(): void {
    this.formValidator = null;
    this.props.onClose();
  }

  public dismiss(reason?: IErrors): void {
    this.props.onDismiss(reason);
  }

  public configureFormValidator(): void {
    if (this.props.visible) {
      if (!this.formValidator) {
        this.formValidator = formValidatorUtils.configureFormValidator(this.SHARE_CERTIFICATE_EMAIL_DIALOG_FORM_ID, {
          Addresses: {
            required: [true, Validator.NOT_EMPTY],
            // eslint-disable-next-line no-control-regex
            regex: [/^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])(;[ ]{0,1}(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))*$/, Validator.EMAILS]
          }
        });
        this.formValidator.validationComplete = (args) => {
          formValidatorUtils.validationComplete(args as FormEventArgs, this.isValidatingAll, this.state.errors, this.auxErrors, (errors): void => this.setState({ errors }));
        };
      }
    } else {
      this.formValidator = null;
    }
  }

  public onAddressesChange(addresses: string): void {
    this.emails = addresses;
  }

  public render(): JSX.Element {
    const { visible, i18n } = this.props;

    return (
      <DialogComponent
        header={i18n.t('dialogs.shareCertificateEmailDialog.title')}
        visible={visible}
        width='480px'
        footerTemplate={dialogUtils.computeFooterTemplate(
          this.SHARE_BUTTON_ID,
          i18n.t('actions.ok'),
          this.CANCEL_BUTTON_ID,
          i18n.t('actions.cancel'),
          this.state.isSending,
          this.isSaveButtonDisabled()
        )}
        onDismiss={() => this.dismiss()}
      >
        <div>
          {<form id={this.SHARE_CERTIFICATE_EMAIL_DIALOG_FORM_ID} ref={(): void => this.configureFormValidator()}>
            <div className='row'>
              <div className='col'>
                <div className='form-group'>
                  <div className='autocomplete-input'>
                    <div className='autocomplete-label' style={{ minWidth: 'auto' }}>{i18n.t('email.addresses')}</div>
                    <TextBox
                      name='Addresses'
                      onChange={(value): void => this.onAddressesChange(value)}
                    />
                  </div>
                  <ValidationError errors={this.state.errors} errorKey={'Addresses'} />
                </div>
              </div>
            </div>
          </form>}
        </div>
      </DialogComponent>
    );
  }

  private isSaveButtonDisabled(): boolean {
    return !!this.state.errors.Addresses || this.state.isSending;
  }

  private resetFormValidator(): void {
    if (!this.formValidator) {
      return;
    }

    if (this.formValidator.getInputElement('Addresses')) {
      this.formValidator.getInputElement('Addresses').parentElement.className = this.formValidator.getInputElement('Addresses').parentElement.className.replace('e-error', '');
    }
    this.formValidator.removeRules();
    this.formValidator.validationComplete = undefined;
  }

  private validateForm(): boolean {
    if (!this.formValidator) {
      return true;
    }

    this.isValidatingAll = true;
    const isFormValid = this.formValidator.validate();
    this.isValidatingAll = false;
    if (!isFormValid) {
      this.setState({ errors: this.auxErrors });
      this.auxErrors = {};
    }
    return isFormValid;
  }
}

interface IShareCertificateEmailDialogState {
  certificate: ICertificate;
  isSending: boolean;
  errors: IErrors;
}

export default withTranslation()(ShareCertificateEmailDialog);
