import i18next from 'i18next';

import { CertificateStatus } from '../common/model/enumerations/certificateStatus.model';

export const getLocalizedText = (i18n: i18next.i18n, value: string): string => {
  return i18n.t([`enums.certificateStatus.${value.toUpperCase()}`, value]);
};

export const getIconCss = (status: string): string => {
  switch (status) {
    case CertificateStatus.ALL:
      return 'icon icon-app-item-list';
    case CertificateStatus.VALID:
      return 'icon icon-calendar-check-o';
    case CertificateStatus.EXPIRED:
      return 'icon icon-calendar-times-o';
    default:
      return '';
  }
};
