export const URL = {
  ODATA: {
    REPORT_FILTERS: 'odata/v1/ReportFilters',
    REPORT_WITH_CUSTOM_FIELDS_FILTERS: 'odata/v1/ReportWithCustomFieldsFilters',
    CERTIFICATE_FILTERS: 'odata/v1/CertificateFilters',
    REQUEST_FILTERS: 'odata/v1/RequestFilters',
    REQUEST_WITH_CUSTOM_FIELDS_FILTERS: 'odata/v1/RequestWithCustomFieldsFilters',
    COMPANY_FILTERS: 'odata/v1/CompanyFilters',
    NOTIFICATION_FILTERS: 'odata/v1/NotificationFilters',
    NOTIFICATION_USER_FILTERS: 'odata/v1/NotificationUserFilters',
    CLASSIFICATION_FILTERS: 'odata/v1/ClassificationFilters',
    ADVANCED_FILTER_FILTERS: 'odata/v1/AdvancedFilterFilters',
    CHAIN_FILTERS: 'odata/v1/ChainFilters',
    MEASUREMENT_UNIT_FILTERS: 'odata/v1/MeasurementUnitFilters',
    SUPPLIER_FILTERS: 'odata/v1/SupplierFilters',
    PRODUCT_FILTERS: 'odata/v1/ProductFilters',
    USER_FILTERS: 'odata/v1/UserFilters',
    USER_BASIC_FILTERS: 'odata/v1/UserBasicFilters',
    SERIES_FILTERS: 'odata/v1/SeriesFilters',
    MANAGER_GROUPS_FILTERS: 'odata/v1/ManagerGroupFilters',
    DELEGATES_MANAGER_GROUPS_FILTERS: 'odata/v1/DelegatesManagerGroupsFilters',
    UNECOS_FILTERS: 'odata/v1/UnecoEmailFilters',
    OFFICES_FILTERS: 'odata/v1/OfficeFilters',
    EMAILS_NOT_RECEIVED_FILTERS: 'odata/v1/EmailNotReceivedFilters'
  },
  WEB: {
    BASE: 'api/v1/',
    SETTINGS: 'settings',
    COMPANIES: 'companies',
    REFERENCESBYREPORTID: 'references/report/',
    REFERENCES: 'references/',
    REPORTSTATES: 'reportstates',
    REPORTSACTIVE: 'reports/active/',
    REPORTS: 'reports',
    REPORT_CUSTOM_FIELDS: 'reportCustomFields',
    REPORT_CUSTOM_FIELD_SETTINGS: 'reportCustomFieldSettings',
    REPORTSBYCOMPANYID: 'reports/getbycompanyid/',
    REPORTSREQUESADVANCE: `reports/{0}/advance`,
    SERVICES: 'services/filter',
    ANNEXES: 'annexes',
    ANNEXED_CATEGORIES: 'annexedCategories',
    CERTIFICATES: 'certificates',
    REQUESTS: 'requests',
    REQUEST_CUSTOM_FIELDS: 'requestCustomFields',
    NOTES: 'notes',
    REPORT_CHANGES_HISTORY: 'reportChangesHistory',
    DETAILREFERENCES: 'references/filter',
    SEASONS: 'seasons',
    GARMENTRATINGS: 'GarmentRatings',
    CAPTCHA: 'captcha',
    REPORTSDETAIL: 'reports/detail/',
    NOTIFICATIONS: 'notifications',
    NOTIFICATION_CLASSIFICATIONS: 'clasifications',
    ADVANCED_FILTERS: 'advancedFilters',
    PRODUCTS: 'products',
    CHAINS: 'chains',
    MEASUREMENT_UNITS: 'measurementUnits',
    SUPPLIERS: 'suppliers',
    COMPANY_SUPPLIERS: 'companySuppliers',
    SUPPLIER_PRODUCTS: 'supplierProducts',
    USER_FEEDBACKS: 'userFeedbacks',
    USERS: 'users',
    UNECOS: 'unecoEmails',
    OFFICES: 'offices',
    EMAILS_NOT_RECEIVED: 'emailsNotReceived',
    USER_SETTINGS: 'userSettings'
  },
  SIGNALR: {
    HUB: 'hub/',
    GARMENTRATINGS: 'garmentratings',
    METHODS: {
      UPDATEREPORTSTATUS: 'UpdateReportStatus'
    }
  }
};
