import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { getItem, saveItem } from '../../utils/localStorageManagement';
import './bottomBanner.scss';

interface IBottomBannerState {
  showBanner: boolean;
}

class BottomBanner extends Component<WithTranslation, IBottomBannerState> {
  public constructor(props: any) {
    super(props);
    this.state = {
      showBanner: !this.hasAccepted(),
    };
  }

  public render() {
    const { showBanner } = this.state;

    if (showBanner) {
      return (
        <section className='bottomBanner'>
          <div className='bottomBanner-info'>
            <span className='bottomBanner-info_text'>{this.props.i18n.t(`cookies.text`)}</span>
            <a href={this.props.i18n.t(`cookies.know-more-link`)} target='_blank' rel='noreferrer' className='bottomBanner-info_link'>{this.props.i18n.t(`cookies.know-more`)}</a>
            <span onClick={this.setAcceptance} className='bottomBanner-info_accept'>{this.props.i18n.t(`cookies.accept`)}</span>
          </div>
          <span onClick={this.setAcceptance} className='bottomBanner-dismiss'><i className='icon icon-app-item-close' /></span>
        </section>
      );
    }

    return null;
  }

  private setAcceptance = () => {
    saveItem('cookies_accepted', true);
    this.setState({
      showBanner: false,
    });
  }

  private hasAccepted = () => Boolean(getItem('cookies_accepted'));
}

export default withTranslation()(BottomBanner);
