import React, { Component, ComponentType } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { compose } from 'redux';
import { DataManager, Query } from '@syncfusion/ej2-data';
import { dialogsService } from '@aitex/tsx-extranet-dialogs';

import { RootState } from '../../store';
import { IChainsListContainerStateProps, IChainsListContainerDispatchProps } from '../../interfaces/props/IChainsListProps';
import * as chainsListStore from '../../store/modules/chainsList.store';
import * as dialogsStore from '../../store/modules/dialogs.store';
import { URL } from '../../common/constants';
import { IChain } from '../../common/model/chain.model';
import { DialogId } from '../../common/model/enumerations/dialogId.model';
import { chainsService, oDataService, dataManagerService } from '../../services';
import { notifyError } from '../../utils/toast.utils';
import ChainsGrid from './chainsGrid/chainsGrid';

type ChainsListContainerPropsType = PropsFromRedux & RouteComponentProps & WithTranslation;

class ChainsListContainer extends Component<ChainsListContainerPropsType> {
  public chainsDataManager: DataManager = null;
  public chainsQuery = new Query()
    .select(['id', 'code', 'name', 'contactEmail', 'active'])
    .expand('series');

  public constructor(props: ChainsListContainerPropsType) {
    super(props);

    const { reset } = this.props;

    this.chainsDataManager = dataManagerService.buildDataManager(URL.ODATA.CHAIN_FILTERS);

    reset();
  }

  public componentDidMount() {
    setTimeout(() => this.props.fetchChains(oDataService, this.chainsDataManager, this.chainsQuery));
  }

  public componentDidUpdate(prevProps: ChainsListContainerPropsType) {
    if (!Object.keys(prevProps.errors).length && Object.keys(this.props.errors).length) {
      if (this.props.errors.ControlledError) {
        notifyError(this.props.errors.ControlledError[0]);
      }
      if (this.props.errors.UnknownError || (!this.props.errors.UnknownError && !this.props.errors.ControlledError)) {
        notifyError(this.props.i18n.t('chainsList.error'));
      }
    }
  }

  public onChainSave(chain: IChain) {
    this.props.saveChain(chain, chainsService, oDataService, this.chainsDataManager, this.chainsQuery);
  }

  public onEditChainSeriesButtonClick(chain: IChain) {
    this.props.setEditChainSeriesDialogChain(chain);
    dialogsService.openDialog(DialogId.EDIT_CHAIN_SERIES)
      .then((editedChain: IChain) => {
        this.onChainSave(editedChain);
      })
      .catch(() => {
        // DO nothing
      });
  }

  public render() {
    const { chains, isFetching, i18n } = this.props;

    return (
      <div>
      <div className='row'>
        <div className='col'>
          <h2>{i18n.t('chainsList.title')}</h2>
        </div>
      </div>
        <div className='row'>
          <div className='col'>
            <ChainsGrid
              chains={chains}
              isFetching={isFetching}
              onChainSave={(chain) => this.onChainSave(chain)}
              onEditChainSeriesButtonClick={(chain) => this.onEditChainSeriesButtonClick(chain)}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IChainsListContainerStateProps => ({
  chains: state.chainsListStore.chains,
  isFetching: state.chainsListStore.isFetching,
  errors: state.chainsListStore.errors
});

const mapDispatchToProps: IChainsListContainerDispatchProps = {
  reset: chainsListStore.reset,
  fetchChains: chainsListStore.fetchChains,
  saveChain: chainsListStore.saveChain,
  setEditChainSeriesDialogChain: dialogsStore.setEditChainSeriesDialogChain
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default compose(
  withRouter,
  withTranslation(),
  connector
)(ChainsListContainer) as ComponentType;
