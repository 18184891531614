import React, { Component, Fragment } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { UnregisterCallback } from 'history';
import { ToastContainer } from 'react-toastify';
import { createSpinner, showSpinner, hideSpinner } from '@syncfusion/ej2-popups';
import { DialogsContainer as AitexDialogsContainer } from '@aitex/tsx-extranet-dialogs';

import './styles/app.scss';

import { RootState } from './store';
import { IAppContainerStateProps, IAppContainerDispatchProps } from './interfaces/props/IAppProps';
import { setInitialUser } from './store/modules/user';
import { FiltersState, defaultFilterState, saveFavoriteFilter, saveReportAllFilters } from './store/modules/filters';
import * as menuStore from './store/modules/menu.store';
import * as settingsStore from './store/modules/settings.store';
import * as currentUserStore from './store/modules/currentUser.store';
import { RoleNormalizedName } from './common/model/enumerations/roleNormalizedName.model';
import { authService, usersService, companiesService } from './services';
import { getDate } from './helpers/date.helpers';
import { getItem, removeItem, getExtranetToken } from './utils/localStorageManagement';
import history from './history';
import NotificationsSidebarContainer from './components/notificationsSidebar/notificationsSidebar';
import MenuContainer from './components/menu/menu';
import DialogsContainer from './components/dialogs/dialogs';
import SimulationModeBanner from './components/simulationModeBanner/simulationModeBanner';
import BottomBanner from './components/bottomBanner';
import PrivateRoute from './components/privateRoute/privateRoute';
import SsoRedirectRoute from './components/ssoRedirectRoute/ssoRedirectRoute';
import ReportsListContainer from './pages/reportsList/reportsList.container';
import CertificatesListContainer from './pages/certificatesList/certificatesList.container';
import RequestsListContainer from './pages/requestsList/requestsList.container';
import RequestDetailContainer from './pages/requestDetail/requestDetail.container';
import NotificationsListContainer from './pages/notificationsList/notificationsList.container';
import NotificationsViewStatusListContainer from './pages/notificationsList/notificationsViewStatusList.container';
import NotificationDetailContainer from './pages/notificationDetail/notificationDetail.container';
import ClientsListContainer from './pages/clientsList/clientsList.container';
import ClientDetailContainer from './pages/clientDetail/clientDetail.container';
import ChainsListContainer from './pages/chainsList/chainsList.container';
import MeasurementUnitsListContainer from './pages/measurementUnitsList/measurementUnitsList.container';
import SuppliersListContainer from './pages/suppliersList/suppliersList.container';
import SupplierDetailContainer from './pages/supplierDetail/supplierDetail.container';
import ReportDetailContainer from './pages/reportDetail/reportDetail.container';
import ReferenceDetail from './pages/referenceDetail';
import ReportStatus from './pages/reportStatus';
import SharedCertificate from './pages/sharedCertificates';
import Error404 from './pages/error404';
import Error410 from './pages/error410';
import CustomIFrame from './components/customIFrame/customIFrame';
import QrRedirect from './components/qrRedirect';
import UnecosListContainer from './pages/unecosList/unecosList.container';
import OfficesListContainer from './pages/officesList/officesList.container';
import EmailsNotReceivedListContainer from './pages/emailsNotReceivedList/emailsNotReceivedList.container';

type AppContainerPropsType = PropsFromRedux;

class AppContainer extends Component<AppContainerPropsType> {
  public historyUnregisterCallback: UnregisterCallback = null;

  public constructor(props: AppContainerPropsType) {
    super(props);

    createSpinner({
      target: document.getElementById('root')
    });
    showSpinner(document.getElementById('root'));

    this.props.fetchSettings();
  }

  public componentDidMount() {
    this.manageLocationChange(window.location.pathname);
    this.historyUnregisterCallback = history.listen((location: any) => {
      this.manageLocationChange(location.pathname);
    });
  }

  public componentDidUpdate() {
    const { settings, settingsErrors, accessToken, setAccessToken, fetchCurrentUser } = this.props;

    if (settingsErrors !== null || this.isCurrentUserLoaded()) {
      hideSpinner(document.getElementById('root'));
    }

    if (settings !== null && !this.isCurrentUserLoaded() && !accessToken) {

      authService.renewToken()
        .catch(() => {
          // TODO: manejar error
        })
        .finally(() => {
          let newAccessToken: string = null;

          authService.getUser()
            .then((user) => {
              if (user) {
                const filteredUser = (({
                  access_token,
                  profile: { name, sub },
                  userType,
                  companyId,
                  role,
                  email
                }) => ({ access_token, name, sub, isAuth: true, role, userType, companyId, email }))(user);
                const finalizedPage = 'finalized';
                const currentStore = getItem('state') as RootState;

                let filterState: FiltersState = null;
                if (currentStore !== undefined) {
                  filterState = currentStore.filtersStore;
                  filterState.reportFilter.reportFilters[filterState.reportFilter.currentPage].query = null;

                  if (filterState.reportFilter.reportFilters[finalizedPage].endDate.length === 0) {
                    filterState.reportFilter.reportFilters[finalizedPage].endDate = [getDate.last60days(), getDate.today()];
                  }
                }

                if (
                  currentStore === undefined
                  || (currentStore != null && currentStore.userStore !== null && currentStore.userStore.user !== null && currentStore.userStore.user.name !== filteredUser.name)
                ) {
                  removeItem('state');
                  filterState = defaultFilterState;
                }

                filterState.reportFilter.reportFilters[filterState.reportFilter.currentPage].isFetching = false;
                this.props.saveReportAllFilters(filterState.reportFilter);
                this.props.saveFavoriteFilter(filterState.favoriteFilter);
                this.props.setInitialUser(filteredUser);

                newAccessToken = user.access_token;
              }
            })
            .catch(() => {
              // TODO: manejar error
            })
            .finally(() => {
              setAccessToken(newAccessToken);
            });
        });
    }

    if (accessToken !== null && !this.isCurrentUserLoaded()) {
      fetchCurrentUser(usersService, companiesService);
    }
  }

  public componentWillUnmount() {
    if (this.historyUnregisterCallback) {
      this.historyUnregisterCallback();
    }
  }

  public areSettingsLoaded(): boolean {
    const { settings, settingsErrors } = this.props;
    return settings !== null || settingsErrors !== null;
  }

  public isCurrentUserLoaded(): boolean {
    const { isAnonymous, currentUser, jwtInfo, currentUserErrors } = this.props;
    return isAnonymous === true || (currentUser !== null && jwtInfo !== null) || currentUserErrors !== null;
  }

  public isInSimulationMode(): boolean {
    return this.props.jwtInfo ? this.props.jwtInfo.isInSimulationMode === 'true' : false;
  }

  public render() {
    const { settingsErrors, currentUser, menuVisible, settings } = this.props;

    if (window.location.href.toLowerCase().includes('logout')) {
      return authService.logout();
    }

    if (!this.areSettingsLoaded()) {
      return null;
    }

    let isAuth = true;

    if (settingsErrors) {
      isAuth = false;
    }

    if (isAuth) {
      if (!this.isCurrentUserLoaded()) {
        return null;
      }

      isAuth = currentUser !== null;
    }

    const shouldShowMenu = isAuth && menuVisible;
    const allRolesExceptTechnicalAndReportReader = [RoleNormalizedName.ADMIN, RoleNormalizedName.ACCOUNT_REVIEWER, RoleNormalizedName.CUSTOMER_SERVICE, RoleNormalizedName.REPORT_MANAGER, RoleNormalizedName.ACCOUNT_MANAGER];
    const allRolesExceptTechnical = allRolesExceptTechnicalAndReportReader.concat(RoleNormalizedName.REPORT_READER);
    const allRolesExceptReportReader = allRolesExceptTechnicalAndReportReader.concat(RoleNormalizedName.TECHNICAL);
    const allRoles = Array.from(new Set(allRolesExceptTechnical.concat(allRolesExceptReportReader)));
    const accessToken = getExtranetToken('oidc.user:' + settings.identity + ':aitexexternalwebsite');

    let paramCustomer: string = null;
    if (window.location.href.toLowerCase().includes('/user/newcustomers/')) {
      paramCustomer = window.location.href.substring(window.location.href.lastIndexOf('/'));
    }

    return (
      <Router history={history}>
        <AitexDialogsContainer
          dialogs={<DialogsContainer />}
          restOfContent={(
            <Fragment>
              {isAuth && <NotificationsSidebarContainer />}

              {shouldShowMenu && <MenuContainer />}

              <div className={`container app-container ${isAuth ? '' : 'app-container--no-menu'}`}>
                {isAuth && this.isInSimulationMode() && <SimulationModeBanner />}

                <div className='mt-3'>
                  <Switch>
                    <Redirect from='/' exact={true} to='/reports-list/inprogress' />
                    <Redirect from='/reports-list' exact={true} to='/reports-list/inprogress' />
                    <PrivateRoute roles={allRolesExceptTechnical} exact={true} path='/reports-list/:mode'>
                      <ReportsListContainer />
                    </PrivateRoute>
                    <PrivateRoute roles={[RoleNormalizedName.ADMIN, RoleNormalizedName.ACCOUNT_MANAGER, RoleNormalizedName.REPORT_MANAGER, RoleNormalizedName.REPORT_READER]} exact={true} path='/reports-list/:mode/:eciMode'>
                      <ReportsListContainer />
                    </PrivateRoute>
                    <Redirect from='/certificates-list' exact={true} to='/certificates-list/all' />
                    <PrivateRoute roles={allRolesExceptTechnical} exact={true} path='/certificates-list/:mode'>
                      <CertificatesListContainer />
                    </PrivateRoute>
                    <Redirect from='/requests-list' exact={true} to='/requests-list/all' />
                    <PrivateRoute roles={allRolesExceptTechnical} exact={true} path='/requests-list/:mode'>
                      <RequestsListContainer />
                    </PrivateRoute>
                    <PrivateRoute roles={allRolesExceptTechnicalAndReportReader} exact={true} path={['/request/new', '/request/:id/edit']}>
                      <RequestDetailContainer />
                    </PrivateRoute>
                    <Redirect from='/request/:id' exact={true} to='/request/:id/view' />
                    <PrivateRoute roles={allRoles} exact={true} path={['/request/:id/view']}>
                      <RequestDetailContainer />
                    </PrivateRoute>
                    <PrivateRoute roles={allRolesExceptTechnical} exact={true} path='/notifications-list'>
                      <NotificationsListContainer />
                    </PrivateRoute>
                    <PrivateRoute roles={allRolesExceptTechnical} exact={true} path='/notifications-view-status-list'>
                      <NotificationsViewStatusListContainer />
                    </PrivateRoute>
                    <PrivateRoute roles={allRolesExceptTechnical} exact={true} path={['/notification/:id/view']}>
                      <NotificationDetailContainer />
                    </PrivateRoute>
                    <PrivateRoute roles={allRolesExceptTechnicalAndReportReader} exact={true} path={['/notification/new', '/notification/:id/copy']}>
                      <NotificationDetailContainer />
                    </PrivateRoute>
                    <PrivateRoute roles={[RoleNormalizedName.ADMIN, RoleNormalizedName.CUSTOMER_SERVICE]} exact={true} path='/clients-list'>
                      <ClientsListContainer />
                    </PrivateRoute>
                    <PrivateRoute roles={[RoleNormalizedName.ADMIN, RoleNormalizedName.CUSTOMER_SERVICE]} exact={true} path={['/client/:id/view', '/client/:id/edit']}>
                      <ClientDetailContainer />
                    </PrivateRoute>
                    <PrivateRoute roles={[RoleNormalizedName.ADMIN, RoleNormalizedName.CUSTOMER_SERVICE]} exact={true} path='/chains-list'>
                      <ChainsListContainer />
                    </PrivateRoute>
                    <PrivateRoute roles={[RoleNormalizedName.ADMIN, RoleNormalizedName.CUSTOMER_SERVICE]} exact={true} path='/measurement-units-list'>
                      <MeasurementUnitsListContainer />
                    </PrivateRoute>
                    <PrivateRoute roles={[RoleNormalizedName.ADMIN, RoleNormalizedName.CUSTOMER_SERVICE, RoleNormalizedName.ACCOUNT_MANAGER, RoleNormalizedName.REPORT_MANAGER]} exact={true} path='/suppliers-list'>
                      <SuppliersListContainer />
                    </PrivateRoute>
                    <PrivateRoute roles={[RoleNormalizedName.ADMIN, RoleNormalizedName.CUSTOMER_SERVICE, RoleNormalizedName.ACCOUNT_MANAGER, RoleNormalizedName.REPORT_MANAGER]} exact={true} path={['/supplier/new', '/supplier/:id/view', '/supplier/:id/edit']}>
                      <SupplierDetailContainer />
                    </PrivateRoute>
                    <Route exact={true} path='/Suppliers/ReportStatus'>
                      <ReportStatus />
                    </Route>
                    <Route exact={true} path={['/Certificates/SharedCertificate/:id', '/Certificates/SharedCertificate']}>
                      <SharedCertificate />
                    </Route>
                    <PrivateRoute roles={allRolesExceptTechnical} exact={true} path={['/report/:id/view', '/report/:id/view/notification/:notificationId']}>
                      <ReportDetailContainer />
                    </PrivateRoute>
                    <PrivateRoute roles={allRolesExceptTechnicalAndReportReader} exact={true} path={['/report/:id/edit']}>
                      <ReportDetailContainer />
                    </PrivateRoute>
                    <Redirect from='/report/:id' exact={true} to='/report/:id/view' />
                    <PrivateRoute roles={allRolesExceptTechnical} exact={true} path='/report/:id/reference/:referenceId'>
                      <ReferenceDetail />
                    </PrivateRoute>
                    <PrivateRoute roles={allRolesExceptTechnical} exact={true} path='/unecos-list'>
                      <UnecosListContainer />
                    </PrivateRoute>
                    <PrivateRoute roles={allRolesExceptTechnical} exact={true} path='/offices-list'>
                      <OfficesListContainer />
                    </PrivateRoute>
                    <PrivateRoute roles={allRolesExceptTechnical} exact={true} path='/no-email-list'>
                      <EmailsNotReceivedListContainer />
                    </PrivateRoute>
                    <Redirect from='/all' exact={true} to='/reports-list/all' />
                    <Redirect from='/inprogress' exact={true} to='/reports-list/inprogress' />
                    <Redirect from='/finalized' exact={true} to='/reports-list/finalized' />

                    <SsoRedirectRoute path='/sso-redirect/*' />

                    <PrivateRoute exact={true} roles={[RoleNormalizedName.ADMIN, RoleNormalizedName.CUSTOMER_SERVICE]} path={['/user/newcustomers', '/user/newcustomers/:param']}>
                      <CustomIFrame src={settings.potentialCustomers + '?access_token=' + accessToken} width={'100%'} height={700} title={'Alta de clientes'} frameBorder={0} allow={'clipboard-read; clipboard-write'}
                        myParam={paramCustomer} />
                    </PrivateRoute>

                    <Route exact={true} path='/Certificates/Qr/:reportNo/:reversion/:lang' >
                      <QrRedirect />
                    </Route>

                    <Route exact={true} path='/410'>
                      <Error410 />
                    </Route>

                    <Route exact={true} path='/404'>
                      <Error404 />
                    </Route>

                    <PrivateRoute exact={true} path='/*'>
                      <Error404 />
                    </PrivateRoute>
                  </Switch>
                </div>
              </div>
              <ToastContainer />
              <BottomBanner />
            </Fragment>
          )}
        />
      </Router>
    );
  }

  private manageLocationChange(pathname: string) {
    const visible = !pathname.toLowerCase().includes('/sso-redirect');
    this.props.setMenuVisible(visible);
  }
}

const mapStateToProps = (state: RootState): IAppContainerStateProps => ({
  settings: state.settingsStore.settings,
  settingsErrors: state.settingsStore.errors,
  accessToken: state.currentUserStore.accessToken,
  isAnonymous: state.currentUserStore.isAnonymous,
  currentUser: state.currentUserStore.user,
  jwtInfo: state.currentUserStore.jwtInfo,
  currentUserErrors: state.currentUserStore.errors,
  menuVisible: state.menuStore.visible
});

const mapDispatchToProps: IAppContainerDispatchProps = {
  setInitialUser,
  saveFavoriteFilter,
  saveReportAllFilters,
  setMenuVisible: menuStore.setVisible,
  fetchSettings: settingsStore.fetchSettings,
  setAccessToken: currentUserStore.setAccessToken,
  fetchCurrentUser: currentUserStore.fetchCurrentUser
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AppContainer);
