export enum DialogId {
  GENERIC_CONFIRMATION,
  DISCARD_CHANGES,
  DEACTIVATE_ENTITY,
  EDIT_ANNEX,
  EDIT_ANNEX_STATE,
  SAVE_ADVANCED_FILTER,
  APPLY_ADVANCED_FILTERS,
  SHARE_ADVANCED_FILTER,
  UPLOAD_ANNEX,
  CHANGE_COMPANY,
  CONTEXTUAL_HELP,
  SIMULATE_EXTERNAL_USER,
  EDIT_COMPANY_SUPPLIER_PURCHASE_PRODUCTS,
  EDIT_REPORT_CUSTOM_FIELD_SETTINGS,
  REQUEST_CERTIFICATE_RENEWAL,
  REPORT_VERSIONS,
  EDIT_CHAIN_SERIES,
  CREATE_NOTE,
  SEND_REPORT_EMAIL,
  SHARE_CERTIFICATE_EMAIL,
  USER_FEEDBACK
}
