import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Sort,
  Page,
  Toolbar,
  ExcelExport,
  ExcelExportProperties,
  ExportType,
  Resize,
  DetailRow,
  SortSettingsModel,
  PagerComponent,
  EditSettingsModel,
  Edit,
  Column
} from '@syncfusion/ej2-react-grids';
import { ItemModel, ClickEventArgs } from '@syncfusion/ej2-navigations';
import { CheckBoxComponent, CheckBox } from '@syncfusion/ej2-react-buttons';

import { IEmailsNotReceivedGridProps } from '../../../interfaces/props/IEmailsNotReceivedListProps';
import { EXCEL_EXPORT_ACTION_ID, EXCEL_ADD_ACTION_ID, EXCEL_EDIT_ACTION_ID } from '../../../common/constants/gridActionId.constants';
import { IEmailNotReceived } from '../../../common/model/emailNotReceived.model';
import * as gridUtils from '../../../utils/grid.utils';
import * as pagerUtils from '../../../utils/pager.utils';
import * as sortUtils from '../../../utils/sort.utils';
import * as unecoCategoryNameUtils from '../../../utils/unecoCategoryName.utils';
import { enumToArray } from '../../../utils/enum.utils';
import { FieldSettingsModel } from '@syncfusion/ej2-react-dropdowns';
import { UnecoCategoryName } from '../../../common/model/enumerations/unecoCategoryName.model';
import { dialogsService } from '@aitex/tsx-extranet-dialogs';
import { DialogId } from '../../../common/model/enumerations/dialogId.model';
import { notifySuccess, notifyError } from '../../../utils/toast.utils';
import { emailsNotReceivedService } from '../../../services';
import './emailsNotReceivedGrid.scss';

type EmailsNotReceivedGridPropsType = IEmailsNotReceivedGridProps & RouteComponentProps & WithTranslation;

class EmailsNotReceivedGrid extends Component<EmailsNotReceivedGridPropsType> {
  public gridComponent: GridComponent = null;
  public toolbarOptions: ItemModel[] | string[] = [];
  public sortOptions: SortSettingsModel = null;
  public editOptions: EditSettingsModel = { allowAdding: true, allowEditing: true, mode: 'Dialog' };

  public pagerComponent: PagerComponent = null;

  public categoryNameOptions: Record<string, string | number>[] = [];
  public categoryNameFields: FieldSettingsModel = { text: 'text', value: 'value', iconCss: 'iconCss' };

  public excepExportType: ExportType = 'AllPages';
  public excelExportProperties: ExcelExportProperties = { exportType: this.excepExportType };

  public isNew = false;

  public initialEmailNotReceived: IEmailNotReceived = null;
  public rowIndex?: number = null;
  public modEmailNotReceived: IEmailNotReceived = null;
  public isReload = false;

  private eciCompanyId = '6015ff2b-cbfe-4f97-b76c-171d1912c7f4';
  private eciPassResult = '8D26EE93-EC2A-4ED8-BAB3-11950419D6FD';

  public constructor(props: EmailsNotReceivedGridPropsType) {
    super(props);

    const { sortField, sortDirection, i18n } = this.props;

    const allCategoryNameOptions = enumToArray(UnecoCategoryName, 'string', 'text', 'value').map((item) => ({
      ...item,
      text: item.value,
      iconCss: unecoCategoryNameUtils.getIconCss(item.value as string)
    }));

    this.categoryNameOptions = allCategoryNameOptions.filter(item => item.text !== 'TODOS');

    this.toolbarOptions = [
      {
        tooltipText: i18n.t('syncfusion.grid.Add'),
        id: EXCEL_ADD_ACTION_ID,
        prefixIcon: 'e-btn-icon e-add e-icons e-icon-left'
      },
      {
        tooltipText: i18n.t('syncfusion.grid.Edit'),
        id: EXCEL_EDIT_ACTION_ID,
        prefixIcon: 'e-btn-icon e-edit e-icons e-icon-left'
      },
      {
        tooltipText: i18n.t('syncfusion.grid.Excelexport'),
        id: EXCEL_EXPORT_ACTION_ID,
        prefixIcon: 'icon icon-file-excel-o'
      }
    ];

    this.sortOptions = {
      columns: [{ field: sortField, direction: sortDirection }]
    };
  }

  public componentDidUpdate(prevProps: EmailsNotReceivedGridPropsType) {
    gridUtils.manageSpinner(this.gridComponent, prevProps.isFetching, this.props.isFetching);
    gridUtils.manageSorting(this.gridComponent, prevProps.isFetching, this.props.isFetching, this.props.sortField, this.props.sortDirection);
  }

  public onToolbarClick(args: ClickEventArgs) {
    if (args.item.id === EXCEL_EXPORT_ACTION_ID) {
      this.gridComponent.excelExport(this.excelExportProperties);
    }

    if (args.item.id === EXCEL_ADD_ACTION_ID) {
      this.gridComponent.addRecord();
    }

    if (args.item.id === EXCEL_EDIT_ACTION_ID) {
      this.gridComponent.startEdit();
    }
  }

  public onGridComponentDataBound() {
    const { currentPage, pageSize, totalCount, searchDate, i18n } = this.props;

    pagerUtils.refreshPager(this.pagerComponent, currentPage, pageSize, totalCount, searchDate, i18n);
  }

  public async onGridComponentActionBegin(args: any) {
    if (args.requestType === 'sorting') {
      this.gridComponent.getColumnByField(args.columnName).sortComparer = (reference, comparer) =>
        sortUtils.gridSortComparer(reference, comparer, args.direction);

      gridUtils.manageSortingChange(args, this.props.sortField, this.props.sortDirection, this.props.onSortingChange);
    }

    if (args.requestType === 'beginEdit' || args.requestType === 'add') {
      this.showOrHideActiveField(true);
    }

    if (args.requestType === 'add') {
      this.isNew = true;
    } else if (args.requestType === 'beginEdit') {
      this.isNew = false;

      if (this.initialEmailNotReceived == null) {
        this.rowIndex = args.rowIndex;
        this.initialEmailNotReceived = {
          id: (args.rowData as any).id,
          active: (args.rowData as any).active,
          email: (args.rowData as any).email,
          companyId: (args.rowData as any).companyId,
          resultId: (args.rowData as any).resultId
        };
      }
    }
  }

  public async onGridComponentActionCompete(args: any) {
    const { i18n } = this.props;

    if (args.requestType === 'beginEdit' || args.requestType === 'add') {
      const dialog = args.dialog;
      dialog.width = 800;
      dialog.header = args.requestType === 'beginEdit' ? i18n.t('emailsNotReceivedList.editEmailNotReceived') + ' ' + this.initialEmailNotReceived.email + ' - ' + this.initialEmailNotReceived.resultId : i18n.t('emailsNotReceivedList.newEmailNotReceived');

      dialog.buttons = [
        {
          buttonModel: { content: i18n.t('syncfusion.grid.Update'), cssClass: 'e-control e-btn e-lib e-primary e-flat' },
          click: () => {
            this.modEmailNotReceived = {
              id: args.primaryKeyValue && args.primaryKeyValue.length > 0 ? args.primaryKeyValue[0] : null,
              active: args.form.querySelector('[id$="active"]').checked,
              email: args.form.querySelector('[id$="email"]').value,
              companyId: args.rowData.companyId ?? this.eciCompanyId,
              resultId: args.rowData.resultId ?? this.eciPassResult
            };

            const formObj = args.form['ej2_instances'][0];
            const formIsValid = formObj.validate();

            if (formIsValid) {
              this.saveCommon(this.modEmailNotReceived, i18n.t('emailsNotReceivedList.messages.acceptSuccess'), i18n.t('emailsNotReceivedList.messages.cannotAccept'));

              this.showOrHideActiveField(false);

              this.gridComponent.editModule.closeEdit();
            }
          }
        },
        {
          buttonModel: { content: i18n.t('syncfusion.grid.Cancel'), cssClass: 'e-control e-btn e-lib e-flat' },
          click: () => {
            this.showOrHideActiveField(false);
            this.cancel();
            this.gridComponent.editModule.closeEdit();
          }
        }        
      ];
      dialog.refresh();

      if ((args.requestType === 'beginEdit' || args.requestType === 'add')) {
        if (this.modEmailNotReceived != null && args.rowData.id != this.modEmailNotReceived.id) {
          this.modEmailNotReceived = null;
        }

        let active = true;
        let email = '';

        active = this.modEmailNotReceived != null || args.rowData.active != null ? (this.modEmailNotReceived != null && this.modEmailNotReceived.active != null && this.modEmailNotReceived != undefined) ? this.modEmailNotReceived.active : args.rowData.active : active;
        email = this.modEmailNotReceived != null || args.rowData.email != null ? this.modEmailNotReceived != null ? this.modEmailNotReceived.email : args.rowData.email : email;

        (args as any).form.querySelector('[id$="email"]').value = email;

        new CheckBox({
          checked: active,
        }, args.form.elements.namedItem('active') as HTMLInputElement);

        const separatorsNoteComponent = document.createElement('label');
        separatorsNoteComponent.setAttribute('class', 'separatorsNote');
        separatorsNoteComponent.innerHTML = i18n.t('emailSeparators');

        const obj = (args as any).form.querySelector('[name="email"]') as any;
        obj.parentElement.parentElement.appendChild(separatorsNoteComponent);
      }
    }
  }

  private showOrHideActiveField(visible: boolean) {
    this.showOrHideField('active', visible);
  }

  private showOrHideField(fieldName: string, visible: boolean) {
    if (this.gridComponent) {
      const cols: Column[] = this.gridComponent.columns as Column[];
      for (const col of cols) {
        if (col.field === fieldName) {
          col.visible = visible;
        }
      }
    }
  }

  public getActiveColumnTemplate(emailNotReceived: IEmailNotReceived): JSX.Element {
    return <CheckBoxComponent checked={emailNotReceived.active} disabled={true} onClick={() => { return false; } } />;
  }

  public getActiveEditTemplate(emailNotReceived: IEmailNotReceived): JSX.Element {
    const { i18n } = this.props;

    if (this.isNew) {
      return (<div className="e-float-input e-control-wrapper e-valid-input">
        <CheckBoxComponent checked={this.isNew} />
        <span className="e-float-line"></span>
        <label className="e-float-text e-label-top">{i18n.t('emailNotReceived.active')}</label>
      </div>);
    }

    return (<div className="e-float-input e-control-wrapper e-valid-input">
      <CheckBoxComponent checked={emailNotReceived.active} />
      <span className="e-float-line"></span>
      <label className="e-float-text e-label-top">{i18n.t('emailNotReceived.active')}</label>
    </div>);
  }

  public onPagerComponentClick(e: any) {
    if (e.currentPage !== this.props.currentPage) {
      this.props.onCurrentPageChange(e.currentPage);
    }
  }

  public onPageSizesDropdownChange(e: any) {
    if (e.pageSize !== this.props.pageSize) {
      this.props.onPageSizeChange(e.pageSize);
    }
  }

  private cancel() {
    this.rowIndex = null;
    this.initialEmailNotReceived = null;
    this.modEmailNotReceived = null;
    this.isReload = false;
  }

  private async saveCommon(emailNotReceived: IEmailNotReceived, successMessage: string, errorMessage: string): Promise<void> {
    await dialogsService.openDialog(DialogId.GENERIC_CONFIRMATION)
      .then(async () => {
        try {
          const savedEmailNotReceived = emailNotReceived.id !== undefined && emailNotReceived.id != null ? await emailsNotReceivedService.update(emailNotReceived) : await emailsNotReceivedService.create(emailNotReceived);

          this.isNew = false;
          this.props.saveEmailNotReceived(savedEmailNotReceived);
          this.cancel();

          notifySuccess(successMessage);
        } catch (error: any) {
          let newErrorMessage = errorMessage;
          if (error && error['UnknownError']) {
            const valueError = error['UnknownError'];
            if (Array.isArray(valueError)) {
              newErrorMessage = valueError.join(' ');
            } else {
              newErrorMessage = valueError;
            }
          }

          this.reloadInfo();
          notifyError(newErrorMessage);
        } finally {
          gridUtils.manageSpinner(this.gridComponent, true, false);
        }
      })
      .catch(() => {
        gridUtils.manageSpinner(this.gridComponent, true, false);
        this.reloadInfo();
      });
  }

  private reloadInfo() {
    this.isReload = true;

    if (this.isNew) {
      this.gridComponent.addRecord();
    } else {
      this.gridComponent.selectRow(this.rowIndex);
      this.gridComponent.startEdit();
    }
  }

  public render() {
    const { emailsNotReceived, i18n } = this.props;

    const emailValidationRules = { required: true };

    return (
      <div className='emailsNotReceived-grid'>
        <GridComponent
          dataSource={emailsNotReceived}
          toolbar={this.toolbarOptions}
          allowSorting={true}
          allowPaging={true}   /* Si no se incluye este paginador (oculto por css), no se muestra el dropdown en el customizado */
          allowExcelExport={true}
          allowResizing={true}
          editSettings={this.editOptions}
          sortSettings={this.sortOptions}
          pageSettings={{ pageSize: pagerUtils.getMaxPageSize() }}
          locale={i18n.language}
          ref={(grid: GridComponent) => this.gridComponent = grid}
          toolbarClick={(args) => this.onToolbarClick(args)}
          dataBound={() => this.onGridComponentDataBound()}
          actionBegin={(args) => this.onGridComponentActionBegin(args)}
          actionComplete={(args) => this.onGridComponentActionCompete(args)}
        >
          <ColumnsDirective>
            <ColumnDirective
              headerText={this.props.i18n.t('emailNotReceived.id')}
              field='id'
              isPrimaryKey={true}
              visible={false}
              allowEditing={false}
            />
            <ColumnDirective
              headerText={this.props.i18n.t('emailNotReceived.email')}
              field='email'
              textAlign='Center'
              width='100'
              validationRules={emailValidationRules}
            />
            <ColumnDirective
              headerText={this.props.i18n.t('emailNotReceived.resultId')}
              field='resultId'
              textAlign='Center'
              width='100'
              visible={false}
              allowEditing={false}
            />
            <ColumnDirective
              headerText={this.props.i18n.t('emailNotReceived.active')}
              field='active'
              width='35'
              template={(emailNotReceived: IEmailNotReceived) => this.getActiveColumnTemplate(emailNotReceived)}
              visible={false}
            />
            <ColumnDirective
              headerText={this.props.i18n.t('emailNotReceived.companyId')}
              field='companyId'
              width='100'
              visible={false}
              allowEditing={false}
            />
          </ColumnsDirective>
          <Inject services={[Sort, Page, Toolbar, ExcelExport, Resize, DetailRow, Edit]} />
        </GridComponent>
        {emailsNotReceived.length ?
          <PagerComponent
            pageSize={pagerUtils.getDefaultPageSize()}
            pageCount={pagerUtils.getPageCount()}
            pageSizes={pagerUtils.getPageSizes()}
            ref={(pagerComponent) => this.pagerComponent = pagerComponent}
            click={(e) => this.onPagerComponentClick(e)}
            dropDownChanged={(e) => this.onPageSizesDropdownChange(e)}
          />
          :
          null
        }
      </div>
    );
  }
}

export default withRouter(withTranslation()(EmailsNotReceivedGrid));
