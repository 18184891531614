import i18next from 'i18next';

import { UserState } from '../common/model/enumerations/userState.model';
import { getKeyByValue } from './enum.utils';

export const getLocalizedText = (i18n: i18next.i18n, key: string): string => {
  return i18n.t(`enums.userState.${key}`);
};

export const getLocalizedTextByValue = (i18n: i18next.i18n, state: number): string => {
  const key = getKeyByValue(UserState, state);
  if (!key) {
    return '';
  }
  return getLocalizedText(i18n, key);
};

export const getIconCss = (state: number): string => {
  switch (state) {
    case UserState.UNKNOW:
      return 'icon icon-none';
    case UserState.OK:
      return 'icon icon-request-acceptance-ok';
    case UserState.NOTEXISTS:
    case UserState.LOCKED:
      return 'icon icon-request-acceptance-ko';
    default:
      return '';
  }
};
