import { Action, Reducer, ActionCreator, combineReducers } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { DataManager, Query } from '@syncfusion/ej2-data';
import moment from 'moment';

import { RootState } from '..';
import * as notificationsListStore from './notificationsList.store';
import * as listGridStore from './listGrid.store';
import { INotificationsListNotificationsState } from '../../interfaces/states/INotificationsListState';
import { INotification } from '../../common/model/notification.model';
import { IODataService } from '../../services';

enum Type {
  SET_NOTIFICATIONS = '@@notificationsListNotifications/SET_NOTIFICATIONS'
}

const initialState: INotificationsListNotificationsState = {
  notifications: []
};

// Actions

export type Actions = notificationsListStore.ResetAction | notificationsListStore.SetFilterAction | notificationsListStore.SetFilterSubjectAction | notificationsListStore.SetFilterScopeAction
  | notificationsListStore.SetFilterActiveAction | notificationsListStore.SetFilterCompaniesIdsAction | notificationsListStore.SetFilterEmailAction | listGridStore.SetIsFetchingAction
  | listGridStore.SetSearchDateAction | listGridStore.SetCurrentPageAction | listGridStore.SetPageSizeAction | listGridStore.SetTotalCountAction | listGridStore.SetSortingAction
  | SetNotificationsAction;

export interface SetNotificationsAction extends Action<Type.SET_NOTIFICATIONS> {
  notifications: INotification[];
}

export const setNotifications: ActionCreator<SetNotificationsAction> = (notificationsLocal: INotification[]) => ({
  type: Type.SET_NOTIFICATIONS,
  notifications: notificationsLocal
});

export const fetchNotifications: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (
  oDataService: IODataService,
  notificationsDataManager: DataManager,
  notificationsQuery: Query
) => (
    async (dispatch, getState) => {
      dispatch(listGridStore.setIsFetching(notificationsListStore.NotificationsListType.SET_IS_FETCHING, true));

      const currentPageLocal = getState().notificationsListStore.grid.currentPage;
      const pageSizeLocal = getState().notificationsListStore.grid.pageSize;
      const sortFieldLocal = getState().notificationsListStore.grid.sortField;
      const sortDirectionLocal = getState().notificationsListStore.grid.sortDirection;

      let query = notificationsQuery.page(currentPageLocal, pageSizeLocal);
      if (sortFieldLocal && sortDirectionLocal) {
        query = sortDirectionLocal === 'Ascending' ? query.sortBy(sortFieldLocal) : query.sortByDesc(sortFieldLocal);
      }
      const notificationsResponse = await oDataService.executeQueryWithCount<INotification>(notificationsDataManager, query);
      const notificationsLocal = notificationsResponse.result.map((notification) => ({
        ...notification,
        createdDate: moment(notification.createdDate)
      }));
      dispatch(setNotifications(notificationsLocal));
      dispatch(listGridStore.setSearchDate(notificationsListStore.NotificationsListType.SET_SEARCH_DATE, moment()));
      dispatch(listGridStore.setTotalCount(notificationsListStore.NotificationsListType.SET_TOTAL_COUNT, notificationsResponse.count));
      dispatch(listGridStore.setIsFetching(notificationsListStore.NotificationsListType.SET_IS_FETCHING, false));
    }
  );

// Reducers

const notifications: Reducer<INotification[]> = (state = initialState.notifications, action) => {
  switch (action.type) {
    case notificationsListStore.NotificationsListType.RESET:
      return state;
    case Type.SET_NOTIFICATIONS:
      return (action as SetNotificationsAction).notifications;
    default:
      return state;
  }
};

export const notificationsListNotificationsStore = combineReducers({ notifications });
