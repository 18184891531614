import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { DropDownListComponent, ChangeEventArgs, MultiSelectComponent, MultiSelectChangeEventArgs, FieldSettingsModel } from '@syncfusion/ej2-react-dropdowns';

import { ICertificatesListFilterProps } from '../../../interfaces/props/ICertificatesListProps';
import { IStringToAnyDictionary } from '../../../common/model/stringToAnyDictionary.model';
import { CertificateStatus } from '../../../common/model/enumerations/certificateStatus.model';
import { CertificateRenewalState } from '../../../common/model/enumerations/certificateRenewalState.model';
import { CertificateType } from '../../../common/model/enumerations/certificateType.model';
import { enumToArray } from '../../../utils/enum.utils';
import * as certificateStatusUtils from '../../../utils/certificateStatus.utils';
import * as certificateRenewalStateUtils from '../../../utils/certificateRenewalState.utils';
import * as certificateTypeUtils from '../../../utils/certificateType.utils';
import TextBox from '../../../components/filters/textBox';
import DateRange from '../../../components/filters/dateRange';

type CertificatesListFilterPropsType = ICertificatesListFilterProps & WithTranslation;

class CertificatesListFilter extends Component<CertificatesListFilterPropsType> {
  public statusDropDownListComponent: DropDownListComponent = null;
  public statusOptions: IStringToAnyDictionary[] = [];
  public statusFields: FieldSettingsModel = { text: 'text', value: 'value', iconCss: 'iconCss' };

  public renewalStateDropDownListComponent: DropDownListComponent = null;
  public renewalStateOptions: IStringToAnyDictionary[] = [];
  public renewalStateFields: FieldSettingsModel = { text: 'text', value: 'value', iconCss: 'iconCss' };

  public certificateTypeOptions: IStringToAnyDictionary[] = [];
  public certificateTypeFields: FieldSettingsModel = { text: 'text', value: 'value' };

  public constructor(props: CertificatesListFilterPropsType) {
    super(props);

    const { i18n } = this.props;

    this.statusOptions = enumToArray(CertificateStatus, 'string', 'text', 'value').map((item) => ({
      ...item,
      text: certificateStatusUtils.getLocalizedText(i18n, item.value as string),
      iconCss: certificateStatusUtils.getIconCss(item.value as string)
    }));

    this.renewalStateOptions = enumToArray(CertificateRenewalState, 'number', 'text', 'value').map((item) => ({
      ...item,
      text: certificateRenewalStateUtils.getLocalizedTextByValue(i18n, item.value as number),
      iconCss: certificateRenewalStateUtils.getIconCss(item.value as number)
    }));

    this.certificateTypeOptions = enumToArray(CertificateType, 'string', 'text', 'value')
      .map((item) => ({ ...item, text: certificateTypeUtils.getLocalizedText(i18n, item.value as string) })); // item.value instead of item.text
  }

  public componentDidUpdate() {
    this.updateStatusDropDownListComponentValueTemplate();
    this.updateRenewalStateDropDownListComponentValueTemplate();
  }

  public isStatusDropDownListComponentEnabled(): boolean {
    return this.props.listMode === CertificateStatus.ALL;
  }

  public onStatusChange(e: ChangeEventArgs) {
    const status = e.value as string;
    this.props.onStatusChange(status);
  }

  public onRenewalStateChange(e: ChangeEventArgs) {
    const status = e.value as number;
    this.props.onRenewalStateChange(status);
  }

  public onCertificateTypeValuesChange(e: MultiSelectChangeEventArgs) {
    this.props.onCertificateTypeValuesChange(e.value as string[]);
  }

  public updateStatusDropDownListComponentValueTemplate() {
    if (this.statusDropDownListComponent) {
      const selectedStatusOption = this.statusOptions.find((statusOption) => statusOption.value === this.props.filter.status);
      if (selectedStatusOption) {
        this.statusDropDownListComponent.valueTemplate = `
          <div class="row no-gutters pl-3 pr-3 h-100">
            <div class="col align-self-center">
              <span class="${`mr-2 ${selectedStatusOption.iconCss}`}" ></span>
              <span>${selectedStatusOption.text}</span>
            </div>
          </div>
        `;
      }
    }
  }

  public updateRenewalStateDropDownListComponentValueTemplate() {
    if (this.renewalStateDropDownListComponent) {
      const selectedRenewalStateOption = this.renewalStateOptions.find((renewalStateOption) => renewalStateOption.value === this.props.filter.renewalState);
      if (selectedRenewalStateOption) {
        this.renewalStateDropDownListComponent.valueTemplate = `
          <div class="row no-gutters pl-3 pr-3 h-100">
            <div class="col align-self-center">
              <span class="${`mr-2 ${selectedRenewalStateOption.iconCss}`}" ></span>
              <span>${selectedRenewalStateOption.text}</span>
            </div>
          </div>
        `;
      }
    }
  }

  public shouldShowCompanyNameFilter(): boolean {
    return this.props.isCurrentUserInternal;
  }

  public render() {
    const {
      filter,
      isFetching,
      onIdentificationCodeChange,
      onOfferRequerimentStandardChange,
      onReportNumberChange,
      onReferenceNamesChange,
      onExpirationDateChange,
      onCompanyNameChange,
      onFilterButtonClick,
      i18n
    } = this.props;

    return (
      <form onSubmit={(e: React.FormEvent) => { e.preventDefault(); onFilterButtonClick(); }}>
        <div className='row'>
          <div className='col-12 col-md-6'>
            <div className='form-group'>
              <div className='autocomplete-input'>
                <div className='autocomplete-label'>{i18n.t('certificate.identificationCode')}</div>
                <TextBox
                  name='code'
                  value={filter.identificationCode}
                  showClearButton={true}
                  onChange={(value) => onIdentificationCodeChange(value)}
                />
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <div className='form-group'>
              <div className='autocomplete-input'>
                <div className='autocomplete-label'>{i18n.t('report.requerimentStandard')}</div>
                <TextBox
                  name='offerRequerimentStandard'
                  value={filter.offerRequerimentStandard}
                  showClearButton={true}
                  onChange={(value) => onOfferRequerimentStandardChange(value)}
                />
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <div className='form-group'>
              <div className='autocomplete-input'>
                <div className='autocomplete-label'>{i18n.t('report.reportNumber')}</div>
                <TextBox
                  name='reportNumber'
                  value={filter.reportNumber}
                  showClearButton={true}
                  onChange={(value) => onReportNumberChange(value)}
                />
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <div className='form-group'>
              <div className='autocomplete-input'>
                <div className='autocomplete-label'>{i18n.t('certificate.status')}</div>
                <DropDownListComponent
                  fields={this.statusFields}
                  dataSource={this.statusOptions}
                  value={filter.status}
                  enabled={this.isStatusDropDownListComponentEnabled()}
                  ref={(dropDownListComponent) => {
                    this.statusDropDownListComponent = dropDownListComponent;
                    this.updateStatusDropDownListComponentValueTemplate();
                  }}
                  change={(e) => this.onStatusChange(e)}
                />
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <div className='form-group'>
              <div className='autocomplete-input'>
                <div className='autocomplete-label'>{i18n.t('report.reference')}</div>
                <TextBox
                  name='referenceNames'
                  value={filter.referenceNames}
                  showClearButton={true}
                  onChange={(value) => onReferenceNamesChange(value)}
                />
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <div className='form-group'>
              <div className='autocomplete-input'>
                <div className='autocomplete-label'>{i18n.t('certificate.renewalState')}</div>
                <DropDownListComponent
                  fields={this.renewalStateFields}
                  dataSource={this.renewalStateOptions}
                  value={filter.renewalState}
                  ref={(dropDownListComponent) => {
                    this.renewalStateDropDownListComponent = dropDownListComponent;
                    this.updateRenewalStateDropDownListComponentValueTemplate();
                  }}
                  change={(e) => this.onRenewalStateChange(e)}
                />
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <div className='form-group'>
              <div className='autocomplete-input'>
                <div className='autocomplete-label'>{i18n.t('certificate.certificateType')}</div>
                <MultiSelectComponent
                  fields={this.certificateTypeFields}
                  dataSource={this.certificateTypeOptions}
                  allowFiltering={false}
                  mode='CheckBox'
                  showSelectAll={true}
                  selectAllText={i18n.t('actions.selectAll')}
                  unSelectAllText={i18n.t('actions.unselectAll')}
                  value={filter.certificateTypeValues}
                  change={(e) => this.onCertificateTypeValuesChange(e)}
                />
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <div className='form-group'>
              <DateRange
                name={i18n.t('certificate.expirationDate')}
                value={filter.expirationDate}
                i18n={i18n}
                onChangeInput={(value) => onExpirationDateChange(value)}
              />
            </div>
          </div>
          {this.shouldShowCompanyNameFilter() && <div className='col-12 col-md-6'>
            <div className='form-group'>
              <div className='autocomplete-input'>
                <div className='autocomplete-label'>{i18n.t('report.client')}</div>
                <TextBox
                  name='companyName'
                  value={filter.companyName}
                  showClearButton={true}
                  onChange={(value) => onCompanyNameChange(value)}
                />
              </div>
            </div>
          </div>}
        </div>
        <div className='row justify-content-end'>
          <div className='col-auto'>
            <button type='submit' className='btn btn-secondary' title={i18n.t('actions.filter')} disabled={isFetching}>
              {isFetching ?
                <span className='spinner-border spinner-border-sm' />
                :
                <span className='icon icon-app-item-search' />
              }
            </button>
          </div>
        </div>
      </form>
    );
  }
}

export default withTranslation()(CertificatesListFilter);
