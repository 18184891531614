import { URL } from '../common/constants';
import { IOffice } from '../common/model/office.model';
import * as services from '.';

export interface IOfficeService {
  create(office: IOffice): Promise<IOffice>;
  update(office: IOffice): Promise<IOffice>;
}

class OfficesService implements IOfficeService {
  public constructor(private httpClient: services.IHttpClient) {

  }

  public async create(office: IOffice): Promise<IOffice> {
    return await this.httpClient.postCustom(`${URL.WEB.BASE}${URL.WEB.OFFICES}`, office);
  }

  public async update(office: IOffice): Promise<IOffice> {
    return await this.httpClient.putCustom(`${URL.WEB.BASE}${URL.WEB.OFFICES}/${office.id}`, office);
  }
}

export const officesService = new OfficesService(services.httpClient);
