import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import AppContainer from './App';
import store from './store';
import './i18n';
import { loadSettings, httpClient, authService, telemetryService } from './services';

(async () => {
  const settings = await loadSettings();
  httpClient.init(settings);
  authService.init();
  await telemetryService.init();

  ReactDOM.render(
    <Provider store={store}><AppContainer /></Provider>,
    document.getElementById('root')
  );
})();
