import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { FormValidator, FormEventArgs } from '@syncfusion/ej2-react-inputs';
import { DialogComponent } from '@aitex/tsx-extranet-dialogs';

import { IEditAnnexDialogProps } from '../../../interfaces/props/IDialogsProps';
import { IAnnex } from '../../../common/model/annex.model';
import { IErrors } from '../../../common/model/errors.model';
import { Validator } from '../../../common/model/enumerations/validator.model';
import { annexesService } from '../../../services';
import * as dialogUtils from '../../../utils/dialog.utils';
import * as formValidatorUtils from '../../../utils/formValidator.utils';
import ValidationError from '../../validationError/validationError';
import TextBox from '../../filters/textBox';

type EditAnnexDialogPropsType = IEditAnnexDialogProps & WithTranslation;

type EditAnnexDialogStateType = IEditAnnexDialogState;

class EditAnnexDialog extends Component<EditAnnexDialogPropsType, EditAnnexDialogStateType> {
  public readonly EDIT_ANNEX_DIALOG_FORM_ID = 'editAnnexDialogForm';

  private readonly CANCEL_BUTTON_ID = 'editAnnexDialogCancel';
  private readonly OK_BUTTON_ID = 'editAnnexDialogOk';

  private readonly CANNOT_EDIT_ANNEX_ERROR: IErrors = { UnknownError: ['dialogs.editAnnexDialog.error'] };

  private formValidator: FormValidator = null;
  private isValidatingAll = false;
  private auxErrors: IErrors = {};

  private initialState: EditAnnexDialogStateType = {
    annex: null,
    isLoading: false,
    errors: {}
  };

  public constructor(props: EditAnnexDialogPropsType) {
    super(props);

    this.state = this.initialState;
  }

  public componentDidUpdate(prevProps: EditAnnexDialogPropsType): void {
    const { visible, annex } = this.props;

    if (!prevProps.visible && visible) {
      this.setState({ annex });
    }
    if (prevProps.visible && !visible) {
      this.setState(this.initialState);
      this.resetFormValidator();
    }

    dialogUtils.manageButtonsClick(this.OK_BUTTON_ID, (): Promise<void> => this.updateAnnex(), this.CANCEL_BUTTON_ID, (): void => this.dismiss());
  }

  public async updateAnnex(): Promise<void> {
    try {
      if (!this.validateForm()) {
        return;
      }

      this.setState({ isLoading: true });

      if (!this.state.annex) {
        throw this.CANNOT_EDIT_ANNEX_ERROR;
      }

      await annexesService.updateDescription(this.state.annex.id, this.state.annex.description);
      this.close();
    } catch (error) {
      let errors = error as IErrors;
      if (errors.Description) {
        this.setState({ errors });
        return;
      }
      if (errors.UnknownError || (!errors.UnknownError && !errors.ControlledError)) {
        errors = this.CANNOT_EDIT_ANNEX_ERROR;
      }
      this.dismiss(errors);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  public close(): void {
    this.formValidator = null;
    this.props.onClose();
  }

  public dismiss(reason?: IErrors): void {
    this.props.onDismiss(reason);
  }

  public configureFormValidator(): void {
    if (this.props.visible) {
      if (!this.formValidator) {
        this.formValidator = formValidatorUtils.configureFormValidator(this.EDIT_ANNEX_DIALOG_FORM_ID, {
          Description: { required: [true, Validator.NOT_EMPTY] }
        });
        this.formValidator.validationComplete = (args) => {
          formValidatorUtils.validationComplete(args as FormEventArgs, this.isValidatingAll, this.state.errors, this.auxErrors, (errors): void => this.setState({ errors }));
        };
      }
    } else {
      this.formValidator = null;
    }
  }

  public onDescriptionChange(description: string): void {
    this.setState({ annex: { ...this.state.annex, description } });
  }

  public render(): JSX.Element {
    const { visible, i18n } = this.props;

    return (
      <DialogComponent
        header={i18n.t('dialogs.editAnnexDialog.title')}
        visible={visible}
        width='480px'
        footerTemplate={dialogUtils.computeFooterTemplate(
          this.OK_BUTTON_ID,
          i18n.t('actions.ok'),
          this.CANCEL_BUTTON_ID,
          i18n.t('actions.cancel'),
          this.state.isLoading,
          this.isOkButtonDisabled()
        )}
        onDismiss={(): void => this.dismiss()}
      >
        <div>
          {this.state.annex && <form id={this.EDIT_ANNEX_DIALOG_FORM_ID} ref={(): void => this.configureFormValidator()}>
            <div className='row'>
              <div className='col'>
                <div className='form-group'>
                  <div className='autocomplete-input'>
                    <div className='autocomplete-label'>{i18n.t('annex.description')}</div>
                    <TextBox
                      name='Description'
                      value={this.state.annex.description}
                      onChange={(value) => this.onDescriptionChange(value)}
                    />
                  </div>
                  <ValidationError errors={this.state.errors} errorKey={'Description'} />
                </div>
              </div>
            </div>
          </form>}
        </div>
      </DialogComponent>
    );
  }

  private isOkButtonDisabled(): boolean {
    return !!this.state.errors.Description || this.state.isLoading;
  }

  private resetFormValidator(): void {
    if (!this.formValidator) {
      return;
    }

    if (this.formValidator.getInputElement('Description')) {
      this.formValidator.getInputElement('Description').parentElement.className = this.formValidator.getInputElement('Description').parentElement.className.replace('e-error', '');
    }
    this.formValidator.removeRules();
    this.formValidator.validationComplete = undefined;
  }

  private validateForm(): boolean {
    if (!this.formValidator) {
      return true;
    }

    this.isValidatingAll = true;
    const isFormValid = this.formValidator.validate();
    this.isValidatingAll = false;
    if (!isFormValid) {
      this.setState({ errors: this.auxErrors });
      this.auxErrors = {};
    }
    return isFormValid;
  }
}

interface IEditAnnexDialogState {
  annex: IAnnex;
  isLoading: boolean;
  errors: IErrors;
}

export default withTranslation()(EditAnnexDialog);
