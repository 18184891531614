export enum CertificateType {
  CORTEFIEL = 'cortefiel',
  CETYPE = 'cetype',
  CETYPE_MODULE_C1 = 'cetype_module_c1',
  CETYPE_MODULE_C2 = 'cetype_module_c2',
  OEKOTEX = 'oekotex',
  UNDEFINED = 'undefined',
  UE_TYPE_MODULE = 'ue_type_module_-',
  UE_TYPE_MODULE_B = 'ue_type_module_b',
  UE_TYPE_MODULE_C1 = 'ue_type_module_c1',
  UE_TYPE_MODULE_C2 = 'ue_type_module_c2',
  UE_TYPE_MODULE_D = 'ue_type_module_d',
  REACTFUEGO = 'reactFuego',
  PROPTR = 'propTR'
}
