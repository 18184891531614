export const manageButtonsClick = (okButtonId: string, onOkButtonClick: () => void, cancelButtonId: string, onCancelButtonClick: () => void): void => {
  if (okButtonId && onOkButtonClick && document.getElementById(okButtonId)) {
    document.getElementById(okButtonId).onclick = () => {
      onOkButtonClick();
    };
  }
  if (cancelButtonId && onCancelButtonClick && document.getElementById(cancelButtonId)) {
    document.getElementById(cancelButtonId).onclick = () => {
      onCancelButtonClick();
    };
  }
};

export const computeFooterTemplate = (okButtonId: string, okButtonText: string, cancelButtonId: string, cancelButtonText: string, isLoading?: boolean, isOkButtonDisabled?: boolean): string => {
  const auxIsOkButtonDisabled = typeof isOkButtonDisabled === 'boolean' ? isOkButtonDisabled : (typeof isLoading === 'boolean' && isLoading ? isLoading : false);

  return `
    <div class="container-fluid">
      <div class="row justify-content-end">
        ${(cancelButtonId && cancelButtonText) ? (
          `<div class="col-auto">
            <button type="button" id="${cancelButtonId}" class="btn btn-light" ${typeof isLoading === 'boolean' && isLoading ? 'disabled' : ''}>
              <span>${cancelButtonText}</span>
            </button>
          </div>`
        ) : ''}
        ${(okButtonId && okButtonText) ? (
          `<div class="col-auto">
            <button type="button" id="${okButtonId}" class="btn btn-secondary" ${auxIsOkButtonDisabled ? 'disabled' : ''}>
              ${typeof isLoading === 'boolean' && isLoading ? '<span class="spinner-border spinner-border-sm" />' : `<span>${okButtonText}</span>`}
            </button>
          </div>`
        ) : ''}
      </div>
    </div>
  `;
};
