export function format(str: string, val: string[]): string {
  for (let index = 0; index < val.length; index++) {
    str = str.replace(`{${index}}`, val[index]);
  }
  return str;
}

export const throttle = (callback: (...args: any[]) => any, limit: number): (...args: any[]) => any => {
  let wait = false;

  return <T>(thisArg: T, ...arguments_: any[]): any => {
    if (!wait) {
      callback.call(thisArg, arguments_);
      wait = true;
      setTimeout(() => {
        wait = false;
      }, limit);
    }
  };

}

export const debounce = (callback: (...args: any[]) => any, wait: number, immediate?: boolean): (...args: any[]) => any => {
  let timeout: NodeJS.Timeout;

  return <T>(thisArg: T, ...arguments_: any[]): any => {
    const later: (...args: any[]) => any = () => {
      timeout = undefined;
      if (!immediate) {
        callback.call(thisArg, arguments_);
      }
    };

    const callNow = !!immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) {
      callback.call(thisArg, arguments_);
    }
  }
}
