import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { WithTranslation, withTranslation } from 'react-i18next';
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, Sort, Page, Toolbar, ExcelExport, Resize, SortSettingsModel, PagerComponent, SortEventArgs } from '@syncfusion/ej2-react-grids';
import { ItemModel, ClickEventArgs } from '@syncfusion/ej2-navigations';

import { ISuppliersGridProps } from '../../../interfaces/props/ISuppliersListProps';
import { EXCEL_EXPORT_ACTION_ID } from '../../../common/constants/gridActionId.constants';
import { ISupplier } from '../../../common/model/supplier.model';
import * as gridUtils from '../../../utils/grid.utils';
import * as pagerUtils from '../../../utils/pager.utils';
import * as sortUtils from '../../../utils/sort.utils';
import './suppliersGrid.scss';

type SuppliersGridPropsType = ISuppliersGridProps & RouteComponentProps & WithTranslation;

class SuppliersGrid extends Component<SuppliersGridPropsType> {
  public gridComponent: GridComponent = null;
  public toolbarOptions: ItemModel[] = [];
  public sortOptions: SortSettingsModel = null;

  public pagerComponent: PagerComponent = null;

  private readonly CREATE_SUPPLIER_ACTION_ID = 'createSupplier';

  public constructor(props: SuppliersGridPropsType) {
    super(props);

    const { sortField, sortDirection, isCurrentUserInternal, i18n } = this.props;

    this.toolbarOptions = [{
      tooltipText: i18n.t('syncfusion.grid.Excelexport'),
      id: EXCEL_EXPORT_ACTION_ID,
      prefixIcon: 'icon icon-file-excel-o'
    }];
    if (isCurrentUserInternal) {
      this.toolbarOptions.push({
        tooltipText: i18n.t('suppliersList.actions.create'),
        id: this.CREATE_SUPPLIER_ACTION_ID,
        template: `
          <button type="button" class="e-tbar-btn e-tbtn-txt e-control e-btn e-lib">
            <span class="icon icon-supplier"></span>
            <span class="ml-1 icon icon-app-item-add"></span>
          </button>
        `
      });

      this.sortOptions = {
        columns: [{ field: sortField, direction: sortDirection }]
      };
    }
  }

  public componentDidUpdate(prevProps: SuppliersGridPropsType) {
    gridUtils.manageSpinner(this.gridComponent, prevProps.isFetching, this.props.isFetching);
    gridUtils.manageSorting(this.gridComponent, prevProps.isFetching, this.props.isFetching, this.props.sortField, this.props.sortDirection);
  }

  public onToolbarClick(args: ClickEventArgs) {
    if (args.item.id === EXCEL_EXPORT_ACTION_ID) {
      this.gridComponent.excelExport();
    }

    if (args.item.id === this.CREATE_SUPPLIER_ACTION_ID) {
      this.props.history.push('/supplier/new');
    }
  }

  public onGridComponentDataBound() {
    const { currentPage, pageSize, totalCount, searchDate, i18n } = this.props;

    pagerUtils.refreshPager(this.pagerComponent, currentPage, pageSize, totalCount, searchDate, i18n);
  }

  public onGridComponentActionBegin(args: SortEventArgs) {
    if (args.requestType === 'sorting') {
      this.gridComponent.getColumnByField(args.columnName).sortComparer = (reference, comparer) =>
        sortUtils.gridSortComparer(reference, comparer, args.direction);

      gridUtils.manageSortingChange(args, this.props.sortField, this.props.sortDirection, this.props.onSortingChange);
    }
  }

  public getCodeColumnTemplate(supplier: ISupplier): JSX.Element {
    const { isCurrentUserInternal, history } = this.props;

    return <span className='e-link' onClick={() => history.push(`/supplier/${supplier.id}/${isCurrentUserInternal ? 'edit' : 'view'}`)}>{supplier.code}</span>;
  }

  public onPagerComponentClick(e: any) {
    if (e.currentPage !== this.props.currentPage) {
      this.props.onCurrentPageChange(e.currentPage);
    }
  }

  public onPageSizesDropdownChange(e: any) {
    if (e.pageSize !== this.props.pageSize) {
      this.props.onPageSizeChange(e.pageSize);
    }
  }

  public render() {
    const { suppliers, i18n } = this.props;

    return (
      <div className='suppliers-grid'>
        <GridComponent
          dataSource={suppliers}
          toolbar={this.toolbarOptions}
          allowSorting={true}
          allowPaging={true}   /* Si no se incluye este paginador (oculto por css), no se muestra el dropdown en el customizado */
          allowExcelExport={true}
          allowResizing={true}
          sortSettings={this.sortOptions}
          pageSettings={{ pageSize: pagerUtils.getMaxPageSize() }}
          locale={i18n.language}
          ref={(grid) => this.gridComponent = grid}
          toolbarClick={(args) => this.onToolbarClick(args)}
          dataBound={() => this.onGridComponentDataBound()}
          actionBegin={(args) => this.onGridComponentActionBegin(args as SortEventArgs)}
        >
          <ColumnsDirective>
            <ColumnDirective
              headerText={this.props.i18n.t('supplier.code')}
              field='code'
              width='150'
              template={(supplier: ISupplier) => this.getCodeColumnTemplate(supplier)}
            />
            <ColumnDirective
              headerText={this.props.i18n.t('supplier.name')}
              field='name'
              width='250'
            />
            <ColumnDirective
              headerText={this.props.i18n.t('supplier.contractNumber')}
              field='contractNumber'
              width='150'
            />
            <ColumnDirective
              headerText={this.props.i18n.t('supplier.city')}
              field='city'
              width='150'
            />
            <ColumnDirective
              headerText={this.props.i18n.t('supplier.province')}
              field='province'
              width='150'
            />
            <ColumnDirective
              headerText={this.props.i18n.t('supplier.country')}
              field='country'
              width='150'
            />
            <ColumnDirective
              headerText={this.props.i18n.t('supplier.active')}
              field='active'
              width='150'
              type='boolean'
              displayAsCheckBox={true}
            />
          </ColumnsDirective>
          <Inject services={[Sort, Page, Toolbar, ExcelExport, Resize]} />
        </GridComponent>
        {suppliers.length ?
          <PagerComponent
            pageSize={pagerUtils.getDefaultPageSize()}
            pageCount={pagerUtils.getPageCount()}
            pageSizes={pagerUtils.getPageSizes()}
            ref={(pagerComponent) => this.pagerComponent = pagerComponent}
            click={(e) => this.onPagerComponentClick(e)}
            dropDownChanged={(e) => this.onPageSizesDropdownChange(e)}
          />
          :
          null
        }
      </div>
    );
  }
}

export default withRouter(withTranslation()(SuppliersGrid));
