import React from 'react';

import { Status } from '../../../../helpers/reportStatus.helpers';
import i18n from '../../../../i18n';
import './reportStatusTable.scss';
import { IReportStatusContent } from './iReportStatusTable';

export enum statusImages {
  PASS = 'pass',
  NO_PASS = 'nopass',
  PENDING = 'pending',
  CANCELLED = 'cancelled',
  PPP = 'ppp'
}

function getStatusImage(status: string | number, isGeneralStatus?: boolean) {
  if (status === Status.NO_PASS || (status === Status.CANCELLED && isGeneralStatus !== undefined && !isGeneralStatus)) {
    return statusImages.NO_PASS;
  }

  if (status === Status.PENDING) {
    return statusImages.PENDING;
  }

  if (status === Status.CANCELLED) {
    return statusImages.CANCELLED;
  }

  if (status === Status.PPP) {
    return statusImages.PPP;
  }

  return statusImages.PASS;
}

const ReportStatusTable = ({ title, generalStatus, tableContent }: IReportStatusContent): JSX.Element => {
  return (
    <div className='meta-container report-status mt-5 w-100'>
      <div className='row'>
        <div className='col'>
          <div className='meta-header m-auto align-items-center flex-column d-flex'>
            {(title !== i18n.t('reportStatus.info')) ?
              ((generalStatus.toLowerCase() === `${statusImages.PENDING}`) || (generalStatus.toLowerCase() === `${statusImages.CANCELLED}`)) ?
                <i className={`icon icon-${getStatusImage(generalStatus, true)}`} title={i18n.t(`reportStatus.${getStatusImage(generalStatus, true)}`)} />
                :
                <div className={`traffic traffic-${getStatusImage(generalStatus)}`} title={i18n.t(`reportStatus.${getStatusImage(generalStatus)}`)} />
              : <></>}
            <span className='meta-label mb-2'>{title}</span>
            {(title !== i18n.t('reportStatus.info')) && generalStatus === 'NO PASS' ?
              <p className="resultNote">{i18n.t(`reportStatus.${getStatusImage(generalStatus, true)}Note`)}</p>
              :
              null
            }
          </div>
        </div>
      </div>
      <div className='row mt-3 p-3 align-items-end'>
        {tableContent && tableContent.map((result, index) =>
          generalStatus && (title === i18n.t('reportStatus.info')) ?
            <div key={index} className='col-12 col-md-4'>
              {result.value &&
                <div className='meta-list_item ml-auto pt-3 pb-1 d-flex flex-sm-row flex-column align-items-center'>
                  {result.key !== i18n.t('reportStatus.State') && <span className='meta-label mr-2 mb-1'>{result.key}</span>}
                  <span>{result.value}</span>
                </div>}
            </div>
            :
            <div key={index} className='col-12 col-md-6'>
              {result.value &&
                <div className='meta-list_item ml-auto pt-3 pb-1 d-flex flex-sm-row flex-column align-items-center'>
                  {<span className='meta-label mr-2 mb-1'>{result.key}</span>}
                  <span>
                    <i className={`icon icon-4x icon-${getStatusImage(result.value)}`} /> {i18n.t(`reportStatus.${result.value.toString().replace(' ', '').toLowerCase()}`)}
                  </span>
                </div>}
            </div>
        )}
      </div>
    </div >
  );
};

export default ReportStatusTable;
