import i18next from 'i18next';

import { AnnexedState } from '../common/model/enumerations/annexedState.model';
import { getKeyByValue } from './enum.utils';

export const getLocalizedText = (i18n: i18next.i18n, key: string): string => {
  return i18n.t(`enums.annexedState.${key}`);
};

export const getLocalizedTextByValue = (i18n: i18next.i18n, state: number): string => {
  const key = getKeyByValue(AnnexedState, state);
  if (!key) {
    return '';
  }
  return getLocalizedText(i18n, key);
};

export const getIconCss = (state: number): string => {
  switch (state) {
    case AnnexedState.PENDING:
      return 'icon icon-none';
    case AnnexedState.OK:
      return 'icon icon-check-square-o';
    case AnnexedState.KO:
      return 'icon icon-share-square-o';
    default:
      return '';
  }
};
