import i18next from 'i18next';

import { RequestStatus } from '../common/model/enumerations/requestStatus.model';

export const getLocalizedText = (i18n: i18next.i18n, status: string): string => {
  return i18n.t(`enums.requestStatus.${status}`);
};

export const getIconCss = (status: string): string => {
  switch (status) {
    case RequestStatus.ALL:
      return 'icon icon-app-item-list';
    case RequestStatus.OPEN:
      return 'icon icon-eci-list-open';
    case RequestStatus.IN_PROGRESS:
      return 'icon icon-state-in-progress';
    case RequestStatus.FINALIZED:
      return 'icon icon-report-state-finalized';
    case RequestStatus.CANCELLED:
      return 'icon icon-app-item-cancel';
    default:
      return '';
  }
};
