import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
  GridComponent,
  ToolbarItems,
  PageSettingsModel,
  FilterSettingsModel,
  EditSettingsModel,
  ActionEventArgs,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Sort,
  Page,
  Toolbar,
  Resize,
  Filter,
  Edit
} from '@syncfusion/ej2-react-grids';
import { DataManager } from '@syncfusion/ej2-data';

import { IMeasurementUnitsGridProps } from '../../../interfaces/props/IMeasurementUnitsListProps';
import { IMeasurementUnitEnglishSpanish } from '../../../common/model/measurementUnit.model';
import * as gridUtils from '../../../utils/grid.utils';
import * as pagerUtils from '../../../utils/pager.utils';
import './measurementUnitsGrid.scss';

type MeasurementUnitsGridPropsType = IMeasurementUnitsGridProps & WithTranslation;

class MeasurementUnitsTab extends Component<MeasurementUnitsGridPropsType> {
  public readonly ACTIVE_DEFAULT_VALUE = true;

  public gridComponent: GridComponent = null;
  public toolbarOptions: ToolbarItems[] = ['Add', 'Edit', 'Update', 'Cancel'];
  public pageSettings: PageSettingsModel = {
    pageSize: pagerUtils.getMaxPageSize(),
    pageCount: pagerUtils.getPageCount(),
    pageSizes: pagerUtils.getPageSizes()
  };
  public editSettings: EditSettingsModel = { allowEditing: true, allowEditOnDblClick: false, allowAdding: true, newRowPosition: 'Top' };
  public filterSettings: FilterSettingsModel = { type: 'Menu' };

  public componentDidUpdate(prevProps: MeasurementUnitsGridPropsType) {
    gridUtils.manageSpinner(this.gridComponent, prevProps.isFetching, this.props.isFetching);
    gridUtils.manageDataSource(this.gridComponent, () => this.updateGridComponentDataSource(), prevProps.isFetching, this.props.isFetching);
  }

  public updateGridComponentDataSource() {
    this.gridComponent.dataSource = new DataManager(JSON.parse(JSON.stringify(this.props.measurementUnits))); // Clone by value
  }

  public onActionComplete(args: ActionEventArgs) {
    const { onMeasurementUnitSave } = this.props;

    if (args.requestType === 'save') {
      const data = args.data as any;
      const measurementUnit: IMeasurementUnitEnglishSpanish = {
        ...data,
        measurementUnitId: data.measurementUnitCode ? data.measurementUnitCode.id : null,
        measurementUnitCode: data.measurementUnitCode ? data.measurementUnitCode.code : null
      };
      setTimeout(() => onMeasurementUnitSave(measurementUnit));
    }
  }

  public render() {
    const { i18n } = this.props;

    return (
      <div className='measurement-units-grid'>
        <GridComponent
          toolbar={this.toolbarOptions}
          allowSorting={true}
          allowPaging={true}
          allowResizing={true}
          allowFiltering={true}
          sortSettings={{ columns: [{ field: 'nameEs' }] }}
          pageSettings={this.pageSettings}
          filterSettings={this.filterSettings}
          editSettings={this.editSettings}
          locale={i18n.language}
          ref={(grid) => this.gridComponent = grid}
          created={() => this.updateGridComponentDataSource()}
          actionComplete={(args) => this.onActionComplete(args as ActionEventArgs)}
        >
          <ColumnsDirective>
            <ColumnDirective field='code' headerText={i18n.t('measurementUnit.code')} width='150' type='string' validationRules={{ required: true }} />
            <ColumnDirective field='nameEs' headerText={i18n.t('measurementUnit.nameEs')} width='150' type='string' validationRules={{ required: true }} />
            <ColumnDirective field='nameEn' headerText={i18n.t('measurementUnit.nameEn')} width='150' type='string' validationRules={{ required: true }} />
            <ColumnDirective field='descriptionEs' headerText={i18n.t('measurementUnit.descriptionEs')} width='150' type='string' validationRules={{ required: true }} />
            <ColumnDirective field='descriptionEn' headerText={i18n.t('measurementUnit.descriptionEn')} width='150' type='string' validationRules={{ required: true }} />
            <ColumnDirective
              field='active'
              headerText={i18n.t('measurementUnit.active')}
              width='100'
              type='boolean'
              defaultValue={this.ACTIVE_DEFAULT_VALUE + ''}
              editType='booleanedit'
              displayAsCheckBox={true}
            />
          </ColumnsDirective>
          <Inject services={[Sort, Page, Toolbar, Resize, Filter, Edit]} />
        </GridComponent>
      </div>
    );
  }
}

export default withTranslation()(MeasurementUnitsTab);
