import store from '../store';
import { URL } from '../common/constants';
import { IUserFeedback } from '../common/model/userFeedback.model';
import * as services from '.';

export interface IUserFeedbacksService {
  getUploadUrl(type: string): string;
  sendWithoutFiles(userFeedback: IUserFeedback, type: string): Promise<IUserFeedback>;
  sendWithFiles(files: Array<string | Blob>, userFeedback: IUserFeedback, type: string): Promise<IUserFeedback>;
}

class UserFeedbacksService implements IUserFeedbacksService {
  public constructor(private httpClient: services.IHttpClient) { }

  public getUploadUrl(type: string): string {
    return `${store.getState().settingsStore.settings.api}${URL.WEB.BASE}${URL.WEB.USER_FEEDBACKS}/${type.toLowerCase()}`;
  }

  public sendWithoutFiles(userFeedback: IUserFeedback, type: string): Promise<IUserFeedback> {
    const formData = this.createFormData([], userFeedback);
    return this.httpClient.postCustom(this.getUploadUrl(type), formData);
  }

  public sendWithFiles(files: Array<string | Blob>, userFeedback: IUserFeedback, type: string): Promise<IUserFeedback> {
    const formData = this.createFormData(files, userFeedback);
    return this.httpClient.postCustom(this.getUploadUrl(type), formData);
  }

  private createFormData(files: Array<string | Blob>, userFeedback: IUserFeedback): FormData {
    const formData = new FormData();
    for (const file of files) {
      formData.append('Files', file);
    }
    formData.append('experienceLevel', userFeedback.experienceLevel + '');
    formData.append('message', userFeedback.message);
    for (const reportId of userFeedback.reportIds) {
      formData.append('reportIds', reportId);
    }
    return formData;
  }
}

export const userFeedbacksService = new UserFeedbacksService(services.httpClient);
