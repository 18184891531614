import { AxiosInstance } from 'axios';

import { RESPONSE_STATUS } from '../../common/constants';
import { BAD_REQUEST_ERROR, INTERNAL_SERVER_ERROR } from '../../common/constants/error.constants';
import { IErrors } from '../../common/model/errors.model';
import { authService } from '../auth.service';

export function configureInterceptors(client: AxiosInstance): void {
  client.interceptors.request.use(
    (request) => {
      return request;
    },
    () => {
      return Promise.reject(BAD_REQUEST_ERROR);
    }
  );

  client.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      let errors: IErrors = BAD_REQUEST_ERROR;

      if (error.response) {
        if (error.response.status === RESPONSE_STATUS.UNAUTHORIZED) {
          authService.login();
        }

        if ((error.response.status + '').startsWith('4')) {
          errors = BAD_REQUEST_ERROR;
        } else if ((error.response.status + '').startsWith('5')) {
          errors = INTERNAL_SERVER_ERROR;
        }
        if (!errors[error.response.status]) {
          errors[error.response.status] = [error.response.statusText];
        }

        if (error.response.data.errors) {
          errors = { ...errors, ...error.response.data.errors };
        }
      }

      return Promise.reject(errors);
    }
  );
}
