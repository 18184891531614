import { Action, Reducer, ActionCreator, combineReducers } from 'redux';

enum Type {
  SET_USER = '@@filters/SET_USER'
}

export interface UserState {
  user: User;
}

export interface User {
  name: string;
  access_token: string;
  sub: string;
  isAuth: boolean;
  userType: number;
  companyId: string;
  role: string;
  email: string;
}

const initialState: UserState = {
  user: {
    name: null,
    access_token: null,
    sub: null,
    isAuth: null,
    userType: null,
    companyId: null,
    role: null,
    email: null
  }
};

// Actions

export interface SetInitialUserAction extends Action<Type.SET_USER> {
  user: User;
}

export const setInitialUser: ActionCreator<SetInitialUserAction> = (userLocal: User) => ({
  type: Type.SET_USER,
  user: userLocal
});

// Reducers

const user: Reducer<User> = (state = initialState.user, action) => {
  if (action.type === Type.SET_USER) {
    return (action as SetInitialUserAction).user;
  }
  return state;
};

export const userStore = combineReducers({ user });
