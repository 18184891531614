import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { WithTranslation, withTranslation } from 'react-i18next';
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, Sort, Page, Toolbar, Resize, Edit, PageSettingsModel, EditSettingsModel, ActionEventArgs } from '@syncfusion/ej2-react-grids';
import { ClickEventArgs, ItemModel } from '@syncfusion/ej2-navigations';
import { DataManager } from '@syncfusion/ej2-data';

import { IReferencesTabProps } from '../../../interfaces/props/ITabsProps'
import { IReference } from '../../../common/model/reference.model';
import { SourceType } from '../../../common/model/enumerations/sourceType.model';
import * as gridUtils from '../../../utils/grid.utils';
import * as pagerUtils from '../../../utils/pager.utils';
import './referencesTab.scss';

type ReferencesTabPropsType = IReferencesTabProps & RouteComponentProps & WithTranslation;

class ReferencesTab extends Component<ReferencesTabPropsType> {
  public gridComponent: GridComponent = null;
  public toolbarOptions: ItemModel[] = [];
  public pageSettings: PageSettingsModel = {
    pageSize: pagerUtils.getMaxPageSize(),
    pageCount: pagerUtils.getPageCount(),
    pageSizes: pagerUtils.getPageSizes()
  };
  public editSettings: EditSettingsModel = { allowEditing: true, allowEditOnDblClick: false, allowAdding: true, newRowPosition: 'Top' };

  private readonly ADD_REFERENCE_ACTION_ID = 'Add';
  private readonly EDIT_REFERENCE_ACTION_ID = 'Edit';
  private readonly SAVE_REFERENCE_ACTION_ID = 'Save';
  private readonly CANCEL_REFERENCE_ACTION_ID = 'Cancel';

  public constructor(props: ReferencesTabPropsType) {
    super(props);

    const { i18n } = this.props;

    this.toolbarOptions = [
      {
        tooltipText: i18n.t('syncfusion.grid.Add'),
        id: this.ADD_REFERENCE_ACTION_ID,
        template: `
          <button type="button" id="addButtonGrid" class="e-tbar-btn e-tbtn-txt e-control e-btn e-lib">
            <span class="icon icon-reference"></span>
            <span class="ml-1 icon icon-app-item-add"></span>
          </button>
        `
      },
      {
        tooltipText: i18n.t('syncfusion.grid.Edit'),
        id: this.EDIT_REFERENCE_ACTION_ID,
        disabled: true,
        template: `
          <button type="button" id="editButtonGrid" class="e-tbar-btn e-tbtn-txt e-control e-btn e-lib">
            <span class="icon icon-reference"></span>
            <span class="ml-1 icon-app-item-edit"></span>
          </button>
        `
      },
      {
        tooltipText: i18n.t('syncfusion.grid.SaveButton'),
        id: this.SAVE_REFERENCE_ACTION_ID,
        disabled: true,
        template: `
          <button type="button" id="saveButtonGrid" class="e-tbar-btn e-tbtn-txt e-control e-btn e-lib">
            <span class="icon icon-reference"></span>
            <span class="ml-1 icon-app-item-save"></span>
          </button>
        `
      },
      {
        tooltipText: i18n.t('syncfusion.grid.Cancel'),
        id: this.CANCEL_REFERENCE_ACTION_ID,
        disabled: true,
        template: `
          <button type="button" id="cancelButtonGrid" class="e-tbar-btn e-tbtn-txt e-control e-btn e-lib">
            <span class="icon icon-reference"></span>
            <span class="ml-1 icon icon-app-item-close"></span>
          </button>
        `
      }
    ];
  }

  public onRowSelected(): void {
    this.toggleToolBarButtons(['editButtonGrid'], true);
  }

  public onRowDeselecting(): void {
    this.toggleToolBarButtons(['editButtonGrid'], false);
  }

  public onToolbarClick(args: ClickEventArgs): void {
    if (args.item.id === this.ADD_REFERENCE_ACTION_ID) {
      this.gridComponent.addRecord();
      this.disableAddEditButtons();
    } else if (args.item.id === this.EDIT_REFERENCE_ACTION_ID) {
      if (this.gridComponent.selectedRowIndex !== -1) {
        this.gridComponent.startEdit();
        this.disableAddEditButtons();
      }
    } else if (args.item.id === this.SAVE_REFERENCE_ACTION_ID) {
      if (this.gridComponent.editModule.editFormValidate()) {
        this.gridComponent.endEdit();
        this.disableSaveCancelButtons();
      }
    } else if (args.item.id === this.CANCEL_REFERENCE_ACTION_ID) {
      this.gridComponent.closeEdit();
      this.disableSaveCancelButtons();
    }
  }

  private disableAddEditButtons(): void {
    const buttonsToEnable = ['saveButtonGrid', 'cancelButtonGrid'];
    const buttonsToDisable = ['addButtonGrid', 'editButtonGrid'];

    this.toggleToolBarButtons(buttonsToEnable, true);
    this.toggleToolBarButtons(buttonsToDisable, false);
  }

  private disableSaveCancelButtons(): void {
    const buttonsToEnable = ['addButtonGrid'];
    const buttonsToDisable = ['saveButtonGrid', 'cancelButtonGrid'];
    if (this.gridComponent.selectedRowIndex !== -1) {
      buttonsToEnable.push('editButtonGrid');
    } else {
      buttonsToDisable.push('editButtonGrid');
    }

    this.toggleToolBarButtons(buttonsToEnable, true);
    this.toggleToolBarButtons(buttonsToDisable, false);
  }

  public componentDidUpdate(prevProps: IReferencesTabProps) {
    gridUtils.manageSpinner(this.gridComponent, prevProps.isFetching, this.props.isFetching);
    gridUtils.manageDataSource(this.gridComponent, () => this.updateGridComponentDataSource(), prevProps.isFetching, this.props.isFetching);
  }

  public updateGridComponentDataSource() {
    this.gridComponent.dataSource = new DataManager(JSON.parse(JSON.stringify(this.props.references))); // Clone by value
  }

  public onActionComplete(args: ActionEventArgs) {
    const { onReferenceSave } = this.props;

    if (args.requestType === 'save') {
      const data = args.data as any;
      const reference: IReference = { ...data };
      setTimeout(() => onReferenceSave(reference));
    }
  }

  public getActionsColumnTemplate(reference: IReference): JSX.Element {
    const { history, i18n } = this.props;

    return reference.sourceType === SourceType.REPORT ? (
      <div className='btn-group'>
        <button
          type='button'
          className='btn btn-sm btn-light'
          title={i18n.t('referencesTab.actions.viewDetail')}
          onClick={() => history.push(`/report/${reference.sourceId}/reference/${reference.id}`)}
        >
          <span className='icon icon-info' />
        </button>
      </div>
    ) : null;
  }

  private toggleToolBarButtons(buttonIds: string[], isEnabled: boolean): void {
    this.gridComponent.toolbarModule.enableItems(buttonIds, isEnabled);
  }

  public render() {
    const { onReferenceSave, i18n } = this.props;

    return (
      <div className='references-grid'>
        <GridComponent
          toolbar={onReferenceSave ? this.toolbarOptions : undefined}
          allowSorting={true}
          allowPaging={true}
          allowResizing={true}
          pageSettings={this.pageSettings}
          editSettings={this.editSettings}
          locale={i18n.language}
          ref={(grid: GridComponent): GridComponent => this.gridComponent = grid}
          created={() => this.updateGridComponentDataSource()}
          actionComplete={(args) => this.onActionComplete(args as ActionEventArgs)}
          toolbarClick={(args) => this.onToolbarClick(args)}
          rowSelected={() => this.onRowSelected()}
          rowDeselecting={() => this.onRowDeselecting()}
        >
          <ColumnsDirective>
            <ColumnDirective field='name' headerText={i18n.t('reference.name')} width='150' type='string' validationRules={{ required: true }} disableHtmlEncode={false}/>
            <ColumnDirective field='description' headerText={i18n.t('reference.description')} width='150' type='string' disableHtmlEncode={false} />
            <ColumnDirective
              width='50'
              allowEditing={false}
              template={(reference: IReference) => this.getActionsColumnTemplate(reference)}
            />
          </ColumnsDirective>
          <Inject services={[Sort, Page, Toolbar, Resize, Edit]} />
        </GridComponent>
      </div>
    );
  }
}

export default withRouter(withTranslation()(ReferencesTab));
