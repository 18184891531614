import { URL } from '../common/constants';
import { IMeasurementUnit } from '../common/model/measurementUnit.model';
import * as services from '.';

export interface IMeasurementUnitsService {
  create(MeasurementUnit: IMeasurementUnit): Promise<IMeasurementUnit>;
  update(MeasurementUnit: IMeasurementUnit): Promise<IMeasurementUnit>;
}

class MeasurementUnitsService implements IMeasurementUnitsService {
  public constructor(private httpClient: services.IHttpClient) {

  }

  public create(measurementUnit: IMeasurementUnit): Promise<IMeasurementUnit> {
    return this.httpClient.postCustom(`${URL.WEB.BASE}${URL.WEB.MEASUREMENT_UNITS}`, measurementUnit);
  }

  public update(measurementUnit: IMeasurementUnit): Promise<IMeasurementUnit> {
    return this.httpClient.putCustom(`${URL.WEB.BASE}${URL.WEB.MEASUREMENT_UNITS}/${measurementUnit.id}`, measurementUnit);
  }
}

export const measurementUnitsService = new MeasurementUnitsService(services.httpClient);
