import { ODataV4Adaptor, Query, RemoteOptions, DataResult, DataOptions, CrudOptions, DataManager } from '@syncfusion/ej2-data';
import { TYPE_EXPAND, TYPE_SELECT } from '../common/constants';
import { Ajax } from '@syncfusion/ej2-base';

export class CustomDetailAdaptor extends ODataV4Adaptor {
  public constructor(props?: RemoteOptions) {
    super(props);
  }

  public beforeSend(dm: DataManager): void {
    dm.dataSource.url = `${dm.dataSource.url}&${TYPE_EXPAND}=references&${TYPE_SELECT}=references`;
  }

  public processResponse(data: DataResult, ds?: DataOptions, query?: Query, xhr?: XMLHttpRequest, request?: Ajax, changes?: CrudOptions): any {
    const result = super.processResponse(data, ds, query, xhr, request, changes) as any;

    if (!Array.isArray(result)) {
      return { result: result.result[0].references };
    }

    return result;
  }
}
