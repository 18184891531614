import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
  GridComponent,
  ToolbarItems,
  PageSettingsModel,
  FilterSettingsModel,
  EditSettingsModel,
  ActionEventArgs,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Sort,
  Page,
  Toolbar,
  Resize,
  Filter,
  Edit
} from '@syncfusion/ej2-react-grids';
import { DataManager } from '@syncfusion/ej2-data';
import { ItemModel, ClickEventArgs } from '@syncfusion/ej2-navigations';

import { IChainsGridProps } from '../../../interfaces/props/IChainsListProps';
import { IChain } from '../../../common/model/chain.model';
import * as gridUtils from '../../../utils/grid.utils';
import * as pagerUtils from '../../../utils/pager.utils';
import './chainsGrid.scss';

type ChainsGridPropsType = IChainsGridProps & WithTranslation;

class ChainsGrid extends Component<ChainsGridPropsType> {
  public readonly ACTIVE_DEFAULT_VALUE = true;

  public gridComponent: GridComponent = null;
  public toolbarOptions: Array<ToolbarItems | ItemModel> = [];
  public pageSettings: PageSettingsModel = {
    pageSize: pagerUtils.getMaxPageSize(),
    pageCount: pagerUtils.getPageCount(),
    pageSizes: pagerUtils.getPageSizes()
  };
  public editSettings: EditSettingsModel = { allowEditing: true, allowEditOnDblClick: false, allowAdding: true, newRowPosition: 'Top' };
  public filterSettings: FilterSettingsModel = { type: 'Menu' };

  private readonly EDIT_SERIES_ACTION_ID = 'editSeries';

  public constructor(props: ChainsGridPropsType) {
    super(props);

    const { i18n } = this.props;

    this.toolbarOptions = [
      'Add',
      'Edit',
      'Update',
      'Cancel',
      { tooltipText: i18n.t('chainsList.actions.editSeries'), id: this.EDIT_SERIES_ACTION_ID, cssClass: 'e-overlay', prefixIcon: 'icon icon-series' }
    ];
  }

  public componentDidUpdate(prevProps: ChainsGridPropsType) {
    gridUtils.manageSpinner(this.gridComponent, prevProps.isFetching, this.props.isFetching);
    gridUtils.manageDataSource(this.gridComponent, () => this.updateGridComponentDataSource(), prevProps.isFetching, this.props.isFetching);
  }

  public updateGridComponentDataSource() {
    this.gridComponent.dataSource = new DataManager(JSON.parse(JSON.stringify(this.props.chains))); // Clone by value
  }

  public onRowSelectedOrDeselected() {
    this.gridComponent.toolbarModule.enableItems([this.EDIT_SERIES_ACTION_ID], this.gridComponent.getSelectedRows().length === 1);
  }

  public onActionBegin(args: ActionEventArgs) {
    if (args.requestType === 'add') {
      (args.data as any).series = [];
    }
  }

  public onActionComplete(args: ActionEventArgs) {
    const { onChainSave } = this.props;

    if (args.requestType === 'save') {
      const data = args.data as any;
      const chain: IChain = {
        ...data,
        id: data.id || null,
        code: data.code || data.name.split(' ').join('-')
      }; // series initialized in onActionBegin
      setTimeout(() => onChainSave(chain));
    }
  }

  public onToolbarClick(args: ClickEventArgs) {
    if (args.item.id === this.EDIT_SERIES_ACTION_ID && this.gridComponent.getSelectedRowIndexes().length === 1) {
      this.props.onEditChainSeriesButtonClick({ ...this.gridComponent.getSelectedRecords()[0] } as IChain); // It's necessary to pass a copy of the object
    }
  }

  public getSeriesColumnTemplate(chain: IChain): JSX.Element {
    return (
      <button type='button' className='btn btn-sm btn-light' title={chain.series.map((series) => series.name).join(', ')}>
        <span className='icon icon-series' />
      </button>
    );
  }

  public render() {
    const { i18n } = this.props;

    return (
      <div className='chains-grid'>
        <GridComponent
          toolbar={this.toolbarOptions}
          allowSorting={true}
          allowPaging={true}
          allowResizing={true}
          allowFiltering={true}
          sortSettings={{ columns: [{ field: 'name' }] }}
          pageSettings={this.pageSettings}
          filterSettings={this.filterSettings}
          editSettings={this.editSettings}
          locale={i18n.language}
          ref={(grid) => this.gridComponent = grid}
          created={() => this.updateGridComponentDataSource()}
          rowSelected={() => this.onRowSelectedOrDeselected()}
          rowDeselected={() => this.onRowSelectedOrDeselected()}
          actionBegin={(args) => this.onActionBegin(args as ActionEventArgs)}
          actionComplete={(args) => this.onActionComplete(args as ActionEventArgs)}
          toolbarClick={(args) => this.onToolbarClick(args)}
        >
          <ColumnsDirective>
            <ColumnDirective field='name' headerText={i18n.t('chain.name')} width='200' type='string' validationRules={{ required: true }} />
            <ColumnDirective
              headerText={this.props.i18n.t('chain.series')}
              width='100'
              allowEditing={false}
              template={(chain: IChain) => this.getSeriesColumnTemplate(chain)}
            />
            <ColumnDirective field='contactEmail' headerText={i18n.t('chain.contactEmail')} width='200' type='string' validationRules={{ required: true, email: true }} />
            <ColumnDirective
              field='active'
              headerText={i18n.t('chain.active')}
              width='100'
              type='boolean'
              defaultValue={this.ACTIVE_DEFAULT_VALUE + ''}
              editType='booleanedit'
              displayAsCheckBox={true}
            />
          </ColumnsDirective>
          <Inject services={[Sort, Page, Toolbar, Resize, Filter, Edit]} />
        </GridComponent>
      </div>
    );
  }
}

export default withTranslation()(ChainsGrid);
