export const getIconCss = (unecoCategoryName: string): string => {
  switch (unecoCategoryName) {
    case 'TODOS':
      return 'icon icon-uneco-all';
    case 'TEXTIL':
      return 'icon icon-uneco-textil';
    case 'CALZADO':
      return 'icon icon-uneco-shoes';
    case 'COMPLEMENTO':
      return 'icon icon-uneco-complement';
    default:
      return '';
  }
};
