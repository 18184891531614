import React, { Component, ComponentType } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { compose } from 'redux';
import queryString from 'query-string';
import { NotificationsSidebarComponent, notificationsSidebarService } from '@aitex/tsx-extranet-notifications-sidebar';

import { RootState } from '../../store';
import { INotificationsSidebarContainerStateProps, INotificationsSidebarContainerDispatchProps } from '../../interfaces/props/INotificationsSidebarProps';
import * as notificationsSidebarStore from '../../store/modules/notificationsSidebar.store';
import { ANNEX_LINK_ID_PREFIX, CERTIFICATES_LIST_LINK_ID_PREFIX } from '../../common/constants/notificationLink.constants';
import { UserType } from '../../common/model/enumerations/userType.model';
import { ReportDetailTab } from '../../common/model/enumerations/reportDetailTab.model';
import { notificationsService, annexesService } from '../../services';
import * as dateHelpers from '../../helpers/date.helpers';

type NotificationsSidebarContainerPropsType = PropsFromRedux & RouteComponentProps & WithTranslation;

class NotificationsSidebarContainer extends Component<NotificationsSidebarContainerPropsType> {
  private readonly FETCH_INTERVAL_MS = 180000;

  private fetchInterval: NodeJS.Timeout = null;

  public componentDidMount() {
    this.fetchNotifications();

    const parsedUrl = queryString.parse(this.props.location.search);
    if (parsedUrl.notificationsSidebar) {
      setTimeout(() => notificationsSidebarService.showSidebar(), 1000);
    }

    this.fetchInterval = setInterval(() => this.fetchNotifications(), this.FETCH_INTERVAL_MS);
  }

  public componentWillUnmount() {
    if (this.fetchInterval) {
      clearInterval(this.fetchInterval);
    }
  }

  public onViewAllChange(viewAll: boolean) {
    this.props.setViewAll(viewAll);
    this.fetchNotifications();
  }

  public onNotificationExpanded(id: string, seen: boolean) {
    const { isInSimulationMode, markNotificationAsSeen } = this.props;

    if (!isInSimulationMode && !seen) {
      markNotificationAsSeen(notificationsService, id);
    }
  }

  public onReportClick(reportId: string, isAnnexLink: boolean) {
    this.props.history.push(`/report/${reportId}/view${isAnnexLink ? `?tab=${ReportDetailTab.ANNEXES}` : ''}`);
  }

  public onRequestClick(requestId: string, isAnnexLink: boolean) {
    this.props.history.push(`/request/${requestId}/view${isAnnexLink ? `?tab=${ReportDetailTab.ANNEXES}` : ''}`);
  }

  public async onAnnexLinkClick(anchorElement: HTMLAnchorElement) {
    try {
      const id = anchorElement.id.substring(ANNEX_LINK_ID_PREFIX.length);
      const description: string = anchorElement.text;
      await annexesService.downloadFile(id, description);
    } catch (error) {
      this.manageLinkClick(anchorElement);
    }
  }

  public manageLinkClick(anchorElement: HTMLAnchorElement) {
    const { webUrl, history } = this.props;

    if (anchorElement.href.startsWith(webUrl)) {
      history.push(anchorElement.href.substring(webUrl.length - (webUrl.endsWith('/') ? 1 : 0)));
    } else {
      window.location.href = anchorElement.href;
    }
  }

  public render() {
    const { notifications, viewAll } = this.props;

    return (
      <NotificationsSidebarComponent
        notifications={notifications.map((n) => ({ ...n, createdDate: dateHelpers.toShortDate(n.createdDate, true) }))}
        viewAll={viewAll}
        expandIconCss='icon icon-cheveron-left'
        collapseIconCss='icon icon-cheveron-right'
        settingsIconCss='icon icon-tuning'
        closeIconCss='icon icon-app-item-close'
        annexLinkIdPrefix={ANNEX_LINK_ID_PREFIX}
        certificatesListLinkIdPrefix={CERTIFICATES_LIST_LINK_ID_PREFIX}
        onViewAllChange={(va) => this.onViewAllChange(va)}
        onNotificationExpanded={(notification) => this.onNotificationExpanded(notification.id, notification.seen)}
        onReportClick={(reportId, isAnnexLink) => this.onReportClick(reportId, isAnnexLink)}
        onRequestClick={(requestId, isAnnexLink) => this.onRequestClick(requestId, isAnnexLink)}
        onAnnexLinkClick={(anchorElement) => this.onAnnexLinkClick(anchorElement)}
        onCertificatesListLinkClick={(anchorElement) => this.manageLinkClick(anchorElement)}
      />
    );
  }

  private fetchNotifications() {
    const { isCurrentUserInternal, fetchNotifications } = this.props;

    if (!isCurrentUserInternal) {
      fetchNotifications(notificationsService);
    }
  }
}

const mapStateToProps = (state: RootState): INotificationsSidebarContainerStateProps => ({
  notifications: state.notificationsSidebarStore.notifications,
  viewAll: state.notificationsSidebarStore.viewAll,
  isCurrentUserInternal: state.currentUserStore.user.type === UserType.INTERNAL,
  isInSimulationMode: state.currentUserStore.jwtInfo.isInSimulationMode === 'true',
  webUrl: state.settingsStore.settings.web
});

const mapDispatchToProps: INotificationsSidebarContainerDispatchProps = {
  setViewAll: notificationsSidebarStore.setViewAll,
  fetchNotifications: notificationsSidebarStore.fetchNotifications,
  markNotificationAsSeen: notificationsSidebarStore.markNotificationAsSeen
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default compose(
  withRouter,
  withTranslation(),
  connector
)(NotificationsSidebarContainer) as ComponentType;
