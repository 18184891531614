import React, { Component } from 'react';
import { Route } from 'react-router';
import { connect, ConnectedProps } from 'react-redux';

import { RootState } from '../../store';
import { ISsoRedirectRouteProps, ISsoRedirectRouteStateProps } from '../../interfaces/props/ISsoRedirectRouteProps';

type SsoRedirectRoutePropsType = ISsoRedirectRouteProps & PropsFromRedux;

class SsoRedirectRoute extends Component<SsoRedirectRoutePropsType> {
  public componentDidMount() {
    const { identityUrl } = this.props;

    window.location.replace(`${identityUrl}${window.location.href.substring(window.location.href.indexOf('/sso-redirect/') + 14)}`);
  }

  public render() {
    return <Route path={this.props.path} exact={true} />
  }
}

const mapStateToProps = (state: RootState): ISsoRedirectRouteStateProps => ({
  identityUrl: state.settingsStore.settings.identity
});

const connector = connect(mapStateToProps, {});

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SsoRedirectRoute);
