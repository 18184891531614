import React from 'react';
import { Form, Col } from 'react-bootstrap';
import { ReportStatusSelectProps } from './iReportStatusSelectProps';
import { ISeasons } from '../../../../interfaces/states/IReportStatusState';

const FormSelect = ({ controlId, label, required, seasons, onChange }: ReportStatusSelectProps): JSX.Element => {
  return (
    <Form.Group as={Col} controlId={controlId}>
      <Form.Label>{label}</Form.Label>
      <Form.Control as='select' required={required} onChange={onChange}  >
        {seasons && seasons.map((season: ISeasons) => {
          return (
            <option key={season.id}>{season.code}</option>
          );
        })}
      </Form.Control>
    </Form.Group>
  );
};

export default FormSelect;
