import { URL } from '../common/constants';
import { IChain } from '../common/model/chain.model';
import * as services from '.';

export interface IChainsService {
  create(chain: IChain): Promise<IChain>;
  update(chain: IChain): Promise<IChain>;
}

class ChainsService implements IChainsService {
  public constructor(private httpClient: services.IHttpClient) {

  }

  public create(chain: IChain): Promise<IChain> {
    return this.httpClient.postCustom(`${URL.WEB.BASE}${URL.WEB.CHAINS}`, chain);
  }

  public update(chain: IChain): Promise<IChain> {
    return this.httpClient.putCustom(`${URL.WEB.BASE}${URL.WEB.CHAINS}/${chain.id}`, chain);
  }
}

export const chainsService = new ChainsService(services.httpClient);
