import { Action, Reducer, ActionCreator, combineReducers } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { batch } from 'react-redux';
import { DataManager, Query } from '@syncfusion/ej2-data';
import moment from 'moment';

import { RootState } from '..';
import * as listGridStore from './listGrid.store';
import { IOfficesListState, IOfficesListFilterState } from '../../interfaces/states/IOfficesListState';
import { IOffice } from '../../common/model/office.model';
import { IODataService } from '../../services';

export enum Type {
  RESET = '@@officesList/RESET',
  RESET_HARD = '@@officesList/RESET_HARD',
  SET_OFFICES = '@@officesList/SET_OFFICES',
  SET_MODE = '@@officesList/SET_MODE',
  SET_IS_CHANGING_MODE = '@@officesList/SET_IS_CHANGING_MODE',
  SET_FILTER = '@@officesList/SET_FILTER',
  SET_FILTER_ACTIVE = '@@officesList/SET_FILTER_ACTIVE',
  SET_FILTER_CODE = '@@officesList/SET_FILTER_CODE',
  SET_FILTER_DESCRIPTION = '@@officesList/SET_FILTER_DESCRIPTION',
  SET_FILTER_EMAILS = '@@officesList/SET_FILTER_EMAILS',
  SET_IS_FETCHING = '@@officesList/SET_IS_FETCHING',
  SET_SEARCH_DATE = '@@officesList/SET_SEARCH_DATE',
  SET_CURRENT_PAGE = '@@officesList/SET_CURRENT_PAGE',
  SET_PAGE_SIZE = '@@officesList/SET_PAGE_SIZE',
  SET_TOTAL_COUNT = '@@officesList/SET_TOTAL_COUNT',
  SET_SORTING = '@@officesList/SET_SORTING',
  SET_OFFICE = '@@officesDetail/SET_OFFICE'
}

const initialState: IOfficesListState = {
  offices: [],
  mode: null,
  isChangingMode: true,
  filter: {
    active: true,
    code: null,
    description: null,
    emails: null
  },
  grid: listGridStore.initialState
};


/////////////
// Actions //
/////////////

export type Actions = ResetAction | ResetHardAction | SetOfficesAction | SetFilterAction | SetFilterActiveAction | SetFilterCodeAction
  | SetFilterDescriptionAction | SetFilterEmailsAction | listGridStore.SetIsFetchingAction
  | listGridStore.SetSearchDateAction | listGridStore.SetCurrentPageAction | listGridStore.SetPageSizeAction | listGridStore.SetTotalCountAction | listGridStore.SetSortingAction
  | SetOfficeAction;

export type ResetAction = Action<Type.RESET>

export type ResetHardAction = Action<Type.RESET_HARD>

export interface SetOfficesAction extends Action<Type.SET_OFFICES> {
  offices: IOffice[];
}

export interface SetFilterAction extends Action<Type.SET_FILTER> {
  filter: IOfficesListFilterState;
}

export interface SetFilterActiveAction extends Action<Type.SET_FILTER_ACTIVE> {
  active?: boolean;
}

export interface SetFilterCodeAction extends Action<Type.SET_FILTER_CODE> {
  code: string;
}

export interface SetFilterDescriptionAction extends Action<Type.SET_FILTER_DESCRIPTION> {
  description: string;
}

export interface SetFilterEmailsAction extends Action<Type.SET_FILTER_EMAILS> {
  emails: string;
}

export interface SetOfficeAction extends Action<Type.SET_OFFICE> {
  office: IOffice;
}

export const reset: ActionCreator<ResetAction> = () => ({
  type: Type.RESET
});

export const resetHard: ActionCreator<ResetHardAction> = () => ({
  type: Type.RESET_HARD
});

export const setOffices: ActionCreator<SetOfficesAction> = (officesLocal: IOffice[]) => ({
  type: Type.SET_OFFICES,
  offices: officesLocal
});

export const setFilter: ActionCreator<SetFilterAction> = (filterLocal: IOfficesListFilterState) => ({
  type: Type.SET_FILTER,
  filter: filterLocal
});

export const setFilterActive: ActionCreator<SetFilterActiveAction> = (active?: boolean) => ({
  type: Type.SET_FILTER_ACTIVE,
  active
});

export const setFilterCode: ActionCreator<SetFilterCodeAction> = (code: string) => ({
  type: Type.SET_FILTER_CODE,
  code
});

export const setFilterDescription: ActionCreator<SetFilterDescriptionAction> = (description: string) => ({
  type: Type.SET_FILTER_DESCRIPTION,
  description
});

export const setFilterEmails: ActionCreator<SetFilterEmailsAction> = (emails: string) => ({
  type: Type.SET_FILTER_EMAILS,
  emails
});

export const fetchOffices: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (
  oDataService: IODataService,
  officesDataManager: DataManager,
  officesQuery: Query
) => (
    async (dispatch, getState) => {
      dispatch(listGridStore.setIsFetching(Type.SET_IS_FETCHING, true));

      const currentPageLocal = getState().officesListStore.grid.currentPage;
      const pageSizeLocal = getState().officesListStore.grid.pageSize;
      const sortFieldLocal = getState().officesListStore.grid.sortField;
      const sortDirectionLocal = getState().officesListStore.grid.sortDirection;

      let query = officesQuery.page(currentPageLocal, pageSizeLocal);
      if (sortFieldLocal && sortDirectionLocal) {
        query = sortDirectionLocal === 'Ascending' ? query.sortBy(sortFieldLocal) : query.sortByDesc(sortFieldLocal);
      }
      const officesResponse = await oDataService.executeQueryWithCount<IOffice>(officesDataManager, query);
      const officesLocal = officesResponse.result.map((office) => office);
      batch(() => {
        dispatch(setOffices(officesLocal));
        dispatch(listGridStore.setSearchDate(Type.SET_SEARCH_DATE, moment()));
        dispatch(listGridStore.setTotalCount(Type.SET_TOTAL_COUNT, officesResponse.count));
      });
      dispatch(listGridStore.setIsFetching(Type.SET_IS_FETCHING, false));
    }
);

export const setOffice: ActionCreator<SetOfficeAction> = (officeLocal: IOffice) => ({
  type: Type.SET_OFFICE,
  office: officeLocal
});

export const saveOffice: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (
  office: IOffice
) => (
  async (dispatch) => {
    dispatch(listGridStore.setIsFetching(Type.SET_IS_FETCHING, true));

    dispatch(setOffice(office));

    dispatch(listGridStore.setIsFetching(Type.SET_IS_FETCHING, false));
  }
);

//////////////
// Reducers //
//////////////

const offices: Reducer<IOffice[]> = (state = initialState.offices, action) => {
  switch (action.type) {
    case Type.RESET:
      return state;
    case Type.SET_MODE:
      return initialState.offices;
    case Type.SET_OFFICES:
      return (action as SetOfficesAction).offices;
    case Type.SET_OFFICE: {
      const office = (action as SetOfficeAction).office;
      let offices = state.filter(value => (value as any).office === undefined || (value as any).office == null);
      if (offices.some(u => u.id === office.id)) {
        const newOffices = offices.map((item: IOffice) => {
          if (item.id == office.id) {
            return office;
          } else {
            return item;
          }
        });

        offices = newOffices;
      } else {
        offices.unshift(office);
      }

      return offices;
    }
    default:
      return state;
  }
};

const mode: Reducer<string> = (state = initialState.mode, action) => {
  switch (action.type) {
    case Type.RESET:
      return state;
    case Type.RESET_HARD:
      return initialState.mode;
    default:
      return state;
  }
};

const isChangingMode: Reducer<boolean> = (state = initialState.isChangingMode, action) => {
  switch (action.type) {
    case Type.RESET:
    case Type.RESET_HARD:
      return initialState.isChangingMode;
    case Type.SET_MODE:
      return false;
    default:
      return state;
  }
}

const filter: Reducer<IOfficesListFilterState> = (state = initialState.filter, action) => {
  switch (action.type) {
    case Type.RESET:
      return state;
    case Type.SET_FILTER:
      return (action as SetFilterAction).filter;
    case Type.SET_FILTER_ACTIVE:
      return { ...state, active: (action as SetFilterActiveAction).active };
    case Type.SET_FILTER_CODE:
      return { ...state, code: (action as SetFilterCodeAction).code };
    case Type.SET_FILTER_DESCRIPTION:
      return { ...state, description: (action as SetFilterDescriptionAction).description };
    case Type.SET_FILTER_EMAILS:
      return { ...state, emails: (action as SetFilterEmailsAction).emails };
    default:
      return state;
  }
};

export const officesListStore = combineReducers({
  offices,
  mode,
  isChangingMode,
  filter,
  grid: listGridStore.createListGridStore(Type, 'code', 'Ascending')
});
