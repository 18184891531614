import { Action, Reducer, ActionCreator, combineReducers } from 'redux';

import * as listGridStore from './listGrid.store';
import { INotificationsListState, INotificationsListFilterState } from '../../interfaces/states/INotificationsListState';
import { NotificationScope } from '../../common/model/enumerations/notificationScope.model';
import { BooleanFilter } from '../../common/model/enumerations/booleanFilter.model';

export enum NotificationsListType {
  RESET = '@@notificationsList/RESET',
  SET_FILTER = '@@notificationsList/SET_FILTER',
  SET_FILTER_SUBJECT = '@@notificationsList/SET_FILTER_SUBJECT',
  SET_FILTER_SCOPE = '@@notificationsList/SET_FILTER_SCOPE',
  SET_FILTER_ACTIVE = '@@notificationsList/SET_FILTER_ACTIVE',
  SET_FILTER_COMPANIES_IDS = '@@notificationsList/SET_FILTER_COMPANIES_IDS',
  SET_FILTER_EMAIL = '@@notificationsList/SET_FILTER_EMAIL',
  SET_IS_FETCHING = '@@notificationsList/SET_IS_FETCHING',
  SET_SEARCH_DATE = '@@notificationsList/SET_SEARCH_DATE',
  SET_CURRENT_PAGE = '@@notificationsList/SET_CURRENT_PAGE',
  SET_PAGE_SIZE = '@@notificationsList/SET_PAGE_SIZE',
  SET_TOTAL_COUNT = '@@notificationsList/SET_TOTAL_COUNT',
  SET_SORTING = '@@notificationsList/SET_SORTING'
}

export enum NotificationsViewStatusListType {
  RESET = '@@notificationsViewStatusList/RESET',
  SET_FILTER = '@@notificationsViewStatusList/SET_FILTER',
  SET_FILTER_SUBJECT = '@@notificationsViewStatusList/SET_FILTER_SUBJECT',
  SET_FILTER_SCOPE = '@@notificationsViewStatusList/SET_FILTER_SCOPE',
  SET_FILTER_ACTIVE = '@@notificationsViewStatusList/SET_FILTER_ACTIVE',
  SET_FILTER_COMPANIES_IDS = '@@notificationsViewStatusList/SET_FILTER_COMPANIES_IDS',
  SET_FILTER_EMAIL = '@@notificationsViewStatusList/SET_FILTER_EMAIL',
  SET_IS_FETCHING = '@@notificationsViewStatusList/SET_IS_FETCHING',
  SET_SEARCH_DATE = '@@notificationsViewStatusList/SET_SEARCH_DATE',
  SET_CURRENT_PAGE = '@@notificationsViewStatusList/SET_CURRENT_PAGE',
  SET_PAGE_SIZE = '@@notificationsViewStatusList/SET_PAGE_SIZE',
  SET_TOTAL_COUNT = '@@notificationsViewStatusList/SET_TOTAL_COUNT',
  SET_SORTING = '@@notificationsViewStatusList/SET_SORTING'
}

const initialState: INotificationsListState = {
  filter: {
    subject: null,
    scope: NotificationScope.ALL,
    active: BooleanFilter.YES,
    companiesIds: [],
    email: BooleanFilter.ALL
  },
  grid: listGridStore.initialState
};

// Actions

export type ResetAction = Action<string>;

export interface SetFilterAction extends Action<string> {
  filter: INotificationsListFilterState;
}

export interface SetFilterSubjectAction extends Action<string> {
  subject: string;
}

export interface SetFilterScopeAction extends Action<string> {
  scope: NotificationScope;
}

export interface SetFilterActiveAction extends Action<string> {
  active: BooleanFilter;
}

export interface SetFilterCompaniesIdsAction extends Action<string> {
  companiesIds: string[];
}

export interface SetFilterEmailAction extends Action<string> {
  email: BooleanFilter;
}

export const reset: ActionCreator<ResetAction> = (type: string) => ({
  type
});

export const setFilter: ActionCreator<SetFilterAction> = (type: string, filterLocal: INotificationsListFilterState) => ({
  type,
  filter: filterLocal
});

export const setFilterSubject: ActionCreator<SetFilterSubjectAction> = (type: string, subject: string) => ({
  type,
  subject
});

export const setFilterScope: ActionCreator<SetFilterScopeAction> = (type: string, scope: NotificationScope) => ({
  type,
  scope
});

export const setFilterActive: ActionCreator<SetFilterActiveAction> = (type: string, active: BooleanFilter) => ({
  type,
  active
});

export const setFilterCompaniesIds: ActionCreator<SetFilterCompaniesIdsAction> = (type: string, companiesIds: string[]) => ({
  type,
  companiesIds
});

export const setFilterEmail: ActionCreator<SetFilterEmailAction> = (type: string, email: BooleanFilter) => ({
  type,
  email
});

// Reducers

const createFilterReducer = (type: Record<string, string>): Reducer<INotificationsListFilterState> => {
  return (state = initialState.filter, action) => {
    switch (action.type) {
      case type.RESET:
        return state;
      case type.SET_FILTER:
        return (action as SetFilterAction).filter;
      case type.SET_FILTER_SUBJECT:
        return { ...state, subject: (action as SetFilterSubjectAction).subject };
      case type.SET_FILTER_SCOPE:
        return { ...state, scope: (action as SetFilterScopeAction).scope };
      case type.SET_FILTER_ACTIVE:
        return { ...state, active: (action as SetFilterActiveAction).active };
      case type.SET_FILTER_COMPANIES_IDS:
        return { ...state, companiesIds: (action as SetFilterCompaniesIdsAction).companiesIds };
      case type.SET_FILTER_EMAIL:
        return { ...state, email: (action as SetFilterEmailAction).email };
      default:
        return state;
    }
  };
};

export const notificationsListStore = combineReducers({
  filter: createFilterReducer(NotificationsListType),
  grid: listGridStore.createListGridStore(NotificationsListType, 'createdDate', 'Descending')
});
export const notificationsViewStatusListStore = combineReducers({
  filter: createFilterReducer(NotificationsViewStatusListType),
  grid: listGridStore.createListGridStore(NotificationsViewStatusListType, 'createdDate', 'Descending')
});
