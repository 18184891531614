import React, { Component, createRef } from 'react';
import { settings, captchaService } from '../../services';
import i18n from '../../i18n';
import { CaptchaProps } from './iCaptcha';
import './captcha.scss';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const captcha = require('reactjs-captcha');

class Captcha extends Component<CaptchaProps> {
  public captcha = createRef() as any;

  public constructor(props: CaptchaProps) {
    super(props);

    captcha.captchaSettings.set({
      captchaEndpoint: `${settings.web}simple-captcha-endpoint.ashx`
    });
  }

  public async validateCaptcha(): Promise<boolean> {
    const postData = {
      userEnteredCaptchaCode: this.captcha.getUserEnteredCaptchaCode(),
      captchaId: this.captcha.getCaptchaId()
    };

    const validatedCaptcha = await captchaService.validateCaptcha(postData);

    if (validatedCaptcha.success === false) {
      this.refreshCaptcha();
      return false;
    }

    return true;
  }

  public refreshCaptcha(): void {
    this.captcha.reloadImage();
  }

  public render(): JSX.Element {
    return (
      <div className='col'>
        <captcha.Captcha
          captchaStyleName='yourFirstCaptchaStyle'
          ref={(c: any) => { this.captcha = c; }}
        />
        {this.props.validatedCaptcha === false && <div className='captcha-error'>{i18n.t('login.captcha.error')}</div>}
        <input className='mt-2 mb-4 col login-form-captcha__input' id='yourFirstCaptchaUserInput' type='text' placeholder={i18n.t('login.captcha.placeholder')} />
      </div>
    );
  }
}

export default Captcha;
