import { Action, Reducer, ActionCreator, combineReducers } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { DataManager, Query } from '@syncfusion/ej2-data';

import { RootState } from '..';
import { IMeasurementUnitsListState } from '../../interfaces/states/IMeasurementUnitsListState';
import { IMeasurementUnit, IMeasurementUnitEnglishSpanish } from '../../common/model/measurementUnit.model';
import { IErrors } from '../../common/model/errors.model';
import { IODataService, IMeasurementUnitsService } from '../../services';
import * as measurementUnitUtils from '../../utils/meausurementUnit.utils';

enum Type {
  RESET = '@@measurementUnitList/RESET',
  SET_MEASUREMENT_UNITS = '@@measurementUnitList/SET_MEASUREMENT_UNITS',
  SET_IS_FETCHING = '@@measurementUnitList/SET_IS_FETCHING',
  SET_ERRORS = '@@measurementUnitList/SET_ERRORS'
}

const initialState: IMeasurementUnitsListState = {
  measurementUnits: [],
  isFetching: false,
  errors: {}
};

// Actions

export type Actions = ResetAction | SetMeasurementUnitsAction | SetIsFetchingAction | SetErrorsAction;

export type ResetAction = Action<Type.RESET>

export interface SetMeasurementUnitsAction extends Action<Type.SET_MEASUREMENT_UNITS> {
  measurementUnits: IMeasurementUnitEnglishSpanish[];
}

export interface SetIsFetchingAction extends Action<Type.SET_IS_FETCHING> {
  isFetching: boolean;
}

export interface SetErrorsAction extends Action<Type.SET_ERRORS> {
  errors: IErrors;
}

export const reset: ActionCreator<ResetAction> = () => ({
  type: Type.RESET
});

export const setMeasurementUnits: ActionCreator<SetMeasurementUnitsAction> = (measurementUnitsLocal: IMeasurementUnitEnglishSpanish[]) => ({
  type: Type.SET_MEASUREMENT_UNITS,
  measurementUnits: measurementUnitsLocal
});

export const setIsFetching: ActionCreator<SetIsFetchingAction> = (isFetchingLocal: boolean) => ({
  type: Type.SET_IS_FETCHING,
  isFetching: isFetchingLocal
});

export const setErrors: ActionCreator<SetErrorsAction> = (errorsLocal: IErrors) => ({
  type: Type.SET_ERRORS,
  errors: errorsLocal
});

export const fetchMeasurementUnits: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (
  oDataService: IODataService,
  measurementUnitsDataManager: DataManager,
  measurementUnitsQuery: Query
) => (
    async (dispatch) => {
      dispatch(setIsFetching(true));

      const measurementUnitsLocal = (await oDataService.executeQuery<IMeasurementUnit>(measurementUnitsDataManager, measurementUnitsQuery))
        .map((measurementUnit) => measurementUnitUtils.toMeasurementUnitEnglishSpanish(measurementUnit));
      dispatch(setMeasurementUnits(measurementUnitsLocal));
      dispatch(setIsFetching(false));
    }
  );

export const saveMeasurementUnit: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (
  measurementUnit: IMeasurementUnitEnglishSpanish,
  measurementUnitsService: IMeasurementUnitsService,
  oDataService: IODataService,
  measurementUnitsDataManager: DataManager,
  measurementUnitsQuery: Query
) => (
    async (dispatch) => {
      try {
        dispatch(setErrors(initialState.errors));

        dispatch(setIsFetching(true));

        const measurementUnitToSave = measurementUnitUtils.fromMeasurementUnitEnsglishSpanish(measurementUnit);
        measurementUnitToSave.id ? await measurementUnitsService.update(measurementUnitToSave) : await measurementUnitsService.create(measurementUnitToSave);
      } catch (error) {
        dispatch(setErrors(error as IErrors));
      } finally {
        const measurementUnitsLocal = (await oDataService.executeQuery<IMeasurementUnit>(measurementUnitsDataManager, measurementUnitsQuery))
          .map((measurementUnitMap) => measurementUnitUtils.toMeasurementUnitEnglishSpanish(measurementUnitMap));
        dispatch(setMeasurementUnits(measurementUnitsLocal));

        dispatch(setIsFetching(false));
      }
    }
  );

// Reducers

const measurementUnits: Reducer<IMeasurementUnitEnglishSpanish[]> = (state = initialState.measurementUnits, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.measurementUnits;
    case Type.SET_MEASUREMENT_UNITS:
      return (action as SetMeasurementUnitsAction).measurementUnits;
    default:
      return state;
  }
};

const isFetching: Reducer<boolean> = (state = initialState.isFetching, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.isFetching;
    case Type.SET_IS_FETCHING:
      return (action as SetIsFetchingAction).isFetching;
    default:
      return state;
  }
};

const errors: Reducer<IErrors> = (state = initialState.errors, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.errors;
    case Type.SET_ERRORS:
      return (action as SetErrorsAction).errors;
    default:
      return state;
  }
};

export const measurementUnitsListStore = combineReducers({ measurementUnits, isFetching, errors });
