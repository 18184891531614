import { IMeasurementUnit, IMeasurementUnitEnglishSpanish } from '../common/model/measurementUnit.model';
import { Language } from '../common/model/enumerations/language.model';

export const toMeasurementUnitEnglishSpanish = (measurementUnit: IMeasurementUnit): IMeasurementUnitEnglishSpanish => {
  const translationEs = measurementUnit.translations.find((translation) => translation.codeIso === Language.SPANISH);
  const translationEn = measurementUnit.translations.find((translation) => translation.codeIso === Language.ENGLISH);
  return {
    id: measurementUnit.id,
    code: measurementUnit.code,
    nameEs: translationEs ? translationEs.name : '',
    descriptionEs: translationEs ? translationEs.description : '',
    nameEn: translationEn ? translationEn.name : '',
    descriptionEn: translationEn ? translationEn.description : '',
    active: measurementUnit.active
  };
};

export const fromMeasurementUnitEnsglishSpanish = (measurementUnitEnglishSpanish: IMeasurementUnitEnglishSpanish): IMeasurementUnit => ({
  id: measurementUnitEnglishSpanish.id,
  code: measurementUnitEnglishSpanish.code,
  name: null,
  translations: [{
    id: null,
    measurementUnitId: measurementUnitEnglishSpanish.id,
    codeIso: Language.SPANISH,
    name: measurementUnitEnglishSpanish.nameEs,
    description: measurementUnitEnglishSpanish.descriptionEs
  }, {
    id: null,
    measurementUnitId: measurementUnitEnglishSpanish.id,
    codeIso: Language.ENGLISH,
    name: measurementUnitEnglishSpanish.nameEn,
    description: measurementUnitEnglishSpanish.descriptionEn
  }],
  active: measurementUnitEnglishSpanish.active
});
