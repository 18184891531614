import React, { Component, ComponentType } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { UnregisterCallback } from 'history';
import { Tabs, Tab } from 'react-bootstrap';
import { GridComponent } from '@syncfusion/ej2-react-grids';
import queryString from 'query-string';
import { dialogsService } from '@aitex/tsx-extranet-dialogs';
import { NotificationsAccordionComponent } from '@aitex/tsx-extranet-notifications-accordion';

import { RootState } from '../../store';
import { IRequestDetailContainerStateProps, IRequestDetailContainerDispatchProps } from '../../interfaces/props/IRequestDetailProps';
import { IRequestDetailContainerRouteParams } from '../../interfaces/routeParams/IRequestDetailRouteParams';
import * as requestDetailStore from '../../store/modules/requestDetail.store';
import * as notificationsSidebarStore from '../../store/modules/notificationsSidebar.store';
import * as dialogsStore from '../../store/modules/dialogs.store';
import { IRequest } from '../../common/model/request.model';
import { RequestStatus } from '../../common/model/enumerations/requestStatus.model';
import { IReference } from '../../common/model/reference.model';
import { IAnnex } from '../../common/model/annex.model';
import { IErrors } from '../../common/model/errors.model';
import { RequestDetailTab } from '../../common/model/enumerations/requestDetailTab.model';
import { SourceType } from '../../common/model/enumerations/sourceType.model';
import { DialogId } from '../../common/model/enumerations/dialogId.model';
import { UserType } from '../../common/model/enumerations/userType.model';
import { requestsService, referencesService, annexesService, notificationsService, notesService, companiesService } from '../../services';
import * as customFieldPermissionUtils from '../../utils/customFieldPermission.utils';
import { notifySuccess, notifyError } from '../../utils/toast.utils';
import * as dateHelpers from '../../helpers/date.helpers';
import InfoCard from './infoCard/infoCard';
import ReportsTab from './tabs/reportsTab/reportsTab';
import ReferencesTab from '../../components/tabs/referencesTab/referencesTab';
import AnnexesTab from '../../components/tabs/annexesTab/annexesTab';
import NotesTab from '../../components/tabs/notesTab/notesTab';
import RequestChangesHistoryTab from './tabs/requestChangesHistoryTab/requestChangesHistoryTab';
import { RequestAcceptanceStatus } from '../../common/model/enumerations/requestAcceptanceStatus.model';
import { ContextualHelpCode } from '../../common/model/enumerations/contextualHelpCode.model';
import './requestDetail.container.scss';

type RequestDetailContainerPropsType = PropsFromRedux & RouteComponentProps<IRequestDetailContainerRouteParams> & WithTranslation;

type RequestDetailContainerStateType = IRequestDetailContainerState;

class RequestDetailContainer extends Component<RequestDetailContainerPropsType, RequestDetailContainerStateType> {
  public annexesGridComponent: GridComponent = null;

  private unregisterCallback: UnregisterCallback = null;

  private readonly CANNOT_SAVE_REFERENCE_ERROR: IErrors = { UnknownError: ['requestDetail.errors.cannotSaveReference'] };

  private readonly CONTEXTUAL_HELP_ID: string = 'requestHelpButton';

  private initialState: RequestDetailContainerStateType = {
    selectedTab: RequestDetailTab.REFERENCES,
    isSaving: false
  };

  public constructor(props: RequestDetailContainerPropsType) {
    super(props);

    props.reset();

    this.state = this.initialState;
  }

  public componentDidMount() {
    this.init();
  }

  public componentDidUpdate(prevProps: RequestDetailContainerPropsType): void {
    if (!this.props.isReadyForRendering && prevProps.isReadyForRendering) {
      this.init();
    }

    if (this.props.isReadyForRendering && prevProps.location.pathname !== this.props.location.pathname) {
      const isView = this.computeIsView();
      const isNew = this.computeIsNew();
      if (isView !== this.props.isView) {
        this.props.setIsView(isView);
      }
      if (!isNew && this.props.isNew) {
        this.props.setIsView(false);
      }
      if ((isNew && !this.props.isNew) || (this.props.request.id && this.props.request.id !== this.props.match.params.id)) {
        this.props.reset();
      }
    }

    if (!this.props.isNew && !prevProps.request.id && this.props.request.id) {
      this.getData();
      if (!this.props.isCurrentUserInternal && this.state.selectedTab !== RequestDetailTab.NOTIFICATIONS) {
        this.fetchNotifications();
      }
    }

    if (this.props.applicantCc.length === 0) {
      let companyId = this.props.currentUserCompanyId;
      if (companyId === null && this.props.request && this.props.request.companyId) {
        companyId = this.props.request.companyId;
      }

      this.fetchApplicantCc(companyId);
    }

    if (prevProps.isView !== this.props.isView && this.props.isView === false) {
      this.unregisterCallback = this.props.history.block((blocker) => {
        if (this.shouldBlockNavigation()) {
          dialogsService.openDialog(DialogId.DISCARD_CHANGES)
            .then(() => {
              if (this.unregisterCallback) {
                this.unregisterCallback();
                this.unregisterCallback = null;
              }
              this.props.resetRequest();
              setTimeout(() => {
                if (blocker.pathname.includes('/request')) {
                  this.props.history.replace(blocker.pathname);
                } else {
                  this.props.history.push(blocker.pathname);
                }
              });
            })
            .catch(() => {
              // DO nothing
            });
          return false;
        }
      });
    }

    if (document.getElementById(this.CONTEXTUAL_HELP_ID)) {
      document.getElementById(this.CONTEXTUAL_HELP_ID).onclick = () => {
        this.onRequestContextualHelpClick();
      };
    }
  }

  public componentWillUnmount(): void {
    const { isReadyForRendering, reset } = this.props;

    if (isReadyForRendering) {
      reset();
    }
  }

  public edit(): void {
    this.props.history.replace(this.props.location.pathname.replace('/view', '/edit'));
  }

  public async save(): Promise<void> {
    const { request, i18n } = this.props;

    if (!this.validate()) {
      return;
    }

    this.setState({ isSaving: true });
    await this.saveCommon(request, i18n.t('requestDetail.messages.saveSuccess'), i18n.t('requestDetail.errors.cannotSaveRequest'));
    this.setState({ isSaving: false });
  }

  public cancel(): void {
    if (this.props.location.pathname.includes('/new')) {
      if (this.props.request.id) {
        this.props.history.replace(this.props.location.pathname.replace('/new', `/${this.props.request.id}/view`));
      } else {
        this.props.history.push('/requests-list');
      }
    } else {
      this.props.history.replace(this.props.location.pathname.replace('/edit', '/view'));
    }
  }

  public async accept(): Promise<void> {
    const { request, i18n } = this.props;

    if (!this.validateAccept()) {
      return;
    }

    const requestToSave = { ...request, acceptanceStatus: RequestAcceptanceStatus.OK };
    await this.saveCommon(requestToSave, i18n.t('requestDetail.messages.acceptSuccess'), i18n.t('requestDetail.errors.cannotAcceptRequest'));
  }

  public async reject(): Promise<void> {
    const { request, i18n } = this.props;

    const requestToSave = { ...request, acceptanceStatus: RequestAcceptanceStatus.KO };
    await this.saveCommon(requestToSave, i18n.t('requestDetail.messages.rejectSuccess'), i18n.t('requestDetail.errors.cannotRejectRequest'));
  }

  public async saveReference(reference: IReference): Promise<void> {
    const referenceToSave: IReference = { ...reference, sourceType: SourceType.REQUEST, sourceId: this.props.request.id };
    try {
      if (reference.id) {
        await referencesService.update(referenceToSave);
      } else {
        await referencesService.create(referenceToSave);
      }
      this.fetchReferences();
    } catch (error) {
      let errors = error as IErrors;
      if (errors.UnknownError || (!errors.UnknownError && !errors.ControlledError)) {
        errors = this.CANNOT_SAVE_REFERENCE_ERROR;
      }
      this.manageError(errors);
    }
  }

  public uploadAnnex(): void {
    dialogsService.openDialog(DialogId.UPLOAD_ANNEX)
      .then(() => this.fetchAnnexes())
      .catch((reason?: IErrors) => {
        this.manageError(reason);
      });
  }

  public async downloadAnnex(annex: IAnnex): Promise<void> {
    try {
      if (this.annexesGridComponent) {
        this.annexesGridComponent.showSpinner();
      }

      await annexesService.downloadFile(annex.id, annex.description, annex.annexedCategoryValue);
    } catch (error) {
      notifyError(this.props.i18n.t('requestDetail.errors.cannotDownloadAnnex'));
    } finally {
      if (this.annexesGridComponent) {
        this.annexesGridComponent.hideSpinner();
      }
    }
  }

  public editAnnexState(annex: IAnnex): void {
    this.props.prepareEditAnnexStateDialog(annex);
    dialogsService.openDialog(DialogId.EDIT_ANNEX_STATE)
      .then(() => this.fetchAnnexes())
      .catch((reason?: IErrors) => {
        this.manageError(reason);
      });
  }

  public editAnnex(annex: IAnnex): void {
    this.props.prepareEditAnnexDialog(annex);
    dialogsService.openDialog(DialogId.EDIT_ANNEX)
      .then(() => this.fetchAnnexes())
      .catch((reason?: IErrors) => {
        this.manageError(reason);
      });
  }

  public deactivateAnnex(annexId: string): void {
    this.deactivateEntity(
      annexId,
      this.props.i18n.t('dialogs.deactivateEntityDialog.entityName.annex'),
      (entityId: string) => annexesService.deactivate(entityId),
      () => this.fetchAnnexes()
    );
  }

  public getPendingNotificationsCount(): number {
    return this.props.notifications.filter((notification) => notification.seen === false).length;
  }

  public onNotificationExpanded(id: string, seen: boolean): void {
    const { isInSimulationMode, markNotificationAsSeenLocal, markNotificationAsSeen } = this.props;

    if (!isInSimulationMode && !seen) {
      markNotificationAsSeenLocal(id);
      markNotificationAsSeen(notificationsService, id);
    }
  }

  public createNote(): void {
    dialogsService.openDialog(DialogId.CREATE_NOTE)
      .then(() => this.fetchNotes())
      .catch((reason?: IErrors) => {
        this.manageError(reason);
      });
  }

  public deactivateNote(noteId: string): void {
    this.deactivateEntity(
      noteId,
      this.props.i18n.t('dialogs.deactivateEntityDialog.entityName.note'),
      (entityId: string) => notesService.deactivate(entityId),
      () => this.fetchNotes()
    );
  }

  public onRequestContextualHelpClick() {
    this.props.setContextualHelpDialogCode(ContextualHelpCode.REQUEST);
    dialogsService.openDialog(DialogId.CONTEXTUAL_HELP)
      .catch(() => {
        // DO nothing
      });
  }

  public render(): JSX.Element {
    const {
      isReadyForRendering,
      request,
      isView,
      isNew,
      customFieldPermissions,
      bidderOptions,
      centerOptions,
      unecoOptions,
      delegationOptions,
      reports,
      references,
      isFetchingReferences,
      annexes,
      notifications,
      notes,
      requestChangesHistory,
      isCurrentUserInternal,
      currentUserId,
      setRequestSampleDate,
      setRequestStatus,
      setRequestOfferNumber,
      setRequestAmount,
      setRequestManagerGroupId,
      setRequestSubject,
      setRequestManagerEmailUserId,
      setRequestAcceptanceStatus,
      setRequestOrder,
      setRequestSendDate,
      setRequestAnalysisToPerform,
      setRequestCustomFields,
      i18n,
      errors,
      setErrors,
      setRequestDelegateUserManagerId,
      setRequestApplicantCc,
      applicantCc
    } = this.props;

    if (!isReadyForRendering) {
      return null;
    }

    if (!isNew && !request.id) {
      return null; // TODO: loading
    }

    const title = isNew ? <h2 className='titleRequest'>{i18n.t('requestDetail.titleNew')}</h2> : <h2 className='titleRequest'>{
      i18n.t('requestDetail.title')}</h2>;
    const contextualHelpButton =
      <button type="button" id={this.CONTEXTUAL_HELP_ID} className="btn btn-sm btn-light infoButton" title={i18n.t('request.actions.showContextualHelp')}>
        <span className="icon icon-info"></span>
      </button>;

    const pendingNotifications = this.getPendingNotificationsCount();

    return (
      <div>
        <div className='clearfix'>
          {title}{contextualHelpButton}
        </div>
        <InfoCard
          request={request}
          isView={isView}
          isNew={isNew}
          isSaving={this.state.isSaving}
          customFieldPermissions={customFieldPermissions}
          bidderOptions={bidderOptions}
          centerOptions={centerOptions}
          unecoOptions={unecoOptions}
          delegationOptions={delegationOptions}
          isCurrentUserInternal={isCurrentUserInternal}
          userId={currentUserId}
          onEditButtonClick={(): void => this.edit()}
          onSaveButtonClick={(): Promise<void> => this.save()}
          onCancelButtonClick={(): void => this.cancel()}
          onAcceptButtonClick={(): Promise<void> => this.accept()}
          onRejectButtonClick={(): Promise<void> => this.reject()}
          onSampleDateChange={setRequestSampleDate}
          onStatusChange={setRequestStatus}
          onOfferNumberChange={setRequestOfferNumber}
          onAmountChange={setRequestAmount}
          onManagerGroupIdChange={setRequestManagerGroupId}
          onSubjectChange={setRequestSubject}
          onManagerEmailUserIdChange={setRequestManagerEmailUserId}
          onAcceptanceStatusChange={setRequestAcceptanceStatus}
          onOrderChange={setRequestOrder}
          onSendDateChange={setRequestSendDate}
          onAnalysisToPerformChange={setRequestAnalysisToPerform}
          onCustomFieldsChange={setRequestCustomFields}
          errors={errors}
          setErrors={setErrors}
          onDelegateUserManagerIdChange={setRequestDelegateUserManagerId}
          applicantCc={applicantCc}
          onApplicantCcChange={setRequestApplicantCc}
        />
        <Tabs
          activeKey={this.state.selectedTab}
          onSelect={(selectedTab: string) => {
            this.setState({ selectedTab }, () => this.getData());
          }}
          id='detail-tabs'
          className='mb-3'
        >
          <Tab
            eventKey={RequestDetailTab.REFERENCES}
            title={(
              <div className='d-flex align-items-center'>
                <span className='icon icon-reference mr-2' />
                <span>{i18n.t(`enums.requestDetailTab.${RequestDetailTab.REFERENCES}`)}</span>
              </div>
            )}
            disabled={!request.id}
          >
            <ReferencesTab
              references={references}
              isFetching={isFetchingReferences}
              onReferenceSave={request.id ? (reference: IReference): Promise<void> => this.saveReference(reference) : undefined}
            />
          </Tab>
          <Tab
            eventKey={RequestDetailTab.ANNEXES}
            title={(
              <div className='d-flex align-items-center'>
                <span className='icon icon-annex mr-2' />
                <span>{i18n.t(`enums.requestDetailTab.${RequestDetailTab.ANNEXES}`)}</span>
              </div>
            )}
            disabled={!request.id}
          >
            <AnnexesTab
              annexes={annexes}
              sourceType={SourceType.REQUEST}
              isCurrentUserInternal={isCurrentUserInternal}
              currentUserId={currentUserId}
              gridRef={(grid: GridComponent): GridComponent => this.annexesGridComponent = grid}
              onUploadAnnexButtonClick={(): void => this.uploadAnnex()}
              onDownloadAnnexButtonClick={(annex): Promise<void> => this.downloadAnnex(annex)}
              onEditAnnexStateButtonClick={(annex): void => this.editAnnexState(annex)}
              onEditAnnexButtonClick={(annex): void => this.editAnnex(annex)}
              onDeactivateAnnexButtonClick={(annexId): void => this.deactivateAnnex(annexId)}
            />
          </Tab>
          <Tab
            eventKey={RequestDetailTab.NOTIFICATIONS}
            title={(
              <div className='d-flex align-items-center'>
                <span className='icon icon-notification mr-2' />
                <span>{i18n.t(`enums.requestDetailTab.${RequestDetailTab.NOTIFICATIONS}`)}</span>
                {pendingNotifications > 0 && <div className='ml-2 e-badge e-badge-primary'>{pendingNotifications}</div>}
              </div>
            )}
            disabled={!request.id}
          >
            <NotificationsAccordionComponent
              notifications={notifications.map((n) => ({ ...n, createdDate: dateHelpers.toShortDate(n.createdDate, true) }))}
              onExpanded={(notification) => this.onNotificationExpanded(notification.id, notification.seen)}
            />
          </Tab>
          <Tab
            eventKey={RequestDetailTab.NOTES}
            title={(
              <div className='d-flex align-items-center'>
                <span className='icon icon-comment mr-2' />
                <span>{i18n.t(`enums.requestDetailTab.${RequestDetailTab.NOTES}`)}</span>
              </div>
            )}
            disabled={!request.id}
          >
            <NotesTab
              notes={notes}
              currentUserId={currentUserId}
              onCreateNoteButtonClick={(): void => this.createNote()}
              onDeactivateNoteButtonClick={(noteId: string): void => this.deactivateNote(noteId)}
            />
          </Tab>
          <Tab
            eventKey={RequestDetailTab.REPORTS}
            title={(
              <div className='d-flex align-items-center'>
                <span className='icon icon-report mr-2' />
                <span>{i18n.t(`enums.requestDetailTab.${RequestDetailTab.REPORTS}`)}</span>
              </div>
            )}
            disabled={!request.id}
          >
            <ReportsTab reports={reports} />
          </Tab>
          {isCurrentUserInternal && <Tab
            eventKey={RequestDetailTab.REQUEST_CHANGES_HISTORY}
            title={(
              <div className='d-flex align-items-center'>
                <span className='icon icon-report-change-history mr-2' />
                <span>{i18n.t(`enums.requestDetailTab.${RequestDetailTab.REQUEST_CHANGES_HISTORY}`)}</span>
              </div>
            )}
          >
            <RequestChangesHistoryTab
              requestChangesHistory={requestChangesHistory}
            />
          </Tab>}
        </Tabs>
      </div>
    );
  }

  private computeIsView(): boolean {
    return this.props.location.pathname.includes('/view');
  }

  private computeIsNew(): boolean {
    return this.props.location.pathname.includes('/new');
  }

  private init(): void {
    const { isCurrentUserInternal, makeReadyForRendering, fetchRequest, fetchPermissionsAndOptions, location, match } = this.props;

    if (!isCurrentUserInternal) {
      fetchPermissionsAndOptions(requestsService);
    }

    const isView = this.computeIsView();
    const isNew = this.computeIsNew();
    makeReadyForRendering(isView, isNew);
    if (!isNew) {
      fetchRequest(match.params.id, requestsService);
    }

    const parsedUrl = queryString.parse(location.search);
    if (parsedUrl.tab && parsedUrl.tab !== this.state.selectedTab) {
      this.setState({ selectedTab: parsedUrl.tab as string });
    }
  }

  private shouldBlockNavigation(): boolean {
    const { request, requestInitial } = this.props;

    let customFieldsHaveChanged = false;
    if (request.customFields) {
      customFieldsHaveChanged = (
        request.customFields.bidderId !== requestInitial.customFields.bidderId
        || request.customFields.contest !== requestInitial.customFields.contest
        || request.customFields.initialAmount !== requestInitial.customFields.initialAmount
        || request.customFields.quantity !== requestInitial.customFields.quantity
        || request.customFields.sampleSealed !== requestInitial.customFields.sampleSealed
        || request.customFields.returnSurplusSample !== requestInitial.customFields.returnSurplusSample
        || (request.customFields.requiredDate === null && requestInitial.customFields.requiredDate !== null)
        || (request.customFields.requiredDate !== null && !requestInitial.customFields.requiredDate === null)
        || (request.customFields.requiredDate && !requestInitial.customFields.requiredDate && request.customFields.requiredDate.valueOf() !== requestInitial.customFields.requiredDate.valueOf())
        || request.customFields.eciRequest !== requestInitial.customFields.eciRequest
        || request.customFields.centerId !== requestInitial.customFields.centerId
        || request.customFields.unecoId !== requestInitial.customFields.unecoId
        || request.customFields.delegationId !== requestInitial.customFields.delegationId
        || request.customFields.supplier !== requestInitial.customFields.supplier
      );
    }

    return (
      customFieldsHaveChanged
      || (request.sampleDate === null && requestInitial.sampleDate !== null)
      || (request.sampleDate !== null && !requestInitial.sampleDate === null)
      || (request.sampleDate && !requestInitial.sampleDate && request.sampleDate.valueOf() !== requestInitial.sampleDate.valueOf())
      || request.status !== requestInitial.status
      || request.offerNumber !== requestInitial.offerNumber
      || request.amount !== requestInitial.amount
      || request.managerGroupId !== requestInitial.managerGroupId
      || request.subject !== requestInitial.subject
      || request.applicantEmail !== requestInitial.applicantEmail
      || request.managerEmailUserId !== requestInitial.managerEmailUserId
      || request.applicantCc !== requestInitial.applicantCc
      || request.acceptanceStatus !== requestInitial.acceptanceStatus
      || request.order !== requestInitial.order
      || (request.sendDate === null && requestInitial.sendDate !== null)
      || (request.sendDate !== null && !requestInitial.sendDate === null)
      || (request.sendDate && !requestInitial.sendDate && request.sendDate.valueOf() !== requestInitial.sendDate.valueOf())
      || request.analysisToPerform !== requestInitial.analysisToPerform
    );
  }

  private async saveCommon(request: IRequest, successMessage: string, errorMessage: string): Promise<void> {
    const { isNew, customFieldPermissions, isCurrentUserInternal } = this.props;

    await dialogsService.openDialog(DialogId.GENERIC_CONFIRMATION)
      .then(async () => {
        try {

          if (isNew && !isCurrentUserInternal && !customFieldPermissions.some((p) => p.canWrite)) {
            request.customFields = null;
          }

          const savedRequest = request.id ? await requestsService.update(request) : await requestsService.create(request);
          notifySuccess(successMessage);

          this.props.saveRequest(savedRequest);

          if (this.unregisterCallback) {
            this.unregisterCallback();
          }
          this.cancel();
        } catch (error) {
          notifyError(errorMessage);
        } finally {
          this.setState({ isSaving: false });
        }
      })
      .catch(() => {
        // DO nothing
      });
  }

  private async getData() {
    if (this.state.selectedTab === RequestDetailTab.REPORTS) {
      this.fetchReports();
    } else if (this.state.selectedTab === RequestDetailTab.REFERENCES) {
      this.fetchReferences();
    } else if (this.state.selectedTab === RequestDetailTab.ANNEXES) {
      this.fetchAnnexes();
    } else if (this.state.selectedTab === RequestDetailTab.NOTIFICATIONS) {
      this.fetchNotifications();
    } else if (this.state.selectedTab === RequestDetailTab.NOTES) {
      this.fetchNotes();
    } else if (this.state.selectedTab === RequestDetailTab.REQUEST_CHANGES_HISTORY) {
      this.fetchRequestChangesHistory();
    }
  }

  private fetchReports(): void {
    const { fetchReports, match } = this.props;

    fetchReports(match.params.id, requestsService);
  }

  private fetchReferences(): void {
    const { fetchReferences, match } = this.props;

    fetchReferences(match.params.id, requestsService);
  }

  private fetchAnnexes(): void {
    const { fetchAnnexes, match } = this.props;

    if (this.annexesGridComponent) {
      this.annexesGridComponent.showSpinner();
    }

    fetchAnnexes(match.params.id, requestsService);
  }

  private fetchNotifications(): void {
    const { fetchNotifications, match } = this.props;

    fetchNotifications(match.params.id, requestsService);
  }

  private fetchNotes(): void {
    const { fetchNotes, match } = this.props;

    fetchNotes(match.params.id, requestsService);
  }

  private fetchRequestChangesHistory() {
    const { fetchRequestChangesHistory, match } = this.props;

    fetchRequestChangesHistory(match.params.id, requestsService);
  }

  private fetchApplicantCc(companyId?: string) {
    const { fetchApplicantCc } = this.props;

    if (companyId !== null) {
      fetchApplicantCc(companyId, companiesService);
    }
  }

  private deactivateEntity(
    entityId: string,
    entityName: string,
    deactivate: (entityId: string) => Promise<void>,
    successCallback: () => void
  ): void {
    this.props.prepareDeactivateEntityDialog(entityId, entityName, deactivate);
    dialogsService.openDialog(DialogId.DEACTIVATE_ENTITY)
      .then(() => successCallback())
      .catch((reason?: IErrors) => {
        this.manageError(reason);
      });
  }

  private validate(): boolean {
    const { request, customFieldPermissions, isCurrentUserInternal, i18n } = this.props;

    if (isCurrentUserInternal) {
      if (request.managerEmailUserId === undefined || request.managerEmailUserId === null) {
        notifyError(i18n.t('requestDetail.errors.internalUserNotAssignManager'));
        return false;
      }
      if (request.status === RequestStatus.FINALIZED && (request.offerNumber === undefined || request.offerNumber === null || request.offerNumber.trim() === '')) {
        notifyError(i18n.t('requestDetail.errors.internalUserCanNotStatusFinishedWithoutOffer'));
        return false;
      }
    }
    else if (request.customFields) {
      const requiredDatePermission = customFieldPermissionUtils.getCustomFieldPermissionByCode(customFieldPermissions, 'requiredDate');
      if (requiredDatePermission && requiredDatePermission.canWrite && !request.customFields.requiredDate) {
        notifyError(i18n.t('requestDetail.errors.requiredDateRequired'));
        return false;
      }
      const eciRequestPermission = customFieldPermissionUtils.getCustomFieldPermissionByCode(customFieldPermissions, 'eciRequest');
      if (eciRequestPermission && eciRequestPermission.canWrite && !request.customFields.eciRequest) {
        notifyError(i18n.t('requestDetail.errors.eciRequestRequired'));
        return false;
      }
      const centerIdPermission = customFieldPermissionUtils.getCustomFieldPermissionByCode(customFieldPermissions, 'centerId');
      if (centerIdPermission && centerIdPermission.canWrite && !request.customFields.centerId) {
        notifyError(i18n.t('requestDetail.errors.centerIdRequired'));
        return false;
      }
      const unecoIdPermission = customFieldPermissionUtils.getCustomFieldPermissionByCode(customFieldPermissions, 'unecoId');
      if (unecoIdPermission && unecoIdPermission.canWrite && !request.customFields.unecoId) {
        notifyError(i18n.t('requestDetail.errors.unecoIdRequired'));
        return false;
      }
      const delegationIdPermission = customFieldPermissionUtils.getCustomFieldPermissionByCode(customFieldPermissions, 'delegationId');
      if (delegationIdPermission && delegationIdPermission.canWrite && !request.customFields.delegationId) {
        notifyError(i18n.t('requestDetail.errors.delegationIdRequired'));
        return false;
      }
      const supplierPermission = customFieldPermissionUtils.getCustomFieldPermissionByCode(customFieldPermissions, 'supplier');
      if (supplierPermission && supplierPermission.canWrite && !request.customFields.supplier) {
        notifyError(i18n.t('requestDetail.errors.supplierRequired'));
        return false;
      }
    }
    
    return true;
  }

  private validateAccept(): boolean {
    const { request, isCurrentUserInternal, i18n } = this.props;

    if (!isCurrentUserInternal && (request.offerNumber === undefined || request.offerNumber === null || request.offerNumber.trim() === '')) {
      notifyError(i18n.t('requestDetail.errors.externalUserCanNotAcceptRequestWithoutAmount'));
      return false;
    }

    return true;
  }

  private manageError(error?: IErrors): void {
    if (error) {
      if (error.ControlledError) {
        notifyError(error.ControlledError[0]);
      }
      if (error.UnknownError) {
        notifyError(this.props.i18n.t(error.UnknownError[0]));
      }
    }
  }
}

const mapStateToProps = (state: RootState): IRequestDetailContainerStateProps => ({
  isReadyForRendering: state.requestDetailStore.isReadyForRendering,
  request: state.requestDetailStore.request,
  requestInitial: state.requestDetailStore.requestInitial,
  isView: state.requestDetailStore.isView,
  isNew: state.requestDetailStore.isNew,
  customFieldPermissions: state.requestDetailStore.customFieldPermissions,
  bidderOptions: state.requestDetailStore.bidderOptions,
  centerOptions: state.requestDetailStore.centerOptions,
  unecoOptions: state.requestDetailStore.unecoOptions,
  delegationOptions: state.requestDetailStore.delegationOptions,
  reports: state.requestDetailStore.reports,
  references: state.requestDetailStore.references,
  isFetchingReferences: state.requestDetailStore.isFetchingReferences,
  annexes: state.requestDetailStore.annexes,
  notifications: state.requestDetailStore.notifications,
  notes: state.requestDetailStore.notes,
  requestChangesHistory: state.requestDetailStore.requestChangesHistory,
  isCurrentUserInternal: state.currentUserStore.user.type === UserType.INTERNAL,
  currentUserId: state.currentUserStore.user.id,
  isInSimulationMode: state.currentUserStore.jwtInfo.isInSimulationMode === 'true',
  errors: state.requestDetailStore.errors,
  currentUserCompanyId: state.currentUserStore.company !== undefined && state.currentUserStore.company !== null ? state.currentUserStore.company.id : null,
  applicantCc: state.requestDetailStore.applicantCc
});

const mapDispatchToProps: IRequestDetailContainerDispatchProps = {
  reset: requestDetailStore.reset,
  makeReadyForRendering: requestDetailStore.makeReadyForRendering,
  setIsView: requestDetailStore.setIsView,
  setRequestSampleDate: requestDetailStore.setRequestSampleDate,
  setRequestStatus: requestDetailStore.setRequestStatus,
  setRequestOfferNumber: requestDetailStore.setRequestOfferNumber,
  setRequestAmount: requestDetailStore.setRequestAmount,
  setRequestManagerGroupId: requestDetailStore.setRequestManagerGroupId,
  setRequestSubject: requestDetailStore.setRequestSubject,
  setRequestManagerEmailUserId: requestDetailStore.setRequestManagerEmailUserId,
  setRequestAcceptanceStatus: requestDetailStore.setRequestAcceptanceStatus,
  setRequestOrder: requestDetailStore.setRequestOrder,
  setRequestSendDate: requestDetailStore.setRequestSendDate,
  setRequestAnalysisToPerform: requestDetailStore.setRequestAnalysisToPerform,
  setRequestCustomFields: requestDetailStore.setRequestCustomFields,
  markNotificationAsSeenLocal: requestDetailStore.markNotificationAsSeenLocal,
  fetchRequest: requestDetailStore.fetchRequest,
  resetRequest: requestDetailStore.resetRequest,
  saveRequest: requestDetailStore.saveRequest,
  fetchPermissionsAndOptions: requestDetailStore.fetchPermissionsAndOptions,
  fetchReports: requestDetailStore.fetchReports,
  fetchReferences: requestDetailStore.fetchReferences,
  fetchAnnexes: requestDetailStore.fetchAnnexes,
  fetchNotifications: requestDetailStore.fetchNotifications,
  fetchNotes: requestDetailStore.fetchNotes,
  fetchRequestChangesHistory: requestDetailStore.fetchRequestChangesHistory,
  markNotificationAsSeen: notificationsSidebarStore.markNotificationAsSeen,
  prepareEditAnnexStateDialog: dialogsStore.prepareEditAnnexStateDialog,
  prepareEditAnnexDialog: dialogsStore.prepareEditAnnexDialog,
  prepareDeactivateEntityDialog: dialogsStore.prepareDeactivateEntityDialog,
  setErrors: requestDetailStore.setErrors,
  setRequestDelegateUserManagerId: requestDetailStore.setRequestDelegateUserManagerId,
  setContextualHelpDialogCode: dialogsStore.setContextualHelpDialogCode,
  setRequestApplicantCc: requestDetailStore.setRequestApplicantCc,
  fetchApplicantCc: requestDetailStore.fetchApplicantCc
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface IRequestDetailContainerState {
  selectedTab: string;
  isSaving: boolean;
}

export default compose(
  withRouter,
  withTranslation(),
  connector,
)(RequestDetailContainer) as ComponentType;
