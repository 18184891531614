import { saveAs } from 'file-saver';

export const getFilenameFromContentDisposition = (headers: Record<string, string>): string => {
  let filename = '';
  const disposition = headers['content-disposition'];
  if (disposition && disposition.indexOf('attachment') >= 0) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches !== null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }
  return filename;
};

export const saveFile = (blob: Blob, filename: string): void => {
  saveAs(blob, filename);
};
