import i18n from 'i18next';
import { loadCldr, L10n, setCulture } from '@syncfusion/ej2-base';
import { initReactI18next } from 'react-i18next';

// English
import en from './assets/locales/en.json';
import numbersEN from 'cldr-data/main/en/numbers.json';
import currenciesEN from 'cldr-data/main/en/currencies.json';
import gregorianEN from 'cldr-data/main/en/ca-gregorian.json';
import zonesEN from 'cldr-data/main/en/timeZoneNames.json';

// Spanish
import 'moment/locale/es';
import numbersES from 'cldr-data/main/es/numbers.json';
import currenciesES from 'cldr-data/main/es/currencies.json';
import gregorianES from 'cldr-data/main/es/ca-gregorian.json';
import zonesES from 'cldr-data/main/es/timeZoneNames.json';
import es from './assets/locales/es.json';

import { Language } from './common/model/enumerations/language.model';

const cldr = {
  [Language.ENGLISH]: [numbersEN, currenciesEN, gregorianEN, zonesEN],
  [Language.SPANISH]: [numbersES, currenciesES, gregorianES, zonesES]
};

const resources = {
  [Language.ENGLISH]: {
    translation: en
  },
  [Language.SPANISH]: {
    translation: es
  }
};

// Define user's language. Different browsers have the user locale defined
// on different fields on the `navigator` object, so we make sure to account
// for these different by checking all of them
const language =
  (window.navigator.languages && window.navigator.languages[0]) ||
  window.navigator.language /*||
    window.navigator.userLanguage*/;

// Split locales with a region code
const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: Language.ENGLISH, // Change current language on the end of this file
    interpolation: {
      escapeValue: false // react already safes from xss
    },
  });

function loadCldrDependencies(languageLocal: Language): void {
  loadCldr(...cldr[languageLocal]);
}

function loadSyncfusionComponentLocale(languageLocal: Language): void {
  const locales = (resources[languageLocal].translation as any);

  if (!locales || !locales.syncfusion) {
    throw new Error(`Cannot load syncfusion texts for language: '${languageLocal}'`);
  }

  L10n.load({
    [languageLocal]: locales.syncfusion
  });

  setCulture(languageLocal);
}

export default i18n;

export function getCurrentLanguage(): string {
  return i18n.language;
}

export type LanguageReturnType = string[];

export function getLanguagesList(): LanguageReturnType[] {
  return [
    ['English', Language.ENGLISH],
    ['Español', Language.SPANISH]
  ];
}

export function changeCurrentLanguage(languageLocal: Language): void {
  i18n.changeLanguage(languageLocal);

  if (languageLocal !== Language.ENGLISH) {
    loadCldrDependencies(languageLocal);
    loadSyncfusionComponentLocale(languageLocal);
  }
}

export function existsLanguageByCode(code: string): boolean {
  const languages: LanguageReturnType[] = getLanguagesList();
  const loweredCode = code.toLowerCase();
  const exists: boolean = languages.some((lang) => {
    return lang[0].toLowerCase() === loweredCode;
  });

  return exists;
}

export function getLanguageByCode(code: string): Language {
  let languageLocal = Language.ENGLISH;
  const languages = Object.keys(Language);
  const loweredCode = code.toLowerCase();

  const enumLanguage = languages.find((lang) => {
    return Language[lang as keyof typeof Language] === loweredCode;
  });

  if (enumLanguage !== undefined) {
    languageLocal = Language[enumLanguage as keyof typeof Language];
  }

  return languageLocal;
}

// If English is not default
changeCurrentLanguage(getLanguageByCode(languageWithoutRegionCode));
