import { Action, combineReducers, Reducer } from 'redux';
import { combineAllReducers } from '../utils';

export interface PageState {
    page: Page;
}

interface Page {
    pageName: string;
    company: PageCompany;
    report: PageReport;
    reference: PageReference;
}

interface PageCompany {
    name?: string;
    id: string;
}

interface PageReport {
    name?: string;
    id: string;
}

interface PageReference {
    name?: string;
    id: string;
}

const defaultState: Page = {
    pageName: null,

    company: {
        id: null,
        name: null,
    },
    report: {
        id: null,
        name: null,
    },
    reference: {
        id: null,
        name: null,
    }
};

// Types
enum Type {
    DISPLAY_COMPANY = '@@page/DISPLAY_COMPANY',
    DISPLAY_REPORT = '@@page/DISPLAY_REPORTID',
    DISPLAY_REPORT_REFERENCE = '@@page/DISPLAY_REPORT_REFERENCE',
    DISPLAY_PAGENAME = '@@page/DISPLAY_PAGENAME',
    CLEAN_INFO = '@@page/CLEAN_INFO'
}

// Actions
export interface DisplayCompanyAction extends Action<Type.DISPLAY_COMPANY> {
    companyId: string;
    companyName: string;
}

export interface DisplayReportAction extends Action<Type.DISPLAY_REPORT> {
    reportId: string;
    reportNumber: string;
}

export interface DisplayPageNameAction extends Action<Type.DISPLAY_PAGENAME> {
    pageName: string;
}

export interface DisplayReportAndReferenceAction extends Action<Type.DISPLAY_REPORT_REFERENCE> {
    reportId: string;
    referenceId: string;
    referenceName: string;
}

export type CleanPageInfoAction = Action<Type.CLEAN_INFO>

export const displayCompany = (companyId: string, companyName?: string): DisplayCompanyAction => {
    return { type: Type.DISPLAY_COMPANY, companyId, companyName };
};

export const displayReport = (reportId: string, reportNumber?: string): DisplayReportAction => {
    return { type: Type.DISPLAY_REPORT, reportId, reportNumber };
};

export const displayReportAndReference = (reportId: string, referenceId: string, referenceName?: string): DisplayReportAndReferenceAction => {
    return { type: Type.DISPLAY_REPORT_REFERENCE, reportId, referenceId, referenceName };
};

export const displayPageName = (pageName: string): DisplayPageNameAction => {
    return { type: Type.DISPLAY_PAGENAME, pageName };
};

export const cleanPageInfo = (): CleanPageInfoAction => {
    return { type: Type.CLEAN_INFO };
};

// Reducers
const displayCompanyReducer: Reducer<Page, DisplayCompanyAction> = (state, action) => {
    if (action.type !== Type.DISPLAY_COMPANY) {
        return state;
    }

    return { ...state, company: { id: action.companyId, name: action.companyName } };
};

const displayReportReducer: Reducer<Page, DisplayReportAction> = (state, action) => {
    if (action.type !== Type.DISPLAY_REPORT) {
        return state;
    }

    return { ...state, report: { id: action.reportId, name: action.reportNumber } };
};

const displayReportAndReferenceReducer: Reducer<Page, DisplayReportAndReferenceAction> = (state, action) => {
    if (action.type !== Type.DISPLAY_REPORT_REFERENCE) {
        return state;
    }

    return { ...state, report: { id: action.reportId }, reference: { id: action.referenceId, name: action.referenceName } };
};

const displayPageNameReducer: Reducer<Page, DisplayPageNameAction> = (state, action) => {
    if (action.type !== Type.DISPLAY_PAGENAME) {
        return state;
    }

    return { ...state, pageName: action.pageName };
};

const cleanPageInfoReducer: Reducer<Page, CleanPageInfoAction> = (state, action) => {
    if (action.type !== Type.CLEAN_INFO) {
        return state;
    }

    return { ...state, report: { id: null, name: null }, reference: { id: null, name: null }, company: { id: null, name: null } };
};

const reducers: Array<Reducer<Page, any>> = [
    displayCompanyReducer,
    displayReportReducer,
    displayReportAndReferenceReducer,
    cleanPageInfoReducer,
    displayPageNameReducer
];

export const pageStore = combineReducers<PageState>({
    page: combineAllReducers<Page>(reducers, defaultState)
});
