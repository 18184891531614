import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ColumnsModel, ChangeEventArgs } from '@syncfusion/ej2-querybuilder';
import { QueryBuilderComponent } from '@syncfusion/ej2-react-querybuilder';
import { getComponent } from '@syncfusion/ej2-base';
import { DropDownList } from '@syncfusion/ej2-dropdowns';

import { IReportsListAdvancedFilterProps } from '../../../interfaces/props/IReportsListProps';
import { DATE_FORMAT } from '../../../common/constants/advancedFilter.constants';
import { IMyRule } from '../../../common/model/myRule.model';
import { AdvancedFilterColumnType } from '../../../common/model/enumerations/advancedFilterColumnType.model';
import { RuleOperator } from '../../../common/model/enumerations/ruleOperator.model';
import { ReportStatus } from '../../../common/model/enumerations/reportStatus.model';
import { queryBuilderService } from '../../../services';
import { enumToArray } from '../../../utils/enum.utils';
import * as advancedFilterUtils from '../../../utils/advancedFilter.utils';
import * as reportStatusUtils from '../../../utils/reportStatus.utils';
import * as queryBuilderUtils from '../../../utils/queryBuilder.utils';

type ReportsListAdvancedFilterPropsType = IReportsListAdvancedFilterProps & WithTranslation;

class ReportsListAdvancedFilter extends Component<ReportsListAdvancedFilterPropsType> {
  public queryBuilderComponent: QueryBuilderComponent = null;
  public columns: ColumnsModel[] = [];

  private readonly CONTEXTUAL_HELP_ID = 'reportsListAdvancedFilterContextualHelp';

  private statusElem: HTMLInputElement;
  private statusDropDownObj: DropDownList;

  public constructor(props: ReportsListAdvancedFilterPropsType) {
    super(props);

    const { advancedFilterColumns, i18n } = props;

    this.columns = advancedFilterColumns.map((advancedFilterColumn) => {
      const column: ColumnsModel = {
        field: advancedFilterColumn.field,
        label: i18n.t(advancedFilterColumn.label),
        type: advancedFilterColumn.type
      };
      if (advancedFilterColumn.type === AdvancedFilterColumnType.NUMBER) {
        column.operators = [
          { key: i18n.t('syncfusion.querybuilder.Equal'), value: RuleOperator.EQUAL },
          { key: i18n.t('syncfusion.querybuilder.NotEqual'), value: RuleOperator.NOT_EQUAL },
          { key: i18n.t('syncfusion.querybuilder.GreaterThan'), value: RuleOperator.GREATER_THAN },
          { key: i18n.t('syncfusion.querybuilder.GreaterThanOrEqual'), value: RuleOperator.GREATER_THAN_OR_EQUAL },
          { key: i18n.t('syncfusion.querybuilder.LessThan'), value: RuleOperator.LESS_THAN },
          { key: i18n.t('syncfusion.querybuilder.LessThanOrEqual'), value: RuleOperator.LESS_THAN_OR_EQUAL },
          { key: i18n.t('syncfusion.querybuilder.Between'), value: RuleOperator.BETWEEN },
          { key: i18n.t('syncfusion.querybuilder.NotBetween'), value: RuleOperator.NOT_BETWEEN },
          { key: i18n.t('syncfusion.querybuilder.In'), value: RuleOperator.IN },
          { key: i18n.t('syncfusion.querybuilder.NotIn'), value: RuleOperator.NOT_IN }
        ];
      }
      if (advancedFilterColumn.type === AdvancedFilterColumnType.STRING) {
        column.operators = [
          { key: i18n.t('syncfusion.querybuilder.StartsWith'), value: RuleOperator.STARTS_WITH },
          { key: i18n.t('syncfusion.querybuilder.EndsWith'), value: RuleOperator.ENDS_WITH },
          { key: i18n.t('syncfusion.querybuilder.Contains'), value: RuleOperator.CONTAINS },
          { key: i18n.t('syncfusion.querybuilder.Equal'), value: RuleOperator.EQUAL },
          { key: i18n.t('syncfusion.querybuilder.NotEqual'), value: RuleOperator.NOT_EQUAL },
          { key: i18n.t('syncfusion.querybuilder.In'), value: RuleOperator.IN },
          { key: i18n.t('syncfusion.querybuilder.NotIn'), value: RuleOperator.NOT_IN },
          { key: i18n.t('syncfusion.querybuilder.IsEmpty'), value: RuleOperator.IS_EMPTY },
          { key: i18n.t('syncfusion.querybuilder.IsNotEmpty'), value: RuleOperator.IS_NOT_EMPTY }
        ];
      }
      if (advancedFilterColumn.type === AdvancedFilterColumnType.DATE) {
        column.format = DATE_FORMAT;
        column.operators = [
          { key: i18n.t('syncfusion.querybuilder.Equal'), value: RuleOperator.EQUAL },
          { key: i18n.t('syncfusion.querybuilder.NotEqual'), value: RuleOperator.NOT_EQUAL },
          { key: i18n.t('syncfusion.querybuilder.GreaterThan'), value: RuleOperator.GREATER_THAN },
          { key: i18n.t('syncfusion.querybuilder.GreaterThanOrEqual'), value: RuleOperator.GREATER_THAN_OR_EQUAL },
          { key: i18n.t('syncfusion.querybuilder.LessThan'), value: RuleOperator.LESS_THAN },
          { key: i18n.t('syncfusion.querybuilder.LessThanOrEqual'), value: RuleOperator.LESS_THAN_OR_EQUAL },
          { key: i18n.t('syncfusion.querybuilder.Today'), value: RuleOperator.TODAY },
          { key: i18n.t('syncfusion.querybuilder.GreaterThanToday'), value: RuleOperator.GREATER_THAN_TODAY },
          { key: i18n.t('syncfusion.querybuilder.GreaterThanOrEqualToday'), value: RuleOperator.GREATER_THAN_OR_EQUAL_TODAY },
          { key: i18n.t('syncfusion.querybuilder.LessThanToday'), value: RuleOperator.LESS_THAN_TODAY },
          { key: i18n.t('syncfusion.querybuilder.LessThanOrEqualToday'), value: RuleOperator.LESS_THAN_OR_EQUAL_TODAY }
        ];
      }
      if (advancedFilterColumn.field === 'status') {
        column.operators = [
          { key: i18n.t('syncfusion.querybuilder.Equal'), value: RuleOperator.EQUAL },
          { key: i18n.t('syncfusion.querybuilder.NotEqual'), value: RuleOperator.NOT_EQUAL }
        ];
        column.template = {
          create: () => {
            this.statusElem = document.createElement('input');
            this.statusElem.setAttribute('type', 'text');
            return this.statusElem;
          },
          destroy: (args: any) => {
            this.statusDropDownObj = getComponent(document.getElementById(args.elementId), 'dropdownlist');
            if (this.statusDropDownObj) {
              this.statusDropDownObj.destroy();
            }
          },
          write: (args: any) => {
            const ds = enumToArray(ReportStatus, 'string', 'text', 'value')
              .filter((item) => item.value !== ReportStatus.ALL)
              .map((item) => ({
                ...item,
                text: reportStatusUtils.getLocalizedText(props.i18n, item.value as string)
              }));
            this.statusDropDownObj = new DropDownList({
              dataSource: ds,
              fields: { text: 'text', value: 'value' },
              value: args.values,
              placeholder: i18n.t('syncfusion.querybuilder.SelectValue'),
              change: (e) => {
                this.queryBuilderComponent.notifyChange(e.itemData.value, e.element);
              }
            });
            this.statusDropDownObj.appendTo('#' + args.elements.id);
          }
        };
      }
      return column;
    });
  }

  public componentDidUpdate(prevProps: ReportsListAdvancedFilterPropsType) {
    const { advancedFilter, onContextualHelpClick } = this.props;

    if (this.queryBuilderComponent) {
      if ((!!prevProps.advancedFilter.rule.condition && !advancedFilter.rule.condition)) {
        this.queryBuilderComponent.reset();
      }

      if (prevProps.advancedFilter.id !== advancedFilter.id) {
        this.queryBuilderComponent.setRules(advancedFilter.rule);
        this.checkTodayOperators();
        if (advancedFilter.id) {
          setTimeout(() => this.onFilterButtonClick(), 100);
        }
      }

      if (document.getElementById(this.CONTEXTUAL_HELP_ID)) {
        document.getElementById(this.CONTEXTUAL_HELP_ID).onclick = () => {
          onContextualHelpClick();
        };
      }
    }
  }

  public onQueryBuilderComponentChange(args: ChangeEventArgs) {
    if (args.type === 'operator' && (
      args.value === RuleOperator.TODAY
      || args.value === RuleOperator.GREATER_THAN_TODAY
      || args.value === RuleOperator.GREATER_THAN_OR_EQUAL_TODAY
      || args.value === RuleOperator.LESS_THAN_TODAY
      || args.value === RuleOperator.LESS_THAN_OR_EQUAL_TODAY
    )) {
      this.hideRuleValue(this.queryBuilderComponent.element.id + '_' + args.ruleID);
    }
  }

  public onSaveButtonClick() {
    this.props.onSaveButtonClick(this.computeMyRule());
  }

  public onApplyButtonClick() {
    this.props.onApplyButtonClick();
  }

  public onShareButtonClick() {
    this.props.onShareButtonClick(this.computeMyRule());
  }

  public onClearButtonClick() {
    if (this.queryBuilderComponent) {
      this.queryBuilderComponent.reset();
    }
    this.props.onClearButtonClick();
  }

  public onFilterButtonClick() {
    this.props.onFilterButtonClick(this.computeMyRule());
  }

  public render() {
    const { advancedFilter, isFetching, isCurrentUserInternal, i18n } = this.props;

    if (this.queryBuilderComponent) {
      queryBuilderUtils.removeAppliedFilterInfoFromQueryBuilder(this.queryBuilderComponent);
      queryBuilderUtils.removeContextualHelpFromQueryBuilder(this.queryBuilderComponent);

      if (advancedFilter.id) {
        queryBuilderUtils.addAppliedFilterInfoToQueryBuilder(this.queryBuilderComponent, advancedFilterUtils.getName(advancedFilter, i18n.language));
      }

      queryBuilderUtils.addContextualHelpToQueryBuilder(this.queryBuilderComponent, this.CONTEXTUAL_HELP_ID, i18n);
    }

    return (
      <form autoComplete='off'>
        <div className='row'>
          <div className='col'>
            <div className='form-group'>
              <QueryBuilderComponent
                columns={this.columns}
                width='100%'
                ref={(queryBuilderComponent) => this.queryBuilderComponent = queryBuilderComponent}
                change={(args) => this.onQueryBuilderComponentChange(args)}
              />
            </div>
          </div>
        </div>
        <div className='row align-items-baseline justify-content-end'>
          {isCurrentUserInternal && <div className='col-auto'>
            <button type='button' className='btn btn-primary text-white' title={i18n.t('advancedFilterComponent.actions.share')} onClick={() => this.onShareButtonClick()}>
              <span className='icon icon-share-square-o' />
            </button>
          </div>}
          <div className='col-auto'>
            <button type='button' className='btn btn-primary text-white' title={i18n.t('advancedFilterComponent.actions.save')} onClick={() => this.onSaveButtonClick()}>
              <span className='icon icon-app-item-save' />
            </button>
          </div>
          <div className='col-auto'>
            <button type='button' className='btn btn-primary text-white' title={i18n.t('advancedFilterComponent.actions.applySavedFilter')} onClick={() => this.onApplyButtonClick()}>
              <span className='icon icon-advanced-filter' />
            </button>
          </div>
          {advancedFilter.id && <div className='col-auto'>
            <button type='button' className='btn btn-primary text-white' title={i18n.t('advancedFilterComponent.actions.clearSavedFilter')} onClick={() => this.onClearButtonClick()}>
              <span className='icon icon-app-item-close' />
            </button>
          </div>}
          <div className='col-auto'>
            <button type='button' className='btn btn-secondary' title={i18n.t('actions.filter')} onClick={() => this.onFilterButtonClick()} disabled={isFetching}>
              {isFetching ?
                <span className='spinner-border spinner-border-sm' />
                :
                <span className='icon icon-app-item-search' />
              }
            </button>
          </div>
        </div>
      </form>
    );
  }

  private computeMyRule(): IMyRule {
    if (!this.queryBuilderComponent) {
      return null;
    }

    return queryBuilderService.mapRule(this.queryBuilderComponent.getRules(), this.props.advancedFilterColumns);
  }

  private checkTodayOperators() {
    const eDatepickerElements = this.queryBuilderComponent.element.getElementsByClassName('e-control e-datepicker e-lib e-input e-keyboard');
    const dateRulesIds: string[] = [];
    for (let i = 0; i < eDatepickerElements.length; i++) {
      dateRulesIds.push(eDatepickerElements.item(i).id.substring(0, eDatepickerElements.item(i).id.lastIndexOf('_')));
    }
    for (const dateRuleId of dateRulesIds) {
      const operatorSelectElement = document.getElementById(dateRuleId + '_operatorkey_hidden') as HTMLSelectElement;
      if (operatorSelectElement) {
        const selectedOption = operatorSelectElement.options.item(operatorSelectElement.selectedIndex);
        if (selectedOption && (
          selectedOption.value === RuleOperator.TODAY
          || selectedOption.value === RuleOperator.GREATER_THAN_TODAY
          || selectedOption.value === RuleOperator.GREATER_THAN_OR_EQUAL_TODAY
          || selectedOption.value === RuleOperator.LESS_THAN_TODAY
          || selectedOption.value === RuleOperator.LESS_THAN_OR_EQUAL_TODAY
        )) {
          this.hideRuleValue(dateRuleId);
        }
      }
    }
  }

  private hideRuleValue(ruleId: string) {
    const ruleElement = document.getElementById(ruleId);
    if (ruleElement) {
      const eRuleValueElements = ruleElement.getElementsByClassName('e-rule-value');
      if (eRuleValueElements.length) {
        const eRuleValueElement = eRuleValueElements[0];
        eRuleValueElement.className = eRuleValueElement.className.replace('e-show', 'e-hide');
      }
    }
  }
}

export default withTranslation()(ReportsListAdvancedFilter);
