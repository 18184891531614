import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { DialogComponent } from '@aitex/tsx-extranet-dialogs';

import { IRequestCertificateRenewalDialogProps } from '../../../interfaces/props/IDialogsProps';
import { IErrors } from '../../../common/model/errors.model';
import { certificatesService } from '../../../services';
import * as dialogUtils from '../../../utils/dialog.utils';

type RequestCertificateRenewalDialogPropsType = IRequestCertificateRenewalDialogProps & WithTranslation;

type RequestCertificateRenewalDialogStateType = IRequestCertificateRenewalDialogState;

class RequestCertificateRenewalDialog extends Component<RequestCertificateRenewalDialogPropsType, RequestCertificateRenewalDialogStateType> {
  private readonly CANCEL_BUTTON_ID = 'requestCertificateRenewalDialogCancel';
  private readonly OK_BUTTON_ID = 'requestCertificateRenewalDialogOk';

  private readonly CANNOT_REQUEST_CERTIFICATE_RENEWAL_ERROR: IErrors = { UnknownError: ['dialogs.requestCertificateRenewalDialog.error'] };

  private initialState: RequestCertificateRenewalDialogStateType = {
    isLoading: false
  };

  public constructor(props: RequestCertificateRenewalDialogPropsType) {
    super(props);

    this.state = this.initialState;
  }

  public componentDidUpdate(prevProps: RequestCertificateRenewalDialogPropsType) {
    const { visible } = this.props;

    if (prevProps.visible && !visible) {
      this.setState(this.initialState);
    }

    dialogUtils.manageButtonsClick(this.OK_BUTTON_ID, () => this.requestCertificateRenewal(), this.CANCEL_BUTTON_ID, () => this.dismiss());
  }

  public async requestCertificateRenewal() {
    const { cancellationMode, certificatesIds } = this.props;

    try {
      this.setState({ isLoading: true });

      if (!certificatesIds || !certificatesIds.length) {
        throw this.CANNOT_REQUEST_CERTIFICATE_RENEWAL_ERROR;
      }

      cancellationMode ? await certificatesService.requestRenewalCancellation(certificatesIds) : await certificatesService.requestRenewal(certificatesIds);
      this.close();
    } catch (error) {
      let errors = error as IErrors;
      if (errors.UnknownError || (!errors.UnknownError && !errors.ControlledError)) {
        errors = this.CANNOT_REQUEST_CERTIFICATE_RENEWAL_ERROR;
      }
      this.dismiss(errors);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  public close() {
    this.props.onClose();
  }

  public dismiss(reason?: IErrors) {
    this.props.onDismiss(reason);
  }

  public render() {
    const { visible, cancellationMode, certificatesIds, i18n } = this.props;

    const isMultiple = certificatesIds && certificatesIds.length > 1;

    return (
      <DialogComponent
        header={i18n.t(`dialogs.requestCertificateRenewalDialog.title${cancellationMode ? 'Cancellation' : ''}${isMultiple ? 'Multiple' : ''}`)}
        visible={visible}
        width='480px'
        footerTemplate={dialogUtils.computeFooterTemplate(
          this.OK_BUTTON_ID,
          i18n.t('actions.ok'),
          this.CANCEL_BUTTON_ID,
          i18n.t('actions.cancel'),
          this.state.isLoading
        )}
        onDismiss={() => this.dismiss()}
      >
        <div>{i18n.t(`dialogs.requestCertificateRenewalDialog.text${cancellationMode ? 'Cancellation' : ''}${isMultiple ? 'Multiple' : ''}`)}</div>
      </DialogComponent>
    );
  }
}

interface IRequestCertificateRenewalDialogState {
  isLoading: boolean;
}

export default withTranslation()(RequestCertificateRenewalDialog);
