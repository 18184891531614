export const getItem = (itemKey: string): any => {
  try {
    const serializedData = localStorage.getItem(itemKey);
    if (serializedData === null) {
      // Si no existe el elemento en el local storage, se devuelve undefined.
      return undefined;
    }
    // Si se encuentra el elemento en el local storage, se devuelve.
    return JSON.parse(serializedData);
  } catch (error: any) {
    // Si ocurre algún error, se devuelve undefined.
    return undefined;
  }
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const saveItem = (itemKey: string, item: any): void => {
  try {
    const serializedData = JSON.stringify(item);
    localStorage.setItem(itemKey, serializedData);
    return;
  } catch (error: any) {
    // Aquí se puede capturar el error y/o crear/realizar cualquier log y/o acción.
  }
};

export const removeItem = (itemKey: string): void => {
  try {
    localStorage.removeItem(itemKey);
  } catch (error: any) {
    // Aquí se puede capturar el error y/o crear/realizar cualquier log y/o acción.
  }
};

export const clean = (): void => {
  try {
    localStorage.clear();
  } catch (error: any) {
    // Aquí se puede capturar el error y/o crear/realizar cualquier log y/o acción.
  }
};

export const getExtranetToken = (itemKey: string = undefined): string => {
  try {
    if (itemKey === undefined || itemKey == null) {
      itemKey = 'oidc.user:https://login.aitex.es/:aitexexternalwebsite';
    }

    const oidcString = window.sessionStorage.getItem(itemKey);
    if (oidcString === null) {
      // Si no existe el elemento en el local storage, se devuelve undefined.
      return undefined;
    }

    // Si se encuentra el elemento en el local storage, se devuelve.
    const oidc = oidcString !== undefined && oidcString != null ? JSON.parse(oidcString) : undefined;
    const accessToken = oidc !== undefined && oidc != null ? oidc.access_token : undefined;

    return accessToken;
  } catch (error: any) {
    // Si ocurre algún error, se devuelve undefined.
    return undefined;
  }
};

