import i18next from 'i18next';

import { SourceType } from '../common/model/enumerations/sourceType.model';
import { getKeyByValue } from './enum.utils';

export const getLocalizedText = (i18n: i18next.i18n, key: string): string => {
  return i18n.t(`enums.sourceType.${key}`);
};

export const getLocalizedTextByValue = (i18n: i18next.i18n, state: number): string => {
  const key = getKeyByValue(SourceType, state);
  if (!key) {
    return '';
  }
  return getLocalizedText(i18n, key);
};

export const getIconCss = (sourceType: number): string => {
  switch (sourceType) {
    case SourceType.REPORT:
      return 'icon icon-report';
    case SourceType.REQUEST:
      return 'icon icon-request';
    default:
      return '';
  }
};
