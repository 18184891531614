import moment from 'moment';

import { ICertificate } from '../common/model/certificate.model';

export const init = (certificate: ICertificate): ICertificate => {
  return {
    ...certificate,
    expirationDate: certificate.expirationDate && moment(certificate.expirationDate),
    issueDate: certificate.issueDate && moment(certificate.issueDate),
    referenceNames: certificate.referenceNames && certificate.referenceNames.split(',').filter((x) => x.length).join(', ')
  };
};

export const canShareCertificate = (certificate: ICertificate): boolean => {
  return (certificate.reportPublished && certificate.expirationDate !== undefined && certificate.expirationDate !== null && moment().isSameOrBefore(certificate.expirationDate));
};
