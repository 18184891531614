import React, { Component, ComponentType, Fragment } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { UnregisterCallback } from 'history';
import { WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { DataManager, Query } from '@syncfusion/ej2-data';
import { SortOrder } from '@syncfusion/ej2-lists';
import { DropDownListComponent, AutoCompleteComponent, MultiSelectComponent, ChangeEventArgs as DropdownChangeEventArgs, MultiSelectChangeEventArgs, Inject as DropdownInject, CheckBoxSelection, FieldSettingsModel, FilteringEventArgs } from '@syncfusion/ej2-react-dropdowns';
import { SwitchComponent, ChangeEventArgs as ButtonChangeEventArgs } from '@syncfusion/ej2-react-buttons';
import { RichTextEditorComponent, Inject as RichTextEditorInject, Toolbar, QuickToolbar, Link, HtmlEditor, Image, ImageSettingsModel, ChangeEventArgs as RichTextEditorChangeEventArgs } from '@syncfusion/ej2-react-richtexteditor';
import { FormValidator, FormEventArgs, AsyncSettingsModel, UploadingEventArgs } from '@syncfusion/ej2-inputs';
import { UploaderComponent } from '@syncfusion/ej2-react-inputs';
import { dialogsService } from '@aitex/tsx-extranet-dialogs';
import { NotificationBodyComponent } from '@aitex/tsx-extranet-notifications-accordion';
import { ActionBarComponent } from '@aitex/tsx-extranet-action-bar';
import { showSpinner, hideSpinner } from '@syncfusion/ej2-popups';
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, Sort, Page, ExcelExport, Resize } from '@syncfusion/ej2-react-grids';

import { RootState } from '../../store';
import { INotificationDetailContainerStateProps, INotificationDetailContainerDispatchProps } from '../../interfaces/props/INotificationDetailProps';
import { INotificationDetailContainerRouteParams } from '../../interfaces/routeParams/INotificationDetailRouteParams';
import * as notificationDetailStore from '../../store/modules/notificationDetail.store';
import { URL } from '../../common/constants';
import { IStringToAnyDictionary } from '../../common/model/stringToAnyDictionary.model';
import { INotification } from '../../common/model/notification.model';
import { INotificationClassification } from '../../common/model/notificationClassification.model';
import { ICompanyCombo } from '../../common/model/company.model';
import { IMyRule } from '../../common/model/myRule.model';
import { IErrors } from '../../common/model/errors.model';
import { NotificationScope } from '../../common/model/enumerations/notificationScope.model';
import { RuleCondition } from '../../common/model/enumerations/ruleCondition.model';
import { RuleOperator } from '../../common/model/enumerations/ruleOperator.model';
import { DialogId } from '../../common/model/enumerations/dialogId.model';
import { UserType } from '../../common/model/enumerations/userType.model';
import { Validator } from '../../common/model/enumerations/validator.model';
import { UserState } from '../../common/model/enumerations/userState.model';
import { notificationsService, queryBuilderService, oDataService, dataManagerService } from '../../services';
import * as notificationUtils from '../../utils/notification.utils';
import * as formValidatorUtils from '../../utils/formValidator.utils';
import { enumToArray } from '../../utils/enum.utils';
import { notifyError, notifyErrorWithOptions, IToastOptions } from '../../utils/toast.utils';
import { debounce } from '../../utils/common.utils';
import FormError from '../../components/formError/formError';
import ValidationError from '../../components/validationError/validationError';
import TextBox from '../../components/filters/textBox';
import * as notificationClassificationUtils from '../../utils/notificationClassification.utils';
import * as userStateUtils from '../../utils/userState.utils';
import './notificationDetail.container.scss';

interface IVerifiedEmails {
  email: string;
  userState: UserState;
}

type NotificationDetailContainerPropsType = PropsFromRedux & RouteComponentProps<INotificationDetailContainerRouteParams> & WithTranslation;

class NotificationDetailContainer extends Component<NotificationDetailContainerPropsType> {
  public readonly NOTIFICATION_DETAIL_FORM_ID = 'notificationDetailForm';

  public scopeOptions: IStringToAnyDictionary[] = [];
  public scopeFields: FieldSettingsModel = { text: 'text', value: 'value' };

  public classificationsDropDownListComponent: DropDownListComponent = null;
  public classificationsQuery: Query = new Query().select(['id', 'title']).take(10);
  public classificationsFields: FieldSettingsModel = { text: 'title', value: 'id' };
  public classificationsSortOrder: SortOrder = 'Ascending';

  public companiesAutoCompleteComponent: AutoCompleteComponent;
  public companiesDataManager: DataManager = null;
  public companiesQuery: Query = new Query().select(['id', 'name']).take(10);
  public companiesFields: FieldSettingsModel = { value: 'name' };
  public initialCompaniesFields: FieldSettingsModel = { text: 'name', value: 'id' };
  public companiesSortOrder: SortOrder = 'Ascending';
  private companiesFilteringEvent: FilteringEventArgs = null;
  private companiesFilteringDebounced = debounce(
    () => {
      if (this.companiesFilteringEvent) {
        if (this.companiesFilteringEvent.text.length >= 3) {
          this.companiesAutoCompleteComponent.filter(
            this.companiesAutoCompleteComponent.dataSource,
            this.companiesAutoCompleteComponent.query.clone()
            .where('name', RuleOperator.CONTAINS, this.companiesFilteringEvent.text, true)
          );
        } else {
          return;
        }
      }
    },
    250
  );

  public reportsMultiSelectComponent: MultiSelectComponent = null;
  public reportsDataManager: DataManager = null;
  public reportsQuery: Query = new Query(); // select() is not present on purpose, take() will be set dynamically
  public reportsFields: FieldSettingsModel = { text: 'reportNumber', value: 'id' };
  public reportsSortOrder: SortOrder = 'Ascending';
  public reportsFilteringEvent: FilteringEventArgs = null;
  public reportsFilteringDebounced = debounce(
    () => {
      if (this.reportsFilteringEvent) {
        if (this.reportsFilteringEvent.text.length >= 3) {
          this.reportsMultiSelectComponent.filter(
            this.reportsMultiSelectComponent.dataSource,
            this.reportsMultiSelectComponent.query.clone().where('reportNumber',
              RuleOperator.CONTAINS,
              this.reportsFilteringEvent.text,
              true)
          );
        } else {
          return;
        }
      }
    },
    250
  );

  public requestsMultiSelectComponent: MultiSelectComponent = null;
  public requestsDataManager: DataManager = null;
  public requestsQuery: Query = new Query().select(['id', 'code']); // take() will be set dynamically
  public requestsFields: FieldSettingsModel = { text: 'code', value: 'id' };
  public requestsSortOrder: SortOrder = 'Ascending';
  public requestsFilteringEvent: FilteringEventArgs = null;
  public requestsFilteringDebounced = debounce(
    () => {
      if (this.requestsFilteringEvent) {
        if (this.requestsFilteringEvent.text.length >= 3) {
          this.requestsMultiSelectComponent.filter(
            this.requestsMultiSelectComponent.dataSource,
            this.requestsMultiSelectComponent.query.clone()
            .where('code', RuleOperator.CONTAINS, this.requestsFilteringEvent.text, true)
          );
        } else {
          return;
        }
      }
    },
    250
  );

  public usersMultiSelectComponent: MultiSelectComponent = null;
  public usersDataManager: DataManager = null;
  public usersQuery: Query = new Query().select(['email', 'type', 'companies']).where('type', RuleOperator.EQUAL, UserType.EXTERNAL);
  public usersFields: FieldSettingsModel = { text: 'email', value: 'email' };
  public usersSortOrder: SortOrder = 'Ascending';

  public richTextEditorComponent: RichTextEditorComponent = null;
  public insertImageSettings: ImageSettingsModel = {
    maxHeight: 500,
    maxWidth: 500,
    saveFormat: 'Base64'
  };

  public uploaderEmailsComponent: UploaderComponent = null;
  public asyncUploaderEmailsSettings: AsyncSettingsModel = {
    saveUrl: notificationsService.getExcelEmailVerifyUrl(),
    retryCount: 0
  };

  public uploaderComponent: UploaderComponent = null;
  public asyncSettings: AsyncSettingsModel = {
    saveUrl: notificationsService.getPostUrl(),
    retryCount: 0
  };

  public classifications: INotificationClassification[] = [];

  private formValidator: FormValidator = null;
  private isValidatingAll = false;
  private auxErrors: IErrors = {};

  private unregisterCallback: UnregisterCallback = null;

  private emailsVerified: IVerifiedEmails[];

  private errorShowed: boolean;

  public constructor(props: NotificationDetailContainerPropsType) {
    super(props);

    const { reset, i18n } = this.props;

    this.errorShowed = false;

    this.scopeOptions = enumToArray(NotificationScope, 'number', 'text', 'value')
      .filter((item) => item.value !== NotificationScope.ALL)
      .map((item) => ({ ...item, text: i18n.t('enums.notificationScope.' + item.text) }));

    this.companiesDataManager = dataManagerService.buildDataManager(URL.ODATA.COMPANY_FILTERS);

    this.reportsDataManager = dataManagerService.buildDataManager(URL.ODATA.REPORT_FILTERS);

    this.requestsDataManager = dataManagerService.buildDataManager(URL.ODATA.REQUEST_FILTERS);

    this.usersDataManager = dataManagerService.buildDataManager(URL.ODATA.USER_FILTERS);

    if (this.classifications.length === 0) {
      notificationsService.fetchNotificationClassifications().then((notificationClassifications) =>
        notificationClassifications.map((notificationClassification) => {
          notificationClassification.title = notificationClassificationUtils.getLocalizedText(i18n, notificationClassification.title);
          this.classifications.push(notificationClassification);
        })
      );
    }

    reset();
  }

  public componentDidMount(): void {
    const { makeReadyForRendering, fetchNotification, loadFromSharedAdvancedFilterNotification, location, match, i18n } = this.props;

    const isView = this.computeIsView();
    const isNew = this.computeIsNew();
    makeReadyForRendering(isView, isNew);
    if (isNew) {
      if (location.search.includes('fromSharedAdvancedFilter')) {
        loadFromSharedAdvancedFilterNotification(i18n);
      }
    } else {
      fetchNotification(match.params.id, notificationsService);
    }
  }

  public componentDidUpdate(prevProps: NotificationDetailContainerPropsType): void {
    if (this.props.isReadyForRendering && prevProps.location.pathname !== this.props.location.pathname) {
      this.props.setIsView(this.computeIsView());
      this.props.setIsNew(this.computeIsNew());
    }

    if (Object.keys(this.props.errors).length > 0 && !this.errorShowed) {
      let errorMessage = '';
      for (const property in this.props.errors) {
        if (property !== '500' && property !== '400') {
          if (property !== 'UnknownError') {
            errorMessage += property + ': ';
          }
          if (Array.isArray(this.props.errors[property])) {
            this.props.errors[property].forEach((item) => {
              errorMessage += item;
            });
          }
        }
      }

      const tOptions: IToastOptions = { toastId: 'ErrorInView' };
      notifyErrorWithOptions(errorMessage, tOptions);
      this.errorShowed = true;
      
      //notifyErrorWithOptions(errorMessage, { toastId: 'ErrorInView' });
    }

    if (prevProps.isView !== this.props.isView && this.props.isView === false) {
      this.unregisterCallback = this.props.history.block((blocker) => {
        if (this.shouldBlockNavigation()) {
          dialogsService.openDialog(DialogId.DISCARD_CHANGES)
            .then((): void => {
              if (this.unregisterCallback) {
                this.unregisterCallback();
                this.unregisterCallback = null;
              }
              this.props.resetNotification();
              setTimeout(() => this.props.history.push(blocker.pathname));
            })
            .catch(() => {
              // DO nothing
            });
          return false;
        }
      });
    }
  }

  public componentWillUnmount(): void {
    const { isReadyForRendering, reset } = this.props;

    if (isReadyForRendering) {
      reset();
    }
  }

  public configureFormValidator(): void {
    if (!this.computeIsView()) {
      if (!this.formValidator) {
        this.formValidator = formValidatorUtils.configureFormValidator(this.NOTIFICATION_DETAIL_FORM_ID, {
          Subject: { required: [true, Validator.NOT_EMPTY] },
          Scope: { required: [true, Validator.NOT_EMPTY] },
          ClassificationId: { required: [true, Validator.NOT_EMPTY] }
        });
        this.formValidator.validationComplete = (args): void => {
          formValidatorUtils.validationComplete(args as FormEventArgs, this.isValidatingAll, this.props.errors, this.auxErrors, (errors) => this.props.setErrors(errors));
        };
      }
    } else {
      this.formValidator = null;
    }
  }

  public onSubjectChange(subject: string): void {
    this.props.setNotificationSubject(subject);
  }

  public onScopeChange(e: DropdownChangeEventArgs): void {
    const { notification, setNotificationScope, setReportsIds, setRequestsIds } = this.props;

    const newScope = e.value as number;

    if (notification.scope === NotificationScope.COMPANY && newScope !== NotificationScope.COMPANY) {
      if (this.uploaderComponent) {
        this.uploaderComponent.clearAll();
      }
    }

    if (
      (notification.scope === NotificationScope.COMPANY || notification.scope === NotificationScope.REPORT || notification.scope === NotificationScope.REQUEST)
      && (newScope !== NotificationScope.COMPANY && newScope !== NotificationScope.REPORT && newScope === NotificationScope.REQUEST)
    ) {
      this.setCompany(null); // setReportsIds([]) and setRequestsIds([]) are done inside this.setCompany()
    } else {
      if (notification.scope === NotificationScope.REPORT && newScope !== NotificationScope.REPORT) {
        setReportsIds([]);
        this.computeReportMultiSelectComponentQuery();
      }
      if (notification.scope === NotificationScope.REQUEST && newScope !== NotificationScope.REQUEST) {
        setRequestsIds([]);
        this.computeRequestMultiSelectComponentQuery();
      }
    }

    if (newScope === NotificationScope.GLOBAL) {
      this.setSendEmail(false);
    }

    setNotificationScope(newScope);

    if (this.formValidator) {
      this.formValidator.validate('Scope');
    }
  }

  public onClassificationsDropDownListComponentCreated(): void {
    if (this.classificationsDropDownListComponent) {
      this.classificationsDropDownListComponent.getItems();
    }
  }

  public onClassificationChange(e: DropdownChangeEventArgs): void {
    const classification = e.itemData as INotificationClassification;
    this.props.setNotificationClassification(classification);

    if (this.formValidator) {
      this.formValidator.validate('ClassificationId');
    }
  }

  public onClassificationsDropDownListComponentDataBound(e: any): void {
    if (this.classificationsDropDownListComponent && e.items && e.items.length === 1) {
      this.classificationsDropDownListComponent.index = 0;
    }
  }

  public onIsImportantChange(e: ButtonChangeEventArgs): void {
    this.props.setNotificationIsImportant(e.checked);
  }

  public onCompanyChange(e: DropdownChangeEventArgs): void {
    const company = e.itemData as ICompanyCombo;
    this.setCompany(company);

    if (this.props.errors.CompanyId && company) {
      const { CompanyId, ...restOfErrors } = this.props.errors;
      this.props.setErrors(restOfErrors);
    }
  }

  public onCompaniesAutoCompleteComponentFocus(): void {
    this.companiesAutoCompleteComponent.fields = this.companiesFields;
  }

  public onReportMultiSelectComponentCreated(): void {
    this.computeReportMultiSelectComponentQuery();
  }

  public onReportMultiSelectComponentChange(e: MultiSelectChangeEventArgs): void {
    const reportsIds = e.value as string[];
    this.props.setReportsIds(reportsIds);

    if (this.props.errors.NotificationsReports && reportsIds.length) {
      const { NotificationsReports, ...restOfErrors } = this.props.errors;
      this.props.setErrors(restOfErrors);
    }
  }

  public onReportMultiSelectComponentDataBound(): void {
    this.reportsMultiSelectComponent.value = this.props.reportsIds;
  }

  public onRequestMultiSelectComponentCreated(): void {
    this.computeRequestMultiSelectComponentQuery();
  }

  public onRequestMultiSelectComponentChange(e: MultiSelectChangeEventArgs): void {
    const requestsIds = e.value as string[];
    this.props.setRequestsIds(requestsIds);

    if (this.props.errors.NotificationsRequests && requestsIds.length) {
      const { NotificationsRequests, ...restOfErrors } = this.props.errors;
      this.props.setErrors(restOfErrors);
    }
  }

  public onRequestMultiSelectComponentDataBound(): void {
    this.requestsMultiSelectComponent.value = this.props.requestsIds;
  }

  public onSendEmailChange(e: ButtonChangeEventArgs): void {
    this.setSendEmail(e.checked);
  }

  public onUserMultiSelectComponentCreated(): void {
    this.fetchEmailDestinations();
  }

  public onUserMultiSelectComponentChange(e: MultiSelectChangeEventArgs): void {
    this.props.setSelectedEmailDestinations(e.value as string[]);
  }

  public onBodyChange(e: RichTextEditorChangeEventArgs): void {
    if (this.props.errors.Body && e.value.trim().length) {
      const { Body, ...restOfErrors } = this.props.errors;
      this.props.setErrors(restOfErrors);
    }
  }

  public getReportMultiSelectComponentPlaceholder(): string {
    return this.isReportMultiSelectComponentEnabled() ? '' : this.props.i18n.t('notificationDetail.messages.selectACompanyFirst');
  }

  public getReportsText(): string {
    return this.props.notification.notificationsReports.map((notificationReport) => notificationReport.reportNumber).join(', ');
  }

  public isReportMultiSelectComponentEnabled(): boolean {
    return this.props.company !== null;
  }

  public getRequestMultiSelectComponentPlaceholder(): string {
    return this.isRequestMultiSelectComponentEnabled() ? '' : this.props.i18n.t('notificationDetail.messages.selectACompanyFirst');
  }

  public getRequestsText(): string {
    return this.props.notification.notificationsRequests.map((notificationRequest) => notificationRequest.requestCode).join(', ');
  }

  public isRequestMultiSelectComponentEnabled(): boolean {
    return this.props.company !== null;
  }

  public getUserMultiSelectComponentPlaceholder(): string {
    return this.isUserMultiSelectComponentEnabled() ? '' : this.props.i18n.t('notificationDetail.messages.selectACompanyFirst');
  }

  public getEmailDestinationsText(): string {
    return this.props.notification.emailDestinations.split(';').join('; ');
  }

  public isUserMultiSelectComponentEnabled(): boolean {
    return this.props.company !== null;
  }

  public isSaveButtonEnabled(): boolean {
    return !this.props.errors.Subject && !this.props.errors.Scope && !this.props.errors.ClassificationId && !this.props.isSaving;
  }

  public copy(): void {
    this.props.history.replace(this.props.location.pathname.replace('/view', '/copy'));
  }

  public save(): void {
    const { notification, createNotification, history } = this.props;

    if (!this.validateForm() || !this.checkNotificationIsValid()) {
      return;
    }

    if (notification.scope === NotificationScope.COMPANY && !this.uploaderComponent) {
      return;
    }

    const currentUploaderComponent = this.uploaderComponent;

    dialogsService.openDialog(DialogId.GENERIC_CONFIRMATION)
      .then((): void => {
        this.errorShowed = false;

        if (notification.scope === NotificationScope.COMPANY && currentUploaderComponent.fileList.length > 0) {
          if (document.getElementsByClassName('e-icons e-file-reload-btn').length) {
            currentUploaderComponent.retry();
          } else {
            currentUploaderComponent.upload();
          }
        } else {
          createNotification(this.computeNotificationToSave(), notificationsService, this.unregisterCallback, history);
        }

        this.formValidator = null;
      })
      .catch(() => {
        // DO nothing
      });
  }

  public cancel(): void {
    if (this.props.location.pathname.includes('/new')) {
      if (this.props.notification.id) {
        this.props.history.replace(this.props.location.pathname.replace('/new', `/${this.props.notification.id}/view`));
      } else {
        this.props.history.push('/notifications-list');
      }
    } else {
      this.props.history.replace(this.props.location.pathname.replace('/edit', '/view'));
    }
  }

  public onUploading(args: UploadingEventArgs): void {
    const { accessToken, onUploading } = this.props;

    onUploading();

    args.currentRequest.setRequestHeader('Authorization', `Bearer ${accessToken}`);

    const notificationToSave = this.computeNotificationToSave();
    args.customFormData = [
      { subject: notificationToSave.subject },
      { scope: notificationToSave.scope },
      { classificationId: notificationToSave.classificationId },
      { isImportant: notificationToSave.isImportant },
      { body: notificationToSave.body },
      { companyId: notificationToSave.companyId },
      { emailDestinations: notificationToSave.emailDestinations }
    ];
  }

  public onUploadSuccess(e: any): void {
    const { onUploadSuccess, history } = this.props;

    const createdNotification: INotification = JSON.parse(e.e.currentTarget.response);

    onUploadSuccess(createdNotification);

    if (this.unregisterCallback) {
      this.unregisterCallback();
    }
    history.push(`/notification/${createdNotification.id}/view`);
    this.formValidator = null;
  }

  public onUploadFailure(e: any): void {
    const errors: IErrors = JSON.parse((e.e as ProgressEvent<XMLHttpRequest>).target.responseText).errors as IErrors;
    this.props.onUploadFailure(errors);
  }

  public async downloadAttachedFiled(notification: INotification): Promise<void> {
    try {
      showSpinner(document.getElementById('root'));

      const fileName = notification.attachedDocumentPath.substring(notification.attachedDocumentPath.lastIndexOf('/') + 1);
      await notificationsService.downloadFile(notification.id, fileName);
    } catch (error) {
      notifyError(this.props.i18n.t('notificationDetail.errors.cannotDownloadAttachedFile'));
    } finally {
      hideSpinner(document.getElementById('root'));
    }
  }

  public onUploadingExcelEmails(args: UploadingEventArgs): void {
    const { accessToken } = this.props;

    args.currentRequest.setRequestHeader('Authorization', `Bearer ${accessToken}`);
  }

  public onUploadExcelEmailsSuccess(event: any): void {
    const { setSelectedEmailDestinations } = this.props;

    const e = event.e;
    const target = e.target;
    const response = target.response;
    const obj = JSON.parse(response);

    if (Array.isArray(obj)) {
      this.emailsVerified = obj;
      const validEmails = obj.filter((element) => {
        return element.userState === UserState.OK;
      });

      if (validEmails) {
        setSelectedEmailDestinations(validEmails.map((element) => element.email));
      }
    }
  }

  public getStatusColumnTemplate(verifiedEmail: IVerifiedEmails): JSX.Element {
    let color = '';
    if (verifiedEmail.userState === UserState.OK) {
      color = 'green';
    } else if (verifiedEmail.userState !== UserState.UNKNOW) {
      color = 'red';
    }

    return <span style={{color: `${color}`}} title={userStateUtils.getLocalizedText(this.props.i18n, UserState[verifiedEmail.userState])} className={userStateUtils.getIconCss(verifiedEmail.userState)} />;
  }

  public getInfoLabel(verifiedEmail: IVerifiedEmails): string {
    return this.props.i18n.t('enums.userState.' + UserState[verifiedEmail.userState]);
  }

  public render(): JSX.Element {
    const { isReadyForRendering, notification, isView, isNew, company, sendEmail, emailDestinations, selectedEmailDestinations, isSaving, errors, i18n } = this.props;

    if (!isReadyForRendering) {
      return null;
    }

    if (!isNew && !notification.id) {
      return null; // TODO: loading
    }

    let title = <h2>{i18n.t('notificationDetail.view.title')}</h2>;
    if (!isView) {
      title = isNew ? <h2>{i18n.t('notificationDetail.new.title')}</h2> : <h2>{i18n.t('notificationDetail.copy.title')}</h2>;
    }

    return (
      <Fragment>
        {title}
        <div className='notification-detail-card'>
          <form id={this.NOTIFICATION_DETAIL_FORM_ID} ref={(): void => this.configureFormValidator()}>
            <FormError errors={errors}/>
            <div className='row'>
              <div className='col'>
                <div className='form-group'>
                  <div className='autocomplete-input'>
                    <div className='autocomplete-label'>{i18n.t('notification.subject')}</div>
                    <TextBox
                      id='subject'
                      name='Subject'
                      value={notification.subject}
                      readonly={isView}
                      onChange={(value): void => this.onSubjectChange(value)}
                    />
                  </div>
                  <ValidationError errors={errors} errorKey={'Subject'}/>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12 col-lg-5'>
                <div className='form-group'>
                  <div className='autocomplete-input'>
                    <div className='autocomplete-label'>{i18n.t('notification.scope')}</div>
                    <DropDownListComponent
                      id='scope'
                      name='Scope'
                      dataSource={this.scopeOptions}
                      fields={this.scopeFields}
                      value={notification.scope}
                      enabled={isNew}
                      change={(e): void => this.onScopeChange(e)}
                    />
                  </div>
                  <ValidationError errors={errors} errorKey={'Scope'}/>
                </div>
              </div>
              <div className='col-12 col-lg-5'>
                <div className='form-group'>
                  <div className='autocomplete-input'>
                    <div className='autocomplete-label'>{i18n.t('notification.classification')}</div>
                    <DropDownListComponent
                      id='classificationId'
                      name='ClassificationId'
                      query={this.classificationsQuery}
                      dataSource={this.classifications as IStringToAnyDictionary[]}
                      fields={this.classificationsFields}
                      sortOrder={this.classificationsSortOrder}
                      value={notification.classificationId}
                      enabled={!isView}
                      ref={(dropDownList: DropDownListComponent): DropDownListComponent => this.classificationsDropDownListComponent = dropDownList}
                      created={(): void => this.onClassificationsDropDownListComponentCreated()}
                      change={(e): void => this.onClassificationChange(e)}
                      dataBound={(e): void => this.onClassificationsDropDownListComponentDataBound(e)}
                    />
                  </div>
                  <ValidationError errors={errors} errorKey={'ClassificationId'}/>
                </div>
              </div>
              <div className='col-12 col-lg-2'>
                <div className='form-group d-flex' style={{ marginTop: '7px' }}>
                  <label htmlFor='isImportant' className='mr-2'>{i18n.t('notification.isImportant')}</label>
                  <div style={{ marginTop: '0.15rem' }}>
                    <SwitchComponent
                      id='isImportant'
                      checked={notification.isImportant}
                      disabled={isView}
                      change={(e): void => this.onIsImportantChange(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
            {notification.scope !== NotificationScope.GLOBAL && notification.scope !== NotificationScope.BULKCUSTOM && <div className='row'>
              <div className={'col-12 ' + (notification.scope === NotificationScope.COMPANY ? 'col-lg-10' : 'col-lg-5')}>
                <div className='form-group'>
                  <div className='autocomplete-input'>
                    <div className='autocomplete-label'>{i18n.t('notification.company')}</div>
                    {!isNew ?
                      <TextBox
                        id='companyId'
                        name='CompanyId'
                        value={notification.companyName}
                        readonly={true}
                      />
                      :
                      <AutoCompleteComponent
                        id='companyId'
                        name='CompanyId'
                        query={this.companiesQuery}
                        dataSource={this.companiesDataManager}
                        fields={this.initialCompaniesFields}
                        sortOrder={this.companiesSortOrder}
                        allowCustom={false}
                        value={company && company.name}
                        ref={(autoComplete: AutoCompleteComponent): AutoCompleteComponent => this.companiesAutoCompleteComponent = autoComplete}
                        focus={(): void => this.onCompaniesAutoCompleteComponentFocus()}
                        change={(e): void => this.onCompanyChange(e)}
                        filtering={(e): void => {
                          this.companiesFilteringEvent = e;
                          this.companiesFilteringEvent.cancel = true;
                          this.companiesFilteringDebounced();
                        }}
                      />
                    }
                  </div>
                  <ValidationError errors={errors} errorKey={'CompanyId'} />
                </div>
              </div>
              {notification.scope === NotificationScope.REPORT && <div className='col-12 col-lg-5'>
                <div className='form-group'>
                  <div className='autocomplete-input'>
                    <div className='autocomplete-label'>{i18n.t('notification.notificationsReports')}</div>
                    {!isNew ?
                      <TextBox
                        id='notificationsReports'
                        name='NotificationsReports'
                        value={this.getReportsText()}
                        readonly={true}
                      />
                      :
                      <MultiSelectComponent
                        id='notificationsReports'
                        name='NotificationsReports'
                        query={null} /* query will be set dynamically */
                        dataSource={this.reportsDataManager}
                        fields={this.reportsFields}
                        sortOrder={this.reportsSortOrder}
                        mode='CheckBox'
                        placeholder={this.getReportMultiSelectComponentPlaceholder()}
                        readonly={!this.isReportMultiSelectComponentEnabled()}
                        ref={(multiSelect: MultiSelectComponent): MultiSelectComponent => this.reportsMultiSelectComponent = multiSelect}
                        created={(): void=> this.onReportMultiSelectComponentCreated()}
                        change={(e): void => this.onReportMultiSelectComponentChange(e)}
                        dataBound={(): void => this.onReportMultiSelectComponentDataBound()}
                        filtering={(e): void => {
                          this.reportsFilteringEvent = e;
                          this.reportsFilteringEvent.cancel = true;
                          this.reportsFilteringDebounced();
                        }}
                      >
                        <DropdownInject services={[CheckBoxSelection]} />
                      </MultiSelectComponent>
                    }
                  </div>
                  <ValidationError errors={errors} errorKey={'NotificationsReports'} />
                </div>
              </div>}
              {notification.scope === NotificationScope.REQUEST && <div className='col-12 col-lg-5'>
                <div className='form-group'>
                  <div className='autocomplete-input'>
                    <div className='autocomplete-label'>{i18n.t('notification.notificationsRequests')}</div>
                    {!isNew ?
                      <TextBox
                        id='notificationsRequests'
                        name='NotificationsRequests'
                        value={this.getRequestsText()}
                        readonly={true}
                      />
                      :
                      <MultiSelectComponent
                        id='notificationsRequests'
                        name='NotificationsRequests'
                        query={null} /* query will be set dynamically */
                        dataSource={this.requestsDataManager}
                        fields={this.requestsFields}
                        sortOrder={this.requestsSortOrder}
                        mode='CheckBox'
                        placeholder={this.getRequestMultiSelectComponentPlaceholder()}
                        readonly={!this.isRequestMultiSelectComponentEnabled()}
                        ref={(multiSelect: MultiSelectComponent): MultiSelectComponent => this.requestsMultiSelectComponent = multiSelect}
                        created={(): void => this.onRequestMultiSelectComponentCreated()}
                        change={(e): void => this.onRequestMultiSelectComponentChange(e)}
                        dataBound={(): void => this.onRequestMultiSelectComponentDataBound()}
                        filtering={(e): void => {
                          this.requestsFilteringEvent = e;
                          this.requestsFilteringEvent.cancel = true;
                          this.requestsFilteringDebounced();
                        }}
                      >
                        <DropdownInject services={[CheckBoxSelection]} />
                      </MultiSelectComponent>
                    }
                  </div>
                  <ValidationError errors={errors} errorKey={'NotificationsRequests'} />
                </div>
              </div>}
              <div className={`col-12 col-lg-2 ${company === null ? 'd-none' : ''}`}>
                <div className='form-group d-flex' style={{ marginTop: '7px' }}>
                  <label htmlFor='sendEmail' className='mr-2'>{i18n.t('notificationDetail.actions.sendEmail')}</label>
                  <div style={{ marginTop: '0.15rem' }}>
                    <SwitchComponent
                      id='sendEmail'
                      checked={sendEmail}
                      disabled={isView}
                      change={(e): void => this.onSendEmailChange(e)}
                    />
                  </div>
                </div>
              </div>
            </div>}
            {sendEmail && <div className='row'>
              <div className='col'>
                <div className='form-group'>
                  <div className='autocomplete-input'>
                    <div className='autocomplete-label'>{i18n.t('notification.emailDestinations')}</div>
                    {isView ?
                      <TextBox
                        id='emailDestinations'
                        name='EmailDestinations'
                        value={this.getEmailDestinationsText()}
                        readonly={true}
                      />
                      :
                      <MultiSelectComponent
                        id='emailDestinations'
                        name='EmailDestinations'
                        dataSource={emailDestinations}
                        fields={this.usersFields}
                        sortOrder={this.usersSortOrder}
                        value={selectedEmailDestinations}
                        mode='CheckBox'
                        filterType='Contains'
                        placeholder={this.getUserMultiSelectComponentPlaceholder()}
                        readonly={!this.isUserMultiSelectComponentEnabled()}
                        ref={(multiSelect: MultiSelectComponent): MultiSelectComponent => this.usersMultiSelectComponent = multiSelect}
                        created={(): void => this.onUserMultiSelectComponentCreated()}
                        change={(e): void => this.onUserMultiSelectComponentChange(e)}
                      >
                        <DropdownInject services={[CheckBoxSelection]} />
                      </MultiSelectComponent>
                    }
                  </div>
                </div>
              </div>
            </div>}
            {notification.scope === NotificationScope.BULKCUSTOM && <Fragment>
                                                                      <div className='row'>
                                                                        <div className='col'>
                                                                          <div className='form-group'>
                                                                            <div className='autocomplete-input'>
                                                                              <div className='autocomplete-label'>{i18n.t('notification.emailDestinations')}</div>
                                                                              <TextBox
                                                                                id='emailDestinations'
                                                                                name='EmailDestinations'
                                                                                value={selectedEmailDestinations != null ? selectedEmailDestinations.join('; ') : notification.emailDestinations != null ? this.getEmailDestinationsText() : ''}
                                                                                readonly={true}
                                                                              />
                                                                              <a className='btn btn-sm btn-light' href='/files/Emails.xlsx' title={i18n.t('notification.emailsTemplate')}>
                                                                                <span className='icon icon-file-excel-o' />
                                                                              </a>
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                      {!isView && <Fragment>
                <div className='row'>
                  <div className='col'>
                    <div className='form-group'>
                      <UploaderComponent
                        name='file'
                        multiple={false}
                        autoUpload={true}
                        asyncSettings={this.asyncUploaderEmailsSettings}
                        allowedExtensions='.xlsx'
                        maxFileSize={25000000}
                        locale={i18n.language}
                        ref={(uploader: UploaderComponent): UploaderComponent => this.uploaderEmailsComponent = uploader}
                        uploading={(args): void => this.onUploadingExcelEmails(args)}
                        success={(e): void => this.onUploadExcelEmailsSuccess(e as any)}
                      />
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col'>
                    <div className='form-group notifications-grid'>
                      <GridComponent
                        dataSource={this.emailsVerified}
                        allowSorting={true}
                        allowPaging={true}   /* Si no se incluye este paginador (oculto por css), no se muestra el dropdown en el customizado */
                        allowExcelExport={true}
                        allowResizing={true}
                        locale={i18n.language}
                      >
                        <ColumnsDirective>
                          <ColumnDirective
                            headerText={this.props.i18n.t('notification.emailsGrid.email')}
                            field='email'
                            width='200'
                          />
                          <ColumnDirective
                            headerText={this.props.i18n.t('notification.emailsGrid.status')}
                            field='userState'
                            width='25'
                            textAlign='Center' 
                            template={(verifiedEmail: IVerifiedEmails) => this.getStatusColumnTemplate(verifiedEmail)}
                          />
                          <ColumnDirective
                            headerText={this.props.i18n.t('notification.emailsGrid.info')}
                            field='userState'
                            width='150'
                            valueAccessor={(_field, data) => this.getInfoLabel(data as IVerifiedEmails)}
                          />
                        </ColumnsDirective>
                        <Inject services={[Sort, Page, Toolbar, ExcelExport, Resize]} />
                      </GridComponent>
                    </div>
                  </div>
                </div>
              </Fragment>}
                                                                    </Fragment>}
<div className='row'>
  <div className='col'>
    <div className='form-group'>
      {isView ?
                    <NotificationBodyComponent body={notification.body} notificationsReports={[]} notificationsRequests={[]} />
                    :
                    <Fragment>
                      <label htmlFor='body'>{i18n.t('notification.body')}</label>
                      <RichTextEditorComponent
                        id='body'
                        name='Body'
                        value={notification.body}
                        insertImageSettings={this.insertImageSettings}
                        ref={(richTextEditor: RichTextEditorComponent): RichTextEditorComponent => this.richTextEditorComponent = richTextEditor}
                        change={(e): void => this.onBodyChange(e)}
                      >
                        <RichTextEditorInject services={[Toolbar, QuickToolbar, Link, HtmlEditor, Image]} />
                      </RichTextEditorComponent>
                      <ValidationError errors={errors} errorKey={'Body'} />
                    </Fragment>
                  }
    </div>
  </div>
</div>
{isView && notification.attachedDocumentPath && <div className='row'>
              <div className='col'>
                <div className='form-group'>
                  <label>{i18n.t('notification.attachedFile')}:</label>
                  <a onClick={() => { this.downloadAttachedFiled(notification) }} title={i18n.t('notification.attachedFile')} ><span className="icon icon-request" /></a>
                </div>
              </div>
            </div>}
</form>
          {(!isView && notification.scope === NotificationScope.COMPANY) && <div className='row'> {/* Must be outside <form> (https://www.syncfusion.com/forums/144130/all-files-are-deleted-from-uploader-list) */}
            <div className='col'>
              <div className='form-group'>
                <UploaderComponent
                  id='files'
                  name='Files'
                  autoUpload={false}
                  asyncSettings={this.asyncSettings}
                  allowedExtensions='.pdf,.doc,.docx,.xls,.xlsx'
                  maxFileSize={25000000}
                  locale={i18n.language}
                  ref={(uploader: UploaderComponent): UploaderComponent => this.uploaderComponent = uploader}
                  uploading={(args): void => this.onUploading(args)}
                  success={(data): void => this.onUploadSuccess(data as any)}
                  failure={(e): void => this.onUploadFailure(e as any)}
                />
              </div>
            </div>
          </div>}
        </div>
        <ActionBarComponent
          disabled={!this.isSaveButtonEnabled()}
          editing={isView === false}
          loading={isSaving}
          withoutTopBorder={true}
          editButtonText={i18n.t('notificationDetail.actions.copy')}
          editButtonIconCss='icon icon-app-item-copy'
          onEditButtonClick={isNew ? undefined : (): void => this.copy()}
          onSaveButtonClick={(): void => this.save()}
          onCancelButtonClick={(): void => this.cancel()}
        />
      </Fragment >
);
}

private computeIsView(): boolean {
    return this.props.location.pathname.includes('/view');
  }

private computeIsNew(): boolean {
    return this.props.location.pathname.includes('/new');
  }

private setCompany(company: ICompanyCombo) {
    const { setReportsIds, setRequestsIds, setCompany } = this.props;

    setReportsIds([]);
    setRequestsIds([]);
    this.setSendEmail(false);
    setCompany(company);

    this.computeReportMultiSelectComponentQuery();
    this.computeRequestMultiSelectComponentQuery();
  }

private setSendEmail(sendEmail: boolean) {
    const { setEmailDestinations, setSelectedEmailDestinations, setSendEmail } = this.props;

    setEmailDestinations([]);
    setSelectedEmailDestinations([]);
    setSendEmail(sendEmail);
  }

private computeReportMultiSelectComponentQuery() {
    if (this.reportsMultiSelectComponent) {
      let query = this.reportsQuery.clone();
      if (this.isReportMultiSelectComponentEnabled()) {
        query = query
          .where('clientId', RuleOperator.EQUAL, this.props.company.id)
          .take(10);
      } else {
        query = query.take(0);
      }
      this.reportsMultiSelectComponent.query = query;
    }
  }

private computeRequestMultiSelectComponentQuery() {
    if (this.requestsMultiSelectComponent) {
      let query = this.requestsQuery.clone();
      if (this.isRequestMultiSelectComponentEnabled()) {
        query = query
          .where('companyId', RuleOperator.EQUAL, this.props.company.id)
          .take(10);
      } else {
        query = query.take(0);
      }
      this.requestsMultiSelectComponent.query = query;
    }
  }

private fetchEmailDestinations() {
    const { company, fetchEmailDestinations } = this.props;

    if (!this.isUserMultiSelectComponentEnabled()) {
      return;
    }

    let query = this.usersQuery.clone();
    const myRule: IMyRule = {
      condition: RuleCondition.ANY,
      field: 'companies/companyId',
      operator: RuleOperator.EQUAL,
      value: company.id
    };
    query = queryBuilderService.buildQuery(this.usersDataManager, myRule, query);
    fetchEmailDestinations(oDataService, this.usersDataManager, query);
  }

private shouldBlockNavigation(): boolean {
    const { notification, notificationInitial, company, reportsIds, requestsIds, sendEmail, selectedEmailDestinations } = this.props;

    const notificationInitialReportsIds = notificationUtils.computeReportsIds(notificationInitial);
    const notificationInitialRequestsIds = notificationUtils.computeRequestsIds(notificationInitial);
    const notificationInitialSendEmail = notificationUtils.computeSendEmail(notificationInitial);
    const notificationInitialSelectedEmailDestinations = notificationUtils.computeSelectedEmailDestinations(notificationInitial);
    return (
      notification.subject !== notificationInitial.subject
      || notification.scope !== notificationInitial.scope
      || notification.classificationId !== notificationInitial.classificationId
      || notification.isImportant !== notificationInitial.isImportant
      || (company && company.id) !== notificationInitial.companyId
      || (reportsIds.length !== notificationInitialReportsIds.length || notificationInitialReportsIds.some((reportId) => !reportsIds.includes(reportId)))
      || (requestsIds.length !== notificationInitialRequestsIds.length || notificationInitialRequestsIds.some((requestId) => !requestsIds.includes(requestId)))
      || sendEmail !== notificationInitialSendEmail
      || (selectedEmailDestinations.length !== notificationInitialSelectedEmailDestinations.length || notificationInitialSelectedEmailDestinations.some((email) => !selectedEmailDestinations.includes(email)))
      || notification.body !== notificationInitial.body
    );
  }

private validateForm(): boolean {
    if (!this.formValidator) {
      return true;
    }

    this.isValidatingAll = true;
    const isFormValid = this.formValidator.validate();
    this.isValidatingAll = false;
    if (!isFormValid) {
      this.props.setErrors(this.auxErrors);
      this.auxErrors = {};
    }
    return isFormValid;
  }

private checkNotificationIsValid(): boolean {
    const { notification, company, reportsIds, requestsIds, sendEmail, selectedEmailDestinations, i18n } = this.props;

    if (notification.scope === NotificationScope.COMPANY || notification.scope === NotificationScope.REPORT || notification.scope === NotificationScope.REQUEST) {
      if (!company) {
        notifyError(i18n.t('notificationDetail.errors.emptyCompany'));
        return false;
      }

      if (notification.scope === NotificationScope.REPORT && !reportsIds.length) {
        notifyError(i18n.t('notificationDetail.errors.emptyReports'));
        return false;
      }

      if (notification.scope === NotificationScope.REQUEST && !requestsIds.length) {
        notifyError(i18n.t('notificationDetail.errors.emptyRequests'));
        return false;
      }
    }

    if (sendEmail && !selectedEmailDestinations.length) {
      notifyError(i18n.t('notificationDetail.errors.emptyEmailDestinations'));
      return false;
    }

    if (!this.richTextEditorComponent || !this.richTextEditorComponent.getText().trim().length) {
      notifyError(i18n.t('notificationDetail.errors.emptyBody'));
      return false;
    }

    return true;
  }

private computeNotificationToSave(): INotification {
    const { notification, company, reportsIds, requestsIds, sendEmail, selectedEmailDestinations } = this.props;

    return {
      ...notification,
      id: null,
      body: this.richTextEditorComponent.getHtml(),
      companyId: company && company.id,
      companyName: company && company.name,
      emailDestinations: sendEmail || notification.scope === NotificationScope.BULKCUSTOM ? selectedEmailDestinations.join(';') : null,
      notificationsReports: reportsIds.map((reportId) => ({ id: null, notificationId: null, reportId, reportNumber: null })),
      notificationsRequests: requestsIds.map((requestId) => ({ id: null, notificationId: null, requestId, requestCode: null }))
    };
  }
}

const mapStateToProps = (state: RootState): INotificationDetailContainerStateProps => ({
isReadyForRendering: state.notificationDetailStore.isReadyForRendering,
notification: state.notificationDetailStore.notification,
notificationInitial: state.notificationDetailStore.notificationInitial,
isView: state.notificationDetailStore.isView,
isNew: state.notificationDetailStore.isNew,
company: state.notificationDetailStore.company,
reportsIds: state.notificationDetailStore.reportsIds,
requestsIds: state.notificationDetailStore.requestsIds,
sendEmail: state.notificationDetailStore.sendEmail,
emailDestinations: state.notificationDetailStore.emailDestinations,
selectedEmailDestinations: state.notificationDetailStore.selectedEmailDestinations,
isSaving: state.notificationDetailStore.isSaving,
errors: state.notificationDetailStore.errors,
accessToken: state.currentUserStore.accessToken
});

const mapDispatchToProps: INotificationDetailContainerDispatchProps = {
  reset: notificationDetailStore.reset,
  makeReadyForRendering: notificationDetailStore.makeReadyForRendering,
  setNotificationSubject: notificationDetailStore.setNotificationSubject,
  setNotificationScope: notificationDetailStore.setNotificationScope,
  setNotificationClassification: notificationDetailStore.setNotificationClassification,
  setNotificationIsImportant: notificationDetailStore.setNotificationIsImportant,
  setIsView: notificationDetailStore.setIsView,
  setIsNew: notificationDetailStore.setIsNew,
  setCompany: notificationDetailStore.setCompany,
  setReportsIds: notificationDetailStore.setReportsIds,
  setRequestsIds: notificationDetailStore.setRequestsIds,
  setSendEmail: notificationDetailStore.setSendEmail,
  setEmailDestinations: notificationDetailStore.setEmailDestinations,
  setSelectedEmailDestinations: notificationDetailStore.setSelectedEmailDestinations,
  setErrors: notificationDetailStore.setErrors,
  onUploading: notificationDetailStore.onUploading,
  onUploadSuccess: notificationDetailStore.onUploadSuccess,
  onUploadFailure: notificationDetailStore.onUploadFailure,
  fetchNotification: notificationDetailStore.fetchNotification,
  loadFromSharedAdvancedFilterNotification: notificationDetailStore.loadFromSharedAdvancedFilterNotification,
  resetNotification: notificationDetailStore.resetNotification,
  fetchEmailDestinations: notificationDetailStore.fetchEmailDestinations,
  createNotification: notificationDetailStore.createNotification
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

                                    export default compose(
                                    withRouter,
                                    withTranslation(),
                                    connector
                                    )(NotificationDetailContainer) as ComponentType;
