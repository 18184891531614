import { Log, User, UserManager } from 'oidc-client';
import { settings } from '../services/settings.service';
import JWT from 'jwt-decode';
import { removeItem } from '../utils/localStorageManagement';

class AuthService {
  public userManager: UserManager;

  public config: Oidc.UserManagerSettings = {
    client_id: 'aitexexternalwebsite',
    redirect_uri: window.location.protocol + '//' + window.location.host + '/signin-callback.html',
    silent_redirect_uri: window.location.protocol + '//' + window.location.host + '/silent-renew.html',
    // tslint:disable-next-line:object-literal-sort-keys
    post_logout_redirect_uri: window.location.protocol + '//' + window.location.host + '/index.html',
    response_type: 'id_token token',
    scope: 'openid profile aitex.extranet.api aitex.extranet.website'
  };

  public constructor() {
    // TODO: Get settings from BE
    Log.logger = console;
    Log.level = Log.INFO;
  }

  public init() {
    this.config.authority = settings.identity;
    this.userManager = new UserManager(this.config);
  }

  public getUser(): Promise<any> {
    const user = this.userManager.getUser().then((u) => {
      if (!u) {
        return null;
      }

      const decoded: any = JWT(u.access_token);
      return { ...u, email: decoded.email, userType: parseInt(decoded.usertype, 10), companyId: decoded.companyid, role: decoded.role };
    });
    return user;
  }

  public login(): Promise<void> {
    return this.userManager.signinRedirect({ state: window.location.href.endsWith('index.html') ? '' : window.location.href });
  }

  public renewToken(): Promise<User> {
    return this.userManager.signinSilent();
  }

  public logout(): Promise<void> {
    removeItem('state');
    return this.userManager.signoutRedirect();
  }
}

export const authService = new AuthService();
