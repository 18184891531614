import { Action, Reducer, ActionCreator, combineReducers } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { batch } from 'react-redux';

import { RootState } from '..';
import { ICurrentUserState } from '../../interfaces/states/ICurrentUserState';
import { IUser } from '../../common/model/user.model';
import { ICurrentUser } from '../../common/model/currentUser.model';
import { IJwtInfo } from '../../common/model/jwtInfo.model';
import { ICompany } from '../../common/model/company.model';
import { IErrors } from '../../common/model/errors.model';
import { IUsersService, ICompaniesService } from '../../services';

enum Type {
  RESET = '@@currentUser/RESET',
  SET_ACCESS_TOKEN = '@@currentUser/SET_ACCESS_TOKEN',
  SET_USER = '@@currentUser/SET_USER',
  SET_JWT_INFO = '@@currentUser/SET_JWT_INFO',
  SET_HAS_CERTIFICATES = '@@currentUser/SET_HAS_CERTIFICATES',
  SET_COMPANY = '@@currentUser/SET_COMPANY',
  SET_ERRORS = '@@currentUser/SET_ERRORS'
}

const initialState: ICurrentUserState = {
  accessToken: null,
  isAnonymous: null,
  user: null,
  jwtInfo: null,
  hasCertificates: false,
  company: null,
  errors: null
};

// Actions

export type Actions = ResetAction | SetAccessTokenAction | SetUserAction | SetJwtInfoAction | SetHasCertificatesAction | SetCompanyAction | SetErrorsAction;

export type ResetAction = Action<Type.RESET>

export interface SetAccessTokenAction extends Action<Type.SET_ACCESS_TOKEN> {
  accessToken: string;
}

export interface SetUserAction extends Action<Type.SET_USER> {
  user: IUser;
}

export interface SetJwtInfoAction extends Action<Type.SET_JWT_INFO> {
  jwtInfo: IJwtInfo;
}

export interface SetHasCertificatesAction extends Action<Type.SET_HAS_CERTIFICATES> {
  hasCertificates: boolean;
}

export interface SetCompanyAction extends Action<Type.SET_COMPANY> {
  company: ICompany;
}

export interface SetErrorsAction extends Action<Type.SET_ERRORS> {
  errors: IErrors;
}

export const reset: ActionCreator<ResetAction> = () => ({
  type: Type.RESET
});

export const setAccessToken: ActionCreator<SetAccessTokenAction> = (accessTokenLocal: string) => ({
  type: Type.SET_ACCESS_TOKEN,
  accessToken: accessTokenLocal
});

export const setUser: ActionCreator<SetUserAction> = (userLocal: IUser) => ({
  type: Type.SET_USER,
  user: userLocal
});

export const setJwtInfo: ActionCreator<SetJwtInfoAction> = (jwtInfoLocal: IJwtInfo) => ({
  type: Type.SET_JWT_INFO,
  jwtInfo: jwtInfoLocal
});

export const setHasCertificates: ActionCreator<SetHasCertificatesAction> = (hasCertificatesLocal: boolean) => ({
  type: Type.SET_HAS_CERTIFICATES,
  hasCertificates: hasCertificatesLocal
});

export const setCompany: ActionCreator<SetCompanyAction> = (companyLocal: ICompany) => ({
  type: Type.SET_COMPANY,
  company: companyLocal
});

export const setErrors: ActionCreator<SetErrorsAction> = (errorsLocal: IErrors) => ({
  type: Type.SET_ERRORS,
  errors: errorsLocal
});

export const setCurrentUser: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (currentUser: ICurrentUser, companiesService: ICompaniesService) => (
  async (dispatch) => {
    let companyLocal = initialState.company;

    if (currentUser.jwtInfo.companyId) {
      companyLocal = await companiesService.fetch(currentUser.jwtInfo.companyId);
    }

    batch(() => {
      dispatch(setUser(currentUser.user));
      dispatch(setJwtInfo(currentUser.jwtInfo));
      dispatch(setHasCertificates(currentUser.hasCertificates));
      dispatch(setCompany(companyLocal));
    });
  }
);

export const fetchCurrentUser: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (usersService: IUsersService, companiesService: ICompaniesService) => (
  async (dispatch) => {
    try {
      dispatch(setErrors(initialState.errors));

      dispatch(setCurrentUser(await usersService.fetchCurrentUser(), companiesService));
    } catch (error) {
      const errorsLocal = error as IErrors;
      dispatch(reset());
      dispatch(setErrors(errorsLocal));
    }
  }
);

export const establishCurrentCompanyAsDefault: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (usersService: IUsersService) => (
  async (dispatch) => {
    try {
      dispatch(setErrors(initialState.errors));

      dispatch(setCurrentUser(await usersService.establishCurrentCompanyAsDefault()));
    } catch (error) {
      // TODO: manejar error
    }
  }
);

// Reducers

const accessToken: Reducer<string> = (state = initialState.accessToken, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.accessToken;
    case Type.SET_ACCESS_TOKEN:
      return (action as SetAccessTokenAction).accessToken;
    default:
      return state;
  }
};

const isAnonymous: Reducer<boolean> = (state = initialState.isAnonymous, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.isAnonymous;
    case Type.SET_ACCESS_TOKEN:
      return !(action as SetAccessTokenAction).accessToken;
    default:
      return state;
  }
};

const user: Reducer<IUser> = (state = initialState.user, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.user;
    case Type.SET_USER:
      return (action as SetUserAction).user;
    default:
      return state;
  }
};

const jwtInfo: Reducer<IJwtInfo> = (state = initialState.jwtInfo, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.jwtInfo;
    case Type.SET_JWT_INFO:
      return (action as SetJwtInfoAction).jwtInfo;
    default:
      return state;
  }
};

const hasCertificates: Reducer<boolean> = (state = initialState.hasCertificates, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.hasCertificates;
    case Type.SET_HAS_CERTIFICATES:
      return (action as SetHasCertificatesAction).hasCertificates;
    default:
      return state;
  }
};

const company: Reducer<ICompany> = (state = initialState.company, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.company;
    case Type.SET_COMPANY:
      return (action as SetCompanyAction).company;
    default:
      return state;
  }
};

const errors: Reducer<IErrors> = (state = initialState.errors, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.errors;
    case Type.SET_ERRORS:
      return (action as SetErrorsAction).errors;
    default:
      return state;
  }
};

export const currentUserStore = combineReducers({ accessToken, isAnonymous, user, jwtInfo, hasCertificates, company, errors });
