import { toast, ToastOptions} from 'react-toastify';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IToastOptions extends ToastOptions {}

function concatMessages(messages?: string[], concatString?: string): string {
  let currentConcatString = '';
  if (messages !== undefined && messages !== null && Array.isArray(messages) && messages.length > 0) {
    let newMessage = '';
    messages.filter(f => f !== undefined && f !== null).forEach((message: string) => {
      newMessage = newMessage + currentConcatString + message;
      currentConcatString = concatString ?? '. ';
    });

    return newMessage;
  }

  return '';
}

const commonToastConfiguration: IToastOptions = {
  position: toast.POSITION.TOP_CENTER,
  hideProgressBar: true
};

export enum TypeMessage {
  Error,
  Success,
  Warning,
  Info
}

export function notifyErrorWithOptions(message?: string, options?: IToastOptions): void {
  toast.error(message, { ...commonToastConfiguration, ...options });
}

export function notifyError(message?: string): void {
  //toast.error(message, commonToastConfiguration);
  notifyErrorWithOptions(message, commonToastConfiguration);
}

export function notifySuccessWithOptions(message?: string, options?: IToastOptions): void {
  toast.success(message, { ...commonToastConfiguration, ...options });
}

export function notifySuccess(message?: string): void {
  //toast.success(message, commonToastConfiguration);
  notifySuccessWithOptions(message, commonToastConfiguration);
}

export function notifyWarningWithOptions(message?: string, options?: IToastOptions): void {
  toast.warn(message, { ...commonToastConfiguration, ...options });
}

export function notifyWarning(message?: string): void {
  //toast.warn(message, commonToastConfiguration);
  notifyWarningWithOptions(message, commonToastConfiguration);
}

export function notifyInfoWithOptions(message?: string, options?: IToastOptions): void {
  toast.info(message, { ...commonToastConfiguration, ...options });
}

export function notifyInfo(message?: string): void {
  //toast.info(message, commonToastConfiguration);
  notifyInfoWithOptions(message, commonToastConfiguration);
}

export function notifyErrors(messages?: string[], concatString?: string): void {
  if (messages !== undefined && messages != null && Array.isArray(messages) && messages.length > 0) {
    const newMessage = concatMessages(messages, concatString);
    notifyError(newMessage);
  }
}

export function notifySuccesses(messages?: string[], concatString?: string): void {
  if (messages !== undefined && messages != null && Array.isArray(messages) && messages.length > 0) {
    const newMessage = concatMessages(messages, concatString);
    notifySuccess(newMessage);
  }
}

export function notifyWarnings(messages?: string[], concatString?: string): void {
  if (messages !== undefined && messages != null && Array.isArray(messages) && messages.length > 0) {
    const newMessage = concatMessages(messages, concatString);
    notifyWarning(newMessage);
  }
}

export function notifyInfos(messages?: string[], concatString?: string): void {
  if (messages !== undefined && messages != null && Array.isArray(messages) && messages.length > 0) {
    const newMessage = concatMessages(messages, concatString);
    notifyInfo(newMessage);
  }
}

export function notifyMessage(type: TypeMessage, message?: string): void {
  switch (type) {
  case TypeMessage.Error:
    notifyError(message);
    break;
  case TypeMessage.Success:
    notifySuccess(message);
    break;
  case TypeMessage.Warning:
    notifyWarning(message);
    break;
  case TypeMessage.Info:
    notifyInfo(message);
    break;
  }
}
