import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { DropDownListComponent, ChangeEventArgs, FieldSettingsModel } from '@syncfusion/ej2-react-dropdowns';

import { IUnecosListFilterProps } from '../../../interfaces/props/IUnecosListProps';
import { UnecoCategoryName } from '../../../common/model/enumerations/unecoCategoryName.model';
import { CheckBoxStates } from '../../../common/model/enumerations/checkBoxStates.model';
import { enumToArray } from '../../../utils/enum.utils';
import * as unecoCategoryNameUtils from '../../../utils/unecoCategoryName.utils';
import * as checkBoxStateUtils from '../../../utils/checkBoxState.utils';
import TextBox from '../../../components/filters/textBox';

type UnecosListFilterPropsType = IUnecosListFilterProps & WithTranslation;

class UnecosListFilter extends Component<UnecosListFilterPropsType> {
  public categoryNameDropDownListComponent: DropDownListComponent = null;
  public categoryNameOptions: Record<string, string | number>[] = [];
  public categoryNameFields: FieldSettingsModel = { text: 'text', value: 'value', iconCss: 'iconCss' };

  public activeDropDownListComponent: DropDownListComponent = null;
  public activeCheckBoxOptions: Record<string, string | number>[] = [];
  public activeFields: FieldSettingsModel = { text: 'text', value: 'value', iconCss: 'iconCss' };

  public constructor(props: UnecosListFilterPropsType) {
    super(props);

    this.categoryNameOptions = enumToArray(UnecoCategoryName, 'string', 'text', 'value').map((item) => ({
      ...item,
      text: item.value,
      iconCss: unecoCategoryNameUtils.getIconCss(item.value as string)
    }));

    this.activeCheckBoxOptions = enumToArray(CheckBoxStates, 'number', 'text', 'value').map((item) => ({
      ...item,
      text: checkBoxStateUtils.getLocalizedYesNoIndiferentText(props.i18n, item.value.toString()),
      iconCss: checkBoxStateUtils.getIconCss(item.value as number)
    }));
  }

  public onCategoryNameChange(e: ChangeEventArgs) {
    const categoryName = e.value as string;
    this.props.onCategoryNameChange(categoryName);
  }

  public onCodeChange(value: string) {
    this.props.onCodeChange(value);
  }

  public onEmailsChange(value: string) {
    this.props.onEmailsChange(value);
  }

  public onEmailCoordinatorChange(value: string) {
    this.props.onEmailCoordinatorChange(value);
  }

  public onActiveChange(e: ChangeEventArgs) {
    this.props.onActiveChange(checkBoxStateUtils.convertIntToBoolean(e.value as number));
  }

  public updateCategoryNameDropDownListComponentValueTemplate() {
    if (this.categoryNameDropDownListComponent) {
      const selectedCategoryNameOption = this.categoryNameOptions.find((categoryNameOption) => categoryNameOption.value === this.props.filter.categoryName);
      if (selectedCategoryNameOption) {
        this.categoryNameDropDownListComponent.valueTemplate = `
          <div class="row no-gutters pl-3 pr-3 h-100">
            <div class="col align-self-center">
              <span class="${`mr-2 ${selectedCategoryNameOption.iconCss}`}" ></span>
              <span>${selectedCategoryNameOption.text}</span>
            </div>
          </div>
        `;
      }
    }
  }

  public updateActiveDropDownListComponentValueTemplate() {
    if (this.activeDropDownListComponent) {
      const selectedActiveOption = this.activeCheckBoxOptions.find((activeOption) => activeOption.value === checkBoxStateUtils.convertBooleanToInt(this.props.filter.active));
      if (selectedActiveOption) {
        this.activeDropDownListComponent.valueTemplate = `
          <div class="row no-gutters pl-3 pr-3 h-100">
            <div class="col align-self-center">
              <span class="${`mr-2 ${selectedActiveOption.iconCss}`}" ></span>
              <span>${selectedActiveOption.text}</span>
            </div>
          </div>
        `;
      }
    }
  }

  public render() {
    const {
      filter,
      isFetching,
      onFilterButtonClick,
      i18n,
    } = this.props;

    return (
      <form onSubmit={(e: React.FormEvent) => { e.preventDefault(); onFilterButtonClick(); }}>
        <div className='row'>
          <div className='col-12 col-md-6'>
            <div className='form-group'>
              <div className='autocomplete-input'>
                <div className='autocomplete-label'>{i18n.t('uneco.code')}</div>
                <TextBox
                  name='code'
                  value={filter.code}
                  showClearButton={true}
                  onChange={(value) => this.onCodeChange(value)} />
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <div className='form-group'>
              <div className='autocomplete-input'>
                <div className='autocomplete-label'>{i18n.t('uneco.categoryName')}</div>
                <DropDownListComponent
                  fields={this.categoryNameFields}
                  dataSource={this.categoryNameOptions}
                  value={filter.categoryName}
                  ref={(dropDownListComponent: DropDownListComponent) => {
                    this.categoryNameDropDownListComponent = dropDownListComponent;
                    this.updateCategoryNameDropDownListComponentValueTemplate();
                  }}
                  sortOrder="Ascending"
                  change={(e) => this.onCategoryNameChange(e)}
                />
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <div className='form-group'>
              <div className='autocomplete-input'>
                <div className='autocomplete-label'>{i18n.t('uneco.emails')}</div>
                <TextBox
                  name='emails'
                  value={filter.emails}
                  showClearButton={true}
                  onChange={(value) => this.onEmailsChange(value)} />
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <div className='form-group'>
              <div className='autocomplete-input'>
                <div className='autocomplete-label'>{i18n.t('uneco.emailCoordinator')}</div>
                <TextBox
                  name='emailCoordinator'
                  value={filter.emailCoordinator}
                  showClearButton={true}
                  onChange={(value) => this.onEmailCoordinatorChange(value)} />
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <div className='form-group'>
              <div className='autocomplete-input'>
                <div className='autocomplete-label'>{i18n.t('uneco.active')}</div>
                <DropDownListComponent
                  fields={this.activeFields}
                  dataSource={this.activeCheckBoxOptions}
                  value={checkBoxStateUtils.convertBooleanToInt(filter.active)}
                  ref={(dropDownListComponent: DropDownListComponent) => {
                    this.activeDropDownListComponent = dropDownListComponent;
                    this.updateActiveDropDownListComponentValueTemplate();
                  }}
                  sortOrder="Ascending"
                  change={(e) => this.onActiveChange(e)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='row justify-content-end'>
          <div className='col-auto'>
            <button type='submit' className='btn btn-secondary' title={i18n.t('actions.filter')} disabled={isFetching}>
              {isFetching
                ? <span className='spinner-border spinner-border-sm' />
                : <span className='icon icon-app-item-search' />
              }
            </button>
          </div>
        </div>
      </form>
    );
  }
}

export default withTranslation()(UnecosListFilter);
