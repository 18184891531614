import React, { Component } from 'react';
import DateRangeBase, { IDateRangeFilterProps, IDateRangeFilterState } from './dateRangeBase';
import { getDate } from '../../helpers';
import { PresetsModel } from '@syncfusion/ej2-calendars';

interface IDateRangeProps extends IDateRangeFilterProps {
  name: string;
}

class DatePastRange extends Component<IDateRangeProps, IDateRangeFilterState> {
  private today: Date = new Date(getDate.today().toDateString());
  private last7days: Date = new Date(getDate.last7days().toDateString());
  private last30days: Date = new Date(getDate.last30days().toDateString());
  private last60days: Date = new Date(getDate.last60days().toDateString());

  private presets: PresetsModel[] = [
    { label: this.props.i18n.t('home.dateRange.today'), start: this.today, end: this.today },
    { label: this.props.i18n.t('home.dateRange.last7days'), start: this.last7days, end: this.today },
    { label: this.props.i18n.t('home.dateRange.last30days'), start: this.last30days, end: this.today },
    { label: this.props.i18n.t('home.dateRange.last60days'), start: this.last60days, end: this.today }
  ];

  public render(): JSX.Element {
    return (
      <DateRangeBase
        name={this.props.name}
        label={this.props.label}
        value={this.props.value}
        i18n={this.props.i18n}
        placeholder={this.props.placeholder || ''}
        onChangeInput={this.props.onChangeInput}
        options={this.presets}
      />
    );
  }
}

export default DatePastRange;
