import { IUser } from '../common/model/user.model';
import { IJwtInfo } from '../common/model/jwtInfo.model';
import { ICompany } from '../common/model/company.model';
import { ReportCustomFieldSettingType } from '../common/model/enumerations/reportCustomFieldSettingType.model';

export const getCurrentUserChainName = (user: IUser, jwtInfo: IJwtInfo): string => {
  return user.companies.find((userCompany) => userCompany.companyId === jwtInfo.companyId) ?
    user.companies.find((userCompany) => userCompany.companyId === jwtInfo.companyId).chainName
    :
    null;
};

export const isEciUser = (currentUserCompany: ICompany, currentUserChainName: string): boolean => {
  return (
    (currentUserCompany && currentUserCompany.reportCustomFieldSettingType === ReportCustomFieldSettingType.ECI)
    && (currentUserChainName && currentUserChainName.toLowerCase().startsWith('eci'))
  );
};
