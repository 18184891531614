import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { createSpinner, showSpinner, hideSpinner } from '@syncfusion/ej2-popups';
import { DialogComponent } from '@aitex/tsx-extranet-dialogs';

import { IContextualHelpDialogProps } from '../../../interfaces/props/IDialogsProps';
import { contextualHelpsService } from '../../../services';
import * as dialogUtils from '../../../utils/dialog.utils';

type ContextualHelpDialogPropsType = IContextualHelpDialogProps & WithTranslation;

type ContextualHelpDialogStateType = IContextualHelpDialogState;

class ContextualHelpDialog extends Component<ContextualHelpDialogPropsType, ContextualHelpDialogStateType> {
  public readonly CONTEXTUAL_HELP_DIALOG_ID = 'contextualHelpDialog';

  private readonly OK_BUTTON_ID = 'contextualHelpDialogOk';

  private initialState: ContextualHelpDialogStateType = {
    htmlString: null,
    isLoading: false
  };

  public constructor(props: ContextualHelpDialogPropsType) {
    super(props);

    this.state = this.initialState;
  }

  public componentDidMount() {
    createSpinner({
      target: document.getElementById(this.CONTEXTUAL_HELP_DIALOG_ID)
    });
  }

  public componentDidUpdate(prevProps: ContextualHelpDialogPropsType, prevState: ContextualHelpDialogStateType) {
    const { visible } = this.props;

    if (!prevProps.visible && visible) {
      this.fetchContextualHelp();
    }
    if (prevProps.visible && !visible) {
      this.setState(this.initialState);
    }

    if (!prevState.isLoading && this.state.isLoading) {
      showSpinner(document.getElementById(this.CONTEXTUAL_HELP_DIALOG_ID));
    }
    if (prevState.isLoading && !this.state.isLoading) {
      hideSpinner(document.getElementById(this.CONTEXTUAL_HELP_DIALOG_ID));
    }

    dialogUtils.manageButtonsClick(this.OK_BUTTON_ID, () => this.props.onClose(), null, null);
  }

  public render() {
    const { visible, onDismiss, i18n } = this.props;

    return (
      <DialogComponent
        header={i18n.t('dialogs.contextualHelpDialog.title')}
        visible={visible}
        width='80%'
        footerTemplate={dialogUtils.computeFooterTemplate(
          this.OK_BUTTON_ID,
          i18n.t('actions.ok'),
          null,
          null
        )}
        onDismiss={() => onDismiss()}
      >
        <div id={this.CONTEXTUAL_HELP_DIALOG_ID}>
          {this.state.isLoading ?
            <div style={{ minHeight: '80px' }} />
            :
            this.state.htmlString ?
              <div dangerouslySetInnerHTML={{ __html: this.state.htmlString }} />
              :
              <span>{i18n.t('dialogs.contextualHelpDialog.error')}</span>
          }
        </div>
      </DialogComponent>
    );
  }

  private async fetchContextualHelp() {
    const { code, i18n } = this.props;

    this.setState({ isLoading: true });

    const htmlString = await contextualHelpsService.fetchByCode(code, i18n.language);
    this.setState({ htmlString, isLoading: false });
  }
}

interface IContextualHelpDialogState {
  htmlString: string;
  isLoading: boolean;
}

export default withTranslation()(ContextualHelpDialog);
