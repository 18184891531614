import store from '../store';
import { URL } from '../common/constants';
import { ICurrentUser } from '../common/model/currentUser.model';
import * as services from '.';

export interface IUsersService {
  fetchCurrentUser(): Promise<ICurrentUser>;
  establishCurrentCompanyAsDefault(): Promise<ICurrentUser>;
  changeCurrentCompany(companyId: string): Promise<ICurrentUser>;
}

class UsersService implements IUsersService {
  public constructor(private httpClient: services.IHttpClient) {

  }

  public fetchCurrentUser(): Promise<ICurrentUser> {
    return this.httpClient.getCustom<ICurrentUser>(`${URL.WEB.BASE}${URL.WEB.USERS}/me`);
  }

  public establishCurrentCompanyAsDefault(): Promise<ICurrentUser> {
    return this.httpClient.postCustom(`${store.getState().settingsStore.settings.identity}${URL.WEB.BASE}${URL.WEB.USERS}/DefaultCompany`, {});
  }

  public changeCurrentCompany(companyId: string): Promise<ICurrentUser> {
    return this.httpClient.postCustom(`${store.getState().settingsStore.settings.identity}${URL.WEB.BASE}${URL.WEB.USERS}/CurrentCompany`, { companyId });
  }
}

export const usersService = new UsersService(services.httpClient);
