import { IJwtInfo } from '../common/model/jwtInfo.model';

export interface IRolesService {
  hasAnyRole(jwtInfo: IJwtInfo, roles: string[]): boolean;
}

class RolesService implements IRolesService {
  public hasAnyRole(jwtInfo: IJwtInfo, roles: string[]): boolean {
    if (!jwtInfo) {
      return false;
    }

    return roles.some((role) => role === jwtInfo.role);
  }
}

export const rolesService = new RolesService();
