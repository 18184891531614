import { Component } from 'react';

interface IFilterProps {
  name: string;
}

interface IFilterState {
  value?: any;
}

export type FilterProps<P> = IFilterProps & P;
export type FilterState<S> = IFilterState & S;

export class Filter<P, S> extends Component<FilterProps<P>, FilterState<S>> {}
