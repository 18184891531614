import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { FormValidator, FormEventArgs } from '@syncfusion/ej2-inputs';
import { MultiSelectComponent, MultiSelectChangeEventArgs, Inject as MultiSelectInject, CheckBoxSelection, FieldSettingsModel } from '@syncfusion/ej2-react-dropdowns';
import { RichTextEditorComponent, ChangeEventArgs as RichTextEditorChangeEventArgs, Inject as RichTextEditorInject, Toolbar, QuickToolbar, Link, HtmlEditor, ImageSettingsModel } from '@syncfusion/ej2-react-richtexteditor';
import { Query } from '@syncfusion/ej2-data';
import { SortOrder } from '@syncfusion/ej2-lists';
import { UploaderComponent, AsyncSettingsModel } from '@syncfusion/ej2-react-inputs';
import { DialogComponent } from '@aitex/tsx-extranet-dialogs';

import { IUserFeedbackDialogProps } from '../../../interfaces/props/IDialogsProps';
import { IUserFeedback } from '../../../common/model/userFeedback.model';
import { IErrors } from '../../../common/model/errors.model';
import { UserFeedbackType } from '../../../common/model/enumerations/userFeedbackType.model';
import { ExperienceLevel } from '../../../common/model/enumerations/experienceLevel.model';
import { RuleOperator } from '../../../common/model/enumerations/ruleOperator.model';
import { Validator } from '../../../common/model/enumerations/validator.model';
import { userFeedbacksService } from '../../../services';
import * as userFeedbackTypeUtils from '../../../utils/userFeedbackType.utils';
import * as dialogUtils from '../../../utils/dialog.utils';
import * as formValidatorUtils from '../../../utils/formValidator.utils';
import ValidationError from '../../validationError/validationError';
import './userFeedbackDialog.scss';

type UserFeedbackDialogPropsType = IUserFeedbackDialogProps & WithTranslation;

type UserFeedbackDialogStateType = IUserFeedbackDialogState;

class UserFeedbackDialog extends Component<UserFeedbackDialogPropsType, UserFeedbackDialogStateType> {
  public readonly USER_FEEDBACK_DIALOG_FORM_ID = 'userFeedbackDialogForm';

  public reportsQuery = new Query().select(['Id', 'ReportNumber']).take(10);
  public reportsFields: FieldSettingsModel = { text: 'reportNumber', value: 'id' };
  public companiesSortOrder: SortOrder = 'Ascending';

  public richTextEditorComponent: RichTextEditorComponent = null;
  public insertImageSettings: ImageSettingsModel = {
    maxHeight: 500,
    maxWidth: 500,
    saveFormat: 'Base64'
  };

  public uploaderComponent: UploaderComponent = null;
  public asyncSettings: AsyncSettingsModel = {
    retryCount: 0
  };

  private readonly CANCEL_BUTTON_ID = 'userFeedbackDialogCancel';
  private readonly SAVE_BUTTON_ID = 'userFeedbackDialogSave';

  private readonly CANNOT_SEND_USER_FEEDBACK_ERROR: IErrors = { UnknownError: ['dialogs.userFeedbackDialog.error'] };

  private formValidator: FormValidator = null;
  private isValidatingAll = false;
  private auxErrors: IErrors = {};

  private initialState: UserFeedbackDialogStateType = {
    showForm: false,
    type: UserFeedbackType.SERVICE,
    userFeedback: {
      experienceLevel: null,
      message: '',
      reportIds: []
    },
    isSaving: false,
    errors: {}
  };

  public constructor(props: UserFeedbackDialogPropsType) {
    super(props);

    this.asyncSettings.saveUrl = userFeedbacksService.getUploadUrl(this.initialState.type);

    this.state = this.initialState;
  }

  public componentDidUpdate(prevProps: UserFeedbackDialogPropsType): void {
    const { visible, currentUserCompany } = this.props;

    if (!prevProps.visible && visible) {
      this.reportsQuery = currentUserCompany ?
        this.reportsQuery
          .clone()
          .where('clientId', RuleOperator.EQUAL, currentUserCompany.id)
          .take(10)
        :
        this.reportsQuery
          .clone()
          .take(10);
      setTimeout(() => {
        this.setState({
          showForm: true,
          userFeedback: { ...this.state.userFeedback }
        });
      });
    }
    if (prevProps.visible && !visible) {
      this.setState(this.initialState);
      if (this.uploaderComponent) {
        this.uploaderComponent.clearAll();
      }
      this.resetFormValidator();
    }

    dialogUtils.manageButtonsClick(this.SAVE_BUTTON_ID, (): Promise<void> => this.userFeedback(), this.CANCEL_BUTTON_ID, (): void => this.dismiss());
  }

  public async userFeedback(): Promise<void> {
    try {
      if (!this.validateForm()) {
        return;
      }

      this.setState({ isSaving: true, errors: this.initialState.errors });
      if (!this.uploaderComponent || this.uploaderComponent.getFilesData().length === 0) {
        await userFeedbacksService.sendWithoutFiles(this.state.userFeedback, this.state.type);
      } else {
        await userFeedbacksService.sendWithFiles(this.uploaderComponent.filesData.map((fileData) => fileData.rawFile), this.state.userFeedback, this.state.type);
      }
      this.onUploadSuccess();
    } catch (error) {
      const errors = error as IErrors;
      this.onUploadFailure(errors);
    }
  }

  public close(): void {
    this.props.onClose();
    this.formValidator = null;
  }

  public dismiss(reason?: IErrors): void {
    this.props.onDismiss(reason);
  }

  public validateMessage(args: { [key: string]: string }): boolean {
    const value = args['value'];
    return typeof value === 'string' && value.trim().replace(/[\u200B-\u200D\uFEFF]/g, '').replace(/(<([^>]+)>)/ig, '').length > 0;
  }

  public configureFormValidator(): void {
    if (this.props.visible) {
      if (!this.formValidator) {
        this.formValidator = formValidatorUtils.configureFormValidator(this.USER_FEEDBACK_DIALOG_FORM_ID, {
          ReportIds: { min: [1, Validator.NOT_EMPTY] },
          ExperienceLevel: { min: [0, Validator.NOT_EMPTY] },
          Message: { required: [this.validateMessage, Validator.NOT_EMPTY] }
        });
        this.formValidator.validationComplete = (args) => {
          formValidatorUtils.validationComplete(args as FormEventArgs, this.isValidatingAll, this.state.errors, this.auxErrors, (errors): void => this.setState({ errors }));
        };
      }
    } else {
      this.formValidator = null;
    }
  }

  public onTypeChange(type: string): void {
    this.setState({ type, userFeedback: { ...this.state.userFeedback, reportIds: [] } });
    this.uploaderComponent.asyncSettings.saveUrl = userFeedbacksService.getUploadUrl(type);
    if (type === UserFeedbackType.SERVICE) {
      this.formValidator.addRules('ReportIds', { min: [1, Validator.NOT_EMPTY] });
    } else {
      this.formValidator.removeRules('ReportIds');
    }
  }

  public onReportIdsChange(e: MultiSelectChangeEventArgs) {
    const reportIds = e.value as string[];
    let errors = this.state.errors;
    if (this.state.errors.ReportIds && reportIds.length) {
      const { ReportIds, ...restOfErrors } = this.state.errors;
      errors = restOfErrors;
    }
    this.setState({ userFeedback: { ...this.state.userFeedback, reportIds }, errors });
  }

  public getExperienceLevelClassName(experienceLevel: number): string {
    return `e-avatar e-avatar-large e-avatar-circle${this.state.userFeedback.experienceLevel === experienceLevel ? ' selected' : ''}`
  }

  public onExperienceLevelClick(experienceLevel: number) {
    let errors = this.state.errors;
    if (this.state.errors.ExperienceLevel && experienceLevel >= 0) {
      const { ExperienceLevel, ...restOfErrors } = this.state.errors;
      errors = restOfErrors;
      if (this.formValidator.getInputElement('ExperienceLevel')) {
        this.formValidator.getInputElement('ExperienceLevel').parentElement.className = this.formValidator.getInputElement('ExperienceLevel').parentElement.className.replace('e-error', '');
      }
    }
    this.setState({ userFeedback: { ...this.state.userFeedback, experienceLevel }, errors });
  }

  public onMessageChange(e: RichTextEditorChangeEventArgs): void {
    let errors = this.state.errors;
    if (this.state.errors.Message && e.value && e.value.trim().length) {
      const { Message, ...restOfErrors } = this.state.errors;
      errors = restOfErrors;
      if (this.formValidator.getInputElement('Message')) {
        this.formValidator.getInputElement('Message').parentElement.className = this.formValidator.getInputElement('Message').parentElement.className.replace('e-error', '');
      }
    }
    this.setState({ userFeedback: { ...this.state.userFeedback, message: e.value }, errors });
  }

  public onUploadSuccess(): void {
    this.setState({ isSaving: false });
    this.close();
  }

  public render(): JSX.Element {
    const { visible, reportsDataManager, i18n } = this.props;

    return (
      <DialogComponent
        header={i18n.t('dialogs.userFeedbackDialog.title')}
        visible={visible}
        width='480px'
        footerTemplate={dialogUtils.computeFooterTemplate(
          this.SAVE_BUTTON_ID,
          i18n.t('actions.save'),
          this.CANCEL_BUTTON_ID,
          i18n.t('actions.cancel'),
          this.state.isSaving,
          this.isSaveButtonDisabled()
        )}
        onDismiss={(): void => this.dismiss()}
      >
        <div className="user-feedback-dialog">
          <div className='row justify-content-around'>
            <div className='col-auto'>
              <div className='form-group'>
                <div className='form-check'>
                  <input
                    className='form-check-input'
                    type='radio'
                    value={UserFeedbackType.SERVICE}
                    checked={this.state.type === UserFeedbackType.SERVICE}
                    id='typeService'
                    onChange={(event): void => this.onTypeChange(event.target.value)}
                  />
                  <label className='form-check-label' htmlFor='typeService'>
                    {userFeedbackTypeUtils.getLocalizedText(i18n, UserFeedbackType.SERVICE)}
                  </label>
                </div>
              </div>
            </div>
            <div className='col-auto'>
              <div className='form-group'>
                <div className='form-check'>
                  <input
                    className='form-check-input'
                    type='radio'
                    value={UserFeedbackType.PLATFORM}
                    checked={this.state.type === UserFeedbackType.PLATFORM}
                    id='typePlatform'
                    onChange={(event): void => this.onTypeChange(event.target.value)}
                  />
                  <label className='form-check-label' htmlFor='typePlatform'>
                    {userFeedbackTypeUtils.getLocalizedText(i18n, UserFeedbackType.PLATFORM)}
                  </label>
                </div>
              </div>
            </div>
          </div>
          {this.state.showForm && <form id={this.USER_FEEDBACK_DIALOG_FORM_ID} ref={() => this.configureFormValidator()}>
            {this.state.type === UserFeedbackType.SERVICE && <div className='form-group'>
              <div className='autocomplete-input'>
                <div className='autocomplete-label'>{i18n.t('userFeedback.reports')} *</div>
                <MultiSelectComponent
                  query={this.reportsQuery}
                  dataSource={reportsDataManager}
                  fields={this.reportsFields}
                  sortOrder={this.companiesSortOrder}
                  mode='CheckBox'
                  filterType='Contains'
                  showSelectAll={true}
                  selectAllText={i18n.t('actions.selectAll')}
                  unSelectAllText={i18n.t('actions.unselectAll')}
                  value={this.state.userFeedback.reportIds}
                  change={(e) => this.onReportIdsChange(e)}
                >
                  <MultiSelectInject services={[CheckBoxSelection]} />
                </MultiSelectComponent>
              </div>
              <input
                type='number'
                className='d-none'
                name='ReportIds'
                readOnly={true}
                value={this.state.userFeedback.reportIds.length}
              />
              <ValidationError errors={this.state.errors} errorKey={'ReportIds'} />
            </div>}
            <div className="form-group">
              <label>{i18n.t('userFeedback.experienceLevel')} *</label>
              <div className='row'>
                <div className='col-auto'>
                  <span className={this.getExperienceLevelClassName(ExperienceLevel.POSITIVE)} onClick={() => this.onExperienceLevelClick(ExperienceLevel.POSITIVE)}>
                    <span className="icon icon-user-feedback-positive" />
                  </span>
                </div>
                <div className='col-auto'>
                  <span className={this.getExperienceLevelClassName(ExperienceLevel.NEGATIVE)} onClick={() => this.onExperienceLevelClick(ExperienceLevel.NEGATIVE)}>
                    <span className="icon icon-user-feedback-negative" />
                  </span>
                </div>
              </div>
              <input
                type='number'
                className='d-none'
                name='ExperienceLevel'
                readOnly={true}
                value={this.state.userFeedback.experienceLevel === null ? -1 : this.state.userFeedback.experienceLevel}
              />
              <ValidationError errors={this.state.errors} errorKey={'ExperienceLevel'} />
            </div>
            <div className='form-group'>
              <label>{i18n.t('userFeedback.message')}</label>
              <RichTextEditorComponent
                value={this.state.userFeedback.message}
                insertImageSettings={this.insertImageSettings}
                ref={(richTextEditor: RichTextEditorComponent) : RichTextEditorComponent => this.richTextEditorComponent = richTextEditor}
                change={(e): void => this.onMessageChange(e)}
              >
                <RichTextEditorInject services={[Toolbar, QuickToolbar, Link, HtmlEditor]} />
              </RichTextEditorComponent>
              <input
                name='Message'
                className='d-none'
                readOnly={true}
                value={this.state.userFeedback.message}
              />
              <ValidationError errors={this.state.errors} errorKey={'Message'} />
            </div>
          </form>}
          <div className='row'> {/* Must be outside <form> (https://www.syncfusion.com/forums/144130/all-files-are-deleted-from-uploader-list) */}
            <div className='col'>
              <UploaderComponent
                name='Files'
                autoUpload={false}
                asyncSettings={this.asyncSettings}
                allowedExtensions='.pdf,.jpg,.jpeg,.png,.doc,.docx,.xls,.xlsx'
                maxFileSize={10000000}
                locale={i18n.language}
                ref={(uploader: UploaderComponent): UploaderComponent => this.uploaderComponent = uploader}
              />
            </div>
          </div>
        </div>
      </DialogComponent>
    );
  }

  private isSaveButtonDisabled(): boolean {
    return this.state.isSaving;
  }

  private onUploadFailure(errors: IErrors) {
    if (!errors || errors.UnknownError) {
      errors = this.CANNOT_SEND_USER_FEEDBACK_ERROR;
    }
    if (errors.Message || errors.ReportIds) {
      this.setState({ errors });
      return;
    }
    this.dismiss(errors);
  }

  private resetFormValidator(): void {
    if (!this.formValidator) {
      return;
    }

    if (this.formValidator.getInputElement('ReportIds')) {
      this.formValidator.getInputElement('ReportIds').parentElement.className = this.formValidator.getInputElement('ReportIds').parentElement.className.replace('e-error', '');
    }
    if (this.formValidator.getInputElement('ExperienceLevel')) {
      this.formValidator.getInputElement('ExperienceLevel').parentElement.className = this.formValidator.getInputElement('ExperienceLevel').parentElement.className.replace('e-error', '');
    }
    if (this.formValidator.getInputElement('Message')) {
      this.formValidator.getInputElement('Message').parentElement.className = this.formValidator.getInputElement('Message').parentElement.className.replace('e-error', '');
    }
    this.formValidator.removeRules();
    this.formValidator.validationComplete = undefined;
  }

  private validateForm(): boolean {
    if (!this.formValidator) {
      return true;
    }

    this.isValidatingAll = true;
    const isFormValid = this.formValidator.validate();
    this.isValidatingAll = false;
    if (!isFormValid) {
      this.setState({ errors: this.auxErrors });
      this.auxErrors = {};
    }
    return isFormValid;
  }
}

interface IUserFeedbackDialogState {
  showForm: boolean;
  type: string;
  userFeedback: IUserFeedback;
  isSaving: boolean;
  errors: IErrors;
}

export default withTranslation()(UserFeedbackDialog);
