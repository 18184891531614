import { ODataV4Adaptor, DataManager, Query, DataResult, DataOptions, CrudOptions } from '@syncfusion/ej2-data';
import { Ajax } from '@syncfusion/ej2-base';
import queryString from 'query-string';

import store from '../store';
import { RuleCondition } from '../common/model/enumerations/ruleCondition.model';
import { unescapeValuesInObjectProperty, htmlValuesToScope } from '../utils/string.utils';

export class CustomODataV4Adaptor extends ODataV4Adaptor {
  public processQuery(dm: DataManager, query: Query): any {
    const queryParams = query.params;
    query.params = [];

    const result = super.processQuery(dm, query) as any;

    const parsedUrl = queryString.parse(result.url.substring(result.url.indexOf('?')));
    if (!parsedUrl.$filter) {
      parsedUrl.$filter = '';
    }
    const initialFilterValue = parsedUrl.$filter;
    const anyParams = queryParams.filter((param) => param.key.includes(RuleCondition.ANY));
    for (const param of anyParams) {
      if (parsedUrl.$filter.length) {
        parsedUrl.$filter += ` ${RuleCondition.AND} `;
      }
      const paramKeyWithoutAny = param.key.substring(RuleCondition.ANY.length);
      let paramValue = (param.fn() as string);

      const guidAsString = 'guidAsString';
      const guidLength = 36;
      if (paramValue.includes(guidAsString)) {
        const startIndex = paramValue.indexOf(guidAsString) + guidAsString.length;
        const textToReplace = paramValue.substring(startIndex, startIndex + guidLength);
        paramValue = paramValue.replace(textToReplace, '\'' + textToReplace + '\'');
        paramValue = paramValue.replace(guidAsString, '');
      }

      if (paramValue.includes(` ${RuleCondition.OR} `)) {
        const conditions = paramValue.split(` ${RuleCondition.OR} `);
        for (let i = 0; i < conditions.length; i++) {
          if (i === 0) {
            parsedUrl.$filter += conditions[i].replace(`(${paramKeyWithoutAny}`, `${paramKeyWithoutAny.split('/')[0]}/any(x: (x/${paramKeyWithoutAny.split('/')[1]}`);
          } else {
            parsedUrl.$filter += ` ${RuleCondition.OR} `;
            parsedUrl.$filter += conditions[i].replace(paramKeyWithoutAny, `x/${paramKeyWithoutAny.split('/')[1]}`);
          }
        }
        parsedUrl.$filter += ')';
      } else {
        parsedUrl.$filter += paramValue.replace(paramKeyWithoutAny, `${paramKeyWithoutAny.split('/')[0]}/any(x: x/${paramKeyWithoutAny.split('/')[1]}`) + ')';
      }
    }
    if (parsedUrl.$filter.length) {
      let url = result.url as string;
      url = url.includes('$filter') ? url.replace(`$filter=${initialFilterValue}`, `$filter=${parsedUrl.$filter}`) : url + `&$filter=${parsedUrl.$filter}`;
      result.url = url;
    }

    return result;
  }

  public beforeSend(dm: DataManager, request: XMLHttpRequest, _settings: Ajax): void {
    const accessToken = store.getState().currentUserStore.accessToken;

    if (accessToken) {
      request.setRequestHeader('Authorization', `Bearer ${accessToken}`);
    }
  }

  public processResponse(data: DataResult, ds?: DataOptions,
    query?: Query, xhr?: XMLHttpRequest, request?: Ajax, changes?: CrudOptions): unknown {

    if (data && data.value && Array.isArray(data.value)) {
      data.value.forEach((item: unknown) => {
        //unescapeValuesInObject(item, [{ propertyName: 'reference', valuesToScape: [{ valuesToUnescape: ['&amp;'], unescapedValue: '&' }] }]);
        unescapeValuesInObjectProperty(item, [{ propertyName: 'reference', valuesToScape: htmlValuesToScope }]);
      });
    }

    return super.processResponse(data, ds, query, xhr, request, changes);
  }
}
