
import i18n from '../i18n';
import { getValue } from '@syncfusion/ej2-base';
import { QueryCellInfoEventArgs } from '@syncfusion/ej2-grids';

interface ServiceState {
    [key: string]: string;
}

const serviceState: ServiceState = {
    pending: i18n.t('detail.table.servicesStates.pending'),
    analyzed: i18n.t('detail.table.servicesStates.analyzed'),
    cancelled: i18n.t('detail.table.servicesStates.cancelled')
};

export const customizeCell = (args: QueryCellInfoEventArgs): void => {
    if (args.column.field === 'serviceState.value'
        && args.data && args.cell) {
        if (getValue('serviceState.value', args.data)) {
            args.cell.innerHTML = serviceState[getValue('serviceState.value', args.data)];
        }
    }
};
