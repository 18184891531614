import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Moment } from 'moment';
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, Sort, Page, Toolbar, ExcelExport, Resize, ToolbarItems, SortSettingsModel, PagerComponent, SortEventArgs } from '@syncfusion/ej2-react-grids';
import { ItemModel, ClickEventArgs } from '@syncfusion/ej2-navigations';

import { INotificationsGridProps } from '../../../interfaces/props/INotificationsListProps';
import { EXCEL_EXPORT_ACTION_ID } from '../../../common/constants/gridActionId.constants';
import { INotification, INotificationUser } from '../../../common/model/notification.model';
import { NotificationScope } from '../../../common/model/enumerations/notificationScope.model';
import * as gridUtils from '../../../utils/grid.utils';
import * as pagerUtils from '../../../utils/pager.utils';
import * as sortUtils from '../../../utils/sort.utils';
import * as dateHelpers from '../../../helpers/date.helpers';
import './notificationsGrid.scss';

type NotificationsGridPropsType = INotificationsGridProps & RouteComponentProps & WithTranslation;

class NotificationsGrid extends Component<NotificationsGridPropsType> {
  public gridComponent: GridComponent = null;
  public toolbarOptions: Array<ToolbarItems | ItemModel> = [];
  public sortOptions: SortSettingsModel = null;

  public pagerComponent: PagerComponent = null;

  private readonly CREATE_NOTIFICATION_ACTION_ID = 'createNotification';

  public constructor(props: NotificationsGridPropsType) {
    super(props);

    const { sortField, sortDirection, i18n } = this.props;

    this.toolbarOptions = [{
      tooltipText: i18n.t('syncfusion.grid.Excelexport'),
      id: EXCEL_EXPORT_ACTION_ID,
      prefixIcon: 'icon icon-file-excel-o'
    }, {
      tooltipText: i18n.t('notificationsList.actions.create'),
      id: this.CREATE_NOTIFICATION_ACTION_ID,
      template: `
        <button type="button" class="e-tbar-btn e-tbtn-txt e-control e-btn e-lib">
          <span class="icon icon-notification"></span>
          <span class="ml-1 icon icon-app-item-add"></span>
        </button>
      `
    }];

    this.sortOptions = {
      columns: [{ field: sortField, direction: sortDirection }]
    };
  }

  public componentDidUpdate(prevProps: NotificationsGridPropsType) {
    gridUtils.manageSpinner(this.gridComponent, prevProps.isFetching, this.props.isFetching);
    gridUtils.manageSorting(this.gridComponent, prevProps.isFetching, this.props.isFetching, this.props.sortField, this.props.sortDirection);
  }

  public onToolbarClick(args: ClickEventArgs) {
    if (args.item.id === EXCEL_EXPORT_ACTION_ID) {
      this.gridComponent.excelExport();
    }

    if (args.item.id === this.CREATE_NOTIFICATION_ACTION_ID) {
      this.props.history.push('/notification/new');
    }
  }

  public onGridComponentDataBound() {
    const { currentPage, pageSize, totalCount, searchDate, i18n } = this.props;

    pagerUtils.refreshPager(this.pagerComponent, currentPage, pageSize, totalCount, searchDate, i18n);
  }

  public onGridComponentActionBegin(args: SortEventArgs) {
    if (args.requestType === 'sorting') {
      if (args.columnName === 'createdDate') {
        this.gridComponent.getColumnByField('createdDate').sortComparer = (reference, comparer) =>
          sortUtils.gridDateSortComparer(reference as unknown as Moment, comparer as unknown as Moment, args.direction);
      } else if (args.columnName === 'viewDate') {
        this.gridComponent.getColumnByField('viewDate').sortComparer = (reference, comparer) =>
          sortUtils.gridDateSortComparer(reference as unknown as Moment, comparer as unknown as Moment, args.direction);
      } else {
        this.gridComponent.getColumnByField(args.columnName).sortComparer = (reference, comparer) =>
          sortUtils.gridSortComparer(reference, comparer, args.direction);
      }

      gridUtils.manageSortingChange(args, this.props.sortField, this.props.sortDirection, this.props.onSortingChange);
    }
  }

  public getSubjectColumnTemplate(notification: INotification): JSX.Element {
    return <span className='e-link' onClick={() => this.props.history.push(`/notification/${notification.id}/view`)}>{notification.subject}</span>;
  }

  public getSubjectColumnTemplateViewStatus(notificationUser: INotificationUser): JSX.Element {
    return <span className='e-link' onClick={() => this.props.history.push(`/notification/${notificationUser.notificationId}/view`)}>{notificationUser.subject}</span>;
  }

  public getNotificationScopeLabel(notification: INotification): string {
    return this.props.i18n.t('enums.notificationScope.' + NotificationScope[notification.scope]);
  }

  public getCreatedDateLabel(notification: INotification): string {
    return `${dateHelpers.toShortDate(notification.createdDate, true)} ${dateHelpers.toDateWithoutZones(notification.createdDate, 'HH:mm:ss')}`;
  }

  public isNotificationActive(notification: INotification): boolean {
    return !notification.deletedDate;
  }

  public getNotificationsReportsLabel(notification: INotification): string {
    return notification.notificationsReports.map((notificationReport) => notificationReport.reportNumber).join(', ');
  }

  public isNotificationWithEmail(notification: INotification): boolean {
    return !!notification.emailDestinations;
  }

  public getActionsColumnTemplate(notification: INotification): JSX.Element {
    const { onDeactivateNotificationButtonClick, i18n } = this.props;

    return (
      <div className='btn-group'>
        <button
          type='button'
          className={`btn btn-sm btn-light ${!onDeactivateNotificationButtonClick || notification.deletedDate ? 'd-none' : ''}`}
          title={i18n.t('notificationsList.actions.deactivate')}
          onClick={() => this.onDeactivateNotificationButtonClick(notification)}
        >
          <span className='icon icon-app-item-remove' />
        </button>
      </div>
    );
  }

  public getViewDateLabel(notificationUser: INotificationUser): string {
    return `${dateHelpers.toShortDate(notificationUser.viewDate, true)} ${dateHelpers.toDateWithoutZones(notificationUser.viewDate, 'HH:mm:ss')}`;
  }

  public onPagerComponentClick(e: any) {
    if (e.currentPage !== this.props.currentPage) {
      this.props.onCurrentPageChange(e.currentPage);
    }
  }

  public onPageSizesDropdownChange(e: any) {
    if (e.pageSize !== this.props.pageSize) {
      this.props.onPageSizeChange(e.pageSize);
    }
  }

  public render() {
    const { notifications, isViewStatus, i18n } = this.props;

    return (
      <div className='notifications-grid'>
        <GridComponent
          dataSource={notifications}
          toolbar={this.toolbarOptions}
          allowSorting={true}
          allowPaging={true}   /* Si no se incluye este paginador (oculto por css), no se muestra el dropdown en el customizado */
          allowExcelExport={true}
          allowResizing={true}
          sortSettings={this.sortOptions}
          pageSettings={{ pageSize: pagerUtils.getMaxPageSize() }}
          locale={i18n.language}
          ref={(grid: GridComponent): GridComponent => this.gridComponent = grid}
          toolbarClick={(args) => this.onToolbarClick(args)}
          dataBound={() => this.onGridComponentDataBound()}
          actionBegin={(args) => this.onGridComponentActionBegin(args as SortEventArgs)}
        >
          <ColumnsDirective>
            <ColumnDirective
              headerText={this.props.i18n.t('notification.subject')}
              field='subject'
              width='150'
              template={isViewStatus ?
                (notificationUser: INotificationUser) => this.getSubjectColumnTemplateViewStatus(notificationUser)
                :
                (notification: INotification) => this.getSubjectColumnTemplate(notification)
              }
            />
            <ColumnDirective
              headerText={this.props.i18n.t('notification.scope')}
              field='scope'
              width='100'
              valueAccessor={(_field, data) => this.getNotificationScopeLabel(data as INotification)}
            />
            <ColumnDirective
              headerText={this.props.i18n.t('notification.createdDate')}
              field='createdDate'
              width='150'
              valueAccessor={(_field, data) => this.getCreatedDateLabel(data as INotification)}
            />
            <ColumnDirective
              headerText={this.props.i18n.t('notificationsList.columns.active')}
              field='deletedDate'
              width='80'
              type='boolean'
              valueAccessor={(_field, data) => this.isNotificationActive(data as INotification)}
              displayAsCheckBox={true}
            />
            <ColumnDirective headerText={this.props.i18n.t('notification.company')} field='companyName' width='150' />
            <ColumnDirective
              headerText={this.props.i18n.t('notification.notificationsReports')}
              width='150'
              valueAccessor={(_field, data) => this.getNotificationsReportsLabel(data as INotification)}
            />
            {!isViewStatus && <ColumnDirective
              headerText={this.props.i18n.t('notificationsList.columns.email')}
              field='emailDestinations'
              width='80'
              type='boolean'
              valueAccessor={(_field, data) => this.isNotificationWithEmail(data as INotification)}
              displayAsCheckBox={true}
            />}
            {!isViewStatus && <ColumnDirective
              width='100'
              template={(notification: INotification) => this.getActionsColumnTemplate(notification)}
            />}
            {isViewStatus && <ColumnDirective headerText={this.props.i18n.t('notificationUser.user')} field='userEmail' width='180' />}
            {isViewStatus && <ColumnDirective
              headerText={this.props.i18n.t('notificationUser.viewDate')}
              field='viewDate'
              width='150'
              valueAccessor={(_field, data) => this.getViewDateLabel(data as INotificationUser)}
            />}
          </ColumnsDirective>
          <Inject services={[Sort, Page, Toolbar, ExcelExport, Resize]} />
        </GridComponent>
        {notifications.length ?
          <PagerComponent
            pageSize={pagerUtils.getDefaultPageSize()}
            pageCount={pagerUtils.getPageCount()}
            pageSizes={pagerUtils.getPageSizes()}
            ref={(pagerComponent: PagerComponent): PagerComponent => this.pagerComponent = pagerComponent}
            click={(e) => this.onPagerComponentClick(e)}
            dropDownChanged={(e) => this.onPageSizesDropdownChange(e)}
          />
          :
          null
        }
      </div>
    );
  }

  private onDeactivateNotificationButtonClick(notification: INotification) {
    if (this.props.onDeactivateNotificationButtonClick && !notification.deletedDate) {
      this.props.onDeactivateNotificationButtonClick(notification.id);
    }
  }
}

export default withRouter(withTranslation()(NotificationsGrid));
