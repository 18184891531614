// tslint:disable: no-bitwise
export enum RequestCustomFieldSettingsOption {
  LIST_ALL = 0x01 << 6,
  LIST_OPEN = 0x01 << 5,
  LIST_IN_PROGRESS = 0x01 << 4,
  FILTER_ALL = 0x01 << 3,
  FILTER_OPEN = 0x01 << 2,
  FILTER_IN_PROGRESS = 0x01 << 1,
  FORM = 0x01
}
