import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { FormValidator, FormEventArgs } from '@syncfusion/ej2-react-inputs';
import { DialogComponent } from '@aitex/tsx-extranet-dialogs';

import { ISaveAdvancedFilterDialogProps } from '../../../interfaces/props/IDialogsProps';
import { IAdvancedFilter } from '../../../common/model/advancedFilter.model';
import { IAdvancedFilterTranslationBasic } from '../../../common/model/advancedFilterTranslation.model';
import { ICompanyCombo } from '../../../common/model/company.model';
import { IErrors } from '../../../common/model/errors.model';
import { AdvancedFilterType } from '../../../common/model/enumerations/advancedFilterType.model';
import { UserType } from '../../../common/model/enumerations/userType.model';
import { Validator } from '../../../common/model/enumerations/validator.model';
import { advancedFiltersService } from '../../../services';
import * as dialogUtils from '../../../utils/dialog.utils';
import * as formValidatorUtils from '../../../utils/formValidator.utils';
import * as advancedFilterUtils from '../../../utils/advancedFilter.utils';
import { notifyError } from '../../../utils/toast.utils';
import AdvancedFilterSaveForm from '../../advancedFilterSaveForm/advancedFilterSaveForm';

type SaveAdvancedFilterDialogPropsType = ISaveAdvancedFilterDialogProps & WithTranslation;

type SaveAdvancedFilterDialogStateType = ISaveAdvancedFilterDialogState;

class SaveAdvancedFilterDialog extends Component<SaveAdvancedFilterDialogPropsType, SaveAdvancedFilterDialogStateType> {
  public readonly SAVE_ADVANCED_FILTER_DIALOG_FORM_ID = 'saveAdvancedFilterDialogForm';

  private readonly CANCEL_BUTTON_ID = 'saveAdvancedFilterDialogCancel';
  private readonly SAVE_BUTTON_ID = 'saveAdvancedFilterDialogSave';

  private readonly CANNOT_SAVE_ADVANCED_FILTER_ERROR: IErrors = { UnknownError: ['dialogs.saveAdvancedFilterDialog.errors.cannotSave'] };

  private formValidator: FormValidator = null;
  private isValidatingAll = false;
  private auxErrors: IErrors = {};

  private initialState: ISaveAdvancedFilterDialogState = {
    advancedFilterToSave: null,
    isSaving: false,
    errors: {}
  };

  public constructor(props: SaveAdvancedFilterDialogPropsType) {
    super(props);

    this.state = this.initialState;
  }

  public componentDidUpdate(prevProps: SaveAdvancedFilterDialogPropsType): void {
    const { visible, advancedFilter } = this.props;

    if (!prevProps.visible && visible) {
      this.setState({ advancedFilterToSave: advancedFilter }, () => {
        if (this.state.advancedFilterToSave.translations.length === 0) {
          this.onTranslationChange({ codeIso: this.props.i18n.language, name: null });
        }
      });
    }
    if (prevProps.visible && !visible) {
      this.setState(this.initialState);
      this.resetFormValidator();
    }

    dialogUtils.manageButtonsClick(this.SAVE_BUTTON_ID, (): Promise<void> => this.saveAdvancedFilter(), this.CANCEL_BUTTON_ID, (): void => this.dismiss());
  }

  public async saveAdvancedFilter(): Promise<void> {
    let advancedFilter = this.state.advancedFilterToSave;

    try {
      if (!this.validateForm() || !this.checkAdvancedFilterIsValid()) {
        return;
      }

      this.setState({ isSaving: true });

      if (!advancedFilter) {
        throw this.CANNOT_SAVE_ADVANCED_FILTER_ERROR;
      }

      advancedFilter = advancedFilter.id ? await advancedFiltersService.update(advancedFilter) : await advancedFiltersService.create(advancedFilter);

      this.close(advancedFilter);
    } catch (error) {
      let errors = error as IErrors;
      if (errors.Name) {
        this.setState({ errors });
        return;
      }
      if (errors.UnknownError || (!errors.UnknownError && !errors.ControlledError)) {
        errors = this.CANNOT_SAVE_ADVANCED_FILTER_ERROR;
      }
      this.dismiss(errors);
    } finally {
      this.setState({ isSaving: false });
    }
  }

  public close(advancedFilter: IAdvancedFilter): void {
    this.formValidator = null;
    this.props.onClose(advancedFilter);
  }

  public dismiss(reason?: IErrors): void {
    this.props.onDismiss(reason);
  }

  public configureFormValidator(): void {
    if (this.props.visible) {
      if (!this.formValidator) {
        this.formValidator = formValidatorUtils.configureFormValidator(this.SAVE_ADVANCED_FILTER_DIALOG_FORM_ID, {
          Name: { required: [true, Validator.NOT_EMPTY] }
        });
        this.formValidator.validationComplete = (args) => {
          formValidatorUtils.validationComplete(args as FormEventArgs, this.isValidatingAll, this.state.errors, this.auxErrors, (errors): void => this.setState({ errors }));
        };
      }
    } else {
      this.formValidator = null;
    }
  }

  public onTranslationChange(translationBasic: IAdvancedFilterTranslationBasic): void {
    if (this.state.advancedFilterToSave) {
      this.setState({ advancedFilterToSave: advancedFilterUtils.upsertTranslation(this.state.advancedFilterToSave, translationBasic) });
    }
  }

  public onAddAdditionalTranslations(languages: string[]): void {
    if (this.state.advancedFilterToSave) {
      this.setState({ advancedFilterToSave: advancedFilterUtils.addAdditionalTranslations(this.state.advancedFilterToSave, this.props.i18n.language, languages) });
    }
  }

  public onRemoveAdditionalTranslations(): void {
    if (this.state.advancedFilterToSave) {
      this.setState({ advancedFilterToSave: advancedFilterUtils.removeAdditionalTranslations(this.state.advancedFilterToSave, this.props.i18n.language) });
    }
  }

  public onTypeChange(type: number): void {
    if (this.state.advancedFilterToSave) {
      this.setState({ advancedFilterToSave: { ...this.state.advancedFilterToSave, type } });

      if (type === AdvancedFilterType.COMPANY) {
        if (!this.isCurrentUserInternal()) {
          this.onCompanyChange(this.props.currentUserCompany);
        }
      } else {
        this.onCompanyChange(null);
      }
    }
  }

  public onCompanyChange(company: ICompanyCombo): void {
    if (this.state.advancedFilterToSave) {
      this.setState({ advancedFilterToSave: { ...this.state.advancedFilterToSave, companyId: company && company.id, companyName: company && company.name } });
    }
  }

  public onIsFavouriteChange(isFavourite: boolean): void{
    if (this.state.advancedFilterToSave) {
      this.setState({ advancedFilterToSave: { ...this.state.advancedFilterToSave, isFavourite } });
    }
  }

  public isCurrentUserInternal(): boolean {
    return this.props.currentUser.type === UserType.INTERNAL;
  }

  public render(): JSX.Element {
    const { visible, advancedFilter, currentUser, companiesDataManager, i18n } = this.props;

    return (
      <DialogComponent
        header={i18n.t('dialogs.saveAdvancedFilterDialog.title')}
        visible={visible && !!this.state.advancedFilterToSave}
        footerTemplate={dialogUtils.computeFooterTemplate(
          this.SAVE_BUTTON_ID,
          i18n.t('actions.save'),
          this.CANCEL_BUTTON_ID,
          i18n.t('actions.cancel'),
          this.state.isSaving,
          this.isSaveButtonDisabled()
        )}
        width='480px'
        onDismiss={(): void => this.dismiss()}
      >
        <div>
          {advancedFilter && currentUser && <AdvancedFilterSaveForm
            advancedFilter={this.state.advancedFilterToSave || advancedFilter}  // this.state.advancedFilterToSave is not set until componentDidUpdate()
            isCurrentUserInternal={this.isCurrentUserInternal()}
            currentLanguage={i18n.language}
            formId={this.SAVE_ADVANCED_FILTER_DIALOG_FORM_ID}
            companiesDataManager={companiesDataManager}
            errors={this.state.errors}
            onFormRef={(): void => this.configureFormValidator()}
            onTranslationChange={(translationBasic): void => this.onTranslationChange(translationBasic)}
            onAddAdditionalTranslations={(languages): void => this.onAddAdditionalTranslations(languages)}
            onRemoveAdditionalTranslations={(): void => this.onRemoveAdditionalTranslations()}
            onTypeChange={(type): void => this.onTypeChange(type)}
            onCompanyChange={(company): void => this.onCompanyChange(company)}
            onIsFavouriteChange={(isFavourite): void => this.onIsFavouriteChange(isFavourite)}
          />}
        </div>
      </DialogComponent>
    );
  }

  private isSaveButtonDisabled(): boolean {
    return !!this.state.errors.Name || this.state.isSaving;
  }

  private resetFormValidator(): void {
    if (!this.formValidator) {
      return;
    }

    if (this.formValidator.getInputElement('Name')) {
      this.formValidator.getInputElement('Name').parentElement.className = this.formValidator.getInputElement('Name').parentElement.className.replace('e-error', '');
    }
    this.formValidator.removeRules();
    this.formValidator.validationComplete = undefined;
  }

  private validateForm(): boolean {
    if (!this.formValidator) {
      return true;
    }

    this.isValidatingAll = true;
    const isFormValid = this.formValidator.validate();
    this.isValidatingAll = false;
    if (!isFormValid) {
      this.setState({ errors: this.auxErrors });
      this.auxErrors = {};
    }
    return isFormValid;
  }

  private checkAdvancedFilterIsValid(): boolean {
    if (!this.state.advancedFilterToSave.translations.every((translation) => {
      return translation.name ? translation.name.trim().length > 0 : false;
    })) {
      notifyError(this.props.i18n.t('dialogs.saveAdvancedFilterDialog.errors.emptyTranslation'));
      return false;
    }

    return true;
  }
}

interface ISaveAdvancedFilterDialogState {
  advancedFilterToSave: IAdvancedFilter;
  isSaving: boolean;
  errors: IErrors;
}

export default withTranslation()(SaveAdvancedFilterDialog);
