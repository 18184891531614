import React, { Component, ComponentType, Fragment } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { SwitchComponent, ChangeEventArgs } from '@syncfusion/ej2-react-buttons';
import { FormValidator, FormEventArgs } from '@syncfusion/ej2-inputs';
import { dialogsService } from '@aitex/tsx-extranet-dialogs';

import { RootState } from '../../store';
import { ISupplierDetailContainerStateProps, ISupplierDetailContainerDispatchProps } from '../../interfaces/props/ISupplierDetailProps';
import * as supplierDetailStore from '../../store/modules/supplierDetail.store';
import { IErrors } from '../../common/model/errors.model';
import { UserType } from '../../common/model/enumerations/userType.model';
import { Validator } from '../../common/model/enumerations/validator.model';
import { DialogId } from '../../common/model/enumerations/dialogId.model';
import { suppliersService } from '../../services';
import * as formValidatorUtils from '../../utils/formValidator.utils';
import FormError from '../../components/formError/formError';
import ValidationError from '../../components/validationError/validationError';
import TextBox from '../../components/filters/textBox';
import Unauthorized from '../../components/unauthorized/unauthorized';

type SupplierDetailContainerPropsType = PropsFromRedux & RouteComponentProps & WithTranslation;

class SupplierDetailContainer extends Component<SupplierDetailContainerPropsType> {
  public readonly SUPPLIER_DETAIL_FORM_ID = 'supplierDetailForm';

  private formValidator: FormValidator = null;
  private isValidatingAll = false;
  private auxErrors: IErrors = {};

  public constructor(props: SupplierDetailContainerPropsType) {
    super(props);

    const { reset, makeReadyForRendering, location } = this.props;

    reset();

    const isView = location.pathname.includes('/view');
    const isNew = location.pathname.includes('/new');
    makeReadyForRendering(isView, isNew);
  }

  public componentDidUpdate(prevProps: SupplierDetailContainerPropsType): void {
    const { isNew, fetchSupplier, location } = this.props;

    if (prevProps.isNew === null && isNew === false) {
      const supplierPathSubstring = '/supplier/';
      const id = location.pathname.substring(location.pathname.lastIndexOf(supplierPathSubstring) + supplierPathSubstring.length, location.pathname.lastIndexOf('/'));
      fetchSupplier(id, suppliersService);
    }
  }

  public componentWillUnmount(): void {
    const { isReadyForRendering, reset } = this.props;

    if (isReadyForRendering) {
      reset();
    }
  }

  public configureFormValidator(): void {
    if (!this.props.isView) {
      if (!this.formValidator) {
        this.formValidator = formValidatorUtils.configureFormValidator(this.SUPPLIER_DETAIL_FORM_ID, {
          Name: { required: [true, Validator.NOT_EMPTY] },
          Address: { required: [true, Validator.NOT_EMPTY] },
          City: { required: [true, Validator.NOT_EMPTY] },
          Province: { required: [true, Validator.NOT_EMPTY] },
          PostalCode: { required: [true, Validator.NOT_EMPTY] },
          Country: { required: [true, Validator.NOT_EMPTY] }
        });
        this.formValidator.validationComplete = (args) => {
          formValidatorUtils.validationComplete(args as FormEventArgs, this.isValidatingAll, this.props.errors, this.auxErrors, (errors) => this.props.setErrors(errors));
        };
      }
    } else {
      this.formValidator = null;
    }
  }

  public onActiveChange(e: ChangeEventArgs): void {
    this.props.setSupplierActive(e.checked);
  }

  public isUnauthorized(): boolean {
    const { isView, isCurrentUserInternal } = this.props;

    return !isView && !isCurrentUserInternal;
  }

  public save(): void {
    const { supplier, saveSupplier, history } = this.props;

    if (!this.validateForm()) {
      return;
    }

    dialogsService.openDialog(DialogId.GENERIC_CONFIRMATION)
      .then((): void => {
        saveSupplier(supplier, suppliersService, history);
        this.formValidator = null;
      })
      .catch(() => {
        // DO nothing
      });
  }

  public goBack(): void {
    const { isView, history } = this.props;

    const goBackPath = '/suppliers-list';

    if (isView) {
      history.push(goBackPath);
    } else {
      dialogsService.openDialog(DialogId.DISCARD_CHANGES)
        .then((): void => history.push(goBackPath))
        .catch(() => {
          // DO nothing
        });
    }
  }

  public render(): JSX.Element {
    const {
      isReadyForRendering,
      supplier,
      isView,
      isNew,
      setSupplierName,
      setSupplierCode,
      setSupplierContractNumber,
      setSupplierAddress,
      setSupplierCity,
      setSupplierProvince,
      setSupplierPostalCode,
      setSupplierCountry,
      errors,
      i18n
    } = this.props;

    if (!isReadyForRendering) {
      return null;
    }

    if (!isNew && !supplier.id) {
      return null; // TODO: loading
    }

    if (this.isUnauthorized()) {
      return <Unauthorized />;
    }

    let title = <h2>{i18n.t('supplierDetail.view.title')}</h2>;
    if (!isView) {
      title = isNew ? <h2>{i18n.t('supplierDetail.new.title')}</h2> : <h2>{i18n.t('supplierDetail.edit.title')}</h2>;
    }
    return (
      <Fragment>
        {title}
        <div className='card'>
          <div className='card-body'>
            <form id={this.SUPPLIER_DETAIL_FORM_ID} ref={(): void => this.configureFormValidator()}>
              <FormError errors={errors} />
              <div className='row'>
                <div className='col'>
                  <div className='form-group'>
                    <div className='autocomplete-input'>
                      <div className='autocomplete-label'>{i18n.t('supplier.name')}</div>
                      <TextBox
                        name='Name'
                        value={supplier.name}
                        readonly={isView}
                        onChange={(value): supplierDetailStore.SetSupplierNameAction => setSupplierName(value)}
                      />
                    </div>
                    <ValidationError errors={errors} errorKey={'Name'} />
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-12 col-md-6'>
                  <div className='form-group'>
                    <div className='autocomplete-input'>
                      <div className='autocomplete-label'>{i18n.t('supplier.code')}</div>
                      <TextBox
                        name='Code'
                        value={supplier.code}
                        readonly={isView}
                        onChange={(value): supplierDetailStore.SetSupplierCodeAction => setSupplierCode(value)}
                      />
                    </div>
                    <ValidationError errors={errors} errorKey={'Code'} />
                  </div>
                </div>
                <div className='col-12 col-md-6'>
                  <div className='form-group'>
                    <div className='autocomplete-input'>
                      <div className='autocomplete-label'>{i18n.t('supplier.contractNumber')}</div>
                      <TextBox
                        name='ContractNumber'
                        value={supplier.contractNumber}
                        readonly={isView}
                        onChange={(value): supplierDetailStore.SetSupplierContractNumberAction => setSupplierContractNumber(value)}
                      />
                    </div>
                    <ValidationError errors={errors} errorKey={'ContractNumber'} />
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-12 col-md-6'>
                  <div className='form-group'>
                    <div className='autocomplete-input'>
                      <div className='autocomplete-label'>{i18n.t('supplier.address')}</div>
                      <TextBox
                        name='Address'
                        value={supplier.address}
                        readonly={isView}
                        onChange={(value): supplierDetailStore.SetSupplierAddressAction => setSupplierAddress(value)}
                      />
                    </div>
                    <ValidationError errors={errors} errorKey={'Address'} />
                  </div>
                </div>
                <div className='col-12 col-md-6'>
                  <div className='form-group'>
                    <div className='autocomplete-input'>
                      <div className='autocomplete-label'>{i18n.t('supplier.city')}</div>
                      <TextBox
                        name='City'
                        value={supplier.city}
                        readonly={isView}
                        onChange={(value): supplierDetailStore.SetSupplierCityAction => setSupplierCity(value)}
                      />
                    </div>
                    <ValidationError errors={errors} errorKey={'City'} />
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-12 col-md-6'>
                  <div className='form-group'>
                    <div className='autocomplete-input'>
                      <div className='autocomplete-label'>{i18n.t('supplier.province')}</div>
                      <TextBox
                        name='Province'
                        value={supplier.province}
                        readonly={isView}
                        onChange={(value): supplierDetailStore.SetSupplierProvinceAction => setSupplierProvince(value)}
                      />
                    </div>
                    <ValidationError errors={errors} errorKey={'Province'} />
                  </div>
                </div>
                <div className='col-12 col-md-6'>
                  <div className='form-group'>
                    <div className='autocomplete-input'>
                      <div className='autocomplete-label'>{i18n.t('supplier.postalCode')}</div>
                      <TextBox
                        name='PostalCode'
                        value={supplier.postalCode}
                        readonly={isView}
                        onChange={(value): supplierDetailStore.SetSupplierPostalCodeAction => setSupplierPostalCode(value)}
                      />
                    </div>
                    <ValidationError errors={errors} errorKey={'PostalCode'} />
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-12 col-md-6'>
                  <div className='form-group'>
                    <div className='autocomplete-input'>
                      <div className='autocomplete-label'>{i18n.t('supplier.country')}</div>
                      <TextBox
                        name='Country'
                        value={supplier.country}
                        readonly={isView}
                        onChange={(value): supplierDetailStore.SetSupplierCountryAction => setSupplierCountry(value)}
                      />
                    </div>
                    <ValidationError errors={errors} errorKey={'Country'} />
                  </div>
                </div>
                <div className='col-12 col-md-6'>
                  <div className='form-group d-flex' style={{ marginTop: '7px' }}>
                    <label htmlFor='active' className='mr-2'>{i18n.t('supplier.active')}</label>
                    <div style={{ marginTop: '0.15rem' }}>
                      <SwitchComponent
                        id='active'
                        checked={supplier.active}
                        disabled={isView}
                        change={(e): void => this.onActiveChange(e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                {!isView && <div className='col-auto'>
                  <button type='button' className='btn btn-secondary' onClick={() => this.save()}>
                    <span>{i18n.t('actions.save')}</span>
                  </button>
                </div>}
                <div className='col-auto'>
                  <button type='button' className='btn btn-light' onClick={() => this.goBack()}>
                    {isView ?
                      <span>{i18n.t('actions.goBack')}</span>
                      :
                      <span>{i18n.t('actions.cancel')}</span>
                    }
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    );
  }

  private validateForm(): boolean {
    if (!this.formValidator) {
      return true;
    }

    this.isValidatingAll = true;
    const isFormValid = this.formValidator.validate();
    this.isValidatingAll = false;
    if (!isFormValid) {
      this.props.setErrors(this.auxErrors);
      this.auxErrors = {};
    }
    return isFormValid;
  }
}

const mapStateToProps = (state: RootState): ISupplierDetailContainerStateProps => ({
  isReadyForRendering: state.supplierDetailStore.isReadyForRendering,
  supplier: state.supplierDetailStore.supplier,
  isView: state.supplierDetailStore.isView,
  isNew: state.supplierDetailStore.isNew,
  errors: state.supplierDetailStore.errors,
  isCurrentUserInternal: state.currentUserStore.user.type === UserType.INTERNAL
});

const mapDispatchToProps: ISupplierDetailContainerDispatchProps = {
  reset: supplierDetailStore.reset,
  makeReadyForRendering: supplierDetailStore.makeReadyForRendering,
  setSupplierCode: supplierDetailStore.setSupplierCode,
  setSupplierName: supplierDetailStore.setSupplierName,
  setSupplierContractNumber: supplierDetailStore.setSupplierContractNumber,
  setSupplierAddress: supplierDetailStore.setSupplierAddress,
  setSupplierCity: supplierDetailStore.setSupplierCity,
  setSupplierProvince: supplierDetailStore.setSupplierProvince,
  setSupplierPostalCode: supplierDetailStore.setSupplierPostalCode,
  setSupplierCountry: supplierDetailStore.setSupplierCountry,
  setSupplierActive: supplierDetailStore.setSupplierActive,
  setErrors: supplierDetailStore.setErrors,
  fetchSupplier: supplierDetailStore.fetchSupplier,
  saveSupplier: supplierDetailStore.saveSupplier
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default compose(
  withRouter,
  withTranslation(),
  connector
)(SupplierDetailContainer) as ComponentType;
