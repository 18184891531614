import React from 'react';
import { Button } from 'react-bootstrap';
import i18n from '../../i18n';
import './error404.scss';
import { Link } from 'react-router-dom';

const Error404 = (): JSX.Element => {
  return (
    <div className='error-container d-flex align-items-center justify-content-center flex-column'>
      <span className='error-title mb-1'>{i18n.t('errorPage.error404.title')}</span>
      <span className='mb-3'>{i18n.t('errorPage.error404.subtitle')}</span>
      <Link to='/'><Button variant='secondary'>{i18n.t('errorPage.error404.button')}</Button></Link>
    </div>
  );
};

export default Error404;
