import React, { Component, ComponentType } from 'react';
import { withRouter } from 'react-router-dom';
import { reportStatusService } from '../../services';
import { IReportStatusState } from '../../interfaces/states/IReportStatusState';
import LoginForm from './loginForm';
import ReportStatusInfo from './reportStatusInfo';
import { notifyError } from '../../utils/toast.utils';
import { WithTranslation, withTranslation } from 'react-i18next';
import IReportStatus from '../../interfaces/IReportStatus';
import withPageTracking from '../../hoc/withPageTracking';
import { compose } from 'redux';

interface IReportStatusProps {
  location?: {
    pathname?: string;
  };
}

type ReportStatusState = IReportStatusState;
type ReportStatusProps = WithTranslation & IReportStatusProps;

class ReportStatus extends Component<ReportStatusProps, ReportStatusState> {
  public constructor(props: ReportStatusProps) {
    super(props);
    this.state = {
      seasons: null,
      showReportStatusInfo: false,
      reportStatus: null
    };
  }

  public componentDidMount = async () => {
    try {
      const seasons = await reportStatusService.getAllSeasons();
      this.setState({ seasons });
    } catch (error) {
      notifyError(this.props.i18n.t('login.suppliers.errorMessage.noSeasons'));
      return;
    }
  }

  public returnToLogin = () => {
    this.setState({ showReportStatusInfo: false });
  }

  public handleSubmit = async (reportStatus: IReportStatus) => {
    this.setState({ reportStatus, showReportStatusInfo: true });
  }

  public render() {
    return (
      <>
        <section className='container'>
          {this.state.showReportStatusInfo ?
            <ReportStatusInfo
              returnToLogin={this.returnToLogin}
              reportStatus={this.state.reportStatus}
            />
            : <LoginForm
              handleSubmit={this.handleSubmit}
              seasons={this.state.seasons}
            />
          }
        </section>
      </>
    );
  }
}

export default compose(
  withRouter,
  withTranslation(),
  withPageTracking
)(ReportStatus) as ComponentType;
