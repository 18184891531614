import moment from 'moment';

import { URL } from '../common/constants';
import { IReportCombo, IReport, IReportEmails } from '../common/model/report.model';
import { IReportCustomFields } from '../common/model/reportCustomFields.model';
import { ICustomFieldPermission } from '../common/model/customFieldPermission.model';
import { IAnnex } from '../common/model/annex.model';
import { ICertificate } from '../common/model/certificate.model';
import { IReference } from '../common/model/reference.model';
import { INotificationWithViewInfo } from '../common/model/notification.model';
import { INote } from '../common/model/note.model';
import { IReportChangeHistory } from '../common/model/reportChangeHistory.model';
import { ICustomFieldOptions } from '../common/model/customFieldOptions.model';
import { IEmail } from '../common/model/email.model';
import * as services from '.';
import * as reportUtils from '../utils/report.utils';
import * as certificateUtils from '../utils/certificate.utils';
import { format } from '../utils/common.utils';

export interface IReportsService {
  getReportStates(): Promise<any>;
  getAllReports(active: boolean): Promise<any>;
  getReportsByCompanyId(companyId: string): Promise<any>;
  getSelectedReport(id: string): Promise<any>;
  requestAdvance(id: string, emails: string): Promise<any>;
  getReportDetail(id: string): Promise<IReport>;
  fetchCustomFieldPermissions(id: string): Promise<ICustomFieldPermission[]>;
  fetchAnnexes(id: string): Promise<IAnnex[]>;
  fetchCertificates(id: string): Promise<ICertificate[]>;
  fetchReferences(id: string): Promise<IReference[]>;
  fetchNotifications(id: string): Promise<INotificationWithViewInfo[]>;
  fetchNotes(id: string): Promise<INote[]>;
  fetchReportChangesHistory(id: string): Promise<IReportChangeHistory[]>;
  fetchVersions(id: string): Promise<IReport[]>;
  fetchCustomFieldOptions(code: string): Promise<ICustomFieldOptions[]>;
  downloadFile(report: IReportCombo): Promise<void>;
  downloadMultipleFiles(ids: string[]): Promise<void>;
  updateCustomFields(id: string, customFields: IReportCustomFields): Promise<IReport>;
  sendEmail(id: string, email: IEmail): Promise<void>;
  getAddressesToSendEmail(id: string): Promise<string>;
}

class ReportsService implements IReportsService {
  private cacheReportStates: any[];

  public constructor(private httpClient: services.IHttpClient) {

  }

  public async getReportStates() {
    if (this.cacheReportStates) {
      return Promise.resolve(this.cacheReportStates);
    }

    const reportStates = await this.httpClient.get(`${URL.WEB.BASE}${URL.WEB.REPORTSTATES}`);

    this.cacheReportStates = reportStates;

    return reportStates;
  }

  public async getAllReports(active: boolean) {
    const allReports = await this.httpClient.get(`${URL.WEB.BASE}${URL.WEB.REPORTSACTIVE}${active}`);
    return allReports;
  }

  public async getReportsByCompanyId(companyId: string) {
    const reportsByCompanyId = await this.httpClient.get(`${URL.WEB.BASE}${URL.WEB.REPORTSBYCOMPANYID}${companyId}`);
    return reportsByCompanyId;
  }

  public async getSelectedReport(id: string) {
    const allReports = await this.httpClient.get(`${URL.WEB.BASE}${URL.WEB.REPORTS}/${id}`);
    return allReports;
  }

  public async requestAdvance(id: string, emails: string) {
    const requestAdvanceUrl = format(URL.WEB.REPORTSREQUESADVANCE, [id]);
    const allReports = await this.httpClient.put(`${URL.WEB.BASE}${requestAdvanceUrl}`, { AdvanceEmails: emails });
    return allReports;
  }

  public async getReportDetail(id: string): Promise<IReport> {
    return reportUtils.initDates(await this.httpClient.getCustom<IReport>(`${URL.WEB.BASE}${URL.WEB.REPORTSDETAIL}${id}`));
  }

  public async fetchCustomFieldPermissions(id: string): Promise<ICustomFieldPermission[]> {
    try {
      return (await this.httpClient.getCustom<ICustomFieldPermission[]>(`${URL.WEB.BASE}${URL.WEB.REPORTS}/${id}/customFieldsPermissions`));
    } catch (error) {
      return [];
    }
  }

  public async fetchAnnexes(id: string): Promise<IAnnex[]> {
    try {
      return await this.httpClient.getCustom<IAnnex[]>(`${URL.WEB.BASE}${URL.WEB.REPORTS}/${id}/annexes`);
    } catch (error) {
      return [];
    }
  }

  public async fetchCertificates(id: string): Promise<ICertificate[]> {
    try {
      return (await this.httpClient.getCustom<ICertificate[]>(`${URL.WEB.BASE}${URL.WEB.REPORTS}/${id}/certificates`))
        .map((certificate) => certificateUtils.init(certificate));
    } catch (error) {
      return [];
    }
  }

  public async fetchReferences(id: string): Promise<IReference[]> {
    try {
      return await this.httpClient.getCustom<IReference[]>(`${URL.WEB.BASE}${URL.WEB.REPORTS}/${id}/references`);
    } catch (error) {
      return [];
    }
  }

  public async fetchNotifications(id: string): Promise<INotificationWithViewInfo[]> {
    try {
      return (await this.httpClient.getCustom<INotificationWithViewInfo[]>(`${URL.WEB.BASE}${URL.WEB.REPORTS}/${id}/notifications`))
        .map((notification) => ({ ...notification, createdDate: moment(notification.createdDate) }));
    } catch (error) {
      return [];
    }
  }

  public async fetchNotes(id: string): Promise<INote[]> {
    try {
      return (await this.httpClient.getCustom<INote[]>(`${URL.WEB.BASE}${URL.WEB.REPORTS}/${id}/notes`))
        .map((note) => ({ ...note, createdDate: moment(note.createdDate) }));
    } catch (error) {
      return [];
    }
  }

  public async fetchReportChangesHistory(id: string): Promise<IReportChangeHistory[]> {
    try {
      return (await this.httpClient.getCustom<IReportChangeHistory[]>(`${URL.WEB.BASE}${URL.WEB.REPORTS}/${id}/changesHistory`))
        .map((reportChangeHistory) => ({ ...reportChangeHistory, createdDate: moment(reportChangeHistory.createdDate) }));
    } catch (error) {
      return [];
    }
  }

  public async fetchVersions(id: string): Promise<IReport[]> {
    try {
      return (await this.httpClient.getCustom<IReport[]>(`${URL.WEB.BASE}${URL.WEB.REPORTS}/${id}/versions`))
        .map((report) => reportUtils.initDates(report))
        .sort((a, b) => b.version - a.version);
    } catch (error) {
      return [];
    }
  }

  public async fetchCustomFieldOptions(code: string): Promise<ICustomFieldOptions[]> {
    try {
      return (await this.httpClient.getCustom<ICustomFieldOptions[]>(`${URL.WEB.BASE}${URL.WEB.REPORT_CUSTOM_FIELDS}/${code}/options`));
    } catch (error) {
      return [];
    }
  }

  public downloadFile(report: IReportCombo): Promise<void> {
    const filenameWithoutExtension = report.reportNumber;
    return this.httpClient.downloadFile(`${URL.WEB.BASE}${URL.WEB.REPORTS}/${report.id}/file`, filenameWithoutExtension);
  }

  public downloadMultipleFiles(ids: string[]): Promise<void> {
    const idsJoined = ids.join(',');
    return this.httpClient.downloadFile(`${URL.WEB.BASE}${URL.WEB.REPORTS}/files`, undefined, { params: { ids: idsJoined }});
  }

  public async updateCustomFields(id: string, customFields: IReportCustomFields): Promise<IReport> {
    return reportUtils.initDates(await this.httpClient.patchCustom(`${URL.WEB.BASE}${URL.WEB.REPORTS}/${id}`, { id, customFields }));
  }

  public sendEmail(id: string, email: IEmail): Promise<void> {
    return this.httpClient.postCustom(`${URL.WEB.BASE}${URL.WEB.REPORTS}/${id}/sendEmail`, email);
  }
  
  public async getAddressesToSendEmail(id: string): Promise<string> {
    try {
      const result = await this.httpClient.getCustom<IReportEmails>(`${URL.WEB.BASE}${URL.WEB.REPORTS}/${id}/emails`);

      return result.emails;
    } catch (error) {
      return '';
    }
  }
}

export const reportsService = new ReportsService(services.httpClient);
