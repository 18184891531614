import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { DropDownListComponent, MultiSelectComponent, ChangeEventArgs, FieldSettingsModel, MultiSelectChangeEventArgs, Inject as DropdownInject, CheckBoxSelection } from '@syncfusion/ej2-react-dropdowns';

import { IReportsListFilterProps } from '../../../interfaces/props/IReportsListProps';
import { IStringToAnyDictionary } from '../../../common/model/stringToAnyDictionary.model';
import { ReportStatus } from '../../../common/model/enumerations/reportStatus.model';
import { ReportsListMode } from '../../../common/model/enumerations/reportsListMode.model';
import { BooleanFilter } from '../../../common/model/enumerations/booleanFilter.model';
import { enumToArray } from '../../../utils/enum.utils';
import * as reportStatusUtils from '../../../utils/reportStatus.utils';
import TextBox from '../../../components/filters/textBox';
import DateRange from '../../../components/filters/dateRange';
import DatePastRange from '../../../components/filters/datePastRange';

type ReportsListFilterPropsType = IReportsListFilterProps & WithTranslation;

class ReportsListFilter extends Component<ReportsListFilterPropsType> {
  public statusDropDownListComponent: DropDownListComponent = null;
  public statusOptions: IStringToAnyDictionary[] = [];
  public statusFields: FieldSettingsModel = { text: 'text', value: 'value', iconCss: 'iconCss' };

  public booleanOptions: IStringToAnyDictionary[] = [];
  public booleanFields: FieldSettingsModel = { text: 'text', value: 'value' };

  public reportCustomFieldOptionsFields: FieldSettingsModel = { text: 'name', value: 'id' };

  public constructor(props: ReportsListFilterPropsType) {
    super(props);

    const { i18n } = this.props;

    this.statusOptions = enumToArray(ReportStatus, 'string', 'text', 'value').map((item) => ({
      ...item,
      text: reportStatusUtils.getLocalizedText(i18n, item.value as string),
      iconCss: reportStatusUtils.getIconCss(item.value as string)
    }));

    this.booleanOptions = enumToArray(BooleanFilter, 'number', 'text', 'value').map((item) => ({ ...item, text: i18n.t('enums.booleanFilter.' + item.text) }));
  }

  public componentDidUpdate() {
    this.updateStatusDropDownListComponentValueTemplate();
  }

  public isStatusDropDownListComponentEnabled(): boolean {
    return this.props.listMode === ReportsListMode.ALL;
  }

  public onStatusChange(e: ChangeEventArgs) {
    const status = e.value as string;
    this.props.onStatusChange(status);
  }

  public onClosedChange(e: ChangeEventArgs) {
    const closed = e.value as BooleanFilter;
    this.props.onClosedChange(closed);
  }

  public onCopyChange(e: ChangeEventArgs) {
    const copy = e.value as BooleanFilter;
    this.props.onCopyChange(copy);
  }

  public onAitexResultIdChange(e: ChangeEventArgs) {
    const aitexResultId = e.value as string;
    this.props.onAitexResultIdChange(aitexResultId);
  }

  public onCountryIdChange(e: ChangeEventArgs) {
    const countryId = e.value as string;
    this.props.onCountryIdChange(countryId);
  }

  public onSeasonIdChange(e: ChangeEventArgs) {
    const seasonId = e.value as string;
    this.props.onSeasonIdChange(seasonId);
  }

  public onOfficeIdChange(e: ChangeEventArgs) {
    const officeId = e.value as string;
    this.props.onOfficeIdChange(officeId);
  }

  public onDivisionIdsChange(e: MultiSelectChangeEventArgs) {
    const divisionId = e.value as string[];
    this.props.onDivisionIdChange(divisionId);
  }

  public onSectionIdChange(e: ChangeEventArgs) {
    const sectionId = e.value as string;
    this.props.onSectionIdChange(sectionId);
  }

  public onBrandIdChange(e: ChangeEventArgs) {
    const brandId = e.value as string;
    this.props.onBrandIdChange(brandId);
  }

  public onQualityControlIdChange(e: ChangeEventArgs) {
    const qualityControlId = e.value as string;
    this.props.onQualityControlIdChange(qualityControlId);
  }

  public onBuyerIdChange(e: ChangeEventArgs) {
    const buyerId = e.value as string;
    this.props.onBuyerIdChange(buyerId);
  }

  public shouldShowEntryDateFilter(): boolean {
    return this.props.listMode === ReportsListMode.IN_PROGRESS || this.props.isEciUser;
  }

  public shouldShowExpectedDateFilter(): boolean {
    return this.props.listMode === ReportsListMode.IN_PROGRESS && !this.props.isEciUser;
  }

  public shouldShowEndDateFilter(): boolean {
    return this.props.listMode === ReportsListMode.FINALIZED;
  }

  public shouldShowExpirationDateFilter(): boolean {
    return this.props.listMode === ReportsListMode.FINALIZED && !this.props.isEciUser;
  }

  public shouldShowClientFilter(): boolean {
    return this.props.isCurrentUserInternal || this.props.hasCurrentUserChain;
  }

  public shouldShowCustomFieldFilter(customFieldCode: string): boolean {
    const { listMode, reportCustomFieldSettings } = this.props;

    const settings = reportCustomFieldSettings.find((s) => s.customFieldCode === customFieldCode);

    if (!settings) {
      return false;
    }

    switch (listMode) {
      case ReportsListMode.ALL:
        return settings.filterAll;
      case ReportsListMode.IN_PROGRESS:
        return settings.filterInProgress;
      case ReportsListMode.FINALIZED:
        return settings.filterFinalized;
      default:
        return false;
    }
  }

  public shouldShowModelColumn(): boolean {
    return this.props.isEciUser || this.shouldShowCustomFieldFilter('model');
  }

  public shouldShowIncidenceColumn(): boolean {
    return this.props.isEciUser || this.shouldShowCustomFieldFilter('incidence');
  }

  public shouldShowAitexResultIdColumn(): boolean {
    return this.props.isEciUser || this.shouldShowCustomFieldFilter('aitexResultId');
  }

  public shouldShowSeasonIdColumn(): boolean {
    return this.props.isEciUser || this.shouldShowCustomFieldFilter('seasonId');
  }

  public render() {
    const {
      filter,
      aitexResultOptions,
      countryOptions,
      seasonOptions,
      officeOptions,
      divisionOptions,
      sectionOptions,
      brandOptions,
      qualityControlOptions,
      buyerOptions,
      isFetching,
      //currentUserOfficeName,
      isEciUser,
      onReportNumberChange,
      onReferenceChange,
      onRequerimentStandardChange,
      onEntryDateChange,
      onExpectedDateChange,
      onEndDateChange,
      onExpirationDateChange,
      onOfferNumberChange,
      onClientChange,
      onCollectionChange,
      onColourChange,
      onTargetChange,
      onModelChange,
      onIncidenceChange,
      onEciOrderNumberChange,
      onUnecoChange,
      onSupplierChange,
      onFilterButtonClick,
      i18n
    } = this.props;

    return (
      <form onSubmit={(e: React.FormEvent) => { e.preventDefault(); onFilterButtonClick(); }}>
        <div className='row'>
          <div className='col-12 col-md-6'>
            <div className='form-group'>
              <div className='autocomplete-input'>
                <div className='autocomplete-label'>{i18n.t('report.reportNumber')}</div>
                <TextBox
                  name='reportNumber'
                  value={filter.reportNumber}
                  showClearButton={true}
                  onChange={(value) => onReportNumberChange(value)}
                />
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <div className='form-group'>
              <div className='autocomplete-input'>
                <div className='autocomplete-label'>{i18n.t('report.status')}</div>
                <DropDownListComponent
                  fields={this.statusFields}
                  dataSource={this.statusOptions}
                  value={filter.status}
                  enabled={this.isStatusDropDownListComponentEnabled()}
                  ref={(dropDownListComponent: DropDownListComponent) => {
                    this.statusDropDownListComponent = dropDownListComponent;
                    this.updateStatusDropDownListComponentValueTemplate();
                  }}
                  sortOrder="Ascending"
                  change={(e) => this.onStatusChange(e)}
                />
              </div>
            </div>
          </div>
          {this.shouldShowClientFilter() && <div className='col-12 col-md-6'>
            <div className='form-group'>
              <div className='autocomplete-input'>
                <div className='autocomplete-label'>{i18n.t('report.client')}</div>
                <TextBox
                  name='client'
                  value={filter.client}
                  showClearButton={true}
                  onChange={(value) => onClientChange(value)}
                />
              </div>
            </div>
          </div>}
          {this.shouldShowEntryDateFilter() && <div className='col-12 col-md-6'>
            <div className='form-group'>
              <DatePastRange
                name={i18n.t('report.entryDate')}
                value={filter.entryDate}
                i18n={i18n}
                onChangeInput={(value) => onEntryDateChange(value)}
              />
            </div>
          </div>}
          {this.shouldShowExpectedDateFilter() && <div className='col-12 col-md-6'>
            <div className='form-group'>
              <DateRange
                name={i18n.t('report.expectedDate')}
                value={filter.expectedDate}
                i18n={i18n}
                onChangeInput={(value) => onExpectedDateChange(value)}
              />
            </div>
          </div>}
          {this.shouldShowEndDateFilter() && <div className='col-12 col-md-6'>
            <div className='form-group'>
              <DatePastRange
                name={i18n.t('report.endDate')}
                value={filter.endDate}
                i18n={i18n}
                onChangeInput={(value) => onEndDateChange(value)}
              />
            </div>
          </div>}
          {this.shouldShowExpirationDateFilter() && <div className='col-12 col-md-6'>
            <div className='form-group'>
              <DateRange
                name={i18n.t('report.expirationDate')}
                value={filter.expirationDate}
                i18n={i18n}
                onChangeInput={(value) => onExpirationDateChange(value)}
              />
            </div>
          </div>}
          <div className='col-12 col-md-6'>
            <div className='form-group'>
              <div className='autocomplete-input'>
                <div className='autocomplete-label'>{i18n.t('report.reference')}</div>
                <TextBox
                  name='reference'
                  value={filter.reference}
                  showClearButton={true}
                  onChange={(value) => onReferenceChange(value)}
                />
              </div>
            </div>
          </div>
          {!isEciUser && <div className='col-12 col-md-6'>
            <div className='form-group'>
              <div className='autocomplete-input'>
                <div className='autocomplete-label'>{i18n.t('report.requerimentStandard')}</div>
                <TextBox
                  name='requerimentStandard'
                  value={filter.requerimentStandard}
                  showClearButton={true}
                  onChange={(value) => onRequerimentStandardChange(value)}
                />
              </div>
            </div>
          </div>}
          {!isEciUser && <div className='col-12 col-md-6'>
            <div className='form-group'>
              <div className='autocomplete-input'>
                <div className='autocomplete-label'>{i18n.t('report.offerNumber')}</div>
                <TextBox
                  name='offerNumber'
                  value={filter.offerNumber}
                  showClearButton={true}
                  onChange={(value) => onOfferNumberChange(value)}
                />
              </div>
            </div>
          </div>}
          {this.shouldShowModelColumn() && <div className='col-12 col-md-6'>
            <div className='form-group'>
              <div className='autocomplete-input'>
                <div className='autocomplete-label'>{i18n.t('reportCustomFields.model')}</div>
                <TextBox
                  name='model'
                  value={filter.model}
                  showClearButton={true}
                  onChange={(value) => onModelChange(value)}
                />
              </div>
            </div>
          </div>}
          {isEciUser && <div className='col-12 col-md-6'>
            <div className='form-group'>
              <div className='autocomplete-input'>
                <div className='autocomplete-label'>{i18n.t('reportCustomFields.eciOrderNumber')}</div>
                <TextBox
                  name='eciOrderNumber'
                  value={filter.eciOrderNumber}
                  showClearButton={true}
                  onChange={(value) => onEciOrderNumberChange(value)}
                />
              </div>
            </div>
          </div>}
          {isEciUser && <div className='col-12 col-md-6'>
            <div className='form-group'>
              <div className='autocomplete-input'>
                <div className='autocomplete-label'>{i18n.t('reportCustomFields.supplier')}</div>
                <TextBox
                  name='supplier'
                  value={filter.supplier}
                  showClearButton={true}
                  onChange={(value) => onSupplierChange(value)}
                />
              </div>
            </div>
          </div>}
          {isEciUser && <div className='col-12 col-md-6'>
            <div className='form-group'>
              <div className='autocomplete-input'>
                <div className='autocomplete-label'>{i18n.t('reportCustomFields.divisionId')}</div>
                {/*<DropDownListComponent
                  fields={this.reportCustomFieldOptionsFields}
                  dataSource={divisionOptions as IStringToAnyDictionary[]}
                  value={filter.divisionId}
                  showClearButton={true}
                  sortOrder="Ascending"
                  change={(e) => this.onDivisionIdChange(e)}
                />*/}

                <MultiSelectComponent
                  query={null} /* query will be set dynamically */
                  dataSource={divisionOptions as IStringToAnyDictionary[]}
                  fields={this.reportCustomFieldOptionsFields}
                  sortOrder='Ascending'
                  mode='CheckBox'
                  showDropDownIcon={true}
                  change={(e: MultiSelectChangeEventArgs): void => this.onDivisionIdsChange(e)}
                >
                  <DropdownInject services={[CheckBoxSelection]} />
                </MultiSelectComponent>
              </div>
            </div>
          </div>}
          {isEciUser && <div className='col-12 col-md-6'>
            <div className='form-group'>
              <div className='autocomplete-input'>
                <div className='autocomplete-label'>{i18n.t('reportCustomFields.uneco')}</div>
                <TextBox
                  name='uneco'
                  value={filter.uneco}
                  showClearButton={true}
                  onChange={(value) => onUnecoChange(value)}
                />
              </div>
            </div>
          </div>}
          {isEciUser && <div className='col-12 col-md-6'>
            <div className='form-group'>
              <div className='autocomplete-input'>
                <div className='autocomplete-label'>{i18n.t('reportCustomFields.officeId')}</div>
                <DropDownListComponent
                  fields={this.reportCustomFieldOptionsFields}
                  dataSource={officeOptions as IStringToAnyDictionary[]}
                  value={filter.officeId}
                  showClearButton={true}
                  sortOrder="Ascending"
                  change={(e) => this.onOfficeIdChange(e)}
                />
              </div>
            </div>
          </div>}
          {isEciUser && <div className='col-12 col-md-6'>
            <div className='form-group'>
              <div className='autocomplete-input'>
                <div className='autocomplete-label'>{i18n.t('reportCustomFields.sectionId')}</div>
                <DropDownListComponent
                  fields={this.reportCustomFieldOptionsFields}
                  dataSource={sectionOptions as IStringToAnyDictionary[]}
                  value={filter.sectionId}
                  showClearButton={true}
                  sortOrder="Ascending"
                  change={(e) => this.onSectionIdChange(e)}
                />
              </div>
            </div>
          </div>}
          {this.shouldShowSeasonIdColumn() && <div className='col-12 col-md-6'>
            <div className='form-group'>
              <div className='autocomplete-input'>
                <div className='autocomplete-label'>{i18n.t('reportCustomFields.seasonId')}</div>
                <DropDownListComponent
                  fields={this.reportCustomFieldOptionsFields}
                  dataSource={seasonOptions as IStringToAnyDictionary[]}
                  value={filter.seasonId}
                  showClearButton={true}
                  sortOrder="Ascending"
                  change={(e) => this.onSeasonIdChange(e)}
                />
              </div>
            </div>
          </div>}
          {isEciUser && <div className='col-12 col-md-6'>
            <div className='form-group'>
              <div className='autocomplete-input'>
                <div className='autocomplete-label'>{i18n.t('reportCustomFields.brandId')}</div>
                <DropDownListComponent
                  fields={this.reportCustomFieldOptionsFields}
                  dataSource={brandOptions as IStringToAnyDictionary[]}
                  value={filter.brandId}
                  showClearButton={true}
                  sortOrder="Ascending"
                  change={(e) => this.onBrandIdChange(e)}
                />
              </div>
            </div>
          </div>}
          {this.shouldShowAitexResultIdColumn() && <div className='col-12 col-md-6'>
            <div className='form-group'>
              <div className='autocomplete-input'>
                <div className='autocomplete-label'>{i18n.t('reportCustomFields.aitexResultId')}</div>
                <DropDownListComponent
                  fields={this.reportCustomFieldOptionsFields}
                  dataSource={aitexResultOptions as IStringToAnyDictionary[]}
                  value={filter.aitexResultId}
                  showClearButton={true}
                  sortOrder="Ascending"
                  change={(e) => this.onAitexResultIdChange(e)}
                />
              </div>
            </div>
          </div>}
          {this.shouldShowCustomFieldFilter('countryId') && <div className='col-12 col-md-6'>
            <div className='form-group'>
              <div className='autocomplete-input'>
                <div className='autocomplete-label'>{i18n.t('reportCustomFields.countryId')}</div>
                <DropDownListComponent
                  fields={this.reportCustomFieldOptionsFields}
                  dataSource={countryOptions as IStringToAnyDictionary[]}
                  value={filter.countryId}
                  showClearButton={true}
                  sortOrder="Ascending"
                  change={(e) => this.onCountryIdChange(e)}
                />
              </div>
            </div>
          </div>}
          {this.shouldShowIncidenceColumn() && <div className='col-12 col-md-6'>
            <div className='form-group'>
              <div className='autocomplete-input'>
                <div className='autocomplete-label'>{i18n.t('reportCustomFields.incidence')}</div>
                <TextBox
                  name='incidence'
                  value={filter.incidence}
                  showClearButton={true}
                  onChange={(value) => onIncidenceChange(value)}
                />
              </div>
            </div>
          </div>}
          {this.shouldShowCustomFieldFilter('collection') && <div className='col-12 col-md-6'>
            <div className='form-group'>
              <div className='autocomplete-input'>
                <div className='autocomplete-label'>{i18n.t('reportCustomFields.collection')}</div>
                <TextBox
                  name='collection'
                  value={filter.collection}
                  showClearButton={true}
                  onChange={(value) => onCollectionChange(value)}
                />
              </div>
            </div>
          </div>}
          {this.shouldShowCustomFieldFilter('colour') && <div className='col-12 col-md-6'>
            <div className='form-group'>
              <div className='autocomplete-input'>
                <div className='autocomplete-label'>{i18n.t('reportCustomFields.colour')}</div>
                <TextBox
                  name='colour'
                  value={filter.colour}
                  showClearButton={true}
                  onChange={(value) => onColourChange(value)}
                />
              </div>
            </div>
          </div>}
          {this.shouldShowCustomFieldFilter('target') && <div className='col-12 col-md-6'>
            <div className='form-group'>
              <div className='autocomplete-input'>
                <div className='autocomplete-label'>{i18n.t('reportCustomFields.target')}</div>
                <TextBox
                  name='target'
                  value={filter.target}
                  showClearButton={true}
                  onChange={(value) => onTargetChange(value)}
                />
              </div>
            </div>
          </div>}
          {isEciUser && <div className='col-12 col-md-6'>
            <div className='form-group'>
              <div className='autocomplete-input'>
                <div className='autocomplete-label'>{i18n.t('reportCustomFields.qualityControlId')}</div>
                <DropDownListComponent
                  fields={this.reportCustomFieldOptionsFields}
                  dataSource={qualityControlOptions as IStringToAnyDictionary[]}
                  value={filter.qualityControlId}
                  showClearButton={true}
                  sortOrder="Ascending"
                  change={(e) => this.onQualityControlIdChange(e)}
                />
              </div>
            </div>
          </div>}
          {isEciUser && <div className='col-12 col-md-6'>
            <div className='form-group'>
              <div className='autocomplete-input'>
                <div className='autocomplete-label'>{i18n.t('reportCustomFields.buyerId')}</div>
                <DropDownListComponent
                  fields={this.reportCustomFieldOptionsFields}
                  dataSource={buyerOptions as IStringToAnyDictionary[]}
                  value={filter.buyerId}
                  showClearButton={true}
                  sortOrder="Ascending"
                  change={(e) => this.onBuyerIdChange(e)}
                />
              </div>
            </div>
          </div>}
          {isEciUser && <div className='col-12 col-md-6'>
            <div className='form-group'>
              <div className='autocomplete-input'>
                <div className='autocomplete-label'>{i18n.t('reportCustomFields.closed')}</div>
                <DropDownListComponent
                  fields={this.booleanFields}
                  dataSource={this.booleanOptions}
                  value={filter.closed}
                  sortOrder="Ascending"
                  change={(e) => this.onClosedChange(e)}
                />
              </div>
            </div>
          </div>}
          {isEciUser && <div className='col-12 col-md-6'>
            <div className='form-group'>
              <div className='autocomplete-input'>
                <div className='autocomplete-label'>{i18n.t('reportCustomFields.copy')}</div>
                <DropDownListComponent
                  fields={this.booleanFields}
                  dataSource={this.booleanOptions}
                  value={filter.copy}
                  sortOrder="Ascending"
                  change={(e) => this.onCopyChange(e)}
                />
              </div>
            </div>
          </div>}
        </div>
        <div className='row justify-content-end'>
          <div className='col-auto'>
            <button type='submit' className='btn btn-secondary' title={i18n.t('actions.filter')} disabled={isFetching}>
              {isFetching ?
                <span className='spinner-border spinner-border-sm' />
                :
                <span className='icon icon-app-item-search' />
              }
            </button>
          </div>
        </div>
      </form>
    );
  }

  private updateStatusDropDownListComponentValueTemplate() {
    if (this.statusDropDownListComponent) {
      const selectedStatusOption = this.statusOptions.find((statusOption) => statusOption.value === this.props.filter.status);
      if (selectedStatusOption) {
        this.statusDropDownListComponent.valueTemplate = `
          <div class="row no-gutters pl-3 pr-3 h-100">
            <div class="col align-self-center">
              <span class="${`mr-2 ${selectedStatusOption.iconCss}`}" ></span>
              <span>${selectedStatusOption.text}</span>
            </div>
          </div>
        `;
      }
    }
  }
}

export default withTranslation()(ReportsListFilter);
