import React, { Component, ComponentType } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { compose } from 'redux';
import { MenuComponent } from '@aitex/tsx-extranet-menu';
import { dialogsService } from '@aitex/tsx-extranet-dialogs';
import { notificationsSidebarService } from '@aitex/tsx-extranet-notifications-sidebar';

import { RootState } from '../../store';
import { IMenuContainerStateProps, IMenuContainerDispatchProps } from '../../interfaces/props/IMenuProps';
import * as currentUserStore from '../../store/modules/currentUser.store';
import * as notificationDetailStore from '../../store/modules/notificationDetail.store';
import { ICurrentUserWithAccessToken } from '../../common/model/currentUser.model';
import { ISimulateExternalUser } from '../../common/model/simulateExternalUser.model';
import { IErrors } from '../../common/model/errors.model';
import { UserType } from '../../common/model/enumerations/userType.model';
import { RoleNormalizedName } from '../../common/model/enumerations/roleNormalizedName.model';
import { DialogId } from '../../common/model/enumerations/dialogId.model';
import { ReportCustomFieldSettingType } from '../../common/model/enumerations/reportCustomFieldSettingType.model';
import { ReportsListEciMode } from '../../common/model/enumerations/reportsListEciMode.model';
import { rolesService, authService, usersService, companiesService } from '../../services';
import { notifyError } from '../../utils/toast.utils';

type MenuContainerPropsType = PropsFromRedux & RouteComponentProps & WithTranslation;

class MenuContainer extends Component<MenuContainerPropsType> {
  public onReportsAllItemClick() {
    const { location, history } = this.props;

    if (location.pathname !== '/reports-list/all') {
      history.push('/reports-list/all');
    }
  }

  public onReportsInProgressItemClick() {
    const { location, history } = this.props;

    if (location.pathname !== '/reports-list/inprogress') {
      history.push('/reports-list/inprogress');
    }
  }

  public onReportsFinalizedItemClick() {
    const { location, history } = this.props;

    if (location.pathname !== '/reports-list/finalized') {
      history.push('/reports-list/finalized');
    }
  }

  public onReportsEciItemClick(eciMode: string) {
    const { location, history } = this.props;

    if (location.pathname !== `/reports-list/eci/${eciMode}`) {
      history.push(`/reports-list/eci/${eciMode}`);
    }
  }

  public onCertificatesAllItemClick() {
    const { location, history } = this.props;

    if (location.pathname !== '/certificates-list/all') {
      history.push('/certificates-list/all');
    }
  }

  public onCertificatesValidItemClick() {
    const { location, history } = this.props;

    if (location.pathname !== '/certificates-list/valid') {
      history.push('/certificates-list/valid');
    }
  }

  public onCertificatesExpiredItemClick() {
    const { location, history } = this.props;

    if (location.pathname !== '/certificates-list/expired') {
      history.push('/certificates-list/expired');
    }
  }

  public onRequestsAllItemClick() {
    const { location, history } = this.props;

    if (location.pathname !== '/requests-list/all') {
      history.push('/requests-list/all');
    }
  }

  public onRequestsOpenItemClick() {
    const { location, history } = this.props;

    if (location.pathname !== '/requests-list/open') {
      history.push('/requests-list/open');
    }
  }

  public onRequestsInProgressItemClick() {
    const { location, history } = this.props;

    if (location.pathname !== '/requests-list/inprogress') {
      history.push('/requests-list/inprogress');
    }
  }

  public onCreateRequestItemClick() {
    const { location, history } = this.props;

    if (location.pathname !== '/request/new') {
      history.push('/request/new');
    }
  }

  public onUsersListItemClick() {
    this.props.history.push('/sso-redirect/users-list');
  }

  public onCreateInternalUserItemClick() {
    this.props.history.push('/sso-redirect/user/internal/new');
  }

  public onCreateExternalUserItemClick() {
    this.props.history.push('/sso-redirect/user/external/new');
  }

  public onClientsListItemClick() {
    const { location, history } = this.props;

    if (location.pathname !== '/clients-list') {
      history.push('/clients-list');
    }
  }

  public onChainsListItemClick() {
    const { location, history } = this.props;

    if (location.pathname !== '/chains-list') {
      history.push('/chains-list');
    }
  }

  public onMeasurementUnitsListItemClick() {
    const { location, history } = this.props;

    if (location.pathname !== '/measurement-units-list') {
      history.push('/measurement-units-list');
    }
  }

  public onNotificationsItemClick() {
    notificationsSidebarService.showSidebar();
  }

  public onCreateNotificationItemClick() {
    const { resetNotificationDetail, makeReadyForRenderingNotificationDetail, location, history } = this.props;

    if (location.pathname !== '/notification/new') {
      resetNotificationDetail();
      makeReadyForRenderingNotificationDetail(false, true);
      history.push('/notification/new');
    }
  }

  public onNotificationsListItemClick() {
    const { location, history } = this.props;

    if (location.pathname !== '/notifications-list') {
      history.push('/notifications-list');
    }
  }

  public onNotificationsViewStatusItemClick() {
    const { location, history } = this.props;

    if (location.pathname !== '/notifications-view-status-list') {
      history.push('/notifications-view-status-list');
    }
  }

  public onMyProfileItemClick() {
    this.props.history.push('/sso-redirect/my-profile/view');
  }

  public onSimulateExternalUserClick() {
    const { identityUrl, i18n } = this.props;

    dialogsService.openDialog(DialogId.SIMULATE_EXTERNAL_USER)
      .then((simulateExternalUser: ISimulateExternalUser) => {
        window.location.href = `${identityUrl}simulate-external-user-in-background?companyId=${simulateExternalUser.companyId}&userId=${simulateExternalUser.userId}`;
      })
      .catch((reason?: IErrors) => {
        if (reason) {
          if (reason.ControlledError) {
            notifyError(reason.ControlledError[0]);
          }
          if (reason.UnknownError) {
            notifyError(i18n.t(reason.UnknownError[0]));
          }
        }
      });
  }

  public onDefaultItemClick() {
    this.props.establishCurrentCompanyAsDefault(usersService);
  }

  public onChangeCompanyItemClick() {
    dialogsService.openDialog(DialogId.CHANGE_COMPANY)
      .then((currentUserWithAccessToken: ICurrentUserWithAccessToken) => {
        this.props.setAccessToken(currentUserWithAccessToken.accessToken);
        this.props.setCurrentUser(currentUserWithAccessToken, companiesService);
      })
      .catch((reason?: IErrors) => {
        if (reason) {
          if (reason.ControlledError) {
            notifyError(reason.ControlledError[0]);
          }
          if (reason.UnknownError) {
            notifyError(this.props.i18n.t(reason.UnknownError[0]));
          }
        }
      });
  }

  public onLogOutItemClick() {
    authService.logout();
  }

  public onNewCustomersListClick(): void {
    this.props.history.push('/user/newcustomers');
  }

  public onUserFeedbackItemClick() {
    dialogsService.openDialog(DialogId.USER_FEEDBACK)
      .catch((reason?: IErrors) => {
        if (reason) {
          if (reason.ControlledError) {
            notifyError(reason.ControlledError[0]);
          }
          if (reason.UnknownError) {
            notifyError(this.props.i18n.t(reason.UnknownError[0]));
          }
        }
      });
  }

  public onContactUnecosItemClick() {
    const { location, history } = this.props;

    if (location.pathname !== '/unecos-list') {
      history.push('/unecos-list');
    }
  }

  public onContactOfficesItemClick() {
    const { location, history } = this.props;

    if (location.pathname !== '/officess-list') {
      history.push('/offices-list');
    }
  }

  public onContactNoEmailsItemClick() {
    const { location, history } = this.props;

    if (location.pathname !== '/no-email-list') {
      history.push('/no-email-list');
    }
  }

  public render() {
    const {
      isInSimulationMode,
      currentUserFirstName,
      currentUserLastName,
      isCurrentUserInternal,
      currentUserBelongsToMoreThanOneCompany,
      isCurrentUserCompanyDefault,
      currentUserCompany,
      notificationsCount,
      isCertificatesVisible,
      isRequestsVisible,
      isUsersManagementVisible,
      isMastersVisible,
      isSimulateExternalUserVisible,
      areEciReportsVisible,
      isUserFeedbackVisible,
      i18n,
      showPotentialCustomersList
    } = this.props;

    return (
      <MenuComponent
        currentUserFirstName={currentUserFirstName}
        currentUserLastName={currentUserLastName}
        isCurrentUserInternal={isCurrentUserInternal}
        currentUserBelongsToMoreThanOneCompany={currentUserBelongsToMoreThanOneCompany}
        isCurrentUserCompanyDefault={isCurrentUserCompanyDefault}
        isInSimulationMode={isInSimulationMode}
        currentUserCompanyName={currentUserCompany ? currentUserCompany.name : i18n.t('menu.errors.companyError')}
        currentUserCompanyLogo={currentUserCompany && currentUserCompany.reportCustomFieldSettingType !== ReportCustomFieldSettingType.NONE ? currentUserCompany.logo : null}
        notificationsCount={notificationsCount}
        isCertificatesVisible={isCertificatesVisible}
        isRequestsVisible={isRequestsVisible}
        isUsersManagementVisible={isUsersManagementVisible}
        isMastersVisible={isMastersVisible}
        isSimulateExternalUserVisible={isSimulateExternalUserVisible}
        areEciReportsVisible={areEciReportsVisible}
        isUserFeedbackVisible={isUserFeedbackVisible}
        reportsAllUrl={null}
        reportsInProgressUrl={null}
        reportsFinalizedUrl={null}
        reportsFmUrl={null}
        reportsZmUrl={null}
        reportsLcNoPassUrl={null}
        reportsFmOpenUrl={null}
        reportsOpenUrl={null}
        requestsAllUrl={null}
        requestsOpenUrl={null}
        requestsInProgressUrl={null}
        createRequestUrl={null}
        certificatesAllUrl={null}
        certificatesValidUrl={null}
        certificatesExpiredUrl={null}
        usersListUrl={null}
        createInternalUserUrl={null}
        createExternalUserUrl={null}
        clientsListUrl={null}
        chainsListUrl={null}
        measurementUnitsListUrl={null}
        createNotificationUrl={null}
        notificationsListUrl={null}
        notificationsViewStatusListUrl={null}
        myProfileUrl={null}
        reportsIconCss='icon icon-report'
        reportsAllIconCss='icon icon-app-item-list'
        reportsInProgressIconCss='icon icon-state-in-progress'
        reportsFinalizedIconCss='icon icon-report-state-finalized'
        reportsFmIconCss='icon icon-eci-list-fm'
        reportsZmIconCss='icon icon-eci-list-zm'
        reportsLcNoPassIconCss='icon icon-eci-list-lc'
        reportsFmOpenIconCss='icon icon-eci-list-fm-open'
        reportsOpenIconCss='icon icon-eci-list-open'
        certificatesIconCss='icon icon-certificate'
        certificatesAllIconCss='icon icon-app-item-list'
        certificatesValidIconCss='icon icon-calendar-check-o'
        certificatesExpiredIconCss='icon icon-calendar-times-o'
        requestsIconCss='icon icon-request'
        requestsAllIconCss='icon icon-app-item-list'
        requestsOpenIconCss='icon icon-eci-list-open'
        requestsInProgressIconCss='icon icon-state-in-progress'
        createRequestIconCss='icon icon-app-item-add'
        usersManagementIconCss='icon icon-users-management'
        usersListIconCss='icon icon-app-item-list'
        createInternalUserIconCss='icon icon-user-internal'
        createExternalUserIconCss='icon icon-user-external'
        mastersIconCss='icon icon-masters'
        clientsListIconCss='icon icon-company'
        chainsListIconCss='icon icon-chain'
        measurementUnitsListIconCss='icon icon-measurement-unit'
        notificationsIconCss='icon icon-notification'
        createNotificationIconCss='icon icon-app-item-add'
        notificationsListIconCss='icon icon-app-item-list'
        notificationsViewStatusListIconCss='icon icon-notification-view-state'
        companyIconCss='icon icon-company'
        myProfileIconCss='icon icon-user-profile'
        simulateExternalUserIconCss='icon icon-user-impersonate'
        defaultIconCss='icon icon-thumb-tack'
        changeCompanyIconCss='icon icon-random'
        logOutIconCss='icon icon-app-action-logout'
        userFeedbackIconCss='icon icon-user-feedback-positive'
        onReportsAllItemClick={() => this.onReportsAllItemClick()}
        onReportsInProgressItemClick={() => this.onReportsInProgressItemClick()}
        onReportsFinalizedItemClick={() => this.onReportsFinalizedItemClick()}
        onReportsFmItemClick={() => this.onReportsEciItemClick(ReportsListEciMode.FM)}
        onReportsZmItemClick={() => this.onReportsEciItemClick(ReportsListEciMode.ZM)}
        onReportsLcNoPassItemClick={() => this.onReportsEciItemClick(ReportsListEciMode.LC_NO_PASS)}
        onReportsFmOpenItemClick={() => this.onReportsEciItemClick(ReportsListEciMode.FM_OPEN)}
        onReportsOpenItemClick={() => this.onReportsEciItemClick(ReportsListEciMode.OPEN)}
        onCertificatesAllItemClick={() => this.onCertificatesAllItemClick()}
        onCertificatesValidItemClick={() => this.onCertificatesValidItemClick()}
        onCertificatesExpiredItemClick={() => this.onCertificatesExpiredItemClick()}
        onRequestsAllItemClick={() => this.onRequestsAllItemClick()}
        onRequestsOpenItemClick={() => this.onRequestsOpenItemClick()}
        onRequestsInProgressItemClick={() => this.onRequestsInProgressItemClick()}
        onCreateRequestItemClick={() => this.onCreateRequestItemClick()}
        onUsersListItemClick={() => this.onUsersListItemClick()}
        onCreateInternalUserItemClick={() => this.onCreateInternalUserItemClick()}
        onCreateExternalUserItemClick={() => this.onCreateExternalUserItemClick()}
        onClientsListItemClick={() => this.onClientsListItemClick()}
        onChainsListItemClick={() => this.onChainsListItemClick()}
        onMeasurementUnitsListItemClick={() => this.onMeasurementUnitsListItemClick()}
        onNotificationsItemClick={() => this.onNotificationsItemClick()}
        onCreateNotificationItemClick={() => this.onCreateNotificationItemClick()}
        onNotificationsListItemClick={() => this.onNotificationsListItemClick()}
        onNotificationsViewStatusListItemClick={() => this.onNotificationsViewStatusItemClick()}
        onMyProfileItemClick={() => this.onMyProfileItemClick()}
        onSimulateExternalUserClick={() => this.onSimulateExternalUserClick()}
        onDefaultItemClick={() => this.onDefaultItemClick()}
        onChangeCompanyItemClick={() => this.onChangeCompanyItemClick()}
        onLogOutItemClick={() => this.onLogOutItemClick()}
        onUserFeedbackClick={() => this.onUserFeedbackItemClick()}
        showPotentialCustomersList={showPotentialCustomersList}
        potentialCustomersListIconCss='icon icon-user-impersonate'
        onPotentialCustomersListClick={(): void => this.onNewCustomersListClick()}
        contactsIconCss='icon icon-contacts'
        contactUnecosIconCss='icon icon-uneco'
        contactOfficesIconCss='icon icon-office'
        contactNoEmailsIconCss='icon icon-no-mail'
        onContactUnecosItemClick={() => this.onContactUnecosItemClick()}
        onContactOfficesItemClick={() => this.onContactOfficesItemClick()}
        onContactNoEmailsItemClick={() => this.onContactNoEmailsItemClick()}
      />
    );
  }
}

const computeAreEciReportsVisibleProp = (state: RootState): boolean => {
  const currentUserCompany = state.currentUserStore.company;
  const currentUserChainName = state.currentUserStore.user.companies.find((userCompany) => userCompany.companyId === state.currentUserStore.jwtInfo.companyId) ?
    state.currentUserStore.user.companies.find((userCompany) => userCompany.companyId === state.currentUserStore.jwtInfo.companyId).chainName
    :
    null;
    const hasCurrentUserCompanyEciReportCustomFieldSettingType = currentUserCompany && currentUserCompany.reportCustomFieldSettingType === ReportCustomFieldSettingType.ECI;
  const currentUserChainNameStartsWithEci = currentUserChainName && currentUserChainName.toLowerCase().startsWith('eci');
  return hasCurrentUserCompanyEciReportCustomFieldSettingType && currentUserChainNameStartsWithEci;
}

const mapStateToProps = (state: RootState): IMenuContainerStateProps => ({
  isInSimulationMode: state.currentUserStore.jwtInfo.isInSimulationMode === 'true',
  currentUserFirstName: state.currentUserStore.user.firstName,
  currentUserLastName: state.currentUserStore.user.lastName,
  isCurrentUserInternal: state.currentUserStore.user.type === UserType.INTERNAL,
  currentUserBelongsToMoreThanOneCompany: state.currentUserStore.user.companies.length > 1,
  isCurrentUserCompanyDefault: state.currentUserStore.company ?
    state.currentUserStore.user.companies.find((userCompany) => userCompany.companyId === state.currentUserStore.company.id).default
    :
    false,
  currentUserCompany: state.currentUserStore.company,
  notificationsCount: state.notificationsSidebarStore.notifications.filter((notification) => !notification.seen).length,
  isCertificatesVisible: state.currentUserStore.company == null || state.currentUserStore.company.code !== '0349',
  isRequestsVisible: state.currentUserStore.company == null || state.currentUserStore.company.code !== '0349',
  isUsersManagementVisible: rolesService.hasAnyRole(state.currentUserStore.jwtInfo, [RoleNormalizedName.ADMIN, RoleNormalizedName.CUSTOMER_SERVICE, RoleNormalizedName.ACCOUNT_MANAGER]),
  isMastersVisible: rolesService.hasAnyRole(state.currentUserStore.jwtInfo, [RoleNormalizedName.ADMIN, RoleNormalizedName.CUSTOMER_SERVICE]),
  isSimulateExternalUserVisible: rolesService.hasAnyRole(state.currentUserStore.jwtInfo, [RoleNormalizedName.ADMIN, RoleNormalizedName.CUSTOMER_SERVICE, RoleNormalizedName.ACCOUNT_REVIEWER]),
  areEciReportsVisible: computeAreEciReportsVisibleProp(state),
  isUserFeedbackVisible: true,
  identityUrl: state.settingsStore.settings.identity,
  showPotentialCustomersList: rolesService.hasAnyRole(state.currentUserStore.jwtInfo, [RoleNormalizedName.ADMIN, RoleNormalizedName.CUSTOMER_SERVICE])
});

const mapDispatchToProps: IMenuContainerDispatchProps = {
  setCurrentUser: currentUserStore.setCurrentUser,
  setAccessToken: currentUserStore.setAccessToken,
  establishCurrentCompanyAsDefault: currentUserStore.establishCurrentCompanyAsDefault,
  resetNotificationDetail: notificationDetailStore.reset,
  makeReadyForRenderingNotificationDetail: notificationDetailStore.makeReadyForRendering
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default compose(
  withRouter,
  withTranslation(),
  connector
)(MenuContainer) as ComponentType;
