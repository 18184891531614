
interface StatusIconClass {
    [key: string]: string;
}

interface StatusTranslation {
    [key: string]: string;
}

export const statusIconClass: StatusIconClass = {
    inprogress: 'icon icon-state-in-progress',
    finalized: 'icon icon-report-state-finalized',
    cancelled: 'icon icon-app-item-cancel',
    all: 'icon icon-app-item-list'
};

export const statusTranslation: StatusTranslation = {
    inprogress: 'inprogress',
    finalized: 'finalized',
    cancelled: 'cancelled',
    all: 'all'
};
