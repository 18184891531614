import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { IReportsGridActionsColumnProps } from '../../../interfaces/props/IReportsListProps';

type ReportsGridActionsColumnPropsType = IReportsGridActionsColumnProps & WithTranslation;

type ReportsGridActionsColumnStateType = ReportsGridActionsColumnState;

class ReportsGridActionsColumn extends Component<ReportsGridActionsColumnPropsType, ReportsGridActionsColumnStateType> {
  public constructor(props: ReportsGridActionsColumnPropsType) {
    super(props);

    this.state = {
      isSelected: false
    };
  }

  public onIsSelectedCheckboxChange() {
    this.setState({ isSelected: !this.state.isSelected }, () => this.props.onIsSelectedChange(this.state.isSelected));
  }

  public render() {
    const { showDownloadButton, onDownloadButtonClick, i18n, showRequestAdvanceButton, onRequestAdvanceClick } = this.props;

    return (
      <div className='row no-gutters'>
        <div className='col'>
          {showDownloadButton && <div className='row no-gutters align-items-center'>
            <div className='col-auto'>
              <button
                type='button'
                className='btn btn-sm btn-light'
                title={i18n.t('reportsList.actions.download')}
                onClick={() => onDownloadButtonClick()}
              >
                <span className='icon icon-app-file-download' />
              </button>
            </div>
            <div className='col-auto ml-2'>
              <input className='e-table-check' type='checkbox' checked={this.state.isSelected} onChange={() => this.onIsSelectedCheckboxChange()} />
            </div>
          </div>}
          {showRequestAdvanceButton && <div className='col-auto ml-2'>
            <button
              type='button'
              className='btn btn-sm btn-light'
              title={i18n.t('reportsList.actions.requestAdvance')}
              onClick={() => onRequestAdvanceClick()}
            >
              <span className='icon icon-report-action-advance' />
              <span className='bwi'>{i18n.t('reportsList.actions.requestAdvance')}</span>
            </button>
          </div>}
        </div>
      </div>
    );
  }
}

interface ReportsGridActionsColumnState {
  isSelected: boolean;
}

export default withTranslation()(ReportsGridActionsColumn);
