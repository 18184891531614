import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { DialogComponent } from '@aitex/tsx-extranet-dialogs';
import { ListViewComponent, FieldSettingsModel, SelectedItem } from '@syncfusion/ej2-react-lists';

import { IChangeCompanyDialogProps } from '../../../interfaces/props/IDialogsProps';
import { ICompanyCombo } from '../../../common/model/company.model';
import { ICurrentUserWithAccessToken } from '../../../common/model/currentUser.model';
import { IErrors } from '../../../common/model/errors.model';
import { usersService, authService } from '../../../services';
import * as dialogUtils from '../../../utils/dialog.utils';

import * as reportsListStore from '../../../store/modules/reportsList.store';
import * as certificatesListStore from '../../../store/modules/certificatesList.store';
import * as requestsListStore from '../../../store/modules/requestsList.store';
import * as listGridStore from '../../../store/modules/listGrid.store';

type ChangeCompanyDialogPropsType = IChangeCompanyDialogProps & WithTranslation;

type ChangeCompanyDialogStateType = IChangeCompanyDialogState;

class ChangeCompanyDialog extends Component<ChangeCompanyDialogPropsType, ChangeCompanyDialogStateType> {
  public changeCompanyDialogListView: ListViewComponent = null;
  public changeCompanyDialogListViewFields: FieldSettingsModel = { text: 'name', id: 'id' };

  private readonly CANCEL_BUTTON_ID = 'changeCompanyDialogCancel';
  private readonly SELECT_BUTTON_ID = 'changeCompanyDialogSelect';

  private readonly CANNOT_CHANGE_COMPANY_ERROR: IErrors = { UnknownError: ['dialogs.changeCompanyDialog.error'] };

  private initialState: IChangeCompanyDialogState = {
    isLoading: false
  };

  private cleanGrids() {
    listGridStore.setCurrentPage(reportsListStore.Type.SET_CURRENT_PAGE, 1);
    reportsListStore.setReports([]);
    listGridStore.setCurrentPage(certificatesListStore.Type.SET_CURRENT_PAGE, 1);
    certificatesListStore.setCertificates([]);
    listGridStore.setCurrentPage(requestsListStore.Type.SET_CURRENT_PAGE, 1);
    requestsListStore.setRequests([]);
  }

  public constructor(props: ChangeCompanyDialogPropsType) {
    super(props);

    this.state = this.initialState;
  }

  public componentDidUpdate(prevProps: ChangeCompanyDialogPropsType) {
    const { visible } = this.props;

    if (prevProps.visible && !visible) {
      this.setState(this.initialState);
    }

    dialogUtils.manageButtonsClick(this.SELECT_BUTTON_ID, () => this.changeCompany(), this.CANCEL_BUTTON_ID, () => this.dismiss());
  }

  public async changeCompany() {
    try {
      this.setState({ isLoading: true });

      this.cleanGrids();

      if (!this.changeCompanyDialogListView) {
        throw this.CANNOT_CHANGE_COMPANY_ERROR;
      }

      const selectedItem = this.changeCompanyDialogListView.getSelectedItems() as SelectedItem;
      if (!selectedItem) {
        throw this.CANNOT_CHANGE_COMPANY_ERROR;
      }

      const company = (selectedItem.data as unknown as ICompanyCombo);
      if (!company.id) {
        throw this.CANNOT_CHANGE_COMPANY_ERROR;
      }

      const currentUser = await usersService.changeCurrentCompany(company.id);
      await authService.renewToken();
      const user = await authService.getUser();
      const currentUserWithAccessToken: ICurrentUserWithAccessToken = { ...currentUser, accessToken: user.access_token };
      this.close(currentUserWithAccessToken);
    } catch (error) {
      let errors = error as IErrors;
      if (errors.UnknownError || (!errors.UnknownError && !errors.ControlledError)) {
        errors = this.CANNOT_CHANGE_COMPANY_ERROR;
      }
      this.dismiss(errors);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  public close(currentUserWithAccessToken: ICurrentUserWithAccessToken) {
    this.props.onClose(currentUserWithAccessToken);
  }

  public dismiss(reason?: IErrors) {
    this.props.onDismiss(reason);
  }

  public onActionComplete(event: any) {
    const elements = this.changeCompanyDialogListView.getSelectedItems();

    if (event.data !== undefined && event.data !== null && Array.isArray(event.data) && event.data.length > 0 && elements === undefined) {
      this.changeCompanyDialogListView.selectItem(event.data[0]);
    }
  }

  public render() {
    const { visible, companies, currentUserCompanyName, i18n } = this.props;

    return (
      <DialogComponent
        header={`${i18n.t('dialogs.changeCompanyDialog.title')} (${currentUserCompanyName})`}
        visible={visible}
        footerTemplate={dialogUtils.computeFooterTemplate(this.SELECT_BUTTON_ID, i18n.t('actions.select'), this.CANCEL_BUTTON_ID, i18n.t('actions.cancel'), this.state.isLoading)}
        onDismiss={() => this.dismiss()}
      >
        <ListViewComponent
          dataSource={companies as any}
          fields={this.changeCompanyDialogListViewFields}
          ref={(listView: ListViewComponent) => this.changeCompanyDialogListView = listView}
          actionComplete={(args: any) => this.onActionComplete(args)}
        />
      </DialogComponent>
    );
  }
}

interface IChangeCompanyDialogState {
  isLoading: boolean;
}

export default withTranslation()(ChangeCompanyDialog);
