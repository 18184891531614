import React, { Component, Fragment } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, Sort, Page, Toolbar, Resize, PageSettingsModel } from '@syncfusion/ej2-react-grids';
import { ItemModel, ClickEventArgs } from '@syncfusion/ej2-navigations';

import { IAnnexesTabProps } from '../../../interfaces/props/ITabsProps';
import { IAnnex } from '../../../common/model/annex.model';
import { AnnexedState } from '../../../common/model/enumerations/annexedState.model';
import { SourceType } from '../../../common/model/enumerations/sourceType.model';
import * as sourceTypeUtils from '../../../utils/sourceType.utils';
import * as annexedStateUtils from '../../../utils/annexedState.utils';
import * as annexedCategoryUtils from '../../../utils/annexedCategory.utils';
import * as pagerUtils from '../../../utils/pager.utils';
import './annexesTab.scss';

type AnnexesTabPropsType = IAnnexesTabProps & WithTranslation;

class AnnexesTab extends Component<AnnexesTabPropsType> {
  public toolbarOptions: ItemModel[] = [];
  public pageSettings: PageSettingsModel = {
    pageSize: pagerUtils.getMaxPageSize(),
    pageCount: pagerUtils.getPageCount(),
    pageSizes: pagerUtils.getPageSizes()
  };

  private readonly UPLOAD_ANNEX_ACTION_ID = 'uploadAnnex';

  public constructor(props: AnnexesTabPropsType) {
    super(props);

    const { i18n } = this.props;

    this.toolbarOptions = [
      {
        tooltipText: i18n.t('reportDetail.actions.uploadAnnex'),
        id: this.UPLOAD_ANNEX_ACTION_ID,
        template: `
          <button type="button" class="e-tbar-btn e-tbtn-txt e-control e-btn e-lib">
            <span class="icon icon-annex"></span>
            <span class="ml-1 icon icon-app-item-add"></span>
          </button>
        `
      }
    ];
  }

  public onToolbarClick(args: ClickEventArgs) {
    if (args.item.id === this.UPLOAD_ANNEX_ACTION_ID) {
      this.props.onUploadAnnexButtonClick();
    }
  }

  public getDescriptionColumnTemplate(annex: IAnnex): JSX.Element {
    return <span className='e-link' onClick={() => this.props.onDownloadAnnexButtonClick(annex)}>{annex.description}</span>;
  }

  public getSourceTypeColumnTemplate(annex: IAnnex): JSX.Element {
    return <span title={sourceTypeUtils.getLocalizedTextByValue(this.props.i18n, annex.sourceType)} className={sourceTypeUtils.getIconCss(annex.sourceType)} />;
  }

  public getStateColumnTemplate(annex: IAnnex): JSX.Element {
    return <span title={annexedStateUtils.getLocalizedTextByValue(this.props.i18n, annex.state)} className={annexedStateUtils.getIconCss(annex.state)} />;
  }

  public getObservationsColumnTemplate(annex: IAnnex): JSX.Element {
    return (
      <div>
        {((annex.state === AnnexedState.OK || annex.state === AnnexedState.KO) && annex.observations) && (
          <button
            type='button'
            className='btn btn-sm btn-light'
            title={annex.observations}
          >
            <span className='icon icon-info' />
          </button>
        )}
      </div>
    );
  }

  public getCategoryColumnTemplate(annex: IAnnex): JSX.Element {
    if (annex.annexedCategoryValue !== undefined && annex.annexedCategoryValue !== null) {
      return <span>{annexedCategoryUtils.getLocalizedText(this.props.i18n, annex.annexedCategoryValue)} </span>;
    }
    return <span/>;
  }

  public getActionsColumnTemplate(annex: IAnnex): JSX.Element {
    const { isCurrentUserInternal, sourceType, onEditAnnexStateButtonClick, onEditAnnexButtonClick, onDeactivateAnnexButtonClick, i18n } = this.props;

    const annexCopy: IAnnex = { ...annex };

    return sourceType === annexCopy.sourceType ? (
      <div className='btn-group'>
        {isCurrentUserInternal && <button
          type='button'
          className='btn btn-sm btn-light'
          title={i18n.t('reportDetail.actions.editAnnexState')}
          onClick={() => onEditAnnexStateButtonClick(annexCopy)}
        >
          <span className='icon icon-progress' />
        </button>}
        {this.shouldShowEditAndDeactivateButtons(annexCopy) && <Fragment>
          <button
            type='button'
            className='btn btn-sm btn-light'
            title={i18n.t('reportDetail.actions.editAnnex')}
            onClick={() => onEditAnnexButtonClick(annexCopy)}
          >
            <span className='icon icon-app-item-edit' />
          </button>
          <button
            type='button'
            className='btn btn-sm btn-light'
            title={i18n.t('reportDetail.actions.deactivateAnnex')}
            onClick={() => onDeactivateAnnexButtonClick(annexCopy.id)}
          >
            <span className='icon icon-app-item-remove' />
          </button>
        </Fragment>}
      </div>
    ) : null;
  }

  public getUserNameColumnTemplate(annex: IAnnex): JSX.Element {
    if (annex.userName !== undefined && annex.userName !== null) {
      return <span>{(annex.userName === undefined || annex.userName == null || annex.userName.length == 0 ? '' : annex.userName)} </span>;
    }
    return <span />;
  }

  public render() {
    const { annexes, sourceType, isCurrentUserInternal, gridRef, i18n } = this.props;

    return (
      <div className='annexes-grid'>
        <GridComponent
          dataSource={annexes}
          toolbar={this.toolbarOptions}
          allowSorting={true}
          allowPaging={true}
          allowResizing={true}
          pageSettings={this.pageSettings}
          locale={i18n.language}
          ref={(grid: GridComponent) => gridRef(grid)}
          toolbarClick={(args: ClickEventArgs) => this.onToolbarClick(args)}
        >
          <ColumnsDirective>
            <ColumnDirective
              headerText={i18n.t('annex.description')}
              field='description'
              width='250'
              template={(annex: IAnnex) => this.getDescriptionColumnTemplate(annex)}
            />
            {sourceType === SourceType.REPORT && <ColumnDirective
              headerText={i18n.t('annex.sourceType')}
              field='sourceType'
              width='100'
              template={(annex: IAnnex) => this.getSourceTypeColumnTemplate(annex)}
            />}
            {isCurrentUserInternal && <ColumnDirective
              headerText={i18n.t('annex.state')}
              field='state'
              width='100'
              template={(annex: IAnnex) => this.getStateColumnTemplate(annex)}
            />}
            {<ColumnDirective
               headerText={i18n.t('annex.annexedCategory')}
               field='state'
               width='75'
               template={(annex: IAnnex) => this.getCategoryColumnTemplate(annex)}
            />}
            <ColumnDirective
              headerText={i18n.t('annex.observations')}
              field='state'
              width='75'
              template={(annex: IAnnex) => this.getObservationsColumnTemplate(annex)}
            />
            <ColumnDirective
              headerText={i18n.t('annex.userName')}
              field='userName'
              width='75'
              template={(annex: IAnnex) => this.getUserNameColumnTemplate(annex)}
            />
            <ColumnDirective
              width='100'
              template={(annex: IAnnex) => this.getActionsColumnTemplate(annex)}
            />
          </ColumnsDirective>
          <Inject services={[Sort, Page, Toolbar, Resize]} />
        </GridComponent>
      </div>
    );
  }

  private shouldShowEditAndDeactivateButtons(annex: IAnnex): boolean {
    const { isCurrentUserInternal, currentUserId } = this.props;

    return isCurrentUserInternal || currentUserId === annex.userId;
  }
}

export default withTranslation()(AnnexesTab);
