import React from 'react';
import { Form, Col } from 'react-bootstrap';
import { ReportStatusInputProps } from './iReportStatusInput';

const FormInput = ({ controlId, label, placeholder, required, feedback, onChange }: ReportStatusInputProps): JSX.Element => {
  return (
    <Form.Group as={Col} controlId={controlId}>
      <Form.Label>{label}</Form.Label>
      <Form.Control placeholder={placeholder} required={required} onChange={onChange} />
      <Form.Control.Feedback type='invalid'>{feedback}</Form.Control.Feedback>
    </Form.Group>
  );
};

export default FormInput;
