import { QueryBuilderComponent } from '@syncfusion/ej2-react-querybuilder';
import i18next from 'i18next';

export const addContextualHelpToQueryBuilder = (queryBuilderComponent: QueryBuilderComponent, id: string, i18n: i18next.i18n): void => {
  if (queryBuilderComponent.element) {
    const contextualHelpElements = queryBuilderComponent.element.getElementsByClassName('contextual-help');
    if (contextualHelpElements.length === 0) {
      const groupAction = queryBuilderComponent.element.getElementsByClassName('e-group-action');
      groupAction[0].insertAdjacentHTML('afterend', `<div id="${id}" class="contextual-help"></div>`);
    }
    contextualHelpElements[0].innerHTML = `
      <button type="button" class="btn btn-sm btn-light" title="${i18n.t('advancedFilterComponent.actions.showContextualHelp')}">
        <span class="icon icon-info"></span>
      </button>
    `;
  }
};

export const removeContextualHelpFromQueryBuilder = (queryBuilderComponent: QueryBuilderComponent): void => {
  if (queryBuilderComponent.element) {
    const contextualHelpElements = queryBuilderComponent.element.getElementsByClassName('contextual-help');
    if (contextualHelpElements.length) {
      contextualHelpElements[0].remove();
    }
  }
};

export const addAppliedFilterInfoToQueryBuilder = (queryBuilderComponent: QueryBuilderComponent, appliedFilterName: string): void => {
  if (queryBuilderComponent.element) {
    const appliedFilterInfoElements = queryBuilderComponent.element.getElementsByClassName('applied-filter-info');
    if (appliedFilterInfoElements.length === 0) {
      const groupAction = queryBuilderComponent.element.getElementsByClassName('e-group-action');
      groupAction[0].insertAdjacentHTML('afterend', '<div class="applied-filter-info"></div>');
    }
    appliedFilterInfoElements[0].innerHTML = `
      <button type="button" class="btn btn-sm btn-light" title="${appliedFilterName}">
        <span class="icon icon-advanced-filter-active text-primary"></span>
      </button>
    `;
  }
};

export const removeAppliedFilterInfoFromQueryBuilder = (queryBuilderComponent: QueryBuilderComponent): void => {
  if (queryBuilderComponent.element) {
    const appliedFilterInfoElements = queryBuilderComponent.element.getElementsByClassName('applied-filter-info');
    if (appliedFilterInfoElements.length) {
      appliedFilterInfoElements[0].remove();
    }
  }
};
