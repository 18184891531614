import { URL } from '../common/constants';
import { ICompanySupplier } from '../common/model/companySupplier.model';
import * as services from '.';

export interface ICompanySuppliersService {
  create(CompanySupplier: ICompanySupplier): Promise<ICompanySupplier>;
  update(CompanySupplier: ICompanySupplier): Promise<ICompanySupplier>;
}

class CompanySuppliersService implements ICompanySuppliersService {
  public constructor(private httpClient: services.IHttpClient) {

  }

  public create(companySupplier: ICompanySupplier): Promise<ICompanySupplier> {
    return this.httpClient.postCustom(`${URL.WEB.BASE}${URL.WEB.COMPANY_SUPPLIERS}`, companySupplier);
  }

  public update(companySupplier: ICompanySupplier): Promise<ICompanySupplier> {
    return this.httpClient.putCustom(`${URL.WEB.BASE}${URL.WEB.COMPANY_SUPPLIERS}/${companySupplier.id}`, companySupplier);
  }
}

export const companySuppliersService = new CompanySuppliersService(services.httpClient);
