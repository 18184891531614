import { URL } from '../common/constants';
import { IUneco } from '../common/model/uneco.model';
import * as services from '.';

export interface IUnecoService {
  create(uneco: IUneco): Promise<IUneco>;
  update(uneco: IUneco): Promise<IUneco>;
}

class UnecosService implements IUnecoService {
  public constructor(private httpClient: services.IHttpClient) {

  }

  public async create(uneco: IUneco): Promise<IUneco> {
    return await this.httpClient.postCustom(`${URL.WEB.BASE}${URL.WEB.UNECOS}`, uneco);
  }

  public async update(uneco: IUneco): Promise<IUneco> {
    return await this.httpClient.putCustom(`${URL.WEB.BASE}${URL.WEB.UNECOS}/${uneco.id}`, uneco);
  }
}

export const unecosService = new UnecosService(services.httpClient);
