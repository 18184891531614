import { Moment } from 'moment';

export function sortAZ<T>(list: T[], property: string): T[] {
  return list.sort((a: any, b: any) => {
    if (!a[property] || !b[property]) {
      throw new Error(`property ${property} does not exist`);
    }

    const aProp = a[property].toLowerCase().replace(/ /g, '');
    const bProp = b[property].toLowerCase().replace(/ /g, '');

    if (aProp < bProp) {
      return -1;
    }

    if (aProp > bProp) {
      return 1;
    }

    return 0;
  });
}

export const gridSortComparer = <T> (reference: T, comparer: T, sortDirection: string): number => {
  const isSortDirectionAscending = sortDirection === 'Ascending';
  const isSortDirectionDescending = sortDirection === 'Descending';

  if (reference === null) {
    return 1;
  }

  if (comparer === null) {
    return -1;
  }

  if (isSortDirectionAscending) {
    return [reference, comparer].sort()[0] === reference ? -1 : 1;
  }

  if (isSortDirectionDescending) {
    return [comparer, reference].sort()[0] === comparer ? -1 : 1;
  }

  return 0;
};

export const gridDateSortComparer = (reference: Moment, comparer: Moment, sortDirection: string): number => {
  const isSortDirectionAscending = sortDirection === 'Ascending';
  const isSortDirectionDescending = sortDirection === 'Descending';

  if (reference === null) {
    return 1;
  }

  if (comparer === null) {
    return -1;
  }

  if (isSortDirectionAscending) {
    return reference.diff(comparer, 'seconds');
  }

  if (isSortDirectionDescending) {
    return comparer.diff(reference, 'seconds');
  }

  return 0;
};
