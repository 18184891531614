import React, { Component, ComponentType } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { UnregisterCallback } from 'history';
import { WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { Tabs, Tab } from 'react-bootstrap';
import { DataManager, Query } from '@syncfusion/ej2-data';
import { SortOrder } from '@syncfusion/ej2-lists';
import { IEditCell } from '@syncfusion/ej2-grids';
import { FieldSettingsModel } from '@syncfusion/ej2-dropdowns';
import { dialogsService } from '@aitex/tsx-extranet-dialogs';

import { RootState } from '../../store';
import { IClientDetailContainerStateProps, IClientDetailContainerDispatchProps } from '../../interfaces/props/IClientDetailProps';
import * as clientDetailStore from '../../store/modules/clientDetail.store';
import * as dialogsStore from '../../store/modules/dialogs.store';
import { URL } from '../../common/constants';
import { IStringToAnyDictionary } from '../../common/model/stringToAnyDictionary.model';
import { ClientDetailTab } from '../../common/model/enumerations/clientDetailTab.model';
import { DialogId } from '../../common/model/enumerations/dialogId.model';
import { CertificateType } from '../../common/model/enumerations/certificateType.model';
import { RuleOperator } from '../../common/model/enumerations/ruleOperator.model';
import { RoleNormalizedName } from '../../common/model/enumerations/roleNormalizedName.model';
import { companiesService, dataManagerService, rolesService } from '../../services';
import * as certificateTypeUtils from '../../utils/certificateType.utils';
import { enumToArray } from '../../utils/enum.utils';
import ClientDataTab from './tabs/clientDataTab/clientDataTab';

type ClientDetailContainerPropsType = PropsFromRedux & RouteComponentProps & WithTranslation;

type ClientDetailContainerStateType = IClientDetailContainerState;

class ClientDetailContainer extends Component<ClientDetailContainerPropsType, ClientDetailContainerStateType> {
  public measurementUnitDataManager: DataManager = null;
  public measurementUnitQuery: Query = new Query().select(['id', 'code']).where('active', RuleOperator.EQUAL, true).take(10);
  public measurementUnitFields: FieldSettingsModel = { value: 'code' };
  public measurementUnitSortOrder: SortOrder = 'Ascending';
  public measurementUnitParams: IEditCell = null;

  public certificateTypesOptions: IStringToAnyDictionary[] = [];
  public certificateTypesFields: FieldSettingsModel = { text: 'text', value: 'value' };

  public supplierDataManager: DataManager = null;
  public supplierQuery: Query = new Query().select(['id', 'name']).where('active', RuleOperator.EQUAL, true).take(10);
  public supplierFields: FieldSettingsModel = { value: 'name' };
  public supplierSortOrder: SortOrder = 'Ascending';
  public supplierParams: IEditCell = null;

  private unregisterCallback: UnregisterCallback = null;

  private initialState: ClientDetailContainerStateType = {
    selectedTab: ClientDetailTab.CLIENT_DATA
  };

  public constructor(props: ClientDetailContainerPropsType) {
    super(props);

    const { reset, i18n } = this.props;

    reset();

    this.measurementUnitDataManager = dataManagerService.buildDataManager(URL.ODATA.MEASUREMENT_UNIT_FILTERS);
    this.measurementUnitParams = {
      params: {
        actionComplete: () => false,
        allowFiltering: true,
        dataSource: this.measurementUnitDataManager,
        fields: this.measurementUnitFields,
        query: this.measurementUnitQuery,
        sortOrder: this.measurementUnitSortOrder,
        filterType: 'Contains'
      },
      read: (args: any) => {
        return args.ej2_instances[0].itemData;
      }
    };

    this.certificateTypesOptions = enumToArray(CertificateType, 'string', 'text', 'value')
      .map((item) => ({ ...item, text: certificateTypeUtils.getLocalizedText(i18n, item.text as string) }));

    this.supplierDataManager = dataManagerService.buildDataManager(URL.ODATA.SUPPLIER_FILTERS);
    this.supplierParams = {
      params: {
        actionComplete: () => false,
        allowFiltering: true,
        dataSource: this.supplierDataManager,
        fields: this.supplierFields,
        query: this.supplierQuery,
        sortOrder: this.supplierSortOrder,
        filterType: 'Contains'
      },
      read: (args: any) => {
        return args.ej2_instances[0].itemData;
      }
    };

    this.state = this.initialState;
  }

  public componentDidMount() {
    const { makeReadyForRendering, fetchClient, location } = this.props;

    makeReadyForRendering(this.computeIsView());
    const clientPathSubstring = '/client/';
    const id = location.pathname.substring(location.pathname.lastIndexOf(clientPathSubstring) + clientPathSubstring.length, location.pathname.lastIndexOf('/'));
    fetchClient(id, companiesService);
  }

  public componentDidUpdate(prevProps: ClientDetailContainerPropsType) {
    if (this.props.isReadyForRendering && prevProps.location.pathname !== this.props.location.pathname) {
      this.props.setIsView(this.computeIsView());
    }

    if (prevProps.isView !== this.props.isView && this.props.isView === false) {
      this.unregisterCallback = this.props.history.block((blocker) => {
        if (this.shouldBlockNavigation()) {
          dialogsService.openDialog(DialogId.DISCARD_CHANGES)
            .then(() => {
              if (this.unregisterCallback) {
                this.unregisterCallback();
                this.unregisterCallback = null;
              }
              this.props.resetClient();
              setTimeout(() => this.props.history.push(blocker.pathname));
            })
            .catch(() => {
              // DO nothing
            });
          return false;
        }
      });
    }
  }

  public componentWillUnmount() {
    const { isReadyForRendering, reset } = this.props;

    if (isReadyForRendering) {
      reset();
    }
  }

  public onEditCustomFieldsButtonClick() {
    const { client, setEditReportCustomFieldSettingsDialogCompanyId: setEditCustomFieldsDialogCompanyId } = this.props;

    setEditCustomFieldsDialogCompanyId(client.id);
    dialogsService.openDialog(DialogId.EDIT_REPORT_CUSTOM_FIELD_SETTINGS)
      .catch(() => {
        // DO nothing
      });
  }

  public onCertificationsAccordionExpanded() {
    const { client, companyCertifications, fetchCompanyCertifications } = this.props;

    if (!companyCertifications.length) {
      fetchCompanyCertifications(client.id, companiesService);
    }
  }

  public onUsersAccordionExpanded() {
    const { client, companyUsers, fetchCompanyUsers } = this.props;

    if (!companyUsers.length) {
      fetchCompanyUsers(client.id, companiesService);
    }
  }

  public shouldShowEditReportCustomFieldSettingsButton(): boolean {
    return (
      this.props.isView === false
      && this.props.hasReportCustomFieldSettingTypeGeneralAtMountTime
      && rolesService.hasAnyRole(this.props.jwtInfo, [RoleNormalizedName.ADMIN, RoleNormalizedName.CUSTOMER_SERVICE])
    );
  }

  public edit() {
    this.props.history.push(this.props.location.pathname.replace('/view', '/edit'));
  }

  public save() {
    const { client, updateClient, history } = this.props;

    dialogsService.openDialog(DialogId.GENERIC_CONFIRMATION)
      .then(() => updateClient(client, companiesService, this.unregisterCallback, history))
      .catch(() => {
        // DO nothing
      });
  }

  public cancel() {
    this.props.history.push(this.props.location.pathname.replace('/edit', '/view'));
  }

  public render() {
    const {
      isReadyForRendering,
      client,
      isView,
      companyCertifications,
      isFetchingCompanyCertifications,
      isSaving,
      errors,
      setClientName,
      setClientAddress,
      setClientCity,
      setClientProvince,
      setClientPostalCode,
      setClientCountry,
      setClientReportCustomFieldSettingType,
      setClientRequestCustomFieldSettingType,
      setClientHasReportVersions,
      setClientLogo,
      i18n,
      jwtInfo,
      companyUsers,
      isFetchingCompanyUsers
    } = this.props;

    if (!isReadyForRendering) {
      return null;
    }

    if (!client) {
      return null; // TODO: loading
    }

    return (
      <form>
        <h2>{i18n.t('clientDetail.title')}</h2>
        <Tabs id='clientDetailTabs' className='mb-3' activeKey={this.state.selectedTab} onSelect={(selectedTab: string) => this.setState({ selectedTab })}>
          <Tab
            eventKey={ClientDetailTab.CLIENT_DATA}
            title={(
              <div className='d-flex align-items-center'>
                <span className='icon icon-company mr-2' />
                <span>{i18n.t(`enums.clientDetailTab.${ClientDetailTab.CLIENT_DATA}`)}</span>
              </div>
            )}
          >
            <ClientDataTab
              client={client}
              shouldShowEditReportCustomFieldSettingsButton={this.shouldShowEditReportCustomFieldSettingsButton()}
              companyCertifications={companyCertifications}
              isFetchingCompanyCertifications={isFetchingCompanyCertifications}
              disabled={false}
              editing={isView === false}
              loading={isSaving}
              errors={errors}
              onEditButtonClick={() => this.edit()}
              onSaveButtonClick={() => this.save()}
              onCancelButtonClick={() => this.cancel()}
              setClientName={setClientName}
              setClientAddress={setClientAddress}
              setClientCity={setClientCity}
              setClientProvince={setClientProvince}
              setClientPostalCode={setClientPostalCode}
              setClientCountry={setClientCountry}
              setClientReportCustomFieldSettingType={setClientReportCustomFieldSettingType}
              setClientRequestCustomFieldSettingType={setClientRequestCustomFieldSettingType}
              setClientHasReportVersions={setClientHasReportVersions}
              setClientLogo={setClientLogo}
              onEditReportCustomFieldSettingsButtonClick={() => this.onEditCustomFieldsButtonClick()}
              onCertificationsAccordionExpanded={() => this.onCertificationsAccordionExpanded()}

              companyUsers={companyUsers}
              isFetchingCompanyUsers={isFetchingCompanyUsers}
              showCreateUserButton={rolesService.hasAnyRole(jwtInfo, [RoleNormalizedName.ADMIN, RoleNormalizedName.CUSTOMER_SERVICE])}
              onUsersAccordionExpanded={() => this.onUsersAccordionExpanded()}
            />
          </Tab>
        </Tabs>
      </form>
    );
  }

  private computeIsView(): boolean {
    return this.props.location.pathname.includes('/view');
  }

  private shouldBlockNavigation(): boolean {
    const { client, clientInitial } = this.props;

    return (
      client.name !== clientInitial.name
      || client.address !== clientInitial.address
      || client.city !== clientInitial.city
      || client.province !== clientInitial.province
      || client.postalCode !== clientInitial.postalCode
      || client.country !== clientInitial.country
      || client.reportCustomFieldSettingType !== clientInitial.reportCustomFieldSettingType
      || client.logo !== clientInitial.logo
      || client.hasReportVersions !== clientInitial.hasReportVersions
    );
  }
}

const mapStateToProps = (state: RootState): IClientDetailContainerStateProps => ({
  isReadyForRendering: state.clientDetailStore.isReadyForRendering,
  client: state.clientDetailStore.client,
  clientInitial: state.clientDetailStore.clientInitial,
  isView: state.clientDetailStore.isView,
  hasReportCustomFieldSettingTypeGeneralAtMountTime: state.clientDetailStore.hasReportCustomFieldSettingTypeGeneralAtMountTime,
  companyCertifications: state.clientDetailStore.companyCertifications,
  isFetchingCompanyCertifications: state.clientDetailStore.isFetchingCompanyCertifications,
  isSaving: state.clientDetailStore.isSaving,
  errors: state.clientDetailStore.errors,
  jwtInfo: state.currentUserStore.jwtInfo,

  companyUsers: state.clientDetailStore.companyUsers,
  isFetchingCompanyUsers: state.clientDetailStore.isFetchingCompanyUsers
});

const mapDispatchToProps: IClientDetailContainerDispatchProps = {
  reset: clientDetailStore.reset,
  makeReadyForRendering: clientDetailStore.makeReadyForRendering,
  setClientName: clientDetailStore.setClientName,
  setClientAddress: clientDetailStore.setClientAddress,
  setClientCity: clientDetailStore.setClientCity,
  setClientProvince: clientDetailStore.setClientProvince,
  setClientPostalCode: clientDetailStore.setClientPostalCode,
  setClientCountry: clientDetailStore.setClientCountry,
  setClientReportCustomFieldSettingType: clientDetailStore.setClientReportCustomFieldSettingType,
  setClientRequestCustomFieldSettingType: clientDetailStore.setClientRequestCustomFieldSettingType,
  setClientHasReportVersions: clientDetailStore.setClientHasReportVersions,
  setClientLogo: clientDetailStore.setClientLogo,
  setIsView: clientDetailStore.setIsView,
  fetchClient: clientDetailStore.fetchClient,
  resetClient: clientDetailStore.resetClient,
  updateClient: clientDetailStore.updateClient,
  fetchCompanyCertifications: clientDetailStore.fetchCompanyCertifications,
  setEditReportCustomFieldSettingsDialogCompanyId: dialogsStore.setEditReportCustomFieldSettingsDialogCompanyId,

  fetchCompanyUsers: clientDetailStore.fetchCompanyUsers
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface IClientDetailContainerState {
  selectedTab: string;
}

export default compose(
  withRouter,
  withTranslation(),
  connector
)(ClientDetailContainer) as ComponentType;
