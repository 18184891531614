import moment from 'moment';

import { URL } from '../common/constants';
import { ICompany } from '../common/model/company.model';
import { ICompanyCertification } from '../common/model/companyCertification.model';
import { IProduct } from '../common/model/product.model';
import { ICompanySupplier } from '../common/model/companySupplier.model';
import { ISupplierProduct } from '../common/model/supplierProduct.model';
import { ICustomFieldSettings } from '../common/model/customFieldSettings.model';
import * as services from '.';
import { sortAZ } from '../utils/sort.utils';
import { IUser } from '../common/model/user.model';

export interface ICompaniesService {
  fetch(id: string): Promise<ICompany>;
  update(company: ICompany): Promise<ICompany>;
  fetchCompanyCertifications(id: string): Promise<ICompanyCertification[]>;
  fetchSaleProducts(id: string): Promise<IProduct[]>;
  fetchPurchaseProducts(id: string): Promise<IProduct[]>;
  fetchCompanySuppliers(id: string): Promise<ICompanySupplier[]>;
  fetchSupplierProducts(id: string, supplierId: string): Promise<ISupplierProduct[]>;
  fetchReportCustomFieldSettings(id: string): Promise<ICustomFieldSettings[]>;
  fetchRequestCustomFieldSettings(id: string): Promise<ICustomFieldSettings[]>;
  getCompanies(): Promise<any>;
  getCompanyById(id: string): Promise<any>;
  fetchCompanyUsers(id: string): Promise<IUser[]>;
  fetchActiveCompanyUsers(id: string): Promise<IUser[]>;
}

class CompaniesService implements ICompaniesService {
  public constructor(private httpClient: services.IHttpClient) {

  }

  public fetch(id: string): Promise<ICompany> {
    return this.httpClient.getCustom(`${URL.WEB.BASE}${URL.WEB.COMPANIES}/${id}`);
  }

  public update(company: ICompany): Promise<ICompany> {
    return this.httpClient.putCustom(`${URL.WEB.BASE}${URL.WEB.COMPANIES}/${company.id}`, company);
  }

  public async fetchCompanyCertifications(id: string): Promise<ICompanyCertification[]> {
    try {
      return (await this.httpClient.getCustom<ICompanyCertification[]>(`${URL.WEB.BASE}${URL.WEB.COMPANIES}/${id}/companyCertifications`))
        .map((companyCertification) => ({
          ...companyCertification,
          validFromDate: moment(companyCertification.validFromDate),
          validUntilDate: moment(companyCertification.validUntilDate)
        }));
    } catch (error) {
      return [];
    }
  }

  public async fetchCompanyUsers(id: string): Promise<IUser[]> {
    try {
      return (await this.httpClient.getCustom<IUser[]>(`${URL.WEB.BASE}${URL.WEB.COMPANIES}/${id}/companyUsersWithInfo`));
    } catch (error) {
      return [];
    }
  }

  public async fetchActiveCompanyUsers(id: string): Promise<IUser[]> {
    try {
      const users = await this.fetchCompanyUsers(id);
      const enabledUsers = users.filter(u => u.disabled === false);

      return enabledUsers;
    } catch (error) {
      return [];
    }
  }

  public async fetchSaleProducts(id: string): Promise<IProduct[]> {
    try {
      return await this.httpClient.getCustom<IProduct[]>(`${URL.WEB.BASE}${URL.WEB.COMPANIES}/${id}/saleProducts`);
    } catch (error) {
      return [];
    }
  }

  public async fetchPurchaseProducts(id: string): Promise<IProduct[]> {
    try {
      return await this.httpClient.getCustom<IProduct[]>(`${URL.WEB.BASE}${URL.WEB.COMPANIES}/${id}/purchaseProducts`);
    } catch (error) {
      return [];
    }
  }

  public async fetchCompanySuppliers(id: string): Promise<ICompanySupplier[]> {
    try {
      return await this.httpClient.getCustom<ICompanySupplier[]>(`${URL.WEB.BASE}${URL.WEB.COMPANIES}/${id}/companySuppliers`);
    } catch (error) {
      return [];
    }
  }

  public async fetchSupplierProducts(id: string, supplierId: string): Promise<ISupplierProduct[]> {
    try {
      return await this.httpClient.getCustom<ISupplierProduct[]>(`${URL.WEB.BASE}${URL.WEB.COMPANIES}/${id}/suppliers/${supplierId}/supplierProducts`);
    } catch (error) {
      return [];
    }
  }

  public async fetchReportCustomFieldSettings(id: string): Promise<ICustomFieldSettings[]> {
    try {
      return await this.httpClient.getCustom<ICustomFieldSettings[]>(`${URL.WEB.BASE}${URL.WEB.COMPANIES}/${id}/reportCustomFieldSettings`);
    } catch (error) {
      return [];
    }
  }

  public async fetchRequestCustomFieldSettings(id: string): Promise<ICustomFieldSettings[]> {
    try {
      return await this.httpClient.getCustom<ICustomFieldSettings[]>(`${URL.WEB.BASE}${URL.WEB.COMPANIES}/${id}/requestCustomFieldSettings`);
    } catch (error) {
      return [];
    }
  }

  public async getCompanies() {
    const companies = await this.httpClient.get(`${URL.WEB.BASE}${URL.WEB.COMPANIES}`);
    return sortAZ<any>(companies, 'name');
  }

  public async getCompanyById(id: string) {
    const companies = await this.httpClient.get(`${URL.WEB.BASE}${URL.WEB.COMPANIES}/${id}`);
    return companies;
  }
}

export const companiesService = new CompaniesService(services.httpClient);
