import { DataManager } from '@syncfusion/ej2-data';

import store from '../store';
import { CustomODataV4Adaptor } from '../interceptors';

export interface IDataManagerService {
  buildDataManager(url: string): DataManager;
}

class DataManagerService implements IDataManagerService {
  public buildDataManager(url: string): DataManager {
    return new DataManager({
      adaptor: new CustomODataV4Adaptor(),
      crossDomain: true,
      url: `${store.getState().settingsStore.settings.api}${url}`
    });
  }
}

export const dataManagerService = new DataManagerService();
