import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { ICertificatesGridActionsColumnProps } from '../../../interfaces/props/ICertificatesListProps';

type CertificatesGridActionsColumnPropsType = ICertificatesGridActionsColumnProps & WithTranslation;

type CertificatesGridActionsColumnStateType = CertificatesGridActionsColumnState;

class CertificatesGridActionsColumn extends Component<CertificatesGridActionsColumnPropsType, CertificatesGridActionsColumnStateType> {
  public constructor(props: CertificatesGridActionsColumnPropsType) {
    super(props);

    this.state = {
      isSelected: false
    };
  }

  public onIsSelectedCheckboxChange() {
    this.setState({ isSelected: !this.state.isSelected }, () => this.props.onIsSelectedChange(this.state.isSelected));
  }

  public render() {
    const { showRequestRenewalButtons, onRequestRenewalButtonClick, onRequestRenewalCancellationButtonClick, i18n, showShareCertificateButton, onShareCertificateButtonClick, certificate } = this.props;

    return (
      <div className='row no-gutters'>
        <div className='col'>
          <div className='row no-gutters align-items-center'>
            {showShareCertificateButton && <div className='col-auto ml-2'>
              <button
                type='button'
                className='btn btn-sm btn-light'
                title={i18n.t('certificatesList.actions.shareCertificate')}
                onClick={() => { onShareCertificateButtonClick(certificate); }}
              >
                <span className='icon icon-share-square-o' />
                <span className='bwi' >{i18n.t('certificatesList.actions.shareCertificate')}</span>
              </button>
            </div>}
            {showRequestRenewalButtons && <div className='col-auto ml-2'>
              <button
                type='button'
                className='btn btn-sm btn-light'
                title={i18n.t('certificatesList.actions.requestRenewal')}
                onClick={() => onRequestRenewalButtonClick()}
              >
                <span className='icon icon-compose' />
                <span className='bwi' >{i18n.t('certificatesList.actions.requestRenewal')}</span>
              </button>
            </div>}
            {showRequestRenewalButtons && <div className='col-auto ml-2'>
              <button
                type='button'
                className='btn btn-sm btn-light'
                title={i18n.t('certificatesList.actions.requestRenewalCancellation')}
                onClick={() => onRequestRenewalCancellationButtonClick()}
              >
                <span className='icon icon-app-item-close' />
                <span className='bwi' >{i18n.t('certificatesList.actions.requestRenewalCancellation')}</span>
              </button>
            </div>}
            {false /*showRequestRenewalButtons*/ && <div className='col-auto ml-2'>
              <input className='e-table-check' type='checkbox' checked={this.state.isSelected} onChange={() => this.onIsSelectedCheckboxChange()} />
            </div>}
          </div>
        </div>
      </div>
    );
  }
}

interface CertificatesGridActionsColumnState {
  isSelected: boolean;
}

export default withTranslation()(CertificatesGridActionsColumn);
