import * as signalR from '@aspnet/signalr';
import { URL } from '../common/constants';
/* import { notifyError } from '../utils/toast.utils';
import i18n from '../i18n'; */
import { settings } from './settings.service';

type IUpdateReportStatus = (args: []) => void;

class SignalRService {
  private hubConnection: signalR.HubConnection;

  public async startConnection(methodName: string, updateReportStatus: IUpdateReportStatus) {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${settings.api}${URL.SIGNALR.HUB}${URL.SIGNALR.GARMENTRATINGS}`)
      .build();

    try {
      await this.hubConnection.start();
    } catch (e) {
      // TODO: volver a mostrar el error
      //notifyError(i18n.t('SignalR.connectionError'));
    }

    this.hubConnection.on(methodName, updateReportStatus);
  }
}

export const signalRService = new SignalRService();
