import React, { Component, Fragment, ComponentType } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect, ConnectedProps } from 'react-redux';
import { compose } from 'redux';
import { DataManager } from '@syncfusion/ej2-data';
import { SortOrder } from '@syncfusion/ej2-lists';
import { IEditCell } from '@syncfusion/ej2-grids';
import { FieldSettingsModel } from '@syncfusion/ej2-dropdowns';
import { DialogResult, dialogsService } from '@aitex/tsx-extranet-dialogs';

import { RootState } from '../../store';
import { IDialogsContainerStateProps, IDialogsContainerDispatchProps } from '../../interfaces/props/IDialogsProps';
import * as dialogsStore from '../../store/modules/dialogs.store';
import { URL } from '../../common/constants';
import { IAdvancedFilter } from '../../common/model/advancedFilter.model';
import { ISupplierProduct } from '../../common/model/supplierProduct.model';
import { IReportCustomFieldSettingsWithCheckboxes } from '../../common/model/customFieldSettings.model';
import { ICurrentUserWithAccessToken } from '../../common/model/currentUser.model';
import { ISimulateExternalUser } from '../../common/model/simulateExternalUser.model';
import { IChain } from '../../common/model/chain.model';
import { IErrors } from '../../common/model/errors.model';
import { DialogId } from '../../common/model/enumerations/dialogId.model';
import { SourceType } from '../../common/model/enumerations/sourceType.model';
import { oDataService, supplierProductsService, companiesService, dataManagerService, reportCustomFieldSettingsService, reportsService } from '../../services';
import GenericConfirmationDialog from './genericConfirmationDialog/genericConfirmationDialog';
import DiscardChangesDialog from './discardChangesDialog/discardChangesDialog';
import DeactivateEntityDialog from './deactivateEntityDialog/deactivateEntityDialog';
import EditAnnexDialog from './editAnnexDialog/editAnnexDialog';
import EditAnnexStateDialog from './editAnnexStateDialog/editAnnexStateDialog';
import SaveAdvancedFilterDialog from './saveAdvancedFilterDialog/saveAdvancedFilterDialog';
import ApplyAdvancedFiltersDialog from './applyAdvancedFiltersDialog/applyAdvancedFiltersDialog';
import ShareAdvancedFilterDialog from './shareAdvancedFilterDialog/shareAdvancedFilterDialog';
import UploadAnnexDialog from './uploadAnnexDialog/uploadAnnexDialog';
import ChangeCompanyDialog from './changeCompanyDialog/changeCompanyDialog';
import ContextualHelpDialog from './contextualHelpDialog/contextualHelpDialog';
import SimulateExternalUserDialog from './simulateExternalUserDialog/simulateExternalUserDialog';
import EditCompanySupplierPurchaseProductsDialog from './editCompanySupplierPurchaseProductsDialog/editCompanySupplierPurchaseProductsDialog';
import EditReportCustomFieldSettingsDialog from './editReportCustomFieldSettingsDialog/editReportCustomFieldSettingsDialog';
import RequestCertificateRenewalDialog from './requestCertificateRenewalDialog/requestCertificateRenewalDialog';
import ReportVersionsDialog from './reportVersionsDialog/reportVersionsDialog';
import EditChainSeriesDialog from './editChainSeriesDialog/editChainSeriesDialog';
import CreateNoteDialog from './createNoteDialog/createNoteDialog';
import SendReportEmailDialog from './sendReportEmailDialog/sendReportEmailDialog';
import ShareCertificateEmailDialog from './shareCertificateEmailDialog/shareCertificateEmailDialog';
import UserFeedbackDialog from './userFeedbackDialog/userFeedbackDialog';

type DialogsContainerPropsType = PropsFromRedux & RouteComponentProps;

class DialogsContainer extends Component<DialogsContainerPropsType> {
  public advancedFiltersDataManager: DataManager = null;

  public companiesDataManager: DataManager = null;

  public reportsDataManager: DataManager = null;

  public usersDataManager: DataManager = null;

  public usersBasicDataManager: DataManager = null;

  public purchaseProductsDataManager: DataManager = null;
  public purchaseProductsFields: FieldSettingsModel = { value: 'name' };
  public purchaseProductsSortOrder: SortOrder = 'Ascending';
  public purchaseProductsParams: IEditCell = null;

  public seriesDataManager: DataManager = null;
  public seriesFields: FieldSettingsModel = { value: 'name' };
  public seriesSortOrder: SortOrder = 'Ascending';
  public seriesParams: IEditCell = null;

  public constructor(props: DialogsContainerPropsType) {
    super(props);

    this.advancedFiltersDataManager = dataManagerService.buildDataManager(URL.ODATA.ADVANCED_FILTER_FILTERS);

    this.companiesDataManager = dataManagerService.buildDataManager(URL.ODATA.COMPANY_FILTERS);

    this.reportsDataManager = dataManagerService.buildDataManager(URL.ODATA.REPORT_FILTERS);

    this.usersDataManager = dataManagerService.buildDataManager(URL.ODATA.USER_FILTERS);

    this.usersBasicDataManager = dataManagerService.buildDataManager(URL.ODATA.USER_BASIC_FILTERS);

    this.purchaseProductsDataManager = dataManagerService.buildDataManager(URL.ODATA.PRODUCT_FILTERS);
    this.purchaseProductsParams = {
      params: {
        actionComplete: () => false,
        allowFiltering: true,
        dataSource: this.purchaseProductsDataManager,
        fields: this.purchaseProductsFields,
        sortOrder: this.purchaseProductsSortOrder,
        filterType: 'Contains'
      },
      read: (args: any) => {
        return args.ej2_instances[0].itemData;
      }
    };

    this.seriesDataManager = dataManagerService.buildDataManager(URL.ODATA.SERIES_FILTERS);
    this.seriesParams = {
      params: {
        actionComplete: () => false,
        allowFiltering: true,
        dataSource: this.seriesDataManager,
        fields: this.seriesFields,
        sortOrder: this.seriesSortOrder,
        filterType: 'Contains'
      },
      read: (args: any) => {
        return args.ej2_instances[0].itemData;
      }
    };

    this.registerDialogs();
  }

  public componentDidUpdate(prevProps: DialogsContainerPropsType) {
    if (!prevProps.isChangeCompanyDialogVisible && this.props.isChangeCompanyDialogVisible) {
      this.props.fetchCompanies(oDataService, this.companiesDataManager);
    }

    if (!prevProps.isEditCompanySupplierPurchaseProductsDialogVisible && this.props.isEditCompanySupplierPurchaseProductsDialogVisible) {
      this.props.fetchSupplierProducts(companiesService);
    }

    if (!prevProps.isEditReportCustomFieldSettingsDialogVisible && this.props.isEditReportCustomFieldSettingsDialogVisible) {
      this.props.fetchReportCustomFieldCodes(reportCustomFieldSettingsService);
      this.props.fetchReportCustomFieldSettings(companiesService);
    }

    if (!prevProps.isReportVersionsDialogVisible && this.props.isReportVersionsDialogVisible) {
      this.props.fetchReportVersions(reportsService);
    }
  }

  public onGenericConfirmationDialogClose() {
    dialogsService.closeDialog(DialogId.GENERIC_CONFIRMATION);
  }

  public onGenericConfirmationDialogDismiss() {
    dialogsService.dismissDialog(DialogId.GENERIC_CONFIRMATION);
  }

  public onDiscardChangesDialogClose() {
    dialogsService.closeDialog(DialogId.DISCARD_CHANGES);
  }

  public onDiscardChangesDialogDismiss() {
    dialogsService.dismissDialog(DialogId.DISCARD_CHANGES);
  }

  public onDeactivateEntityDialogClose() {
    dialogsService.closeDialog(DialogId.DEACTIVATE_ENTITY);
  }

  public onDeactivateEntityDialogDismiss(reason?: IErrors) {
    dialogsService.dismissDialog(DialogId.DEACTIVATE_ENTITY, reason);
  }

  public onEditAnnexDialogClose() {
    dialogsService.closeDialog(DialogId.EDIT_ANNEX);
  }

  public onEditAnnexDialogDismiss(reason?: IErrors) {
    dialogsService.dismissDialog(DialogId.EDIT_ANNEX, reason);
  }

  public onEditAnnexStateDialogClose() {
    dialogsService.closeDialog(DialogId.EDIT_ANNEX_STATE);
  }

  public onEditAnnexStateDialogDismiss(reason?: IErrors) {
    dialogsService.dismissDialog(DialogId.EDIT_ANNEX_STATE, reason);
  }

  public onSaveAdvancedFilterDialogClose(advancedFilter: IAdvancedFilter) {
    dialogsService.closeDialog(DialogId.SAVE_ADVANCED_FILTER, advancedFilter);
  }

  public onSaveAdvancedFilterDialogDismiss(reason?: IErrors) {
    dialogsService.dismissDialog(DialogId.SAVE_ADVANCED_FILTER, reason);
  }

  public onApplyAdvancedFiltersDialogClose(advancedFilter: IAdvancedFilter) {
    dialogsService.closeDialog(DialogId.APPLY_ADVANCED_FILTERS, advancedFilter);
  }

  public onApplyAdvancedFiltersDialogDismiss(reason?: IErrors) {
    dialogsService.dismissDialog(DialogId.APPLY_ADVANCED_FILTERS, reason);
  }

  public onShareAdvancedFilterDialogClose(advancedFilter: IAdvancedFilter) {
    dialogsService.closeDialog(DialogId.SHARE_ADVANCED_FILTER, advancedFilter);
  }

  public onShareAdvancedFilterDialogDismiss(reason?: IErrors) {
    dialogsService.dismissDialog(DialogId.SHARE_ADVANCED_FILTER, reason);
  }

  public onUploadAnnexDialogClose() {
    dialogsService.closeDialog(DialogId.UPLOAD_ANNEX);
  }

  public onUploadAnnexDialogDismiss(reason?: IErrors) {
    dialogsService.dismissDialog(DialogId.UPLOAD_ANNEX, reason);
  }

  public onChangeCompanyDialogClose(currentUserWithAccessToken: ICurrentUserWithAccessToken) {
    dialogsService.closeDialog(DialogId.CHANGE_COMPANY, currentUserWithAccessToken);
  }

  public onChangeCompanyDialogDismiss(reason?: IErrors) {
    dialogsService.dismissDialog(DialogId.CHANGE_COMPANY, reason);
  }

  public onContextualHelpDialogClose() {
    dialogsService.closeDialog(DialogId.CONTEXTUAL_HELP);
  }

  public onContextualHelpDialogDismiss() {
    dialogsService.dismissDialog(DialogId.CONTEXTUAL_HELP);
  }

  public onSimulateExternalUserDialogClose(simulateExternalUser: ISimulateExternalUser) {
    dialogsService.closeDialog(DialogId.SIMULATE_EXTERNAL_USER, simulateExternalUser);
  }

  public onSimulateExternalUserDialogDismiss(reason?: IErrors) {
    dialogsService.dismissDialog(DialogId.SIMULATE_EXTERNAL_USER, reason);
  }

  public onEditCompanySupplierPurchaseProductsDialogClose() {
    dialogsService.closeDialog(DialogId.EDIT_COMPANY_SUPPLIER_PURCHASE_PRODUCTS);
  }

  public onEditCompanySupplierPurchaseProductsDialogDismiss() {
    dialogsService.dismissDialog(DialogId.EDIT_COMPANY_SUPPLIER_PURCHASE_PRODUCTS);
  }

  public onEditReportCustomFieldSettingsDialogClose() {
    dialogsService.closeDialog(DialogId.EDIT_REPORT_CUSTOM_FIELD_SETTINGS);
  }

  public onEditReportCustomFieldSettingsDialogDismiss() {
    dialogsService.dismissDialog(DialogId.EDIT_REPORT_CUSTOM_FIELD_SETTINGS);
  }

  public onRequestCertificateRenewalDialogClose() {
    dialogsService.closeDialog(DialogId.REQUEST_CERTIFICATE_RENEWAL);
  }

  public onRequestCertificateRenewalDialogDismiss(reason?: IErrors) {
    dialogsService.dismissDialog(DialogId.REQUEST_CERTIFICATE_RENEWAL, reason);
  }

  public onReportVersionsDialogClose() {
    dialogsService.closeDialog(DialogId.REPORT_VERSIONS);
  }

  public onReportVersionsDialogDismiss() {
    dialogsService.dismissDialog(DialogId.REPORT_VERSIONS);
  }

  public onEditChainSeriesDialogClose(chain: IChain) {
    dialogsService.closeDialog(DialogId.EDIT_CHAIN_SERIES, chain);
  }

  public onEditChainSeriesDialogDismiss() {
    dialogsService.dismissDialog(DialogId.EDIT_CHAIN_SERIES);
  }

  public onCreateNoteDialogClose() {
    dialogsService.closeDialog(DialogId.CREATE_NOTE);
  }

  public onCreateNoteDialogDismiss(reason?: IErrors) {
    dialogsService.dismissDialog(DialogId.CREATE_NOTE, reason);
  }

  public onSendReportEmailDialogClose() {
    dialogsService.closeDialog(DialogId.SEND_REPORT_EMAIL);
  }

  public onSendReportEmailDialogDismiss(reason?: IErrors) {
    dialogsService.dismissDialog(DialogId.SEND_REPORT_EMAIL, reason);
  }

  public onShareCertificateEmailDialogClose() {
    dialogsService.closeDialog(DialogId.SHARE_CERTIFICATE_EMAIL);
  }

  public onShareCertificateEmailDialogDismiss(reason?: IErrors) {
    dialogsService.dismissDialog(DialogId.SHARE_CERTIFICATE_EMAIL, reason);
  }

  public onUserFeedbackDialogClose() {
    dialogsService.closeDialog(DialogId.USER_FEEDBACK);
  }

  public onUserFeedbackDialogDismiss() {
    dialogsService.dismissDialog(DialogId.USER_FEEDBACK);
  }

  public getAdvancedFilter(): IAdvancedFilter {
    const { reportsListAdvancedFilter, location } = this.props;

    if (location.pathname.includes('/reports-list')) {
      return reportsListAdvancedFilter;
    }

    return null;
  }

  public getReportId(): string {
    if (this.props.location.pathname.includes('/report/')) {
      return this.props.location.pathname.substring(this.props.location.pathname.lastIndexOf('/report/') + 8, this.props.location.pathname.lastIndexOf('/'));
    }
    return null;
  }

  public getSourceType(): number {
    if (this.props.location.pathname.includes('/report/')) {
      return SourceType.REPORT;
    }
    if (this.props.location.pathname.includes('/request/')) {
      return SourceType.REQUEST;
    }
    return null;
  }

  public getSourceId(): string {
    if (this.props.location.pathname.includes('/report/')) {
      return this.props.location.pathname.substring(this.props.location.pathname.lastIndexOf('/report/') + 8, this.props.location.pathname.lastIndexOf('/'));
    }
    if (this.props.location.pathname.includes('/request/')) {
      return this.props.location.pathname.substring(this.props.location.pathname.lastIndexOf('/request/') + 9, this.props.location.pathname.lastIndexOf('/'));
    }
    return null;
  }

  public onSaveSupplierProduct(supplierProduct: ISupplierProduct) {
    this.props.saveSupplierProduct(supplierProduct, supplierProductsService, companiesService);
  }

  public onSaveReportCustomFieldSettings(reportCustomFieldSettingsWithCheckboxes: IReportCustomFieldSettingsWithCheckboxes) {
    this.props.saveReportCustomFieldSettings(reportCustomFieldSettingsWithCheckboxes, reportCustomFieldSettingsService, companiesService);
  }

  public render() {
    const {
      isGenericConfirmationDialogVisible,
      isDiscardChangesDialogVisible,
      isDeactivateEntityDialogVisible,
      deactivateEntityDialogEntityId,
      deactivateEntityDialogEntityName,
      deactivateEntityDialogDeactivate,
      isEditAnnexDialogVisible,
      editAnnexDialogAnnex,
      isEditAnnexStateDialogVisible,
      editAnnexStateDialogAnnex,
      isSaveAdvancedFilterDialogVisible,
      isApplyAdvancedFiltersDialogVisible,
      isShareAdvancedFilterDialogVisible,
      isUploadAnnexDialogVisible,
      isChangeCompanyDialogVisible,
      changeCompanyDialogCompanies,
      isContextualHelpDialogVisible,
      contextualHelpDialogCode,
      isSimulateExternalUserDialogVisible,
      isEditCompanySupplierPurchaseProductsDialogVisible,
      editCompanySupplierPurchaseProductsDialogCompanyId,
      editCompanySupplierPurchaseProductsDialogSupplierId,
      editCompanySupplierPurchaseProductsDialogSupplierProducts,
      editCompanySupplierPurchaseProductsDialogIsFetching,
      isEditReportCustomFieldSettingsDialogVisible,
      editReportCustomFieldSettingsDialogCompanyId,
      editReportCustomFieldSettingsDialogReportCustomFieldCodes,
      editReportCustomFieldSettingsDialogReportCustomFieldSettingsWithCheckboxes,
      editReportCustomFieldSettingsDialogIsFetching,
      editReportCustomFieldSettingsDialogErrors,
      isRequestCertificateRenewalDialogVisible,
      requestCertificateRenewalDialogCancellationMode,
      requestCertificateRenewalDialogCertificatesIds,
      isReportVersionsDialogVisible,
      reportVersionsDialogReportCombo,
      reportVersionsDialogReports,
      reportVersionsDialogIsFetching,
      isEditChainSeriesDialogVisible,
      editChainSeriesDialogChain,
      isCreateNoteDialogVisible,
      isSendReportEmailDialogVisible,
      sendReportEmailDialogReport,
      currentUser,
      currentUserCompany,
      accessToken,
      isShareCertificateEmailDialogVisible,
      shareCertificateEmailDialogCertificate,
      isUserFeedbackDialogVisible
    } = this.props;

    return (
      <Fragment>
        <GenericConfirmationDialog
          visible={isGenericConfirmationDialogVisible}
          onClose={() => this.onGenericConfirmationDialogClose()}
          onDismiss={() => this.onGenericConfirmationDialogDismiss()}
        />
        <DiscardChangesDialog
          visible={isDiscardChangesDialogVisible}
          onClose={() => this.onDiscardChangesDialogClose()}
          onDismiss={() => this.onDiscardChangesDialogDismiss()}
        />
        <DeactivateEntityDialog
          visible={isDeactivateEntityDialogVisible}
          onClose={() => this.onDeactivateEntityDialogClose()}
          onDismiss={(reason) => this.onDeactivateEntityDialogDismiss(reason)}
          entityId={deactivateEntityDialogEntityId}
          entityName={deactivateEntityDialogEntityName}
          deactivate={deactivateEntityDialogDeactivate}
        />
        <EditAnnexDialog
          visible={isEditAnnexDialogVisible}
          onClose={() => this.onEditAnnexDialogClose()}
          onDismiss={(reason) => this.onEditAnnexDialogDismiss(reason)}
          annex={editAnnexDialogAnnex}
        />
        <EditAnnexStateDialog
          visible={isEditAnnexStateDialogVisible}
          onClose={() => this.onEditAnnexStateDialogClose()}
          onDismiss={(reason) => this.onEditAnnexStateDialogDismiss(reason)}
          annex={editAnnexStateDialogAnnex}
        />
        <SaveAdvancedFilterDialog
          visible={isSaveAdvancedFilterDialogVisible}
          onClose={(advancedFilter) => this.onSaveAdvancedFilterDialogClose(advancedFilter)}
          onDismiss={(reason) => this.onSaveAdvancedFilterDialogDismiss(reason)}
          advancedFilter={this.getAdvancedFilter()}
          currentUser={currentUser}
          currentUserCompany={currentUserCompany}
          companiesDataManager={this.companiesDataManager}
        />
        <ApplyAdvancedFiltersDialog
          visible={isApplyAdvancedFiltersDialogVisible}
          onClose={(advancedFilter) => this.onApplyAdvancedFiltersDialogClose(advancedFilter)}
          onDismiss={(reason) => this.onApplyAdvancedFiltersDialogDismiss(reason)}
          currentUser={currentUser}
          currentUserCompany={currentUserCompany}
          advancedFiltersDataManager={this.advancedFiltersDataManager}
          companiesDataManager={this.companiesDataManager}
        />
        <ShareAdvancedFilterDialog
          visible={isShareAdvancedFilterDialogVisible}
          onClose={(advancedFilter) => this.onShareAdvancedFilterDialogClose(advancedFilter)}
          onDismiss={(reason) => this.onShareAdvancedFilterDialogDismiss(reason)}
          advancedFilter={this.getAdvancedFilter()}
          companiesDataManager={this.companiesDataManager}
        />
        <UploadAnnexDialog
          visible={isUploadAnnexDialogVisible}
          onClose={() => this.onUploadAnnexDialogClose()}
          onDismiss={(reason) => this.onUploadAnnexDialogDismiss(reason)}
          sourceType={this.getSourceType()}
          sourceId={this.getSourceId()}
          accessToken={accessToken}
        />
        <ChangeCompanyDialog
          visible={isChangeCompanyDialogVisible}
          onClose={(currentUserWithAccessToken) => this.onChangeCompanyDialogClose(currentUserWithAccessToken)}
          onDismiss={(reason) => this.onChangeCompanyDialogDismiss(reason)}
          companies={changeCompanyDialogCompanies}
          currentUserCompanyName={currentUserCompany && currentUserCompany.name}
        />
        <ContextualHelpDialog
          visible={isContextualHelpDialogVisible}
          onClose={() => this.onContextualHelpDialogClose()}
          onDismiss={() => this.onContextualHelpDialogDismiss()}
          code={contextualHelpDialogCode}
        />
        <SimulateExternalUserDialog
          visible={isSimulateExternalUserDialogVisible}
          onClose={(simulateExternalUser) => this.onSimulateExternalUserDialogClose(simulateExternalUser)}
          onDismiss={(reason) => this.onSimulateExternalUserDialogDismiss(reason)}
          companiesDataManager={this.companiesDataManager}
          usersDataManager={this.usersBasicDataManager}
        />
        <EditCompanySupplierPurchaseProductsDialog
          visible={isEditCompanySupplierPurchaseProductsDialogVisible}
          onClose={() => this.onEditCompanySupplierPurchaseProductsDialogClose()}
          onDismiss={() => this.onEditCompanySupplierPurchaseProductsDialogDismiss()}
          companyId={editCompanySupplierPurchaseProductsDialogCompanyId}
          supplierId={editCompanySupplierPurchaseProductsDialogSupplierId}
          supplierProducts={editCompanySupplierPurchaseProductsDialogSupplierProducts}
          isFetching={editCompanySupplierPurchaseProductsDialogIsFetching}
          purchaseProductsParams={this.purchaseProductsParams}
          onSaveSupplierProduct={(supplierProduct) => this.onSaveSupplierProduct(supplierProduct)}
        />
        <EditReportCustomFieldSettingsDialog
          visible={isEditReportCustomFieldSettingsDialogVisible}
          onClose={() => this.onEditReportCustomFieldSettingsDialogClose()}
          onDismiss={() => this.onEditReportCustomFieldSettingsDialogDismiss()}
          companyId={editReportCustomFieldSettingsDialogCompanyId}
          reportCustomFieldCodes={editReportCustomFieldSettingsDialogReportCustomFieldCodes}
          reportCustomFieldSettingsWithCheckboxes={editReportCustomFieldSettingsDialogReportCustomFieldSettingsWithCheckboxes}
          isFetching={editReportCustomFieldSettingsDialogIsFetching}
          errors={editReportCustomFieldSettingsDialogErrors}
          onSaveReportCustomFieldSettings={(reportCustomFieldSettings) => this.onSaveReportCustomFieldSettings(reportCustomFieldSettings)}
        />
        <RequestCertificateRenewalDialog
          visible={isRequestCertificateRenewalDialogVisible}
          onClose={() => this.onRequestCertificateRenewalDialogClose()}
          onDismiss={(reason) => this.onRequestCertificateRenewalDialogDismiss(reason)}
          cancellationMode={requestCertificateRenewalDialogCancellationMode}
          certificatesIds={requestCertificateRenewalDialogCertificatesIds}
        />
        <ReportVersionsDialog
          visible={isReportVersionsDialogVisible}
          onClose={() => this.onReportVersionsDialogClose()}
          onDismiss={() => this.onReportVersionsDialogDismiss()}
          reportCombo={reportVersionsDialogReportCombo}
          reports={reportVersionsDialogReports}
          isFetching={reportVersionsDialogIsFetching}
        />
        <EditChainSeriesDialog
          visible={isEditChainSeriesDialogVisible}
          onClose={(chain) => this.onEditChainSeriesDialogClose(chain)}
          onDismiss={() => this.onEditChainSeriesDialogDismiss()}
          chain={editChainSeriesDialogChain}
          seriesParams={this.seriesParams}
        />
        <CreateNoteDialog
          visible={isCreateNoteDialogVisible}
          onClose={() => this.onCreateNoteDialogClose()}
          onDismiss={(reason) => this.onCreateNoteDialogDismiss(reason)}
          sourceType={this.getSourceType()}
          sourceId={this.getSourceId()}
        />
        <SendReportEmailDialog
          visible={isSendReportEmailDialogVisible}
          onClose={() => this.onSendReportEmailDialogClose()}
          onDismiss={(reason) => this.onSendReportEmailDialogDismiss(reason)}
          report={sendReportEmailDialogReport}
        />
        <ShareCertificateEmailDialog
          visible={isShareCertificateEmailDialogVisible}
          certificate={shareCertificateEmailDialogCertificate}
          onClose={() => this.onShareCertificateEmailDialogClose()}
          onDismiss={(reason) => this.onShareCertificateEmailDialogDismiss(reason)}
        />
        <UserFeedbackDialog
          visible={isUserFeedbackDialogVisible}
          onClose={() => this.onUserFeedbackDialogClose()}
          onDismiss={() => this.onUserFeedbackDialogDismiss()}
          currentUserCompany={currentUserCompany}
          reportsDataManager={this.reportsDataManager}
          accessToken={accessToken}
        />
      </Fragment>
    );
  }

  private registerDialogs() {
    const {
      showGenericConfirmationDialog,
      hideGenericConfirmationDialog,
      showDiscardChangesDialog,
      hideDiscardChangesDialog,
      showDeactivateEntityDialog,
      hideDeactivateEntityDialog,
      showEditAnnexDialog,
      hideEditAnnexDialog,
      showEditAnnexStateDialog,
      hideEditAnnexStateDialog,
      showSaveAdvancedFilterDialog,
      hideSaveAdvancedFilterDialog,
      showApplyAdvancedFiltersDialog,
      hideApplyAdvancedFiltersDialog,
      showShareAdvancedFilterDialog,
      hideShareAdvancedFilterDialog,
      showUploadAnnexDialog,
      hideUploadAnnexDialog,
      showChangeCompanyDialog,
      hideChangeCompanyDialog,
      showContextualHelpDialog,
      hideContextualHelpDialog,
      showSimulateExternalUserDialog,
      hideSimulateExternalUserDialog,
      showEditCompanySupplierPurchaseProductsDialog,
      hideEditCompanySupplierPurchaseProductsDialog,
      showEditReportCustomFieldSettingsDialog,
      hideEditReportCustomFieldSettingsDialog,
      showRequestCertificateRenewalDialog,
      hideRequestCertificateRenewalDialog,
      showReportVersionsDialog,
      hideReportVersionsDialog,
      showEditChainSeriesDialog,
      hideEditChainSeriesDialog,
      showCreateNoteDialog,
      hideCreateNoteDialog,
      showSendReportEmailDialog,
      hideSendReportEmailDialog,
      showShareCertificateEmailDialog,
      hideShareCertificateEmailDialog,
      showUserFeedbackDialog,
      hideUserFeedbackDialog
    } = this.props;

    const genericConfirmationDialog = new DialogResult(
      DialogId.GENERIC_CONFIRMATION,
      () => showGenericConfirmationDialog(),
      () => hideGenericConfirmationDialog()
    );
    const discardChangesDialog = new DialogResult(
      DialogId.DISCARD_CHANGES,
      () => showDiscardChangesDialog(),
      () => hideDiscardChangesDialog()
    );
    const deactivateEntityDialog = new DialogResult(
      DialogId.DEACTIVATE_ENTITY,
      () => showDeactivateEntityDialog(),
      () => hideDeactivateEntityDialog()
    );
    const editAnnexDialog = new DialogResult(
      DialogId.EDIT_ANNEX,
      () => showEditAnnexDialog(),
      () => hideEditAnnexDialog()
    );
    const editAnnexStateDialog = new DialogResult(
      DialogId.EDIT_ANNEX_STATE,
      () => showEditAnnexStateDialog(),
      () => hideEditAnnexStateDialog()
    );
    const saveAdvancedFilterDialog = new DialogResult(
      DialogId.SAVE_ADVANCED_FILTER,
      () => showSaveAdvancedFilterDialog(),
      () => hideSaveAdvancedFilterDialog()
    );
    const applyAdvancedFiltersDialog = new DialogResult(
      DialogId.APPLY_ADVANCED_FILTERS,
      () => showApplyAdvancedFiltersDialog(),
      () => hideApplyAdvancedFiltersDialog()
    );
    const shareAdvancedFilterDialog = new DialogResult(
      DialogId.SHARE_ADVANCED_FILTER,
      () => showShareAdvancedFilterDialog(),
      () => hideShareAdvancedFilterDialog()
    );
    const uploadAnnexDialog = new DialogResult(
      DialogId.UPLOAD_ANNEX,
      () => showUploadAnnexDialog(),
      () => hideUploadAnnexDialog()
    );
    const changeCompanyDialog = new DialogResult(
      DialogId.CHANGE_COMPANY,
      () => showChangeCompanyDialog(),
      () => hideChangeCompanyDialog()
    );
    const contextualHelpDialog = new DialogResult(
      DialogId.CONTEXTUAL_HELP,
      () => showContextualHelpDialog(),
      () => hideContextualHelpDialog()
    );
    const simulateExternalUserDialog = new DialogResult(
      DialogId.SIMULATE_EXTERNAL_USER,
      () => showSimulateExternalUserDialog(),
      () => hideSimulateExternalUserDialog()
    );
    const editCompanySupplierPurchaseProductsDialog = new DialogResult(
      DialogId.EDIT_COMPANY_SUPPLIER_PURCHASE_PRODUCTS,
      () => showEditCompanySupplierPurchaseProductsDialog(),
      () => hideEditCompanySupplierPurchaseProductsDialog()
    );
    const editReportCustomFieldSettingsDialog = new DialogResult(
      DialogId.EDIT_REPORT_CUSTOM_FIELD_SETTINGS,
      () => showEditReportCustomFieldSettingsDialog(),
      () => hideEditReportCustomFieldSettingsDialog()
    );
    const requestCertificateRenewalDialog = new DialogResult(
      DialogId.REQUEST_CERTIFICATE_RENEWAL,
      () => showRequestCertificateRenewalDialog(),
      () => hideRequestCertificateRenewalDialog()
    );
    const reportVersionsDialog = new DialogResult(
      DialogId.REPORT_VERSIONS,
      () => showReportVersionsDialog(),
      () => hideReportVersionsDialog()
    );
    const editChainSeriesDialog = new DialogResult(
      DialogId.EDIT_CHAIN_SERIES,
      () => showEditChainSeriesDialog(),
      () => hideEditChainSeriesDialog()
    );
    const createNoteDialog = new DialogResult(
      DialogId.CREATE_NOTE,
      () => showCreateNoteDialog(),
      () => hideCreateNoteDialog()
    );
    const sendReportEmailDialog = new DialogResult(
      DialogId.SEND_REPORT_EMAIL,
      () => showSendReportEmailDialog(),
      () => hideSendReportEmailDialog()
    );
    const shareCertificateEmailDialog = new DialogResult(
      DialogId.SHARE_CERTIFICATE_EMAIL,
      () => showShareCertificateEmailDialog(),
      () => hideShareCertificateEmailDialog()
    );
    const userFeedbackDialog = new DialogResult(
      DialogId.USER_FEEDBACK,
      () => showUserFeedbackDialog(),
      () => hideUserFeedbackDialog()
    );

    dialogsService.registerDialogs([
      genericConfirmationDialog,
      discardChangesDialog,
      deactivateEntityDialog,
      editAnnexDialog,
      editAnnexStateDialog,
      saveAdvancedFilterDialog,
      applyAdvancedFiltersDialog,
      shareAdvancedFilterDialog,
      uploadAnnexDialog,
      changeCompanyDialog,
      contextualHelpDialog,
      simulateExternalUserDialog,
      editCompanySupplierPurchaseProductsDialog,
      editReportCustomFieldSettingsDialog,
      requestCertificateRenewalDialog,
      reportVersionsDialog,
      editChainSeriesDialog,
      createNoteDialog,
      sendReportEmailDialog,
      shareCertificateEmailDialog,
      userFeedbackDialog
    ]);
  }
}

const mapStateToProps = (state: RootState): IDialogsContainerStateProps => ({
  isGenericConfirmationDialogVisible: state.dialogsStore.genericConfirmationDialog.visible,
  isDiscardChangesDialogVisible: state.dialogsStore.discardChangesDialog.visible,
  isDeactivateEntityDialogVisible: state.dialogsStore.deactivateEntityDialog.visible,
  deactivateEntityDialogEntityId: state.dialogsStore.deactivateEntityDialog.entityId,
  deactivateEntityDialogEntityName: state.dialogsStore.deactivateEntityDialog.entityName,
  deactivateEntityDialogDeactivate: state.dialogsStore.deactivateEntityDialog.deactivate,
  isEditAnnexDialogVisible: state.dialogsStore.editAnnexDialog.visible,
  editAnnexDialogAnnex: state.dialogsStore.editAnnexDialog.annex,
  isEditAnnexStateDialogVisible: state.dialogsStore.editAnnexStateDialog.visible,
  editAnnexStateDialogAnnex: state.dialogsStore.editAnnexStateDialog.annex,
  isSaveAdvancedFilterDialogVisible: state.dialogsStore.saveAdvancedFilterDialog.visible,
  isApplyAdvancedFiltersDialogVisible: state.dialogsStore.applyAdvancedFiltersDialog.visible,
  isShareAdvancedFilterDialogVisible: state.dialogsStore.shareAdvancedFilterDialog.visible,
  isUploadAnnexDialogVisible: state.dialogsStore.uploadAnnexDialog.visible,
  isChangeCompanyDialogVisible: state.dialogsStore.changeCompanyDialog.visible,
  changeCompanyDialogCompanies: state.dialogsStore.changeCompanyDialog.companies,
  isContextualHelpDialogVisible: state.dialogsStore.contextualHelpDialog.visible,
  contextualHelpDialogCode: state.dialogsStore.contextualHelpDialog.code,
  isSimulateExternalUserDialogVisible: state.dialogsStore.simulateExternalUserDialog.visible,
  isEditCompanySupplierPurchaseProductsDialogVisible: state.dialogsStore.editCompanySupplierPurchaseProductsDialog.visible,
  editCompanySupplierPurchaseProductsDialogCompanyId: state.dialogsStore.editCompanySupplierPurchaseProductsDialog.companyId,
  editCompanySupplierPurchaseProductsDialogSupplierId: state.dialogsStore.editCompanySupplierPurchaseProductsDialog.supplierId,
  editCompanySupplierPurchaseProductsDialogSupplierProducts: state.dialogsStore.editCompanySupplierPurchaseProductsDialog.supplierProducts,
  editCompanySupplierPurchaseProductsDialogIsFetching: state.dialogsStore.editCompanySupplierPurchaseProductsDialog.isFetching,
  isEditReportCustomFieldSettingsDialogVisible: state.dialogsStore.editReportCustomFieldSettingsDialog.visible,
  editReportCustomFieldSettingsDialogCompanyId: state.dialogsStore.editReportCustomFieldSettingsDialog.companyId,
  editReportCustomFieldSettingsDialogReportCustomFieldCodes: state.dialogsStore.editReportCustomFieldSettingsDialog.reportCustomFieldCodes,
  editReportCustomFieldSettingsDialogReportCustomFieldSettingsWithCheckboxes: state.dialogsStore.editReportCustomFieldSettingsDialog.reportCustomFieldSettingsWithCheckboxes,
  editReportCustomFieldSettingsDialogIsFetching: state.dialogsStore.editReportCustomFieldSettingsDialog.isFetching,
  editReportCustomFieldSettingsDialogErrors: state.dialogsStore.editReportCustomFieldSettingsDialog.errors,
  isRequestCertificateRenewalDialogVisible: state.dialogsStore.requestCertificateRenewalDialog.visible,
  requestCertificateRenewalDialogCancellationMode: state.dialogsStore.requestCertificateRenewalDialog.cancellationMode,
  requestCertificateRenewalDialogCertificatesIds: state.dialogsStore.requestCertificateRenewalDialog.certificatesIds,
  isReportVersionsDialogVisible: state.dialogsStore.reportVersionsDialog.visible,
  reportVersionsDialogReportCombo: state.dialogsStore.reportVersionsDialog.reportCombo,
  reportVersionsDialogReports: state.dialogsStore.reportVersionsDialog.reports,
  reportVersionsDialogIsFetching: state.dialogsStore.reportVersionsDialog.isFetching,
  isEditChainSeriesDialogVisible: state.dialogsStore.editChainSeriesDialog.visible,
  editChainSeriesDialogChain: state.dialogsStore.editChainSeriesDialog.chain,
  isCreateNoteDialogVisible: state.dialogsStore.createNoteDialog.visible,
  isSendReportEmailDialogVisible: state.dialogsStore.sendReportEmailDialog.visible,
  sendReportEmailDialogReport: state.dialogsStore.sendReportEmailDialog.report,
  reportsListAdvancedFilter: state.reportsListStore.advancedFilter,
  currentUser: state.currentUserStore.user,
  currentUserCompany: state.currentUserStore.company,
  accessToken: state.currentUserStore.accessToken,
  isShareCertificateEmailDialogVisible: state.dialogsStore.shareCertificateEmailDialog.visible,
  shareCertificateEmailDialogCertificate: state.dialogsStore.shareCertificateEmailDialog.certificate,
  isUserFeedbackDialogVisible: state.dialogsStore.userFeedbackDialog.visible
});

const mapDispatchToProps: IDialogsContainerDispatchProps = {
  showGenericConfirmationDialog: dialogsStore.showGenericConfirmationDialog,
  hideGenericConfirmationDialog: dialogsStore.hideGenericConfirmationDialog,
  showDiscardChangesDialog: dialogsStore.showDiscardChangesDialog,
  hideDiscardChangesDialog: dialogsStore.hideDiscardChangesDialog,
  showDeactivateEntityDialog: dialogsStore.showDeactivateEntityDialog,
  hideDeactivateEntityDialog: dialogsStore.hideDeactivateEntityDialog,
  showEditAnnexDialog: dialogsStore.showEditAnnexDialog,
  hideEditAnnexDialog: dialogsStore.hideEditAnnexDialog,
  showEditAnnexStateDialog: dialogsStore.showEditAnnexStateDialog,
  hideEditAnnexStateDialog: dialogsStore.hideEditAnnexStateDialog,
  showSaveAdvancedFilterDialog: dialogsStore.showSaveAdvancedFilterDialog,
  hideSaveAdvancedFilterDialog: dialogsStore.hideSaveAdvancedFilterDialog,
  showApplyAdvancedFiltersDialog: dialogsStore.showApplyAdvancedFiltersDialog,
  hideApplyAdvancedFiltersDialog: dialogsStore.hideApplyAdvancedFiltersDialog,
  showShareAdvancedFilterDialog: dialogsStore.showShareAdvancedFilterDialog,
  hideShareAdvancedFilterDialog: dialogsStore.hideShareAdvancedFilterDialog,
  showUploadAnnexDialog: dialogsStore.showUploadAnnexDialog,
  hideUploadAnnexDialog: dialogsStore.hideUploadAnnexDialog,
  showChangeCompanyDialog: dialogsStore.showChangeCompanyDialog,
  hideChangeCompanyDialog: dialogsStore.hideChangeCompanyDialog,
  showContextualHelpDialog: dialogsStore.showContextualHelpDialog,
  hideContextualHelpDialog: dialogsStore.hideContextualHelpDialog,
  showSimulateExternalUserDialog: dialogsStore.showSimulateExternalUserDialog,
  hideSimulateExternalUserDialog: dialogsStore.hideSimulateExternalUserDialog,
  showEditCompanySupplierPurchaseProductsDialog: dialogsStore.showEditCompanySupplierPurchaseProductsDialog,
  hideEditCompanySupplierPurchaseProductsDialog: dialogsStore.hideEditCompanySupplierPurchaseProductsDialog,
  showEditReportCustomFieldSettingsDialog: dialogsStore.showEditReportCustomFieldSettingsDialog,
  hideEditReportCustomFieldSettingsDialog: dialogsStore.hideEditReportCustomFieldSettingsDialog,
  showRequestCertificateRenewalDialog: dialogsStore.showRequestCertificateRenewalDialog,
  hideRequestCertificateRenewalDialog: dialogsStore.hideRequestCertificateRenewalDialog,
  showReportVersionsDialog: dialogsStore.showReportVersionsDialog,
  hideReportVersionsDialog: dialogsStore.hideReportVersionsDialog,
  showEditChainSeriesDialog: dialogsStore.showEditChainSeriesDialog,
  hideEditChainSeriesDialog: dialogsStore.hideEditChainSeriesDialog,
  showCreateNoteDialog: dialogsStore.showCreateNoteDialog,
  hideCreateNoteDialog: dialogsStore.hideCreateNoteDialog,
  showSendReportEmailDialog: dialogsStore.showSendReportEmailDialog,
  hideSendReportEmailDialog: dialogsStore.hideSendReportEmailDialog,
  fetchCompanies: dialogsStore.fetchCompanies,
  fetchSupplierProducts: dialogsStore.fetchSupplierProducts,
  saveSupplierProduct: dialogsStore.saveSupplierProduct,
  fetchReportCustomFieldCodes: dialogsStore.fetchReportCustomFieldCodes,
  fetchReportCustomFieldSettings: dialogsStore.fetchReportCustomFieldSettings,
  saveReportCustomFieldSettings: dialogsStore.saveReportCustomFieldSettings,
  fetchReportVersions: dialogsStore.fetchReportVersions,
  showShareCertificateEmailDialog: dialogsStore.showShareCertificateEmailDialog,
  hideShareCertificateEmailDialog: dialogsStore.hideShareCertificateEmailDialog,
  showUserFeedbackDialog: dialogsStore.showUserFeedbackDialog,
  hideUserFeedbackDialog: dialogsStore.hideUserFeedbackDialog
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default compose(
  withRouter,
  connector
)(DialogsContainer) as ComponentType;
