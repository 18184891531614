import i18next from 'i18next';

import { IAdvancedFilter } from '../common/model/advancedFilter.model';
import { IAdvancedFilterTranslationBasic, IAdvancedFilterTranslation } from '../common/model/advancedFilterTranslation.model';
import { AdvancedFilterType } from '../common/model/enumerations/advancedFilterType.model';
import { insertItem, updateItem } from './array.utils';
import { getKeyByValue } from './enum.utils';

export const upsertTranslation = (advancedFilter: IAdvancedFilter, translationBasic: IAdvancedFilterTranslationBasic): IAdvancedFilter => {
  const translationWithSameLanguageIdIndex = advancedFilter.translations.findIndex((t) => t.codeIso === translationBasic.codeIso);

  const translation: IAdvancedFilterTranslation = { ...translationBasic, id: null, advancedFilterId: advancedFilter.id, description: null };
  if (translationWithSameLanguageIdIndex >= 0) {
    translation.id = advancedFilter.translations[translationWithSameLanguageIdIndex].id;
    advancedFilter.translations = updateItem(advancedFilter.translations, translationWithSameLanguageIdIndex, translation);
  } else {
    advancedFilter.translations = insertItem(advancedFilter.translations, translation);
  }

  return advancedFilter;
};

export const addAdditionalTranslations = (advancedFilter: IAdvancedFilter, currentLanguage: string, languages: string[]): IAdvancedFilter => {
  languages.filter((language) => language !== currentLanguage).forEach((language) => {
    advancedFilter = upsertTranslation(advancedFilter, { codeIso: language, name: null });
  });
  return advancedFilter;
};

export const removeAdditionalTranslations = (advancedFilter: IAdvancedFilter, currentLanguage: string): IAdvancedFilter => {
  advancedFilter.translations = advancedFilter.translations.filter((translation) => translation.codeIso === currentLanguage);
  return advancedFilter;
};

export const getName = (advancedFilter: IAdvancedFilter, currentLanguage: string): string => {
  if (advancedFilter.name) {
    return advancedFilter.name;
  }

  const activeTranslation = advancedFilter.translations.find((translation) => translation.codeIso === currentLanguage);
  if (activeTranslation) {
    return activeTranslation.name;
  }

  return null;
};

export const getTypeLocalizedTextByKey = (i18n: i18next.i18n, key: string, isCurrentUserInternal: boolean): string => {
  return i18n.t(`enums.advancedFilterType${isCurrentUserInternal ? '' : 'ForExternalUsers'}.${key}`);
};

export const getTypeLocalizedTextByValue = (i18n: i18next.i18n, type: number, isCurrentUserInternal: boolean): string => {
  const key = getKeyByValue(AdvancedFilterType, type);
  if (!key) {
    return '';
  }
  return getTypeLocalizedTextByKey(i18n, key, isCurrentUserInternal);
};
