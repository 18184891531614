import React, { Component } from 'react';
import ReportStatusTable from './table';
import Button from 'react-bootstrap/Button';
import { IReportStatusInfo } from './iReportStatusInfo';
import { IReportStatusState, ITableContent } from './table/iReportStatusTable';
import { WithTranslation, withTranslation } from 'react-i18next';
import { createReportStatusInfo, Status } from '../../../helpers';
import { reportStatusService } from '../../../services/reportStatus.service';
import { IForm } from '../../../interfaces/states/ILoginFormState';
import { signalRService } from '../../../services';
import { URL } from '../../../common/constants';

type ReportStatusInfoProps = IReportStatusInfo & WithTranslation;

enum generalStatus {
  PASS = 'generalStatusPass',
  NO_PASS = 'generalStatusNoPass',
  PENDING = 'generalStatusPending',
  CANCELLED = 'generalStatusCancelled'
}

class ReportStatusInfo extends Component<ReportStatusInfoProps, IReportStatusState> {
  public constructor(props: ReportStatusInfoProps) {
    super(props);
    this.state = {
      reportStatusInfo: null
    };
  }

  public componentDidMount() {
    const reportStatusInfo = createReportStatusInfo(this.props.reportStatus);
    this.setState({ reportStatusInfo });

    signalRService.startConnection(URL.SIGNALR.METHODS.UPDATEREPORTSTATUS, this.updateReportStatus.bind(this));
  }

  public render() {
    const results: ITableContent[] = [];
    if (this.state.reportStatusInfo) {
      this.state.reportStatusInfo.results.forEach((tableContent) => {
        if (tableContent.tableContent[0].value !== `${Status.NOT_IN_SELECTION}`) {
          results.push({ key: tableContent.title, value: tableContent.tableContent[0].value });
        }
      });
    }

    return (
      <div>
        {this.state.reportStatusInfo && <>
          <ReportStatusTable
            title={this.props.i18n.t(`reportStatus.${this.getTitleToGeneralStatus(this.state.reportStatusInfo.generalInfo.generalStatus)}`)}
            generalStatus={this.state.reportStatusInfo.generalInfo.generalStatus}
            tableContent={results}
          />
          <ReportStatusTable {...this.state.reportStatusInfo.generalInfo} title={this.props.i18n.t('reportStatus.info')} />
        </>}

        <Button className='login-form-button' variant='secondary' onClick={this.onClickReturnToLogin}>
          {this.props.i18n.t('reportStatus.Return')}
        </Button>
      </div>
    );
  }

  private getTitleToGeneralStatus(status: string | number): string {
    if (status === Status.PENDING) {
      return generalStatus.PENDING;
    }

    if (status === Status.PASS) {
      return generalStatus.PASS;
    }

    if (status === Status.NO_PASS) {
      return generalStatus.NO_PASS;
    }

    if (status === Status.CANCELLED) {
      return generalStatus.CANCELLED;
    }
  }

  private onClickReturnToLogin = () => {
    this.props.returnToLogin();
  }

  private async updateReportStatus() {
    const { generalInfo } = this.state.reportStatusInfo;

    const formData = {
      model: generalInfo.tableContent[0].value,
      provider: generalInfo.tableContent[1].value,
      season: generalInfo.tableContent[2].value
    };

    const reportStatus = await reportStatusService.getReportStatus(formData as IForm);
    const reportStatusInfo = createReportStatusInfo(reportStatus);

    this.setState({ reportStatusInfo });
  }

}

export default withTranslation()(ReportStatusInfo);
