import React, { Component, Fragment } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { IValidationErrorProps } from '../../interfaces/props/IValidationErrorProps';

type ValidationErrorPropsType = IValidationErrorProps & WithTranslation;

class ValidationError extends Component<ValidationErrorPropsType> {
  public render() {
    const { errors, errorKey, i18n } = this.props;

    return (
      <Fragment>
        {(errors[errorKey]) ?
          <div>
            <small className='form-text text-danger'>
              <span>{i18n.t(`apiErrors.${errors[errorKey][0]}`)}</span>
            </small>
          </div> : null}
      </Fragment>
    );
  }
}

export default withTranslation()(ValidationError);
