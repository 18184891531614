import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, Sort, Page, Resize, PageSettingsModel } from '@syncfusion/ej2-react-grids';

import { ICertificatesTabProps } from '../../../../interfaces/props/IReportDetailProps';
import { ICertificate } from '../../../../common/model/certificate.model';
import * as pagerUtils from '../../../../utils/pager.utils';
import * as certificateTypeUtils from '../../../../utils/certificateType.utils';
import * as dateHelpers from '../../../../helpers/date.helpers';
import './certificatesTab.scss';
import { getValue } from '@syncfusion/ej2-base';

type CertificatesTabPropsType = ICertificatesTabProps & WithTranslation;

class CertificatesTab extends Component<CertificatesTabPropsType> {
  public pageSettings: PageSettingsModel = {
    pageSize: pagerUtils.getMaxPageSize(),
    pageCount: pagerUtils.getPageCount(),
    pageSizes: pagerUtils.getPageSizes()
  };

  public getIdentificationCodeColumnTemplate(certificate: ICertificate, buttonsAreVisible: boolean): JSX.Element {
    if (buttonsAreVisible) {
      return (
        <span
          className={certificate.fileName ? 'e-link' : ''}
          onClick={certificate.fileName && certificate.reportPublished ? () => this.props.onDownloadCertificateButtonClick(certificate) : undefined}>
          {certificate.identificationCode}
        </span>
      );
    }

    return (
      <span>
        {certificate.identificationCode}
      </span>
    );
  }

  public getCertificateTypeValueLabel(certificate: ICertificate): string {
    return certificateTypeUtils.getLocalizedText(this.props.i18n, certificate.certificateTypeValue);
  }

  public getExpirationDateLabel(certificate: ICertificate): string {
    return certificate.expirationDate && dateHelpers.toShortDate(certificate.expirationDate, true);
  }

  public getRevisionLabel(certificate: ICertificate): number {
    return certificate.revision;
  }

  public getLanguageCode(certificate: ICertificate): string {
    return certificate.languageCode;
  }

  public getIsOriginal(certificate: ICertificate): boolean {
    return certificate.isOriginal;
  }

  public getActionsColumnTemplate(certificate: ICertificate, buttonsAreVisible: boolean): JSX.Element {
    const { i18n, onShareCertificateButtonClick } = this.props;

    if (buttonsAreVisible) {
      return (
        <button
          type='button'
          className='btn btn-sm btn-light'
          title={i18n.t('certificatesList.actions.shareCertificate')}
          onClick={() => { onShareCertificateButtonClick(certificate); }}
        >
          <span className='icon icon-share-square-o' />
          <span className='bwi' >{i18n.t('certificatesList.actions.shareCertificate')}</span>
        </button>
      );
    }

    return null;
  }

  public render() {
    const { certificates, buttonsAreVisible, gridRef, i18n } = this.props;

    return (
      <div className='certificates-grid'>
        <GridComponent
          dataSource={certificates}
          allowSorting={true}
          allowPaging={true}
          allowResizing={true}
          pageSettings={this.pageSettings}
          locale={i18n.language}
          ref={(grid: GridComponent) => gridRef(grid)}
          height='100%'
          width='100%'
          rowDataBound={(args) => {
            if (args.row) {
              if (getValue('isOriginal', args.data)) {
                args.row.classList.add('highlighted-Row');
              }
            }
          }}>
          <ColumnsDirective>
            <ColumnDirective
              headerText={i18n.t('certificate.identificationCode')}
              field='identificationCode'
              width='200'
              template={(certificate: ICertificate) => this.getIdentificationCodeColumnTemplate(certificate, buttonsAreVisible)}              
            />
            <ColumnDirective
              headerText={i18n.t('certificate.certificateType')}
              field='certificateTypeValue'
              width='150'
              valueAccessor={(_field, data) => this.getCertificateTypeValueLabel(data as ICertificate)}              
            />
            <ColumnDirective
              headerText={i18n.t('certificate.expirationDate')}
              field='expirationDate'
              width='150'
              valueAccessor={(_field, data) => this.getExpirationDateLabel(data as ICertificate)}              
            />
            <ColumnDirective
              headerText={i18n.t('certificate.languageCode')}
              field='languageCode'
              width='50'
              valueAccessor={(_field, data) => this.getLanguageCode(data as ICertificate)}
            />
            <ColumnDirective
              headerText={i18n.t('certificate.isOriginal')}
              field='isOriginal'
              width='50'
              valueAccessor={(_field, data) => this.getIsOriginal(data as ICertificate)}
              visible={false}
            />
            {<ColumnDirective
              headerText={this.props.i18n.t('syncfusion.grid.Actions')}
              template={(certificate: ICertificate) => this.getActionsColumnTemplate(certificate, buttonsAreVisible)}
            />}
          </ColumnsDirective>
          <Inject services={[Sort, Page, Resize]} />
        </GridComponent>
      </div>
    );
  }
}

export default withTranslation()(CertificatesTab);
