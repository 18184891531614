// tslint:disable: no-bitwise
import { ICustomFieldSettings, IReportCustomFieldSettingsWithCheckboxes } from '../common/model/customFieldSettings.model';
import { ReportCustomFieldSettingsOption } from '../common/model/enumerations/reportCustomFieldSettingsOption.model';

export const toWithCheckboxes = (customFieldSettings: ICustomFieldSettings): IReportCustomFieldSettingsWithCheckboxes => {
  return {
    ...customFieldSettings,
    listAll: (customFieldSettings.settings & ReportCustomFieldSettingsOption.LIST_ALL) !== 0,
    listInProgress: (customFieldSettings.settings & ReportCustomFieldSettingsOption.LIST_IN_PROGRESS) !== 0,
    listFinalized: (customFieldSettings.settings & ReportCustomFieldSettingsOption.LIST_FINALIZED) !== 0,
    filterAll: (customFieldSettings.settings & ReportCustomFieldSettingsOption.FILTER_ALL) !== 0,
    filterInProgress: (customFieldSettings.settings & ReportCustomFieldSettingsOption.FILTER_IN_PROGRESS) !== 0,
    filterFinalized: (customFieldSettings.settings & ReportCustomFieldSettingsOption.FILTER_FINALIZED) !== 0,
    advancedFilter: (customFieldSettings.settings & ReportCustomFieldSettingsOption.ADVANCED_FILTER) !== 0,
    form: (customFieldSettings.settings & ReportCustomFieldSettingsOption.FORM) !== 0
  };
};

export const fromWithCheckboxes = (customFieldSettingsWithCheckboxes: IReportCustomFieldSettingsWithCheckboxes): ICustomFieldSettings => {
  let settings = 0;
  if (customFieldSettingsWithCheckboxes.listAll) {
    settings += ReportCustomFieldSettingsOption.LIST_ALL;
  }
  if (customFieldSettingsWithCheckboxes.listInProgress) {
    settings += ReportCustomFieldSettingsOption.LIST_IN_PROGRESS;
  }
  if (customFieldSettingsWithCheckboxes.listFinalized) {
    settings += ReportCustomFieldSettingsOption.LIST_FINALIZED;
  }
  if (customFieldSettingsWithCheckboxes.filterAll) {
    settings += ReportCustomFieldSettingsOption.FILTER_ALL;
  }
  if (customFieldSettingsWithCheckboxes.filterInProgress) {
    settings += ReportCustomFieldSettingsOption.FILTER_IN_PROGRESS;
  }
  if (customFieldSettingsWithCheckboxes.filterFinalized) {
    settings += ReportCustomFieldSettingsOption.FILTER_FINALIZED;
  }
  if (customFieldSettingsWithCheckboxes.advancedFilter) {
    settings += ReportCustomFieldSettingsOption.ADVANCED_FILTER;
  }
  if (customFieldSettingsWithCheckboxes.form) {
    settings += ReportCustomFieldSettingsOption.FORM;
  }

  return {
    id: customFieldSettingsWithCheckboxes.id,
    customFieldCode: customFieldSettingsWithCheckboxes.customFieldCode,
    settings,
    sourceType: customFieldSettingsWithCheckboxes.sourceType,
    companyId: customFieldSettingsWithCheckboxes.companyId
  };
};
