import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { IForm } from '../interfaces/states/ILoginFormState';
import IReportStatus from '../interfaces/IReportStatus';
import { AxiosInstance } from 'axios';
import { settings } from './settings.service';
import { Initialization } from '@microsoft/applicationinsights-web/dist-esm/Initialization';
import { IdToDownload } from '../interfaces/states/IReportListState';
import { TELEMETRY_ACTIONS } from '../common/constants';

interface IPageInfoToTrack {
  userName?: string;
  email?: string;
  pathName: string;
}

class TelemetryService {
  public appInsights: Initialization = null;

  public async init() {
    if (!settings.instrumentationKey) {
      throw new Error('Instrumentation key not provided');
    }

    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: settings.instrumentationKey
      }
    });

    this.appInsights.loadAppInsights();
  }

  public async trackPage(pageInfoToTrack: IPageInfoToTrack): Promise<any> {
    return this.appInsights.trackEvent({
      name: TELEMETRY_ACTIONS.PAGE,
      properties: { userName: pageInfoToTrack.userName, email: pageInfoToTrack.email, pathName: pageInfoToTrack.pathName }
    });
  }

  public async trackReportStatus(formData: IForm, response: IReportStatus | AxiosInstance): Promise<any> {
    return this.appInsights.trackEvent({
      name: TELEMETRY_ACTIONS.REPORTSTATUS,
      properties: { body: formData, result: response }
    });
  }

  public async trackDownload(userName: string, idsToDownload: IdToDownload[] | IdToDownload): Promise<any> {
    return this.appInsights.trackEvent({
      name: TELEMETRY_ACTIONS.DOWNLOAD,
      properties: { username: userName, idsToDownload }
    });
  }
}

export const telemetryService = new TelemetryService();
