import React, { Component, ComponentType } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { compose } from 'redux';
import { DataManager, Query } from '@syncfusion/ej2-data';
import { SortDirection } from '@syncfusion/ej2-grids';
import { Tabs, Tab } from 'react-bootstrap';

import { RootState } from '../../store';
import { IOfficesListContainerStateProps, IOfficesListContainerDispatchProps } from '../../interfaces/props/IOfficesListProps';
import * as officesListStore from '../../store/modules/officesList.store';
import * as listGridStore from '../../store/modules/listGrid.store';
import { URL } from '../../common/constants';
import { IMyRule } from '../../common/model/myRule.model';
import { FilterMode } from '../../common/model/enumerations/filterMode.model';
import { RuleCondition } from '../../common/model/enumerations/ruleCondition.model';
import { RuleOperator } from '../../common/model/enumerations/ruleOperator.model';
import { dataManagerService, oDataService, queryBuilderService } from '../../services';
import OfficesListFilter from './officesListFilter/officesListFilter';
import OfficesGrid from './officesGrid/officesGrid';
import { IOffice } from '../../common/model/office.model';

type OfficesListContainerPropsType = PropsFromRedux & RouteComponentProps & WithTranslation;

class OfficesListContainer extends Component<OfficesListContainerPropsType> {
  public officesDataManager: DataManager = null;
  public officesQuery: Query = new Query()
    .select([
      'id', 'active', 'code', 'description', 'emails'
    ]);

  public constructor(props: OfficesListContainerPropsType) {
    super(props);

    this.officesDataManager = dataManagerService.buildDataManager(URL.ODATA.OFFICES_FILTERS);

    this.props.reset();
  }

  public componentDidMount() {
    this.fetchOffices();
  }

  public componentWillUnmount() {
    this.props.reset();
  }

  public onFilterButtonClick() {
    this.props.setCurrentPage(officesListStore.Type.SET_CURRENT_PAGE, 1);
    this.fetchOffices();
  }

  public onCurrentPageChange(currentPage: number) {
    this.props.setCurrentPage(officesListStore.Type.SET_CURRENT_PAGE, currentPage);
    this.fetchOffices();
  }

  public onPageSizeChange(pageSize: number) {
    this.props.setPageSize(officesListStore.Type.SET_PAGE_SIZE, pageSize);
    this.fetchOffices();
  }

  public onSortingChange(field: string, direction: SortDirection) {
    this.props.setSorting(officesListStore.Type.SET_SORTING, field, direction);
    this.fetchOffices();
  }

  public saveOffice(office: IOffice) {
    const { isFetching, saveOffice } = this.props;

    if (!isFetching) {
      saveOffice(office);
    }
  }

  public render() {
    const {
      offices,
      filter,
      isFetching,
      searchDate,
      currentPage,
      pageSize,
      totalCount,
      sortField,
      sortDirection,
      setFilterActive,
      setFilterCode,
      setFilterDescription,
      setFilterEmails,
      i18n
    } = this.props;

    return (
      <div>
        <div className='row'>
          <div className='col'>
            <h2>{i18n.t('officesList.title')}</h2>
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <div className='mb-3'>
              <Tabs id='officesListTabs' className='mb-3'>
                <Tab eventKey={FilterMode.SIMPLE} title={i18n.t('enums.filterMode.SIMPLE')}>
                  <OfficesListFilter
                    filter={filter}
                    isFetching={isFetching}
                    onActiveChange={setFilterActive}
                    onCodeChange={setFilterCode}
                    onDescriptionChange={setFilterDescription}
                    onEmailsChange={setFilterEmails}
                    onFilterButtonClick={() => this.onFilterButtonClick()}
                  />
                </Tab>
              </Tabs>
            </div>
            <OfficesGrid
              offices={offices}
              isFetching={isFetching}
              searchDate={searchDate}
              currentPage={currentPage}
              pageSize={pageSize}
              totalCount={totalCount}
              sortField={sortField}
              sortDirection={sortDirection}
              onCurrentPageChange={(cp: number) => this.onCurrentPageChange(cp)}
              onPageSizeChange={(ps: number) => this.onPageSizeChange(ps)}
              onSortingChange={(field: string, direction: SortDirection) => this.onSortingChange(field, direction)}
              saveOffice={(office: IOffice) => this.saveOffice(office)}
            />
          </div>
        </div>
      </div>
    );
  }

  private fetchOffices() {
    const { isFetching, fetchOffices } = this.props;

    if (!isFetching) {
      fetchOffices(oDataService, this.officesDataManager, this.buildQuery());
    }
  }

  private buildQuery(): Query {
    const { filter } = this.props;

    const myRule: IMyRule = {
      condition: RuleCondition.AND,
      myRules: []
    };
    if (filter.code) {
      myRule.myRules.push({
        field: 'code',
        operator: RuleOperator.CONTAINS,
        value: filter.code,
        ignoreCase: true
      });
    }
    if (filter.description) {
      myRule.myRules.push({
        field: 'description',
        operator: RuleOperator.CONTAINS,
        value: filter.description,
        ignoreCase: true
      });
    }
    if (filter.active != null) {
      myRule.myRules.push({
        field: 'active',
        operator: RuleOperator.EQUAL,
        value: filter.active
      });
    }
    if (filter.emails) {
      myRule.myRules.push({
        field: 'emails',
        operator: RuleOperator.CONTAINS,
        value: filter.emails
      });
    }

    return queryBuilderService.buildQuery(this.officesDataManager, myRule, this.officesQuery.clone());
  }
}

const mapStateToProps = (state: RootState): IOfficesListContainerStateProps => ({
  offices: state.officesListStore.offices,
  filter: state.officesListStore.filter,
  isFetching: state.officesListStore.grid.isFetching,
  searchDate: state.officesListStore.grid.searchDate,
  currentPage: state.officesListStore.grid.currentPage,
  pageSize: state.officesListStore.grid.pageSize,
  totalCount: state.officesListStore.grid.totalCount,
  sortField: state.officesListStore.grid.sortField,
  sortDirection: state.officesListStore.grid.sortDirection
});

const mapDispatchToProps: IOfficesListContainerDispatchProps = {
  reset: officesListStore.reset,
  resetHard: officesListStore.resetHard,
  setFilterActive: officesListStore.setFilterActive,
  setFilterCode: officesListStore.setFilterCode,
  setFilterDescription: officesListStore.setFilterDescription,
  setFilterEmails: officesListStore.setFilterEmails,
  setCurrentPage: listGridStore.setCurrentPage,
  setPageSize: listGridStore.setPageSize,
  setSorting: listGridStore.setSorting,
  fetchOffices: officesListStore.fetchOffices,
  saveOffice: officesListStore.saveOffice
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default compose(
  withRouter,
  withTranslation(),
  connector
)(OfficesListContainer) as ComponentType;
