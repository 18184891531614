import { Action, Reducer, ActionCreator, combineReducers } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { History, UnregisterCallback } from 'history';

import { RootState } from '..';
import { IClientDetailState } from '../../interfaces/states/IClientDetailState';
import { ICompany } from '../../common/model/company.model';
import { ICompanyCertification } from '../../common/model/companyCertification.model';
import { IProduct } from '../../common/model/product.model';
import { ICompanySupplier } from '../../common/model/companySupplier.model';
import { IErrors } from '../../common/model/errors.model';
import { ProductType } from '../../common/model/enumerations/productType.model';
import { ReportCustomFieldSettingType } from '../../common/model/enumerations/reportCustomFieldSettingType.model';
import { ICompaniesService, IProductsService, ICompanySuppliersService } from '../../services';
import { IUser } from '../../common/model/user.model';

enum Type {
  RESET = '@@clientDetail/RESET',
  MAKE_READY_FOR_RENDERING = '@@clientDetail/MAKE_READY_FOR_RENDERING',
  SET_CLIENT = '@@clientDetail/SET_CLIENT',
  SET_CLIENT_NAME = '@@clientDetail/SET_CLIENT_NAME',
  SET_CLIENT_ADDRESS = '@@clientDetail/SET_CLIENT_ADDRESS',
  SET_CLIENT_CITY = '@@clientDetail/SET_CLIENT_CITY',
  SET_CLIENT_PROVINCE = '@@clientDetail/SET_CLIENT_PROVINCE',
  SET_CLIENT_POSTAL_CODE = '@@clientDetail/SET_CLIENT_POSTAL_CODE',
  SET_CLIENT_COUNTRY = '@@clientDetail/SET_CLIENT_COUNTRY',
  SET_CLIENT_REPORT_CUSTOM_FIELD_SETTING_TYPE = '@@clientDetail/SET_CLIENT_REPORT_CUSTOM_FIELD_SETTING_TYPE',
  SET_CLIENT_REQUEST_CUSTOM_FIELD_SETTING_TYPE = '@@clientDetail/SET_CLIENT_REQUEST_CUSTOM_FIELD_SETTING_TYPE',
  SET_CLIENT_HAS_REPORT_VERSIONS = '@@clientDetail/SET_CLIENT_HAS_REPORT_VERSIONS',
  SET_CLIENT_LOGO = '@@clientDetail/SET_CLIENT_LOGO',
  SET_CLIENT_INITIAL = '@@clientDetail/SET_CLIENT_INITIAL',
  SET_IS_VIEW = '@@clientDetail/SET_IS_VIEW',
  SET_COMPANY_CERTIFICATIONS = '@@clientDetail/SET_COMPANY_CERTIFICATIONS',
  SET_IS_FETCHING_COMPANY_CERTIFICATIONS = '@@clientDetail/SET_IS_FETCHING_COMPANY_CERTIFICATIONS',
  SET_SALE_PRODUCTS = '@@clientDetail/SET_SALE_PRODUCTS',
  SET_IS_FETCHING_SALE_PRODUCTS = '@@clientDetail/SET_IS_FETCHING_SALE_PRODUCTS',
  SET_PURCHASE_PRODUCTS = '@@clientDetail/SET_PURCHASE_PRODUCTS',
  SET_IS_FETCHING_PURCHASE_PRODUCTS = '@@clientDetail/SET_IS_FETCHING_PURCHASE_PRODUCTS',
  SET_IS_EDITING_PRODUCT = '@@clientDetail/SET_IS_EDITING_PRODUCT',
  SET_COMPANY_SUPPLIERS = '@@clientDetail/SET_COMPANY_SUPPLIERS',
  SET_IS_FETCHING_COMPANY_SUPPLIERS = '@@clientDetail/SET_IS_FETCHING_COMPANY_SUPPLIERS',
  SET_IS_EDITING_COMPANY_SUPPLIER = '@@clientDetail/SET_IS_EDITING_COMPANY_SUPPLIER',
  SET_IS_SAVING = '@@myProfile/SET_IS_SAVING',
  SET_ERRORS = '@@clientDetail/SET_ERRORS',

  SET_COMPANY_USERS = '@@clientDetail/SET_COMPANY_USERS',
  SET_IS_FETCHING_COMPANY_USERS = '@@clientDetail/SET_IS_FETCHING_COMPANY_USERS'
}

const initialState: IClientDetailState = {
  isReadyForRendering: false,
  client: null,
  clientInitial: null,
  isView: null, // must be !== null when isReadyForRendering === true
  hasReportCustomFieldSettingTypeGeneralAtMountTime: false,
  companyCertifications: [],
  isFetchingCompanyCertifications: false,
  saleProducts: [],
  isFetchingSaleProducts: false,
  purchaseProducts: [],
  isFetchingPurchaseProducts: false,
  isEditingProduct: false,
  companySuppliers: [],
  isFetchingCompanySuppliers: false,
  isEditingCompanySupplier: false,
  isSaving: false,
  errors: {},

  companyUsers: [],
  isFetchingCompanyUsers: false
};

// Actions

export type Actions = ResetAction | MakeReadyForRenderingAction | SetClientAction | SetClientNameAction | SetClientAddressAction | SetClientCityAction | SetClientProvinceAction
  | SetClientPostalCodeAction | SetClientCountryAction | SetClientReportCustomFieldSettingTypeAction | SetClientRequestCustomFieldSettingTypeAction | SetClientHasReportVersionsAction
  | SetClientLogoAction | SetClientInitialAction | SetIsViewAction | SetCompanyCertificationsAction | SetIsFetchingCompanyCertificationsAction | SetSaleProductsAction
  | SetIsFetchingSaleProductsAction | SetPurchaseProductsAction | SetIsFetchingPurchaseProductsAction | SetIsEditingProductAction | SetCompanySuppliersAction | SetIsFetchingCompanySuppliersAction
  | SetIsEditingCompanySupplierAction | SetIsSavingAction | SetErrorsAction | SetCompanyUsersAction | SetIsFetchingCompanyUsersAction;

export type ResetAction = Action<Type.RESET>

export interface MakeReadyForRenderingAction extends Action<Type.MAKE_READY_FOR_RENDERING> {
  isView: boolean;
}

export interface SetClientAction extends Action<Type.SET_CLIENT> {
  client: ICompany;
}

export interface SetClientNameAction extends Action<Type.SET_CLIENT_NAME> {
  name: string;
}

export interface SetClientAddressAction extends Action<Type.SET_CLIENT_ADDRESS> {
  address: string;
}

export interface SetClientCityAction extends Action<Type.SET_CLIENT_CITY> {
  city: string;
}

export interface SetClientProvinceAction extends Action<Type.SET_CLIENT_PROVINCE> {
  province: string;
}

export interface SetClientPostalCodeAction extends Action<Type.SET_CLIENT_POSTAL_CODE> {
  postalCode: string;
}

export interface SetClientCountryAction extends Action<Type.SET_CLIENT_COUNTRY> {
  country: string;
}

export interface SetClientReportCustomFieldSettingTypeAction extends Action<Type.SET_CLIENT_REPORT_CUSTOM_FIELD_SETTING_TYPE> {
  reportCustomFieldSettingType: number;
}

export interface SetClientRequestCustomFieldSettingTypeAction extends Action<Type.SET_CLIENT_REQUEST_CUSTOM_FIELD_SETTING_TYPE> {
  requestCustomFieldSettingType: number;
}

export interface SetClientHasReportVersionsAction extends Action<Type.SET_CLIENT_HAS_REPORT_VERSIONS> {
  hasReportVersions: boolean;
}

export interface SetClientLogoAction extends Action<Type.SET_CLIENT_LOGO> {
  logo: string;
}

export interface SetClientInitialAction extends Action<Type.SET_CLIENT_INITIAL> {
  clientInitial: ICompany;
}

export interface SetIsViewAction extends Action<Type.SET_IS_VIEW> {
  isView: boolean;
}

export interface SetCompanyCertificationsAction extends Action<Type.SET_COMPANY_CERTIFICATIONS> {
  companyCertifications: ICompanyCertification[];
}

export interface SetIsFetchingCompanyCertificationsAction extends Action<Type.SET_IS_FETCHING_COMPANY_CERTIFICATIONS> {
  isFetchingCompanyCertifications: boolean;
}

export interface SetCompanyUsersAction extends Action<Type.SET_COMPANY_USERS> {
  companyUsers: IUser[];
}

export interface SetIsFetchingCompanyUsersAction extends Action<Type.SET_IS_FETCHING_COMPANY_USERS> {
  isFetchingCompanyUsers: boolean;
}

export interface SetSaleProductsAction extends Action<Type.SET_SALE_PRODUCTS> {
  saleProducts: IProduct[];
}

export interface SetIsFetchingSaleProductsAction extends Action<Type.SET_IS_FETCHING_SALE_PRODUCTS> {
  isFetchingSaleProducts: boolean;
}

export interface SetPurchaseProductsAction extends Action<Type.SET_PURCHASE_PRODUCTS> {
  purchaseProducts: IProduct[];
}

export interface SetIsFetchingPurchaseProductsAction extends Action<Type.SET_IS_FETCHING_PURCHASE_PRODUCTS> {
  isFetchingPurchaseProducts: boolean;
}

export interface SetIsEditingProductAction extends Action<Type.SET_IS_EDITING_PRODUCT> {
  isEditingProduct: boolean;
}

export interface SetCompanySuppliersAction extends Action<Type.SET_COMPANY_SUPPLIERS> {
  companySuppliers: ICompanySupplier[];
}

export interface SetIsFetchingCompanySuppliersAction extends Action<Type.SET_IS_FETCHING_COMPANY_SUPPLIERS> {
  isFetchingCompanySuppliers: boolean;
}

export interface SetIsEditingCompanySupplierAction extends Action<Type.SET_IS_EDITING_COMPANY_SUPPLIER> {
  isEditingCompanySupplier: boolean;
}

export interface SetIsSavingAction extends Action<Type.SET_IS_SAVING> {
  isSaving: boolean;
}

export interface SetErrorsAction extends Action<Type.SET_ERRORS> {
  errors: IErrors;
}

export const reset: ActionCreator<ResetAction> = () => ({
  type: Type.RESET
});

export const makeReadyForRendering: ActionCreator<MakeReadyForRenderingAction> = (isViewLocal: boolean) => ({
  type: Type.MAKE_READY_FOR_RENDERING,
  isView: isViewLocal
});

export const setClient: ActionCreator<SetClientAction> = (clientLocal: ICompany) => ({
  type: Type.SET_CLIENT,
  client: clientLocal
});

export const setClientName: ActionCreator<SetClientNameAction> = (name: string) => ({
  type: Type.SET_CLIENT_NAME,
  name
});

export const setClientAddress: ActionCreator<SetClientAddressAction> = (address: string) => ({
  type: Type.SET_CLIENT_ADDRESS,
  address
});

export const setClientCity: ActionCreator<SetClientCityAction> = (city: string) => ({
  type: Type.SET_CLIENT_CITY,
  city
});

export const setClientProvince: ActionCreator<SetClientProvinceAction> = (province: string) => ({
  type: Type.SET_CLIENT_PROVINCE,
  province
});

export const setClientPostalCode: ActionCreator<SetClientPostalCodeAction> = (postalCode: string) => ({
  type: Type.SET_CLIENT_POSTAL_CODE,
  postalCode
});

export const setClientCountry: ActionCreator<SetClientCountryAction> = (country: string) => ({
  type: Type.SET_CLIENT_COUNTRY,
  country
});

export const setClientReportCustomFieldSettingType: ActionCreator<SetClientReportCustomFieldSettingTypeAction> = (reportCustomFieldSettingType: number) => ({
  type: Type.SET_CLIENT_REPORT_CUSTOM_FIELD_SETTING_TYPE,
  reportCustomFieldSettingType
});

export const setClientRequestCustomFieldSettingType: ActionCreator<SetClientRequestCustomFieldSettingTypeAction> = (requestCustomFieldSettingType: number) => ({
  type: Type.SET_CLIENT_REQUEST_CUSTOM_FIELD_SETTING_TYPE,
  requestCustomFieldSettingType
});

export const setClientHasReportVersions: ActionCreator<SetClientHasReportVersionsAction> = (hasReportVersions: boolean) => ({
  type: Type.SET_CLIENT_HAS_REPORT_VERSIONS,
  hasReportVersions
});

export const setClientLogo: ActionCreator<SetClientLogoAction> = (logo: string) => ({
  type: Type.SET_CLIENT_LOGO,
  logo
});

export const setClientInitial: ActionCreator<SetClientInitialAction> = (clientInitialLocal: ICompany) => ({
  type: Type.SET_CLIENT_INITIAL,
  clientInitial: clientInitialLocal
});

export const setIsView: ActionCreator<SetIsViewAction> = (isViewLocal: boolean) => ({
  type: Type.SET_IS_VIEW,
  isView: isViewLocal
});

export const setCompanyCertifications: ActionCreator<SetCompanyCertificationsAction> = (companyCertificationsLocal: ICompanyCertification[]) => ({
  type: Type.SET_COMPANY_CERTIFICATIONS,
  companyCertifications: companyCertificationsLocal
});

export const setIsFetchingCompanyCertifications: ActionCreator<SetIsFetchingCompanyCertificationsAction> = (isFetchingCompanyCertificationsLocal: boolean) => ({
  type: Type.SET_IS_FETCHING_COMPANY_CERTIFICATIONS,
  isFetchingCompanyCertifications: isFetchingCompanyCertificationsLocal
});

export const setCompanyUsers: ActionCreator<SetCompanyUsersAction> = (companyUsersLocal: IUser[]) => ({
  type: Type.SET_COMPANY_USERS,
  companyUsers: companyUsersLocal
});

export const setIsFetchingCompanyUsers: ActionCreator<SetIsFetchingCompanyUsersAction> = (isFetchingCompanyUsersLocal: boolean) => ({
  type: Type.SET_IS_FETCHING_COMPANY_USERS,
  isFetchingCompanyUsers: isFetchingCompanyUsersLocal
});

export const setSaleProducts: ActionCreator<SetSaleProductsAction> = (saleProductsLocal: IProduct[]) => ({
  type: Type.SET_SALE_PRODUCTS,
  saleProducts: saleProductsLocal
});

export const setIsFetchingSaleProducts: ActionCreator<SetIsFetchingSaleProductsAction> = (isFetchingSaleProductsLocal: boolean) => ({
  type: Type.SET_IS_FETCHING_SALE_PRODUCTS,
  isFetchingSaleProducts: isFetchingSaleProductsLocal
});

export const setPurchaseProducts: ActionCreator<SetPurchaseProductsAction> = (purchaseProductsLocal: IProduct[]) => ({
  type: Type.SET_PURCHASE_PRODUCTS,
  purchaseProducts: purchaseProductsLocal
});

export const setIsFetchingPurchaseProducts: ActionCreator<SetIsFetchingPurchaseProductsAction> = (isFetchingPurchaseProductsLocal: boolean) => ({
  type: Type.SET_IS_FETCHING_PURCHASE_PRODUCTS,
  isFetchingPurchaseProducts: isFetchingPurchaseProductsLocal
});

export const setIsEditingProduct: ActionCreator<SetIsEditingProductAction> = (isEditingProductLocal: boolean) => ({
  type: Type.SET_IS_EDITING_PRODUCT,
  isEditingProduct: isEditingProductLocal
});

export const setCompanySuppliers: ActionCreator<SetCompanySuppliersAction> = (companySuppliersLocal: ICompanySupplier[]) => ({
  type: Type.SET_COMPANY_SUPPLIERS,
  companySuppliers: companySuppliersLocal
});

export const setIsFetchingCompanySuppliers: ActionCreator<SetIsFetchingCompanySuppliersAction> = (isFetchingCompanySuppliersLocal: boolean) => ({
  type: Type.SET_IS_FETCHING_COMPANY_SUPPLIERS,
  isFetchingCompanySuppliers: isFetchingCompanySuppliersLocal
});

export const setIsEditingCompanySupplier: ActionCreator<SetIsEditingCompanySupplierAction> = (isEditingCompanySupplierLocal: boolean) => ({
  type: Type.SET_IS_EDITING_COMPANY_SUPPLIER,
  isEditingCompanySupplier: isEditingCompanySupplierLocal
});

export const setIsSaving: ActionCreator<SetIsSavingAction> = (isSavingLocal: boolean) => ({
  type: Type.SET_IS_SAVING,
  isSaving: isSavingLocal
});

export const setErrors: ActionCreator<SetErrorsAction> = (errorsLocal: IErrors) => ({
  type: Type.SET_ERRORS,
  errors: errorsLocal
});

export const fetchClient: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (id: string, companiesService: ICompaniesService) => (
  async (dispatch) => {
    try {
      const clientLocal = await companiesService.fetch(id);
      dispatch(setClient(clientLocal));
      dispatch(setClientInitial(clientLocal));
    } catch (error) {
      // TODO: manejar error
    }
  }
);

export const resetClient: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = () => (
  async (dispatch, getState) => {
    dispatch(setClient(getState().clientDetailStore.clientInitial));
  }
);

export const updateClient: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (
  clientLocal: ICompany,
  companiesService: ICompaniesService,
  unregisterCallback: UnregisterCallback,
  history: History
) => (
    async (dispatch) => {
      try {
        dispatch(setErrors(initialState.errors));

        dispatch(setIsSaving(true));

        const updatedClient = await companiesService.update(clientLocal);
        dispatch(setClient(updatedClient));
        dispatch(setClientInitial(updatedClient));

        if (unregisterCallback) {
          unregisterCallback();
        }
        history.push(`/client/${clientLocal.id}/view`);
      } catch (error) {
        dispatch(setErrors(error as IErrors));
      } finally {
        dispatch(setIsSaving(false));
      }
    }
  );

export const fetchCompanyCertifications: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (companyId: string, companiesService: ICompaniesService) => (
  async (dispatch) => {
    dispatch(setIsFetchingCompanyCertifications(true));

    const companyCertificationsLocal = await companiesService.fetchCompanyCertifications(companyId);
    dispatch(setCompanyCertifications(companyCertificationsLocal));
    dispatch(setIsFetchingCompanyCertifications(false));
  }
);

export const fetchCompanyUsers: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (companyId: string, companiesService: ICompaniesService) => (
  async (dispatch) => {
    dispatch(setIsFetchingCompanyUsers(true));

    const companyUsersLocal = await companiesService.fetchCompanyUsers(companyId);
    dispatch(setCompanyUsers(companyUsersLocal));
    dispatch(setIsFetchingCompanyUsers(false));
  }
);

export const fetchActiveCompanyUsers: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (companyId: string, companiesService: ICompaniesService) => (
  async (dispatch) => {
    dispatch(setIsFetchingCompanyUsers(true));

    const companyUsersLocal = await companiesService.fetchActiveCompanyUsers(companyId);
    dispatch(setCompanyUsers(companyUsersLocal));
    dispatch(setIsFetchingCompanyUsers(false));
  }
);

export const fetchSaleProducts: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (companyId: string, companiesService: ICompaniesService) => (
  async (dispatch) => {
    dispatch(setIsFetchingSaleProducts(true));

    const saleProductsLocal = await companiesService.fetchSaleProducts(companyId);
    dispatch(setSaleProducts(saleProductsLocal));
    dispatch(setIsFetchingSaleProducts(false));
  }
);

export const fetchPurchaseProducts: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (companyId: string, companiesService: ICompaniesService) => (
  async (dispatch) => {
    dispatch(setIsFetchingPurchaseProducts(true));

    const purchaseProductsLocal = await companiesService.fetchPurchaseProducts(companyId);
    dispatch(setPurchaseProducts(purchaseProductsLocal));
    dispatch(setIsFetchingPurchaseProducts(false));
  }
);

export const saveProduct: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (product: IProduct, productsService: IProductsService, companiesService: ICompaniesService) => (
  async (dispatch) => {
    try {
      if (product.type === ProductType.SALE) {
        dispatch(setIsFetchingSaleProducts(true));
      }
      if (product.type === ProductType.PURCHASE) {
        dispatch(setIsFetchingPurchaseProducts(true));
      }

      dispatch(setErrors(initialState.errors));

      product.id ? await productsService.update(product) : await productsService.create(product);
    } catch (error) {
      dispatch(setErrors(error as IErrors));
    } finally {
      if (product.type === ProductType.SALE) {
        const saleProductsLocal = await companiesService.fetchSaleProducts(product.companyId);
        dispatch(setSaleProducts(saleProductsLocal));
      }
      if (product.type === ProductType.PURCHASE) {
        const purchaseProductsLocal = await companiesService.fetchPurchaseProducts(product.companyId);
        dispatch(setPurchaseProducts(purchaseProductsLocal));
      }

      dispatch(setIsEditingProduct(false));

      if (product.type === ProductType.SALE) {
        dispatch(setIsFetchingSaleProducts(false));
      }
      if (product.type === ProductType.PURCHASE) {
        dispatch(setIsFetchingPurchaseProducts(false));
      }
    }
  }
);

export const fetchCompanySuppliers: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (companyId: string, companiesService: ICompaniesService) => (
  async (dispatch) => {
    dispatch(setIsFetchingCompanySuppliers(true));

    const companySuppliersLocal = await companiesService.fetchCompanySuppliers(companyId);
    dispatch(setCompanySuppliers(companySuppliersLocal));
    dispatch(setIsFetchingCompanySuppliers(false));
  }
);

export const saveCompanySupplier: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (
  companySupplier: ICompanySupplier,
  companySuppliersService: ICompanySuppliersService,
  companiesService: ICompaniesService
) => (
    async (dispatch) => {
      try {
        dispatch(setErrors(initialState.errors));

        dispatch(setIsFetchingCompanySuppliers(true));

        companySupplier.id ? await companySuppliersService.update(companySupplier) : await companySuppliersService.create(companySupplier);
      } catch (error) {
        dispatch(setErrors(error as IErrors));
      } finally {
        const companySuppliersLocal = await companiesService.fetchCompanySuppliers(companySupplier.companyId);
        dispatch(setCompanySuppliers(companySuppliersLocal));

        dispatch(setIsEditingCompanySupplier(false));

        dispatch(setIsFetchingCompanySuppliers(false));
      }
    }
  );

// Reducers

const isReadyForRendering: Reducer<boolean> = (state = initialState.isReadyForRendering, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.isReadyForRendering;
    case Type.MAKE_READY_FOR_RENDERING:
      return true;
    default:
      return state;
  }
};

const client: Reducer<ICompany> = (state = initialState.client, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.client;
    case Type.SET_CLIENT:
      return (action as SetClientAction).client;
    case Type.SET_CLIENT_NAME:
      return { ...state, name: (action as SetClientNameAction).name };
    case Type.SET_CLIENT_ADDRESS:
      return { ...state, address: (action as SetClientAddressAction).address };
    case Type.SET_CLIENT_CITY:
      return { ...state, city: (action as SetClientCityAction).city };
    case Type.SET_CLIENT_PROVINCE:
      return { ...state, province: (action as SetClientProvinceAction).province };
    case Type.SET_CLIENT_POSTAL_CODE:
      return { ...state, postalCode: (action as SetClientPostalCodeAction).postalCode };
    case Type.SET_CLIENT_COUNTRY:
      return { ...state, country: (action as SetClientCountryAction).country };
    case Type.SET_CLIENT_REPORT_CUSTOM_FIELD_SETTING_TYPE:
      return { ...state, reportCustomFieldSettingType: (action as SetClientReportCustomFieldSettingTypeAction).reportCustomFieldSettingType };
    case Type.SET_CLIENT_REQUEST_CUSTOM_FIELD_SETTING_TYPE:
      return { ...state, requestCustomFieldSettingType: (action as SetClientRequestCustomFieldSettingTypeAction).requestCustomFieldSettingType };
    case Type.SET_CLIENT_HAS_REPORT_VERSIONS:
      return { ...state, hasReportVersions: (action as SetClientHasReportVersionsAction).hasReportVersions };
    case Type.SET_CLIENT_LOGO:
      return { ...state, logo: (action as SetClientLogoAction).logo };
    default:
      return state;
  }
};

const clientInitial: Reducer<ICompany> = (state = initialState.clientInitial, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.clientInitial;
    case Type.SET_CLIENT_INITIAL:
      return (action as SetClientInitialAction).clientInitial;
    default:
      return state;
  }
};

const isView: Reducer<boolean> = (state = initialState.isView, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.isView;
    case Type.MAKE_READY_FOR_RENDERING:
      return (action as MakeReadyForRenderingAction).isView;
    case Type.SET_IS_VIEW:
      return (action as SetIsViewAction).isView;
    default:
      return state;
  }
};

const hasReportCustomFieldSettingTypeGeneralAtMountTime: Reducer<boolean> = (state = initialState.hasReportCustomFieldSettingTypeGeneralAtMountTime, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.hasReportCustomFieldSettingTypeGeneralAtMountTime;
    case Type.SET_CLIENT:
      return (action as SetClientAction).client.reportCustomFieldSettingType === ReportCustomFieldSettingType.GENERAL;
    default:
      return state;
  }
};

const companyCertifications: Reducer<ICompanyCertification[]> = (state = initialState.companyCertifications, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.companyCertifications;
    case Type.SET_COMPANY_CERTIFICATIONS:
      return (action as SetCompanyCertificationsAction).companyCertifications;
    default:
      return state;
  }
};

const isFetchingCompanyCertifications: Reducer<boolean> = (state = initialState.isFetchingCompanyCertifications, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.isFetchingCompanyCertifications;
    case Type.SET_IS_FETCHING_COMPANY_CERTIFICATIONS:
      return (action as SetIsFetchingCompanyCertificationsAction).isFetchingCompanyCertifications;
    default:
      return state;
  }
};

const companyUsers: Reducer<IUser[]> = (state = initialState.companyUsers, action) => {
  switch (action.type) {
  case Type.RESET:
    return initialState.companyUsers;
  case Type.SET_COMPANY_USERS:
    return (action as SetCompanyUsersAction).companyUsers;
  default:
    return state;
  }
};

const isFetchingCompanyUsers: Reducer<boolean> = (state = initialState.isFetchingCompanyUsers, action) => {
  switch (action.type) {
  case Type.RESET:
    return initialState.isFetchingCompanyUsers;
  case Type.SET_IS_FETCHING_COMPANY_USERS:
    return (action as SetIsFetchingCompanyUsersAction).isFetchingCompanyUsers;
  default:
    return state;
  }
};

const saleProducts: Reducer<IProduct[]> = (state = initialState.saleProducts, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.saleProducts;
    case Type.SET_SALE_PRODUCTS:
      return (action as SetSaleProductsAction).saleProducts;
    default:
      return state;
  }
};

const isFetchingSaleProducts: Reducer<boolean> = (state = initialState.isFetchingSaleProducts, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.isFetchingSaleProducts;
    case Type.SET_IS_FETCHING_SALE_PRODUCTS:
      return (action as SetIsFetchingSaleProductsAction).isFetchingSaleProducts;
    default:
      return state;
  }
};

const purchaseProducts: Reducer<IProduct[]> = (state = initialState.purchaseProducts, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.purchaseProducts;
    case Type.SET_PURCHASE_PRODUCTS:
      return (action as SetPurchaseProductsAction).purchaseProducts.map((purchaseProduct) => ({ ...purchaseProduct, recycledPercentage: purchaseProduct.recycledPercentage * 100 }));
    default:
      return state;
  }
};

const isFetchingPurchaseProducts: Reducer<boolean> = (state = initialState.isFetchingPurchaseProducts, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.isFetchingPurchaseProducts;
    case Type.SET_IS_FETCHING_PURCHASE_PRODUCTS:
      return (action as SetIsFetchingPurchaseProductsAction).isFetchingPurchaseProducts;
    default:
      return state;
  }
};

const isEditingProduct: Reducer<boolean> = (state = initialState.isEditingProduct, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.isEditingProduct;
    case Type.SET_IS_EDITING_PRODUCT:
      return (action as SetIsEditingProductAction).isEditingProduct;
    default:
      return state;
  }
};

const companySuppliers: Reducer<ICompanySupplier[]> = (state = initialState.companySuppliers, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.companySuppliers;
    case Type.SET_COMPANY_SUPPLIERS:
      return (action as SetCompanySuppliersAction).companySuppliers;
    default:
      return state;
  }
};

const isFetchingCompanySuppliers: Reducer<boolean> = (state = initialState.isFetchingCompanySuppliers, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.isFetchingCompanySuppliers;
    case Type.SET_IS_FETCHING_COMPANY_SUPPLIERS:
      return (action as SetIsFetchingCompanySuppliersAction).isFetchingCompanySuppliers;
    default:
      return state;
  }
};

const isEditingCompanySupplier: Reducer<boolean> = (state = initialState.isEditingCompanySupplier, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.isEditingCompanySupplier;
    case Type.SET_IS_EDITING_COMPANY_SUPPLIER:
      return (action as SetIsEditingCompanySupplierAction).isEditingCompanySupplier;
    default:
      return state;
  }
};

const isSaving: Reducer<boolean> = (state = initialState.isSaving, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.isSaving;
    case Type.SET_IS_SAVING:
      return (action as SetIsSavingAction).isSaving;
    default:
      return state;
  }
};

const errors: Reducer<IErrors> = (state = initialState.errors, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.errors;
    case Type.SET_ERRORS:
      return (action as SetErrorsAction).errors;
    default:
      return state;
  }
};

export const clientDetailStore = combineReducers({
  isReadyForRendering,
  client,
  clientInitial,
  isView,
  hasReportCustomFieldSettingTypeGeneralAtMountTime,
  companyCertifications,
  isFetchingCompanyCertifications,
  saleProducts,
  isFetchingSaleProducts,
  purchaseProducts,
  isFetchingPurchaseProducts,
  isEditingProduct,
  companySuppliers,
  isFetchingCompanySuppliers,
  isEditingCompanySupplier,
  isSaving,
  errors,

  companyUsers,
  isFetchingCompanyUsers
});
