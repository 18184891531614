import { PagerComponent } from '@syncfusion/ej2-react-grids';
import { Moment } from 'moment';
import i18next from 'i18next';
import * as dateHelpers from '../helpers/date.helpers';

const defaultPageSize = 25;

export const getPageCount = (): number => {
  return 5;
};

export const getDefaultPageSize = (): number => {
  return defaultPageSize;
};

export const getPageSizes = (): number[] => {
  return [10, defaultPageSize, 50, 100, 200];
};

export const getMaxPageSize = (): number => {
  const pageSizes = getPageSizes();
  return pageSizes[pageSizes.length - 1];
};

const addSearchDateToPager = (pagerComponent: PagerComponent, searchDate: Moment, i18n: i18next.i18n): void => {
  if (pagerComponent.element) {
    const parentMsgBarCustom = pagerComponent.element.getElementsByClassName('e-parentmsgbar--custom');
    if (parentMsgBarCustom.length === 0) {
      const parentMsgBar = pagerComponent.element.getElementsByClassName('e-parentmsgbar');
      parentMsgBar[0].insertAdjacentHTML('afterend', '<div class="e-parentmsgbar e-parentmsgbar--custom"></div>');
    }
    parentMsgBarCustom[0].innerHTML = `${i18n.t('pagerComponent.lastSearchDate')}: ${dateHelpers.toShortDate(searchDate, true)} ${dateHelpers.toDateWithoutZones(searchDate, 'LT')}`;
  }
};

export const refreshPager = (
  pagerComponent: PagerComponent,
  currentPage: number,
  pageSize: number,
  totalCount: number,
  searchDate: Moment,
  i18n: i18next.i18n
): void => {
  if (pagerComponent) {
    pagerComponent.currentPage = currentPage;
    pagerComponent.pageSize = pageSize;
    pagerComponent.totalRecordsCount = totalCount;
    if (searchDate) {
      addSearchDateToPager(pagerComponent, searchDate, i18n);
    }
  }
}
