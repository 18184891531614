import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
  GridComponent,
  ToolbarItems,
  PageSettingsModel,
  EditSettingsModel,
  ActionEventArgs,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Sort,
  Page,
  Toolbar,
  Resize,
  Edit
} from '@syncfusion/ej2-react-grids';
import { DataManager } from '@syncfusion/ej2-data';
import { DropDownList } from '@syncfusion/ej2-dropdowns';
import { DialogComponent } from '@aitex/tsx-extranet-dialogs';

import { IEditReportCustomFieldSettingsDialogProps } from '../../../interfaces/props/IDialogsProps';
import { IReportCustomFieldSettingsWithCheckboxes } from '../../../common/model/customFieldSettings.model';
import * as dialogUtils from '../../../utils/dialog.utils';
import * as gridUtils from '../../../utils/grid.utils';
import * as pagerUtils from '../../../utils/pager.utils';
import FormError from '../../../components/formError/formError';
import './editReportCustomFieldSettingsDialog.scss';

type EditReportCustomFieldSettingsDialogPropsType = IEditReportCustomFieldSettingsDialogProps & WithTranslation;

class EditReportCustomFieldSettingsDialog extends Component<EditReportCustomFieldSettingsDialogPropsType> {
  public readonly BOOLEAN_DEFAULT_VALUE = false;

  public gridComponent: GridComponent = null;
  public toolbarOptions: ToolbarItems[] = ['Add', 'Edit', 'Update', 'Cancel'];
  public pageSettings: PageSettingsModel = {
    pageSize: pagerUtils.getMaxPageSize(),
    pageCount: pagerUtils.getPageCount(),
    pageSizes: pagerUtils.getPageSizes()
  };
  public editSettings: EditSettingsModel = { allowEditing: true, allowEditOnDblClick: false, allowAdding: true, newRowPosition: 'Top' };

  private auxElem: HTMLInputElement;
  private auxDropDownObj: DropDownList;

  private readonly CLOSE_BUTTON_ID = 'editReportCustomFieldSettingsDialogClose';

  public componentDidUpdate(prevProps: EditReportCustomFieldSettingsDialogPropsType) {
    if (this.gridComponent) {
      gridUtils.manageSpinner(this.gridComponent, prevProps.isFetching, this.props.isFetching);
      gridUtils.manageDataSource(this.gridComponent, () => this.updateGridComponentDataSource(), prevProps.isFetching, this.props.isFetching, prevProps.visible, this.props.visible);

      if (prevProps.reportCustomFieldCodes.length !== this.props.reportCustomFieldCodes.length) {
        const customFieldCodeColumn = this.gridComponent.getColumnByField('customFieldCode');
        if (customFieldCodeColumn) {
          customFieldCodeColumn.edit = {
            create: () => {
              this.auxElem = document.createElement('input');
              this.auxElem.setAttribute('type', 'text');
              return this.auxElem;
            },
            destroy: () => {
              this.auxDropDownObj.destroy();
            },
            read: () => {
              return this.auxDropDownObj.value;
            },
            write: (args: any) => {
              this.auxDropDownObj = new DropDownList({
                dataSource: this.props.reportCustomFieldCodes.map((code) => ({ value: code, text: this.props.i18n.t(`reportCustomFields.${code}`) })),
                fields: { text: 'text', value: 'value' },
                value: args.rowData.customFieldCode,
                itemTemplate: ((data: any) => (<span title={data.text}>{data.text}</span>)) as unknown as string
              });
              this.auxDropDownObj.appendTo(this.auxElem);
            }
          };
        }
      }
    }

    dialogUtils.manageButtonsClick(null, null, this.CLOSE_BUTTON_ID, () => this.props.onDismiss());
  }

  public updateGridComponentDataSource() {
    this.gridComponent.dataSource = new DataManager(JSON.parse(JSON.stringify(this.props.reportCustomFieldSettingsWithCheckboxes))); // Clone by value
  }

  public onActionComplete(args: ActionEventArgs) {
    const { companyId, onSaveReportCustomFieldSettings } = this.props;

    if (args.requestType === 'save') {
      const data = args.data as any;
      const reportCustomFieldSettingsWithCheckboxes: IReportCustomFieldSettingsWithCheckboxes = {
        ...data,
        id: data.id ? data.id : null,
        companyId
      };
      setTimeout(() => onSaveReportCustomFieldSettings(reportCustomFieldSettingsWithCheckboxes));
    }
  }

  public getCustomFieldCodeLabel(reportCustomFieldSettingsWithCheckboxes: IReportCustomFieldSettingsWithCheckboxes): string {
    return this.props.i18n.t(`reportCustomFields.${reportCustomFieldSettingsWithCheckboxes.customFieldCode}`);
  }

  public render() {
    const { visible, errors, onDismiss, i18n } = this.props;

    return (
      <DialogComponent
        header={i18n.t('dialogs.editReportCustomFieldSettingsDialog.title')}
        visible={visible}
        width='80%'
        footerTemplate={dialogUtils.computeFooterTemplate(
          null,
          null,
          this.CLOSE_BUTTON_ID,
          i18n.t('actions.close')
        )}
        onDismiss={() => onDismiss()}
      >
        <div className='edit-report-custom-field-settings-grid'>
          <FormError errors={errors} />
          <GridComponent
            toolbar={this.toolbarOptions}
            allowSorting={true}
            allowPaging={true}
            allowResizing={true}
            allowFiltering={true}
            pageSettings={this.pageSettings}
            editSettings={this.editSettings}
            clipMode='EllipsisWithTooltip'
            locale={i18n.language}
            ref={(grid) => this.gridComponent = grid}
            created={() => this.updateGridComponentDataSource()}
            actionComplete={(args) => this.onActionComplete(args as ActionEventArgs)}
          >
            <ColumnsDirective>
              <ColumnDirective
                field='customFieldCode'
                headerText={i18n.t('reportCustomFieldSettings.customField')}
                width='150'
                type='string'
                validationRules={{ required: true }}
                valueAccessor={(_field, data) => this.getCustomFieldCodeLabel(data as IReportCustomFieldSettingsWithCheckboxes)}
              />
              <ColumnDirective
                field='listAll'
                headerText={i18n.t('reportCustomFieldSettings.listAll')}
                width='100'
                type='boolean'
                defaultValue={this.BOOLEAN_DEFAULT_VALUE + ''}
                editType='booleanedit'
                displayAsCheckBox={true}
              />
              <ColumnDirective
                field='listInProgress'
                headerText={i18n.t('reportCustomFieldSettings.listInProgress')}
                width='100'
                type='boolean'
                defaultValue={this.BOOLEAN_DEFAULT_VALUE + ''}
                editType='booleanedit'
                displayAsCheckBox={true}
              />
              <ColumnDirective
                field='listFinalized'
                headerText={i18n.t('reportCustomFieldSettings.listFinalized')}
                width='100'
                type='boolean'
                defaultValue={this.BOOLEAN_DEFAULT_VALUE + ''}
                editType='booleanedit'
                displayAsCheckBox={true}
              />
              <ColumnDirective
                field='filterAll'
                headerText={i18n.t('reportCustomFieldSettings.filterAll')}
                width='100'
                type='boolean'
                defaultValue={this.BOOLEAN_DEFAULT_VALUE + ''}
                editType='booleanedit'
                displayAsCheckBox={true}
              />
              <ColumnDirective
                field='filterInProgress'
                headerText={i18n.t('reportCustomFieldSettings.filterInProgress')}
                width='100'
                type='boolean'
                defaultValue={this.BOOLEAN_DEFAULT_VALUE + ''}
                editType='booleanedit'
                displayAsCheckBox={true}
              />
              <ColumnDirective
                field='filterFinalized'
                headerText={i18n.t('reportCustomFieldSettings.filterFinalized')}
                width='100'
                type='boolean'
                defaultValue={this.BOOLEAN_DEFAULT_VALUE + ''}
                editType='booleanedit'
                displayAsCheckBox={true}
              />
              <ColumnDirective
                field='advancedFilter'
                headerText={i18n.t('reportCustomFieldSettings.advancedFilter')}
                width='100'
                type='boolean'
                defaultValue={this.BOOLEAN_DEFAULT_VALUE + ''}
                editType='booleanedit'
                displayAsCheckBox={true}
              />
              <ColumnDirective
                field='form'
                headerText={i18n.t('reportCustomFieldSettings.form')}
                width='100'
                type='boolean'
                defaultValue={this.BOOLEAN_DEFAULT_VALUE + ''}
                editType='booleanedit'
                displayAsCheckBox={true}
              />
            </ColumnsDirective>
            <Inject services={[Sort, Page, Toolbar, Resize, Edit]} />
          </GridComponent>
        </div>
      </DialogComponent>
    );
  }
}

export default withTranslation()(EditReportCustomFieldSettingsDialog);
