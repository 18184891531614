import { Action, Reducer, ActionCreator, combineReducers } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { batch } from 'react-redux';
import { DataManager, Query } from '@syncfusion/ej2-data';
import moment from 'moment';

import { RootState } from '..';
import * as listGridStore from './listGrid.store';
import { ICertificatesListState, ICertificatesListFilterState } from '../../interfaces/states/ICertificatesListState';
import { ICertificate } from '../../common/model/certificate.model';
import { CertificateStatus } from '../../common/model/enumerations/certificateStatus.model';
import { CertificateRenewalState } from '../../common/model/enumerations/certificateRenewalState.model';
import { IODataService } from '../../services';
import * as certificateUtils from '../../utils/certificate.utils';

export enum Type {
  RESET = '@@certificatesList/RESET',
  SET_CERTIFICATES = '@@certificatesList/SET_CERTIFICATES',
  SET_MODE = '@@certificatesList/SET_MODE',
  SET_IS_CHANGING_MODE = '@@certificatesList/SET_IS_CHANGING_MODE',
  SET_FILTER = '@@certificatesList/SET_FILTER',
  SET_FILTER_IDENTIFICATION_CODE = '@@certificatesList/SET_FILTER_IDENTIFICATION_CODE',
  SET_FILTER_OFFER_REQUERIMENT_STANDARD = '@@certificatesList/SET_FILTER_OFFER_REQUERIMENT_STANDARD',
  SET_FILTER_REPORT_NUMBER = '@@certificatesList/SET_FILTER_REPORT_NUMBER',
  SET_FILTER_STATUS = '@@certificatesList/SET_FILTER_STATUS',
  SET_FILTER_REFERENCE_NAMES = '@@certificatesList/SET_FILTER_REFERENCE_NAMES',
  SET_FILTER_RENEWAL_STATE = '@@certificatesList/SET_FILTER_RENEWAL_STATE',
  SET_FILTER_CERTIFICATE_TYPE_VALUES = '@@certificatesList/SET_FILTER_CERTIFICATE_TYPE_VALUES',
  SET_FILTER_EXPIRATION_DATE = '@@certificatesList/SET_FILTER_EXPIRATION_DATE',
  SET_FILTER_COMPANY_NAME = '@@certificatesList/SET_FILTER_COMPANY_NAME',
  SET_IS_FETCHING = '@@certificatesList/SET_IS_FETCHING',
  SET_SEARCH_DATE = '@@certificatesList/SET_SEARCH_DATE',
  SET_CURRENT_PAGE = '@@certificatesList/SET_CURRENT_PAGE',
  SET_PAGE_SIZE = '@@certificatesList/SET_PAGE_SIZE',
  SET_TOTAL_COUNT = '@@certificatesList/SET_TOTAL_COUNT',
  SET_SORTING = '@@certificatesList/SET_SORTING',
  SET_SELECTED_CERTIFICATES_IDS = '@@certificatesList/SET_SELECTED_CERTIFICATES_IDS'
}

const initialState: ICertificatesListState = {
  certificates: [],
  mode: null,
  isChangingMode: true,
  filter: {
    identificationCode: null,
    offerRequerimentStandard: null,
    reportNumber: null,
    status: CertificateStatus.ALL,
    referenceNames: null,
    renewalState: CertificateRenewalState.ALL,
    certificateTypeValues: [],
    expirationDate: [],
    companyName: null
  },
  grid: listGridStore.initialState,
  selectedCertificatesIds: []
};

// Actions

export type Actions = ResetAction | SetCertificatesAction | SetModeAction | SetIsChangingModeAction | SetFilterAction | SetFilterIdentificationCodeAction | SetFilterOfferRequerimentStandardAction
  | SetFilterReportNumberAction | SetFilterStatusAction | SetFilterReferenceNamesAction | SetFilterRenewalStateAction | SetFilterCertificateTypeValuesAction | SetFilterExpirationDateAction
  | SetFilterCompanyNameAction | listGridStore.SetIsFetchingAction | listGridStore.SetSearchDateAction | listGridStore.SetCurrentPageAction | listGridStore.SetPageSizeAction | listGridStore.SetTotalCountAction
  | listGridStore.SetSortingAction | SetSelectedCertificatesIdsAction;

export type ResetAction = Action<Type.RESET>

export interface SetCertificatesAction extends Action<Type.SET_CERTIFICATES> {
  certificates: ICertificate[];
}

export interface SetModeAction extends Action<Type.SET_MODE> {
  mode: string;
}

export interface SetIsChangingModeAction extends Action<Type.SET_IS_CHANGING_MODE> {
  isChangingMode: boolean;
}

export interface SetFilterAction extends Action<Type.SET_FILTER> {
  filter: ICertificatesListFilterState;
}

export interface SetFilterIdentificationCodeAction extends Action<Type.SET_FILTER_IDENTIFICATION_CODE> {
  identificationCode: string;
}

export interface SetFilterOfferRequerimentStandardAction extends Action<Type.SET_FILTER_OFFER_REQUERIMENT_STANDARD> {
  offerRequerimentStandard: string;
}

export interface SetFilterReportNumberAction extends Action<Type.SET_FILTER_REPORT_NUMBER> {
  reportNumber: string;
}

export interface SetFilterStatusAction extends Action<Type.SET_FILTER_STATUS> {
  status: string;
}

export interface SetFilterReferenceNamesAction extends Action<Type.SET_FILTER_REFERENCE_NAMES> {
  referenceNames: string;
}

export interface SetFilterRenewalStateAction extends Action<Type.SET_FILTER_RENEWAL_STATE> {
  renewalState: number;
}

export interface SetFilterCertificateTypeValuesAction extends Action<Type.SET_FILTER_CERTIFICATE_TYPE_VALUES> {
  certificateTypeValues: string[];
}

export interface SetFilterExpirationDateAction extends Action<Type.SET_FILTER_EXPIRATION_DATE> {
  expirationDate: Date[];
}

export interface SetFilterCompanyNameAction extends Action<Type.SET_FILTER_COMPANY_NAME> {
  companyName: string;
}

export interface SetSelectedCertificatesIdsAction extends Action<Type.SET_SELECTED_CERTIFICATES_IDS> {
  selectedCertificatesIds: string[];
}

export const reset: ActionCreator<ResetAction> = () => ({
  type: Type.RESET
});

export const setCertificates: ActionCreator<SetCertificatesAction> = (certificatesLocal: ICertificate[]) => ({
  type: Type.SET_CERTIFICATES,
  certificates: certificatesLocal
});

export const setMode: ActionCreator<SetModeAction> = (modeLocal: string) => ({
  type: Type.SET_MODE,
  mode: modeLocal
});

export const setIsChangingMode: ActionCreator<SetIsChangingModeAction> = (isChanginModeLocal: boolean) => ({
  type: Type.SET_IS_CHANGING_MODE,
  isChangingMode: isChanginModeLocal
});

export const setFilter: ActionCreator<SetFilterAction> = (filterLocal: ICertificatesListFilterState) => ({
  type: Type.SET_FILTER,
  filter: filterLocal
});

export const setFilterIdentificationCode: ActionCreator<SetFilterIdentificationCodeAction> = (identificationCode: string) => ({
  type: Type.SET_FILTER_IDENTIFICATION_CODE,
  identificationCode
});

export const setFilterOfferRequerimentStandard: ActionCreator<SetFilterOfferRequerimentStandardAction> = (offerRequerimentStandard: string) => ({
  type: Type.SET_FILTER_OFFER_REQUERIMENT_STANDARD,
  offerRequerimentStandard
});

export const setFilterReportNumber: ActionCreator<SetFilterReportNumberAction> = (reportNumber: string) => ({
  type: Type.SET_FILTER_REPORT_NUMBER,
  reportNumber
});

export const setFilterStatus: ActionCreator<SetFilterStatusAction> = (status: string) => ({
  type: Type.SET_FILTER_STATUS,
  status
});

export const setFilterReferenceNames: ActionCreator<SetFilterReferenceNamesAction> = (referenceNames: string) => ({
  type: Type.SET_FILTER_REFERENCE_NAMES,
  referenceNames
});

export const setFilterRenewalState: ActionCreator<SetFilterRenewalStateAction> = (renewalState: number) => ({
  type: Type.SET_FILTER_RENEWAL_STATE,
  renewalState
});

export const setFilterCertificateTypeValues: ActionCreator<SetFilterCertificateTypeValuesAction> = (certificateTypeValues: string[]) => ({
  type: Type.SET_FILTER_CERTIFICATE_TYPE_VALUES,
  certificateTypeValues
});

export const setFilterExpirationDate: ActionCreator<SetFilterExpirationDateAction> = (expirationDate: Date[]) => ({
  type: Type.SET_FILTER_EXPIRATION_DATE,
  expirationDate
});

export const setFilterCompanyName: ActionCreator<SetFilterCompanyNameAction> = (companyName: string) => ({
  type: Type.SET_FILTER_COMPANY_NAME,
  companyName
});

export const setSelectedCertificatesIds: ActionCreator<SetSelectedCertificatesIdsAction> = (selectedCertificatesIdsLocal: string[]) => ({
  type: Type.SET_SELECTED_CERTIFICATES_IDS,
  selectedCertificatesIds: selectedCertificatesIdsLocal
});

export const fetchCertificates: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (
  oDataService: IODataService,
  certificatesDataManager: DataManager,
  certificatesQuery: Query
) => (
    async (dispatch, getState) => {
      dispatch(listGridStore.setIsFetching(Type.SET_IS_FETCHING, true));

      const currentPageLocal = getState().certificatesListStore.grid.currentPage;
      const pageSizeLocal = getState().certificatesListStore.grid.pageSize;
      const sortFieldLocal = getState().certificatesListStore.grid.sortField;
      const sortDirectionLocal = getState().certificatesListStore.grid.sortDirection;

      let query = certificatesQuery.page(currentPageLocal, pageSizeLocal);
      if (sortFieldLocal && sortDirectionLocal) {
        query = sortDirectionLocal === 'Ascending' ? query.sortBy(sortFieldLocal) : query.sortByDesc(sortFieldLocal);
      }
      const certificatesResponse = await oDataService.executeQueryWithCount<ICertificate>(certificatesDataManager, query);
      const certificatesLocal = certificatesResponse.result.map((certificate) => certificateUtils.init(certificate));
      batch(() => {
        dispatch(setCertificates(certificatesLocal));
        dispatch(listGridStore.setSearchDate(Type.SET_SEARCH_DATE, moment()));
        dispatch(listGridStore.setTotalCount(Type.SET_TOTAL_COUNT, certificatesResponse.count));
        dispatch(setSelectedCertificatesIds(initialState.selectedCertificatesIds));
      });
      dispatch(listGridStore.setIsFetching(Type.SET_IS_FETCHING, false));
    }
  );

// Reducers

const certificates: Reducer<ICertificate[]> = (state = initialState.certificates, action) => {
  switch (action.type) {
    case Type.RESET:
      return state;
    case Type.SET_MODE:
      return initialState.certificates;
    case Type.SET_CERTIFICATES:
      return (action as SetCertificatesAction).certificates;
    default:
      return state;
  }
};

const mode: Reducer<string> = (state = initialState.mode, action) => {
  switch (action.type) {
    case Type.RESET:
      return state;
    case Type.SET_MODE:
      return (action as SetModeAction).mode;
    default:
      return state;
  }
};

const isChangingMode: Reducer<boolean> = (state = initialState.isChangingMode, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.isChangingMode;
      case Type.SET_MODE:
        return false;
    case Type.SET_IS_CHANGING_MODE:
      return (action as SetIsChangingModeAction).isChangingMode;
    default:
      return state;
  }
}

const filter: Reducer<ICertificatesListFilterState> = (state = initialState.filter, action) => {
  switch (action.type) {
    case Type.RESET:
      return state;
    case Type.SET_MODE:
      return {
        ...state,
        status: (action as SetModeAction).mode
      };
    case Type.SET_FILTER:
      return (action as SetFilterAction).filter;
    case Type.SET_FILTER_IDENTIFICATION_CODE:
      return { ...state, identificationCode: (action as SetFilterIdentificationCodeAction).identificationCode };
    case Type.SET_FILTER_OFFER_REQUERIMENT_STANDARD:
      return { ...state, offerRequerimentStandard: (action as SetFilterOfferRequerimentStandardAction).offerRequerimentStandard };
    case Type.SET_FILTER_REPORT_NUMBER:
      return { ...state, reportNumber: (action as SetFilterReportNumberAction).reportNumber };
    case Type.SET_FILTER_STATUS:
      return { ...state, status: (action as SetFilterStatusAction).status };
    case Type.SET_FILTER_REFERENCE_NAMES:
      return { ...state, referenceNames: (action as SetFilterReferenceNamesAction).referenceNames };
    case Type.SET_FILTER_RENEWAL_STATE:
      return { ...state, renewalState: (action as SetFilterRenewalStateAction).renewalState };
    case Type.SET_FILTER_CERTIFICATE_TYPE_VALUES:
      return { ...state, certificateTypeValues: (action as SetFilterCertificateTypeValuesAction).certificateTypeValues };
    case Type.SET_FILTER_EXPIRATION_DATE:
      return { ...state, expirationDate: (action as SetFilterExpirationDateAction).expirationDate };
    case Type.SET_FILTER_COMPANY_NAME:
      return { ...state, companyName: (action as SetFilterCompanyNameAction).companyName };
    default:
      return state;
  }
};

const selectedCertificatesIds: Reducer<string[]> = (state = initialState.selectedCertificatesIds, action) => {
  switch (action.type) {
    case Type.RESET:
      return state;
    case Type.SET_MODE:
      return initialState.selectedCertificatesIds;
    case Type.SET_SELECTED_CERTIFICATES_IDS:
      return (action as SetSelectedCertificatesIdsAction).selectedCertificatesIds;
    default:
      return state;
  }
};

export const certificatesListStore = combineReducers({
  certificates,
  mode,
  isChangingMode,
  filter,
  grid: listGridStore.createListGridStore(Type, 'reportNumber', 'Descending'),
  selectedCertificatesIds
});
