
import contextualHelpData from '../assets/contextualHelp/contextualHelpData.json';
import { UNKNOWN_ERROR } from '../common/constants/error.constants';
import { IContextualHelp } from '../common/model/contextualHelp.model';
import * as services from '.';

// TODO: extraer a utilidad compartida por web y sso

export interface IContextualHelpsService {
  fetchByCode(code: string, language: string): Promise<string>;
}

class ContextualHelpsService implements IContextualHelpsService {
  public constructor(private httpClient: services.IHttpClient) {

  }

  public async fetchByCode(code: string, language: string): Promise<string> {
    try {
      const contextualHelp: IContextualHelp = contextualHelpData.find((ch) => ch.code === code && ch.language === language);

      if (!contextualHelp) {
        throw UNKNOWN_ERROR;
      }

      const result = await this.httpClient.getCustom<string>(window.location.origin + contextualHelp.url);
      return result;
    } catch (error) {
      return null;
    }
  }
}

export const contextualHelpsService = new ContextualHelpsService(services.httpClient);
