import { URL } from '../common/constants';
import { IReference } from '../common/model/reference.model';
import { SourceType } from '../common/model/enumerations/sourceType.model';
import * as services from '.';

export interface IReferencesService {
  create(reference: IReference): Promise<IReference>;
  update(reference: IReference): Promise<IReference>;
  getReferenceByReferenceId(referenceId: string): Promise<any>;
}

class ReferencesService implements IReferencesService {
  public constructor(private httpClient: services.IHttpClient) {

  }

  public create(reference: IReference): Promise<IReference> {
    switch (reference.sourceType) {
      case SourceType.REPORT:
        return this.httpClient.postCustom(`${URL.WEB.BASE}${URL.WEB.REPORTS}/${reference.sourceId}/references`, reference);
      case SourceType.REQUEST:
        return this.httpClient.postCustom(`${URL.WEB.BASE}${URL.WEB.REQUESTS}/${reference.sourceId}/references`, reference);
      default:
        throw new Error();
    }
  }

  public update(reference: IReference): Promise<IReference> {
    switch (reference.sourceType) {
      case SourceType.REPORT:
        return this.httpClient.putCustom(`${URL.WEB.BASE}${URL.WEB.REPORTS}/${reference.sourceId}/references`, reference);
      case SourceType.REQUEST:
        return this.httpClient.putCustom(`${URL.WEB.BASE}${URL.WEB.REQUESTS}/${reference.sourceId}/references`, reference);
      default:
        throw new Error();
    }
  }

  public async getReferenceByReferenceId(referenceId: string) {
    const reference = await this.httpClient.get(`${URL.WEB.BASE}${URL.WEB.REFERENCES}${referenceId}`);
    return reference;
  }
}

export const referencesService = new ReferencesService(services.httpClient);
