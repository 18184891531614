import { Action, Reducer, ActionCreator, combineReducers } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { RootState } from '..';
import { ISettingsState } from '../../interfaces/states/ISettingsState';
import { ISettings } from '../../common/model/settings.model';
import { IErrors } from '../../common/model/errors.model';
import * as settingsService from '../../services/settings.service';

enum Type {
  RESET = '@@settings/RESET',
  SET_SETTINGS = '@@settings/SET_SETTINGS',
  SET_ERRORS = '@@settings/SET_ERRORS'
}

const initialState: ISettingsState = {
  settings: null,
  errors: null
};

// Actions

export type Actions = ResetAction | SetSettingsAction | SetErrorsAction;

export type ResetAction = Action<Type.RESET>

export interface SetSettingsAction extends Action<Type.SET_SETTINGS> {
  settings: ISettings;
}

export interface SetErrorsAction extends Action<Type.SET_ERRORS> {
  errors: IErrors;
}

export const reset: ActionCreator<ResetAction> = () => ({
  type: Type.RESET
});

export const setSettings: ActionCreator<SetSettingsAction> = (settingsLocal: ISettings) => ({
  type: Type.SET_SETTINGS,
  settings: settingsLocal
});

export const setErrors: ActionCreator<SetErrorsAction> = (errorsLocal: IErrors) => ({
  type: Type.SET_ERRORS,
  errors: errorsLocal
});

export const fetchSettings: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = () => (
  async (dispatch) => {
    try {
      dispatch(setErrors(initialState.errors));

      const settingsLocal = await settingsService.fetch();

      dispatch(setSettings(settingsLocal));
    } catch (error) {
      const errorsLocal = error as IErrors;
      dispatch(reset());
      dispatch(setErrors(errorsLocal));
    }
  }
);

// Reducers

const settings: Reducer<ISettings> = (state = initialState.settings, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.settings;
    case Type.SET_SETTINGS:
      return (action as SetSettingsAction).settings;
    default:
      return state;
  }
};

const errors: Reducer<IErrors> = (state = initialState.errors, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.errors;
    case Type.SET_ERRORS:
      return (action as SetErrorsAction).errors;
    default:
      return state;
  }
};

export const settingsStore = combineReducers({ settings, errors });
