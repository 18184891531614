import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { GridComponent, ToolbarItems, PageSettingsModel, EditSettingsModel, ActionEventArgs, ColumnsDirective, ColumnDirective, Inject, Page, Toolbar, Resize, Edit } from '@syncfusion/ej2-react-grids';
import { DataManager, Query } from '@syncfusion/ej2-data';
import { DropDownListModel } from '@syncfusion/ej2-dropdowns';
import { ItemModel, ClickEventArgs } from '@syncfusion/ej2-navigations';
import { DialogComponent } from '@aitex/tsx-extranet-dialogs';

import { IEditChainSeriesDialogProps } from '../../../interfaces/props/IDialogsProps';
import { ISeries } from '../../../common/model/series.model';
import { RuleOperator } from '../../../common/model/enumerations/ruleOperator.model';
import * as dialogUtils from '../../../utils/dialog.utils';
import * as pagerUtils from '../../../utils/pager.utils';
import './editChainSeriesDialog.scss';

type EditChainSeriesDialogPropsType = IEditChainSeriesDialogProps & WithTranslation;

type EditChainSeriesDialogStateType = IEditChainSeriesDialogState;

class EditChainSeriesDialog extends Component<EditChainSeriesDialogPropsType, EditChainSeriesDialogStateType> {
  public readonly ACTIVE_DEFAULT_VALUE = true;

  public gridComponent: GridComponent = null;
  public toolbarOptions: Array<ToolbarItems | ItemModel> = [];
  public pageSettings: PageSettingsModel = {
    pageSize: pagerUtils.getMaxPageSize(),
    pageCount: pagerUtils.getPageCount(),
    pageSizes: pagerUtils.getPageSizes()
  };
  public editSettings: EditSettingsModel = { allowEditing: false, allowAdding: true, newRowPosition: 'Top' };

  private readonly OK_BUTTON_ID = 'editChainSeriesDialogOk';

  private readonly REMOVE_SERIES_ACTION_ID = 'removeSeries';

  private initialState: EditChainSeriesDialogStateType = {
    isEditing: false
  };

  public constructor(props: EditChainSeriesDialogPropsType) {
    super(props);

    const { i18n } = this.props;

    this.toolbarOptions = [
      'Add',
      'Update',
      'Cancel',
      { tooltipText: i18n.t('actions.remove'), id: this.REMOVE_SERIES_ACTION_ID, cssClass: 'e-overlay', prefixIcon: 'icon icon-app-item-remove' }
    ];

    this.state = this.initialState;
  }

  public componentDidUpdate() {
    dialogUtils.manageButtonsClick(this.OK_BUTTON_ID, () => this.onOkButtonClick(), null, null);
  }

  public onOkButtonClick() {
    this.props.onClose(this.props.chain);
  }

  public dismiss() {
    this.props.onDismiss();
  }

  public updateGridComponentDataSource() {
    this.gridComponent.dataSource = new DataManager(JSON.parse(JSON.stringify(this.props.chain.series))); // Clone by value
  }

  public onRowSelectedOrDeselected() {
    this.gridComponent.toolbarModule.enableItems([this.REMOVE_SERIES_ACTION_ID], this.gridComponent.getSelectedRows().length === 1);
  }

  public onActionBegin(args: ActionEventArgs) {
    if (args.requestType === 'add' || args.requestType === 'beginEdit') {
      let query = new Query().select(['id', 'name']);
      for (const series of this.props.chain.series) {
        query = query.where('id', RuleOperator.NOT_EQUAL, series.id);
      }
      query = query.take(10);
      (this.gridComponent.getColumnByField('name').edit.params as DropDownListModel).query = query;

      this.setState({ isEditing: true });
    }

    if (args.requestType === 'cancel') {
      this.setState({ isEditing: false });
    }
  }

  public onActionComplete(args: ActionEventArgs) {
    if (args.requestType === 'save') {
      const data = args.data as any;
      const series: ISeries = {
        ...data,
        id: data.name ? data.name.id : null,
        name: data.name ? data.name.name : null
      };
      this.props.chain.series = [...this.props.chain.series, series]; // Always adding, never editing
      setTimeout(() => {
        this.updateGridComponentDataSource();
        this.setState({ isEditing: false });
      });
    }
  }

  public onToolbarClick(args: ClickEventArgs) {
    if (args.item.id === this.REMOVE_SERIES_ACTION_ID && this.gridComponent.getSelectedRowIndexes().length === 1) {
      const index = this.gridComponent.getSelectedRowIndexes()[0];
      this.props.chain.series = [...this.props.chain.series.slice(0, index), ...this.props.chain.series.slice(index + 1)];
      setTimeout(() => this.updateGridComponentDataSource());
    }
  }

  public isOkButtonDisabled(): boolean {
    return this.state.isEditing;
  }

  public render() {
    const { visible, chain, seriesParams, i18n } = this.props;

    return (
      <DialogComponent
        header={i18n.t('dialogs.editChainSeriesDialog.title')}
        visible={visible}
        showCloseIcon={!this.state.isEditing}
        width='60%'
        footerTemplate={dialogUtils.computeFooterTemplate(
          this.OK_BUTTON_ID,
          i18n.t('actions.ok'),
          null,
          null,
          null,
          this.isOkButtonDisabled()
        )}
        onDismiss={() => this.dismiss()}
      >
        <div>
          {chain && <div className='edit-chain-series-grid'>
            <GridComponent
              toolbar={this.toolbarOptions}
              allowPaging={true}
              allowResizing={true}
              pageSettings={this.pageSettings}
              editSettings={this.editSettings}
              locale={i18n.language}
              ref={(grid: GridComponent) => this.gridComponent = grid}
              created={() => this.updateGridComponentDataSource()}
              rowSelected={() => this.onRowSelectedOrDeselected()}
              rowDeselected={() => this.onRowSelectedOrDeselected()}
              actionBegin={(args) => this.onActionBegin(args as ActionEventArgs)}
              actionComplete={(args) => this.onActionComplete(args as ActionEventArgs)}
              toolbarClick={(args) => this.onToolbarClick(args)}
            >
              <ColumnsDirective>
                <ColumnDirective
                  field='name'
                  headerText={i18n.t('series.name')}
                  width='200'
                  type='string'
                  editType='dropdownedit'
                  edit={seriesParams}
                />
              </ColumnsDirective>
              <Inject services={[Page, Toolbar, Resize, Edit]} />
            </GridComponent>
          </div>}
        </div>
      </DialogComponent>
    );
  }

}

interface IEditChainSeriesDialogState {
  isEditing: boolean;
}

export default withTranslation()(EditChainSeriesDialog);
