import React, { Component, ComponentType } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { compose } from 'redux';
import { Tabs, Tab } from 'react-bootstrap';
import { GridComponent } from '@syncfusion/ej2-react-grids';
import { DataManager } from '@syncfusion/ej2-data';
import { SortDirection } from '@syncfusion/ej2-grids';
import queryString from 'query-string';
import { dialogsService } from '@aitex/tsx-extranet-dialogs';

import { RootState } from '../../store';
import { IReportsListContainerStateProps, IReportsListContainerDispatchProps } from '../../interfaces/props/IReportsListProps';
import { IReportsListRouteParams } from '../../interfaces/routeParams/IReportsListRouteParams';
import * as reportsListStore from '../../store/modules/reportsList.store';
import * as listGridStore from '../../store/modules/listGrid.store';
import * as dialogsStore from '../../store/modules/dialogs.store';
import { URL } from '../../common/constants';
import { IReportCombo } from '../../common/model/report.model';
import { IMyRule } from '../../common/model/myRule.model';
import { IAdvancedFilter } from '../../common/model/advancedFilter.model';
import { IErrors } from '../../common/model/errors.model';
import { ReportsListMode } from '../../common/model/enumerations/reportsListMode.model';
import { FilterMode } from '../../common/model/enumerations/filterMode.model';
import { DialogId } from '../../common/model/enumerations/dialogId.model';
import { ContextualHelpCode } from '../../common/model/enumerations/contextualHelpCode.model';
import { UserType } from '../../common/model/enumerations/userType.model';
import { RoleNormalizedName } from '../../common/model/enumerations/roleNormalizedName.model';
import { queryBuilderService, oDataService, rolesService, advancedFiltersService, dataManagerService, companiesService, reportsService, userSettingsService } from '../../services';
import * as currentUserUtils from '../../utils/currentUser.utils';
import { notifySuccess, notifyError } from '../../utils/toast.utils';
import ReportsListFilter from './reportsListFilter/reportsListFilter';
import ReportsListAdvancedFilter from './reportsListAdvancedFilter/reportsListAdvancedFilter';
import ReportsGrid from './reportsGrid/reportsGrid';
import { IOrderColumn } from '../../interfaces/IOrderColumn';
import { IUserSetting } from '../../common/model/userSetting.model';
import * as userSettingStore from '../../store/modules/userSetting.store';
import * as gridUtils from '../../utils/grid.utils';

type ReportsListContainerPropsType = PropsFromRedux & RouteComponentProps<IReportsListRouteParams> & WithTranslation;

class ReportsListContainer extends Component<ReportsListContainerPropsType> {
  public reportsGridComponent: GridComponent = null;
  public reportsDataManager: DataManager = null;

  public constructor(props: ReportsListContainerPropsType) {
    super(props);

    const { reset } = this.props;

    this.reportsDataManager = dataManagerService.buildDataManager(URL.ODATA.REPORT_WITH_CUSTOM_FIELDS_FILTERS);

    reset();
  }

  public componentDidMount() {
    this.init();
  }

  public componentDidUpdate(prevProps: ReportsListContainerPropsType) {
    if (
      prevProps.isCurrentUserInternal !== this.props.isCurrentUserInternal
      || !this.props.isCurrentUserInternal && (prevProps.currentUserCompany !== this.props.currentUserCompany)
    ) {
      setTimeout(() => this.props.resetHard());
    }

    this.init();
  }

  public componentWillUnmount() {
    this.props.reset();
  }

  public onFilterReportNumberChange(subject: string) {
    this.props.setFilterReportNumber(subject);
  }

  public onFilterStatusChange(status: string) {
    this.props.setFilterStatus(status);
  }

  public onFilterReferenceChange(reference: string) {
    this.props.setFilterReference(reference);
  }

  public onFilterRequerimentStandardChange(requerimentStandard: string) {
    this.props.setFilterRequerimentStandard(requerimentStandard);
  }

  public onFilterEntryDateChange(entryDate: Date[]) {
    this.props.setFilterEntryDate(entryDate);
  }

  public onFilterExpectedDateChange(expectedDate: Date[]) {
    this.props.setFilterExpectedDate(expectedDate);
  }

  public onFilterEndDateChange(endDate: Date[]) {
    this.props.setFilterEndDate(endDate);
  }

  public onFilterExpirationDateChange(expirationDate: Date[]) {
    this.props.setFilterExpirationDate(expirationDate);
  }

  public onFilterOfferNumberChange(reference: string) {
    this.props.setFilterOfferNumber(reference);
  }

  public onFilterClientChange(client: string) {
    this.props.setFilterClient(client);
  }

  public onFilterCollectionChange(collection: string) {
    this.props.setFilterCollection(collection);
  }

  public onFilterColourChange(colour: string) {
    this.props.setFilterColour(colour);
  }

  public onFilterTargetChange(target: string) {
    this.props.setFilterTarget(target);
  }

  public onFilterModelChange(model: string) {
    this.props.setFilterModel(model);
  }

  public onFilterIncidenceChange(incidence: string) {
    this.props.setFilterIncidence(incidence);
  }

  public onFilterEciOrderNumberChange(eciOrderNumber: string) {
    this.props.setFilterEciOrderNumber(eciOrderNumber);
  }

  public onFilterUnecoChange(uneco: string) {
    this.props.setFilterUneco(uneco);
  }

  public onFilterSupplierChange(supplier: string) {
    this.props.setFilterSupplier(supplier);
  }

  public onFilterClosedChange(closed: number) {
    this.props.setFilterClosed(closed);
  }

  public onFilterCopyChange(copy: number) {
    this.props.setFilterCopy(copy);
  }

  public onFilterAitexResultIdChange(aitexResultId: string) {
    this.props.setFilterAitexResultId(aitexResultId);
  }

  public onFilterCountryIdChange(countryId: string) {
    this.props.setFilterCountryId(countryId);
  }

  public onFilterSeasonIdChange(seasonId: string) {
    this.props.setFilterSeasonId(seasonId);
  }

  public onFilterOfficeIdChange(officeId: string) {
    this.props.setFilterOfficeId(officeId);
  }

  public onFilterDivisionIdsChange(divisionId: string[]) {
    this.props.setFilterDivisionId(divisionId);
  }

  public onFilterSectionIdChange(sectionId: string) {
    this.props.setFilterSectionId(sectionId);
  }

  public onFilterBrandIdChange(brandId: string) {
    this.props.setFilterBrandId(brandId);
  }

  public onFilterQualityControlIdChange(qualityControlId: string) {
    this.props.setFilterQualityControlId(qualityControlId);
  }

  public onFilterBuyerIdChange(buyerId: string) {
    this.props.setFilterBuyerId(buyerId);
  }

  public onFilterButtonClick() {
    if (!this.checkIsSimpleFilterValid()) {
      return;
    }

    this.props.setCurrentPage(reportsListStore.Type.SET_CURRENT_PAGE, 1);
    this.fetchReports();
  }

  public onAdvancedFilterSaveButtonClick(rule: IMyRule) {
    const { setAdvancedFilter, setAdvancedFilterRule } = this.props;

    if (!this.checkIsRuleValid(rule)) {
      return;
    }

    setAdvancedFilterRule(rule);
    dialogsService.openDialog(DialogId.SAVE_ADVANCED_FILTER)
      .then((advancedFilter: IAdvancedFilter) => setAdvancedFilter(advancedFilter))
      .catch((reason?: IErrors) => {
        this.manageDialogDismissed(reason);
      });
  }

  public onAdvancedFilterApplyButtonClick() {
    const { setAdvancedFilter, resetAdvancedFilter } = this.props;

    dialogsService.openDialog(DialogId.APPLY_ADVANCED_FILTERS)
      .then((advancedFilterToApply: IAdvancedFilter) => {
        resetAdvancedFilter();
        if (advancedFilterToApply.isActive !== false) {
          setAdvancedFilter(advancedFilterToApply);
        }
      })
      .catch((reason?: IErrors) => {
        this.manageDialogDismissed(reason);
      });
  }

  public onAdvancedFilterShareButtonClick(rule: IMyRule) {
    const { setAdvancedFilter, setAdvancedFilterRule, history } = this.props;

    if (!this.checkIsRuleValid(rule)) {
      return;
    }

    setAdvancedFilterRule(rule);
    dialogsService.openDialog(DialogId.SHARE_ADVANCED_FILTER)
      .then((advancedFilter: IAdvancedFilter) => {
        setAdvancedFilter(advancedFilter);
        setTimeout(() => history.push('/notification/new?fromSharedAdvancedFilter'), 500);
      })
      .catch((reason?: IErrors) => {
        this.manageDialogDismissed(reason);
      });
  }

  public onAdvancedFilterClearButtonClick() {
    this.props.resetAdvancedFilter();
  }

  public onAdvancedFilterFilterButtonClick(rule: IMyRule) {
    const { setAdvancedFilterRule, setCurrentPage } = this.props;

    if (!this.checkIsRuleValid(rule)) {
      return;
    }

    setAdvancedFilterRule(rule);
    setCurrentPage(reportsListStore.Type.SET_CURRENT_PAGE, 1);
    this.fetchReports();
  }

  public onAdvancedFilterContextualHelpClick() {
    this.props.setContextualHelpDialogCode(ContextualHelpCode.QUERY_BUILDER);
    dialogsService.openDialog(DialogId.CONTEXTUAL_HELP)
      .catch(() => {
        // DO nothing
      });
  }

  public onCurrentPageChange(currentPage: number) {
    this.props.setCurrentPage(reportsListStore.Type.SET_CURRENT_PAGE, currentPage);
    this.fetchReports();
  }

  public onPageSizeChange(pageSize: number) {
    this.props.setPageSize(reportsListStore.Type.SET_PAGE_SIZE, pageSize);
    this.fetchReports();
  }

  public onSortingChange(field: string, direction: SortDirection) {
    this.props.setSorting(reportsListStore.Type.SET_SORTING, field, direction);
    this.fetchReports();
  }

  public onVersionClick(report: IReportCombo) {
    this.props.prepareReportVersionsDialog({ id: report.id, reportNumber: report.reportNumber, isEciUser: this.isEciUser()});
    dialogsService.openDialog(DialogId.REPORT_VERSIONS)
      .catch(() => {
        // Do nothing
      });
  }

  public async downloadReport(report: IReportCombo) {
    try {
      if (this.reportsGridComponent) {
        this.reportsGridComponent.showSpinner();
      }

      await reportsService.downloadFile(report);
    } catch (error) {
      notifyError(this.props.i18n.t('reportsList.errors.cannotDownloadReport'));
    } finally {
      if (this.reportsGridComponent) {
        this.reportsGridComponent.hideSpinner();
      }
    }
  }

  public async downloadMultipleReports() {
    try {
      if (this.reportsGridComponent) {
        this.reportsGridComponent.showSpinner();
      }

      await reportsService.downloadMultipleFiles(this.props.selectedReportsIds);
    } catch (error) {
      notifyError(this.props.i18n.t('reportsList.errors.cannotDownloadMultipleReports'));
    } finally {
      if (this.reportsGridComponent) {
        this.reportsGridComponent.hideSpinner();
      }
    }
  }

  public shouldShowFilter(): boolean {
    return this.props.mode === ReportsListMode.ALL || this.props.mode === ReportsListMode.IN_PROGRESS || this.props.mode === ReportsListMode.FINALIZED;
  }

  public shouldShowAdvancedFilterTab(): boolean {
    return this.props.mode === ReportsListMode.ALL && this.areAdvancedFilterColumnsLoaded();
  }

  public isEciUser(): boolean {
    return currentUserUtils.isEciUser(this.props.currentUserCompany, this.props.currentUserChainName);
  }

  public render() {
    const {
      reports,
      mode,
      eciMode,
      isChangingMode,
      filterMode,
      filter,
      advancedFilter,
      reportCustomFieldSettings,
      aitexResultOptions,
      countryOptions,
      seasonOptions,
      officeOptions,
      divisionOptions,
      sectionOptions,
      brandOptions,
      qualityControlOptions,
      buyerOptions,
      advancedFilterColumns,
      isFetching,
      searchDate,
      currentPage,
      pageSize,
      totalCount,
      sortField,
      sortDirection,
      selectedReportsIds,
      isCurrentUserInternal,
      currentUserChainName,
      setFilterMode,
      setSelectedReportsIds,
      i18n,
      currentUserEmail,
      currentUserId
    } = this.props;

    if (!this.areReportCustomFieldSettingsLoaded() || !this.areAdvancedFilterColumnsLoaded() || isChangingMode) {
      return null; // TODO: loading
    }

    return (
      <div>
        <div className='row'>
          <div className='col'>
            <h2>{i18n.t(`reportsList.title.${eciMode ? eciMode : this.getReportsListMode()}`)}</h2>
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            {this.shouldShowFilter() && <div className='mb-3'>
              <Tabs id='reportsListTabs' className='mb-3' activeKey={filterMode} onSelect={(eventKey: string) => setFilterMode(eventKey)}>
                <Tab eventKey={FilterMode.SIMPLE} title={i18n.t('enums.filterMode.SIMPLE')}>
                  <ReportsListFilter
                    filter={filter}
                    listMode={mode}
                    reportCustomFieldSettings={reportCustomFieldSettings}
                    aitexResultOptions={aitexResultOptions}
                    countryOptions={countryOptions}
                    seasonOptions={seasonOptions}
                    officeOptions={officeOptions}
                    divisionOptions={divisionOptions}
                    sectionOptions={sectionOptions}
                    brandOptions={brandOptions}
                    qualityControlOptions={qualityControlOptions}
                    buyerOptions={buyerOptions}
                    isFetching={isFetching}
                    isCurrentUserInternal={isCurrentUserInternal}
                    hasCurrentUserChain={!!currentUserChainName}
                    isEciUser={this.isEciUser()}
                    onReportNumberChange={(reportNumber) => this.onFilterReportNumberChange(reportNumber)}
                    onStatusChange={(status) => this.onFilterStatusChange(status)}
                    onReferenceChange={(reference) => this.onFilterReferenceChange(reference)}
                    onRequerimentStandardChange={(requerimentStandard) => this.onFilterRequerimentStandardChange(requerimentStandard)}
                    onEntryDateChange={(entryDate) => this.onFilterEntryDateChange(entryDate)}
                    onExpectedDateChange={(expectedDate) => this.onFilterExpectedDateChange(expectedDate)}
                    onEndDateChange={(endDate) => this.onFilterEndDateChange(endDate)}
                    onExpirationDateChange={(expirationDate) => this.onFilterExpirationDateChange(expirationDate)}
                    onOfferNumberChange={(offerNumber) => this.onFilterOfferNumberChange(offerNumber)}
                    onClientChange={(client) => this.onFilterClientChange(client)}
                    onCollectionChange={(collection) => this.onFilterCollectionChange(collection)}
                    onColourChange={(colour) => this.onFilterColourChange(colour)}
                    onTargetChange={(target) => this.onFilterTargetChange(target)}
                    onModelChange={(model) => this.onFilterModelChange(model)}
                    onIncidenceChange={(incidence) => this.onFilterIncidenceChange(incidence)}
                    onEciOrderNumberChange={(eciOrderNumber) => this.onFilterEciOrderNumberChange(eciOrderNumber)}
                    onUnecoChange={(uneco) => this.onFilterUnecoChange(uneco)}
                    onSupplierChange={(supplier) => this.onFilterSupplierChange(supplier)}
                    onClosedChange={(closed) => this.onFilterClosedChange(closed)}
                    onCopyChange={(copy) => this.onFilterCopyChange(copy)}
                    onAitexResultIdChange={(aitexResultId) => this.onFilterAitexResultIdChange(aitexResultId)}
                    onCountryIdChange={(countryId) => this.onFilterCountryIdChange(countryId)}
                    onSeasonIdChange={(seasonId) => this.onFilterSeasonIdChange(seasonId)}
                    onOfficeIdChange={(officeId) => this.onFilterOfficeIdChange(officeId)}
                    onDivisionIdChange={(divisionId) => this.onFilterDivisionIdsChange(divisionId)}
                    onSectionIdChange={(sectionId) => this.onFilterSectionIdChange(sectionId)}
                    onBrandIdChange={(brandId) => this.onFilterBrandIdChange(brandId)}
                    onQualityControlIdChange={(qualityControlId) => this.onFilterQualityControlIdChange(qualityControlId)}
                    onBuyerIdChange={(buyerId) => this.onFilterBuyerIdChange(buyerId)}
                    onFilterButtonClick={() => this.onFilterButtonClick()}
                  />
                </Tab>
                {this.shouldShowAdvancedFilterTab() && <Tab eventKey={FilterMode.ADVANCED} title={i18n.t('enums.filterMode.ADVANCED')}>
                  <ReportsListAdvancedFilter
                    advancedFilter={advancedFilter}
                    advancedFilterColumns={advancedFilterColumns}
                    isFetching={isFetching}
                    isCurrentUserInternal={isCurrentUserInternal}
                    onSaveButtonClick={(rule) => this.onAdvancedFilterSaveButtonClick(rule)}
                    onApplyButtonClick={() => this.onAdvancedFilterApplyButtonClick()}
                    onShareButtonClick={(rule) => this.onAdvancedFilterShareButtonClick(rule)}
                    onClearButtonClick={() => this.onAdvancedFilterClearButtonClick()}
                    onFilterButtonClick={(rule) => this.onAdvancedFilterFilterButtonClick(rule)}
                    onContextualHelpClick={() => this.onAdvancedFilterContextualHelpClick()}
                  />
                </Tab>}
              </Tabs>
            </div>}
            <ReportsGrid
               reports={reports}
               listMode={mode}
               reportCustomFieldSettings={reportCustomFieldSettings}
               isFetching={isFetching}
               searchDate={searchDate}
               currentPage={currentPage}
               pageSize={pageSize}
               totalCount={totalCount}
               sortField={sortField}
               sortDirection={sortDirection}
               selectedReportsIds={selectedReportsIds}
               isCurrentUserInternal={isCurrentUserInternal}
               currentUserEmail={currentUserEmail}
               currentUserId={currentUserId}
               hasCurrentUserChain={!!currentUserChainName}
               isEciUser={this.isEciUser()}
               gridRef={(grid) => this.reportsGridComponent = grid}
               onCurrentPageChange={(cp) => this.onCurrentPageChange(cp)}
               onPageSizeChange={(ps) => this.onPageSizeChange(ps)}
               onSortingChange={(field, direction) => this.onSortingChange(field, direction)}
               onVersionClick={(report) => this.onVersionClick(report)}
               onDownloadButtonClick={(report) => this.downloadReport(report)}
               onDownloadMultipleButtonClick={() => this.downloadMultipleReports()}
               onSelectedReportsIdsChange={(newSelectedReportsIds) => setSelectedReportsIds(newSelectedReportsIds)}
               onReportNumberClick={() => this.removeEciMode()}
               onSaveColumnsButtonClick={(userSetting: IUserSetting) => this.saveColumnsSettings(userSetting)}
               onCleanColumnsButtonClick={(key: string) => this.cleanColumnsSettings(key)}
             />
          </div>
        </div>
      </div>
    );
  }

  private removeEciMode() {
    const { mode, setEciMode } = this.props;
    if (mode === 'eci') {
      setEciMode('other');
    }
  }

  private async saveColumnsSettings(userSetting: IUserSetting) {
    try {
      const info = await userSettingsService.refreshUserSetting(userSetting);

      if (info) {
        this.props.saveUserSetting(info);
        notifySuccess('La configuración personalizada se ha guardado correctamente.');
      } else {
        notifyError('');
      }
    } catch (error: any) {
      let newErrorMessage = 'errorMessage';
      if (error && error['UnknownError']) {
        const valueError = error['UnknownError'];
        if (Array.isArray(valueError)) {
          newErrorMessage = valueError.join(' ');
        } else {
          newErrorMessage = valueError;
        }
      }

      notifyError(newErrorMessage);
    }
  }

  private async cleanColumnsSettings(key: string) {
    const { currentUserId } = this.props;

    try {
      gridUtils.cleanColumnsGridInLocalStorage(currentUserId, this.reportsGridComponent);
      const info = await userSettingsService.getUserSetting(currentUserId, key);

      if (info !== undefined && info != null && (info as any) != '') {
        this.props.saveUserSetting(info);
        const result = JSON.parse(info.jsonValue) as IOrderColumn[];
        gridUtils.saveColumnsGridInLocalStorage(currentUserId, this.reportsGridComponent, result);
      }
    } catch (error: any) {
      let newErrorMessage = 'errorMessage';
      if (error && error['UnknownError']) {
        const valueError = error['UnknownError'];
        if (Array.isArray(valueError)) {
          newErrorMessage = valueError.join(' ');
        } else {
          newErrorMessage = valueError;
        }
      }

      notifyError(newErrorMessage);
    }
  }

  private init() {
    const { isFetching, fetchReportCustomFieldSettings, fetchAdvancedFilterColumns } = this.props;

    if (!this.areReportCustomFieldSettingsLoaded()) {
      if (!isFetching) {
        fetchReportCustomFieldSettings(companiesService, reportsService);
      }
      return;
    }

    if (!this.areAdvancedFilterColumnsLoaded()) {
      if (!isFetching) {
        fetchAdvancedFilterColumns(advancedFiltersService);
      }
      return;
    }

    this.manageReportsListMode();
  }

  private manageReportsListMode() {
    const { mode, eciMode, isChangingMode, setMode, setEciMode, setIsChangingMode, setFilterStatus, setSorting, fetchAdvancedFilterByShareId, history, location } = this.props;

    const currentReportsListMode = this.getReportsListMode();
    const currentReportsListEciMode = this.getReportsListEciMode();
    if (currentReportsListMode !== mode || currentReportsListEciMode !== eciMode) {
      if (
        (
          currentReportsListMode !== ReportsListMode.ALL
          && currentReportsListMode !== ReportsListMode.IN_PROGRESS
          && currentReportsListMode !== ReportsListMode.FINALIZED
          && currentReportsListMode !== ReportsListMode.ECI
        )
        || (
          currentReportsListMode === ReportsListMode.ECI
          && (!currentReportsListEciMode || !this.isEciUser())
        )
      ) {
        history.push(`/reports-list/${ReportsListMode.IN_PROGRESS}`);
        return;
      }

      if (mode === null && currentReportsListMode === ReportsListMode.ALL) {
        const parsedUrl = queryString.parse(location.search);
        if (parsedUrl.advancedFilterShareId) {
          fetchAdvancedFilterByShareId(parsedUrl.advancedFilterShareId as string, advancedFiltersService);
        }
      }

      if (!isChangingMode) {
        setIsChangingMode(true);
      }

      setTimeout(() => {
        if (currentReportsListMode !== mode) {
          setMode(currentReportsListMode);

          if (currentReportsListMode === ReportsListMode.ALL || currentReportsListMode === ReportsListMode.IN_PROGRESS || currentReportsListMode === ReportsListMode.FINALIZED) {
            setFilterStatus(currentReportsListMode);
          }

          if (currentReportsListMode === ReportsListMode.IN_PROGRESS) {
            if (!this.isEciUser()) {
              setSorting(reportsListStore.Type.SET_SORTING, 'expectedDate', 'Ascending');
            } else {
              setSorting(reportsListStore.Type.SET_SORTING, 'reportNumber', 'Descending');
            }
          } else if (currentReportsListMode === ReportsListMode.FINALIZED) {
            setSorting(reportsListStore.Type.SET_SORTING, 'endDate', 'Descending');
          } else {
            setSorting(reportsListStore.Type.SET_SORTING, 'reportNumber', 'Descending');
          }
        }

        if (currentReportsListEciMode !== eciMode) {
          setEciMode(currentReportsListEciMode);
        }

        if (!this.props.isCurrentUserInternal && currentReportsListMode !== ReportsListMode.ALL) {
          setTimeout(() => this.onFilterButtonClick(), 250);
        }
      }, 100);
    } else {
      if (isChangingMode) {
        setIsChangingMode(false);
      }
    }
  }

  private getReportsListMode(): string {
    return this.props.match.params.mode;
  }

  private getReportsListEciMode(): string {
    return this.getReportsListMode() === ReportsListMode.ECI ? this.props.match.params.eciMode : null;
  }

  private fetchReports() {
    const { isFetching, fetchReports } = this.props;

    if (!isFetching) {
      fetchReports(queryBuilderService, oDataService, this.reportsDataManager);
    }
  }

  private checkIsSimpleFilterValid(): boolean {
    /*const { filter, isCurrentUserInternal, isCurrentUserAdmin, i18n } = this.props;

    if (isCurrentUserInternal && !isCurrentUserAdmin && !filter.client) {
      notifyError(i18n.t('home.filters.errorByClient'));
      return false;
    }*/

    return true;
  }

  private checkIsRuleValid(rule: IMyRule): boolean {
    const isRuleValid = queryBuilderService.isRuleValid(rule);

    if (!isRuleValid) {
      notifyError(this.props.i18n.t('advancedFilterComponent.errors.invalidFilter'));
    }

    return isRuleValid;
  }

  private areReportCustomFieldSettingsLoaded(): boolean {
    return this.props.isCurrentUserInternal || this.props.areReportCustomFieldSettingsLoaded;
  }

  private areAdvancedFilterColumnsLoaded(): boolean {
    return this.props.areAdvancedFilterColumnsLoaded;
  }

  private manageDialogDismissed(reason?: IErrors) {
    if (reason) {
      if (reason.ControlledError) {
        notifyError(reason.ControlledError[0]);
      }
      if (reason.UnknownError) {
        notifyError(this.props.i18n.t(reason.UnknownError[0]));
      }
    }
  }
}

const mapStateToProps = (state: RootState): IReportsListContainerStateProps => ({
  reports: state.reportsListStore.reports,
  mode: state.reportsListStore.mode,
  eciMode: state.reportsListStore.eciMode,
  isChangingMode: state.reportsListStore.isChangingMode,
  filterMode: state.reportsListStore.filterMode,
  filter: state.reportsListStore.filter,
  advancedFilter: state.reportsListStore.advancedFilter,
  reportCustomFieldSettings: state.reportsListStore.reportCustomFieldSettings,
  areReportCustomFieldSettingsLoaded: state.reportsListStore.areReportCustomFieldSettingsLoaded,
  aitexResultOptions: state.reportsListStore.aitexResultOptions,
  countryOptions: state.reportsListStore.countryOptions,
  seasonOptions: state.reportsListStore.seasonOptions,
  officeOptions: state.reportsListStore.officeOptions,
  divisionOptions: state.reportsListStore.divisionOptions,
  sectionOptions: state.reportsListStore.sectionOptions,
  brandOptions: state.reportsListStore.brandOptions,
  qualityControlOptions: state.reportsListStore.qualityControlOptions,
  buyerOptions: state.reportsListStore.buyerOptions,
  advancedFilterColumns: state.reportsListStore.advancedFilterColumns,
  areAdvancedFilterColumnsLoaded: state.reportsListStore.areAdvancedFilterColumnsLoaded,
  isFetching: state.reportsListStore.grid.isFetching,
  searchDate: state.reportsListStore.grid.searchDate,
  currentPage: state.reportsListStore.grid.currentPage,
  pageSize: state.reportsListStore.grid.pageSize,
  totalCount: state.reportsListStore.grid.totalCount,
  sortField: state.reportsListStore.grid.sortField,
  sortDirection: state.reportsListStore.grid.sortDirection,
  selectedReportsIds: state.reportsListStore.selectedReportsIds,
  isCurrentUserInternal: state.currentUserStore.user.type === UserType.INTERNAL,
  isCurrentUserAdmin: rolesService.hasAnyRole(state.currentUserStore.jwtInfo, [RoleNormalizedName.ADMIN]),
  currentUserCompany: state.currentUserStore.company,
  currentUserChainName: currentUserUtils.getCurrentUserChainName(state.currentUserStore.user, state.currentUserStore.jwtInfo),
  currentUserEmail: state.currentUserStore.user.email,
  currentUserId: state.currentUserStore.user.id
});

const mapDispatchToProps: IReportsListContainerDispatchProps = {
  reset: reportsListStore.reset,
  resetHard: reportsListStore.resetHard,
  setMode: reportsListStore.setMode,
  setEciMode: reportsListStore.setEciMode,
  setIsChangingMode: reportsListStore.setIsChangingMode,
  setFilterMode: reportsListStore.setFilterMode,
  setFilterReportNumber: reportsListStore.setFilterReportNumber,
  setFilterStatus: reportsListStore.setFilterStatus,
  setFilterReference: reportsListStore.setFilterReference,
  setFilterRequerimentStandard: reportsListStore.setFilterRequerimentStandard,
  setFilterEntryDate: reportsListStore.setFilterEntryDate,
  setFilterExpectedDate: reportsListStore.setFilterExpectedDate,
  setFilterEndDate: reportsListStore.setFilterEndDate,
  setFilterExpirationDate: reportsListStore.setFilterExpirationDate,
  setFilterOfferNumber: reportsListStore.setFilterOfferNumber,
  setFilterClient: reportsListStore.setFilterClient,
  setFilterCollection: reportsListStore.setFilterCollection,
  setFilterColour: reportsListStore.setFilterColour,
  setFilterTarget: reportsListStore.setFilterTarget,
  setFilterModel: reportsListStore.setFilterModel,
  setFilterIncidence: reportsListStore.setFilterIncidence,
  setFilterEciOrderNumber: reportsListStore.setFilterEciOrderNumber,
  setFilterUneco: reportsListStore.setFilterUneco,
  setFilterSupplier: reportsListStore.setFilterSupplier,
  setFilterClosed: reportsListStore.setFilterClosed,
  setFilterCopy: reportsListStore.setFilterCopy,
  setFilterAitexResultId: reportsListStore.setFilterAitexResultId,
  setFilterCountryId: reportsListStore.setFilterCountryId,
  setFilterSeasonId: reportsListStore.setFilterSeasonId,
  setFilterOfficeId: reportsListStore.setFilterOfficeId,
  setFilterDivisionId: reportsListStore.setFilterDivisionId,
  setFilterSectionId: reportsListStore.setFilterSectionId,
  setFilterBrandId: reportsListStore.setFilterBrandId,
  setFilterQualityControlId: reportsListStore.setFilterQualityControlId,
  setFilterBuyerId: reportsListStore.setFilterBuyerId,
  setAdvancedFilter: reportsListStore.setAdvancedFilter,
  resetAdvancedFilter: reportsListStore.resetAdvancedFilter,
  setAdvancedFilterRule: reportsListStore.setAdvancedFilterRule,
  setCurrentPage: listGridStore.setCurrentPage,
  setPageSize: listGridStore.setPageSize,
  setSorting: listGridStore.setSorting,
  setSelectedReportsIds: reportsListStore.setSelectedReportsIds,
  fetchReports: reportsListStore.fetchReports,
  fetchReportCustomFieldSettings: reportsListStore.fetchReportCustomFieldSettings,
  fetchAdvancedFilterColumns: reportsListStore.fetchAdvancedFilterColumns,
  fetchAdvancedFilterByShareId: reportsListStore.fetchAdvancedFilterByShareId,
  setContextualHelpDialogCode: dialogsStore.setContextualHelpDialogCode,
  prepareReportVersionsDialog: dialogsStore.prepareReportVersionsDialog,
  saveUserSetting: userSettingStore.saveUserSetting
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default compose(
  withRouter,
  withTranslation(),
  connector
)(ReportsListContainer) as ComponentType;
