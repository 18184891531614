import { Action, Reducer, ActionCreator, combineReducers } from 'redux';

import { IMenuState } from '../../interfaces/states/IMenuState';

enum Type {
  SET_VISIBLE = '@@menu/SET_VISIBLE'
}

const initialState: IMenuState = {
  visible: false
};

// Actions

export interface SetVisibleAction extends Action<Type.SET_VISIBLE> {
  visible: boolean;
}

export const setVisible: ActionCreator<SetVisibleAction> = (visibleLocal: boolean) => ({
  type: Type.SET_VISIBLE,
  visible: visibleLocal
});

// Reducers

const visible: Reducer<boolean> = (state = initialState.visible, action) => {
  if (action.type == Type.SET_VISIBLE) {
    return (action as SetVisibleAction).visible;
  }
  return state;
};

export const menuStore = combineReducers({ visible });
