import { Action, Reducer, ActionCreator, combineReducers } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { DataManager, Query } from '@syncfusion/ej2-data';
import moment from 'moment';

import { RootState } from '..';
import * as listGridStore from './listGrid.store';
import { IClientsListState, IClientsListFilterState } from '../../interfaces/states/IClientsListState';
import { ICompany } from '../../common/model/company.model';
import { IODataService } from '../../services';

export enum Type {
  RESET = '@@clientsList/RESET',
  SET_CLIENTS = '@@clientsList/SET_CLIENTS',
  SET_FILTER = '@@clientsList/SET_FILTER',
  SET_FILTER_CODE = '@@clientsList/SET_FILTER_CODE',
  SET_FILTER_NAME = '@@clientsList/SET_FILTER_NAME',
  SET_IS_FETCHING = '@@clientsList/SET_IS_FETCHING',
  SET_SEARCH_DATE = '@@clientsList/SET_SEARCH_DATE',
  SET_CURRENT_PAGE = '@@clientsList/SET_CURRENT_PAGE',
  SET_PAGE_SIZE = '@@clientsList/SET_PAGE_SIZE',
  SET_TOTAL_COUNT = '@@clientsList/SET_TOTAL_COUNT',
  SET_SORTING = '@@clientsList/SET_SORTING'
}

const initialState: IClientsListState = {
  clients: [],
  filter: {
    code: null,
    name: null
  },
  grid: listGridStore.initialState
};

// Actions

export type Actions = ResetAction | SetClientsAction | SetFilterAction | SetFilterCodeAction | SetFilterNameAction | listGridStore.SetIsFetchingAction | listGridStore.SetSearchDateAction
  | listGridStore.SetCurrentPageAction | listGridStore.SetPageSizeAction | listGridStore.SetTotalCountAction | listGridStore.SetSortingAction;

export type ResetAction = Action<Type.RESET>

export interface SetClientsAction extends Action<Type.SET_CLIENTS> {
  clients: ICompany[];
}

export interface SetFilterAction extends Action<Type.SET_FILTER> {
  filter: IClientsListFilterState;
}

export interface SetFilterCodeAction extends Action<Type.SET_FILTER_CODE> {
  code: string;
}

export interface SetFilterNameAction extends Action<Type.SET_FILTER_NAME> {
  name: string;
}

export const reset: ActionCreator<ResetAction> = () => ({
  type: Type.RESET
});

export const setClients: ActionCreator<SetClientsAction> = (clientsLocal: ICompany[]) => ({
  type: Type.SET_CLIENTS,
  clients: clientsLocal
});

export const setFilter: ActionCreator<SetFilterAction> = (filterLocal: IClientsListFilterState) => ({
  type: Type.SET_FILTER,
  filter: filterLocal
});

export const setFilterCode: ActionCreator<SetFilterCodeAction> = (code: string) => ({
  type: Type.SET_FILTER_CODE,
  code
});

export const setFilterName: ActionCreator<SetFilterNameAction> = (name: string) => ({
  type: Type.SET_FILTER_NAME,
  name
});

export const fetchClients: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (
  oDataService: IODataService,
  clientsDataManager: DataManager,
  clientsQuery: Query
) => (
    async (dispatch, getState) => {
      dispatch(listGridStore.setIsFetching(Type.SET_IS_FETCHING, true));

      const currentPageLocal = getState().clientsListStore.grid.currentPage;
      const pageSizeLocal = getState().clientsListStore.grid.pageSize;
      const sortFieldLocal = getState().clientsListStore.grid.sortField;
      const sortDirectionLocal = getState().clientsListStore.grid.sortDirection;

      let query = clientsQuery.page(currentPageLocal, pageSizeLocal);
      if (sortFieldLocal && sortDirectionLocal) {
        query = sortDirectionLocal === 'Ascending' ? query.sortBy(sortFieldLocal) : query.sortByDesc(sortFieldLocal);
      }
      const clientsResponse = await oDataService.executeQueryWithCount<ICompany>(clientsDataManager, query);
      const clientsLocal = clientsResponse.result;
      dispatch(setClients(clientsLocal));
      dispatch(listGridStore.setSearchDate(Type.SET_SEARCH_DATE, moment()));
      dispatch(listGridStore.setTotalCount(Type.SET_TOTAL_COUNT, clientsResponse.count));
      dispatch(listGridStore.setIsFetching(Type.SET_IS_FETCHING, false));
    }
  );

// Reducers

const clients: Reducer<ICompany[]> = (state = initialState.clients, action) => {
  switch (action.type) {
    case Type.RESET:
      return state;
    case Type.SET_CLIENTS:
      return (action as SetClientsAction).clients;
    default:
      return state;
  }
};

const filter: Reducer<IClientsListFilterState> = (state = initialState.filter, action) => {
  switch (action.type) {
    case Type.RESET:
      return state;
    case Type.SET_FILTER:
      return (action as SetFilterAction).filter;
    case Type.SET_FILTER_CODE:
      return { ...state, code: (action as SetFilterCodeAction).code };
    case Type.SET_FILTER_NAME:
      return { ...state, name: (action as SetFilterNameAction).name };
    default:
      return state;
  }
};

export const clientsListStore = combineReducers({
  clients,
  filter,
  grid: listGridStore.createListGridStore(Type, 'name', 'Ascending')
 });
