import { GridComponent, RowDataBoundEventArgs, SortDirection, SortEventArgs, Column, ColumnModel } from '@syncfusion/ej2-react-grids';
import { IOrderColumn } from '../interfaces/IOrderColumn';
import { getItem, saveItem, removeItem } from './localStorageManagement'

export const gridLocalStorageKeySeparator = '::';

export const manageSpinner = (gridComponent: GridComponent, prevIsFetching: boolean, currentIsFetching: boolean, prevVisible?: boolean, currentVisible?: boolean): void => {
  if (!prevIsFetching && currentIsFetching) {
    setTimeout(() => gridComponent && gridComponent.showSpinner());
  }
  if ((prevIsFetching && !currentIsFetching) || (prevVisible && !currentVisible)) {
    setTimeout(() => gridComponent && gridComponent.hideSpinner());
  }
};

export const manageSorting = (gridComponent: GridComponent, prevIsFetching: boolean, currentIsFetching: boolean, sortField: string, sortDirection: SortDirection): void => {
  if (prevIsFetching && !currentIsFetching) {
    if (!sortField || !sortDirection) {
      setTimeout(() => gridComponent && gridComponent.clearSorting());
    } else {
      gridComponent && gridComponent.sortColumn(sortField, sortDirection);
    }
  }
};

export const manageSortingChange = (
  args: SortEventArgs,
  sortField: string,
  sortDirection: SortDirection,
  onSortingChange: (field: string, direction: SortDirection) => void
): void => {
  if (
    (args.columnName && (args.columnName !== sortField))
    || (args.direction && (args.direction !== sortDirection))
    || (!args.columnName && sortField)
    || (!args.direction && sortDirection)
  ) {
    setTimeout(() => onSortingChange(args.columnName || null, args.direction || null));
    args.cancel = true;
  }
}

export const manageDataSource = (
  gridComponent: GridComponent,
  updateDataSourceCallback: () => void,
  prevIsFetching: boolean,
  currentIsFetching: boolean,
  prevVisible?: boolean,
  currentVisible?: boolean
): void => {
  if (gridComponent) {
    if ((prevIsFetching && !currentIsFetching) || (prevVisible && !currentVisible)) {
      updateDataSourceCallback();
    }
  }
}

export const manageDetailRowExpansion = (gridComponent: GridComponent, args: RowDataBoundEventArgs, fieldNotFalsy: boolean, expandAutomatically: boolean): void => {
  if (fieldNotFalsy) {
    if (expandAutomatically) {
      setTimeout(() => {
        if (gridComponent && gridComponent.detailRowModule) {
          gridComponent.detailRowModule.expand(+args.row.getAttribute('aria-rowindex'));
        }
      }, 500);
    }
  } else {
    args.row.querySelector('td').innerHTML = '';
    args.row.querySelector('td').className = 'e-customizedExpandcell';
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const mapItemToLocalStorage = (item: any, index: number): IOrderColumn => {
  return {
    orden: item.orden || index,
    field: item.field || item.columnName || item,
    headerText: item.headerText,
    width: parseFloat(item.width.toString().replace(/px/g, '')),
    visible: item.visible
  };
}


// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const mapToLocalStorage = (data: any): IOrderColumn[] => {
  let result: IOrderColumn[] = [];

  if (data && Array.isArray(data)) {
    result = data.map((item, index) => {
      return mapItemToLocalStorage(item, index);
    });
  }

  return result;
}

export const getKeyToLocalStorage = (currentUserId: string, gridComponent: GridComponent): string => {
  if (gridComponent !== undefined && gridComponent != null) {
    const gridName = (gridComponent as any).id;
    return currentUserId + gridLocalStorageKeySeparator + getPathNameWithoutParameters() + gridLocalStorageKeySeparator + gridName;
  }

  return getPathNameWithoutParameters();
}

const getPathNameWithoutParameters = (): string => {
  const currentPathName = window.location.pathname;

  const lastIndexOf = currentPathName.lastIndexOf('/');
  if (lastIndexOf != -1 && lastIndexOf != 0) {
    return window.location.pathname.substring(0, lastIndexOf);
  }

  return currentPathName;
}

export const saveColumnsGridInLocalStorage = (currentUserId: string, gridComponent: GridComponent, columns?: IOrderColumn[]): void => {
  const columnInfo = columns ?? mapToLocalStorage(gridComponent.columns);

  saveItem(getKeyToLocalStorage(currentUserId, gridComponent), columnInfo);
}

export const getColumnsGridFromLocalStorage = (currentUserId: string, gridComponent: GridComponent): IOrderColumn[] => {
  const item = getItem(getKeyToLocalStorage(currentUserId, gridComponent));

  if (item) {
    return item;
  }

  return [];
}

export const cleanColumnsGridInLocalStorage = (currentUserId: string, gridComponent: GridComponent): void => {
  removeItem(getKeyToLocalStorage(currentUserId, gridComponent));
}

export const orderColumns = (currentUserId: string, gridComponent: GridComponent, columns?: IOrderColumn[]): void => {
  const orderColumns = columns ?? getColumnsGridFromLocalStorage(currentUserId, gridComponent);

  if (gridComponent && gridComponent.columns && orderColumns && Array.isArray(orderColumns)) {
    const internalColumns: Column[] = [];
    gridComponent.columns.forEach((column: Column | string | ColumnModel) => {
      if (typeof column !== 'string' && column.customAttributes && column.customAttributes['data']) {
        const data: any = column.customAttributes['data'];
        if (data && data['isInternalColumn'] === true) {
          internalColumns.push(column as Column);
        }
      }
    });

    for (let i = 0; i < orderColumns.length; i++) {
      const isInternalColumn = internalColumns.filter((item: any) => {
        return orderColumns && (item.field === orderColumns[i] || item.field == (orderColumns[i] as IOrderColumn).field);
      });

      const element = orderColumns[i] as IOrderColumn;
      let currentOrden = element.orden;
      if (currentOrden < internalColumns.length && isInternalColumn.length == 0) {
        currentOrden += (internalColumns.length - 1);
      }

      gridComponent.reorderColumnByTargetIndex(element.field, currentOrden);
    }
  }
}

export const setWidthToColumns = (currentUserId: string, gridComponent: GridComponent, columns?: IOrderColumn[]): void => {
  const widthColumns = columns ?? getColumnsGridFromLocalStorage(currentUserId, gridComponent);

  if (gridComponent && widthColumns) {
    gridComponent.columns.forEach(column => {
      const currentColumn = column as Column;
      const result = widthColumns.find(item => item.field == currentColumn.field);
      if (result) {
        currentColumn.width = result.width;
      }
    });
  }
}
