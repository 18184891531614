import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { DropDownListComponent, ChangeEventArgs, FieldSettingsModel } from '@syncfusion/ej2-react-dropdowns';

import { ISuppliersListFilterProps } from '../../../interfaces/props/ISuppliersListProps';
import { IStringToAnyDictionary } from '../../../common/model/stringToAnyDictionary.model';
import { BooleanFilter } from '../../../common/model/enumerations/booleanFilter.model';
import { enumToArray } from '../../../utils/enum.utils';
import TextBox from '../../../components/filters/textBox';

type SuppliersListFilterPropsType = ISuppliersListFilterProps & WithTranslation;

class SuppliersListFilter extends Component<SuppliersListFilterPropsType> {
  public activeOptions: IStringToAnyDictionary[] = [];
  public activeFields: FieldSettingsModel = { text: 'text', value: 'value' };

  public constructor(props: SuppliersListFilterPropsType) {
    super(props);

    const { i18n } = this.props;

    this.activeOptions = enumToArray(BooleanFilter, 'number', 'text', 'value').map((item) => ({ ...item, text: i18n.t('enums.booleanFilter.' + item.text) }));
  }

  public onActiveChange(e: ChangeEventArgs) {
    const active = e.value as BooleanFilter;
    this.props.onActiveChange(active);
  }

  public render() {
    const {
      filter,
      isFetching,
      onCodeChange,
      onNameChange,
      onFilterButtonClick,
      i18n
    } = this.props;

    return (
      <form onSubmit={(e: React.FormEvent) => { e.preventDefault(); onFilterButtonClick(); }}>
        <div className='row'>
          <div className='col-12 col-md-6'>
            <div className='form-group'>
              <div className='autocomplete-input'>
                <div className='autocomplete-label'>{i18n.t('supplier.code')}</div>
                <TextBox
                  name='code'
                  value={filter.code}
                  showClearButton={true}
                  onChange={(value) => onCodeChange(value)}
                />
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <div className='form-group'>
              <div className='autocomplete-input'>
                <div className='autocomplete-label'>{i18n.t('supplier.name')}</div>
                <TextBox
                  name='name'
                  value={filter.name}
                  showClearButton={true}
                  onChange={(value) => onNameChange(value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-12 col-md-6'>
            <div className='form-group'>
              <div className='autocomplete-input'>
                <div className='autocomplete-label'>{i18n.t('supplier.active')}</div>
                <DropDownListComponent
                  fields={this.activeFields}
                  dataSource={this.activeOptions}
                  value={filter.active}
                  change={(e) => this.onActiveChange(e)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='row justify-content-end'>
          <div className='col-auto'>
            <button type='submit' className='btn btn-secondary' title={i18n.t('actions.filter')} disabled={isFetching}>
              {isFetching ?
                <span className='spinner-border spinner-border-sm' />
                :
                <span className='icon icon-app-item-search' />
              }
            </button>
          </div>
        </div>
      </form>
    );
  }
}

export default withTranslation()(SuppliersListFilter);
