import { Action, Reducer, ActionCreator, combineReducers } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { batch } from 'react-redux';
import { DataManager, Query } from '@syncfusion/ej2-data';
import moment from 'moment';

import { RootState } from '..';
import * as listGridStore from './listGrid.store';
import { IReportsListState, IReportsListFilterState } from '../../interfaces/states/IReportsListState';
import { DATE_MOMENT_FORMAT } from '../../common/constants/advancedFilter.constants';
import { IReportWithFlattenedCustomFields } from '../../common/model/report.model';
import { IAdvancedFilter } from '../../common/model/advancedFilter.model';
import { IReportCustomFieldSettingsWithCheckboxes } from '../../common/model/customFieldSettings.model';
import { ICustomFieldOptions } from '../../common/model/customFieldOptions.model';
import { IAdvancedFilterColumn } from '../../common/model/advancedFilterColumn.model';
import { IMyRule } from '../../common/model/myRule.model';
import { ReportsListEciMode } from '../../common/model/enumerations/reportsListEciMode.model';
import { FilterMode } from '../../common/model/enumerations/filterMode.model';
import { ReportStatus } from '../../common/model/enumerations/reportStatus.model';
import { AdvancedFilterType } from '../../common/model/enumerations/advancedFilterType.model';
import { RuleCondition } from '../../common/model/enumerations/ruleCondition.model';
import { RuleOperator } from '../../common/model/enumerations/ruleOperator.model';
import { BooleanFilter } from '../../common/model/enumerations/booleanFilter.model';
import { SourceType } from '../../common/model/enumerations/sourceType.model';
import { IQueryBuilderService, IODataService, IAdvancedFiltersService, ICompaniesService, IReportsService } from '../../services';
import { ReportCustomFieldQualityControl } from '../../common/model/enumerations/reportCustomFieldQualityControl.model';
import { ReportCustomFieldBuyer } from '../../common/model/enumerations/reportCustomFieldBuyer.model';
import * as reportUtils from '../../utils/report.utils';
import * as reportCustomFieldSettingsUtils from '../../utils/reportCustomFieldSettings.utils';
import * as dateHelpers from '../../helpers/date.helpers';

export enum Type {
  RESET = '@@reportsList/RESET',
  RESET_HARD = '@@reportsList/RESET_HARD',
  SET_REPORTS = '@@reportsList/SET_REPORTS',
  SET_MODE = '@@reportsList/SET_MODE',
  SET_ECI_MODE = '@@reportsList/SET_ECI_MODE',
  SET_IS_CHANGING_MODE = '@@reportsList/SET_IS_CHANGING_MODE',
  SET_FILTER_MODE = '@@reportsList/SET_FILTER_MODE',
  SET_FILTER = '@@reportsList/SET_FILTER',
  SET_FILTER_REPORT_NUMBER = '@@reportsList/SET_FILTER_REPORT_NUMBER',
  SET_FILTER_STATUS = '@@reportsList/SET_FILTER_STATUS',
  SET_FILTER_REFERENCE = '@@reportsList/SET_FILTER_REFERENCE',
  SET_FILTER_REQUIREMENT_STANDARD = '@@reportsList/SET_FILTER_REQUIREMENT_STANDARD',
  SET_FILTER_ENTRY_DATE = '@@reportsList/SET_FILTER_ENTRY_DATE',
  SET_FILTER_EXPECTED_DATE = '@@reportsList/SET_FILTER_EXPECTED_DATE',
  SET_FILTER_END_DATE = '@@reportsList/SET_FILTER_END_DATE',
  SET_FILTER_EXPIRATION_DATE = '@@reportsList/SET_FILTER_EXPIRATION_DATE',
  SET_FILTER_OFFER_NUMBER = '@@reportsList/SET_FILTER_OFFER_NUMBER',
  SET_FILTER_SERIES_CODE = '@@reportsList/SET_FILTER_SERIES_CODE',
  SET_FILTER_CLIENT = '@@reportsList/SET_FILTER_CLIENT',
  SET_FILTER_COLLECTION = '@@reportsList/SET_FILTER_COLLECTION',
  SET_FILTER_COLOUR = '@@reportsList/SET_FILTER_COLOUR',
  SET_FILTER_TARGET = '@@reportsList/SET_FILTER_TARGET',
  SET_FILTER_MODEL = '@@reportsList/SET_FILTER_MODEL',
  SET_FILTER_INCIDENCE = '@@reportsList/SET_FILTER_INCIDENCE',
  SET_FILTER_ECI_ORDER_NUMBER = '@@reportsList/SET_FILTER_ECI_ORDER_NUMBER',
  SET_FILTER_UNECO = '@@reportsList/SET_FILTER_UNECO',
  SET_FILTER_SUPPLIER = '@@reportsList/SET_FILTER_SUPPLIER',
  SET_FILTER_CLOSED = '@@reportsList/SET_FILTER_CLOSED',
  SET_FILTER_COPY = '@@reportsList/SET_FILTER_COPY',
  SET_FILTER_AITEX_RESULT_ID = '@@reportsList/SET_FILTER_AITEX_RESULT_ID',
  SET_FILTER_COUNTRY_ID = '@@reportsList/SET_FILTER_COUNTRY_ID',
  SET_FILTER_SEASON_ID = '@@reportsList/SET_FILTER_SEASON_ID',
  SET_FILTER_OFFICE_ID = '@@reportsList/SET_FILTER_OFFICE_ID',
  SET_FILTER_DIVISION_ID = '@@reportsList/SET_FILTER_DIVISION_ID',
  SET_FILTER_SECTION_ID = '@@reportsList/SET_FILTER_SECTION_ID',
  SET_FILTER_BRAND_ID = '@@reportsList/SET_FILTER_BRAND_ID',
  SET_FILTER_QUALITY_CONTROL_ID = '@@reportsList/SET_FILTER_QUALITY_CONTROL_ID',
  SET_FILTER_BUYER_ID = '@@reportsList/SET_FILTER_BUYER_ID',
  SET_ADVANCED_FILTER = '@@reportsList/SET_ADVANCED_FILTER',
  RESET_ADVANCED_FILTER = '@@reportsList/RESET_ADVANCED_FILTER',
  SET_ADVANCED_FILTER_RULE = '@@reportsList/SET_ADVANCED_FILTER_RULE',
  SET_REPORT_CUSTOM_FIELD_SETTINGS = '@@reportsList/SET_REPORT_CUSTOM_FIELD_SETTINGS',
  SET_ARE_REPORT_CUSTOM_FIELD_SETTINGS_LOADED = '@@reportsList/SET_ARE_REPORT_CUSTOM_FIELD_SETTINGS_LOADED',
  SET_AITEX_RESULT_OPTIONS = '@@reportsList/SET_AITEX_RESULT_OPTIONS',
  SET_COUNTRY_OPTIONS = '@@reportsList/SET_COUNTRY_OPTIONS',
  SET_SEASON_OPTIONS = '@@reportsList/SET_SEASON_OPTIONS',
  SET_OFFICE_OPTIONS = '@@reportsList/SET_OFFICE_OPTIONS',
  SET_DIVISION_OPTIONS = '@@reportsList/SET_DIVISION_OPTIONS',
  SET_SECTION_OPTIONS = '@@reportsList/SET_SECTION_OPTIONS',
  SET_BRAND_OPTIONS = '@@reportsList/SET_BRAND_OPTIONS',
  SET_QUALITY_CONTROL_OPTIONS = '@@reportsList/SET_QUALITY_CONTROL_OPTIONS',
  SET_BUYER_OPTIONS = '@@reportsList/SET_BUYER_OPTIONS',
  SET_ADVANCED_FILTER_COLUMNS = '@@reportsList/SET_ADVANCED_FILTER_COLUMNS',
  SET_ARE_ADVANCED_FILTER_COLUMNS_LOADED = '@@reportsList/SET_ARE_ADVANCED_FILTER_COLUMNS_LOADED',
  SET_IS_FETCHING = '@@reportsList/SET_IS_FETCHING',
  SET_SEARCH_DATE = '@@reportsList/SET_SEARCH_DATE',
  SET_CURRENT_PAGE = '@@reportsList/SET_CURRENT_PAGE',
  SET_PAGE_SIZE = '@@reportsList/SET_PAGE_SIZE',
  SET_TOTAL_COUNT = '@@reportsList/SET_TOTAL_COUNT',
  SET_SORTING = '@@reportsList/SET_SORTING',
  SET_SELECTED_REPORTS_IDS = '@@reportsList/SET_SELECTED_REPORTS_IDS'
}

const initialState: IReportsListState = {
  reports: [],
  mode: null,
  eciMode: null,
  isChangingMode: true,
  filterMode: FilterMode.SIMPLE,
  filter: {
    reportNumber: null,
    status: ReportStatus.ALL,
    reference: null,
    requerimentStandard: null,
    entryDate: [],
    expectedDate: [],
    endDate: [],
    expirationDate: [],
    offerNumber: null,
    seriesCode: null,
    client: null,
    collection: null,
    colour: null,
    target: null,
    model: null,
    incidence: null,
    eciOrderNumber: null,
    uneco: null,
    supplier: null,
    closed: BooleanFilter.ALL,
    copy: BooleanFilter.ALL,
    aitexResultId: null,
    countryId: null,
    seasonId: null,
    officeId: null,
    divisionId: [],
    sectionId: null,
    brandId: null,
    qualityControlId: null,
    buyerId: null
  },
  advancedFilter: {
    id: null,
    name: null,
    type: AdvancedFilterType.GLOBAL,
    companyId: null,
    companyName: null,
    isFavourite: false,
    translations: [],
    rule: {},
    isActive: true,
    shareId: null
  },
  reportCustomFieldSettings: [],
  areReportCustomFieldSettingsLoaded: false,
  aitexResultOptions: [],
  countryOptions: [],
  seasonOptions: [],
  officeOptions: [],
  divisionOptions: [],
  sectionOptions: [],
  brandOptions: [],
  qualityControlOptions: [],
  buyerOptions: [],
  advancedFilterColumns: [],
  areAdvancedFilterColumnsLoaded: false,
  grid: listGridStore.initialState,
  selectedReportsIds: []
};

// Action

export type Actions = ResetAction | ResetHardAction | SetReportsAction | SetModeAction | SetEciModeAction | SetIsChangingModeAction | SetFilterModeAction | SetFilterAction
  | SetFilterReportNumberAction | SetFilterStatusAction | SetFilterReferenceAction | SetFilterRequerimentStandardAction | SetFilterEntryDateAction | SetFilterExpectedDateAction
  | SetFilterOfferNumberAction | SetFilterSeriesCodeAction | SetFilterClientAction | SetFilterCollectionAction | SetFilterSeasonIdAction | SetFilterOfficeIdAction | SetFilterDivisionIdAction
  | SetFilterSectionIdAction | SetFilterBrandIdAction | SetFilterQualityControlIdAction | SetFilterBuyerIdAction | SetFilterColourAction | SetFilterTargetAction | SetFilterModelAction
  | SetFilterIncidenceAction | SetFilterEciOrderNumberAction | SetFilterUnecoAction | SetFilterSupplierAction | SetFilterClosedAction | SetFilterCopyAction | SetFilterAitexResultIdAction
  | SetFilterCountryIdAction | SetAdvancedFilterAction | ResetAdvancedFilterAction | SetAdvancedFilterRuleAction | SetReportCustomFieldSettingsAction | SetAreReportCustomFieldSettingsLoadedAction
  | SetAitexResultOptionsAction | SetCountryOptionsAction | SetSeasonOptionsAction | SetOfficeOptionsAction | SetDivisionOptionsAction | SetSectionOptionsAction | SetBrandOptionsAction
  | SetQualityControlOptionsAction | SetBuyerOptionsAction | SetAdvancedFilterColumnsAction | SetAreAdvancedFilterColumnsLoadedAction | listGridStore.SetIsFetchingAction
  | listGridStore.SetSearchDateAction | listGridStore.SetCurrentPageAction | listGridStore.SetPageSizeAction | listGridStore.SetTotalCountAction | listGridStore.SetSortingAction
  | SetSelectedReportsIdsAction;

export type ResetAction = Action<Type.RESET>

export type ResetHardAction = Action<Type.RESET_HARD>

export interface SetReportsAction extends Action<Type.SET_REPORTS> {
  reports: IReportWithFlattenedCustomFields[];
}

export interface SetModeAction extends Action<Type.SET_MODE> {
  mode: string;
}

export interface SetEciModeAction extends Action<Type.SET_ECI_MODE> {
  eciMode: string;
}

export interface SetIsChangingModeAction extends Action<Type.SET_IS_CHANGING_MODE> {
  isChangingMode: boolean;
}

export interface SetFilterModeAction extends Action<Type.SET_FILTER_MODE> {
  filterMode: string;
}

export interface SetFilterAction extends Action<Type.SET_FILTER> {
  filter: IReportsListFilterState;
}

export interface SetFilterReportNumberAction extends Action<Type.SET_FILTER_REPORT_NUMBER> {
  reportNumber: string;
}

export interface SetFilterStatusAction extends Action<Type.SET_FILTER_STATUS> {
  status: string;
}

export interface SetFilterReferenceAction extends Action<Type.SET_FILTER_REFERENCE> {
  reference: string;
}

export interface SetFilterRequerimentStandardAction extends Action<Type.SET_FILTER_REQUIREMENT_STANDARD> {
  requerimentStandard: string;
}

export interface SetFilterEntryDateAction extends Action<Type.SET_FILTER_ENTRY_DATE> {
  entryDate: Date[];
}

export interface SetFilterExpectedDateAction extends Action<Type.SET_FILTER_EXPECTED_DATE> {
  expectedDate: Date[];
}

export interface SetFilterEndDateAction extends Action<Type.SET_FILTER_END_DATE> {
  endDate: Date[];
}

export interface SetFilterExpirationDateAction extends Action<Type.SET_FILTER_EXPIRATION_DATE> {
  expirationDate: Date[];
}

export interface SetFilterOfferNumberAction extends Action<Type.SET_FILTER_OFFER_NUMBER> {
  offerNumber: string;
}

export interface SetFilterSeriesCodeAction extends Action<Type.SET_FILTER_SERIES_CODE> {
  seriesCode: string;
}

export interface SetFilterClientAction extends Action<Type.SET_FILTER_CLIENT> {
  client: string;
}

export interface SetFilterCollectionAction extends Action<Type.SET_FILTER_COLLECTION> {
  collection: string;
}

export interface SetFilterColourAction extends Action<Type.SET_FILTER_COLOUR> {
  colour: string;
}

export interface SetFilterTargetAction extends Action<Type.SET_FILTER_TARGET> {
  target: string;
}

export interface SetFilterModelAction extends Action<Type.SET_FILTER_MODEL> {
  model: string;
}

export interface SetFilterIncidenceAction extends Action<Type.SET_FILTER_INCIDENCE> {
  incidence: string;
}

export interface SetFilterEciOrderNumberAction extends Action<Type.SET_FILTER_ECI_ORDER_NUMBER> {
  eciOrderNumber: string;
}

export interface SetFilterUnecoAction extends Action<Type.SET_FILTER_UNECO> {
  uneco: string;
}

export interface SetFilterSupplierAction extends Action<Type.SET_FILTER_SUPPLIER> {
  supplier: string;
}

export interface SetFilterClosedAction extends Action<Type.SET_FILTER_CLOSED> {
  closed: number;
}

export interface SetFilterCopyAction extends Action<Type.SET_FILTER_COPY> {
  copy: number;
}

export interface SetFilterAitexResultIdAction extends Action<Type.SET_FILTER_AITEX_RESULT_ID> {
  aitexResultId: string;
}

export interface SetFilterCountryIdAction extends Action<Type.SET_FILTER_COUNTRY_ID> {
  countryId: string;
}

export interface SetFilterSeasonIdAction extends Action<Type.SET_FILTER_SEASON_ID> {
  seasonId: string;
}

export interface SetFilterOfficeIdAction extends Action<Type.SET_FILTER_OFFICE_ID> {
  officeId: string;
}

export interface SetFilterDivisionIdAction extends Action<Type.SET_FILTER_DIVISION_ID> {
  divisionId: string[];
}

export interface SetFilterSectionIdAction extends Action<Type.SET_FILTER_SECTION_ID> {
  sectionId: string;
}

export interface SetFilterBrandIdAction extends Action<Type.SET_FILTER_BRAND_ID> {
  brandId: string;
}

export interface SetFilterQualityControlIdAction extends Action<Type.SET_FILTER_QUALITY_CONTROL_ID> {
  qualityControlId: string;
}

export interface SetFilterBuyerIdAction extends Action<Type.SET_FILTER_BUYER_ID> {
  buyerId: string;
}

export interface SetAdvancedFilterAction extends Action<Type.SET_ADVANCED_FILTER> {
  advancedFilter: IAdvancedFilter;
}

export type ResetAdvancedFilterAction = Action<Type.RESET_ADVANCED_FILTER>

export interface SetAdvancedFilterRuleAction extends Action<Type.SET_ADVANCED_FILTER_RULE> {
  rule: IMyRule;
}

export interface SetReportCustomFieldSettingsAction extends Action<Type.SET_REPORT_CUSTOM_FIELD_SETTINGS> {
  reportCustomFieldSettings: IReportCustomFieldSettingsWithCheckboxes[];
}

export interface SetAreReportCustomFieldSettingsLoadedAction extends Action<Type.SET_ARE_REPORT_CUSTOM_FIELD_SETTINGS_LOADED> {
  areReportCustomFieldSettingsLoaded: boolean;
}

export interface SetAitexResultOptionsAction extends Action<Type.SET_AITEX_RESULT_OPTIONS> {
  aitexResultOptions: ICustomFieldOptions[];
}

export interface SetCountryOptionsAction extends Action<Type.SET_COUNTRY_OPTIONS> {
  countryOptions: ICustomFieldOptions[];
}

export interface SetSeasonOptionsAction extends Action<Type.SET_SEASON_OPTIONS> {
  seasonOptions: ICustomFieldOptions[];
}

export interface SetOfficeOptionsAction extends Action<Type.SET_OFFICE_OPTIONS> {
  officeOptions: ICustomFieldOptions[];
}

export interface SetDivisionOptionsAction extends Action<Type.SET_DIVISION_OPTIONS> {
  divisionOptions: ICustomFieldOptions[];
}

export interface SetSectionOptionsAction extends Action<Type.SET_SECTION_OPTIONS> {
  sectionOptions: ICustomFieldOptions[];
}

export interface SetBrandOptionsAction extends Action<Type.SET_BRAND_OPTIONS> {
  brandOptions: ICustomFieldOptions[];
}

export interface SetQualityControlOptionsAction extends Action<Type.SET_QUALITY_CONTROL_OPTIONS> {
  qualityControlOptions: ICustomFieldOptions[];
}

export interface SetBuyerOptionsAction extends Action<Type.SET_BUYER_OPTIONS> {
  buyerOptions: ICustomFieldOptions[];
}

export interface SetAdvancedFilterColumnsAction extends Action<Type.SET_ADVANCED_FILTER_COLUMNS> {
  columns: IAdvancedFilterColumn[];
}

export interface SetAreAdvancedFilterColumnsLoadedAction extends Action<Type.SET_ARE_ADVANCED_FILTER_COLUMNS_LOADED> {
  areAdvancedFilterColumnsLoaded: boolean;
}

export interface SetSelectedReportsIdsAction extends Action<Type.SET_SELECTED_REPORTS_IDS> {
  selectedReportsIds: string[];
}

export const reset: ActionCreator<ResetAction> = () => ({
  type: Type.RESET
});

export const resetHard: ActionCreator<ResetHardAction> = () => ({
  type: Type.RESET_HARD
});

export const setReports: ActionCreator<SetReportsAction> = (reportsLocal: IReportWithFlattenedCustomFields[]) => ({
  type: Type.SET_REPORTS,
  reports: reportsLocal
});

export const setMode: ActionCreator<SetModeAction> = (modeLocal: string) => ({
  type: Type.SET_MODE,
  mode: modeLocal
});

export const setEciMode: ActionCreator<SetEciModeAction> = (eciModeLocal: string) => ({
  type: Type.SET_ECI_MODE,
  eciMode: eciModeLocal
});

export const setIsChangingMode: ActionCreator<SetIsChangingModeAction> = (isChanginModeLocal: boolean) => ({
  type: Type.SET_IS_CHANGING_MODE,
  isChangingMode: isChanginModeLocal
});

export const setFilterMode: ActionCreator<SetFilterModeAction> = (filterModeLocal: string) => ({
  type: Type.SET_FILTER_MODE,
  filterMode: filterModeLocal
});

export const setFilter: ActionCreator<SetFilterAction> = (filterLocal: IReportsListFilterState) => ({
  type: Type.SET_FILTER,
  filter: filterLocal
});

export const setFilterReportNumber: ActionCreator<SetFilterReportNumberAction> = (reportNumber: string) => ({
  type: Type.SET_FILTER_REPORT_NUMBER,
  reportNumber
});

export const setFilterStatus: ActionCreator<SetFilterStatusAction> = (status: string) => ({
  type: Type.SET_FILTER_STATUS,
  status
});

export const setFilterReference: ActionCreator<SetFilterReferenceAction> = (reference: string) => ({
  type: Type.SET_FILTER_REFERENCE,
  reference
});

export const setFilterRequerimentStandard: ActionCreator<SetFilterRequerimentStandardAction> = (requerimentStandard: string) => ({
  type: Type.SET_FILTER_REQUIREMENT_STANDARD,
  requerimentStandard
});

export const setFilterEntryDate: ActionCreator<SetFilterEntryDateAction> = (entryDate: Date[]) => ({
  type: Type.SET_FILTER_ENTRY_DATE,
  entryDate
});

export const setFilterExpectedDate: ActionCreator<SetFilterExpectedDateAction> = (expectedDate: Date[]) => ({
  type: Type.SET_FILTER_EXPECTED_DATE,
  expectedDate
});

export const setFilterEndDate: ActionCreator<SetFilterEndDateAction> = (endDate: Date[]) => ({
  type: Type.SET_FILTER_END_DATE,
  endDate
});

export const setFilterExpirationDate: ActionCreator<SetFilterExpirationDateAction> = (expirationDate: Date[]) => ({
  type: Type.SET_FILTER_EXPIRATION_DATE,
  expirationDate
});

export const setFilterOfferNumber: ActionCreator<SetFilterOfferNumberAction> = (offerNumber: string) => ({
  type: Type.SET_FILTER_OFFER_NUMBER,
  offerNumber
});

export const setFilterSeriesCode: ActionCreator<SetFilterSeriesCodeAction> = (seriesCode: string) => ({
  type: Type.SET_FILTER_SERIES_CODE,
  seriesCode
});

export const setFilterClient: ActionCreator<SetFilterClientAction> = (client: string) => ({
  type: Type.SET_FILTER_CLIENT,
  client
});

export const setFilterCollection: ActionCreator<SetFilterCollectionAction> = (collection: string) => ({
  type: Type.SET_FILTER_COLLECTION,
  collection
});

export const setFilterColour: ActionCreator<SetFilterColourAction> = (colour: string) => ({
  type: Type.SET_FILTER_COLOUR,
  colour
});

export const setFilterTarget: ActionCreator<SetFilterTargetAction> = (target: string) => ({
  type: Type.SET_FILTER_TARGET,
  target
});

export const setFilterModel: ActionCreator<SetFilterModelAction> = (model: string) => ({
  type: Type.SET_FILTER_MODEL,
  model
});

export const setFilterIncidence: ActionCreator<SetFilterIncidenceAction> = (incidence: string) => ({
  type: Type.SET_FILTER_INCIDENCE,
  incidence
});

export const setFilterEciOrderNumber: ActionCreator<SetFilterEciOrderNumberAction> = (eciOrderNumber: string) => ({
  type: Type.SET_FILTER_ECI_ORDER_NUMBER,
  eciOrderNumber
});

export const setFilterSupplier: ActionCreator<SetFilterSupplierAction> = (supplier: string) => ({
  type: Type.SET_FILTER_SUPPLIER,
  supplier
});

export const setFilterUneco: ActionCreator<SetFilterUnecoAction> = (uneco: string) => ({
  type: Type.SET_FILTER_UNECO,
  uneco
});

export const setFilterClosed: ActionCreator<SetFilterClosedAction> = (closed: number) => ({
  type: Type.SET_FILTER_CLOSED,
  closed
});

export const setFilterCopy: ActionCreator<SetFilterCopyAction> = (copy: number) => ({
  type: Type.SET_FILTER_COPY,
  copy
});

export const setFilterAitexResultId: ActionCreator<SetFilterAitexResultIdAction> = (aitexResultId: string) => ({
  type: Type.SET_FILTER_AITEX_RESULT_ID,
  aitexResultId
});

export const setFilterCountryId: ActionCreator<SetFilterCountryIdAction> = (countryId: string) => ({
  type: Type.SET_FILTER_COUNTRY_ID,
  countryId
});

export const setFilterSeasonId: ActionCreator<SetFilterSeasonIdAction> = (seasonId: string) => ({
  type: Type.SET_FILTER_SEASON_ID,
  seasonId
});

export const setFilterOfficeId: ActionCreator<SetFilterOfficeIdAction> = (officeId: string) => ({
  type: Type.SET_FILTER_OFFICE_ID,
  officeId
});

export const setFilterDivisionId: ActionCreator<SetFilterDivisionIdAction> = (divisionId: string[]) => ({
  type: Type.SET_FILTER_DIVISION_ID,
  divisionId
});

export const setFilterSectionId: ActionCreator<SetFilterSectionIdAction> = (sectionId: string) => ({
  type: Type.SET_FILTER_SECTION_ID,
  sectionId
});

export const setFilterBrandId: ActionCreator<SetFilterBrandIdAction> = (brandId: string) => ({
  type: Type.SET_FILTER_BRAND_ID,
  brandId
});

export const setFilterQualityControlId: ActionCreator<SetFilterQualityControlIdAction> = (qualityControlId: string) => ({
  type: Type.SET_FILTER_QUALITY_CONTROL_ID,
  qualityControlId
});

export const setFilterBuyerId: ActionCreator<SetFilterBuyerIdAction> = (buyerId: string) => ({
  type: Type.SET_FILTER_BUYER_ID,
  buyerId
});

export const setAdvancedFilter: ActionCreator<SetAdvancedFilterAction> = (advancedFilterOptions: IAdvancedFilter) => ({
  type: Type.SET_ADVANCED_FILTER,
  advancedFilter: advancedFilterOptions
});

export const resetAdvancedFilter: ActionCreator<ResetAdvancedFilterAction> = () => ({
  type: Type.RESET_ADVANCED_FILTER
});

export const setAdvancedFilterRule: ActionCreator<SetAdvancedFilterRuleAction> = (rule: IMyRule) => ({
  type: Type.SET_ADVANCED_FILTER_RULE,
  rule
});

export const setReportCustomFieldSettings: ActionCreator<SetReportCustomFieldSettingsAction> = (reportCustomFieldSettingsLocal: IReportCustomFieldSettingsWithCheckboxes[]) => ({
  type: Type.SET_REPORT_CUSTOM_FIELD_SETTINGS,
  reportCustomFieldSettings: reportCustomFieldSettingsLocal
});

export const setAreReportCustomFieldSettingsLoaded: ActionCreator<SetAreReportCustomFieldSettingsLoadedAction> = (areReportCustomFieldSettingsLoadedLocal: boolean) => ({
  type: Type.SET_ARE_REPORT_CUSTOM_FIELD_SETTINGS_LOADED,
  areReportCustomFieldSettingsLoaded: areReportCustomFieldSettingsLoadedLocal
});

export const setAitexResultOptions: ActionCreator<SetAitexResultOptionsAction> = (aitexResultOptionsLocal: ICustomFieldOptions[]) => ({
  type: Type.SET_AITEX_RESULT_OPTIONS,
  aitexResultOptions: aitexResultOptionsLocal
});

export const setCountryOptions: ActionCreator<SetCountryOptionsAction> = (countryOptionsLocal: ICustomFieldOptions[]) => ({
  type: Type.SET_COUNTRY_OPTIONS,
  countryOptions: countryOptionsLocal
});

export const setSeasonOptions: ActionCreator<SetSeasonOptionsAction> = (seasonOptionsLocal: ICustomFieldOptions[]) => ({
  type: Type.SET_SEASON_OPTIONS,
  seasonOptions: seasonOptionsLocal
});

export const setOfficeOptions: ActionCreator<SetOfficeOptionsAction> = (officeOptionsLocal: ICustomFieldOptions[]) => ({
  type: Type.SET_OFFICE_OPTIONS,
  officeOptions: officeOptionsLocal
});

export const setDivisionOptions: ActionCreator<SetDivisionOptionsAction> = (divisionOptionsLocal: ICustomFieldOptions[]) => ({
  type: Type.SET_DIVISION_OPTIONS,
  divisionOptions: divisionOptionsLocal
});

export const setSectionOptions: ActionCreator<SetSectionOptionsAction> = (sectionOptionsLocal: ICustomFieldOptions[]) => ({
  type: Type.SET_SECTION_OPTIONS,
  sectionOptions: sectionOptionsLocal
});

export const setBrandOptions: ActionCreator<SetBrandOptionsAction> = (brandOptionsLocal: ICustomFieldOptions[]) => ({
  type: Type.SET_BRAND_OPTIONS,
  brandOptions: brandOptionsLocal
});

export const setQualityControlOptions: ActionCreator<SetQualityControlOptionsAction> = (qualityControlOptionsLocal: ICustomFieldOptions[]) => ({
  type: Type.SET_QUALITY_CONTROL_OPTIONS,
  qualityControlOptions: qualityControlOptionsLocal
});

export const setBuyerOptions: ActionCreator<SetBuyerOptionsAction> = (buyerOptionsLocal: ICustomFieldOptions[]) => ({
  type: Type.SET_BUYER_OPTIONS,
  buyerOptions: buyerOptionsLocal
});

export const setAdvancedFilterColumns: ActionCreator<SetAdvancedFilterColumnsAction> = (columns: IAdvancedFilterColumn[]) => ({
  type: Type.SET_ADVANCED_FILTER_COLUMNS,
  columns
});

export const setAreAdvancedFilterColumnsLoaded: ActionCreator<SetAreAdvancedFilterColumnsLoadedAction> = (areAdvancedFilterColumnsLoadedLocal: boolean) => ({
  type: Type.SET_ARE_ADVANCED_FILTER_COLUMNS_LOADED,
  areAdvancedFilterColumnsLoaded: areAdvancedFilterColumnsLoadedLocal
});

export const setSelectedReportsIds: ActionCreator<SetSelectedReportsIdsAction> = (selectedReportsIdsLocal: string[]) => ({
  type: Type.SET_SELECTED_REPORTS_IDS,
  selectedReportsIds: selectedReportsIdsLocal
});

const computeSimpleFilterRule = (filterLocal: IReportsListFilterState) => {
  const myRule: IMyRule = {
    condition: RuleCondition.AND,
    myRules: []
  };
  if (filterLocal.reportNumber) {
    myRule.myRules.push({
      field: 'reportNumber',
      operator: RuleOperator.CONTAINS,
      value: filterLocal.reportNumber,
      ignoreCase: true
    });
  }
  if (filterLocal.status !== ReportStatus.ALL) {
    myRule.myRules.push({
      field: 'status',
      operator: RuleOperator.EQUAL,
      value: filterLocal.status
    });
  }
  if (filterLocal.reference) {
    myRule.myRules.push({
      field: 'reference',
      operator: RuleOperator.CONTAINS,
      value: filterLocal.reference,
      ignoreCase: true
    });
  }
  if (filterLocal.requerimentStandard) {
    myRule.myRules.push({
      field: 'requerimentStandard',
      operator: RuleOperator.CONTAINS,
      value: filterLocal.requerimentStandard,
      ignoreCase: true
    });
  }
  if (filterLocal.entryDate.length) {
    myRule.myRules.push({
      field: 'entryDate',
      operator: RuleOperator.GREATER_THAN_OR_EQUAL,
      value: dateHelpers.toDateWithoutZones(filterLocal.entryDate[0], DATE_MOMENT_FORMAT),
      isDate: true
    });
    myRule.myRules.push({
      field: 'entryDate',
      operator: RuleOperator.LESS_THAN_OR_EQUAL,
      value: dateHelpers.toDateWithoutZones(filterLocal.entryDate[1], DATE_MOMENT_FORMAT),
      isDate: true
    });
  }
  if (filterLocal.expectedDate.length) {
    myRule.myRules.push({
      field: 'expectedDate',
      operator: RuleOperator.GREATER_THAN_OR_EQUAL,
      value: dateHelpers.toDateWithoutZones(filterLocal.expectedDate[0], DATE_MOMENT_FORMAT),
      isDate: true
    });
    myRule.myRules.push({
      field: 'expectedDate',
      operator: RuleOperator.LESS_THAN_OR_EQUAL,
      value: dateHelpers.toDateWithoutZones(filterLocal.expectedDate[1], DATE_MOMENT_FORMAT),
      isDate: true
    });
  }
  if (filterLocal.endDate.length) {
    myRule.myRules.push({
      field: 'endDate',
      operator: RuleOperator.GREATER_THAN_OR_EQUAL,
      value: dateHelpers.toDateWithoutZones(filterLocal.endDate[0], DATE_MOMENT_FORMAT),
      isDate: true
    });
    myRule.myRules.push({
      field: 'endDate',
      operator: RuleOperator.LESS_THAN_OR_EQUAL,
      value: dateHelpers.toDateWithoutZones(filterLocal.endDate[1], DATE_MOMENT_FORMAT),
      isDate: true
    });
  }
  if (filterLocal.expirationDate.length) {
    myRule.myRules.push({
      field: 'expirationDate',
      operator: RuleOperator.GREATER_THAN_OR_EQUAL,
      value: dateHelpers.toDateWithoutZones(filterLocal.expirationDate[0], DATE_MOMENT_FORMAT),
      isDate: true
    });
    myRule.myRules.push({
      field: 'expirationDate',
      operator: RuleOperator.LESS_THAN_OR_EQUAL,
      value: dateHelpers.toDateWithoutZones(filterLocal.expirationDate[1], DATE_MOMENT_FORMAT),
      isDate: true
    });
  }
  if (filterLocal.offerNumber) {
    myRule.myRules.push({
      field: 'offerNumber',
      operator: RuleOperator.CONTAINS,
      value: filterLocal.offerNumber,
      ignoreCase: true
    });
  }
  if (filterLocal.seriesCode) {
    myRule.myRules.push({
      field: 'seriesCode',
      operator: RuleOperator.CONTAINS,
      value: filterLocal.seriesCode,
      ignoreCase: true
    });
  }
  if (filterLocal.client) {
    myRule.myRules.push({
      field: 'client',
      operator: RuleOperator.CONTAINS,
      value: filterLocal.client,
      ignoreCase: true
    });
  }
  if (filterLocal.collection) {
    myRule.myRules.push({
      field: 'collection',
      operator: RuleOperator.CONTAINS,
      value: filterLocal.collection,
      ignoreCase: true
    });
  }
  if (filterLocal.colour) {
    myRule.myRules.push({
      field: 'colour',
      operator: RuleOperator.CONTAINS,
      value: filterLocal.colour,
      ignoreCase: true
    });
  }
  if (filterLocal.target) {
    myRule.myRules.push({
      field: 'target',
      operator: RuleOperator.CONTAINS,
      value: filterLocal.target,
      ignoreCase: true
    });
  }
  if (filterLocal.model) {
    myRule.myRules.push({
      field: 'model',
      operator: RuleOperator.CONTAINS,
      value: filterLocal.model,
      ignoreCase: true
    });
  }
  if (filterLocal.incidence) {
    myRule.myRules.push({
      field: 'incidence',
      operator: RuleOperator.CONTAINS,
      value: filterLocal.incidence,
      ignoreCase: true
    });
  }
  if (filterLocal.eciOrderNumber) {
    myRule.myRules.push({
      field: 'eciOrderNumber',
      operator: RuleOperator.CONTAINS,
      value: filterLocal.eciOrderNumber,
      ignoreCase: true
    });
  }
  if (filterLocal.supplier) {
    myRule.myRules.push({
      field: 'supplier',
      operator: RuleOperator.CONTAINS,
      value: filterLocal.supplier,
      ignoreCase: true
    });
  }
  if (filterLocal.uneco) {
    myRule.myRules.push({
      field: 'uneco',
      operator: RuleOperator.CONTAINS,
      value: filterLocal.uneco,
      ignoreCase: true
    });
  }
  if (filterLocal.closed !== BooleanFilter.ALL) {
    myRule.myRules.push({
      field: 'closed',
      operator: RuleOperator.EQUAL,
      value: filterLocal.closed === BooleanFilter.YES ? true : false
    });
  }
  if (filterLocal.copy !== BooleanFilter.ALL) {
    myRule.myRules.push({
      field: 'copy',
      operator: RuleOperator.EQUAL,
      value: filterLocal.copy === BooleanFilter.YES ? true : false
    });
  }
  if (filterLocal.aitexResultId) {
    myRule.myRules.push({
      field: 'aitexResultId',
      operator: RuleOperator.EQUAL,
      value: filterLocal.aitexResultId
    });
  }
  if (filterLocal.countryId) {
    myRule.myRules.push({
      field: 'countryId',
      operator: RuleOperator.EQUAL,
      value: filterLocal.countryId
    });
  }
  if (filterLocal.seasonId) {
    myRule.myRules.push({
      field: 'seasonId',
      operator: RuleOperator.EQUAL,
      value: filterLocal.seasonId
    });
  }
  if (filterLocal.officeId) {
    myRule.myRules.push({
      field: 'officeId',
      operator: RuleOperator.EQUAL,
      value: filterLocal.officeId
    });
  }
  if (filterLocal.divisionId) {
    myRule.myRules.push({
      field: 'divisionId',
      operator: RuleOperator.IN,
      value: filterLocal.divisionId
    });
  }
  if (filterLocal.sectionId) {
    myRule.myRules.push({
      field: 'sectionId',
      operator: RuleOperator.EQUAL,
      value: filterLocal.sectionId
    });
  }
  if (filterLocal.brandId) {
    myRule.myRules.push({
      field: 'brandId',
      operator: RuleOperator.EQUAL,
      value: filterLocal.brandId
    });
  }
  if (filterLocal.qualityControlId) {
    myRule.myRules.push({
      field: 'qualityControlId',
      operator: RuleOperator.EQUAL,
      value: filterLocal.qualityControlId
    });
  }
  if (filterLocal.buyerId) {
    myRule.myRules.push({
      field: 'buyerId',
      operator: RuleOperator.EQUAL,
      value: filterLocal.buyerId
    });
  }
  return myRule;
};

export const fetchReports: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (
  queryBuilderService: IQueryBuilderService,
  oDataService: IODataService,
  reportsDataManager: DataManager
) => (
    async (dispatch, getState) => {
      dispatch(listGridStore.setIsFetching(Type.SET_IS_FETCHING, true));

      const eciModeLocal = getState().reportsListStore.eciMode;
      const filterModeLocal = getState().reportsListStore.filterMode;

      let query = new Query()
        .select([
          'id', 'reportNumber', 'version', 'revision', 'client', 'entryDate', 'expectedDate', 'endDate', 'expirationDate', 'status', 'reference', 'fileName', 'resultIsVisible', 'seriesName',
          'seriesCode', 'requerimentStandard', 'collection', 'colour', 'target', 'model', 'incidence', 'identifier', 'eciOrderNumber', 'uneco', 'supplier', 'eciBuyer', 'commentsForReport',
          'emailRecipients', 'closed', 'copy', 'automaticGeneration', 'qualityControlDate', 'buyerDate', 'validationDate', 'countryId', 'countryName', 'aitexResultId', 'aitexResultName',
          'seasonId', 'seasonName', 'campaignId', 'campaignName', 'officeId', 'officeName', 'divisionId', 'divisionName', 'sectionId', 'sectionName', 'brandId', 'brandName', 'qualityControlId',
          'qualityControlName', 'buyerId', 'buyerName', 'advanceDate', 'canRequestAdvance', 'totalAnalyzedServices', 'totalPendingServices', 'totalServices', 'published'
        ]);

      let myRule: IMyRule = {
        condition: RuleCondition.AND,
        myRules: []
      };
      if (eciModeLocal === null) {
        if (filterModeLocal === FilterMode.SIMPLE) {
          const filterLocal = getState().reportsListStore.filter;
          myRule = computeSimpleFilterRule(filterLocal);
        } else if (filterModeLocal === FilterMode.ADVANCED) {
          const advancedFilterLocal = getState().reportsListStore.advancedFilter;
          myRule = advancedFilterLocal.rule;
        }
      } else {
        switch (eciModeLocal) {
          case ReportsListEciMode.FM: {
            myRule.myRules.push({
              field: 'seriesCode',
              operator: RuleOperator.EQUAL,
              value: 'ECI-FM',
              ignoreCase: true
            });

            myRule.myRules.push({
              field: 'status',
              operator: RuleOperator.EQUAL,
              value: ReportStatus.FINALIZED
            });

            const qualityControlPendingRule: IMyRule = {
              condition: RuleCondition.OR,
              myRules: [{
                field: 'qualityControlId',
                operator: RuleOperator.EQUAL,
                value: ReportCustomFieldQualityControl.PENDING,
                ignoreCase: true
              }, {
                field: 'qualityControlId',
                operator: RuleOperator.EQUAL,
                value: ReportCustomFieldQualityControl.SAMPLE_PENDING,
                ignoreCase: true
              }]
            };

            const qualityControlNoPassRule: IMyRule = {
              field: 'qualityControlId',
              operator: RuleOperator.EQUAL,
              value: ReportCustomFieldQualityControl.NO_PASS,
              ignoreCase: true
            };

            const buyerRule: IMyRule = {
              condition: RuleCondition.OR,
              myRules: [{
                  field: 'buyerId',
                  operator: RuleOperator.EQUAL,
                  value: ReportCustomFieldBuyer.PENDING,
                  ignoreCase: true
                }, {
                  field: 'buyerId',
                  operator: RuleOperator.EQUAL,
                  value: ReportCustomFieldBuyer.SAMPLE_PENDING,
                  ignoreCase: true
                }, {
                  field: 'buyerId',
                  operator: RuleOperator.EQUAL,
                  value: null,
                  ignoreCase: true
                }]
            };

            const buyerFullRule: IMyRule = {
              condition: RuleCondition.AND,
              myRules: [qualityControlNoPassRule, buyerRule]
            };

            const fullControl: IMyRule = {
              condition: RuleCondition.OR,
              myRules: [qualityControlPendingRule, buyerFullRule]
            };

            myRule.myRules.push(fullControl);
            break;
          }
          case ReportsListEciMode.ZM: {
            myRule.myRules.push({
              field: 'seriesCode',
              operator: RuleOperator.EQUAL,
              value: 'ECI-ZM',
              ignoreCase: true
            });

            myRule.myRules.push({
              field: 'status',
              operator: RuleOperator.EQUAL,
              value: ReportStatus.FINALIZED
            });

            const qualityControlPendingRule: IMyRule = {
              condition: RuleCondition.OR,
              myRules: [{
                field: 'qualityControlId',
                operator: RuleOperator.EQUAL,
                value: ReportCustomFieldQualityControl.PENDING,
                ignoreCase: true
              }, {
                field: 'qualityControlId',
                operator: RuleOperator.EQUAL,
                value: ReportCustomFieldQualityControl.SAMPLE_PENDING,
                ignoreCase: true
              }]
            };

            const qualityControlNoPassRule: IMyRule = {
              field: 'qualityControlId',
              operator: RuleOperator.EQUAL,
              value: ReportCustomFieldQualityControl.NO_PASS,
              ignoreCase: true
            };

            const buyerRule: IMyRule = {
              condition: RuleCondition.OR,
              myRules: [{
                field: 'buyerId',
                operator: RuleOperator.EQUAL,
                value: ReportCustomFieldBuyer.PENDING,
                ignoreCase: true
              }, {
                field: 'buyerId',
                operator: RuleOperator.EQUAL,
                value: ReportCustomFieldBuyer.SAMPLE_PENDING,
                ignoreCase: true
              }, {
                field: 'buyerId',
                operator: RuleOperator.EQUAL,
                value: null,
                ignoreCase: true
              }]
            };

            const buyerFullRule: IMyRule = {
              condition: RuleCondition.AND,
              myRules: [qualityControlNoPassRule, buyerRule]
            };

            const fullControl: IMyRule = {
              condition: RuleCondition.OR,
              myRules: [qualityControlPendingRule, buyerFullRule]
            };

            myRule.myRules.push(fullControl);
            break;
          }
          case ReportsListEciMode.LC_NO_PASS: {
            myRule.myRules.push({
              condition: RuleCondition.OR,
              myRules: [{
                field: 'seriesCode',
                operator: RuleOperator.EQUAL,
                value: 'ECI-FM',
                ignoreCase: true
              }, {
                field: 'seriesCode',
                operator: RuleOperator.EQUAL,
                value: 'ECI-ZM',
                ignoreCase: true
              }, {
                field: 'seriesCode',
                operator: RuleOperator.EQUAL,
                value: 'ECI-CI',
                ignoreCase: true
              }, {
                field: 'seriesCode',
                operator: RuleOperator.EQUAL,
                value: 'ECI-CI-Z',
                ignoreCase: true
              }]
            });

            myRule.myRules.push({
              field: 'status',
              operator: RuleOperator.EQUAL,
              value: ReportStatus.FINALIZED
            });

            const lcNoPassAitexResult = getState().reportsListStore.aitexResultOptions.find((option) => option.name.toLowerCase() === 'l&c no pass');
            if (lcNoPassAitexResult) {
              myRule.myRules.push({
                field: 'aitexResultId',
                operator: RuleOperator.EQUAL,
                value: lcNoPassAitexResult.id
              });
            } else {
              // Add this filter so that it returns no results (cannot be finalized and in progress at the same time)
              myRule.myRules.push({
                field: 'status',
                operator: RuleOperator.EQUAL,
                value: ReportStatus.IN_PROGRESS
              });
            }
            break;
          }
          case ReportsListEciMode.FM_OPEN: {
            myRule.myRules.push({
              field: 'seriesCode',
              operator: RuleOperator.EQUAL,
              value: 'ECI-FM',
              ignoreCase: true
            });

            myRule.myRules.push({
              field: 'status',
              operator: RuleOperator.EQUAL,
              value: ReportStatus.FINALIZED
            });

            myRule.myRules.push({
              field: 'closed',
              operator: RuleOperator.EQUAL,
              value: false
            });

            break;
          }
          case ReportsListEciMode.OPEN: {
            myRule.myRules.push({
              field: 'status',
              operator: RuleOperator.EQUAL,
              value: ReportStatus.FINALIZED
            });

            myRule.myRules.push({
              field: 'closed',
              operator: RuleOperator.EQUAL,
              value: false
            });
            break;
          }
        }
      }

      query = queryBuilderService.buildQuery(reportsDataManager, myRule, query);

      const currentPageLocal = getState().reportsListStore.grid.currentPage;
      const pageSizeLocal = getState().reportsListStore.grid.pageSize;
      const sortFieldLocal = getState().reportsListStore.grid.sortField;
      const sortDirectionLocal = getState().reportsListStore.grid.sortDirection;

      query = query.page(currentPageLocal, pageSizeLocal);
      if (sortFieldLocal && sortDirectionLocal) {
        query = sortDirectionLocal === 'Ascending' ? query.sortBy(sortFieldLocal) : query.sortByDesc(sortFieldLocal);
      }
      const reportsResponse = await oDataService.executeQueryWithCount<IReportWithFlattenedCustomFields>(reportsDataManager, query);
      const reportsLocal = reportsResponse.result.map((report) => reportUtils.initDatesFlattened(report));
      batch(() => {
        dispatch(setReports(reportsLocal));
        dispatch(listGridStore.setSearchDate(Type.SET_SEARCH_DATE, moment()));
        dispatch(listGridStore.setTotalCount(Type.SET_TOTAL_COUNT, reportsResponse.count));
        dispatch(setSelectedReportsIds(initialState.selectedReportsIds));
      });
      dispatch(listGridStore.setIsFetching(Type.SET_IS_FETCHING, false));
    }
  );

export const fetchReportCustomFieldSettings: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (companiesService: ICompaniesService, reportsService: IReportsService) => (
  async (dispatch, getState) => {
    batch(() => {
      dispatch(setReportCustomFieldSettings(initialState.reportCustomFieldSettings));
      dispatch(setAitexResultOptions(initialState.aitexResultOptions));
      dispatch(setCountryOptions(initialState.countryOptions));
      dispatch(setSeasonOptions(initialState.seasonOptions));
      dispatch(setOfficeOptions(initialState.officeOptions));
      dispatch(setDivisionOptions(initialState.divisionOptions));
      dispatch(setSectionOptions(initialState.sectionOptions));
      dispatch(setBrandOptions(initialState.brandOptions));
      dispatch(setQualityControlOptions(initialState.qualityControlOptions));
      dispatch(setBuyerOptions(initialState.buyerOptions));
    });

    const companyId = getState().currentUserStore.jwtInfo.companyId;

    if (companyId) {
      batch(() => {
        dispatch(listGridStore.setIsFetching(Type.SET_IS_FETCHING, true));
        dispatch(setAreReportCustomFieldSettingsLoaded(initialState.areReportCustomFieldSettingsLoaded));
      });

      const reportCustomFieldSettingsLocal = (await companiesService.fetchReportCustomFieldSettings(companyId))
        .map((s) => reportCustomFieldSettingsUtils.toWithCheckboxes(s));
      batch(() => {
        dispatch(setReportCustomFieldSettings(reportCustomFieldSettingsLocal));
        dispatch(setAreReportCustomFieldSettingsLoaded(true));
        dispatch(listGridStore.setIsFetching(Type.SET_IS_FETCHING, false));
      });

      dispatch(setAitexResultOptions(await reportsService.fetchCustomFieldOptions('aitexResultId')));
      dispatch(setCountryOptions(await reportsService.fetchCustomFieldOptions('countryId')));
      dispatch(setSeasonOptions(await reportsService.fetchCustomFieldOptions('seasonId')));
      dispatch(setOfficeOptions(await reportsService.fetchCustomFieldOptions('officeId')));
      dispatch(setDivisionOptions(await reportsService.fetchCustomFieldOptions('divisionId')));
      dispatch(setSectionOptions(await reportsService.fetchCustomFieldOptions('sectionId')));
      dispatch(setBrandOptions(await reportsService.fetchCustomFieldOptions('brandId')));
      dispatch(setQualityControlOptions(await reportsService.fetchCustomFieldOptions('qualityControlId')));
      dispatch(setBuyerOptions(await reportsService.fetchCustomFieldOptions('buyerId')));
    } else {
      dispatch(setAreReportCustomFieldSettingsLoaded(true));
    }
  }
);

export const fetchAdvancedFilterColumns: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (advancedFiltersService: IAdvancedFiltersService) => (
  async (dispatch) => {
    batch(() => {
      dispatch(listGridStore.setIsFetching(Type.SET_IS_FETCHING, true));
      dispatch(setAreAdvancedFilterColumnsLoaded(initialState.areAdvancedFilterColumnsLoaded));
      dispatch(setAdvancedFilterColumns(initialState.advancedFilterColumns));
    });

    const advancedFilterColumnsLocal = await advancedFiltersService.fetchColumns(SourceType.REPORT)
    batch(() => {
      dispatch(setAdvancedFilterColumns(advancedFilterColumnsLocal));
      dispatch(setAreAdvancedFilterColumnsLoaded(true));
      dispatch(listGridStore.setIsFetching(Type.SET_IS_FETCHING, false));
    });
  }
);

export const fetchAdvancedFilterByShareId: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (shareId: string, advancedFiltersService: IAdvancedFiltersService) => (
  async (dispatch) => {
    try {
      const advancedFilterLocal = await advancedFiltersService.fetchByShareId(shareId);

      dispatch(setAdvancedFilter(advancedFilterLocal));
      dispatch(setFilterMode(FilterMode.ADVANCED));
    } catch (error) {
      // Do nothing
    }
  }
);

// Reducers

const reports: Reducer<IReportWithFlattenedCustomFields[]> = (state = initialState.reports, action) => {
  switch (action.type) {
    case Type.RESET:
      return state;
    case Type.RESET_HARD:
      return initialState.reports;
    case Type.SET_MODE:
      return initialState.reports;
    case Type.SET_ECI_MODE:
      return initialState.reports;
    case Type.SET_REPORTS:
      return (action as SetReportsAction).reports;
    default:
      return state;
  }
};

const mode: Reducer<string> = (state = initialState.mode, action) => {
  switch (action.type) {
    case Type.RESET:
      return state;
    case Type.RESET_HARD:
      return initialState.mode;
    case Type.SET_MODE:
      return (action as SetModeAction).mode;
    default:
      return state;
  }
};

const eciMode: Reducer<string> = (state = initialState.eciMode, action) => {
  switch (action.type) {
    case Type.RESET:
      return state;
    case Type.RESET_HARD:
      return initialState.eciMode;
    case Type.SET_ECI_MODE:
      return (action as SetEciModeAction).eciMode;
    default:
      return state;
  }
};

const isChangingMode: Reducer<boolean> = (state = initialState.isChangingMode, action) => {
  switch (action.type) {
    case Type.RESET:
    case Type.RESET_HARD:
      return initialState.isChangingMode;
    case Type.SET_IS_CHANGING_MODE:
      return (action as SetIsChangingModeAction).isChangingMode;
    default:
      return state;
  }
}

const filterMode: Reducer<string> = (state = initialState.filterMode, action) => {
  switch (action.type) {
    case Type.RESET:
      return state;
    case Type.RESET_HARD:
      return initialState.filterMode;
    case Type.SET_MODE:
      return initialState.filterMode;
    case Type.SET_FILTER_MODE:
      return (action as SetFilterModeAction).filterMode;
    default:
      return state;
  }
};

const filter: Reducer<IReportsListFilterState> = (state = initialState.filter, action) => {
  switch (action.type) {
    case Type.RESET:
      return state;
    case Type.RESET_HARD:
      return initialState.filter;
    case Type.SET_MODE:
      return {
        ...state,
        status: (action as SetModeAction).mode,
        entryDate: initialState.filter.entryDate,
        expectedDate: initialState.filter.expectedDate,
        endDate: initialState.filter.endDate,
        expirationDate: initialState.filter.expirationDate
      };
    case Type.SET_FILTER:
      return (action as SetFilterAction).filter;
    case Type.SET_FILTER_REPORT_NUMBER:
      return { ...state, reportNumber: (action as SetFilterReportNumberAction).reportNumber };
    case Type.SET_FILTER_STATUS:
      return { ...state, status: (action as SetFilterStatusAction).status };
    case Type.SET_FILTER_REFERENCE:
      return { ...state, reference: (action as SetFilterReferenceAction).reference };
    case Type.SET_FILTER_REQUIREMENT_STANDARD:
      return { ...state, requerimentStandard: (action as SetFilterRequerimentStandardAction).requerimentStandard };
    case Type.SET_FILTER_ENTRY_DATE:
      return { ...state, entryDate: (action as SetFilterEntryDateAction).entryDate };
    case Type.SET_FILTER_EXPECTED_DATE:
      return { ...state, expectedDate: (action as SetFilterExpectedDateAction).expectedDate };
    case Type.SET_FILTER_END_DATE:
      return { ...state, endDate: (action as SetFilterEndDateAction).endDate };
    case Type.SET_FILTER_EXPIRATION_DATE:
      return { ...state, expirationDate: (action as SetFilterExpirationDateAction).expirationDate };
    case Type.SET_FILTER_OFFER_NUMBER:
      return { ...state, offerNumber: (action as SetFilterOfferNumberAction).offerNumber };
    case Type.SET_FILTER_SERIES_CODE:
      return { ...state, seriesCode: (action as SetFilterSeriesCodeAction).seriesCode };
    case Type.SET_FILTER_CLIENT:
      return { ...state, client: (action as SetFilterClientAction).client };
    case Type.SET_FILTER_COLLECTION:
      return { ...state, collection: (action as SetFilterCollectionAction).collection };
    case Type.SET_FILTER_COLOUR:
      return { ...state, colour: (action as SetFilterColourAction).colour };
    case Type.SET_FILTER_TARGET:
      return { ...state, target: (action as SetFilterTargetAction).target };
    case Type.SET_FILTER_MODEL:
      return { ...state, model: (action as SetFilterModelAction).model };
    case Type.SET_FILTER_INCIDENCE:
      return { ...state, incidence: (action as SetFilterIncidenceAction).incidence };
    case Type.SET_FILTER_ECI_ORDER_NUMBER:
      return { ...state, eciOrderNumber: (action as SetFilterEciOrderNumberAction).eciOrderNumber };
    case Type.SET_FILTER_UNECO:
      return { ...state, uneco: (action as SetFilterUnecoAction).uneco };
    case Type.SET_FILTER_SUPPLIER:
      return { ...state, supplier: (action as SetFilterSupplierAction).supplier };
    case Type.SET_FILTER_CLOSED:
      return { ...state, closed: (action as SetFilterClosedAction).closed };
    case Type.SET_FILTER_COPY:
      return { ...state, copy: (action as SetFilterCopyAction).copy };
    case Type.SET_FILTER_AITEX_RESULT_ID:
      return { ...state, aitexResultId: (action as SetFilterAitexResultIdAction).aitexResultId };
    case Type.SET_FILTER_COUNTRY_ID:
      return { ...state, countryId: (action as SetFilterCountryIdAction).countryId };
    case Type.SET_FILTER_SEASON_ID:
      return { ...state, seasonId: (action as SetFilterSeasonIdAction).seasonId };
    case Type.SET_FILTER_OFFICE_ID:
      return { ...state, officeId: (action as SetFilterOfficeIdAction).officeId };
    case Type.SET_FILTER_DIVISION_ID:
      return { ...state, divisionId: (action as SetFilterDivisionIdAction).divisionId };
    case Type.SET_FILTER_SECTION_ID:
      return { ...state, sectionId: (action as SetFilterSectionIdAction).sectionId };
    case Type.SET_FILTER_BRAND_ID:
      return { ...state, brandId: (action as SetFilterBrandIdAction).brandId };
    case Type.SET_FILTER_QUALITY_CONTROL_ID:
      return { ...state, qualityControlId: (action as SetFilterQualityControlIdAction).qualityControlId };
    case Type.SET_FILTER_BUYER_ID:
      return { ...state, buyerId: (action as SetFilterBuyerIdAction).buyerId };
    default:
      return state;
  }
};

const advancedFilter: Reducer<IAdvancedFilter> = (state = initialState.advancedFilter, action) => {
  switch (action.type) {
    case Type.RESET:
      return state;
    case Type.RESET_HARD:
      return initialState.advancedFilter;
    case Type.SET_ADVANCED_FILTER:
      return (action as SetAdvancedFilterAction).advancedFilter;
    case Type.RESET_ADVANCED_FILTER:
      return initialState.advancedFilter;
    case Type.SET_ADVANCED_FILTER_RULE:
      return { ...state, rule: (action as SetAdvancedFilterRuleAction).rule };
    default:
      return state;
  }
};

const reportCustomFieldSettings: Reducer<IReportCustomFieldSettingsWithCheckboxes[]> = (state = initialState.reportCustomFieldSettings, action) => {
  switch (action.type) {
    case Type.RESET:
      return state;
    case Type.RESET_HARD:
      return initialState.reportCustomFieldSettings;
    case Type.SET_REPORT_CUSTOM_FIELD_SETTINGS:
      return (action as SetReportCustomFieldSettingsAction).reportCustomFieldSettings;
    default:
      return state;
  }
};

const areReportCustomFieldSettingsLoaded: Reducer<boolean> = (state = initialState.areReportCustomFieldSettingsLoaded, action) => {
  switch (action.type) {
    case Type.RESET:
      return state;
    case Type.RESET_HARD:
      return initialState.areReportCustomFieldSettingsLoaded;
    case Type.SET_ARE_REPORT_CUSTOM_FIELD_SETTINGS_LOADED:
      return (action as SetAreReportCustomFieldSettingsLoadedAction).areReportCustomFieldSettingsLoaded;
    default:
      return state;
  }
};

const aitexResultOptions: Reducer<ICustomFieldOptions[]> = (state = initialState.aitexResultOptions, action) => {
  switch (action.type) {
    case Type.RESET:
      return state;
    case Type.RESET_HARD:
      return initialState.aitexResultOptions;
    case Type.SET_AITEX_RESULT_OPTIONS:
      return (action as SetAitexResultOptionsAction).aitexResultOptions;
    default:
      return state;
  }
};

const countryOptions: Reducer<ICustomFieldOptions[]> = (state = initialState.countryOptions, action) => {
  switch (action.type) {
    case Type.RESET:
      return state;
    case Type.RESET_HARD:
      return initialState.countryOptions;
    case Type.SET_COUNTRY_OPTIONS:
      return (action as SetCountryOptionsAction).countryOptions;
    default:
      return state;
  }
};

const seasonOptions: Reducer<ICustomFieldOptions[]> = (state = initialState.seasonOptions, action) => {
  switch (action.type) {
    case Type.RESET:
      return state;
    case Type.RESET_HARD:
      return initialState.seasonOptions;
    case Type.SET_SEASON_OPTIONS:
      return (action as SetSeasonOptionsAction).seasonOptions;
    default:
      return state;
  }
};

const officeOptions: Reducer<ICustomFieldOptions[]> = (state = initialState.officeOptions, action) => {
  switch (action.type) {
    case Type.RESET:
      return state;
    case Type.RESET_HARD:
      return initialState.officeOptions;
    case Type.SET_OFFICE_OPTIONS:
      return (action as SetOfficeOptionsAction).officeOptions;
    default:
      return state;
  }
};

const divisionOptions: Reducer<ICustomFieldOptions[]> = (state = initialState.divisionOptions, action) => {
  switch (action.type) {
    case Type.RESET:
      return state;
    case Type.RESET_HARD:
      return initialState.divisionOptions;
    case Type.SET_DIVISION_OPTIONS:
      return (action as SetDivisionOptionsAction).divisionOptions;
    default:
      return state;
  }
};

const sectionOptions: Reducer<ICustomFieldOptions[]> = (state = initialState.sectionOptions, action) => {
  switch (action.type) {
    case Type.RESET:
      return state;
    case Type.RESET_HARD:
      return initialState.sectionOptions;
    case Type.SET_SECTION_OPTIONS:
      return (action as SetSectionOptionsAction).sectionOptions;
    default:
      return state;
  }
};

const brandOptions: Reducer<ICustomFieldOptions[]> = (state = initialState.brandOptions, action) => {
  switch (action.type) {
    case Type.RESET:
      return state;
    case Type.RESET_HARD:
      return initialState.brandOptions;
    case Type.SET_BRAND_OPTIONS:
      return (action as SetBrandOptionsAction).brandOptions;
    default:
      return state;
  }
};

const qualityControlOptions: Reducer<ICustomFieldOptions[]> = (state = initialState.qualityControlOptions, action) => {
  switch (action.type) {
    case Type.RESET:
      return state;
    case Type.RESET_HARD:
      return initialState.qualityControlOptions;
    case Type.SET_QUALITY_CONTROL_OPTIONS:
      return (action as SetQualityControlOptionsAction).qualityControlOptions;
    default:
      return state;
  }
};

const buyerOptions: Reducer<ICustomFieldOptions[]> = (state = initialState.buyerOptions, action) => {
  switch (action.type) {
    case Type.RESET:
      return state;
    case Type.RESET_HARD:
      return initialState.buyerOptions;
    case Type.SET_BUYER_OPTIONS:
      return (action as SetBuyerOptionsAction).buyerOptions;
    default:
      return state;
  }
};

const advancedFilterColumns: Reducer<IAdvancedFilterColumn[]> = (state = initialState.advancedFilterColumns, action) => {
  switch (action.type) {
    case Type.RESET:
      return state;
    case Type.RESET_HARD:
      return initialState.advancedFilterColumns;
    case Type.SET_ADVANCED_FILTER_COLUMNS:
      return (action as SetAdvancedFilterColumnsAction).columns;
    default:
      return state;
  }
};

const areAdvancedFilterColumnsLoaded: Reducer<boolean> = (state = initialState.areAdvancedFilterColumnsLoaded, action) => {
  switch (action.type) {
    case Type.RESET:
      return state;
    case Type.RESET_HARD:
      return initialState.areAdvancedFilterColumnsLoaded;
    case Type.SET_ARE_ADVANCED_FILTER_COLUMNS_LOADED:
      return (action as SetAreAdvancedFilterColumnsLoadedAction).areAdvancedFilterColumnsLoaded;
    default:
      return state;
  }
};

const selectedReportsIds: Reducer<string[]> = (state = initialState.selectedReportsIds, action) => {
  switch (action.type) {
    case Type.RESET:
      return state;
    case Type.RESET_HARD:
      return initialState.selectedReportsIds;
    case Type.SET_MODE:
      return initialState.selectedReportsIds;
    case Type.SET_SELECTED_REPORTS_IDS:
      return (action as SetSelectedReportsIdsAction).selectedReportsIds;
    default:
      return state;
  }
};

export const reportsListStore = combineReducers({
  reports,
  mode,
  eciMode,
  isChangingMode,
  filterMode,
  filter,
  advancedFilter,
  reportCustomFieldSettings,
  areReportCustomFieldSettingsLoaded,
  aitexResultOptions,
  countryOptions,
  seasonOptions,
  officeOptions,
  divisionOptions,
  sectionOptions,
  brandOptions,
  qualityControlOptions,
  buyerOptions,
  advancedFilterColumns,
  areAdvancedFilterColumnsLoaded,
  grid: listGridStore.createListGridStore(Type, 'reportNumber', 'Descending'),
  selectedReportsIds
});
