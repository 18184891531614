import React, { Component, ComponentType } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { compose } from 'redux';
import { DataManager, Query } from '@syncfusion/ej2-data';
import { SortDirection } from '@syncfusion/ej2-grids';
import { Tabs, Tab } from 'react-bootstrap';

import { RootState } from '../../store';
import { IEmailsNotReceivedListContainerStateProps, IEmailsNotReceivedListContainerDispatchProps } from '../../interfaces/props/IEmailsNotReceivedListProps';
import * as emailsNotReceivedListStore from '../../store/modules/emailsNotReceivedList.store';
import * as listGridStore from '../../store/modules/listGrid.store';
import { URL } from '../../common/constants';
import { IMyRule } from '../../common/model/myRule.model';
import { FilterMode } from '../../common/model/enumerations/filterMode.model';
import { RuleCondition } from '../../common/model/enumerations/ruleCondition.model';
import { RuleOperator } from '../../common/model/enumerations/ruleOperator.model';
import { dataManagerService, oDataService, queryBuilderService } from '../../services';
import EmailsNotReceivedListFilter from './emailsNotReceivedListFilter/emailsNotReceivedListFilter';
import EmailsNotReceivedGrid from './emailsNotReceivedGrid/emailsNotReceivedGrid';
import { IEmailNotReceived } from '../../common/model/emailNotReceived.model';

type EmailsNotReceivedListContainerPropsType = PropsFromRedux & RouteComponentProps & WithTranslation;

class EmailsNotReceivedListContainer extends Component<EmailsNotReceivedListContainerPropsType> {
  public emailsNotReceivedDataManager: DataManager = null;
  public emailsNotReceivedQuery: Query = new Query()
    .select([
      'id', 'active', 'email', 'companyId', 'resultId'
    ]);

  public constructor(props: EmailsNotReceivedListContainerPropsType) {
    super(props);

    this.emailsNotReceivedDataManager = dataManagerService.buildDataManager(URL.ODATA.EMAILS_NOT_RECEIVED_FILTERS);

    this.props.reset();
  }

  public componentDidMount() {
    this.fetchEmailsNotReceived();
  }

  public componentWillUnmount() {
    this.props.reset();
  }

  public onFilterButtonClick() {
    this.props.setCurrentPage(emailsNotReceivedListStore.Type.SET_CURRENT_PAGE, 1);
    this.fetchEmailsNotReceived();
  }

  public onCurrentPageChange(currentPage: number) {
    this.props.setCurrentPage(emailsNotReceivedListStore.Type.SET_CURRENT_PAGE, currentPage);
    this.fetchEmailsNotReceived();
  }

  public onPageSizeChange(pageSize: number) {
    this.props.setPageSize(emailsNotReceivedListStore.Type.SET_PAGE_SIZE, pageSize);
    this.fetchEmailsNotReceived();
  }

  public onSortingChange(field: string, direction: SortDirection) {
    this.props.setSorting(emailsNotReceivedListStore.Type.SET_SORTING, field, direction);
    this.fetchEmailsNotReceived();
  }

  public saveEmailNotReceived(emailNotReceived: IEmailNotReceived) {
    const { isFetching, saveEmailNotReceived } = this.props;

    if (!isFetching) {
      saveEmailNotReceived(emailNotReceived);
    }
  }

  public render() {
    const {
      emailsNotReceived,
      filter,
      isFetching,
      searchDate,
      currentPage,
      pageSize,
      totalCount,
      sortField,
      sortDirection,
      setFilterActive,
      setFilterEmail,
      setFilterCompanyId,
      setFilterResultId,
      i18n
    } = this.props;

    return (
      <div>
        <div className='row'>
          <div className='col'>
            <h2>{i18n.t('emailsNotReceivedList.title')}</h2>
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <div className='mb-3'>
              <Tabs id='emailsNotReceivedListTabs' className='mb-3'>
                <Tab eventKey={FilterMode.SIMPLE} title={i18n.t('enums.filterMode.SIMPLE')}>
                  <EmailsNotReceivedListFilter
                    filter={filter}
                    isFetching={isFetching}
                    onActiveChange={setFilterActive}
                    onEmailChange={setFilterEmail}
                    onCompanyIdChange={setFilterCompanyId}
                    onResultIdChange={setFilterResultId}
                    onFilterButtonClick={() => this.onFilterButtonClick()}
                  />
                </Tab>
              </Tabs>
            </div>
            <EmailsNotReceivedGrid
              emailsNotReceived={emailsNotReceived}
              isFetching={isFetching}
              searchDate={searchDate}
              currentPage={currentPage}
              pageSize={pageSize}
              totalCount={totalCount}
              sortField={sortField}
              sortDirection={sortDirection}
              onCurrentPageChange={(cp: number) => this.onCurrentPageChange(cp)}
              onPageSizeChange={(ps: number) => this.onPageSizeChange(ps)}
              onSortingChange={(field: string, direction: SortDirection) => this.onSortingChange(field, direction)}
              saveEmailNotReceived={(emailNotReceived: IEmailNotReceived) => this.saveEmailNotReceived(emailNotReceived)}
            />
          </div>
        </div>
      </div>
    );
  }

  private fetchEmailsNotReceived() {
    const { isFetching, fetchEmailsNotReceived } = this.props;

    if (!isFetching) {
      fetchEmailsNotReceived(oDataService, this.emailsNotReceivedDataManager, this.buildQuery());
    }
  }

  private buildQuery(): Query {
    const { filter } = this.props;

    const myRule: IMyRule = {
      condition: RuleCondition.AND,
      myRules: []
    };
    if (filter.email) {
      myRule.myRules.push({
        field: 'email',
        operator: RuleOperator.CONTAINS,
        value: filter.email,
        ignoreCase: true
      });
    }
    if (filter.resultId && filter.resultId !== 'TODOS') {
      myRule.myRules.push({
        field: 'resultId',
        operator: RuleOperator.EQUAL,
        value: filter.resultId,
        ignoreCase: true
      });
    }
    if (filter.active != null) {
      myRule.myRules.push({
        field: 'active',
        operator: RuleOperator.EQUAL,
        value: filter.active
      });
    }
    if (filter.companyId) {
      myRule.myRules.push({
        field: 'companyId',
        operator: RuleOperator.EQUAL,
        value: filter.companyId
      });
    }

    return queryBuilderService.buildQuery(this.emailsNotReceivedDataManager, myRule, this.emailsNotReceivedQuery.clone());
  }
}

const mapStateToProps = (state: RootState): IEmailsNotReceivedListContainerStateProps => ({
  emailsNotReceived: state.emailsNotReceivedListStore.emailsNotReceived,
  filter: state.emailsNotReceivedListStore.filter,
  isFetching: state.emailsNotReceivedListStore.grid.isFetching,
  searchDate: state.emailsNotReceivedListStore.grid.searchDate,
  currentPage: state.emailsNotReceivedListStore.grid.currentPage,
  pageSize: state.emailsNotReceivedListStore.grid.pageSize,
  totalCount: state.emailsNotReceivedListStore.grid.totalCount,
  sortField: state.emailsNotReceivedListStore.grid.sortField,
  sortDirection: state.emailsNotReceivedListStore.grid.sortDirection
});

const mapDispatchToProps: IEmailsNotReceivedListContainerDispatchProps = {
  reset: emailsNotReceivedListStore.reset,
  resetHard: emailsNotReceivedListStore.resetHard,
  setFilterActive: emailsNotReceivedListStore.setFilterActive,
  setFilterEmail: emailsNotReceivedListStore.setFilterEmail,
  setFilterCompanyId: emailsNotReceivedListStore.setFilterCompanyId,
  setFilterResultId: emailsNotReceivedListStore.setFilterResultId,
  setCurrentPage: listGridStore.setCurrentPage,
  setPageSize: listGridStore.setPageSize,
  setSorting: listGridStore.setSorting,
  fetchEmailsNotReceived: emailsNotReceivedListStore.fetchEmailsNotReceived,
  saveEmailNotReceived: emailsNotReceivedListStore.saveEmailNotReceived
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default compose(
  withRouter,
  withTranslation(),
  connector
)(EmailsNotReceivedListContainer) as ComponentType;
