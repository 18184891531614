import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { DialogComponent } from '@aitex/tsx-extranet-dialogs';

import { IGenericConfirmationDialogProps } from '../../../interfaces/props/IDialogsProps';
import * as dialogUtils from '../../../utils/dialog.utils';

type GenericConfirmationDialogPropsType = IGenericConfirmationDialogProps & WithTranslation;

class GenericConfirmationDialog extends Component<GenericConfirmationDialogPropsType> {
  private readonly CANCEL_BUTTON_ID = 'genericConfirmationDialogCancel';
  private readonly OK_BUTTON_ID = 'genericConfirmationDialogOk';

  public componentDidUpdate() {
    dialogUtils.manageButtonsClick(this.OK_BUTTON_ID, () => this.props.onClose(), this.CANCEL_BUTTON_ID, () => this.props.onDismiss());
  }

  public render() {
    const { visible, onDismiss, i18n } = this.props;

    return (
      <DialogComponent
        header={i18n.t('dialogs.genericConfirmationDialog.title')}
        visible={visible}
        footerTemplate={dialogUtils.computeFooterTemplate(
          this.OK_BUTTON_ID,
          i18n.t('actions.ok'),
          this.CANCEL_BUTTON_ID,
          i18n.t('actions.cancel')
        )}
        onDismiss={() => onDismiss()}
      >
        <div>{i18n.t('dialogs.genericConfirmationDialog.text')}</div>
      </DialogComponent>
    );
  }
}

export default withTranslation()(GenericConfirmationDialog);
