import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { WithTranslation, withTranslation } from 'react-i18next';
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, Sort, Page, Resize, PageSettingsModel } from '@syncfusion/ej2-react-grids';

import { IReportsTabProps } from '../../../../interfaces/props/IRequestDetailProps';
import { IReport } from '../../../../common/model/report.model';
import * as pagerUtils from '../../../../utils/pager.utils';
import * as reportStatusUtils from '../../../../utils/reportStatus.utils';
import './reportsTab.scss';

type ReportsTabPropsType = IReportsTabProps & RouteComponentProps & WithTranslation;

class ReportsTab extends Component<ReportsTabPropsType> {
  public pageSettings: PageSettingsModel = {
    pageSize: pagerUtils.getMaxPageSize(),
    pageCount: pagerUtils.getPageCount(),
    pageSizes: pagerUtils.getPageSizes()
  };

  public getReportNumberColumnTemplate(report: IReport): JSX.Element {
    return <span className='e-link' onClick={() => this.props.history.push(`/report/${report.id}/view`)}>{report.reportNumber}</span>;
  }

  public getStatusColumnTemplate(report: IReport): JSX.Element {
    return <span title={reportStatusUtils.getLocalizedText(this.props.i18n, report.status)} className={reportStatusUtils.getIconCss(report.status)} />;
  }

  public render() {
    const { reports, i18n } = this.props;

    return (
      <div className='reports-grid'>
        <GridComponent
          dataSource={reports}
          allowSorting={true}
          allowPaging={true}
          allowResizing={true}
          pageSettings={this.pageSettings}
          locale={i18n.language}
        >
          <ColumnsDirective>
            <ColumnDirective
              headerText={i18n.t('report.reportNumber')}
              field='reportNumber'
              width='200'
              template={(report: IReport) => this.getReportNumberColumnTemplate(report)}
            />
            <ColumnDirective
              headerText={i18n.t('report.version')}
              field='version'
              width='100'
            />
            <ColumnDirective
              headerText={i18n.t('report.status')}
              field='status'
              width='100'
              template={(report: IReport) => this.getStatusColumnTemplate(report)}
            />
          </ColumnsDirective>
          <Inject services={[Sort, Page, Resize]} />
        </GridComponent>
      </div>
    );
  }
}

export default withRouter(withTranslation()(ReportsTab));
