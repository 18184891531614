import { IUserSetting } from '../common/model/userSetting.model';

export const getKeyToUserSettingFromlocalStorageKey = (currentUserId: string, localStorageKey: string, localStorageKeySeparator: string): string => {
  const splittedValues = localStorageKey.split(localStorageKeySeparator);
  const newKeyArray = splittedValues.filter((key: string) => key != currentUserId);
  const result = newKeyArray.join(localStorageKeySeparator);

  return result;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getInfoToSaveUserSetting = (currentUserId: string, localStorageKey: string, localStorageKeySeparator: string, value : any): IUserSetting => {
  const url = getKeyToUserSettingFromlocalStorageKey(currentUserId, localStorageKey, localStorageKeySeparator);

  const result: IUserSetting = {
    id: null,
    userId: currentUserId,
    url: url,
    jsonValue: (typeof value === 'string' || value instanceof String ? value as string : JSON.stringify(value))
  };

  return result;
}
