import { Action, Reducer, ActionCreator, combineReducers } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { DataManager, Query } from '@syncfusion/ej2-data';
import moment from 'moment';

import { RootState } from '..';
import * as listGridStore from './listGrid.store';
import { ISuppliersListState, ISuppliersListFilterState } from '../../interfaces/states/ISuppliersListState';
import { ISupplier } from '../../common/model/supplier.model';
import { BooleanFilter } from '../../common/model/enumerations/booleanFilter.model';
import { IODataService } from '../../services';

export enum Type {
  RESET = '@@suppliersList/RESET',
  SET_SUPPLIERS = '@@suppliersList/SET_SUPPLIERS',
  SET_FILTER = '@@suppliersList/SET_FILTER',
  SET_FILTER_CODE = '@@suppliersList/SET_FILTER_CODE',
  SET_FILTER_NAME = '@@suppliersList/SET_FILTER_NAME',
  SET_FILTER_ACTIVE = '@@suppliersList/SET_FILTER_ACTIVE',
  SET_IS_FETCHING = '@@suppliersList/SET_IS_FETCHING',
  SET_SEARCH_DATE = '@@suppliersList/SET_SEARCH_DATE',
  SET_CURRENT_PAGE = '@@suppliersList/SET_CURRENT_PAGE',
  SET_PAGE_SIZE = '@@suppliersList/SET_PAGE_SIZE',
  SET_TOTAL_COUNT = '@@suppliersList/SET_TOTAL_COUNT',
  SET_SORTING = '@@suppliersList/SET_SORTING'
}

const initialState: ISuppliersListState = {
  suppliers: [],
  filter: {
    code: null,
    name: null,
    active: BooleanFilter.ALL
  },
  grid: listGridStore.initialState
};

// Actions

export type Actions = ResetAction | SetSuppliersAction | SetFilterAction | SetFilterCodeAction | SetFilterNameAction | SetFilterActiveAction | listGridStore.SetIsFetchingAction
  | listGridStore.SetSearchDateAction | listGridStore.SetCurrentPageAction | listGridStore.SetPageSizeAction | listGridStore.SetTotalCountAction | listGridStore.SetSortingAction;

export type ResetAction = Action<Type.RESET>

export interface SetSuppliersAction extends Action<Type.SET_SUPPLIERS> {
  suppliers: ISupplier[];
}

export interface SetFilterAction extends Action<Type.SET_FILTER> {
  filter: ISuppliersListFilterState;
}

export interface SetFilterCodeAction extends Action<Type.SET_FILTER_CODE> {
  code: string;
}

export interface SetFilterNameAction extends Action<Type.SET_FILTER_NAME> {
  name: string;
}

export interface SetFilterActiveAction extends Action<Type.SET_FILTER_ACTIVE> {
  active: number;
}

export const reset: ActionCreator<ResetAction> = () => ({
  type: Type.RESET
});

export const setSuppliers: ActionCreator<SetSuppliersAction> = (suppliersLocal: ISupplier[]) => ({
  type: Type.SET_SUPPLIERS,
  suppliers: suppliersLocal
});

export const setFilter: ActionCreator<SetFilterAction> = (filterLocal: ISuppliersListFilterState) => ({
  type: Type.SET_FILTER,
  filter: filterLocal
});

export const setFilterCode: ActionCreator<SetFilterCodeAction> = (code: string) => ({
  type: Type.SET_FILTER_CODE,
  code
});

export const setFilterName: ActionCreator<SetFilterNameAction> = (name: string) => ({
  type: Type.SET_FILTER_NAME,
  name
});

export const setFilterActive: ActionCreator<SetFilterActiveAction> = (active: number) => ({
  type: Type.SET_FILTER_ACTIVE,
  active
});

export const fetchSuppliers: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (
  oDataService: IODataService,
  suppliersDataManager: DataManager,
  suppliersQuery: Query
) => (
    async (dispatch, getState) => {
      dispatch(listGridStore.setIsFetching(Type.SET_IS_FETCHING, true));

      const currentPageLocal = getState().suppliersListStore.grid.currentPage;
      const pageSizeLocal = getState().suppliersListStore.grid.pageSize;
      const sortFieldLocal = getState().suppliersListStore.grid.sortField;
      const sortDirectionLocal = getState().suppliersListStore.grid.sortDirection;

      let query = suppliersQuery.page(currentPageLocal, pageSizeLocal);
      if (sortFieldLocal && sortDirectionLocal) {
        query = sortDirectionLocal === 'Ascending' ? query.sortBy(sortFieldLocal) : query.sortByDesc(sortFieldLocal);
      }
      const suppliersResponse = await oDataService.executeQueryWithCount<ISupplier>(suppliersDataManager, query);
      const suppliersLocal = suppliersResponse.result;
      dispatch(setSuppliers(suppliersLocal));
      dispatch(listGridStore.setSearchDate(Type.SET_SEARCH_DATE, moment()));
      dispatch(listGridStore.setTotalCount(Type.SET_TOTAL_COUNT, suppliersResponse.count));
      dispatch(listGridStore.setIsFetching(Type.SET_IS_FETCHING, false));
    }
  );

// Reducers

const suppliers: Reducer<ISupplier[]> = (state = initialState.suppliers, action) => {
  switch (action.type) {
    case Type.RESET:
      return state;
    case Type.SET_SUPPLIERS:
      return (action as SetSuppliersAction).suppliers;
    default:
      return state;
  }
};

const filter: Reducer<ISuppliersListFilterState> = (state = initialState.filter, action) => {
  switch (action.type) {
    case Type.RESET:
      return state;
    case Type.SET_FILTER:
      return (action as SetFilterAction).filter;
    case Type.SET_FILTER_CODE:
      return { ...state, code: (action as SetFilterCodeAction).code };
    case Type.SET_FILTER_NAME:
      return { ...state, name: (action as SetFilterNameAction).name };
    case Type.SET_FILTER_ACTIVE:
      return { ...state, active: (action as SetFilterActiveAction).active };
    default:
      return state;
  }
};

export const suppliersListStore = combineReducers({
  suppliers,
  filter,
  grid: listGridStore.createListGridStore(Type, 'name', 'Ascending')
});
