import { IAdvancedFilter } from '../common/model/advancedFilter.model';
import { IAdvancedFilterColumn } from '../common/model/advancedFilterColumn.model';
import { URL } from '../common/constants';
import * as services from '.';

export interface IAdvancedFiltersService {
  fetch(id: string): Promise<IAdvancedFilter>;
  fetchByShareId(shareId: string): Promise<IAdvancedFilter>;
  create(advancedFilter: IAdvancedFilter): Promise<IAdvancedFilter>;
  createShared(advancedFilter: IAdvancedFilter): Promise<IAdvancedFilter>;
  update(advancedFilter: IAdvancedFilter): Promise<IAdvancedFilter>;
  deactivate(id: string): Promise<void>;
  activateSharing(id: string): Promise<IAdvancedFilter>;
  deactivateSharing(id: string): Promise<IAdvancedFilter>;
  fetchColumns(sourceType: number): Promise<IAdvancedFilterColumn[]>;
}

class AdvancedFiltersService implements IAdvancedFiltersService {
  public constructor(private httpClient: services.IHttpClient) {

  }

  public fetch(id: string): Promise<IAdvancedFilter> {
    return this.httpClient.getCustom<IAdvancedFilter>(`${URL.WEB.BASE}${URL.WEB.ADVANCED_FILTERS}/${id}`);
  }

  public fetchByShareId(shareId: string): Promise<IAdvancedFilter> {
    return this.httpClient.getCustom<IAdvancedFilter>(`${URL.WEB.BASE}${URL.WEB.ADVANCED_FILTERS}/byShareId/${shareId}`);
  }

  public create(advancedFilter: IAdvancedFilter): Promise<IAdvancedFilter> {
    return this.httpClient.postCustom(`${URL.WEB.BASE}${URL.WEB.ADVANCED_FILTERS}`, advancedFilter);
  }

  public createShared(advancedFilter: IAdvancedFilter): Promise<IAdvancedFilter> {
    return this.httpClient.postCustom(`${URL.WEB.BASE}${URL.WEB.ADVANCED_FILTERS}/shared`, advancedFilter);
  }

  public update(advancedFilter: IAdvancedFilter): Promise<IAdvancedFilter> {
    return this.httpClient.putCustom(`${URL.WEB.BASE}${URL.WEB.ADVANCED_FILTERS}/${advancedFilter.id}`, advancedFilter);
  }

  public deactivate(id: string): Promise<void> {
    return this.httpClient.deleteCustom(`${URL.WEB.BASE}${URL.WEB.ADVANCED_FILTERS}/${id}`);
  }

  public activateSharing(id: string): Promise<IAdvancedFilter> {
    return this.httpClient.postCustom(`${URL.WEB.BASE}${URL.WEB.ADVANCED_FILTERS}/${id}/activateSharing`);
  }

  public deactivateSharing(id: string): Promise<IAdvancedFilter> {
    return this.httpClient.postCustom(`${URL.WEB.BASE}${URL.WEB.ADVANCED_FILTERS}/${id}/deactivateSharing`);
  }

  public async fetchColumns(sourceType: number): Promise<IAdvancedFilterColumn[]> {
    try {
      return await this.httpClient.getCustom(`${URL.WEB.BASE}${URL.WEB.ADVANCED_FILTERS}/columns/${sourceType}`);
    } catch (error) {
      return [];
    }
  }
}

export const advancedFiltersService = new AdvancedFiltersService(services.httpClient);
