import { FormValidator, FormEventArgs } from '@syncfusion/ej2-react-inputs';

import { IErrors } from '../common/model/errors.model';

export const configureFormValidator = (id: string, rules: { [name: string]: { [rule: string]: unknown } }): FormValidator => {
  return new FormValidator(`#${id}`, { rules });
};

export const validationComplete = (args: FormEventArgs, isValidatingAll: boolean, errors: IErrors, auxErrors: IErrors, onErrorsChange: (errors: IErrors) => void): void => {
  if (args.errorElement) {
    if (!args.errorElement.className.includes('d-none')) {
      args.errorElement.className += ' d-none';
    }
    if (!args.element.parentElement.className.includes('e-error')) {
      args.element.parentElement.className += ' e-error';
    }
    if (isValidatingAll) {
      auxErrors[args.inputName] = [args.message];
    } else {
      onErrorsChange({ ...errors, [args.inputName]: [args.message] });
    }
  } else {
    if (args.element.parentElement.className.includes('e-error')) {
      args.element.parentElement.className = args.element.parentElement.className.replace('e-error', '');
    }
    const { [args.inputName]: aux, ...restOfErrors } = errors;
    onErrorsChange(restOfErrors);
  }
};

export const clearErrors = (formValidator: FormValidator): void => {
  if (formValidator !== undefined && formValidator !== null) {
    const rules = formValidator.rules;
    const propertyNames = Object.getOwnPropertyNames(rules);
    propertyNames.forEach((rule) => {
      const element = formValidator.getInputElement(rule);
      if (element.parentElement.className.includes('e-error')) {
        element.parentElement.className = element.parentElement.className.replace('e-error', '');
      }
    });
  }
};
