import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Sort,
  Page,
  Toolbar,
  ExcelExport,
  ExcelExportProperties,
  ExportType,
  Resize,
  DetailRow,
  SortSettingsModel,
  PagerComponent,
  EditSettingsModel,
  Edit,
  Column
} from '@syncfusion/ej2-react-grids';
import { ItemModel, ClickEventArgs } from '@syncfusion/ej2-navigations';
import { CheckBoxComponent, CheckBox } from '@syncfusion/ej2-react-buttons';

import { IUnecosGridProps } from '../../../interfaces/props/IUnecosListProps';
import { EXCEL_EXPORT_ACTION_ID, EXCEL_ADD_ACTION_ID, EXCEL_EDIT_ACTION_ID } from '../../../common/constants/gridActionId.constants';
import { IUneco } from '../../../common/model/uneco.model';
import * as gridUtils from '../../../utils/grid.utils';
import * as pagerUtils from '../../../utils/pager.utils';
import * as sortUtils from '../../../utils/sort.utils';
import * as unecoCategoryNameUtils from '../../../utils/unecoCategoryName.utils';
import { enumToArray } from '../../../utils/enum.utils';
import { DropDownListComponent, FieldSettingsModel, DropDownList } from '@syncfusion/ej2-react-dropdowns';
import { TextBox } from '@syncfusion/ej2-react-inputs';
import { UnecoCategoryName } from '../../../common/model/enumerations/unecoCategoryName.model';
import { dialogsService } from '@aitex/tsx-extranet-dialogs';
import { DialogId } from '../../../common/model/enumerations/dialogId.model';
import { notifySuccess, notifyError } from '../../../utils/toast.utils';
import { unecosService } from '../../../services';
import './unecosGrid.scss';

type UnecosGridPropsType = IUnecosGridProps & RouteComponentProps & WithTranslation;

class UnecosGrid extends Component<UnecosGridPropsType> {
  public gridComponent: GridComponent = null;
  public toolbarOptions: ItemModel[] | string[] = [];
  public sortOptions: SortSettingsModel = null;
  public editOptions: EditSettingsModel = { allowAdding: true, allowEditing: true, mode: 'Dialog' };

  public pagerComponent: PagerComponent = null;

  public categoryNameOptions: Record<string, string | number>[] = [];
  public categoryNameFields: FieldSettingsModel = { text: 'text', value: 'value', iconCss: 'iconCss' };

  public excepExportType: ExportType = 'AllPages';
  public excelExportProperties: ExcelExportProperties = { exportType: this.excepExportType };

  public isNew = false;

  public initialUneco: IUneco = null;
  public rowIndex?: number = null;
  public modUneco: IUneco = null;
  public isReload = false;

  public constructor(props: UnecosGridPropsType) {
    super(props);

    const { sortField, sortDirection, i18n } = this.props;

    const allCategoryNameOptions = enumToArray(UnecoCategoryName, 'string', 'text', 'value').map((item) => ({
      ...item,
      text: item.value,
      iconCss: unecoCategoryNameUtils.getIconCss(item.value as string)
    }));

    this.categoryNameOptions = allCategoryNameOptions.filter(item => item.text !== 'TODOS');

    this.toolbarOptions = [
      {
        tooltipText: i18n.t('syncfusion.grid.Add'),
        id: EXCEL_ADD_ACTION_ID,
        prefixIcon: 'e-btn-icon e-add e-icons e-icon-left'
      },
      {
        tooltipText: i18n.t('syncfusion.grid.Edit'),
        id: EXCEL_EDIT_ACTION_ID,
        prefixIcon: 'e-btn-icon e-edit e-icons e-icon-left'
      },
      {
        tooltipText: i18n.t('syncfusion.grid.Excelexport'),
        id: EXCEL_EXPORT_ACTION_ID,
        prefixIcon: 'icon icon-file-excel-o'
      }
    ];

    this.sortOptions = {
      columns: [{ field: sortField, direction: sortDirection }]
    };
  }

  public componentDidUpdate(prevProps: UnecosGridPropsType) {
    gridUtils.manageSpinner(this.gridComponent, prevProps.isFetching, this.props.isFetching);
    gridUtils.manageSorting(this.gridComponent, prevProps.isFetching, this.props.isFetching, this.props.sortField, this.props.sortDirection);
  }

  public onToolbarClick(args: ClickEventArgs) {
    if (args.item.id === EXCEL_EXPORT_ACTION_ID) {
      this.gridComponent.excelExport(this.excelExportProperties);
    }

    if (args.item.id === EXCEL_ADD_ACTION_ID) {
      this.gridComponent.addRecord();
    }

    if (args.item.id === EXCEL_EDIT_ACTION_ID) {
      this.gridComponent.startEdit();
    }
  }

  public onGridComponentDataBound() {
    const { currentPage, pageSize, totalCount, searchDate, i18n } = this.props;

    pagerUtils.refreshPager(this.pagerComponent, currentPage, pageSize, totalCount, searchDate, i18n);
  }

  public async onGridComponentActionBegin(args: any) {
    if (args.requestType === 'sorting') {
      this.gridComponent.getColumnByField(args.columnName).sortComparer = (reference, comparer) =>
        sortUtils.gridSortComparer(reference, comparer, args.direction);

      gridUtils.manageSortingChange(args, this.props.sortField, this.props.sortDirection, this.props.onSortingChange);
    }

    if (args.requestType === 'beginEdit' || args.requestType === 'add') {
      this.showOrHideActiveField(true);
    }

    if (args.requestType === 'add') {
      this.isNew = true;
    } else if (args.requestType === 'beginEdit') {
      this.isNew = false;

      if (this.initialUneco == null) {
        this.rowIndex = args.rowIndex;
        this.initialUneco = {
          id: (args.rowData as any).id,
          active: (args.rowData as any).active,
          code: (args.rowData as any).code,
          name: (args.rowData as any).name,
          categoryName: (args.rowData as any).categoryName,
          emails: (args.rowData as any).emails,
          emailCoordinator: (args.rowData as any).emailCoordinator
        };
      }
    }
  }

  public async onGridComponentActionCompete(args: any) {
    const { i18n } = this.props;

    if (args.requestType === 'beginEdit' || args.requestType === 'add') {
      const dialog = args.dialog;
      dialog.width = 800;
      dialog.header = args.requestType === 'beginEdit' ? i18n.t('unecosList.editUneco') + ' ' + this.initialUneco.code + ' - ' + this.initialUneco.categoryName : i18n.t('unecosList.newUneco');

      dialog.buttons = [
        {
          buttonModel: { content: i18n.t('syncfusion.grid.Update'), cssClass: 'e-control e-btn e-lib e-primary e-flat' },
          click: () => {
            this.modUneco = {
              id: args.primaryKeyValue && args.primaryKeyValue.length > 0 ? args.primaryKeyValue[0] : null,
              active: args.form.querySelector('[id$="active"]').checked,
              code: args.form.querySelector('[id$="code"]').value,
              name: args.form.querySelector('[id$="code"]').value,
              categoryName: args.form.querySelector('[id$="categoryName"]').value,
              emails: args.form.querySelector('[id$="emails"]').ej2_instances[0].value,
              emailCoordinator: args.form.querySelector('[id$="emailCoordinator"]').value
            };

            const formObj = args.form['ej2_instances'][0];
            const formIsValid = formObj.validate();

            if (formIsValid) {
              this.saveCommon(this.modUneco, i18n.t('unecosList.messages.acceptSuccess'), i18n.t('unecosList.messages.cannotAccept'));

              this.showOrHideActiveField(false);

              this.gridComponent.editModule.closeEdit();
            }
          }
        },
        {
          buttonModel: { content: i18n.t('syncfusion.grid.Cancel'), cssClass: 'e-control e-btn e-lib e-flat' },
          click: () => {
            this.showOrHideActiveField(false);
            this.cancel();
            this.gridComponent.editModule.closeEdit();
          }
        }        
      ];
      dialog.refresh();

      if ((args.requestType === 'beginEdit' || args.requestType === 'add')) {
        if (this.modUneco != null && args.rowData.id != this.modUneco.id) {
          this.modUneco = null;
        }

        let categoryName = this.categoryNameOptions[0].value;
        let active = true;
        let emails = '';
        let code = '';
        let emailCoordinator = '';

        categoryName = this.modUneco != null || args.rowData.categoryName != null ? this.modUneco != null ? this.modUneco.categoryName : args.rowData.categoryName : categoryName;
        active = this.modUneco != null || args.rowData.active != null ? (this.modUneco != null && this.modUneco.active != null && this.modUneco != undefined) ? this.modUneco.active : args.rowData.active : active;
        emails = this.modUneco != null || args.rowData.emails != null ? this.modUneco != null ? this.modUneco.emails : args.rowData.emails : emails;
        code = this.modUneco != null || args.rowData.code != null ? this.modUneco != null ? this.modUneco.code : args.rowData.code: code;
        emailCoordinator = this.modUneco != null || args.rowData.emailCoordinator != null ? this.modUneco != null ? this.modUneco.emailCoordinator : args.rowData.emailCoordinator : emailCoordinator;

        (args as any).form.querySelector('[id$="code"]').value = code;
        (args as any).form.querySelector('[id$="emailCoordinator"]').value = emailCoordinator;

        new DropDownList({
          value: categoryName,
          dataSource: this.categoryNameOptions,
          fields: this.categoryNameFields
        }, args.form.elements.namedItem('categoryName') as HTMLInputElement);

        new CheckBox({
          checked: active,
        }, args.form.elements.namedItem('active') as HTMLInputElement);

        new TextBox({
          value: emails,
          multiline: true,
          htmlAttributes: { ['style']: 'height: 100px; resize: none;' },
        }, args.form.elements.namedItem('emails') as HTMLInputElement);

        ((args as any).form.querySelector('[id$="emails"]').ej2_instances[0] as TextBox).value = emails;

        const separatorsNoteComponent = document.createElement('label');
        separatorsNoteComponent.setAttribute('class', 'separatorsNote');
        separatorsNoteComponent.innerHTML = i18n.t('emailSeparators');

        const obj = (args as any).form.querySelector('[id$="emails"]').ej2_instances[0] as any;
        obj.element.parentElement.parentElement.appendChild(separatorsNoteComponent);
      }
    }
  }

  private showOrHideActiveField(visible: boolean) {
    this.showOrHideField('active', visible);
  }

  private showOrHideField(fieldName: string, visible: boolean) {
    if (this.gridComponent) {
      const cols: Column[] = this.gridComponent.columns as Column[];
      for (const col of cols) {
        if (col.field === fieldName) {
          col.visible = visible;
        }
      }
    }
  }

  public getActiveColumnTemplate(uneco: IUneco): JSX.Element {
    return <CheckBoxComponent checked={uneco.active} disabled={true} onClick={() => { return false; } } />;
  }

  public getActiveEditTemplate(uneco: IUneco): JSX.Element {
    const { i18n } = this.props;

    if (this.isNew) {
      return (<div className="e-float-input e-control-wrapper e-valid-input">
        <CheckBoxComponent checked={this.isNew} />
        <span className="e-float-line"></span>
        <label className="e-float-text e-label-top">{i18n.t('uneco.active')}</label>
      </div>);
    }

    return (<div className="e-float-input e-control-wrapper e-valid-input">
      <CheckBoxComponent checked={uneco.active} />
      <span className="e-float-line"></span>
      <label className="e-float-text e-label-top">{i18n.t('uneco.active')}</label>
    </div>);
  }

  public getCategoryNameEditTemplate(uneco: IUneco): JSX.Element {
    const { i18n } = this.props;

    return (<div className="e-float-input e-control-wrapper e-valid-input">
      <DropDownListComponent dataSource={this.categoryNameOptions} fields={this.categoryNameFields} value={uneco.categoryName} popupHeight={150}></DropDownListComponent>
      <span className="e-float-line"></span>
      <label className="e-float-text e-label-top">{i18n.t('uneco.categoryName')}</label>
    </div>);
  }

  public onPagerComponentClick(e: any) {
    if (e.currentPage !== this.props.currentPage) {
      this.props.onCurrentPageChange(e.currentPage);
    }
  }

  public onPageSizesDropdownChange(e: any) {
    if (e.pageSize !== this.props.pageSize) {
      this.props.onPageSizeChange(e.pageSize);
    }
  }

  private cancel() {
    this.rowIndex = null;
    this.initialUneco = null;
    this.modUneco = null;
    this.isReload = false;
  }

  private async saveCommon(uneco: IUneco, successMessage: string, errorMessage: string): Promise<void> {
    await dialogsService.openDialog(DialogId.GENERIC_CONFIRMATION)
      .then(async () => {
        try {
          const savedUneco = uneco.id !== undefined && uneco.id != null ? await unecosService.update(uneco) : await unecosService.create(uneco);

          this.isNew = false;
          this.props.saveUneco(savedUneco);
          this.cancel();

          notifySuccess(successMessage);
        } catch (error: any) {
          let newErrorMessage = errorMessage;
          if (error && error['UnknownError']) {
            const valueError = error['UnknownError'];
            if (Array.isArray(valueError)) {
              newErrorMessage = valueError.join(' ');
            } else {
              newErrorMessage = valueError;
            }
          }

          this.reloadInfo();
          notifyError(newErrorMessage);
        } finally {
          gridUtils.manageSpinner(this.gridComponent, true, false);
        }
      })
      .catch(() => {
        gridUtils.manageSpinner(this.gridComponent, true, false);
        this.reloadInfo();
      });
  }

  private reloadInfo() {
    this.isReload = true;

    if (this.isNew) {
      this.gridComponent.addRecord();
    } else {
      this.gridComponent.selectRow(this.rowIndex);
      this.gridComponent.startEdit();
    }
  }

  public render() {
    const { unecos, i18n } = this.props;

    const codeValidationRules = { required: true,  minLength: 3, maxLength: 3 };

    return (
      <div className='unecos-grid'>
        <GridComponent
          dataSource={unecos}
          toolbar={this.toolbarOptions}
          allowSorting={true}
          allowPaging={true}   /* Si no se incluye este paginador (oculto por css), no se muestra el dropdown en el customizado */
          allowExcelExport={true}
          allowResizing={true}
          editSettings={this.editOptions}
          sortSettings={this.sortOptions}
          pageSettings={{ pageSize: pagerUtils.getMaxPageSize() }}
          locale={i18n.language}
          ref={(grid: GridComponent) => this.gridComponent = grid}
          toolbarClick={(args) => this.onToolbarClick(args)}
          dataBound={() => this.onGridComponentDataBound()}
          actionBegin={(args) => this.onGridComponentActionBegin(args)}
          actionComplete={(args) => this.onGridComponentActionCompete(args)}
        >
          <ColumnsDirective>
            <ColumnDirective
              headerText={this.props.i18n.t('uneco.id')}
              field='id'
              width='0'
              isPrimaryKey={true}
              visible={false}
              allowEditing={false}
            />
            <ColumnDirective
              headerText={this.props.i18n.t('uneco.code')}
              field='code'
              textAlign='Center'
              width='35'
              validationRules={codeValidationRules}
            />
            <ColumnDirective
              headerText={this.props.i18n.t('uneco.categoryName')}
              field='categoryName'
              textAlign='Center'
              width='50'
            />
            <ColumnDirective
              headerText={this.props.i18n.t('uneco.active')}
              field='active'
              width='35'
              template={(uneco: IUneco) => this.getActiveColumnTemplate(uneco)}
              visible={false}
            />
            <ColumnDirective
              headerText={this.props.i18n.t('uneco.emails')}
              field='emails'
              width='350'
            />
            <ColumnDirective
              headerText={this.props.i18n.t('uneco.emailCoordinator')}
              field='emailCoordinator'
              width='100'
            />
          </ColumnsDirective>
          <Inject services={[Sort, Page, Toolbar, ExcelExport, Resize, DetailRow, Edit]} />
        </GridComponent>
        {unecos.length ?
          <PagerComponent
            pageSize={pagerUtils.getDefaultPageSize()}
            pageCount={pagerUtils.getPageCount()}
            pageSizes={pagerUtils.getPageSizes()}
            ref={(pagerComponent) => this.pagerComponent = pagerComponent}
            click={(e) => this.onPagerComponentClick(e)}
            dropDownChanged={(e) => this.onPageSizesDropdownChange(e)}
          />
          :
          null
        }
      </div>
    );
  }
}

export default withRouter(withTranslation()(UnecosGrid));
