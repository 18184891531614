import { URL } from '../common/constants';
import { ISupplier } from '../common/model/supplier.model';
import * as services from '.';

export interface ISuppliersService {
  fetch(id: string): Promise<ISupplier>;
  create(supplier: ISupplier): Promise<ISupplier>;
  update(supplier: ISupplier): Promise<ISupplier>;
}

class SuppliersService implements ISuppliersService {
  public constructor(private httpClient: services.IHttpClient) {

  }

  public fetch(id: string): Promise<ISupplier> {
    return this.httpClient.getCustom(`${URL.WEB.BASE}${URL.WEB.SUPPLIERS}/${id}`);
  }

  public create(supplier: ISupplier): Promise<ISupplier> {
    return this.httpClient.postCustom(`${URL.WEB.BASE}${URL.WEB.SUPPLIERS}`, supplier);
  }

  public update(supplier: ISupplier): Promise<ISupplier> {
    return this.httpClient.putCustom(`${URL.WEB.BASE}${URL.WEB.SUPPLIERS}/${supplier.id}`, supplier);
  }
}

export const suppliersService = new SuppliersService(services.httpClient);
