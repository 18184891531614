import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { AutoCompleteComponent, ChangeEventArgs, FilteringEventArgs, FieldSettingsModel } from '@syncfusion/ej2-react-dropdowns';
import { Query } from '@syncfusion/ej2-data';
import { SortOrder } from '@syncfusion/ej2-navigations';
import { DialogComponent } from '@aitex/tsx-extranet-dialogs';

import { IShareAdvancedFilterDialogProps } from '../../../interfaces/props/IDialogsProps';
import { ICompanyCombo } from '../../../common/model/company.model';
import { IErrors } from '../../../common/model/errors.model';
import { RuleOperator } from '../../../common/model/enumerations/ruleOperator.model';
import { advancedFiltersService } from '../../../services';
import { debounce } from '../../../utils/common.utils';
import * as dialogUtils from '../../../utils/dialog.utils';
import { IAdvancedFilter } from '../../../common/model/advancedFilter.model';
import { AdvancedFilterType } from '../../../common/model/enumerations/advancedFilterType.model';

type ShareAdvancedFilterDialogPropsType = IShareAdvancedFilterDialogProps & WithTranslation;

type ShareAdvancedFilterDialogStateType = IShareAdvancedFilterDialogState;

class ShareAdvancedFilterDialog extends Component<ShareAdvancedFilterDialogPropsType, ShareAdvancedFilterDialogStateType> {
  public readonly SHARE_ADVANCED_FILTER_DIALOG_FORM_ID = 'shareAdvancedFilterDialogForm';

  public companiesAutoCompleteComponent: AutoCompleteComponent = null;
  public companiesQuery = new Query()
    .select(['id', 'name'])
    .where('active', RuleOperator.EQUAL, true)
    .where('isLocked', RuleOperator.EQUAL, false)
    .take(10);
  public companiesFields: FieldSettingsModel = { value: 'name' };
  public companiesSortOrder: SortOrder = 'Ascending';
  public companiesFilteringEvent: FilteringEventArgs = null;
  public companiesFilteringDebounced = debounce(
    () => {
      if (this.companiesFilteringEvent) {
        this.companiesAutoCompleteComponent.filter(
          this.companiesAutoCompleteComponent.dataSource,
          this.companiesAutoCompleteComponent.query.clone().where('name', RuleOperator.CONTAINS, this.companiesFilteringEvent.text, true)
        );
      }
    },
    250
  );

  private readonly CANCEL_BUTTON_ID = 'shareAdvancedFilterDialogCancel';
  private readonly OK_BUTTON_ID = 'shareAdvancedFilterDialogOk';

  private readonly CANNOT_SHARE_ADVANCED_FILTER_ERROR: IErrors = { UnknownError: ['dialogs.shareAdvancedFilterDialog.error'] };

  private initialState: ShareAdvancedFilterDialogStateType = {
    selectedCompany: null,
    isLoading: false
  };

  public constructor(props: ShareAdvancedFilterDialogPropsType) {
    super(props);

    this.state = this.initialState;
  }

  public componentDidUpdate(prevProps: ShareAdvancedFilterDialogPropsType) {
    const { visible } = this.props;

    if (prevProps.visible && !visible) {
      this.setState(this.initialState);
    }

    dialogUtils.manageButtonsClick(this.OK_BUTTON_ID, () => this.shareAdvancedFilter(), this.CANCEL_BUTTON_ID, () => this.dismiss());
  }

  public async shareAdvancedFilter() {
    try {
      this.setState({ isLoading: true });

      if (!this.props.advancedFilter || !this.state.selectedCompany) {
        throw this.CANNOT_SHARE_ADVANCED_FILTER_ERROR;
      }

      const createdAdvancedFilter = await advancedFiltersService.createShared({
        ...this.props.advancedFilter,
        type: AdvancedFilterType.COMPANY,
        companyId: this.state.selectedCompany.id
      });
      this.close(createdAdvancedFilter);
    } catch (error) {
      let errors = error as IErrors;
      if (errors.UnknownError || (!errors.UnknownError && !errors.ControlledError)) {
        errors = this.CANNOT_SHARE_ADVANCED_FILTER_ERROR;
      }
      this.dismiss(errors);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  public close(advancedFilter: IAdvancedFilter) {
    this.props.onClose(advancedFilter);
  }

  public dismiss(reason?: IErrors) {
    this.props.onDismiss(reason);
  }

  public onCompanyChange(e: ChangeEventArgs) {
    const selectedCompany = e.itemData ? (e.itemData as ICompanyCombo) : null;
    this.setState({ selectedCompany });
  }

  public render() {
    const { visible, companiesDataManager, i18n } = this.props;

    return (
      <DialogComponent
        header={i18n.t('dialogs.shareAdvancedFilterDialog.title')}
        visible={visible}
        width='480px'
        footerTemplate={dialogUtils.computeFooterTemplate(
          this.OK_BUTTON_ID,
          i18n.t('actions.ok'),
          this.CANCEL_BUTTON_ID,
          i18n.t('actions.cancel'),
          this.state.isLoading,
          this.isSimulationDisabled()
        )}
        onDismiss={() => this.dismiss()}
      >
        <form>
          <div className='row'>
            <div className='col'>
              <div className='form-group'>
                <span>{i18n.t('dialogs.shareAdvancedFilterDialog.text')}</span>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              <div className='form-group'>
                <div className='autocomplete-input'>
                  <div className='autocomplete-label' style={{ minWidth: 'auto' }}>
                    <span className='icon icon-company' title={i18n.t('dialogs.shareAdvancedFilterDialog.company')} />
                  </div>
                  <AutoCompleteComponent
                    query={this.companiesQuery}
                    dataSource={companiesDataManager}
                    fields={this.companiesFields}
                    sortOrder={this.companiesSortOrder}
                    allowCustom={false}
                    value={this.state.selectedCompany && this.state.selectedCompany.name}
                    ref={(autoCompleteComponent: AutoCompleteComponent): AutoCompleteComponent => this.companiesAutoCompleteComponent = autoCompleteComponent}
                    change={(e) => this.onCompanyChange(e)}
                    filtering={(e) => {
                      this.companiesFilteringEvent = e;
                      this.companiesFilteringEvent.cancel = true;
                      this.companiesFilteringDebounced();
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </DialogComponent>
    );
  }

  private isSimulationDisabled(): boolean {
    return !this.state.selectedCompany || this.state.isLoading;
  }
}

interface IShareAdvancedFilterDialogState {
  selectedCompany: ICompanyCombo;
  isLoading: boolean;
}

export default withTranslation()(ShareAdvancedFilterDialog);
