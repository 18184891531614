import { Action, ActionCreator, combineReducers, AnyAction } from 'redux';
import { Query } from '@syncfusion/ej2-data';
import { IFavoriteFilter, ISelectStatus } from '../../interfaces';
import { IdToDownload } from '../../interfaces/states/IReportListState';
import { getDate } from '../../helpers/date.helpers';

// Type Names to use in reducer
enum Type {
  SET_FAVORITE_FILTER = '@@filters/SET_FAVORITE_FILTER',
  SAVE_REPORT_ALL_FILTERS = '@@filters/SAVE_REPORT_ALL_FILTERS'
}

// All the interfaces that you need
export interface ReportFilters {
  reportNumber: string; //order
  entryDate: Date[];
  expectedDate: Date[];
  reference: string; // muestras / samples
  status: string;  // status
  client: string; // client
  query: Query;
  clientId: string;
  endDate: Date[];
  expirationDate: Date[];
  requerimentStandard: string;

  lastSearchDate?: Date; // Fecha en que se buscaron los datos actuales
  page: string; // Nombre de la Página donde está el filtro
  pageSize: number; // Nº de elementos visibles en el Grid
  isFetching: boolean; // Indica si se está realizando la búsqueda
  mustShowSpinnerGrid: boolean; // Indica si debe mostrar el Spinner del Grid
}

export interface IPageReportFilters {
  //mustSearch: boolean;
  currentPage: string;
  previousPage: string;
  statusItems: ISelectStatus[];
  idsToDownload: IdToDownload[];
  reportFilters: IPagesReportFilters;
}

export interface IPagesReportFilters {
  [key: string]: ReportFilters;
  'all': ReportFilters;
  'inprogress': ReportFilters;
  'finalized': ReportFilters;
}

// State interface
export interface FiltersState {
  reportFilter: IPageReportFilters;
  favoriteFilter: IFavoriteFilter;
}

function initializeReportFilters(defaultStatus: string): ReportFilters {
  const reportFilterLocal: ReportFilters = {
    reportNumber: null,
    entryDate: [],
    expectedDate: [],
    client: null,
    reference: null,
    status: defaultStatus,
    query: null,
    clientId: null,
    endDate: [],
    expirationDate: [],
    requerimentStandard: null,
    lastSearchDate: null,
    pageSize: 25,
    page: defaultStatus,
    isFetching: false,
    mustShowSpinnerGrid: false
  };

  return reportFilterLocal;
}

function initializeFinalizedReportFilters(): ReportFilters {
  const reportFilterLocal: ReportFilters = initializeReportFilters('finalized');
  reportFilterLocal.endDate = [getDate.last60days(), getDate.today()];

  return reportFilterLocal;
}

export const defaultFilterState: FiltersState = {
  reportFilter: {
    currentPage: 'all',
    previousPage: null,
    statusItems: undefined,
    idsToDownload: [],
    reportFilters: {
      all: initializeReportFilters('all'),
      inprogress: initializeReportFilters('inprogress'),
      finalized: initializeFinalizedReportFilters(),
    }
  },
  favoriteFilter: {
    condition: 'and',
    rules: [],
    query: null
  },
};

// Actions

export interface SaveFavoriteFilterAction extends Action<Type.SET_FAVORITE_FILTER> {
  value: IFavoriteFilter;
}

export interface SaveReportAllFiltersAction extends Action<Type.SAVE_REPORT_ALL_FILTERS> {
  value: IPageReportFilters;
}

export const saveFavoriteFilter: ActionCreator<SaveFavoriteFilterAction> = (value: IFavoriteFilter) => ({
  type: Type.SET_FAVORITE_FILTER,
  value
});

export const saveReportAllFilters: ActionCreator<SaveReportAllFiltersAction> = (value: IPageReportFilters) => ({
  type: Type.SAVE_REPORT_ALL_FILTERS,
  value
});

// Reducers
const favoriteFilter = (
  state = defaultFilterState.favoriteFilter,
  action: AnyAction
): IFavoriteFilter => {
  if (action.type === Type.SET_FAVORITE_FILTER) {
    return { ...state, ...action.value };
  }

  return state;
};

const reportFilter = (
  state = defaultFilterState.reportFilter,
  action: AnyAction
): IPageReportFilters => {
  if (action.type === Type.SAVE_REPORT_ALL_FILTERS) {
    const pageReportFilters1: IPageReportFilters = action.value;
    pageReportFilters1.idsToDownload = [];
    return { ...state, ...pageReportFilters1 };
  }

  return state;
};

export const filtersStore = combineReducers<FiltersState>({
  reportFilter,
  favoriteFilter
});
