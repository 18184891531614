import i18n from '../i18n';
import IReportStatus from '../interfaces/IReportStatus';
import { IReportStatusContent, IReportStatusTable } from '../pages/reportStatus/reportStatusInfo/table/iReportStatusTable';

export enum Status {
  PASS = 'PASS',
  NO_PASS = 'NO PASS',
  PENDING = 'PENDING',
  NOT_IN_SELECTION = 'NOT IN SELECTION',
  CANCELLED = 'CANCELLED',
  PPP = 'PPP'
}

function createGeneralStatus(results: IReportStatusContent[]) {
  const result: any[] = [];

  results.forEach((reportResult) => {
    result.push(reportResult.tableContent[0].value);
  });

  if (result.some((state) => state === Status.CANCELLED)) {
    return Status.CANCELLED;
  }

  if (result.some((state) => state === Status.NO_PASS || state === Status.PPP)) {
    return Status.NO_PASS;
  }

  if (result.some((state) => state === Status.PENDING)) {
    return Status.PENDING;
  }

  return Status.PASS;
}

export const createReportStatusInfo = (props: IReportStatus): IReportStatusTable => {
  const {
    vendorCode,
    style,
    season: { code },
    resultReportCI,
    resultReportFMZM,
    versionReportCI,
    versionReportFMZM,
    numberReportCI,
    numberReportFMZM,
    numberReportSSP,
    versionReportSSP,
    resultReportSSP } = props;

  const reportStatusInfo = {
    generalInfo: {
      title: i18n.t('reportStatus.resultsTitle'),
      generalStatus: '',
      tableContent: [
        { key: i18n.t('reportStatus.SupplierCode'), value: vendorCode },
        { key: i18n.t('reportStatus.Model'), value: style },
        { key: i18n.t('reportStatus.Season'), value: code }
      ]
    },
    results: [
      {
        title: i18n.t('reportStatus.ChemicalTitle') + '     ' + (numberReportCI !== undefined && numberReportCI != null ? (numberReportCI + '/' + versionReportCI) : i18n.t('reportStatus.pending')),
        tableContent: [
          { key: i18n.t('reportStatus.State'), value: resultReportCI }
        ]
      }, {
        title: i18n.t('reportStatus.PhyMechTitle') + '     ' + (numberReportFMZM !== undefined && numberReportFMZM != null ? (numberReportFMZM + '/' + versionReportFMZM) : i18n.t('reportStatus.pending')),
        tableContent: [
          { key: i18n.t('reportStatus.State'), value: resultReportFMZM }
        ]
      }, {
        title: i18n.t('reportStatus.SspMechTitle') + '     ' + (numberReportSSP !== undefined && numberReportSSP != null ? (numberReportSSP + '/' + versionReportSSP) : i18n.t('reportStatus.pending')),
        tableContent: [
          { key: i18n.t('reportStatus.State'), value: resultReportSSP }
        ]
      }
    ]
  };

  reportStatusInfo.generalInfo.generalStatus = createGeneralStatus(reportStatusInfo.results);
  return reportStatusInfo;
};
