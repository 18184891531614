import moment from 'moment';

import { getCurrentLanguage } from '../i18n';

export function toDate(date: Date | moment.Moment, format: string): string {
  if (!date) {
    return '';
  }

  if ((date instanceof moment)) {
    const momentDate = date as moment.Moment;
    return momentDate.locale(getCurrentLanguage()).format(format);
  }

  return moment(date).locale(getCurrentLanguage()).format(format);
}

export function toDateWithoutZones(date: Date | moment.Moment, format: string): string {
  if (!date) {
    return '';
  }

  if ((date instanceof moment)) {
    const momentDate = date as moment.Moment;
    return momentDate.locale(getCurrentLanguage()).utc().local().format(format);
  }

  return moment(date).locale(getCurrentLanguage()).utc().local().format(format);
}

export function toShortDate(date: Date | moment.Moment, withoutZones = false): string {
  if (withoutZones) {
    return toDateWithoutZones(date, 'L');  // 06/09/2014
  }

  return toDate(date, 'L');  // 06/09/2014
}

export function toShortDateWithHour(date: Date | moment.Moment, withoutZones = false): string {
  if (withoutZones) {
   return toDateWithoutZones(date, 'L') + ' ' + toDateWithoutZones(date, 'LT');  // 06/09/2014 12:19
  }

  return toDate(date, 'L') + ' ' + toDate(date, 'LT');  // 06/09/2014 12:19
}

export function toLongDate(date: Date | moment.Moment, withoutZones = false): string {
  if (withoutZones) {
    return toDateWithoutZones(date, 'LL'); // June 9 2014
  }

  return toDate(date, 'LL'); // June 9 2014
}

export function toLongLocalDateWithHour(date: Date | moment.Moment, withoutZones = false): string {
  if (withoutZones) {
    //return moment(date).utc().local().format('lll');
    return toDateWithoutZones(date, 'lll');
  }

  //return moment(date).format('lll');
  return toDate(date, 'lll');
}

function today(): Date {
  return new Date();
}

function weekStart(): Date {
  const current = today();
  const first = current.getDate() - current.getDay() + 1;
  return new Date(current.setDate(first));
}

function weekEnd(): Date {
  const current = today();
  return new Date(current.setDate(current.getDate() + 6));
}

function monthStart(): Date {
  return new Date(new Date().setDate(1));
}

function monthEnd(): Date {
  return new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
}

function lastMonthStart(): Date {
  const previousMonth = new Date().setMonth(new Date().getMonth() - 1);
  return new Date(new Date(previousMonth).setDate(1));
}

function lastMonthEnd(): Date {
  const previousMonth = new Date().getMonth() - 1;
  const previousMonthDay = new Date().setMonth(previousMonth);
  const yearOfPreviousMonth = new Date(previousMonthDay).getFullYear();
  return new Date(yearOfPreviousMonth, previousMonth + 1, 0);
}

function lastYearStart(): Date {
  const previousYear = new Date().getFullYear() - 1;
  return new Date(previousYear, 0, 1);
}

function lastYearEnd(): Date {
  const previousYear = lastYearStart().getFullYear();
  const lastMonth = 11;
  return new Date(previousYear, lastMonth + 1, 0);
}

function next7days(): Date {
  const current = new Date(today());
  current.setDate(current.getDate() + 7);
  return current;
}

function next30days(): Date {
  const current = new Date(today());
  current.setDate(current.getDate() + 30);
  return current;
}

function next60days(): Date {
  const current = new Date(today());
  current.setDate(current.getDate() + 60);
  return current;
}

function last7days(): Date {
  const current = new Date(today());
  current.setDate(current.getDate() - 7);
  return current;
}

function last30days(): Date {
  const current = new Date(today());
  current.setDate(current.getDate() - 30);
  return current;
}

function last60days(): Date {
  const current = new Date(today());
  current.setDate(current.getDate() - 60);
  return current;
}

export function cloneDate(date: Date): Date {
  if (!date) {
    return;
  }

  const newDate = new Date(date);

  return new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate());
}

export function cloneDateTime(date: Date): Date {
  if (!date) {
    return;
  }

  const newDate = new Date(date);

  return new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), newDate.getHours(), newDate.getMinutes(), newDate.getSeconds(), newDate.getMilliseconds());
}

export function getLongDateFormat(format: moment.LongDateFormatKey): string {
  if (!format) {
    return;
  }

  return moment.localeData(getCurrentLanguage()).longDateFormat(format);
}

export function getDifferenceInMilliseconds(maxDate: Date, minDate: Date): number {
  return moment(maxDate).diff(moment(minDate), 'milliseconds');
}

export function getDifferenceInSeconds(maxDate: Date, minDate: Date): number {
  return moment(maxDate).diff(moment(minDate), 'seconds');
}

export function getDifferenceInMinutes(maxDate: Date, minDate: Date): number {
  return moment(maxDate).diff(moment(minDate), 'minutes');
}

export function getDifferenceInHours(maxDate: Date, minDate: Date): number {
  return moment(maxDate).diff(moment(minDate), 'hours');
}

export function getDifferenceInDays(maxDate: Date, minDate: Date): number {
  return moment(maxDate).diff(moment(minDate), 'days');
}

export const getDate = {
  today,
  weekStart,
  weekEnd,
  monthStart,
  monthEnd,
  lastMonthStart,
  lastMonthEnd,
  lastYearStart,
  lastYearEnd,
  next7days,
  next30days,
  next60days,
  last7days,
  last30days,
  last60days
};
