import { URL } from '../common/constants';
import { IEmailNotReceived } from '../common/model/emailNotReceived.model';
import * as services from '.';

export interface IEmailNotReceivedService {
  create(uneco: IEmailNotReceived): Promise<IEmailNotReceived>;
  update(uneco: IEmailNotReceived): Promise<IEmailNotReceived>;
}

class EmailsNotReceivedService implements IEmailNotReceivedService {
  public constructor(private httpClient: services.IHttpClient) {

  }

  public async create(emailNotReceived: IEmailNotReceived): Promise<IEmailNotReceived> {
    return await this.httpClient.postCustom(`${URL.WEB.BASE}${URL.WEB.EMAILS_NOT_RECEIVED}`, emailNotReceived);
  }

  public async update(emailNotReceived: IEmailNotReceived): Promise<IEmailNotReceived> {
    return await this.httpClient.putCustom(`${URL.WEB.BASE}${URL.WEB.EMAILS_NOT_RECEIVED}/${emailNotReceived.id}`, emailNotReceived);
  }
}

export const emailsNotReceivedService = new EmailsNotReceivedService(services.httpClient);
