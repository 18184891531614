import { URL } from '../common/constants';
import { ISupplierProduct } from '../common/model/supplierProduct.model';
import * as services from '.';

export interface ISupplierProductsService {
  create(supplierProduct: ISupplierProduct): Promise<ISupplierProduct>;
  update(supplierProduct: ISupplierProduct): Promise<ISupplierProduct>;
}

class SupplierProductsService implements ISupplierProductsService {
  public constructor(private httpClient: services.IHttpClient) {

  }

  public create(supplierProduct: ISupplierProduct): Promise<ISupplierProduct> {
    return this.httpClient.postCustom(`${URL.WEB.BASE}${URL.WEB.SUPPLIER_PRODUCTS}`, supplierProduct);
  }

  public update(supplierProduct: ISupplierProduct): Promise<ISupplierProduct> {
    return this.httpClient.putCustom(`${URL.WEB.BASE}${URL.WEB.SUPPLIER_PRODUCTS}/${supplierProduct.id}`, supplierProduct);
  }
}

export const supplierProductsService = new SupplierProductsService(services.httpClient);
