import React, { Component, ComponentType } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { compose } from 'redux';
import { DataManager, Query } from '@syncfusion/ej2-data';
import { SortDirection } from '@syncfusion/ej2-grids';
import { Tabs, Tab } from 'react-bootstrap';
import queryString from 'query-string';
import { dialogsService } from '@aitex/tsx-extranet-dialogs';

import { RootState } from '../../store';
import { ICertificatesListContainerStateProps, ICertificatesListContainerDispatchProps } from '../../interfaces/props/ICertificatesListProps';
import * as certificatesListStore from '../../store/modules/certificatesList.store';
import * as listGridStore from '../../store/modules/listGrid.store';
import * as dialogsStore from '../../store/modules/dialogs.store';
import { URL } from '../../common/constants';
import { DATE_MOMENT_FORMAT } from '../../common/constants/advancedFilter.constants';
import { IMyRule } from '../../common/model/myRule.model';
import { IErrors } from '../../common/model/errors.model';
import { CertificateStatus } from '../../common/model/enumerations/certificateStatus.model';
import { CertificateRenewalState } from '../../common/model/enumerations/certificateRenewalState.model';
import { RuleCondition } from '../../common/model/enumerations/ruleCondition.model';
import { RuleOperator } from '../../common/model/enumerations/ruleOperator.model';
import { FilterMode } from '../../common/model/enumerations/filterMode.model';
import { UserType } from '../../common/model/enumerations/userType.model';
import { DialogId } from '../../common/model/enumerations/dialogId.model';
import { ICertificate } from '../../common/model/certificate.model';
import { oDataService, dataManagerService, queryBuilderService, certificatesService } from '../../services';
import { notifySuccess, notifyError } from '../../utils/toast.utils';
import * as dateHelpers from '../../helpers/date.helpers';
import CertificatesListFilter from './certificatesListFilter/certificatesListFilter';
import CertificatesGrid from './certificatesGrid/certificatesGrid';

type CertificatesListContainerPropsType = PropsFromRedux & RouteComponentProps & WithTranslation;

class CertificatesListContainer extends Component<CertificatesListContainerPropsType> {
  public certificatesDataManager: DataManager = null;
  public certificatesQuery: Query = new Query().select(['id', 'identificationCode', 'certificateTypeValue', 'expirationDate', 'issueDate', 'renewalState', 'reportId', 'reportNumber', 'referenceNames', 'offerRequerimentStandard', 'companyName', 'reportResultIsVisible', 'reportPublished']);

  public constructor(props: CertificatesListContainerPropsType) {
    super(props);

    this.certificatesDataManager = dataManagerService.buildDataManager(URL.ODATA.CERTIFICATE_FILTERS);

    this.props.reset();
  }

  public componentDidMount() {
    const { setFilterExpirationDate, location } = this.props;

    const parsedUrl = queryString.parse(location.search);
    if (parsedUrl.expirationDateStart && parsedUrl.expirationDateEnd) {
      setFilterExpirationDate([new Date(parsedUrl.expirationDateStart as string), new Date(parsedUrl.expirationDateEnd as string)]);
    }

    this.manageCertificatesListMode();
  }

  public componentDidUpdate() {
    this.manageCertificatesListMode();
  }

  public componentWillUnmount() {
    this.props.reset();
  }

  public onFilterButtonClick() {
    this.props.setCurrentPage(certificatesListStore.Type.SET_CURRENT_PAGE, 1);
    this.fetchCertificates();
  }

  public onCurrentPageChange(currentPage: number) {
    this.props.setCurrentPage(certificatesListStore.Type.SET_CURRENT_PAGE, currentPage);
    this.fetchCertificates();
  }

  public onPageSizeChange(pageSize: number) {
    this.props.setPageSize(certificatesListStore.Type.SET_PAGE_SIZE, pageSize);
    this.fetchCertificates();
  }

  public onSortingChange(field: string, direction: SortDirection) {
    this.props.setSorting(certificatesListStore.Type.SET_SORTING, field, direction);
    this.fetchCertificates();
  }

  public async onCheckRenewalStatusButtonClick() {
    try {
      await certificatesService.checkExpiration();
      notifySuccess(this.props.i18n.t('certificatesList.messages.checkRenewalStatusSuccess'));
    } catch (error) {
      notifyError(this.props.i18n.t('certificatesList.errors.cannotCheckRenewalStatus'));
    }
  }

  public onRequestRenewalButtonClick(id: string) {
    this.requestRenewal(false, [id]);
  }

  public onRequestRenewalCancellationButtonClick(id: string) {
    this.requestRenewal(true, [id]);
  }

  public async onRequestRenewalMultipleButtonClick() {
    this.requestRenewal(false, this.props.selectedCertificatesIds);
  }

  public async onRequestRenewalCancellationMultipleButtonClick() {
    this.requestRenewal(true, this.props.selectedCertificatesIds);
  }

  public render() {
    const {
      certificates,
      mode,
      isChangingMode,
      filter,
      isFetching,
      searchDate,
      currentPage,
      pageSize,
      totalCount,
      sortField,
      sortDirection,
      selectedCertificatesIds,
      isCurrentUserInternal,
      setFilterIdentificationCode,
      setFilterOfferRequerimentStandard,
      setFilterReportNumber,
      setFilterStatus,
      setFilterReferenceNames,
      setFilterRenewalState,
      setFilterCertificateTypeValues,
      setFilterExpirationDate,
      setFilterCompanyName,
      setSelectedCertificatesIds,
      i18n
    } = this.props;

    if (mode !== CertificateStatus.ALL && mode !== CertificateStatus.VALID && mode !== CertificateStatus.EXPIRED || isChangingMode) {
      return null;
    }

    return (
      <div>
        <div className='row'>
          <div className='col'>
            <h2>{i18n.t('certificatesList.title')}</h2>
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <div className='mb-3'>
              <Tabs id='certificatesListTabs' className='mb-3'>
                <Tab eventKey={FilterMode.SIMPLE} title={i18n.t('enums.filterMode.SIMPLE')}>
                  <CertificatesListFilter
                    filter={filter}
                    listMode={mode}
                    isFetching={isFetching}
                    isCurrentUserInternal={isCurrentUserInternal}
                    onIdentificationCodeChange={setFilterIdentificationCode}
                    onOfferRequerimentStandardChange={setFilterOfferRequerimentStandard}
                    onReportNumberChange={setFilterReportNumber}
                    onStatusChange={setFilterStatus}
                    onReferenceNamesChange={setFilterReferenceNames}
                    onRenewalStateChange={setFilterRenewalState}
                    onCertificateTypeValuesChange={setFilterCertificateTypeValues}
                    onExpirationDateChange={setFilterExpirationDate}
                    onCompanyNameChange={setFilterCompanyName}
                    onFilterButtonClick={() => this.onFilterButtonClick()}
                  />
                </Tab>
              </Tabs>
            </div>
            {!isChangingMode && <CertificatesGrid
              certificates={certificates}
              isFetching={isFetching}
              searchDate={searchDate}
              currentPage={currentPage}
              pageSize={pageSize}
              totalCount={totalCount}
              sortField={sortField}
              sortDirection={sortDirection}
              selectedCertificatesIds={selectedCertificatesIds}
              isCurrentUserInternal={isCurrentUserInternal}
              onCurrentPageChange={(cp) => this.onCurrentPageChange(cp)}
              onPageSizeChange={(ps) => this.onPageSizeChange(ps)}
              onSortingChange={(field, direction) => this.onSortingChange(field, direction)}
              onCheckRenewalStatusButtonClick={() => this.onCheckRenewalStatusButtonClick()}
              onRequestRenewalButtonClick={(id) => this.onRequestRenewalButtonClick(id)}
              onRequestRenewalCancellationButtonClick={(id) => this.onRequestRenewalCancellationButtonClick(id)}
              onRequestRenewalMultipleButtonClick={() => this.onRequestRenewalMultipleButtonClick()}
              onRequestRenewalCancellationMultipleButtonClick={() => this.onRequestRenewalCancellationMultipleButtonClick()}
              onSelectedCertificatesIdsChange={(newSelectedCertificatesIds) => setSelectedCertificatesIds(newSelectedCertificatesIds)}
              onShareCertificateButtonClick={(cert: ICertificate) => this.shareCertificate(cert)}
            />}
          </div>
        </div>
      </div>
    );
  }

  private manageCertificatesListMode() {
    const { mode, isChangingMode, setMode, setIsChangingMode, history } = this.props;

    const currentCertificatesListMode = this.getCertificatesListMode();
    if (currentCertificatesListMode !== mode) {
      if (currentCertificatesListMode !== CertificateStatus.ALL && currentCertificatesListMode !== CertificateStatus.VALID && currentCertificatesListMode !== CertificateStatus.EXPIRED) {
        history.push(`/certificates-list/${CertificateStatus.ALL}`);
      }

      if (!isChangingMode) {
        setIsChangingMode(true);
      }

      setTimeout(() => {
        setMode(currentCertificatesListMode);
        if (!this.props.isCurrentUserInternal) {
          setTimeout(() => this.onFilterButtonClick(), 250);
        }
      }, 100);
    } else {
      setIsChangingMode(false);
    }
  }

  private getCertificatesListMode(): string {
    return this.props.location.pathname.substring(this.props.location.pathname.lastIndexOf('/') + 1);
  }

  private fetchCertificates() {
    const { isFetching, fetchCertificates } = this.props;

    if (!isFetching) {
      fetchCertificates(oDataService, this.certificatesDataManager, this.buildQuery());
    }
  }

  private buildQuery(): Query {
    const { filter } = this.props;

    const myRule: IMyRule = {
      condition: RuleCondition.AND,
      myRules: []
    };
    if (filter.identificationCode) {
      myRule.myRules.push({
        field: 'identificationCode',
        operator: RuleOperator.CONTAINS,
        value: filter.identificationCode,
        ignoreCase: true
      });
    }
    if (filter.offerRequerimentStandard) {
      myRule.myRules.push({
        field: 'offerRequerimentStandard',
        operator: RuleOperator.CONTAINS,
        value: filter.offerRequerimentStandard,
        ignoreCase: true
      });
    }
    if (filter.reportNumber) {
      myRule.myRules.push({
        field: 'reportNumber',
        operator: RuleOperator.CONTAINS,
        value: filter.reportNumber,
        ignoreCase: true
      });
    }
    if (filter.status !== CertificateStatus.ALL) {
      if (filter.status === CertificateStatus.VALID) {
        myRule.myRules.push({
          condition: RuleCondition.OR,
          myRules: [{
            field: 'expirationDate',
            operator: RuleOperator.EQUAL,
            value: null
          }, {
            field: 'expirationDate',
            operator: RuleOperator.GREATER_THAN_OR_EQUAL_TODAY,
            isDate: true
          }]
        });
      } else {
        myRule.myRules.push({
          field: 'expirationDate',
          operator: RuleOperator.LESS_THAN_TODAY,
          isDate: true
        });
      }
    }
    if (filter.referenceNames) {
      myRule.myRules.push({
        field: 'referenceNames',
        operator: RuleOperator.CONTAINS,
        value: filter.referenceNames,
        ignoreCase: true
      });
    }
    if (filter.renewalState !== CertificateRenewalState.ALL) {
      myRule.myRules.push({
        field: 'renewalState',
        operator: RuleOperator.EQUAL,
        value: filter.renewalState
      });
    }
    if (filter.certificateTypeValues.length) {
      myRule.myRules.push({
        field: 'certificateTypeValue',
        operator: RuleOperator.IN,
        value: filter.certificateTypeValues,
        ignoreCase: true
      });
    }
    if (filter.expirationDate.length) {
      myRule.myRules.push({
        field: 'expirationDate',
        operator: RuleOperator.GREATER_THAN_OR_EQUAL,
        value: dateHelpers.toDateWithoutZones(filter.expirationDate[0], DATE_MOMENT_FORMAT),
        isDate: true
      });
      myRule.myRules.push({
        field: 'expirationDate',
        operator: RuleOperator.LESS_THAN_OR_EQUAL,
        value: dateHelpers.toDateWithoutZones(filter.expirationDate[1], DATE_MOMENT_FORMAT),
        isDate: true
      });
    }
    if (filter.companyName) {
      myRule.myRules.push({
        field: 'companyName',
        operator: RuleOperator.CONTAINS,
        value: filter.companyName,
        ignoreCase: true
      });
    }

    return queryBuilderService.buildQuery(this.certificatesDataManager, myRule, this.certificatesQuery.clone());
  }

  private requestRenewal(cancellationMode: boolean, ids: string[]) {
    this.props.prepareRequestCertificateRenewalDialog(cancellationMode, ids);
    dialogsService.openDialog(DialogId.REQUEST_CERTIFICATE_RENEWAL)
      .then(() => this.fetchCertificates())
      .catch((reason?: IErrors) => {
        this.manageDialogDismissed(reason);
      });
  }

  private manageDialogDismissed(reason?: IErrors) {
    if (reason) {
      if (reason.ControlledError) {
        notifyError(reason.ControlledError[0]);
      }
      if (reason.UnknownError) {
        notifyError(this.props.i18n.t(reason.UnknownError[0]));
      }
    }
  }

  public shareCertificate(certificate: ICertificate) {
    const { prepareShareCertificateEmailDialog } = this.props;
    const manageDialogDismissed = (reason: IErrors) => this.manageDialogDismissed(reason);

    prepareShareCertificateEmailDialog(certificate);
    dialogsService.openDialog(DialogId.SHARE_CERTIFICATE_EMAIL)
      //.then(() => )
      .catch((reason?: IErrors) => {
        manageDialogDismissed(reason);
      });
  }
}

const mapStateToProps = (state: RootState): ICertificatesListContainerStateProps => ({
  certificates: state.certificatesListStore.certificates,
  mode: state.certificatesListStore.mode,
  isChangingMode: state.certificatesListStore.isChangingMode,
  filter: state.certificatesListStore.filter,
  isFetching: state.certificatesListStore.grid.isFetching,
  searchDate: state.certificatesListStore.grid.searchDate,
  currentPage: state.certificatesListStore.grid.currentPage,
  pageSize: state.certificatesListStore.grid.pageSize,
  totalCount: state.certificatesListStore.grid.totalCount,
  sortField: state.certificatesListStore.grid.sortField,
  sortDirection: state.certificatesListStore.grid.sortDirection,
  selectedCertificatesIds: state.certificatesListStore.selectedCertificatesIds,
  isCurrentUserInternal: state.currentUserStore.user.type === UserType.INTERNAL,
  currentUserHasCertificates: state.currentUserStore.hasCertificates,
  currentUserCompany: state.currentUserStore.company
});

const mapDispatchToProps: ICertificatesListContainerDispatchProps = {
  reset: certificatesListStore.reset,
  setMode: certificatesListStore.setMode,
  setIsChangingMode: certificatesListStore.setIsChangingMode,
  setFilterIdentificationCode: certificatesListStore.setFilterIdentificationCode,
  setFilterOfferRequerimentStandard: certificatesListStore.setFilterOfferRequerimentStandard,
  setFilterReportNumber: certificatesListStore.setFilterReportNumber,
  setFilterStatus: certificatesListStore.setFilterStatus,
  setFilterReferenceNames: certificatesListStore.setFilterReferenceNames,
  setFilterRenewalState: certificatesListStore.setFilterRenewalState,
  setFilterCertificateTypeValues: certificatesListStore.setFilterCertificateTypeValues,
  setFilterExpirationDate: certificatesListStore.setFilterExpirationDate,
  setFilterCompanyName: certificatesListStore.setFilterCompanyName,
  setCurrentPage: listGridStore.setCurrentPage,
  setPageSize: listGridStore.setPageSize,
  setSorting: listGridStore.setSorting,
  setSelectedCertificatesIds: certificatesListStore.setSelectedCertificatesIds,
  fetchCertificates: certificatesListStore.fetchCertificates,
  prepareRequestCertificateRenewalDialog: dialogsStore.prepareRequestCertificateRenewalDialog,
  prepareShareCertificateEmailDialog: dialogsStore.prepareShareCertificateEmailDialog
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default compose(
  withRouter,
  withTranslation(),
  connector
)(CertificatesListContainer) as ComponentType;
