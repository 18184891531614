import React, { Component, ComponentType } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { compose } from 'redux';
import { DataManager, Query } from '@syncfusion/ej2-data';
import { SortDirection } from '@syncfusion/ej2-grids';
import { Tabs, Tab } from 'react-bootstrap';

import { RootState } from '../../store';
import { IRequestsListContainerStateProps, IRequestsListContainerDispatchProps } from '../../interfaces/props/IRequestsListProps';
import * as requestsListStore from '../../store/modules/requestsList.store';
import * as listGridStore from '../../store/modules/listGrid.store';
import { URL } from '../../common/constants';
import { DATE_MOMENT_FORMAT } from '../../common/constants/advancedFilter.constants';
import { IMyRule } from '../../common/model/myRule.model';
import { RequestsListMode } from '../../common/model/enumerations/requestsListMode.model';
import { RequestStatus } from '../../common/model/enumerations/requestStatus.model';
import { FilterMode } from '../../common/model/enumerations/filterMode.model';
import { RuleCondition } from '../../common/model/enumerations/ruleCondition.model';
import { RuleOperator } from '../../common/model/enumerations/ruleOperator.model';
import { UserType } from '../../common/model/enumerations/userType.model';
import * as dateHelpers from '../../helpers/date.helpers';
import { dataManagerService, oDataService, queryBuilderService, companiesService, requestsService } from '../../services';
import RequestsListFilter from './requestsListFilter/requestsListFilter';
import RequestsGrid from './requestsGrid/requestsGrid';

type RequestsListContainerPropsType = PropsFromRedux & RouteComponentProps & WithTranslation;

class RequestsListContainer extends Component<RequestsListContainerPropsType> {
  public requestsDataManager: DataManager = null;
  public requestsQuery: Query = new Query()
    .select([
      'id', 'code', 'subject', 'createdDate', 'sampleDate', 'status', 'acceptanceStatus', 'analysisToPerform', 'order', 'sendDate', 'offerNumber', 'initialAmount', 'amount', 'companyId', 'companyName', 'applicantId', 'applicantEmail', 'referenceNames',
      'contest', 'eciRequest', 'supplier', 'quantity', 'sampleSealed', 'returnSurplusSample', 'requiredDate', 'bidderId', 'bidderName', 'centerId', 'centerName', 'unecoId', 'unecoName', 'delegationId', 'delegationName'
    ]);

  public companiesDataManager: DataManager = null;
  public usersDataManager: DataManager = null;
  public managerGroupsDataManager: DataManager = null;
  public managerEmailUsersDataManager: DataManager = null;
  public delegateManagerUsersDataManager: DataManager = null;

  public constructor(props: RequestsListContainerPropsType) {
    super(props);

    this.requestsDataManager = dataManagerService.buildDataManager(URL.ODATA.REQUEST_WITH_CUSTOM_FIELDS_FILTERS);

    this.companiesDataManager = dataManagerService.buildDataManager(URL.ODATA.COMPANY_FILTERS);
    this.usersDataManager = dataManagerService.buildDataManager(URL.ODATA.USER_FILTERS);

    this.managerGroupsDataManager = dataManagerService.buildDataManager(URL.ODATA.MANAGER_GROUPS_FILTERS);
    this.managerEmailUsersDataManager = dataManagerService.buildDataManager(URL.ODATA.USER_FILTERS);
    this.delegateManagerUsersDataManager = dataManagerService.buildDataManager(URL.ODATA.DELEGATES_MANAGER_GROUPS_FILTERS);

    this.props.reset();
  }

  public componentDidMount() {
    this.init();
  }

  public componentDidUpdate(prevProps: RequestsListContainerPropsType) {
    if (
      prevProps.isCurrentUserInternal !== this.props.isCurrentUserInternal
      || !this.props.isCurrentUserInternal && (prevProps.currentUserCompany !== this.props.currentUserCompany)
    ) {
      setTimeout(() => this.props.resetHard());
    }

    this.init();
  }

  public componentWillUnmount() {
    this.props.reset();
  }

  public onFilterButtonClick() {
    this.props.setCurrentPage(requestsListStore.Type.SET_CURRENT_PAGE, 1);
    this.fetchRequests();
  }

  public onCurrentPageChange(currentPage: number) {
    this.props.setCurrentPage(requestsListStore.Type.SET_CURRENT_PAGE, currentPage);
    this.fetchRequests();
  }

  public onPageSizeChange(pageSize: number) {
    this.props.setPageSize(requestsListStore.Type.SET_PAGE_SIZE, pageSize);
    this.fetchRequests();
  }

  public onSortingChange(field: string, direction: SortDirection) {
    this.props.setSorting(requestsListStore.Type.SET_SORTING, field, direction);
    this.fetchRequests();
  }

  public render() {
    const {
      requests,
      mode,
      isChangingMode,
      filter,
      requestCustomFieldSettings,
      bidderOptions,
      centerOptions,
      unecoOptions,
      isFetching,
      searchDate,
      currentPage,
      pageSize,
      totalCount,
      sortField,
      sortDirection,
      isCurrentUserInternal,
      currentUserCompany,
      setFilterSubject,
      setFilterCode,
      setFilterStatus,
      setFilterCompaniesIds,
      setFilterApplicantsIds,
      setFilterCreatedDate,
      setFilterReferenceNames,
      setFilterEciRequest,
      setFilterBidderId,
      setFilterBidderName,
      setFilterCenterId,
      setFilterCenterName,
      setFilterUnecoId,
      setFilterUnecoName,
      setFilterOrder,
      i18n,
      setFilterManagerGroupsIds,
      setFilterManagerEmailUsersIds,
      setFilterDelegateManagerUsersIds
    } = this.props;

    if (mode !== RequestsListMode.ALL && mode !== RequestsListMode.OPEN && mode !== RequestsListMode.IN_PROGRESS || isChangingMode) {
      return null;
    }

    if (!this.areRequestCustomFieldSettingsLoaded()) {
      return null; // TODO: loading
    }

    return (
      <div>
        <div className='row'>
          <div className='col'>
            <h2>{i18n.t('requestsList.title')}</h2>
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <div className='mb-3'>
              <Tabs id='requestsListTabs' className='mb-3'>
                <Tab eventKey={FilterMode.SIMPLE} title={i18n.t('enums.filterMode.SIMPLE')}>
                  <RequestsListFilter
                    filter={filter}
                    listMode={mode}
                    requestCustomFieldSettings={requestCustomFieldSettings}
                    bidderOptions={bidderOptions}
                    centerOptions={centerOptions}
                    unecoOptions={unecoOptions}
                    isFetching={isFetching}
                    isCurrentUserInternal={isCurrentUserInternal}
                    currentUserCompany={currentUserCompany}
                    companiesDataManager={this.companiesDataManager}
                    usersDataManager={this.usersDataManager}
                    onSubjectChange={setFilterSubject}
                    onCodeChange={setFilterCode}
                    onStatusChange={setFilterStatus}
                    onCompaniesIdsChange={setFilterCompaniesIds}
                    onApplicantsIdsChange={setFilterApplicantsIds}
                    onCreatedDateChange={setFilterCreatedDate}
                    onReferenceNamesChange={setFilterReferenceNames}
                    onEciRequestChange={setFilterEciRequest}
                    onBidderIdChange={setFilterBidderId}
                    onBidderNameChange={setFilterBidderName}
                    onCenterIdChange={setFilterCenterId}
                    onCenterNameChange={setFilterCenterName}
                    onUnecoIdChange={setFilterUnecoId}
                    onUnecoNameChange={setFilterUnecoName}
                    managerGroupsDataManager={this.managerGroupsDataManager}
                    onManagerGroupsIdsChange={setFilterManagerGroupsIds}
                    onManagerEmailUsersIdsChange={setFilterManagerEmailUsersIds}
                    managerEmailUsersDataManager={this.managerEmailUsersDataManager}
                    onDelegateManagerUsersIdsChange={setFilterDelegateManagerUsersIds}
                    delegateManagerUsersDataManager={this.delegateManagerUsersDataManager}
                    onOrderChange={setFilterOrder}
                    onFilterButtonClick={() => this.onFilterButtonClick()}
                  />
                </Tab>
              </Tabs>
            </div>
            <RequestsGrid
              requests={requests}
              listMode={mode}
              requestCustomFieldSettings={requestCustomFieldSettings}
              isFetching={isFetching}
              searchDate={searchDate}
              currentPage={currentPage}
              pageSize={pageSize}
              totalCount={totalCount}
              sortField={sortField}
              sortDirection={sortDirection}
              isCurrentUserInternal={isCurrentUserInternal}
              onCurrentPageChange={(cp: number) => this.onCurrentPageChange(cp)}
              onPageSizeChange={(ps: number) => this.onPageSizeChange(ps)}
              onSortingChange={(field: string, direction: SortDirection) => this.onSortingChange(field, direction)}
            />
          </div>
        </div>
      </div>
    );
  }

  private init() {
    const { isFetching, fetchRequestCustomFieldSettings } = this.props;

    if (!this.areRequestCustomFieldSettingsLoaded()) {
      if (!isFetching) {
        fetchRequestCustomFieldSettings(companiesService, requestsService);
      }
      return;
    }

    this.manageRequestsListMode();
  }

  private manageRequestsListMode() {
    const { mode, isChangingMode, setMode, setIsChangingMode, history } = this.props;

    const currentCertfificatesListMode = this.getRequestsListMode();
    if (currentCertfificatesListMode !== mode) {
      if (currentCertfificatesListMode !== RequestsListMode.ALL && currentCertfificatesListMode !== RequestsListMode.OPEN && currentCertfificatesListMode !== RequestsListMode.IN_PROGRESS) {
        history.push(`/requests-list/${RequestsListMode.ALL}`);
      }

      if (!isChangingMode) {
        setIsChangingMode(true);
      }

      setTimeout(() => {
        setMode(currentCertfificatesListMode);
        if (!this.props.isCurrentUserInternal) {
          setTimeout(() => this.onFilterButtonClick(), 250);
        }
      }, 100);
    } else {
      setIsChangingMode(false);
    }
  }

  private getRequestsListMode(): string {
    return this.props.location.pathname.substring(this.props.location.pathname.lastIndexOf('/') + 1);
  }

  private fetchRequests() {
    const { isFetching, fetchRequests } = this.props;

    if (!isFetching) {
      fetchRequests(oDataService, this.requestsDataManager, this.buildQuery());
    }
  }

  private buildQuery(): Query {
    const { filter } = this.props;

    const myRule: IMyRule = {
      condition: RuleCondition.AND,
      myRules: []
    };
    if (filter.subject) {
      myRule.myRules.push({
        field: 'subject',
        operator: RuleOperator.CONTAINS,
        value: filter.subject,
        ignoreCase: true
      });
    }
    if (filter.code) {
      myRule.myRules.push({
        field: 'code',
        operator: RuleOperator.CONTAINS,
        value: filter.code,
        ignoreCase: true
      });
    }
    if (filter.status !== RequestStatus.ALL) {
      myRule.myRules.push({
        field: 'status',
        operator: RuleOperator.EQUAL,
        value: filter.status,
        ignoreCase: true
      });
    }
    if (filter.companiesIds.length) {
      myRule.myRules.push({
        field: 'companyId',
        operator: RuleOperator.IN,
        value: filter.companiesIds
      });
    }
    if (filter.applicantsIds.length) {
      myRule.myRules.push({
        field: 'applicantId',
        operator: RuleOperator.IN,
        value: filter.applicantsIds
      });
    }
    if (filter.createdDate.length) {
      myRule.myRules.push({
        field: 'createdDate',
        operator: RuleOperator.GREATER_THAN_OR_EQUAL,
        value: dateHelpers.toDateWithoutZones(filter.createdDate[0], DATE_MOMENT_FORMAT),
        isDate: true
      });
      myRule.myRules.push({
        field: 'createdDate',
        operator: RuleOperator.LESS_THAN_OR_EQUAL,
        value: dateHelpers.toDateWithoutZones(filter.createdDate[1], DATE_MOMENT_FORMAT),
        isDate: true
      });
    }
    if (filter.referenceNames) {
      myRule.myRules.push({
        field: 'referenceNames',
        operator: RuleOperator.CONTAINS,
        value: filter.referenceNames,
        ignoreCase: true
      });
    }
    if (filter.eciRequest) {
      myRule.myRules.push({
        field: 'eciRequest',
        operator: RuleOperator.CONTAINS,
        value: filter.eciRequest,
        ignoreCase: true
      });
    }
    if (filter.bidderId) {
      myRule.myRules.push({
        field: 'bidderId',
        operator: RuleOperator.EQUAL,
        value: filter.bidderId
      });
    }
    if (filter.bidderName) {
      myRule.myRules.push({
        field: 'bidderName',
        operator: RuleOperator.CONTAINS,
        value: filter.bidderName
      });
    }
    if (filter.centerId) {
      myRule.myRules.push({
        field: 'centerId',
        operator: RuleOperator.EQUAL,
        value: filter.centerId
      });
    }
    if (filter.centerName) {
      myRule.myRules.push({
        field: 'centerName',
        operator: RuleOperator.CONTAINS,
        value: filter.centerName
      });
    }
    if (filter.unecoId) {
      myRule.myRules.push({
        field: 'unecoId',
        operator: RuleOperator.EQUAL,
        value: filter.unecoId
      });
    }
    if (filter.unecoName) {
      myRule.myRules.push({
        field: 'unecoName',
        operator: RuleOperator.CONTAINS,
        value: filter.unecoName
      });
    }
    if (filter.managerGroupsIds.length) {
      myRule.myRules.push({
        field: 'ManagerGroupId',
        operator: RuleOperator.IN,
        value: filter.managerGroupsIds
      });
    }
    if (filter.managerEmailUsersIds.length) {
      myRule.myRules.push({
        field: 'ManagerEmailUserId',
        operator: RuleOperator.IN,
        value: filter.managerEmailUsersIds
      });
    }
    if (filter.delegateUserManagersIds.length) {
      myRule.myRules.push({
        field: 'DelegateUserManagerId',
        operator: RuleOperator.IN,
        value: filter.delegateUserManagersIds
      });
    }
    if (filter.order) {
      myRule.myRules.push({
        field: 'order',
        operator: RuleOperator.CONTAINS,
        value: filter.order
      });
    }

    return queryBuilderService.buildQuery(this.requestsDataManager, myRule, this.requestsQuery.clone());
  }

  private areRequestCustomFieldSettingsLoaded(): boolean {
    return this.props.isCurrentUserInternal || this.props.areRequestCustomFieldSettingsLoaded;
  }
}

const mapStateToProps = (state: RootState): IRequestsListContainerStateProps => ({
  requests: state.requestsListStore.requests,
  mode: state.requestsListStore.mode,
  isChangingMode: state.requestsListStore.isChangingMode,
  filter: state.requestsListStore.filter,
  requestCustomFieldSettings: state.requestsListStore.requestCustomFieldSettings,
  areRequestCustomFieldSettingsLoaded: state.requestsListStore.areRequestCustomFieldSettingsLoaded,
  bidderOptions: state.requestsListStore.bidderOptions,
  centerOptions: state.requestsListStore.centerOptions,
  unecoOptions: state.requestsListStore.unecoOptions,
  isFetching: state.requestsListStore.grid.isFetching,
  searchDate: state.requestsListStore.grid.searchDate,
  currentPage: state.requestsListStore.grid.currentPage,
  pageSize: state.requestsListStore.grid.pageSize,
  totalCount: state.requestsListStore.grid.totalCount,
  sortField: state.requestsListStore.grid.sortField,
  sortDirection: state.requestsListStore.grid.sortDirection,
  isCurrentUserInternal: state.currentUserStore.user.type === UserType.INTERNAL,
  currentUserCompany: state.currentUserStore.company
});

const mapDispatchToProps: IRequestsListContainerDispatchProps = {
  reset: requestsListStore.reset,
  resetHard: requestsListStore.resetHard,
  setMode: requestsListStore.setMode,
  setIsChangingMode: requestsListStore.setIsChangingMode,
  setFilterSubject: requestsListStore.setFilterSubject,
  setFilterCode: requestsListStore.setFilterCode,
  setFilterStatus: requestsListStore.setFilterStatus,
  setFilterCompaniesIds: requestsListStore.setFilterCompaniesIds,
  setFilterApplicantsIds: requestsListStore.setFilterApplicantsIds,
  setFilterCreatedDate: requestsListStore.setFilterCreatedDate,
  setFilterReferenceNames: requestsListStore.setFilterReferenceNames,
  setFilterEciRequest: requestsListStore.setFilterEciRequest,
  setFilterBidderId: requestsListStore.setFilterBidderId,
  setFilterBidderName: requestsListStore.setFilterBidderName,
  setFilterCenterId: requestsListStore.setFilterCenterId,
  setFilterCenterName: requestsListStore.setFilterCenterName,
  setFilterUnecoId: requestsListStore.setFilterUnecoId,
  setFilterUnecoName: requestsListStore.setFilterUnecoName,
  setFilterOrder: requestsListStore.setFilterOrder,
  setCurrentPage: listGridStore.setCurrentPage,
  setPageSize: listGridStore.setPageSize,
  setSorting: listGridStore.setSorting,
  fetchRequests: requestsListStore.fetchRequests,
  fetchRequestCustomFieldSettings: requestsListStore.fetchRequestCustomFieldSettings,
  setFilterManagerGroupsIds: requestsListStore.setFilterManagerGroupsIds,
  setFilterManagerEmailUsersIds: requestsListStore.setFilterManagerEmailUsersIds,
  setFilterDelegateManagerUsersIds: requestsListStore.setFilterDelegateManagerUsersIds
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default compose(
  withRouter,
  withTranslation(),
  connector
)(RequestsListContainer) as ComponentType;
