import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

type UnauthorizedPropsType = WithTranslation;

export class Unauthorized extends Component<UnauthorizedPropsType> {
  public render(): JSX.Element {
    return (
      <div className='row justify-content-center mt-5'>
        <div className='col-auto'>
          <div className='alert alert-warning'>
            {this.props.i18n.t('unauthorized.text')}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Unauthorized);
