import React, { Component, ComponentType } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { compose } from 'redux';
import { Tabs, Tab } from 'react-bootstrap';
import { DataManager, Query } from '@syncfusion/ej2-data';
import { SortDirection } from '@syncfusion/ej2-grids';

import { RootState } from '../../store';
import { ISuppliersListContainerStateProps, ISuppliersListContainerDispatchProps } from '../../interfaces/props/ISuppliersListProps';
import * as suppliersListStore from '../../store/modules/suppliersList.store';
import * as listGridStore from '../../store/modules/listGrid.store';
import { URL } from '../../common/constants';
import { IMyRule } from '../../common/model/myRule.model';
import { UserType } from '../../common/model/enumerations/userType.model';
import { RuleCondition } from '../../common/model/enumerations/ruleCondition.model';
import { RuleOperator } from '../../common/model/enumerations/ruleOperator.model';
import { FilterMode } from '../../common/model/enumerations/filterMode.model';
import { BooleanFilter } from '../../common/model/enumerations/booleanFilter.model';
import { queryBuilderService, oDataService, dataManagerService } from '../../services';
import SuppliersListFilter from './suppliersListFilter/suppliersListFilter';
import SuppliersGrid from './suppliersGrid/suppliersGrid';

type SuppliersListContainerPropsType = PropsFromRedux & RouteComponentProps & WithTranslation;

class SuppliersListContainer extends Component<SuppliersListContainerPropsType> {
  public suppliersDataManager: DataManager = null;
  public suppliersQuery: Query = new Query().select(['id', 'code', 'name', 'contractNumber', 'city', 'province', 'country', 'active']);

  public constructor(props: SuppliersListContainerPropsType) {
    super(props);

    const { reset } = this.props;

    this.suppliersDataManager = dataManagerService.buildDataManager(URL.ODATA.SUPPLIER_FILTERS);

    reset();
  }

  public componentWillUnmount() {
    this.props.reset();
  }

  public onFilterButtonClick() {
    this.props.setCurrentPage(suppliersListStore.Type.SET_CURRENT_PAGE, 1);
    this.fetchSuppliers();
  }

  public onCurrentPageChange(currentPage: number) {
    this.props.setCurrentPage(suppliersListStore.Type.SET_CURRENT_PAGE, currentPage);
    this.fetchSuppliers();
  }

  public onPageSizeChange(pageSize: number) {
    this.props.setPageSize(suppliersListStore.Type.SET_PAGE_SIZE, pageSize);
    this.fetchSuppliers();
  }

  public onSortingChange(field: string, direction: SortDirection) {
    this.props.setSorting(suppliersListStore.Type.SET_SORTING, field, direction);
    this.fetchSuppliers();
  }

  public render() {
    const {
      suppliers,
      filter,
      isFetching,
      searchDate,
      currentPage,
      pageSize,
      totalCount,
      isCurrentUserInternal,
      setFilterCode,
      setFilterName,
      setFilterActive,
      sortField,
      sortDirection,
      i18n
    } = this.props;

    return (
      <div>
        <div className='row'>
          <div className='col'>
            <h2>{i18n.t('suppliersList.title')}</h2>
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <div className='mb-3'>
              <Tabs id='suppliersListTabs' className='mb-3'>
                <Tab eventKey={FilterMode.SIMPLE} title={i18n.t('enums.filterMode.SIMPLE')}>
                  <SuppliersListFilter
                    filter={filter}
                    isFetching={isFetching}
                    onCodeChange={setFilterCode}
                    onNameChange={setFilterName}
                    onActiveChange={setFilterActive}
                    onFilterButtonClick={() => this.onFilterButtonClick()}
                  />
                </Tab>
              </Tabs>
            </div>
            <SuppliersGrid
              suppliers={suppliers}
              isFetching={isFetching}
              searchDate={searchDate}
              currentPage={currentPage}
              pageSize={pageSize}
              totalCount={totalCount}
              sortField={sortField}
              sortDirection={sortDirection}
              isCurrentUserInternal={isCurrentUserInternal}
              onCurrentPageChange={(cp) => this.onCurrentPageChange(cp)}
              onPageSizeChange={(ps) => this.onPageSizeChange(ps)}
              onSortingChange={(field, direction) => this.onSortingChange(field, direction)}
            />
          </div>
        </div>
      </div>
    );
  }

  private fetchSuppliers() {
    const { isFetching, fetchSuppliers } = this.props;

    if (!isFetching) {
      fetchSuppliers(oDataService, this.suppliersDataManager, this.buildQuery());
    }
  }

  private buildQuery(): Query {
    const { filter } = this.props;

    const myRule: IMyRule = {
      condition: RuleCondition.AND,
      myRules: []
    };
    if (filter.code) {
      myRule.myRules.push({
        field: 'code',
        operator: RuleOperator.CONTAINS,
        value: filter.code,
        ignoreCase: true
      });
    }
    if (filter.name) {
      myRule.myRules.push({
        field: 'name',
        operator: RuleOperator.CONTAINS,
        value: filter.name,
        ignoreCase: true
      });
    }
    if (filter.active !== BooleanFilter.ALL) {
      myRule.myRules.push({
        field: 'active',
        operator: RuleOperator.EQUAL,
        value: filter.active === BooleanFilter.YES ? true : false
      });
    }

    return queryBuilderService.buildQuery(this.suppliersDataManager, myRule, this.suppliersQuery.clone());
  }
}

const mapStateToProps = (state: RootState): ISuppliersListContainerStateProps => ({
  suppliers: state.suppliersListStore.suppliers,
  filter: state.suppliersListStore.filter,
  isFetching: state.suppliersListStore.grid.isFetching,
  searchDate: state.suppliersListStore.grid.searchDate,
  currentPage: state.suppliersListStore.grid.currentPage,
  pageSize: state.suppliersListStore.grid.pageSize,
  totalCount: state.suppliersListStore.grid.totalCount,
  sortField: state.suppliersListStore.grid.sortField,
  sortDirection: state.suppliersListStore.grid.sortDirection,
  isCurrentUserInternal: state.currentUserStore.user.type === UserType.INTERNAL
});

const mapDispatchToProps: ISuppliersListContainerDispatchProps = {
  reset: suppliersListStore.reset,
  setFilterCode: suppliersListStore.setFilterCode,
  setFilterName: suppliersListStore.setFilterName,
  setFilterActive: suppliersListStore.setFilterActive,
  setCurrentPage: listGridStore.setCurrentPage,
  setPageSize: listGridStore.setPageSize,
  setSorting: listGridStore.setSorting,
  fetchSuppliers: suppliersListStore.fetchSuppliers
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default compose(
  withRouter,
  withTranslation(),
  connector
)(SuppliersListContainer) as ComponentType;
