import React, { Component, ComponentType } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { compose } from 'redux';

import { RootState } from '../../store';
import { ISimulationModeBannerStateProps } from '../../interfaces/props/ISimulationModeProps';

type SimulationModeBannerPropsType = PropsFromRedux & WithTranslation;

class SimulationModeBanner extends Component<SimulationModeBannerPropsType> {
  public render() {
    const { currentUserCompanyName, currentUserName, i18n } = this.props;

    return (
      <div className='alert alert-warning'>
      <div className='row justify-content-center'>
        <div className='col-auto'>
          <span className='icon icon-user-impersonate' style={{ fontSize: '24px' }} />
        </div>
        <div className='col-auto'>
          <span>{i18n.t('simulationModeBanner.message', { currentUserCompanyName, currentUserName })}</span>
        </div>
      </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): ISimulationModeBannerStateProps => ({
  currentUserCompanyName: state.currentUserStore.company ? state.currentUserStore.company.name : '',
  currentUserName: state.currentUserStore.user ? `${state.currentUserStore.user.firstName} ${state.currentUserStore.user.lastName}` : ''
});

const connector = connect(mapStateToProps, {});

type PropsFromRedux = ConnectedProps<typeof connector>;

export default compose(
  withTranslation(),
  connector
)(SimulationModeBanner) as ComponentType;
