import moment from 'moment';

import { URL } from '../common/constants';
import { IRequest } from '../common/model/request.model';
import { ICustomFieldPermission } from '../common/model/customFieldPermission.model';
import { IReportComboExtended } from '../common/model/report.model';
import { IReference } from '../common/model/reference.model';
import { IAnnex } from '../common/model/annex.model';
import { INotificationWithViewInfo } from '../common/model/notification.model';
import { INote } from '../common/model/note.model';
import { ICustomFieldOptions } from '../common/model/customFieldOptions.model';
import * as services from '.';
import * as requestUtils from '../utils/request.utils';
import { IRequestChangeHistory } from '../common/model/requestChangeHistory.model';

export interface IRequestsService {
  fetch(id: string): Promise<IRequest>;
  create(request: IRequest): Promise<IRequest>;
  update(request: IRequest): Promise<IRequest>;
  fetchCustomFieldPermissions(): Promise<ICustomFieldPermission[]>;
  fetchReports(id: string): Promise<IReportComboExtended[]>;
  fetchReferences(id: string): Promise<IReference[]>;
  fetchAnnexes(id: string): Promise<IAnnex[]>;
  fetchNotifications(id: string): Promise<INotificationWithViewInfo[]>;
  fetchNotes(id: string): Promise<INote[]>;
  fetchCustomFieldOptions(code: string): Promise<ICustomFieldOptions[]>;
  fetchRequestChangesHistory(id: string): Promise<IRequestChangeHistory[]>;
}

class RequestsService implements IRequestsService {
  public constructor(private httpClient: services.IHttpClient) {

  }

  public async fetch(id: string): Promise<IRequest> {
    return requestUtils.initDates(await this.httpClient.getCustom<IRequest>(`${URL.WEB.BASE}${URL.WEB.REQUESTS}/${id}`));
  }

  public async create(request: IRequest): Promise<IRequest> {
    return requestUtils.initDates(await this.httpClient.postCustom(`${URL.WEB.BASE}${URL.WEB.REQUESTS}`, request));
  }

  public async update(request: IRequest): Promise<IRequest> {
    return requestUtils.initDates(await this.httpClient.putCustom(`${URL.WEB.BASE}${URL.WEB.REQUESTS}/${request.id}`, request));
  }

  public async fetchCustomFieldPermissions(): Promise<ICustomFieldPermission[]> {
    try {
      return (await this.httpClient.getCustom<ICustomFieldPermission[]>(`${URL.WEB.BASE}${URL.WEB.REQUESTS}/customFieldsPermissions`));
    } catch (error) {
      return [];
    }
  }

  public async fetchReports(id: string): Promise<IReportComboExtended[]> {
    try {
      return await this.httpClient.getCustom<IReportComboExtended[]>(`${URL.WEB.BASE}${URL.WEB.REQUESTS}/${id}/reports`);
    } catch (error) {
      return [];
    }
  }

  public async fetchReferences(id: string): Promise<IReference[]> {
    try {
      return await this.httpClient.getCustom<IReference[]>(`${URL.WEB.BASE}${URL.WEB.REQUESTS}/${id}/references`);
    } catch (error) {
      return [];
    }
  }

  public async fetchAnnexes(id: string): Promise<IAnnex[]> {
    try {
      return await this.httpClient.getCustom<IAnnex[]>(`${URL.WEB.BASE}${URL.WEB.REQUESTS}/${id}/annexes`);
    } catch (error) {
      return [];
    }
  }

  public async fetchNotifications(id: string): Promise<INotificationWithViewInfo[]> {
    try {
      return (await this.httpClient.getCustom<INotificationWithViewInfo[]>(`${URL.WEB.BASE}${URL.WEB.REQUESTS}/${id}/notifications`))
        .map((notification) => ({ ...notification, createdDate: moment(notification.createdDate) }));
    } catch (error) {
      return [];
    }
  }

  public async fetchNotes(id: string): Promise<INote[]> {
    try {
      return (await this.httpClient.getCustom<INote[]>(`${URL.WEB.BASE}${URL.WEB.REQUESTS}/${id}/notes`))
        .map((note) => ({ ...note, createdDate: moment(note.createdDate) }));
    } catch (error) {
      return [];
    }
  }

  public async fetchCustomFieldOptions(code: string): Promise<ICustomFieldOptions[]> {
    try {
      return (await this.httpClient.getCustom<ICustomFieldOptions[]>(`${URL.WEB.BASE}${URL.WEB.REQUEST_CUSTOM_FIELDS}/${code}/options`));
    } catch (error) {
      return [];
    }
  }

  public async fetchRequestChangesHistory(id: string): Promise<IRequestChangeHistory[]> {
    try {
      return (await this.httpClient.getCustom<IRequestChangeHistory[]>(`${URL.WEB.BASE}${URL.WEB.REQUESTS}/${id}/changesHistory`))
        .map((requestChangeHistory) => ({ ...requestChangeHistory, createdDate: moment(requestChangeHistory.createdDate) }));
    } catch (error) {
      return [];
    }
  }
}

export const requestsService = new RequestsService(services.httpClient);
