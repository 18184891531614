import React, { Component, Fragment } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { WithTranslation, withTranslation } from 'react-i18next';
import { DropDownListComponent, ChangeEventArgs as DropdownChangeEventArgs, FieldSettingsModel } from '@syncfusion/ej2-react-dropdowns';
import { SwitchComponent, ChangeEventArgs as ButtonChangeEventArgs } from '@syncfusion/ej2-react-buttons';
import { AccordionComponent, AccordionItemsDirective, AccordionItemDirective, ExpandedEventArgs, ItemModel, ClickEventArgs } from '@syncfusion/ej2-react-navigations';
import { GridComponent, PageSettingsModel, ColumnsDirective, ColumnDirective, Inject, Sort, Page, Resize, /*GridModel,*/ SortSettingsModel, Toolbar,
  ExcelExport, DetailRow, PagerComponent } from '@syncfusion/ej2-react-grids';
import { ActionBarComponent } from '@aitex/tsx-extranet-action-bar';

import { IClientDataTabProps } from '../../../../interfaces/props/IClientDetailProps';
import { ICompanyCertification } from '../../../../common/model/companyCertification.model';
import { IUser } from '../../../../common/model/user.model';
import { IStringToAnyDictionary } from '../../../../common/model/stringToAnyDictionary.model';
import { UserType } from '../../../../common/model/enumerations/userType.model';
import { ReportCustomFieldSettingType } from '../../../../common/model/enumerations/reportCustomFieldSettingType.model';
import { RequestCustomFieldSettingType } from '../../../../common/model/enumerations/requestCustomFieldSettingType.model';
import * as gridUtils from '../../../../utils/grid.utils';
import * as pagerUtils from '../../../../utils/pager.utils';
import * as certificateTypeUtils from '../../../../utils/certificateType.utils';
import { enumToArray } from '../../../../utils/enum.utils';
import { notifyError } from '../../../../utils/toast.utils';
import * as dateHelpers from '../../../../helpers/date.helpers';
import FormError from '../../../../components/formError/formError';
import ValidationError from '../../../../components/validationError/validationError';
import TextBox from '../../../../components/filters/textBox';
import './clientDataTab.scss';

type ClientDataTabPropsType = IClientDataTabProps & RouteComponentProps & WithTranslation;

class ClientDataTab extends Component<ClientDataTabPropsType> {
  public reportCustomFieldSettingTypeOptions: IStringToAnyDictionary[] = [];
  public requestCustomFieldSettingTypeOptions: IStringToAnyDictionary[] = [];
  public customFieldSettingTypeFields: FieldSettingsModel = { text: 'text', value: 'value' };

  public certificationsGridComponent: GridComponent = null;
  public pageSettings: PageSettingsModel = {
    pageSize: pagerUtils.getDefaultPageSize(),
    pageCount: pagerUtils.getPageCount(),
    pageSizes: pagerUtils.getPageSizes()
  };

  public usersGridComponent: GridComponent = null;
  public usersGridOptions: Array<ItemModel> = [];
  public usersSortOptions: SortSettingsModel = {};
  public usersPagerComponent: PagerComponent = null;

  public logoInputElement: HTMLInputElement = null;
  private readonly CREATE_EXTERNAL_USER_ACTION_ID = 'createExternalUser';

  private readonly LOGO_MAX_FILE_SIZE_IN_MB = 1;

  public constructor(props: ClientDataTabPropsType) {
    super(props);

    this.reportCustomFieldSettingTypeOptions = enumToArray(ReportCustomFieldSettingType, 'number', 'text', 'value')
      .map((item) => ({ ...item, text: this.props.i18n.t('enums.reportCustomFieldSettingType.' + item.text) }));
    this.requestCustomFieldSettingTypeOptions = enumToArray(RequestCustomFieldSettingType, 'number', 'text', 'value')
      .map((item) => ({ ...item, text: this.props.i18n.t('enums.requestCustomFieldSettingType.' + item.text) }));

    this.usersGridOptions = [];

    if (this.props.showCreateUserButton) {
      this.usersGridOptions.push({
        id: this.CREATE_EXTERNAL_USER_ACTION_ID,
        tooltipText: this.props.i18n.t('usersList.actions.createExternalUser'),
        template: `
        <button type="button" class="e-tbar-btn e-tbtn-txt e-control e-btn e-lib">
          <span class="icon icon-user-external"></span>
          <span class="ml-1 icon icon-app-item-add"></span>
        </button>
      `
      });
    }
  }

  public componentDidUpdate(prevProps: ClientDataTabPropsType) {
    gridUtils.manageSpinner(this.certificationsGridComponent, prevProps.isFetchingCompanyCertifications, this.props.isFetchingCompanyCertifications);
    gridUtils.manageSpinner(this.usersGridComponent, prevProps.isFetchingCompanyUsers, this.props.isFetchingCompanyUsers);
    gridUtils.manageDataSource(this.certificationsGridComponent, () => this.updateCertificationsGridComponentDataSource(), prevProps.isFetchingCompanyCertifications, this.props.isFetchingCompanyCertifications);
    gridUtils.manageDataSource(this.usersGridComponent, () => this.updateUsersGridComponentDataSource(), prevProps.isFetchingCompanyUsers, this.props.isFetchingCompanyUsers);
  }

  public onReportCustomFieldSettingTypeChange(e: DropdownChangeEventArgs) {
    this.props.setClientReportCustomFieldSettingType(e.value as number);
  }

  public onRequestCustomFieldSettingTypeChange(e: DropdownChangeEventArgs) {
    this.props.setClientRequestCustomFieldSettingType(e.value as number);
  }

  public onHasReportVersionsChange(e: ButtonChangeEventArgs) {
    this.props.setClientHasReportVersions(e.checked);
  }

  public handleLogoInput(event: React.ChangeEvent<HTMLInputElement>) {
    if (event && event.target.files && event.target.files[0]) {
      const selectedFile = event.target.files[0];

      const fileSizeInMb = (selectedFile.size / 1024) / 1024;
      if (fileSizeInMb > this.LOGO_MAX_FILE_SIZE_IN_MB) {
        notifyError(this.props.i18n.t('clientDetail.errors.logoSize'));
        return;
      }

      const fileReader = new FileReader();
      fileReader.readAsDataURL(selectedFile);
      fileReader.onload = (onLoadEvent) => {
        const base64Logo = (onLoadEvent.target.result as string).substring((onLoadEvent.target.result as string).indexOf('base64,') + 'base64,'.length);
        this.props.setClientLogo(base64Logo);
      };
    }
  }

  public deleteLogo() {
    this.props.setClientLogo(null);
  }

  public onAccordionComponentExpanded(event: ExpandedEventArgs) {
    if (event.isExpanded) {
      if (event.index === 0) {
        this.props.onCertificationsAccordionExpanded();
      }
      else if (event.index === 1) {
        this.props.onUsersAccordionExpanded();
      }
    }
  }

  public getAccordionItemHeader(): string {
    return `<div class="row">
      <div class="col-auto">
        <span class="icon icon-company-certification"></span>
      </div>
      <div class="col">
        <span>${this.props.i18n.t('company.companyCertifications')}</span>
      </div>
    </div>`;
  }

  public updateCertificationsGridComponentDataSource() {
    this.certificationsGridComponent.dataSource = this.props.companyCertifications;
  }

  public updateUsersGridComponentDataSource() {
    this.usersGridComponent.dataSource = this.props.companyUsers;
  }

  public getAccordionUserHeader(): string {
    return `<div class="row">
      <div class="col-auto">
        <span class="icon icon-users-management"></span>
      </div>
      <div class="col">
        <span>${this.props.i18n.t('company.users')}</span>
      </div>
    </div>`;
  }

  public getAccordionItemContent(): JSX.Element {
    return (
      <GridComponent
        allowSorting={true}
        allowPaging={true}
        allowResizing={true}
        sortSettings={{ columns: [{ field: 'validUntilDate', direction: 'Descending' }] }}
        pageSettings={this.pageSettings}
        locale={this.props.i18n.language}
        ref={(grid: GridComponent) => this.certificationsGridComponent = grid}
        created={() => this.updateCertificationsGridComponentDataSource()}
      >
        <ColumnsDirective>
          <ColumnDirective
            field='certificateTypeValue'
            headerText={this.props.i18n.t('companyCertification.certificateType')}
            width='100'
            type='string'
            valueAccessor={(_field, data) => this.getCertificateTypeValueLabel(data as ICompanyCertification)}
          />
          <ColumnDirective field='certificateNumber' headerText={this.props.i18n.t('companyCertification.certificateNumber')} width='100' type='string' />
          <ColumnDirective
            field='validFromDate'
            headerText={this.props.i18n.t('companyCertification.validFromDate')}
            width='100'
            type='string'
            valueAccessor={(_field, data) => this.getValidFromDateLabel(data as ICompanyCertification)}
          />
          <ColumnDirective
            field='validUntilDate'
            headerText={this.props.i18n.t('companyCertification.validUntilDate')}
            width='100'
            type='string'
            valueAccessor={(_field, data) => this.getValidUntilDateLabel(data as ICompanyCertification)}
          />
        </ColumnsDirective>
        <Inject services={[Sort, Page, Resize]} />
      </GridComponent>
    );
  }

  public onUsersGridToolbarClick = (args: ClickEventArgs): void => {
    if (args.item.id === this.CREATE_EXTERNAL_USER_ACTION_ID) {
      this.props.history.push('/user/external/new');
    }
  }

  public getUsersFirstNameColumnTemplate(user: IUser): JSX.Element {
    return (
      <span className='e-link' onClick={(): void => this.props.history.push(`/sso-redirect/user/${user.type === UserType.INTERNAL ? 'internal' : 'external'}/${user.id}/${user.type === UserType.INTERNAL ? 'view' : 'edit'}`)}>
        {user.firstName}
      </span>
    );
  }

  public getUserTypeLabel(user: IUser): string {
    return this.props.i18n.t('enums.userType.' + UserType[user.type]);
  }

  public getExternalUserRoleName(user: IUser): string {
    return this.props.i18n.t('enums.roleNormalizedName.' + user.roleName);
  }

  public getExternalUserDisabled(user: IUser): boolean {
    return user.disabled;
  }

  public getAccordionUserContent(): JSX.Element {
    const {
      companyUsers
    } = this.props;

    /* Si algún día se quiere poner la Toolbar para crear usuarios, sólo hay que añadir siguiente línea al Grid */
    //toolbar = { this.usersGridOptions }

    return (
      <Fragment>
        <div className='users-grid'>
          <GridComponent
            dataSource={companyUsers}
            allowSorting={true}
            allowPaging={true}   /* Si no se incluye este paginador (oculto por css), no se muestra el dropdown en el customizado */
            allowExcelExport={true}
            allowResizing={true}
            sortSettings={this.usersSortOptions}
            pageSettings={{ pageSize: pagerUtils.getMaxPageSize() }}
            locale={this.props.i18n.language}
            ref={(grid: GridComponent): GridComponent => this.usersGridComponent = grid}
            toolbarClick={(args): void => this.onUsersGridToolbarClick(args)}
          >
            <ColumnsDirective>
              <ColumnDirective
                headerText={this.props.i18n.t('user.firstName')}
                field='firstName'
                width='100'
                template={(user: IUser): JSX.Element => this.getUsersFirstNameColumnTemplate(user)}
              />
              <ColumnDirective headerText={this.props.i18n.t('user.lastName')} field='lastName' width='130' />
              <ColumnDirective headerText={this.props.i18n.t('user.email')} field='email' width='130' />
              <ColumnDirective headerText={this.props.i18n.t('user.phoneNumber')} field='phoneNumber' width='80' />
              <ColumnDirective
                headerText={this.props.i18n.t('user.type')}
                field='type'
                width='80'
                valueAccessor={(_field, data): string => this.getUserTypeLabel(data as IUser)}
              />
              <ColumnDirective
                headerText={this.props.i18n.t('user.role')}
                width='100'
                valueAccessor={(_field, data): string => this.getExternalUserRoleName(data as IUser)}
              />
              <ColumnDirective
                headerText={this.props.i18n.t('user.disabled')}
                width='100'
                type='boolean'
                displayAsCheckBox={true}
                valueAccessor={(_field, data): boolean => this.getExternalUserDisabled(data as IUser)}
              />
            </ColumnsDirective>
            <Inject services={[Sort, Page, Toolbar, ExcelExport, Resize, DetailRow]} />
          </GridComponent>
          {companyUsers.length
            ? <PagerComponent
                pageSize={pagerUtils.getDefaultPageSize()}
                pageSizes={pagerUtils.getPageSizes()}
                ref={(pagerComponent: PagerComponent): PagerComponent => this.usersPagerComponent = pagerComponent}/>
            : null
          }
        </div>
      </Fragment>
    );
  }

  public getCertificateTypeValueLabel(companyCertification: ICompanyCertification): string {
    return certificateTypeUtils.getLocalizedText(this.props.i18n, companyCertification.certificateTypeValue);
  }

  public getValidFromDateLabel(companyCertification: ICompanyCertification): string {
    return dateHelpers.toShortDate(companyCertification.validFromDate, true);
  }

  public getValidUntilDateLabel(companyCertification: ICompanyCertification): string {
    return dateHelpers.toShortDate(companyCertification.validUntilDate, true);
  }

  public render(): JSX.Element {
    const {
      client,
      shouldShowEditReportCustomFieldSettingsButton,
      disabled,
      editing,
      loading,
      errors,
      onEditButtonClick,
      onSaveButtonClick,
      onCancelButtonClick,
      setClientName,
      setClientAddress,
      setClientCity,
      setClientProvince,
      setClientPostalCode,
      setClientCountry,
      onEditReportCustomFieldSettingsButtonClick,
      i18n
    } = this.props;

    return (
      <Fragment>
        <div className='client-data-card'>
          <FormError errors={errors}/>
          <div className='row'>
            <div className='col'>
              <div className='form-group'>
                <div className='autocomplete-input'>
                  <div className='autocomplete-label'>{i18n.t('company.name')}</div>
                  <TextBox
                    name='name'
                    value={client.name}
                    readonly={!editing}
                    onChange={(value) => setClientName(value)}
                  />
                </div>
                <ValidationError errors={errors} errorKey={'Name'}/>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-12 col-md-6'>
              <div className='form-group'>
                <div className='autocomplete-input'>
                  <div className='autocomplete-label'>{i18n.t('company.address')}</div>
                  <TextBox
                    name='address'
                    value={client.address}
                    readonly={!editing}
                    onChange={(value) => setClientAddress(value)}
                  />
                </div>
                <ValidationError errors={errors} errorKey={'Address'}/>
              </div>
            </div>
            <div className='col-12 col-md-6'>
              <div className='form-group'>
                <div className='autocomplete-input'>
                  <div className='autocomplete-label'>{i18n.t('company.city')}</div>
                  <TextBox
                    name='city'
                    value={client.city}
                    readonly={!editing}
                    onChange={(value) => setClientCity(value)}
                  />
                </div>
                <ValidationError errors={errors} errorKey={'City'}/>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-12 col-md-6'>
              <div className='form-group'>
                <div className='autocomplete-input'>
                  <div className='autocomplete-label'>{i18n.t('company.province')}</div>
                  <TextBox
                    name='province'
                    value={client.province}
                    readonly={!editing}
                    onChange={(value) => setClientProvince(value)}
                  />
                </div>
                <ValidationError errors={errors} errorKey={'Province'}/>
              </div>
            </div>
            <div className='col-12 col-md-6'>
              <div className='form-group'>
                <div className='autocomplete-input'>
                  <div className='autocomplete-label'>{i18n.t('company.postalCode')}</div>
                  <TextBox
                    name='postalCode'
                    value={client.postalCode}
                    readonly={!editing}
                    onChange={(value) => setClientPostalCode(value)}
                  />
                </div>
                <ValidationError errors={errors} errorKey={'PostalCode'}/>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-12 col-md-6'>
              <div className='form-group'>
                <div className='autocomplete-input'>
                  <div className='autocomplete-label'>{i18n.t('company.country')}</div>
                  <TextBox
                    name='country'
                    value={client.country}
                    readonly={!editing}
                    onChange={(value) => setClientCountry(value)}
                  />
                </div>
                <ValidationError errors={errors} errorKey={'Country'}/>
              </div>
            </div>
            <div className='col-12 col-md-6'>
              <div className='row'>
                <div className='col'>
                  <div className='form-group'>
                    <div className='autocomplete-input'>
                      <div className='autocomplete-label'>{i18n.t('company.reportCustomFieldSettingType')}</div>
                      <DropDownListComponent
                        dataSource={this.reportCustomFieldSettingTypeOptions}
                        fields={this.customFieldSettingTypeFields}
                        value={client.reportCustomFieldSettingType}
                        enabled={editing}
                        change={(e) => this.onReportCustomFieldSettingTypeChange(e)}
                      />
                    </div>
                  </div>
                </div>
                {shouldShowEditReportCustomFieldSettingsButton &&
                  <div className='col-auto'>
                    <button
                      type='button'
                      className='btn btn-light'
                      title={i18n.t('clientDetail.actions.editReportCustomFieldSettings')}
                      onClick={() => onEditReportCustomFieldSettingsButtonClick()}>
                      <span className='icon icon-tuning'/>
                    </button>
                  </div>}
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-12 col-md-6'/>
            <div className='col-12 col-md-6'>
              <div className='form-group'>
                <div className='autocomplete-input'>
                  <div className='autocomplete-label'>{i18n.t('company.requestCustomFieldSettingType')}</div>
                  <DropDownListComponent
                    dataSource={this.requestCustomFieldSettingTypeOptions}
                    fields={this.customFieldSettingTypeFields}
                    value={client.requestCustomFieldSettingType}
                    enabled={editing}
                    change={(e) => this.onRequestCustomFieldSettingTypeChange(e)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-xs col-md-6'>
              <div className={`form-group${!editing && !client.logo ? ' d-none' : ''}`}>
                <label htmlFor='logo'>{i18n.t('company.logo')}</label>
                {client.logo
                  ? <div className='row align-items-center'>
                      <div className='col'>
                        <div className='client-data-tab-logo-centered-container'>
                          <img className='client-data-tab-logo-contain' src={`data:image;base64,${client.logo}`} alt={i18n.t('company.logo')}/>
                        </div>
                      </div>
                      {editing &&
                      <div className='col-auto'>
                      <button type='button' onClick={() => this.deleteLogo()} className='btn btn-light' title={i18n.t(
                        'clientDetail.actions.deleteLogo')}>
                        <span className='icon icon-app-item-close' />
                      </button>
                    </div>}
                    </div>
                  : <div className='row'>
                      <div className='col'>
                        <button type='button' onClick={() => this.logoInputElement.click()} className='btn btn-outline-primary w-100' disabled={!editing}>
                          <span>{i18n.t('clientDetail.actions.selectLogo')}</span>
                        </button>
                      </div>
                    </div>
                }
                <input hidden={true} type='file' id='logo' onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleLogoInput(event)} accept='image/*' ref={(inputElement: HTMLInputElement) => this.logoInputElement = inputElement}/>
              </div>
            </div>
            <div className='col-xs col-md-6'>
              <div className='form-group d-flex' style={{ marginTop: '7px' }}>
                <label htmlFor='isImportant' className='mr-2'>{i18n.t('company.hasReportVersions')}</label>
                <div style={{ marginTop: '0.15rem' }}>
                  <SwitchComponent
                    id='hasReportVersions'
                    checked={client.hasReportVersions}
                    disabled={!editing}
                    change={(e: ButtonChangeEventArgs) => this.onHasReportVersionsChange(e)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <ActionBarComponent
          disabled={disabled}
          editing={editing}
          loading={loading}
          withoutTopBorder={true}
          onEditButtonClick={onEditButtonClick}
          onSaveButtonClick={onSaveButtonClick}
          onCancelButtonClick={onCancelButtonClick}
        />
        <div className='row mt-3'>
          <div className='col'>
            <div className='form-group client-data-tab-accordion'>
              <AccordionComponent
                expandMode='Single'
                expanded={(event) => this.onAccordionComponentExpanded(event)}
              >
                <AccordionItemsDirective>
                  <AccordionItemDirective
                    header={this.getAccordionItemHeader()}
                    content={() => this.getAccordionItemContent()}
                  />
                  <AccordionItemDirective
                    header={this.getAccordionUserHeader()}
                    content={() => this.getAccordionUserContent()}
                  />
                </AccordionItemsDirective>
              </AccordionComponent>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

//export default withTranslation()(ClientDataTab);
export default withRouter(withTranslation()(ClientDataTab));
