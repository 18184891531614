import React, { Component, ComponentType } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { compose } from 'redux';
import { Tabs, Tab } from 'react-bootstrap';
import { DataManager, Query } from '@syncfusion/ej2-data';
import { SortDirection } from '@syncfusion/ej2-grids';
import { dialogsService } from '@aitex/tsx-extranet-dialogs';

import { RootState } from '../../store';
import { INotificationsListContainerStateProps, INotificationsListContainerDispatchProps } from '../../interfaces/props/INotificationsListProps';
import * as notificationsListStore from '../../store/modules/notificationsList.store';
import * as notificationsListNotificationsStore from '../../store/modules/notificationsListNotifications.store';
import * as listGridStore from '../../store/modules/listGrid.store';
import * as dialogsStore from '../../store/modules/dialogs.store';
import { URL } from '../../common/constants';
import { IMyRule } from '../../common/model/myRule.model';
import { IErrors } from '../../common/model/errors.model';
import { NotificationScope } from '../../common/model/enumerations/notificationScope.model';
import { BooleanFilter } from '../../common/model/enumerations/booleanFilter.model';
import { RuleCondition } from '../../common/model/enumerations/ruleCondition.model';
import { RuleOperator } from '../../common/model/enumerations/ruleOperator.model';
import { DialogId } from '../../common/model/enumerations/dialogId.model';
import { FilterMode } from '../../common/model/enumerations/filterMode.model';
import { queryBuilderService, oDataService, dataManagerService, notificationsService } from '../../services';
import { notifyError } from '../../utils/toast.utils';
import NotificationsListFilter from './notificationsListFilter/notificationsListFilter';
import NotificationsGrid from './notificationsGrid/notificationsGrid';

type NotificationsListContainerPropsType = PropsFromRedux & RouteComponentProps & WithTranslation;

class NotificationsListContainer extends Component<NotificationsListContainerPropsType> {
  public notificationsDataManager: DataManager = null;
  public notificationsQuery = new Query()
    .select(['id', 'subject', 'scope', 'createdDate', 'deletedDate', 'companyName', 'emailDestinations'])
    .expand(['notificationsReports', 'notificationsRequests']);

  public companiesDataManager: DataManager = null;

  public constructor(props: NotificationsListContainerPropsType) {
    super(props);

    const { reset } = this.props;

    this.notificationsDataManager = dataManagerService.buildDataManager(URL.ODATA.NOTIFICATION_FILTERS);

    this.companiesDataManager = dataManagerService.buildDataManager(URL.ODATA.COMPANY_FILTERS);

    reset(notificationsListStore.NotificationsListType.RESET);
  }

  public componentWillUnmount() {
    this.props.reset(notificationsListStore.NotificationsListType.RESET);
  }

  public onFilterSubjectChange(subject: string) {
    this.props.setFilterSubject(notificationsListStore.NotificationsListType.SET_FILTER_SUBJECT, subject);
  }

  public onFilterScopeChange(scope: NotificationScope) {
    this.props.setFilterScope(notificationsListStore.NotificationsListType.SET_FILTER_SCOPE, scope);
  }

  public onFilterActiveChange(active: BooleanFilter) {
    this.props.setFilterActive(notificationsListStore.NotificationsListType.SET_FILTER_ACTIVE, active);
  }

  public onFilterCompaniesIdsChange(companiesIds: string[]) {
    this.props.setFilterCompaniesIds(notificationsListStore.NotificationsListType.SET_FILTER_COMPANIES_IDS, companiesIds);
  }

  public onFilterEmailChange(email: BooleanFilter) {
    this.props.setFilterEmail(notificationsListStore.NotificationsListType.SET_FILTER_EMAIL, email);
  }

  public onFilterButtonClick() {
    this.props.setCurrentPage(notificationsListStore.NotificationsListType.SET_CURRENT_PAGE, 1);
    this.fetchNotifications();
  }

  public onCurrentPageChange(currentPage: number) {
    this.props.setCurrentPage(notificationsListStore.NotificationsListType.SET_CURRENT_PAGE, currentPage);
    this.fetchNotifications();
  }

  public onPageSizeChange(pageSize: number) {
    this.props.setPageSize(notificationsListStore.NotificationsListType.SET_PAGE_SIZE, pageSize);
    this.fetchNotifications();
  }

  public onSortingChange(field: string, direction: SortDirection) {
    this.props.setSorting(notificationsListStore.NotificationsListType.SET_SORTING, field, direction);
    this.fetchNotifications();
  }

  public deactivateNotification(notificationId: string) {
    const { prepareDeactivateEntityDialog, i18n } = this.props;

    prepareDeactivateEntityDialog(
      notificationId,
      i18n.t('dialogs.deactivateEntityDialog.entityName.notification'),
      (entityId: string) => notificationsService.deactivate(entityId)
    );
    dialogsService.openDialog(DialogId.DEACTIVATE_ENTITY)
      .then(() => this.fetchNotifications())
      .catch((reason?: IErrors) => {
        if (reason) {
          if (reason.ControlledError) {
            notifyError(reason.ControlledError[0]);
          }
          if (reason.UnknownError) {
            notifyError(this.props.i18n.t(reason.UnknownError[0]));
          }
        }
      });
  }

  public render() {
    const { notifications, filter, isFetching, searchDate, currentPage, pageSize, totalCount, sortField, sortDirection, i18n } = this.props;

    return (
      <div>
      <div className='row'>
        <div className='col'>
          <h2>{i18n.t('notificationsList.title')}</h2>
        </div>
      </div>
        <div className='row'>
          <div className='col'>
            <div className='mb-3'>
              <Tabs id='notificationsListTabs' className='mb-3'>
                <Tab eventKey={FilterMode.SIMPLE} title={i18n.t('enums.filterMode.SIMPLE')}>
                  <NotificationsListFilter
                    filter={filter}
                    isFetching={isFetching}
                    companiesDataManager={this.companiesDataManager}
                    onSubjectChange={(subject) => this.onFilterSubjectChange(subject)}
                    onScopeChange={(scope) => this.onFilterScopeChange(scope)}
                    onActiveChange={(active) => this.onFilterActiveChange(active)}
                    onCompaniesIdsChange={(companiesIds) => this.onFilterCompaniesIdsChange(companiesIds)}
                    onEmailChange={(email) => this.onFilterEmailChange(email)}
                    onFilterButtonClick={() => this.onFilterButtonClick()}
                  />
                </Tab>
              </Tabs>
            </div>
            <NotificationsGrid
              notifications={notifications}
              isViewStatus={false}
              isFetching={isFetching}
              searchDate={searchDate}
              currentPage={currentPage}
              pageSize={pageSize}
              totalCount={totalCount}
              sortField={sortField}
              sortDirection={sortDirection}
              onCurrentPageChange={(cp) => this.onCurrentPageChange(cp)}
              onPageSizeChange={(ps) => this.onPageSizeChange(ps)}
              onSortingChange={(field, direction) => this.onSortingChange(field, direction)}
              onDeactivateNotificationButtonClick={(notificationId) => this.deactivateNotification(notificationId)}
            />
          </div>
        </div>
      </div>
    );
  }

  private fetchNotifications() {
    const { isFetching, fetchNotifications } = this.props;

    if (!isFetching) {
      fetchNotifications(oDataService, this.notificationsDataManager, this.buildQuery());
    }
  }

  private buildQuery(): Query {
    const { filter } = this.props;

    const myRule: IMyRule = {
      condition: RuleCondition.AND,
      myRules: []
    };
    if (filter.subject) {
      myRule.myRules.push({
        field: 'subject',
        operator: RuleOperator.CONTAINS,
        value: filter.subject,
        ignoreCase: true
      });
    }
    if (filter.scope !== NotificationScope.ALL) {
      myRule.myRules.push({
        field: 'scope',
        operator: RuleOperator.EQUAL,
        value: filter.scope
      });
    }
    if (filter.active !== BooleanFilter.ALL) {
      myRule.myRules.push({
        field: 'deletedDate',
        operator: filter.active === BooleanFilter.YES ? RuleOperator.EQUAL : RuleOperator.NOT_EQUAL,
        value: null
      });
    }
    if (filter.companiesIds.length) {
      myRule.myRules.push({
        field: 'companyId',
        operator: RuleOperator.IN,
        value: filter.companiesIds
      });
    }
    if (filter.email !== BooleanFilter.ALL) {
      myRule.myRules.push({
        field: 'emailDestinations',
        operator: filter.email === BooleanFilter.YES ? RuleOperator.NOT_EQUAL : RuleOperator.EQUAL,
        value: null
      });
    }

    return queryBuilderService.buildQuery(this.notificationsDataManager, myRule, this.notificationsQuery.clone());
  }
}

const mapStateToProps = (state: RootState): INotificationsListContainerStateProps => ({
  notifications: state.notificationsListNotificationsStore.notifications,
  filter: state.notificationsListStore.filter,
  isFetching: state.notificationsListStore.grid.isFetching,
  searchDate: state.notificationsListStore.grid.searchDate,
  currentPage: state.notificationsListStore.grid.currentPage,
  pageSize: state.notificationsListStore.grid.pageSize,
  totalCount: state.notificationsListStore.grid.totalCount,
  sortField: state.notificationsListStore.grid.sortField,
  sortDirection: state.notificationsListStore.grid.sortDirection
});

const mapDispatchToProps: INotificationsListContainerDispatchProps = {
  reset: notificationsListStore.reset,
  setFilterSubject: notificationsListStore.setFilterSubject,
  setFilterScope: notificationsListStore.setFilterScope,
  setFilterActive: notificationsListStore.setFilterActive,
  setFilterCompaniesIds: notificationsListStore.setFilterCompaniesIds,
  setFilterEmail: notificationsListStore.setFilterEmail,
  setCurrentPage: listGridStore.setCurrentPage,
  setPageSize: listGridStore.setPageSize,
  setSorting: listGridStore.setSorting,
  fetchNotifications: notificationsListNotificationsStore.fetchNotifications,
  prepareDeactivateEntityDialog: dialogsStore.prepareDeactivateEntityDialog
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default compose(
  withRouter,
  withTranslation(),
  connector
)(NotificationsListContainer) as ComponentType;
