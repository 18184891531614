import React, { Component, Fragment } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { DropDownListComponent, ChangeEventArgs as DropDownChangeEventArgs, FieldSettingsModel } from '@syncfusion/ej2-react-dropdowns';
import { SwitchComponent, ChangeEventArgs as ButtonChangeEventArgs } from '@syncfusion/ej2-react-buttons';
import { Query } from '@syncfusion/ej2-data';
import { SortOrder } from '@syncfusion/ej2-navigations';
import { FormValidator, FormEventArgs } from '@syncfusion/ej2-react-inputs';
import { DialogComponent } from '@aitex/tsx-extranet-dialogs';

import { IApplyAdvancedFiltersDialogProps } from '../../../interfaces/props/IDialogsProps';
import { IAdvancedFilter } from '../../../common/model/advancedFilter.model';
import { IAdvancedFilterTranslationBasic } from '../../../common/model/advancedFilterTranslation.model';
import { ICompanyCombo } from '../../../common/model/company.model';
import { IMyRule } from '../../../common/model/myRule.model';
import { IStringToAnyDictionary } from '../../../common/model/stringToAnyDictionary.model';
import { IErrors } from '../../../common/model/errors.model';
import { AdvancedFilterType } from '../../../common/model/enumerations/advancedFilterType.model';
import { ApplyAdvancedFiltersDialogAdditionalAction } from '../../../common/model/enumerations/applyAdvancedFiltersDialogAdditionalAction.model';
import { RuleCondition } from '../../../common/model/enumerations/ruleCondition.model';
import { RuleOperator } from '../../../common/model/enumerations/ruleOperator.model';
import { UserType } from '../../../common/model/enumerations/userType.model';
import { Validator } from '../../../common/model/enumerations/validator.model';
import { advancedFiltersService, queryBuilderService } from '../../../services';
import * as dialogUtils from '../../../utils/dialog.utils';
import * as formValidatorUtils from '../../../utils/formValidator.utils';
import * as advancedFilterUtils from '../../../utils/advancedFilter.utils';
import { enumToArray } from '../../../utils/enum.utils';
import { notifyError } from '../../../utils/toast.utils';
import AdvancedFilterSaveForm from '../../advancedFilterSaveForm/advancedFilterSaveForm';

type ApplyAdvancedFiltersDialogPropsType = IApplyAdvancedFiltersDialogProps & WithTranslation;

type ApplyAdvancedFiltersDialogStateType = IApplyAdvancedFiltersDialogState;

class ApplyAdvancedFiltersDialog extends Component<ApplyAdvancedFiltersDialogPropsType, ApplyAdvancedFiltersDialogStateType> {
  public readonly APPLY_ADVANCED_FILTERS_DIALOG_FORM_ID = 'applyAdvancedFiltersDialogForm';

  public advancedFiltersDropDownListComponent: DropDownListComponent = null;
  public advancedFiltersQuery: Query = new Query().select(['id', 'name']).take(100);
  public advancedFiltersFields: FieldSettingsModel = { text: 'name', value: 'id' };
  public advancedFiltersSortOrder: SortOrder = 'Ascending';

  public additionalActionOptions: IStringToAnyDictionary[] = [];
  public additionalActionFields: FieldSettingsModel = { text: 'text', value: 'value' };

  private readonly CANCEL_BUTTON_ID = 'applyAdvancedFiltersDialogCancel';
  private readonly OK_BUTTON_ID = 'applyAdvancedFiltersDialogOk';

  private readonly CANNOT_SAVE_ADVANCED_FILTER_ERROR: IErrors = { UnknownError: ['dialogs.applyAdvancedFiltersDialog.errors.cannotSave'] };

  private formValidator: FormValidator = null;
  private isValidatingAll = false;
  private auxErrors: IErrors = {};

  private initialState: ApplyAdvancedFiltersDialogStateType = {
    viewOnlyFavourites: false,
    filterByTypeGlobal: false,
    filterByTypeUser: false,
    filterByTypeCompany: false,
    selectedAdvancedFilter: null,
    advancedFilterToSave: null,
    additionalAction: null,
    isLoading: false,
    errors: {}
  };

  public constructor(props: ApplyAdvancedFiltersDialogPropsType) {
    super(props);

    this.state = this.initialState;
  }

  public componentDidUpdate(prevProps: ApplyAdvancedFiltersDialogPropsType): void {
    const { visible } = this.props;

    if (!prevProps.visible && visible) {
      this.computeAdvancedFiltersDropDownListComponentQuery();
    }
    if (prevProps.visible && !visible) {
      this.setState(this.initialState);
      this.resetFormValidator();
    }

    dialogUtils.manageButtonsClick(this.OK_BUTTON_ID, (): Promise<void> => this.applyAdvancedFilters(), this.CANCEL_BUTTON_ID, (): void => this.dismiss());
  }

  public async applyAdvancedFilters(): Promise<void> {
    let advancedFilter = this.state.advancedFilterToSave;

    try {
      if (!this.validateForm() || !this.checkAdvancedFilterIsValid()) {
        return;
      }

      this.setState({ isLoading: true });

      if (!advancedFilter) {
        throw this.CANNOT_SAVE_ADVANCED_FILTER_ERROR;
      }

      if (this.state.additionalAction === ApplyAdvancedFiltersDialogAdditionalAction.SAVE) {
        advancedFilter = await advancedFiltersService.update(advancedFilter);
      } else if (this.state.additionalAction === ApplyAdvancedFiltersDialogAdditionalAction.SAVE_AS) {
        advancedFilter = await advancedFiltersService.create(advancedFilter);
      } else if (this.state.additionalAction === ApplyAdvancedFiltersDialogAdditionalAction.DEACTIVATE) {
        await advancedFiltersService.deactivate(advancedFilter.id);
        advancedFilter.isActive = false;
      }

      this.close(advancedFilter);
    } catch (error) {
      let errors = error as IErrors;
      if (errors.Name) {
        this.setState({ errors });
        return;
      }
      if (errors.UnknownError || (!errors.UnknownError && !errors.ControlledError)) {
        errors = this.CANNOT_SAVE_ADVANCED_FILTER_ERROR;
      }
      this.dismiss(errors);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  public close(advancedFilter: IAdvancedFilter): void {
    this.formValidator = null;
    this.props.onClose(advancedFilter);
  }

  public dismiss(reason?: IErrors): void {
    this.props.onDismiss(reason);
  }

  public configureFormValidator() {
    if (this.props.visible) {
      if (!this.formValidator) {
        this.formValidator = formValidatorUtils.configureFormValidator(this.APPLY_ADVANCED_FILTERS_DIALOG_FORM_ID, {
          Name: { required: [true, Validator.NOT_EMPTY] }
        });
        this.formValidator.validationComplete = (args) => {
          formValidatorUtils.validationComplete(args as FormEventArgs, this.isValidatingAll, this.state.errors, this.auxErrors, (errors): void => this.setState({ errors }));
        };
      }
    } else {
      this.formValidator = null;
    }
  }

  public onViewOnlyFavouritesChange(e: ButtonChangeEventArgs): void {
    this.setState({ viewOnlyFavourites: e.checked }, () => {
      this.computeAdvancedFiltersDropDownListComponentQuery();
    });
  }

  public onFilterByTypeGlobalChange(filterByTypeGlobal: boolean): void {
    this.setState({ filterByTypeGlobal }, () => {
      this.computeAdvancedFiltersDropDownListComponentQuery();
    });
  }

  public onFilterByTypeUserChange(filterByTypeUser: boolean): void {
    this.setState({ filterByTypeUser }, () => {
      this.computeAdvancedFiltersDropDownListComponentQuery();
    });
  }

  public onFilterByTypeCompanyChange(filterByTypeCompany: boolean): void {
    this.setState({ filterByTypeCompany }, () => {
      this.computeAdvancedFiltersDropDownListComponentQuery();
    });
  }

  public async onSelectedAdvancedFilterChange(e: DropDownChangeEventArgs): Promise<void> {
    const { currentUserCompany, i18n } = this.props;

    this.setState({
      ...this.state,
      selectedAdvancedFilter: this.initialState.selectedAdvancedFilter,
      advancedFilterToSave: this.initialState.advancedFilterToSave
    });

    const selectedAdvancedFilterId = e.value as string;
    if (selectedAdvancedFilterId) {
      try {
        this.setState({ isLoading: true });

        const selectedAdvancedFilter = await advancedFiltersService.fetch(selectedAdvancedFilterId);
        if (this.isCurrentUserInternal()) {
          selectedAdvancedFilter.companyName = selectedAdvancedFilter.companyId;
        } else {
          selectedAdvancedFilter.companyName = currentUserCompany.name;
        }

        this.additionalActionOptions = enumToArray(ApplyAdvancedFiltersDialogAdditionalAction, 'number', 'text', 'value')
          .map((item) => ({ ...item, text: i18n.t('enums.applyAdvancedFiltersDialogAdditionalAction.' + item.text) }));

        this.setState({
          selectedAdvancedFilter,
          advancedFilterToSave: selectedAdvancedFilter,
          additionalAction: ApplyAdvancedFiltersDialogAdditionalAction.NONE
        });
      } catch (error) {
        // TODO: manejar error
      } finally {
        this.setState({ isLoading: false });
      }
    }
  }

  public onAdditionalActionChange(e: DropDownChangeEventArgs): void {
    const additionalAction = e.value as number;

    const advancedFilterToSave = this.state.selectedAdvancedFilter;
    if (additionalAction === ApplyAdvancedFiltersDialogAdditionalAction.SAVE_AS) {
      advancedFilterToSave.id = null;
      advancedFilterToSave.isFavourite = false;
    }

    this.setState({ advancedFilterToSave, additionalAction });
  }

  public onTranslationChange(translationBasic: IAdvancedFilterTranslationBasic): void {
    this.setState({ advancedFilterToSave: advancedFilterUtils.upsertTranslation(this.state.advancedFilterToSave, translationBasic) });
  }

  public onAddAdditionalTranslations(languages: string[]): void{
    this.setState({ advancedFilterToSave: advancedFilterUtils.addAdditionalTranslations(this.state.advancedFilterToSave, this.props.i18n.language, languages) });
  }

  public onRemoveAdditionalTranslations(): void {
    this.setState({ advancedFilterToSave: advancedFilterUtils.removeAdditionalTranslations(this.state.advancedFilterToSave, this.props.i18n.language) });
  }

  public onTypeChange(type: number): void {
    this.setState({ advancedFilterToSave: { ...this.state.advancedFilterToSave, type } });

    if (type === AdvancedFilterType.COMPANY) {
      if (!this.isCurrentUserInternal()) {
        this.onCompanyChange(this.props.currentUserCompany);
      }
    } else {
      this.onCompanyChange(null);
    }
  }

  public onCompanyChange(company: ICompanyCombo): void {
    this.setState({ advancedFilterToSave: { ...this.state.advancedFilterToSave, companyId: company && company.id, companyName: company && company.name } });
  }

  public onIsFavouriteChange(isFavourite: boolean): void {
    this.setState({ advancedFilterToSave: { ...this.state.advancedFilterToSave, isFavourite } });
  }

  public isCurrentUserInternal(): boolean {
    return this.props.currentUser.type === UserType.INTERNAL;
  }

  public shouldShowSaveForm(): boolean {
    return this.state.additionalAction === ApplyAdvancedFiltersDialogAdditionalAction.SAVE || this.state.additionalAction === ApplyAdvancedFiltersDialogAdditionalAction.SAVE_AS;
  }

  public render(): JSX.Element {
    const { visible, currentUser, advancedFiltersDataManager, companiesDataManager, i18n } = this.props;

    return (
      <DialogComponent
        header={i18n.t('dialogs.applyAdvancedFiltersDialog.title')}
        visible={visible}
        footerTemplate={dialogUtils.computeFooterTemplate(
          this.OK_BUTTON_ID,
          i18n.t('actions.ok'),
          this.CANCEL_BUTTON_ID,
          i18n.t('actions.cancel'),
          this.state.isLoading,
          this.isOkButtonDisabled()
        )}
        width='480px'
        onDismiss={(): void => this.dismiss()}
      >
        <div>
          {currentUser && <div>
            <div className='row'>
              <div className='col'>
                <div className='form-group d-flex' style={{ marginTop: '7px' }}>
                  <label htmlFor='viewOnlyFavourites' className='mr-2'>{i18n.t('advancedFilterComponent.actions.viewOnlyFavourites')}</label>
                  <div style={{ marginTop: '0.15rem' }}>
                    <SwitchComponent
                      id='viewOnlyFavourites'
                      checked={this.state.viewOnlyFavourites}
                      change={(e): void => this.onViewOnlyFavouritesChange(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='row justify-content-around'>
              {this.isCurrentUserInternal() && <div className='col-auto'>
                <div className='form-group'>
                  <div className='form-check'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      checked={this.state.filterByTypeGlobal}
                      id='filterByTypeGlobal'
                      onChange={(event): void => this.onFilterByTypeGlobalChange(event.target.checked)}
                    />
                    <label className='form-check-label' htmlFor='filterByTypeGlobal'>
                      {advancedFilterUtils.getTypeLocalizedTextByValue(i18n, AdvancedFilterType.GLOBAL, this.isCurrentUserInternal())}
                    </label>
                  </div>
                </div>
              </div>}
              <div className='col-auto'>
                <div className='form-group'>
                  <div className='form-check'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      checked={this.state.filterByTypeUser}
                      id='filterByTypeUser'
                      onChange={(event): void => this.onFilterByTypeUserChange(event.target.checked)}
                    />
                    <label className='form-check-label' htmlFor='filterByTypeUser'>
                      {advancedFilterUtils.getTypeLocalizedTextByValue(i18n, AdvancedFilterType.USER, this.isCurrentUserInternal())}
                    </label>
                  </div>
                </div>
              </div>
              <div className='col-auto'>
                <div className='form-group'>
                  <div className='form-check'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      checked={this.state.filterByTypeCompany}
                      id='filterByTypeCompany'
                      onChange={(event): void => this.onFilterByTypeCompanyChange(event.target.checked)}
                    />
                    <label className='form-check-label' htmlFor='filterByTypeCompany'>
                      {advancedFilterUtils.getTypeLocalizedTextByValue(i18n, AdvancedFilterType.COMPANY, this.isCurrentUserInternal())}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col'>
                <div className='form-group'>
                  <div className='autocomplete-input'>
                    <div className='autocomplete-label'>{i18n.t('dialogs.applyAdvancedFiltersDialog.selectFilter')}</div>
                    <DropDownListComponent
                      query={this.advancedFiltersQuery}
                      dataSource={advancedFiltersDataManager}
                      fields={this.advancedFiltersFields}
                      sortOrder={this.advancedFiltersSortOrder}
                      ref={(dropDownListComponent: DropDownListComponent): DropDownListComponent => this.advancedFiltersDropDownListComponent = dropDownListComponent}
                      change={(e): Promise<void> => this.onSelectedAdvancedFilterChange(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
            {this.state.advancedFilterToSave && <Fragment>
              <hr />
              <div className='row'>
                <div className='col'>
                  <div className='form-group'>
                    <div className='autocomplete-input'>
                      <div className='autocomplete-label'>{i18n.t('dialogs.applyAdvancedFiltersDialog.additionalAction')}</div>
                      <DropDownListComponent
                        dataSource={this.additionalActionOptions}
                        fields={this.additionalActionFields}
                        value={this.state.additionalAction}
                        change={(e): void => this.onAdditionalActionChange(e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {this.shouldShowSaveForm() && <AdvancedFilterSaveForm
                advancedFilter={this.state.advancedFilterToSave}
                isCurrentUserInternal={this.isCurrentUserInternal()}
                currentLanguage={i18n.language}
                formId={this.APPLY_ADVANCED_FILTERS_DIALOG_FORM_ID}
                companiesDataManager={companiesDataManager}
                errors={this.state.errors}
                onFormRef={() => this.configureFormValidator()}
                onTranslationChange={(translationBasic): void => this.onTranslationChange(translationBasic)}
                onAddAdditionalTranslations={(languages): void => this.onAddAdditionalTranslations(languages)}
                onRemoveAdditionalTranslations={(): void => this.onRemoveAdditionalTranslations()}
                onTypeChange={(type): void => this.onTypeChange(type)}
                onCompanyChange={(company): void => this.onCompanyChange(company)}
                onIsFavouriteChange={(isFavourite): void => this.onIsFavouriteChange(isFavourite)}
              />}
            </Fragment>}
          </div>}
        </div>
      </DialogComponent>
    );
  }

  private computeAdvancedFiltersDropDownListComponentQuery(): void {
    if (this.advancedFiltersDropDownListComponent) {
      let query = this.advancedFiltersQuery.clone();
      const myRule: IMyRule = {
        condition: RuleCondition.AND,
        myRules: [{
          field: 'shareId',
          operator: RuleOperator.EQUAL,
          value: null
        }]
      };
      if (this.state.viewOnlyFavourites) {
        myRule.myRules.push({
          field: 'isFavourite',
          operator: RuleOperator.EQUAL,
          value: true
        });
      }
      const typeRule: IMyRule = {
        field: 'type',
        operator: RuleOperator.IN,
        value: []
      };
      if (this.state.filterByTypeGlobal) {
        (typeRule.value as number[]).push(AdvancedFilterType.GLOBAL);
      }
      if (this.state.filterByTypeUser) {
        (typeRule.value as number[]).push(AdvancedFilterType.USER);
      }
      if (this.state.filterByTypeCompany) {
        (typeRule.value as number[]).push(AdvancedFilterType.COMPANY);
      }
      myRule.myRules.push(typeRule);
      query = queryBuilderService.buildQuery(this.props.advancedFiltersDataManager, myRule, query);

      this.advancedFiltersDropDownListComponent.value = null;
      this.advancedFiltersDropDownListComponent.query = query;
    }
  }

  private isOkButtonDisabled(): boolean {
    return !!this.state.errors.Name || this.state.isLoading;
  }

  private resetFormValidator(): void {
    if (!this.formValidator) {
      return;
    }

    if (this.formValidator.getInputElement('Name')) {
      this.formValidator.getInputElement('Name').parentElement.className = this.formValidator.getInputElement('Name').parentElement.className.replace('e-error', '');
    }
    this.formValidator.removeRules();
    this.formValidator.validationComplete = undefined;
  }

  private validateForm(): boolean {
    if (!this.formValidator) {
      return true;
    }

    this.isValidatingAll = true;
    const isFormValid = this.formValidator.validate();
    this.isValidatingAll = false;
    if (!isFormValid) {
      this.setState({ errors: this.auxErrors });
      this.auxErrors = {};
    }
    return isFormValid;
  }

  private checkAdvancedFilterIsValid(): boolean {
    if (!this.state.advancedFilterToSave.translations.every((translation) => {
      return translation.name ? translation.name.trim().length > 0 : false;
    })) {
      notifyError(this.props.i18n.t('dialogs.applyAdvancedFiltersDialog.errors.emptyTranslation'));
      return false;
    }

    return true;
  }
}

interface IApplyAdvancedFiltersDialogState {
  viewOnlyFavourites: boolean;
  filterByTypeGlobal: boolean;
  filterByTypeUser: boolean;
  filterByTypeCompany: boolean;
  selectedAdvancedFilter: IAdvancedFilter;
  advancedFilterToSave: IAdvancedFilter;
  additionalAction: number;
  isLoading: boolean;
  errors: IErrors;
}

export default withTranslation()(ApplyAdvancedFiltersDialog);
