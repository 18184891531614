import React, { Component } from 'react';
import { ICustomIFrameProps } from '../../interfaces/props/ICustomIFrameProps';

class CustomIFrame extends Component<ICustomIFrameProps> {
  public render(): JSX.Element {
    const { src, width, height, title, sandbox, frameBorder, style, allow } = this.props;
    const param = this.props.myParam;

    let newSrc = src;
    if (param !== undefined && param != null && param.trim() != '' && param.trim().length > 1) {
      const questionSymbolIndex = src.indexOf('?');
      const initialUrl = src.substring(0, questionSymbolIndex - 1);
      const suffixUrl = src.substring(questionSymbolIndex);

      newSrc = initialUrl + param + suffixUrl;
    }

    return (
      <div>
        <iframe src={newSrc} width={width} height={height} title={title} sandbox={sandbox} frameBorder={frameBorder} style={style} allow={(allow !== undefined && allow != null ? allow : '')}></iframe>
      </div>
    );
  }
}

export default CustomIFrame;
