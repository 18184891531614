import { AxiosRequestConfig } from 'axios';
import moment from 'moment';

import store from '../store';
import { URL } from '../common/constants';
import { INotification, INotificationWithViewInfo } from '../common/model/notification.model';
import { INotificationClassification } from '../common/model/notificationClassification.model';
import * as services from '.';

export interface INotificationsService {
  fetch(id: string): Promise<INotification>;
  fetchMine(viewAll: boolean): Promise<INotificationWithViewInfo[]>;
  getPostUrl(): string;
  create(notification: INotification): Promise<INotification>;
  markAsSeen(id: any): Promise<void>;
  deactivate(id: string): Promise<void>;
  fetchNotificationClassifications(): Promise<INotificationClassification[]>;
  downloadFile(notificationId: string, fileName: string): Promise<void>;
}

class NotificationsService implements INotificationsService {
  public constructor(private httpClient: services.IHttpClient) {

  }

  public async fetch(id: string): Promise<INotification> {
    const notification = await this.httpClient.getCustom<INotification>(`${URL.WEB.BASE}${URL.WEB.NOTIFICATIONS}/${id}`);
    return { ...notification, createdDate: moment(notification.createdDate) };
  }

  public async fetchMine(viewAll: boolean): Promise<INotificationWithViewInfo[]> {
    const config: AxiosRequestConfig = {
      params: {
        top: 100
      }
    };
    if (!viewAll) {
      config.params.seen = false;
    }

    try {
      return await this.httpClient.getCustom<INotificationWithViewInfo[]>(`${URL.WEB.BASE}${URL.WEB.NOTIFICATIONS}`, config).then((notifications) => (
        notifications.map((notification) => ({ ...notification, createdDate: moment(notification.createdDate) }))
      ));
    } catch (error) {
      return [];
    }
  }

  public getPostUrl(): string {
    return `${store.getState().settingsStore.settings.api}${URL.WEB.BASE}${URL.WEB.NOTIFICATIONS}`;
  }

  public create(notification: INotification): Promise<INotification> {
    return this.httpClient.postCustom(this.getPostUrl(), notification);
  }

  public markAsSeen(id: any): Promise<void> {
    return this.httpClient.postCustom(`${URL.WEB.BASE}${URL.WEB.NOTIFICATIONS}/${id}/seen`);
  }

  public deactivate(id: string): Promise<void> {
    return this.httpClient.deleteCustom(`${URL.WEB.BASE}${URL.WEB.NOTIFICATIONS}/${id}`);
  }

  public async fetchNotificationClassifications(): Promise<INotificationClassification[]> {
    try {
      return await this.httpClient.getCustom<INotificationClassification[]>(`${URL.WEB.BASE}${URL.WEB.NOTIFICATION_CLASSIFICATIONS}`);
    } catch (error) {
      return [];
    }
  }

  public downloadFile(notificationId: string, fileName: string): Promise<void> {
    return this.httpClient.downloadFile(`${URL.WEB.BASE}${URL.WEB.NOTIFICATIONS}/${notificationId}/file`, fileName);
  }

  public getExcelEmailVerifyUrl(): string {
    return `${store.getState().settingsStore.settings.api}${URL.WEB.BASE}${URL.WEB.NOTIFICATIONS}/bulkVerify`;
  }
}

export const notificationsService = new NotificationsService(services.httpClient);
