import store from '../store';
import { URL } from '../common/constants';
import { IAnnex } from '../common/model/annex.model';
import { IAnnexedCategory } from '../common/model/annexedCategory.model';
import * as services from '.';

export interface IAnnexesService {
  updateDescription(id: string, description: string): Promise<IAnnex>;
  updateState(id: string, state: number, observations: string): Promise<IAnnex>;
  downloadFile(annexId: string, annexDescription: string, annexAnnexedCategoryValue?: string): Promise<void>;
  deactivate(id: string): Promise<void>;
  getUploadUrl(): string;
  fetchAnnexedCategories(): Promise<IAnnexedCategory[]>;
}

class AnnexesService implements IAnnexesService {
  public constructor(private httpClient: services.IHttpClient) {

  }

  public updateDescription(id: string, description: string): Promise<IAnnex> {
    return this.httpClient.patchCustom(`${URL.WEB.BASE}${URL.WEB.ANNEXES}/${id}`, { id, description }, { headers: { Accept: 'application/description+json' } });
  }

  public updateState(id: string, state: number, observations: string): Promise<IAnnex> {
    return this.httpClient.patchCustom(`${URL.WEB.BASE}${URL.WEB.ANNEXES}/${id}`, { id, state, observations }, { headers: { Accept: 'application/state+json' } });
  }

  public downloadFile(annexId: string, annexDescription: string, annexAnnexedCategoryValue?: string): Promise<void> {
    const filenameWithoutExtension = `${annexDescription}${annexAnnexedCategoryValue ? `_${annexAnnexedCategoryValue}` : ''}`;
    return this.httpClient.downloadFile(`${URL.WEB.BASE}${URL.WEB.ANNEXES}/${annexId}/file`, filenameWithoutExtension);
  }

  public deactivate(id: string): Promise<void> {
    return this.httpClient.deleteCustom(`${URL.WEB.BASE}${URL.WEB.ANNEXES}/${id}`);
  }

  public getUploadUrl(): string {
    return `${store.getState().settingsStore.settings.api}${URL.WEB.BASE}${URL.WEB.ANNEXES}/upload`;
  }

  public async fetchAnnexedCategories(): Promise<IAnnexedCategory[]> {
    try {
      return await this.httpClient.getCustom<IAnnexedCategory[]>(`${URL.WEB.BASE}${URL.WEB.ANNEXED_CATEGORIES}`);
    } catch (error) {
      return [];
    }
  }
}

export const annexesService = new AnnexesService(services.httpClient);
