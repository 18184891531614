import { Action, Reducer, ActionCreator, combineReducers } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { batch } from 'react-redux';
import { DataManager, Query } from '@syncfusion/ej2-data';
import moment from 'moment';

import { RootState } from '..';
import * as listGridStore from './listGrid.store';
import { IRequestsListState, IRequestsListFilterState } from '../../interfaces/states/IRequestsListState';
import { IRequestWithFlattenedCustomFields } from '../../common/model/request.model';
import { IRequestCustomFieldSettingsWithCheckboxes } from '../../common/model/customFieldSettings.model';
import { ICustomFieldOptions } from '../../common/model/customFieldOptions.model';
import { RequestStatus } from '../../common/model/enumerations/requestStatus.model';
import { IODataService, ICompaniesService, IRequestsService } from '../../services';
import * as requestUtils from '../../utils/request.utils';
import * as requestCustomFieldSettingsUtils from '../../utils/requestCustomFieldSettings.utils';

export enum Type {
  RESET = '@@requestsList/RESET',
  RESET_HARD = '@@requestsList/RESET_HARD',
  SET_REQUESTS = '@@requestsList/SET_REQUESTS',
  SET_MODE = '@@requestsList/SET_MODE',
  SET_IS_CHANGING_MODE = '@@requestsList/SET_IS_CHANGING_MODE',
  SET_FILTER = '@@requestsList/SET_FILTER',
  SET_FILTER_SUBJECT = '@@requestsList/SET_FILTER_SUBJECT',
  SET_FILTER_CODE = '@@requestsList/SET_FILTER_CODE',
  SET_FILTER_STATUS = '@@requestsList/SET_FILTER_STATUS',
  SET_FILTER_COMPANIES_IDS = '@@requestsList/SET_FILTER_COMPANIES_IDS',
  SET_FILTER_APPLICANTS_IDS = '@@requestsList/SET_FILTER_APPLICANTS_IDS',
  SET_FILTER_CREATED_DATE = '@@requestsList/SET_FILTER_CREATED_DATE',
  SET_FILTER_REFERENCE_NAMES = '@@requestsList/SET_FILTER_REFERENCE_NAMES',
  SET_FILTER_ECI_REQUEST = '@@requestsList/SET_FILTER_ECI_REQUEST',
  SET_FILTER_BIDDER_ID = '@@requestsList/SET_FILTER_BIDDER_ID',
  SET_FILTER_BIDDER_NAME = '@@requestsList/SET_FILTER_BIDDER_NAME',
  SET_FILTER_CENTER_ID = '@@requestsList/SET_FILTER_CENTER_ID',
  SET_FILTER_CENTER_NAME = '@@requestsList/SET_FILTER_CENTER_NAME',
  SET_FILTER_UNECO_ID = '@@requestsList/SET_FILTER_UNECO_ID',
  SET_FILTER_UNECO_NAME = '@@requestsList/SET_FILTER_UNECO_NAME',
  SET_FILTER_ORDER = '@@requestsList/SET_FILTER_ORDER',
  SET_REQUEST_CUSTOM_FIELD_SETTINGS = '@@requestsList/SET_REQUEST_CUSTOM_FIELD_SETTINGS',
  SET_ARE_REQUEST_CUSTOM_FIELD_SETTINGS_LOADED = '@@requestsList/SET_ARE_REQUEST_CUSTOM_FIELD_SETTINGS_LOADED',
  SET_BIDDER_OPTIONS = '@@requestsList/SET_BIDDER_OPTIONS',
  SET_CENTER_OPTIONS = '@@requestsList/SET_CENTER_OPTIONS',
  SET_UNECO_OPTIONS = '@@requestsList/SET_UNECO_OPTIONS',
  SET_IS_FETCHING = '@@requestsList/SET_IS_FETCHING',
  SET_SEARCH_DATE = '@@requestsList/SET_SEARCH_DATE',
  SET_CURRENT_PAGE = '@@requestsList/SET_CURRENT_PAGE',
  SET_PAGE_SIZE = '@@requestsList/SET_PAGE_SIZE',
  SET_TOTAL_COUNT = '@@requestsList/SET_TOTAL_COUNT',
  SET_SORTING = '@@requestsList/SET_SORTING',
  SET_FILTER_MANAGER_GROUPS_IDS = '@@requestsList/SET_FILTER_MANAGER_GROUPS_IDS',
  SET_FILTER_MANAGER_USERS_IDS = '@@requestsList/SET_FILTER_MANAGER_USERS_IDS',
  SET_FILTER_DELEGATE_MANAGER_USERS_IDS = '@@requestsList/SET_FILTER_DELEGATE_MANAGER_USERS_IDS'
}

const initialState: IRequestsListState = {
  requests: [],
  mode: null,
  isChangingMode: true,
  filter: {
    subject: null,
    code: null,
    status: RequestStatus.ALL,
    companiesIds: [],
    applicantsIds: [],
    createdDate: [],
    referenceNames: null,
    eciRequest: null,
    bidderId: null,
    bidderName: null,
    centerId: null,
    centerName: null,
    unecoId: null,
    unecoName: null,
    order: null,
    managerGroupsIds: [],
    managerEmailUsersIds: [],
    delegateUserManagersIds: []
  },
  requestCustomFieldSettings: [],
  areRequestCustomFieldSettingsLoaded: false,
  bidderOptions: [],
  centerOptions: [],
  unecoOptions: [],
  grid: listGridStore.initialState
};

// Actions

export type Actions = ResetAction | ResetHardAction | SetRequestsAction | SetModeAction | SetIsChangingModeAction | SetFilterAction | SetFilterSubjectAction | SetFilterCodeAction | SetFilterStatusAction
  | SetFilterCompaniesIdsAction | SetFilterApplicantsIdsAction | SetFilterCreatedDateAction | SetFilterReferenceNamesAction | SetFilterEciRequestAction | SetFilterBidderIdAction
  | SetFilterCenterIdAction | SetFilterUnecoIdAction | SetRequestCustomFieldSettingsAction | SetAreRequestCustomFieldSettingsLoadedAction | SetBidderOptionsAction | SetCenterOptionsAction
  | SetUnecoOptionsAction | listGridStore.SetIsFetchingAction | listGridStore.SetSearchDateAction | listGridStore.SetCurrentPageAction | listGridStore.SetPageSizeAction
  | listGridStore.SetTotalCountAction | listGridStore.SetSortingAction | SetFilterManagerGroupsIdsAction | SetFilterManagerEmailUsersIdsAction | SetFilterDelegateManagerUsersIdsAction
  | SetFilterBidderNameAction | SetFilterCenterNameAction | SetFilterUnecoNameAction | SetFilterOrderAction;

export type ResetAction = Action<Type.RESET>

export type ResetHardAction = Action<Type.RESET_HARD>

export interface SetRequestsAction extends Action<Type.SET_REQUESTS> {
  requests: IRequestWithFlattenedCustomFields[];
}

export interface SetModeAction extends Action<Type.SET_MODE> {
  mode: string;
}

export interface SetIsChangingModeAction extends Action<Type.SET_IS_CHANGING_MODE> {
  isChangingMode: boolean;
}

export interface SetFilterAction extends Action<Type.SET_FILTER> {
  filter: IRequestsListFilterState;
}

export interface SetFilterSubjectAction extends Action<Type.SET_FILTER_SUBJECT> {
  subject: string;
}

export interface SetFilterCodeAction extends Action<Type.SET_FILTER_CODE> {
  code: string;
}

export interface SetFilterStatusAction extends Action<Type.SET_FILTER_STATUS> {
  status: string;
}

export interface SetFilterCompaniesIdsAction extends Action<Type.SET_FILTER_COMPANIES_IDS> {
  companiesIds: string[];
}

export interface SetFilterApplicantsIdsAction extends Action<Type.SET_FILTER_APPLICANTS_IDS> {
  applicantsIds: string[];
}

export interface SetFilterCreatedDateAction extends Action<Type.SET_FILTER_CREATED_DATE> {
  createdDate: Date[];
}

export interface SetFilterReferenceNamesAction extends Action<Type.SET_FILTER_REFERENCE_NAMES> {
  referenceNames: string;
}

export interface SetFilterEciRequestAction extends Action<Type.SET_FILTER_ECI_REQUEST> {
  eciRequest: string;
}

export interface SetFilterBidderIdAction extends Action<Type.SET_FILTER_BIDDER_ID> {
  bidderId: string;
}

export interface SetFilterBidderNameAction extends Action<Type.SET_FILTER_BIDDER_NAME> {
  bidderName: string;
}

export interface SetFilterCenterIdAction extends Action<Type.SET_FILTER_CENTER_ID> {
  centerId: string;
}

export interface SetFilterCenterNameAction extends Action<Type.SET_FILTER_CENTER_NAME> {
  centerName: string;
}

export interface SetFilterUnecoIdAction extends Action<Type.SET_FILTER_UNECO_ID> {
  unecoId: string;
}

export interface SetFilterUnecoNameAction extends Action<Type.SET_FILTER_UNECO_NAME> {
  unecoName: string;
}

export interface SetFilterOrderAction extends Action<Type.SET_FILTER_ORDER> {
  order: string;
}

export interface SetFilterManagerGroupsIdsAction extends Action<Type.SET_FILTER_MANAGER_GROUPS_IDS> {
  managerGroupsIds: string[];
}

export interface SetFilterManagerEmailUsersIdsAction extends Action<Type.SET_FILTER_MANAGER_USERS_IDS> {
  managerEmailUsersIds: string[];
}

export interface SetFilterDelegateManagerUsersIdsAction extends Action<Type.SET_FILTER_DELEGATE_MANAGER_USERS_IDS> {
  delegateUserManagersIds: string[];
}

export interface SetRequestCustomFieldSettingsAction extends Action<Type.SET_REQUEST_CUSTOM_FIELD_SETTINGS> {
  requestCustomFieldSettings: IRequestCustomFieldSettingsWithCheckboxes[];
}

export interface SetAreRequestCustomFieldSettingsLoadedAction extends Action<Type.SET_ARE_REQUEST_CUSTOM_FIELD_SETTINGS_LOADED> {
  areRequestCustomFieldSettingsLoaded: boolean;
}

export interface SetBidderOptionsAction extends Action<Type.SET_BIDDER_OPTIONS> {
  bidderOptions: ICustomFieldOptions[];
}

export interface SetCenterOptionsAction extends Action<Type.SET_CENTER_OPTIONS> {
  centerOptions: ICustomFieldOptions[];
}

export interface SetUnecoOptionsAction extends Action<Type.SET_UNECO_OPTIONS> {
  unecoOptions: ICustomFieldOptions[];
}

export const reset: ActionCreator<ResetAction> = () => ({
  type: Type.RESET
});

export const resetHard: ActionCreator<ResetHardAction> = () => ({
  type: Type.RESET_HARD
});

export const setRequests: ActionCreator<SetRequestsAction> = (requestsLocal: IRequestWithFlattenedCustomFields[]) => ({
  type: Type.SET_REQUESTS,
  requests: requestsLocal
});

export const setMode: ActionCreator<SetModeAction> = (modeLocal: string) => ({
  type: Type.SET_MODE,
  mode: modeLocal
});

export const setIsChangingMode: ActionCreator<SetIsChangingModeAction> = (isChanginModeLocal: boolean) => ({
  type: Type.SET_IS_CHANGING_MODE,
  isChangingMode: isChanginModeLocal
});

export const setFilter: ActionCreator<SetFilterAction> = (filterLocal: IRequestsListFilterState) => ({
  type: Type.SET_FILTER,
  filter: filterLocal
});

export const setFilterSubject: ActionCreator<SetFilterSubjectAction> = (subject: string) => ({
  type: Type.SET_FILTER_SUBJECT,
  subject
});

export const setFilterCode: ActionCreator<SetFilterCodeAction> = (code: string) => ({
  type: Type.SET_FILTER_CODE,
  code
});

export const setFilterStatus: ActionCreator<SetFilterStatusAction> = (status: string) => ({
  type: Type.SET_FILTER_STATUS,
  status
});

export const setFilterCompaniesIds: ActionCreator<SetFilterCompaniesIdsAction> = (companiesIds: string[]) => ({
  type: Type.SET_FILTER_COMPANIES_IDS,
  companiesIds
});

export const setFilterApplicantsIds: ActionCreator<SetFilterApplicantsIdsAction> = (applicantsIds: string[]) => ({
  type: Type.SET_FILTER_APPLICANTS_IDS,
  applicantsIds
});

export const setFilterCreatedDate: ActionCreator<SetFilterCreatedDateAction> = (createdDate: Date[]) => ({
  type: Type.SET_FILTER_CREATED_DATE,
  createdDate
});

export const setFilterReferenceNames: ActionCreator<SetFilterReferenceNamesAction> = (referenceNames: string) => ({
  type: Type.SET_FILTER_REFERENCE_NAMES,
  referenceNames
});

export const setFilterEciRequest: ActionCreator<SetFilterEciRequestAction> = (eciRequest: string) => ({
  type: Type.SET_FILTER_ECI_REQUEST,
  eciRequest
});

export const setFilterBidderId: ActionCreator<SetFilterBidderIdAction> = (bidderId: string) => ({
  type: Type.SET_FILTER_BIDDER_ID,
  bidderId
});

export const setFilterBidderName: ActionCreator<SetFilterBidderNameAction> = (bidderName: string) => ({
  type: Type.SET_FILTER_BIDDER_NAME,
  bidderName
});

export const setFilterCenterId: ActionCreator<SetFilterCenterIdAction> = (centerId: string) => ({
  type: Type.SET_FILTER_CENTER_ID,
  centerId
});

export const setFilterCenterName: ActionCreator<SetFilterCenterNameAction> = (centerName: string) => ({
  type: Type.SET_FILTER_CENTER_NAME,
  centerName
});

export const setFilterUnecoId: ActionCreator<SetFilterUnecoIdAction> = (unecoId: string) => ({
  type: Type.SET_FILTER_UNECO_ID,
  unecoId
});

export const setFilterUnecoName: ActionCreator<SetFilterUnecoNameAction> = (unecoName: string) => ({
  type: Type.SET_FILTER_UNECO_NAME,
  unecoName
});

export const setFilterOrder: ActionCreator<SetFilterOrderAction> = (order: string) => ({
  type: Type.SET_FILTER_ORDER,
  order
});

export const setFilterManagerGroupsIds: ActionCreator<SetFilterManagerGroupsIdsAction> = (managerGroupsIds: string[]) => ({
  type: Type.SET_FILTER_MANAGER_GROUPS_IDS,
  managerGroupsIds
});

export const setFilterManagerEmailUsersIds: ActionCreator<SetFilterManagerEmailUsersIdsAction> = (managerEmailUsersIds: string[]) => ({
  type: Type.SET_FILTER_MANAGER_USERS_IDS,
  managerEmailUsersIds
});

export const setFilterDelegateManagerUsersIds: ActionCreator<SetFilterDelegateManagerUsersIdsAction> = (delegateUserManagersIds: string[]) => ({
  type: Type.SET_FILTER_DELEGATE_MANAGER_USERS_IDS,
  delegateUserManagersIds
});

export const setRequestCustomFieldSettings: ActionCreator<SetRequestCustomFieldSettingsAction> = (requestCustomFieldSettingsLocal: IRequestCustomFieldSettingsWithCheckboxes[]) => ({
  type: Type.SET_REQUEST_CUSTOM_FIELD_SETTINGS,
  requestCustomFieldSettings: requestCustomFieldSettingsLocal
});

export const setAreRequestCustomFieldSettingsLoaded: ActionCreator<SetAreRequestCustomFieldSettingsLoadedAction> = (areRequestCustomFieldSettingsLoadedLocal: boolean) => ({
  type: Type.SET_ARE_REQUEST_CUSTOM_FIELD_SETTINGS_LOADED,
  areRequestCustomFieldSettingsLoaded: areRequestCustomFieldSettingsLoadedLocal
});

export const setBidderOptions: ActionCreator<SetBidderOptionsAction> = (bidderOptionsLocal: ICustomFieldOptions[]) => ({
  type: Type.SET_BIDDER_OPTIONS,
  bidderOptions: bidderOptionsLocal
});

export const setCenterOptions: ActionCreator<SetCenterOptionsAction> = (centerOptionsLocal: ICustomFieldOptions[]) => ({
  type: Type.SET_CENTER_OPTIONS,
  centerOptions: centerOptionsLocal
});

export const setUnecoOptions: ActionCreator<SetUnecoOptionsAction> = (unecoOptionsLocal: ICustomFieldOptions[]) => ({
  type: Type.SET_UNECO_OPTIONS,
  unecoOptions: unecoOptionsLocal
});

export const fetchRequests: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (
  oDataService: IODataService,
  requestsDataManager: DataManager,
  requestsQuery: Query
) => (
    async (dispatch, getState) => {
      dispatch(listGridStore.setIsFetching(Type.SET_IS_FETCHING, true));

      const currentPageLocal = getState().requestsListStore.grid.currentPage;
      const pageSizeLocal = getState().requestsListStore.grid.pageSize;
      const sortFieldLocal = getState().requestsListStore.grid.sortField;
      const sortDirectionLocal = getState().requestsListStore.grid.sortDirection;

      let query = requestsQuery.page(currentPageLocal, pageSizeLocal);
      if (sortFieldLocal && sortDirectionLocal) {
        query = sortDirectionLocal === 'Ascending' ? query.sortBy(sortFieldLocal) : query.sortByDesc(sortFieldLocal);
      }
      const requestsResponse = await oDataService.executeQueryWithCount<IRequestWithFlattenedCustomFields>(requestsDataManager, query);
      const requestsLocal = requestsResponse.result.map((request) => requestUtils.initDatesFlattened(request));
      batch(() => {
        dispatch(setRequests(requestsLocal));
        dispatch(listGridStore.setSearchDate(Type.SET_SEARCH_DATE, moment()));
        dispatch(listGridStore.setTotalCount(Type.SET_TOTAL_COUNT, requestsResponse.count));
      });
      dispatch(listGridStore.setIsFetching(Type.SET_IS_FETCHING, false));
    }
  );

export const fetchRequestCustomFieldSettings: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (companiesService: ICompaniesService, requestsService: IRequestsService) => (
  async (dispatch, getState) => {
    batch(() => {
      dispatch(setRequestCustomFieldSettings(initialState.requestCustomFieldSettings));
      dispatch(setBidderOptions(initialState.bidderOptions));
      dispatch(setCenterOptions(initialState.centerOptions));
      dispatch(setUnecoOptions(initialState.unecoOptions));
    });

    const companyId = getState().currentUserStore.jwtInfo.companyId;

    if (companyId) {
      batch(() => {
        dispatch(listGridStore.setIsFetching(Type.SET_IS_FETCHING, true));
        dispatch(setAreRequestCustomFieldSettingsLoaded(initialState.areRequestCustomFieldSettingsLoaded));
      });

      const requestCustomFieldSettingsLocal = (await companiesService.fetchRequestCustomFieldSettings(companyId))
        .map((s) => requestCustomFieldSettingsUtils.toWithCheckboxes(s));
      batch(() => {
        dispatch(setRequestCustomFieldSettings(requestCustomFieldSettingsLocal));
        dispatch(setAreRequestCustomFieldSettingsLoaded(true));
        dispatch(listGridStore.setIsFetching(Type.SET_IS_FETCHING, false));
      });

      dispatch(setBidderOptions(await requestsService.fetchCustomFieldOptions('bidderId')));
      dispatch(setCenterOptions(await requestsService.fetchCustomFieldOptions('centerId')));
      dispatch(setUnecoOptions(await requestsService.fetchCustomFieldOptions('unecoId')));
    } else {
      dispatch(setAreRequestCustomFieldSettingsLoaded(true));
    }
  }
);

// Reducers

const requests: Reducer<IRequestWithFlattenedCustomFields[]> = (state = initialState.requests, action) => {
  switch (action.type) {
    case Type.RESET:
      return state;
    case Type.SET_MODE:
      return initialState.requests;
    case Type.SET_REQUESTS:
      return (action as SetRequestsAction).requests;
    default:
      return state;
  }
};

const mode: Reducer<string> = (state = initialState.mode, action) => {
  switch (action.type) {
    case Type.RESET:
      return state;
    case Type.RESET_HARD:
      return initialState.mode;
    case Type.SET_MODE:
      return (action as SetModeAction).mode;
    default:
      return state;
  }
};

const isChangingMode: Reducer<boolean> = (state = initialState.isChangingMode, action) => {
  switch (action.type) {
    case Type.RESET:
    case Type.RESET_HARD:
      return initialState.isChangingMode;
    case Type.SET_MODE:
      return false;
    case Type.SET_IS_CHANGING_MODE:
      return (action as SetIsChangingModeAction).isChangingMode;
    default:
      return state;
  }
}

const filter: Reducer<IRequestsListFilterState> = (state = initialState.filter, action) => {
  switch (action.type) {
    case Type.RESET:
      return state;
    case Type.SET_MODE:
      return { ...state, status: (action as SetModeAction).mode };
    case Type.SET_FILTER:
      return (action as SetFilterAction).filter;
    case Type.SET_FILTER_SUBJECT:
      return { ...state, subject: (action as SetFilterSubjectAction).subject };
    case Type.SET_FILTER_CODE:
      return { ...state, code: (action as SetFilterCodeAction).code };
    case Type.SET_FILTER_STATUS:
      return { ...state, status: (action as SetFilterStatusAction).status };
    case Type.SET_FILTER_COMPANIES_IDS:
      return { ...state, companiesIds: (action as SetFilterCompaniesIdsAction).companiesIds };
    case Type.SET_FILTER_APPLICANTS_IDS:
      return { ...state, applicantsIds: (action as SetFilterApplicantsIdsAction).applicantsIds };
    case Type.SET_FILTER_CREATED_DATE:
      return { ...state, createdDate: (action as SetFilterCreatedDateAction).createdDate };
    case Type.SET_FILTER_REFERENCE_NAMES:
      return { ...state, referenceNames: (action as SetFilterReferenceNamesAction).referenceNames };
    case Type.SET_FILTER_ECI_REQUEST:
      return { ...state, eciRequest: (action as SetFilterEciRequestAction).eciRequest };
    case Type.SET_FILTER_BIDDER_ID:
      return { ...state, bidderId: (action as SetFilterBidderIdAction).bidderId };
    case Type.SET_FILTER_BIDDER_NAME:
      return { ...state, bidderName: (action as SetFilterBidderNameAction).bidderName };
    case Type.SET_FILTER_CENTER_ID:
      return { ...state, centerId: (action as SetFilterCenterIdAction).centerId };
    case Type.SET_FILTER_CENTER_NAME:
      return { ...state, centerName: (action as SetFilterCenterNameAction).centerName };
    case Type.SET_FILTER_ORDER:
      return { ...state, order: (action as SetFilterOrderAction).order };
    case Type.SET_FILTER_UNECO_ID:
      return { ...state, unecoId: (action as SetFilterUnecoIdAction).unecoId };
    case Type.SET_FILTER_UNECO_NAME:
      return { ...state, unecoName: (action as SetFilterUnecoNameAction).unecoName };
    case Type.SET_FILTER_MANAGER_GROUPS_IDS:
      return { ...state, managerGroupsIds: (action as SetFilterManagerGroupsIdsAction).managerGroupsIds };
    case Type.SET_FILTER_MANAGER_USERS_IDS:
      return { ...state, managerEmailUsersIds: (action as SetFilterManagerEmailUsersIdsAction).managerEmailUsersIds };
    case Type.SET_FILTER_DELEGATE_MANAGER_USERS_IDS:
      return { ...state, delegateUserManagersIds: (action as SetFilterDelegateManagerUsersIdsAction).delegateUserManagersIds };
    default:
      return state;
  }
};

const requestCustomFieldSettings: Reducer<IRequestCustomFieldSettingsWithCheckboxes[]> = (state = initialState.requestCustomFieldSettings, action) => {
  switch (action.type) {
    case Type.RESET:
      return state;
    case Type.RESET_HARD:
      return initialState.requestCustomFieldSettings;
    case Type.SET_REQUEST_CUSTOM_FIELD_SETTINGS:
      return (action as SetRequestCustomFieldSettingsAction).requestCustomFieldSettings;
    default:
      return state;
  }
};

const areRequestCustomFieldSettingsLoaded: Reducer<boolean> = (state = initialState.areRequestCustomFieldSettingsLoaded, action) => {
  switch (action.type) {
    case Type.RESET:
      return state;
    case Type.RESET_HARD:
      return initialState.areRequestCustomFieldSettingsLoaded;
    case Type.SET_ARE_REQUEST_CUSTOM_FIELD_SETTINGS_LOADED:
      return (action as SetAreRequestCustomFieldSettingsLoadedAction).areRequestCustomFieldSettingsLoaded;
    default:
      return state;
  }
};

const bidderOptions: Reducer<ICustomFieldOptions[]> = (state = initialState.bidderOptions, action) => {
  switch (action.type) {
    case Type.RESET:
      return state;
    case Type.RESET_HARD:
      return initialState.bidderOptions;
    case Type.SET_BIDDER_OPTIONS:
      return (action as SetBidderOptionsAction).bidderOptions;
    default:
      return state;
  }
};

const centerOptions: Reducer<ICustomFieldOptions[]> = (state = initialState.centerOptions, action) => {
  switch (action.type) {
    case Type.RESET:
      return state;
    case Type.RESET_HARD:
      return initialState.centerOptions;
    case Type.SET_CENTER_OPTIONS:
      return (action as SetCenterOptionsAction).centerOptions;
    default:
      return state;
  }
};

const unecoOptions: Reducer<ICustomFieldOptions[]> = (state = initialState.unecoOptions, action) => {
  switch (action.type) {
    case Type.RESET:
      return state;
    case Type.RESET_HARD:
      return initialState.unecoOptions;
    case Type.SET_UNECO_OPTIONS:
      return (action as SetUnecoOptionsAction).unecoOptions;
    default:
      return state;
  }
};

export const requestsListStore = combineReducers({
  requests,
  mode,
  isChangingMode,
  filter,
  requestCustomFieldSettings,
  areRequestCustomFieldSettingsLoaded,
  bidderOptions,
  centerOptions,
  unecoOptions,
  grid: listGridStore.createListGridStore(Type, 'createdDate', 'Descending')
});
