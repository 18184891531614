import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { FormValidator, FormEventArgs } from '@syncfusion/ej2-react-inputs';
import { DialogComponent } from '@aitex/tsx-extranet-dialogs';

import { ICreateNoteDialogProps } from '../../../interfaces/props/IDialogsProps';
import { INote } from '../../../common/model/note.model';
import { IErrors } from '../../../common/model/errors.model';
import { Validator } from '../../../common/model/enumerations/validator.model';
import { notesService } from '../../../services';
import * as dialogUtils from '../../../utils/dialog.utils';
import * as formValidatorUtils from '../../../utils/formValidator.utils';
import ValidationError from '../../validationError/validationError';

type CreateNoteDialogPropsType = ICreateNoteDialogProps & WithTranslation;

type CreateNoteDialogStateType = ICreateNoteDialogState;

class CreateNoteDialog extends Component<CreateNoteDialogPropsType, CreateNoteDialogStateType> {
  public readonly CREATE_NOTE_DIALOG_FORM_ID = 'createNoteDialogForm';

  private readonly CANCEL_BUTTON_ID = 'createNoteDialogCancel';
  private readonly SAVE_BUTTON_ID = 'createNoteDialogSave';

  private readonly CANNOT_CREATE_NOTE_ERROR: IErrors = { UnknownError: ['dialogs.createNoteDialog.error'] };

  private formValidator: FormValidator = null;
  private isValidatingAll = false;
  private auxErrors: IErrors = {};

  private initialState: CreateNoteDialogStateType = {
    showForm: false,
    note: {
      id: null,
      sourceType: null,
      sourceId: null,
      userId: null,
      userFirstName: null,
      userLastName: null,
      createdDate: null,
      description: ''
    },
    isSaving: false,
    errors: {}
  };

  public constructor(props: CreateNoteDialogPropsType) {
    super(props);

    this.state = this.initialState;
  }

  public componentDidUpdate(prevProps: CreateNoteDialogPropsType): void {
    const { visible, sourceType, sourceId } = this.props;

    if (!prevProps.visible && visible) {
      this.setState({
        showForm: true,
        note: { ...this.state.note, sourceType, sourceId }
      });
    }
    if (prevProps.visible && !visible) {
      this.setState(this.initialState);
      this.resetFormValidator();
    }

    dialogUtils.manageButtonsClick(this.SAVE_BUTTON_ID, (): Promise<void> => this.createNote(), this.CANCEL_BUTTON_ID, (): void => this.dismiss());
  }

  public async createNote(): Promise<void> {
    try {
      if (!this.validateForm()) {
        return;
      }

      this.setState({ isSaving: true });

      if (typeof this.state.note.sourceType !== 'number' || !this.state.note.sourceId) {
        throw this.CANNOT_CREATE_NOTE_ERROR;
      }

      await notesService.create(this.state.note);
      this.close();
    } catch (error) {
      let errors = error as IErrors;
      if (errors.Description) {
        this.setState({ errors });
        return;
      }
      if (errors.UnknownError || (!errors.UnknownError && !errors.ControlledError)) {
        errors = this.CANNOT_CREATE_NOTE_ERROR;
      }
      this.dismiss(errors);
    } finally {
      this.setState({ isSaving: false });
    }
  }

  public close(): void {
    this.formValidator = null;
    this.props.onClose();
  }

  public dismiss(reason?: IErrors): void {
    this.props.onDismiss(reason);
  }

  public configureFormValidator(): void {
    if (this.state.showForm) {
      if (!this.formValidator) {
        this.formValidator = formValidatorUtils.configureFormValidator(this.CREATE_NOTE_DIALOG_FORM_ID, {
          Description: { required: [true, Validator.NOT_EMPTY] }
        });
        this.formValidator.validationComplete = (args) => {
          formValidatorUtils.validationComplete(args as FormEventArgs, this.isValidatingAll, this.state.errors, this.auxErrors, (errors): void => this.setState({ errors }));
        };
      }
    } else {
      this.formValidator = null;
    }
  }

  public onDescriptionChange(description: string): void {
    this.setState({ note: { ...this.state.note, description } });
  }

  public render(): JSX.Element {
    const { visible, i18n } = this.props;

    return (
      <DialogComponent
        header={i18n.t('dialogs.createNoteDialog.title')}
        visible={visible}
        width='480px'
        footerTemplate={dialogUtils.computeFooterTemplate(
          this.SAVE_BUTTON_ID,
          i18n.t('actions.save'),
          this.CANCEL_BUTTON_ID,
          i18n.t('actions.cancel'),
          this.state.isSaving,
          this.isSaveButtonDisabled()
        )}
        onDismiss={(): void => this.dismiss()}
      >
        <div>
          {this.state.showForm && <form id={this.CREATE_NOTE_DIALOG_FORM_ID} ref={(): void => this.configureFormValidator()}>
            <div className='row'>
              <div className='col'>
                <div className='form-group'>
                  <label htmlFor='description'>{i18n.t('note.description')}</label>
                  <textarea
                    className='form-control'
                    id='description'
                    name='Description'
                    rows={5}
                    value={this.state.note.description}
                    onChange={(e): void => this.onDescriptionChange(e.target.value)}
                  />
                  <ValidationError errors={this.state.errors} errorKey={'Description'} />
                </div>
              </div>
            </div>
          </form>}
        </div>
      </DialogComponent>
    );
  }

  private isSaveButtonDisabled(): boolean {
    return !!this.state.errors.Description || this.state.isSaving;
  }

  private resetFormValidator(): void {
    if (!this.formValidator) {
      return;
    }

    if (this.formValidator.getInputElement('Description')) {
      this.formValidator.getInputElement('Description').parentElement.className = this.formValidator.getInputElement('Description').parentElement.className.replace('e-error', '');
    }
    this.formValidator.removeRules();
    this.formValidator.validationComplete = undefined;
  }

  private validateForm(): boolean {
    if (!this.formValidator) {
      return true;
    }

    this.isValidatingAll = true;
    const isFormValid = this.formValidator.validate();
    this.isValidatingAll = false;
    if (!isFormValid) {
      this.setState({ errors: this.auxErrors });
      this.auxErrors = {};
    }
    return isFormValid;
  }
}

interface ICreateNoteDialogState {
  showForm: boolean;
  note: INote;
  isSaving: boolean;
  errors: IErrors;
}

export default withTranslation()(CreateNoteDialog);
