import { Action, Reducer, ActionCreator, combineReducers } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { batch } from 'react-redux';
import moment, { Moment } from 'moment';

import { RootState } from '..';
import { IRequestDetailState } from '../../interfaces/states/IRequestDetailState';
import { IRequest } from '../../common/model/request.model';
import { IRequestCustomFields } from '../../common/model/requestCustomFields.model';
import { ICustomFieldPermission } from '../../common/model/customFieldPermission.model';
import { ICustomFieldOptions } from '../../common/model/customFieldOptions.model';
import { IReportComboExtended } from '../../common/model/report.model';
import { IReference } from '../../common/model/reference.model';
import { IAnnex } from '../../common/model/annex.model';
import { INotificationWithViewInfo } from '../../common/model/notification.model';
import { INote } from '../../common/model/note.model';
import { RequestStatus } from '../../common/model/enumerations/requestStatus.model';
import { RequestAcceptanceStatus } from '../../common/model/enumerations/requestAcceptanceStatus.model';
import { UserType } from '../../common/model/enumerations/userType.model';
import { IRequestChangeHistory } from '../../common/model/requestChangeHistory.model';
import { IRequestsService, ICompaniesService } from '../../services';
import { IErrors } from '../../common/model/errors.model';

enum Type {
  RESET = '@@requestDetail/RESET',
  MAKE_READY_FOR_RENDERING = '@@requestDetail/MAKE_READY_FOR_RENDERING',
  SET_REQUEST = '@@requestDetail/SET_REQUEST',
  SET_REQUEST_INITIAL = '@@requestDetail/SET_REQUEST_INITIAL',
  SET_REQUEST_SAMPLE_DATE = '@@requestDetail/SET_REQUEST_SAMPLE_DATE',
  SET_REQUEST_STATUS = '@@requestDetail/SET_REQUEST_STATUS',
  SET_REQUEST_OFFER_NUMBER = '@@requestDetail/SET_REQUEST_OFFER_NUMBER',
  SET_REQUEST_INITIAL_AMOUNT = '@@requestDetail/SET_REQUEST_INITIAL_AMOUNT',
  SET_REQUEST_AMOUNT = '@@requestDetail/SET_REQUEST_AMOUNT',
  SET_REQUEST_MANAGER_GROUPS_ID = '@@requestDetail/SET_REQUEST_MANAGER_GROUPS_ID',
  SET_REQUEST_SUBJECT = '@@requestDetail/SET_REQUEST_MANAGER_SUBJECT',
  SET_REQUEST_MANAGER_EMAIL_USER_ID = '@@requestDetail/SET_REQUEST_MANAGER_EMAIL_USER_ID',
  SET_REQUEST_ACCEPTANCE_STATUS = '@@requestDetail/SET_REQUEST_ACCEPTANCE_STATUS',
  SET_REQUEST_ORDER = '@@requestDetail/SET_REQUEST_ORDER',
  SET_REQUEST_END_DATE = '@@requestDetail/SET_REQUEST_END_DATE',
  SET_REQUEST_ANALYSIS_TO_PERFORM = '@@requestDetail/SET_REQUEST_ANALYSIS_TO_PERFORM',
  SET_REQUEST_CUSTOM_FIELDS = '@@requestDetail/SET_REQUEST_CUSTOM_FIELDS',
  SET_IS_VIEW = '@@requestDetail/SET_IS_VIEW',
  SET_IS_NEW = '@@requestDetail/SET_IS_NEW',
  SET_CUSTOM_FIELD_PERMISSIONS = '@@requestDetail/SET_CUSTOM_FIELD_PERMISSIONS',
  SET_BIDDER_OPTIONS = '@@requestDetail/SET_BIDDER_OPTIONS',
  SET_CENTER_OPTIONS = '@@requestDetail/SET_CENTER_OPTIONS',
  SET_UNECO_OPTIONS = '@@requestDetail/SET_UNECO_OPTIONS',
  SET_DELEGATION_OPTIONS = '@@requestDetail/SET_DELEGATION_OPTIONS',
  SET_REPORTS = '@@requestDetail/SET_REPORTS',
  SET_REFERENCES = '@@requestDetail/SET_REFERENCES',
  SET_IS_FETCHING_REFERENCES = '@@requestDetail/SET_IS_FETCHING_REFERENCES',
  SET_ANNEXES = '@@requestDetail/SET_ANNEXES',
  SET_NOTIFICATIONS = '@@requestDetail/SET_NOTIFICATIONS',
  MARK_NOTIFICATION_AS_SEEN_LOCAL = '@@requestDetail/MARK_NOTIFICATION_AS_SEEN_LOCAL',
  SET_NOTES = '@@requestDetail/SET_NOTES',
  SET_ERRORS = '@@requestDetail/SET_ERRORS',
  SET_REQUEST_DELEGATE_USER_MANAGER_ID = '@@requestDetail/SET_REQUEST_DELEGATE_USER_MANAGER_ID',
  SET_REQUEST_CHANGES_HISTORY = '@@reportDetail/SET_REQUEST_CHANGES_HISTORY',
  SET_APPLICANT_CC = '@@requestDetail/SET_APPLICANT_CC',
  SET_REQUEST_APPLICANT_CC = '@@requestDetail/SET_REQUEST_APPLICANT_CC'
}

const defaultRequest: IRequest = {
  id: null,
  code: null,
  createdDate: null,
  sampleDate: null,
  status: RequestStatus.OPEN,
  acceptanceStatus: RequestAcceptanceStatus.NONE,
  analysisToPerform: '',
  order: null,
  sendDate: null,
  offerNumber: null,
  amount: 0,
  managerGroupId: null,
  subject: null,
  companyId: null,
  companyName: null,
  applicantId: null,
  applicantEmail: null,
  managerEmailUserId: null,
  referenceNames: null,
  managerGroupUserName: null,
  customFields: {
    contest: null,
    eciRequest: null,
    supplier: null,
    initialAmount: 0,
    quantity: 0,
    sampleSealed: false,
    returnSurplusSample: false,
    requiredDate: null,
    bidderId: null,
    bidderName: null,
    centerId: null,
    centerName: null,
    unecoId: null,
    unecoName: null,
    delegationId: null,
    delegationName: null
  },
  countryId: null,
  delegateUserManagerId: null,
  applicantCc: null
};

const initialState: IRequestDetailState = {
  isReadyForRendering: false,
  request: defaultRequest,
  requestInitial: defaultRequest,
  isView: null, // must be !== null when isReadyForRendering === true
  isNew: null, // must be !== null when isReadyForRendering === true
  customFieldPermissions: [],
  bidderOptions: [],
  centerOptions: [],
  unecoOptions: [],
  delegationOptions: [],
  reports: [],
  references: [],
  isFetchingReferences: false,
  annexes: [],
  notifications: [],
  notes: [],
  requestChangesHistory: [],
  errors: {},
  applicantCc: []
};

// Actions

export type Actions = ResetAction | MakeReadyForRenderingAction | SetRequestAction | SetRequestSampleDateAction | SetRequestStatusAction | SetRequestOfferNumberAction | SetRequestAmountAction
  | SetRequestManagerEmailUserIdAction | SetRequestAcceptanceStatusAction | SetRequestOrderAction | SetRequestSendDateAction | SetRequestAnalysisToPerformAction
  | SetRequestCustomFieldsAction | SetRequestInitialAction | SetIsViewAction | SetCustomFieldPermissionsAction | SetBidderOptionsAction | SetCenterOptionsAction | SetUnecoOptionsAction
  | SetDelegationOptionsAction | SetReportsAction | SetReferencesAction | SetIsFetchingReferencesAction | SetAnnexesAction | SetNotificationsAction | MarkNotificationAsSeenLocalAction
  | SetNotesAction | SetRequestChangesHistoryAction | SetRequestManagerGroupIdAction | SetRequestSubjectAction | SetErrorsAction | SetRequestDelegateUserManagerIdAction
  | SetRequestApplicantCcAction | SetApplicantCcAction;

export type ResetAction = Action<Type.RESET>

export interface MakeReadyForRenderingAction extends Action<Type.MAKE_READY_FOR_RENDERING> {
  isView: boolean;
  isNew: boolean;
}

export interface SetRequestAction extends Action<Type.SET_REQUEST> {
  request: IRequest;
}

export interface SetRequestSampleDateAction extends Action<Type.SET_REQUEST_SAMPLE_DATE> {
  sampleDate: Moment;
}

export interface SetRequestStatusAction extends Action<Type.SET_REQUEST_STATUS> {
  status: string;
}

export interface SetRequestOfferNumberAction extends Action<Type.SET_REQUEST_OFFER_NUMBER> {
  offerNumber: string;
}

export interface SetRequestAmountAction extends Action<Type.SET_REQUEST_AMOUNT> {
  amount: number;
}

export interface SetRequestManagerGroupIdAction extends Action<Type.SET_REQUEST_MANAGER_GROUPS_ID> {
  managerGroupId: string;
}

export interface SetRequestSubjectAction extends Action<Type.SET_REQUEST_SUBJECT> {
  subject: string;
}

export interface SetRequestManagerEmailUserIdAction extends Action<Type.SET_REQUEST_MANAGER_EMAIL_USER_ID> {
  managerEmailUserId: string;
}

export interface SetRequestAcceptanceStatusAction extends Action<Type.SET_REQUEST_ACCEPTANCE_STATUS> {
  acceptanceStatus: number;
}

export interface SetRequestOrderAction extends Action<Type.SET_REQUEST_ORDER> {
  order: string;
}

export interface SetRequestSendDateAction extends Action<Type.SET_REQUEST_END_DATE> {
  sendDate: Moment;
}

export interface SetRequestAnalysisToPerformAction extends Action<Type.SET_REQUEST_ANALYSIS_TO_PERFORM> {
  analysisToPerform: string;
}

export interface SetRequestCustomFieldsAction extends Action<Type.SET_REQUEST_CUSTOM_FIELDS> {
  customFields: IRequestCustomFields;
}

export interface SetRequestInitialAction extends Action<Type.SET_REQUEST_INITIAL> {
  requestInitial: IRequest;
}

export interface SetIsViewAction extends Action<Type.SET_IS_VIEW> {
  isView: boolean;
}

export interface SetIsNewAction extends Action<Type.SET_IS_NEW> {
  isNew: boolean;
}

export interface SetCustomFieldPermissionsAction extends Action<Type.SET_CUSTOM_FIELD_PERMISSIONS> {
  customFieldPermissions: ICustomFieldPermission[];
}

export interface SetBidderOptionsAction extends Action<Type.SET_BIDDER_OPTIONS> {
  bidderOptions: ICustomFieldOptions[];
}

export interface SetCenterOptionsAction extends Action<Type.SET_CENTER_OPTIONS> {
  centerOptions: ICustomFieldOptions[];
}

export interface SetUnecoOptionsAction extends Action<Type.SET_UNECO_OPTIONS> {
  unecoOptions: ICustomFieldOptions[];
}

export interface SetDelegationOptionsAction extends Action<Type.SET_DELEGATION_OPTIONS> {
  delegationOptions: ICustomFieldOptions[];
}

export interface SetRequestDelegateUserManagerIdAction extends Action<Type.SET_REQUEST_DELEGATE_USER_MANAGER_ID> {
  delegateUserManagerId: string;
}

export interface SetApplicantCcAction extends Action<Type.SET_APPLICANT_CC> {
  applicantCc: string[];
}

export interface SetRequestApplicantCcAction extends Action<Type.SET_REQUEST_APPLICANT_CC> {
  applicantCc: string;
}

export interface SetReportsAction extends Action<Type.SET_REPORTS> {
  reports: IReportComboExtended[];
}

export interface SetReferencesAction extends Action<Type.SET_REFERENCES> {
  references: IReference[];
}

export interface SetIsFetchingReferencesAction extends Action<Type.SET_IS_FETCHING_REFERENCES> {
  isFetchingReferences: boolean;
}

export interface SetAnnexesAction extends Action<Type.SET_ANNEXES> {
  annexes: IAnnex[];
}

export interface SetNotificationsAction extends Action<Type.SET_NOTIFICATIONS> {
  notifications: INotificationWithViewInfo[];
}

export interface MarkNotificationAsSeenLocalAction extends Action<Type.MARK_NOTIFICATION_AS_SEEN_LOCAL> {
  notificationId: string;
}

export interface SetNotesAction extends Action<Type.SET_NOTES> {
  notes: INote[];
}

export interface SetRequestChangesHistoryAction extends Action<Type.SET_REQUEST_CHANGES_HISTORY> {
  requestChangesHistory: IRequestChangeHistory[];
}

export interface SetErrorsAction extends Action<Type.SET_ERRORS> {
  errors: IErrors;
}

export const reset: ActionCreator<ResetAction> = () => ({
  type: Type.RESET
});

export const makeReadyForRendering: ActionCreator<MakeReadyForRenderingAction> = (isViewLocal: boolean, isNewLocal: boolean) => ({
  type: Type.MAKE_READY_FOR_RENDERING,
  isView: isViewLocal,
  isNew: isNewLocal
});

export const setRequest: ActionCreator<SetRequestAction> = (requestLocal: IRequest) => ({
  type: Type.SET_REQUEST,
  request: requestLocal
});

export const setRequestSampleDate: ActionCreator<SetRequestSampleDateAction> = (sampleDate: Moment) => ({
  type: Type.SET_REQUEST_SAMPLE_DATE,
  sampleDate
});

export const setRequestStatus: ActionCreator<SetRequestStatusAction> = (status: string) => ({
  type: Type.SET_REQUEST_STATUS,
  status
});

export const setRequestOfferNumber: ActionCreator<SetRequestOfferNumberAction> = (offerNumber: string) => ({
  type: Type.SET_REQUEST_OFFER_NUMBER,
  offerNumber
});

export const setRequestAmount: ActionCreator<SetRequestAmountAction> = (amount: number) => ({
  type: Type.SET_REQUEST_AMOUNT,
  amount
});

export const setRequestManagerGroupId: ActionCreator<SetRequestManagerGroupIdAction> = (managerGroupId: string) => ({
  type: Type.SET_REQUEST_MANAGER_GROUPS_ID,
  managerGroupId
});

export const setRequestSubject: ActionCreator<SetRequestSubjectAction> = (subject: string) => ({
  type: Type.SET_REQUEST_SUBJECT,
  subject
});

export const setRequestManagerEmailUserId: ActionCreator<SetRequestManagerEmailUserIdAction> = (managerEmailUserId: string) => ({
  type: Type.SET_REQUEST_MANAGER_EMAIL_USER_ID,
  managerEmailUserId
});

export const setRequestAcceptanceStatus: ActionCreator<SetRequestAcceptanceStatusAction> = (acceptanceStatus: number) => ({
  type: Type.SET_REQUEST_ACCEPTANCE_STATUS,
  acceptanceStatus
});

export const setRequestOrder: ActionCreator<SetRequestOrderAction> = (order: string) => ({
  type: Type.SET_REQUEST_ORDER,
  order
});

export const setRequestSendDate: ActionCreator<SetRequestSendDateAction> = (sendDate: Moment) => ({
  type: Type.SET_REQUEST_END_DATE,
  sendDate
});

export const setRequestAnalysisToPerform: ActionCreator<SetRequestAnalysisToPerformAction> = (analysisToPerform: string) => ({
  type: Type.SET_REQUEST_ANALYSIS_TO_PERFORM,
  analysisToPerform
});

export const setRequestCustomFields: ActionCreator<SetRequestCustomFieldsAction> = (customFields: IRequestCustomFields) => ({
  type: Type.SET_REQUEST_CUSTOM_FIELDS,
  customFields
});

export const setRequestInitial: ActionCreator<SetRequestInitialAction> = (requestInitialLocal: IRequest) => ({
  type: Type.SET_REQUEST_INITIAL,
  requestInitial: requestInitialLocal
});

export const setIsView: ActionCreator<SetIsViewAction> = (isViewLocal: boolean) => ({
  type: Type.SET_IS_VIEW,
  isView: isViewLocal
});

export const setIsNew: ActionCreator<SetIsNewAction> = (isNewLocal: boolean) => ({
  type: Type.SET_IS_NEW,
  isNew: isNewLocal
});

export const setCustomFieldPermissions: ActionCreator<SetCustomFieldPermissionsAction> = (customFieldPermissionsLocal: ICustomFieldPermission[]) => ({
  type: Type.SET_CUSTOM_FIELD_PERMISSIONS,
  customFieldPermissions: customFieldPermissionsLocal
});

export const setRequestDelegateUserManagerId: ActionCreator<SetRequestDelegateUserManagerIdAction> = (delegateUserManagerId: string) => ({
  type: Type.SET_REQUEST_DELEGATE_USER_MANAGER_ID,
  delegateUserManagerId
});

export const setApplicantCc: ActionCreator<SetApplicantCcAction> = (applicantCc: string[]) => ({
  type: Type.SET_APPLICANT_CC,
  applicantCc
});

export const setRequestApplicantCc: ActionCreator<SetRequestApplicantCcAction> = (applicantCc: string) => ({
  type: Type.SET_REQUEST_APPLICANT_CC,
  applicantCc
});

export const setBidderOptions: ActionCreator<SetBidderOptionsAction> = (bidderOptionsLocal: ICustomFieldOptions[]) => ({
  type: Type.SET_BIDDER_OPTIONS,
  bidderOptions: bidderOptionsLocal
});

export const setCenterOptions: ActionCreator<SetCenterOptionsAction> = (centerOptionsLocal: ICustomFieldOptions[]) => ({
  type: Type.SET_CENTER_OPTIONS,
  centerOptions: centerOptionsLocal
});

export const setUnecoOptions: ActionCreator<SetUnecoOptionsAction> = (unecoOptionsLocal: ICustomFieldOptions[]) => ({
  type: Type.SET_UNECO_OPTIONS,
  unecoOptions: unecoOptionsLocal
});

export const setDelegationOptions: ActionCreator<SetDelegationOptionsAction> = (delegationOptionsLocal: ICustomFieldOptions[]) => ({
  type: Type.SET_DELEGATION_OPTIONS,
  delegationOptions: delegationOptionsLocal
});

export const setReports: ActionCreator<SetReportsAction> = (reportsLocal: IReportComboExtended[]) => ({
  type: Type.SET_REPORTS,
  reports: reportsLocal
});

export const setReferences: ActionCreator<SetReferencesAction> = (referencesLocal: IReference[]) => ({
  type: Type.SET_REFERENCES,
  references: referencesLocal
});

export const setIsFetchingReferences: ActionCreator<SetIsFetchingReferencesAction> = (isFetchingReferencesLocal: boolean) => ({
  type: Type.SET_IS_FETCHING_REFERENCES,
  isFetchingReferences: isFetchingReferencesLocal
});

export const setAnnexes: ActionCreator<SetAnnexesAction> = (annexesLocal: IAnnex[]) => ({
  type: Type.SET_ANNEXES,
  annexes: annexesLocal
});

export const setNotifications: ActionCreator<SetNotificationsAction> = (notificationsLocal: INotificationWithViewInfo[]) => ({
  type: Type.SET_NOTIFICATIONS,
  notifications: notificationsLocal
});

export const markNotificationAsSeenLocal: ActionCreator<MarkNotificationAsSeenLocalAction> = (notificationId: string) => ({
  type: Type.MARK_NOTIFICATION_AS_SEEN_LOCAL,
  notificationId
});

export const setNotes: ActionCreator<SetNotesAction> = (notesLocal: INote[]) => ({
  type: Type.SET_NOTES,
  notes: notesLocal
});

export const setRequestChangesHistory: ActionCreator<SetRequestChangesHistoryAction> = (requestChangesHistoryLocal: IRequestChangeHistory[]) => ({
  type: Type.SET_REQUEST_CHANGES_HISTORY,
  requestChangesHistory: requestChangesHistoryLocal
});

export const fetchRequest: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (id: string, requestsService: IRequestsService) => (
  async (dispatch) => {
    try {
      const requestLocal = await requestsService.fetch(id);

      batch(() => {
        dispatch(setRequest(requestLocal));
        dispatch(setRequestInitial(requestLocal));
      });
    } catch (error) {
      // TODO: manejar error
    }
  }
);

export const resetRequest: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = () => (
  async (dispatch, getState) => {
    dispatch(setRequest(getState().requestDetailStore.requestInitial));
  }
);

export const saveRequest: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (requestLocal: IRequest) => (
  async (dispatch) => {
    dispatch(setRequest(requestLocal));
    dispatch(setRequestInitial(requestLocal));
  }
);

export const fetchPermissionsAndOptions: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (requestsService: IRequestsService) => (
  async (dispatch) => {
    dispatch(setCustomFieldPermissions(await requestsService.fetchCustomFieldPermissions()));
    dispatch(setBidderOptions(await requestsService.fetchCustomFieldOptions('bidderId')));
    dispatch(setCenterOptions(await requestsService.fetchCustomFieldOptions('centerId')));
    dispatch(setUnecoOptions(await requestsService.fetchCustomFieldOptions('unecoId')));
    dispatch(setDelegationOptions(await requestsService.fetchCustomFieldOptions('delegationId')));
  }
);

export const fetchReports: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (requestId: string, requestsService: IRequestsService) => (
  async (dispatch) => {
    dispatch(setReports(await requestsService.fetchReports(requestId)));
  }
);

export const fetchReferences: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (requestId: string, requestsService: IRequestsService) => (
  async (dispatch) => {
    dispatch(setIsFetchingReferences(true));

    const referencesLocal = await requestsService.fetchReferences(requestId);
    batch(() => {
      dispatch(setReferences(referencesLocal));
      dispatch(setIsFetchingReferences(false));
    });
  }
);

export const fetchAnnexes: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (requestId: string, requestsService: IRequestsService) => (
  async (dispatch) => {
    dispatch(setAnnexes(await requestsService.fetchAnnexes(requestId)));
  }
);

export const fetchNotifications: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (requestId: string, requestsService: IRequestsService) => (
  async (dispatch, getState) => {
    const isCurrentUserInternal = getState().currentUserStore.user.type === UserType.INTERNAL;
    let notificationsLocal = await requestsService.fetchNotifications(requestId);
    if (isCurrentUserInternal) {
      notificationsLocal = notificationsLocal.map((notification) => ({ ...notification, seen: true }));
    }
    dispatch(setNotifications(notificationsLocal));
  }
);

export const fetchNotes: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (requestId: string, requestsService: IRequestsService) => (
  async (dispatch) => {
    dispatch(setNotes(await requestsService.fetchNotes(requestId)));
  }
);

export const fetchRequestChangesHistory: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (requestId: string, requestsService: IRequestsService) => (
  async (dispatch) => {
    dispatch(setRequestChangesHistory(await requestsService.fetchRequestChangesHistory(requestId)));
  }
);

export const fetchApplicantCc: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (companyId: string, companiesService: ICompaniesService) => (
  async (dispatch) => {
    dispatch(setApplicantCc(await companiesService.fetchActiveCompanyUsers(companyId)));
  }
);

export const setErrors: ActionCreator<SetErrorsAction> = (errorsLocal: IErrors) => ({
  type: Type.SET_ERRORS,
  errors: errorsLocal
});

// Reducers

const isReadyForRendering: Reducer<boolean> = (state = initialState.isReadyForRendering, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.isReadyForRendering;
    case Type.MAKE_READY_FOR_RENDERING:
      return true;
    default:
      return state;
  }
};

const request: Reducer<IRequest> = (state = initialState.request, action) => {
  switch (action.type) {
    case Type.RESET:
      return {
        ...initialState.request,
        sendDate: moment()
      };
    case Type.SET_REQUEST:
      return (action as SetRequestAction).request;
    case Type.SET_REQUEST_SAMPLE_DATE:
      return { ...state, sampleDate: (action as SetRequestSampleDateAction).sampleDate };
    case Type.SET_REQUEST_STATUS: {
      const newStatus = (action as SetRequestStatusAction).status;
      const currentStatus = state.status;
      let currentAcceptanceStatus = state.acceptanceStatus;
      if (currentStatus === RequestStatus.FINALIZED && newStatus === RequestStatus.IN_PROGRESS) {
        currentAcceptanceStatus = RequestAcceptanceStatus.NONE;
      }

      return { ...state, status: newStatus, acceptanceStatus: currentAcceptanceStatus};
    }
    case Type.SET_REQUEST_OFFER_NUMBER: {
      const offerNumber = (action as SetRequestOfferNumberAction).offerNumber;
      let currentStatus = state.status;
      if (currentStatus !== RequestStatus.FINALIZED && offerNumber !== undefined && offerNumber !== null && offerNumber.trim() !== '') {
        currentStatus = RequestStatus.FINALIZED;
      } else if (currentStatus !== RequestStatus.OPEN && state.managerEmailUserId && (offerNumber === undefined || offerNumber === null || offerNumber.trim() === '')) {
        currentStatus = RequestStatus.IN_PROGRESS;
      } else if (currentStatus !== RequestStatus.OPEN && !state.managerEmailUserId && (offerNumber === undefined || offerNumber === null || offerNumber.trim() === '')) {
        currentStatus = RequestStatus.OPEN;
      }

      return { ...state, offerNumber, status: currentStatus };
    }
    case Type.SET_REQUEST_AMOUNT:
      return { ...state, amount: (action as SetRequestAmountAction).amount };
    case Type.SET_REQUEST_MANAGER_GROUPS_ID:
      return { ...state, managerGroupId: (action as SetRequestManagerGroupIdAction).managerGroupId };
    case Type.SET_REQUEST_SUBJECT:
      return { ...state, subject: (action as SetRequestSubjectAction).subject };
    case Type.SET_REQUEST_MANAGER_EMAIL_USER_ID:
      return { ...state, managerEmailUserId: (action as SetRequestManagerEmailUserIdAction).managerEmailUserId };
    case Type.SET_REQUEST_ACCEPTANCE_STATUS:
      return { ...state, acceptanceStatus: (action as SetRequestAcceptanceStatusAction).acceptanceStatus };
    case Type.SET_REQUEST_ORDER:
      return { ...state, order: (action as SetRequestOrderAction).order };
    case Type.SET_REQUEST_END_DATE:
      return { ...state, sendDate: (action as SetRequestSendDateAction).sendDate };
    case Type.SET_REQUEST_ANALYSIS_TO_PERFORM:
      return { ...state, analysisToPerform: (action as SetRequestAnalysisToPerformAction).analysisToPerform };
    case Type.SET_REQUEST_CUSTOM_FIELDS:
      return { ...state, customFields: (action as SetRequestCustomFieldsAction).customFields };
    case Type.SET_REQUEST_DELEGATE_USER_MANAGER_ID:
      return { ...state, delegateUserManagerId: (action as SetRequestDelegateUserManagerIdAction).delegateUserManagerId };
    case Type.SET_REQUEST_APPLICANT_CC:
      return { ...state, applicantCc: (action as SetRequestApplicantCcAction).applicantCc };
    default:
      return state;
  }
};

const requestInitial: Reducer<IRequest> = (state = initialState.requestInitial, action) => {
  switch (action.type) {
    case Type.RESET:
      return {
        ...initialState.requestInitial,
        sendDate: moment()
      };
    case Type.SET_REQUEST_INITIAL:
      return (action as SetRequestInitialAction).requestInitial;
    default:
      return state;
  }
};

const isView: Reducer<boolean> = (state = initialState.isView, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.isView;
    case Type.MAKE_READY_FOR_RENDERING:
      return (action as MakeReadyForRenderingAction).isView;
    case Type.SET_IS_VIEW:
      return (action as SetIsViewAction).isView;
    default:
      return state;
  }
};

const isNew: Reducer<boolean> = (state = initialState.isNew, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.isNew;
    case Type.MAKE_READY_FOR_RENDERING:
      return (action as MakeReadyForRenderingAction).isNew;
    case Type.SET_IS_NEW:
      return (action as SetIsNewAction).isNew;
    default:
      return state;
  }
};

const customFieldPermissions: Reducer<ICustomFieldPermission[]> = (state = initialState.customFieldPermissions, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.customFieldPermissions;
    case Type.SET_CUSTOM_FIELD_PERMISSIONS:
      return (action as SetCustomFieldPermissionsAction).customFieldPermissions;
    default:
      return state;
  }
};

const bidderOptions: Reducer<ICustomFieldOptions[]> = (state = initialState.bidderOptions, action) => {
  switch (action.type) {
    case Type.RESET:
      return state;
    case Type.SET_BIDDER_OPTIONS:
      return (action as SetBidderOptionsAction).bidderOptions;
    default:
      return state;
  }
};

const centerOptions: Reducer<ICustomFieldOptions[]> = (state = initialState.centerOptions, action) => {
  switch (action.type) {
    case Type.RESET:
      return state;
    case Type.SET_CENTER_OPTIONS:
      return (action as SetCenterOptionsAction).centerOptions;
    default:
      return state;
  }
};

const unecoOptions: Reducer<ICustomFieldOptions[]> = (state = initialState.unecoOptions, action) => {
  switch (action.type) {
    case Type.RESET:
      return state;
    case Type.SET_UNECO_OPTIONS:
      return (action as SetUnecoOptionsAction).unecoOptions;
    default:
      return state;
  }
};

const delegationOptions: Reducer<ICustomFieldOptions[]> = (state = initialState.delegationOptions, action) => {
  switch (action.type) {
    case Type.RESET:
      return state;
    case Type.SET_DELEGATION_OPTIONS:
      return (action as SetDelegationOptionsAction).delegationOptions;
    default:
      return state;
  }
};

const reports: Reducer<IReportComboExtended[]> = (state = initialState.reports, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.reports;
    case Type.SET_REPORTS:
      return (action as SetReportsAction).reports;
    default:
      return state;
  }
};

const references: Reducer<IReference[]> = (state = initialState.references, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.references;
    case Type.SET_REFERENCES:
      return (action as SetReferencesAction).references;
    default:
      return state;
  }
};

const isFetchingReferences: Reducer<boolean> = (state = initialState.isFetchingReferences, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.isFetchingReferences;
    case Type.SET_IS_FETCHING_REFERENCES:
      return (action as SetIsFetchingReferencesAction).isFetchingReferences;
    default:
      return state;
  }
};

const annexes: Reducer<IAnnex[]> = (state = initialState.annexes, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.annexes;
    case Type.SET_ANNEXES:
      return (action as SetAnnexesAction).annexes;
    default:
      return state;
  }
};

const notifications: Reducer<INotificationWithViewInfo[]> = (state = initialState.notifications, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.notifications;
    case Type.SET_NOTIFICATIONS:
      return (action as SetNotificationsAction).notifications;
    case Type.MARK_NOTIFICATION_AS_SEEN_LOCAL:
      return state.map((notification) => {
        if (notification.id === (action as MarkNotificationAsSeenLocalAction).notificationId) {
          return {
            ...notification,
            seen: true
          };
        }
        return notification;
      });
    default:
      return state;
  }
};

const notes: Reducer<INote[]> = (state = initialState.notes, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.notes;
    case Type.SET_NOTES:
      return (action as SetNotesAction).notes;
    default:
      return state;
  }
};

const requestChangesHistory: Reducer<IRequestChangeHistory[]> = (state = initialState.requestChangesHistory, action) => {
  switch (action.type) {
  case Type.RESET:
    return initialState.requestChangesHistory;
  case Type.SET_REQUEST_CHANGES_HISTORY:
    return (action as SetRequestChangesHistoryAction).requestChangesHistory;
  default:
    return state;
  }
};

const errors: Reducer<IErrors> = (state = initialState.errors, action) => {
  switch (action.type) {
  case Type.RESET:
    return initialState.errors;
  case Type.SET_ERRORS:
    return (action as SetErrorsAction).errors;
  default:
    return state;
  }
};

const applicantCc: Reducer<string[]> = (state = initialState.applicantCc, action) => {
  switch (action.type) {
  case Type.RESET:
    return initialState.applicantCc;
  case Type.SET_APPLICANT_CC:
    return (action as SetApplicantCcAction).applicantCc;
  default:
    return state;
  }
};

export const requestDetailStore = combineReducers({
  isReadyForRendering,
  request,
  requestInitial,
  isView,
  isNew,
  customFieldPermissions,
  bidderOptions,
  centerOptions,
  unecoOptions,
  delegationOptions,
  reports,
  references,
  isFetchingReferences,
  annexes,
  notifications,
  notes,
  requestChangesHistory,
  errors,
  applicantCc
});
