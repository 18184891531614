import { Action, Reducer, ActionCreator, AnyAction, combineReducers } from 'redux';
import { SortDirection } from '@syncfusion/ej2-grids';
import { Moment } from 'moment';

import { IListGridState } from '../../interfaces/states/IListGridState';
import { getDefaultPageSize } from '../../utils/pager.utils';

export const initialState: IListGridState = {
  isFetching: false,
  searchDate: null,
  currentPage: 1,
  pageSize: getDefaultPageSize(),
  totalCount: 0,
  sortField: null, // will be initialized at createSortFieldReducer()
  sortDirection: null // will be initialized at createSortDirectionReducer()
};

// Actions

export interface SetIsFetchingAction extends Action<string> {
  isFetching: boolean;
}

export interface SetSearchDateAction extends Action<string> {
  searchDate: Moment;
}

export interface SetCurrentPageAction extends Action<string> {
  currentPage: number;
}

export interface SetPageSizeAction extends Action<string> {
  pageSize: number;
}

export interface SetTotalCountAction extends Action<string> {
  totalCount: number;
}

export interface SetSortingAction extends Action<string> {
  field: string;
  direction: SortDirection;
}

export const setIsFetching: ActionCreator<SetIsFetchingAction> = (type: string, isFetchingLocal: boolean) => ({
  type,
  isFetching: isFetchingLocal
});

export const setSearchDate: ActionCreator<SetSearchDateAction> = (type: string, searchDateLocal: Moment) => ({
  type,
  searchDate: searchDateLocal
});

export const setCurrentPage: ActionCreator<SetCurrentPageAction> = (type: string, currentPageLocal: number) => ({
  type,
  currentPage: currentPageLocal
});

export const setPageSize: ActionCreator<SetPageSizeAction> = (type: string, pageSizeLocal: number) => ({
  type,
  pageSize: pageSizeLocal
});

export const setTotalCount: ActionCreator<SetTotalCountAction> = (type: string, totalCountLocal: number) => ({
  type,
  totalCount: totalCountLocal
});

export const setSorting: ActionCreator<SetSortingAction> = (type: string, field: string, direction: SortDirection) => ({
  type,
  field,
  direction
});

// Reducers

const createIsFetchingReducer = (type: Record<string, string>): Reducer<boolean> => {
  return (state = initialState.isFetching, action) => {
    switch (action.type) {
      case type.RESET:
        return initialState.isFetching;
      case type.SET_IS_FETCHING:
        return (action as SetIsFetchingAction).isFetching;
      default:
        return state;
    }
  };
};

const createSearchDateReducer = (type: Record<string, string>): Reducer<Moment> => {
  return (state = initialState.searchDate, action) => {
    switch (action.type) {
      case type.RESET:
        return state;
      case type.SET_SEARCH_DATE:
        return (action as SetSearchDateAction).searchDate;
      default:
        return state;
    }
  };
};

const createCurrentPageReducer = (type: Record<string, string>): Reducer<number> => {
  return (state = initialState.currentPage, action) => {
    switch (action.type) {
      case type.RESET:
        return state;
      case type.SET_CURRENT_PAGE:
        return (action as SetCurrentPageAction).currentPage;
      default:
        return state;
    }
  };
};

const createPageSizeReducer = (type: Record<string, string>): Reducer<number> => {
  return (state = initialState.pageSize, action) => {
    switch (action.type) {
      case type.RESET:
        return state;
      case type.SET_PAGE_SIZE:
        return (action as SetPageSizeAction).pageSize;
      default:
        return state;
    }
  };
};

const createTotalCountReducer = (type: Record<string, string>): Reducer<number> => {
  return (state = initialState.totalCount, action) => {
    switch (action.type) {
      case type.RESET:
        return state;
      case type.SET_TOTAL_COUNT:
        return (action as SetTotalCountAction).totalCount;
      default:
        return state;
    }
  };
};

const createSortFieldReducer = (type: Record<string, string>, initialSortField: string): Reducer<string> => {
  return (state = initialSortField, action) => {
    switch (action.type) {
      case type.RESET:
        return state;
      case type.SET_SORTING:
        return (action as SetSortingAction).field;
      default:
        return state;
    }
  };
};

const createSortDirectionReducer = (type: Record<string, string>, initialSortDirection: SortDirection): Reducer<SortDirection> => {
  return (state = initialSortDirection, action) => {
    switch (action.type) {
      case type.RESET:
        return state;
      case type.SET_SORTING:
        return (action as SetSortingAction).direction;
      default:
        return state;
    }
  };
};

export const createListGridStore = (type: Record<string, string>, initialSortField: string, initialSortDirection: SortDirection): Reducer<IListGridState, AnyAction> => {
  return combineReducers({
    isFetching: createIsFetchingReducer(type),
    searchDate: createSearchDateReducer(type),
    currentPage: createCurrentPageReducer(type),
    pageSize: createPageSizeReducer(type),
    totalCount: createTotalCountReducer(type),
    sortField: createSortFieldReducer(type, initialSortField),
    sortDirection: createSortDirectionReducer(type, initialSortDirection)
  });
};
