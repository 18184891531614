import React, { Component, ComponentType } from 'react';
import { telemetryService } from '../services/telemetry.service';

interface TrackingProps {
  user?: {
    name: string;
    email: string;
  };
  location: {
    pathname: string;
  };
}

const withPageTracking = (WrappedComponent: ComponentType): typeof Component => {
  // eslint-disable-next-line react/display-name
  return class extends Component<TrackingProps> {
    public displayName: 'withPageTracking';

    public async componentDidMount() {
      await telemetryService.trackPage({
        userName: this.props.user ? this.props.user.name : null,
        email: this.props.user ? this.props.user.email : null,
        pathName: this.props.location.pathname
      });
    }

    public render() {
      return <WrappedComponent {...this.props} />;
    }
  };
};

export default withPageTracking;
