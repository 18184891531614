import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, Sort, Page, Toolbar, Resize, PageSettingsModel } from '@syncfusion/ej2-react-grids';
import { ItemModel, ClickEventArgs } from '@syncfusion/ej2-navigations';

import { INotesTabProps } from '../../../interfaces/props/ITabsProps'
import { INote } from '../../../common/model/note.model';
import * as pagerUtils from '../../../utils/pager.utils';
import * as dateHelpers from '../../../helpers/date.helpers';
import './notesTab.scss';

type NotesTabPropsType = INotesTabProps & WithTranslation;

class NotesTab extends Component<NotesTabPropsType> {
  public toolbarOptions: ItemModel[] = [];
  public pageSettings: PageSettingsModel = {
    pageSize: pagerUtils.getMaxPageSize(),
    pageCount: pagerUtils.getPageCount(),
    pageSizes: pagerUtils.getPageSizes()
  };

  private readonly CREATE_NOTE_ACTION_ID = 'creteNote';

  public constructor(props: NotesTabPropsType) {
    super(props);

    this.toolbarOptions = [
      {
        tooltipText: this.props.i18n.t('reportDetail.actions.createNote'),
        id: this.CREATE_NOTE_ACTION_ID,
        template: `
          <button type="button" class="e-tbar-btn e-tbtn-txt e-control e-btn e-lib">
            <span class="icon icon-comment"></span>
            <span class="ml-1 icon icon-app-item-add"></span>
          </button>
        `
      }
    ];
  }

  public onToolbarClick(args: ClickEventArgs) {
    if (args.item.id === this.CREATE_NOTE_ACTION_ID) {
      this.props.onCreateNoteButtonClick();
    }
  }

  public getCreatedDateLabel(note: INote): string {
    return `${dateHelpers.toShortDate(note.createdDate, true)} ${dateHelpers.toDateWithoutZones(note.createdDate, 'HH:mm:ss')}`;
  }

  public getUserColumnLabel(note: INote): string {
    return `${note.userFirstName} ${note.userLastName}`;
  }

  public getActionsColumnTemplate(note: INote): JSX.Element {
    const { currentUserId, onDeactivateNoteButtonClick, i18n } = this.props;

    return (
      <div className='btn-group'>
        <button
          type='button'
          className={`btn btn-sm btn-light${note.userId === currentUserId ? '' : ' d-none'}`}
          title={i18n.t('reportDetail.actions.deactivateNote')}
          onClick={() => onDeactivateNoteButtonClick(note.id)}
        >
          <span className='icon icon-app-item-remove' />
        </button>
      </div>
    );
  }

  public render() {
    const { notes, i18n } = this.props;

    return (
      <div className='notes-grid'>
        <GridComponent
          dataSource={notes}
          toolbar={this.toolbarOptions.length > 0 ? this.toolbarOptions : undefined}
          allowSorting={true}
          allowPaging={true}
          allowResizing={true}
          pageSettings={this.pageSettings}
          clipMode='EllipsisWithTooltip'
          locale={i18n.language}
          toolbarClick={(args) => this.onToolbarClick(args)}
        >
          <ColumnsDirective>
            <ColumnDirective
              headerText={this.props.i18n.t('note.createdDate')}
              field='createdDate'
              width='100'
              valueAccessor={(_field, data) => this.getCreatedDateLabel(data as INote)}
            />
            <ColumnDirective
              headerText={i18n.t('note.user')}
              field='userFirstName'
              width='100'
              valueAccessor={(_field, data) => this.getUserColumnLabel(data as INote)}
            />
            <ColumnDirective
              headerText={this.props.i18n.t('note.description')}
              field='description'
              width='250'
              disableHtmlEncode={false}
            />
            <ColumnDirective
              width='50'
              template={(note: INote) => this.getActionsColumnTemplate(note)}
            />
          </ColumnsDirective>
          <Inject services={[Sort, Page, Toolbar, Resize]} />
        </GridComponent>
      </div>
    );
  }
}

export default withTranslation()(NotesTab);
