import React, { Component } from 'react';
import { Route } from 'react-router';
import { connect, ConnectedProps } from 'react-redux';

import { RootState } from '../../store';
import { IPrivateRouteProps, IPrivateRouteStateProps } from '../../interfaces/props/IPrivateRouteProps';
import { authService, rolesService } from '../../services';
import Unauthorized from '../unauthorized/unauthorized';

type PrivateRoutePropsType = IPrivateRouteProps & PropsFromRedux;

class PrivateRoute extends Component<PrivateRoutePropsType> {
  public render() {
    const { roles, isAuth, jwtInfo, ...rest } = this.props;

    if (!isAuth) {
      authService.login();
      return null;
    }

    let hasPermission = true;
    if (Array.isArray(roles)) {
      hasPermission = rolesService.hasAnyRole(jwtInfo, roles);
    }

    if (!hasPermission) {
      return <Unauthorized />;
    }

    return (
      <Route {...rest} />
    );
  }
}

const mapStateToProps = (state: RootState): IPrivateRouteStateProps => ({
  isAuth: state.currentUserStore.user !== null,
  jwtInfo: state.currentUserStore.jwtInfo
});

const connector = connect(mapStateToProps, {});

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(PrivateRoute);
