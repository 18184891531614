import React, { Component, ComponentType } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { certificatesService } from '../../services';
import { ISharedCertificateState } from '../../interfaces/states/ISharedCertificateState';
import { WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'redux';
import queryString from 'query-string';
import moment from 'moment';
import * as certificateTypeUtils from '../../utils/certificateType.utils';
import * as dateHelpers from '../../helpers/date.helpers';
import './index.scss';

interface ISharedCertificateRouteParams {
  id: string;
}

type SharedCertificateProps = RouteComponentProps<ISharedCertificateRouteParams> & WithTranslation;
type SharedCertificateState = ISharedCertificateState;

class SharedCertificate extends Component<SharedCertificateProps, SharedCertificateState> {
  public constructor(props: SharedCertificateProps) {
    super(props);
    this.state = {
      code: null,
      certificateTypeValue: null,
      companyName: null,
      expirationDate: null,
      issueDate: null,
      offerRequerimentStandard: null,
      referenceNames: null,
      revision: 0,
      hasError: false
    };
  }

  public componentDidMount = async () => {
    let currentId: string = null;
    const parsedUrl = queryString.parse(this.props.location.search);
    if (parsedUrl.id) {
      currentId = parsedUrl.id as string;
    }
    if (this.props.match.params.id) {
      currentId = this.props.match.params.id;
    } else {
      const parsedUrl = queryString.parse(this.props.location.search);
      if (parsedUrl.id) {
        currentId = parsedUrl.id as string;
      }
    }

    if (currentId) {
      try {
        const certificate = await certificatesService.getSharedCertification(currentId);
        this.setState({ ...certificate });
      } catch (error: any) {
        if (error['410']) {
          this.props.history.push('/410');
        } else {
          let errorMessage = '';
          for (const property in error) {
            if (Object.prototype.hasOwnProperty.call(error, property)) {
              errorMessage += property + ': ' + error[property] + '\n';
            }
          }

          this.setState({ hasError: true });
          console.error(errorMessage);
        }

        return;
      }
    }
  }

  public render(): JSX.Element {
    const { i18n } = this.props;
    const hasMenu = document.getElementsByClassName('e-menu-wrapper').length > 0 && this.state.code !== null;

    return (
      <>
        {!hasMenu && <div className='container'>
          <div className='row align-items-center justify-content-between my-menu-container'>
            <div className='row w100'>
              <div className='col-3'>
                <a href='https://www.aitex.es/' target='_blank' rel='noopener noreferrer'>
                  <div className='logo'>
                  </div>
                </a>
              </div>
              <div className='col-9'>
                <div className='info'>
                  <p className='name'>Instituto Tecnológico Textil / Textile Research Institute</p>
                  <p className='address'>Carretera Banyeres, nº10, 03802 Alcoy (Alicante) - España</p>
                  <div>
                    <a href='https://www.linkedin.com/company/instituto-tecnol-gico-textil-aitex/' title='LinkedIn'><span className='icon icon-linkedin' /></a>
                    <a href='https://twitter.com/aitex_es' title='Twitter'><span className='icon icon-no-bird' /></a>
                    <a href='https://www.facebook.com/aitex.es' title='Facebook'><span className='icon icon-facebook' /></a>
                    <a href='https://www.instagram.com/aitex_es/' title='Instagram'><span className='icon icon-instagram' /></a>
                    <a href='https://www.youtube.com/user/aitex1' title='YouTube'><span className='icon icon-youtube' /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>}
        <div className='container mt3'>
          {this.state.hasError
            ? <div className='meta-container'>
              <div className='p-3 align-items-center' style={{textAlign: 'center', fontSize: 'x-large'}}>
                <span>{i18n.t('enums.certificateError.CERTIFICATE_NOT_FOUND')}</span>
              </div>
            </div>
            : <>
              <div className='meta-container'>
                <div className='row p-3 align-items-end'>
                  <div className='col-12 col-md-4'>
                    <div className='meta-list_item ml-auto pt-3 pb-1 d-flex flex-sm-row flex-column align-items-center'>
                      <span className='meta-label mr-2 mb-1'>{i18n.t('report.client')}</span>
                      <span>{this.state.companyName}</span>
                    </div>
                  </div>
                  <div className='col-12 col-md-4'>
                    <div className='meta-list_item ml-auto pt-3 pb-1 d-flex flex-sm-row flex-column align-items-center'>
                      <span className='meta-label mr-2 mb-1'>{i18n.t('certificate.identificationCode')}</span>
                      <span>{this.state.code}</span>
                    </div>
                  </div>
                  <div className='col-12 col-md-4'>
                    <div className='meta-list_item ml-auto pt-3 pb-1 d-flex flex-sm-row flex-column align-items-center'>
                      <span className='meta-label mr-2 mb-1'>{i18n.t('certificate.certificateType')}</span>
                      <span title={this.state.certificateTypeValue ? certificateTypeUtils.getLocalizedText(i18n, this.state.certificateTypeValue) : ''} className={'certificateType ' + (this.state.certificateTypeValue ? certificateTypeUtils.getIconCss(this.state.certificateTypeValue) : '')} >&nbsp;{this.state.certificateTypeValue ? certificateTypeUtils.getLocalizedText(i18n, this.state.certificateTypeValue) : ''}</span>
                    </div>
                  </div>
                  <div className='col-12 col-md-4'>
                    <div className='meta-list_item ml-auto pt-3 pb-1 d-flex flex-sm-row flex-column align-items-center'>
                      <span className='meta-label mr-2 mb-1'>{i18n.t('certificate.issueDate')}*</span>
                      {this.state.issueDate && <span>{dateHelpers.toShortDate(this.state.issueDate, true)}</span>}
                    </div>
                  </div>
                  <div className='col-12 col-md-4'>
                    <div className='meta-list_item ml-auto pt-3 pb-1 d-flex flex-sm-row flex-column align-items-center'>
                      <span className='meta-label mr-2 mb-1'>{i18n.t('certificate.expirationDate')}*</span>
                      {this.state.expirationDate && <span>{dateHelpers.toShortDate(this.state.expirationDate, true)}</span>}
                    </div>
                  </div>
                  <div className='col-12 col-md-4'>
                    <div className='meta-list_item ml-auto pt-3 pb-1 d-flex flex-sm-row flex-column align-items-center'>
                      <span className='meta-label mr-2 mb-1'>{i18n.t('report.requerimentStandard')}</span>
                      {this.state.offerRequerimentStandard && <span>{this.state.offerRequerimentStandard}</span>}
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-12'>
                  <div className='meta-list_item ml-auto pt-3 pb-1 d-flex flex-sm-row flex-column align-items-center'>
                    <span className='meta-label mr-2 mb-1'>{i18n.t('enums.reportDetailTab.references')}:</span>
                    {this.state.referenceNames && <span className='references'>{(this.state.referenceNames.endsWith(',') ? this.state.referenceNames.substring(0, this.state.referenceNames.length - 1) : this.state.referenceNames)}</span>}
                  </div>
                </div>
              </div>
              <span className='formatDate'>*{i18n.t('date.format')}: {/*DATE_MOMENT_FORMAT*/ moment.localeData().longDateFormat('L')}</span>
            </>}
        </div>
      </>
    );
  }
}

export default compose(
  withRouter,
  withTranslation()
)(SharedCertificate) as ComponentType;
