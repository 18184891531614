import axios from 'axios';

import { URL } from '../common/constants';
import { ISettings } from '../common/model/settings.model';

export let settings: ISettings = null;

const getUrl = window.location;

export const loadSettings = async (): Promise<ISettings> => {
  const baseUrl = `${getUrl.protocol}//${getUrl.host}/`;

  const results = await axios.get<ISettings>(`${baseUrl}${URL.WEB.BASE}${URL.WEB.SETTINGS}`);
  settings = results.data;
  return results.data;
};

export const fetch = async (): Promise<ISettings> => {
  const baseUrl = `${getUrl.protocol}//${getUrl.host}/`;
  return (await axios.get<ISettings>(`${baseUrl}${URL.WEB.BASE}${URL.WEB.SETTINGS}`)).data;
};
