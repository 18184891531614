import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import { ISettingsState } from '../interfaces/states/ISettingsState';
import { settingsStore } from './modules/settings.store';
import { FiltersState, filtersStore } from './modules/filters';
import { UserState, userStore } from './modules/user';
import { ICurrentUserState } from '../interfaces/states/ICurrentUserState';
import { currentUserStore } from './modules/currentUser.store';
import { IDialogsState } from '../interfaces/states/IDialogsState';
import { dialogsStore } from './modules/dialogs.store';
import { PageState, pageStore } from './modules/page';
import { IReportsListState } from '../interfaces/states/IReportsListState';
import { reportsListStore } from './modules/reportsList.store';
import { IReportDetailState } from '../interfaces/states/IReportDetailState';
import { reportDetailStore } from './modules/reportDetail.store';
import { ICertificatesListState } from '../interfaces/states/ICertificatesListState';
import { certificatesListStore } from './modules/certificatesList.store';
import { IRequestsListState } from '../interfaces/states/IRequestsListState';
import { requestsListStore } from './modules/requestsList.store';
import { IRequestDetailState } from '../interfaces/states/IRequestDetailState';
import { requestDetailStore } from './modules/requestDetail.store';
import { INotificationsListState, INotificationsListNotificationsState, INotificationsListNotificationsViewStatusState } from '../interfaces/states/INotificationsListState';
import { notificationsListStore, notificationsViewStatusListStore } from './modules/notificationsList.store';
import { notificationsListNotificationsStore } from './modules/notificationsListNotifications.store';
import { notificationsListNotificationsViewStatusStore } from './modules/notificationsListNotificationsViewStatus.store';
import { INotificationDetailState } from '../interfaces/states/INotificationDetailState';
import { notificationDetailStore } from './modules/notificationDetail.store';
import { INotificationsSidebarState } from '../interfaces/states/INotificationsSidebarState';
import { notificationsSidebarStore } from './modules/notificationsSidebar.store';
import { IClientsListState } from '../interfaces/states/IClientsListState';
import { clientsListStore } from './modules/clientsList.store';
import { IClientDetailState } from '../interfaces/states/IClientDetailState';
import { clientDetailStore } from './modules/clientDetail.store';
import { IChainsListState } from '../interfaces/states/IChainsListState';
import { chainsListStore } from './modules/chainsList.store';
import { IMeasurementUnitsListState } from '../interfaces/states/IMeasurementUnitsListState';
import { measurementUnitsListStore } from './modules/measurementUnitsList.store';
import { ISuppliersListState } from '../interfaces/states/ISuppliersListState';
import { suppliersListStore } from './modules/suppliersList.store';
import { ISupplierDetailState } from '../interfaces/states/ISupplierDetailState';
import { supplierDetailStore } from './modules/supplierDetail.store';
import { IMenuState } from '../interfaces/states/IMenuState';
import { menuStore } from './modules/menu.store';
import { saveItem } from '../utils/localStorageManagement';
import { IUnecosListState } from '../interfaces/states/IUnecosListState';
import { unecosListStore } from './modules/unecosList.store';
import { IOfficesListState } from '../interfaces/states/IOfficesListState';
import { officesListStore } from './modules/officesList.store';
import { IEmailsNotReceivedListState } from '../interfaces/states/IEmailsNotReceivedListState';
import { emailsNotReceivedListStore } from './modules/emailsNotReceivedList.store';
import { IUserSettingsState } from '../interfaces/states/IUserSettingState';
import { userSettingStore } from './modules/userSetting.store';

export interface RootState {
  settingsStore: ISettingsState;
  filtersStore: FiltersState;
  userStore: UserState;
  currentUserStore: ICurrentUserState;
  dialogsStore: IDialogsState;
  pageStore: PageState;
  reportsListStore: IReportsListState;
  reportDetailStore: IReportDetailState;
  certificatesListStore: ICertificatesListState;
  requestsListStore: IRequestsListState;
  requestDetailStore: IRequestDetailState;
  notificationsListStore: INotificationsListState;
  notificationsViewStatusListStore: INotificationsListState;
  notificationsListNotificationsStore: INotificationsListNotificationsState;
  notificationsListNotificationsViewStatusStore: INotificationsListNotificationsViewStatusState;
  notificationDetailStore: INotificationDetailState;
  notificationsSidebarStore: INotificationsSidebarState;
  clientsListStore: IClientsListState;
  clientDetailStore: IClientDetailState;
  chainsListStore: IChainsListState;
  measurementUnitsListStore: IMeasurementUnitsListState;
  suppliersListStore: ISuppliersListState;
  supplierDetailStore: ISupplierDetailState;
  menuStore: IMenuState;
  unecosListStore: IUnecosListState;
  officesListStore: IOfficesListState;
  emailsNotReceivedListStore: IEmailsNotReceivedListState;
  userSettingStore: IUserSettingsState;
}

const rootStore = combineReducers<RootState>({
  settingsStore,
  filtersStore,
  userStore,
  currentUserStore,
  dialogsStore,
  pageStore,
  reportsListStore,
  reportDetailStore,
  certificatesListStore,
  requestsListStore,
  requestDetailStore,
  notificationsListStore,
  notificationsViewStatusListStore,
  notificationsListNotificationsStore,
  notificationsListNotificationsViewStatusStore,
  notificationDetailStore,
  notificationsSidebarStore,
  clientsListStore,
  clientDetailStore,
  chainsListStore,
  measurementUnitsListStore,
  suppliersListStore,
  supplierDetailStore,
  menuStore,
  unecosListStore,
  officesListStore,
  emailsNotReceivedListStore,
  userSettingStore
});

const store = createStore(rootStore, applyMiddleware(...[thunk]));

const stateKey = 'state';
store.subscribe(() => {
  const state = store.getState();
  if (state.currentUserStore.user !== null) {
    saveItem(stateKey, state);
  }
});

export default store;
