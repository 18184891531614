import moment from 'moment';

import { IReportWithoutCustomFields, IReport, IReportWithFlattenedCustomFields } from '../common/model/report.model';
import { IReportCustomFields } from '../common/model/reportCustomFields.model';
//import { ReportStatus } from '../common/model/enumerations/reportStatus.model';

const initDatesCommon = (report: IReportWithoutCustomFields): IReportWithoutCustomFields => {
  return {
    ...report,
    reference: report.reference ? report.reference.split(',').filter((r) => r.trim().length > 0).join(',') : report.reference,
    entryDate: report.entryDate ? moment(report.entryDate) : report.entryDate,
    expectedDate: report.expectedDate ? moment(report.expectedDate) : report.expectedDate,
    endDate: report.endDate ? moment(report.endDate) : report.endDate,
    expirationDate: report.expirationDate ? moment(report.expirationDate) : report.expirationDate,
    advanceDate: report.advanceDate ? moment(report.advanceDate) : report.advanceDate
  };
};

export const initDates = (report: IReport): IReport => {
  let customFields: IReportCustomFields = null;
  if (report.customFields) {
    customFields = {
      ...report.customFields,
      qualityControlDate: report.customFields.qualityControlDate ? moment(report.customFields.qualityControlDate) : report.customFields.qualityControlDate,
      buyerDate: report.customFields.buyerDate ? moment(report.customFields.buyerDate) : report.customFields.buyerDate,
      validationDate: report.customFields.validationDate ? moment(report.customFields.validationDate) : report.customFields.validationDate
    };
  }
  return {
    ...initDatesCommon(report),
    customFields
  };
};

export const initDatesFlattened = (report: IReportWithFlattenedCustomFields): IReportWithFlattenedCustomFields => {
  return {
    ...report,
    ...initDatesCommon(report),
    qualityControlDate: report.qualityControlDate ? moment(report.qualityControlDate) : report.qualityControlDate,
    buyerDate: report.buyerDate ? moment(report.buyerDate) : report.buyerDate,
    validationDate: report.validationDate ? moment(report.validationDate) : report.validationDate
  };
};

export const canRequestAdvance = (report: IReport | IReportWithFlattenedCustomFields): boolean => {
  /*const now = moment();
  const tomorrowEntryDate = report.entryDate ? report.entryDate.clone() : null;
  if (tomorrowEntryDate) {
    tomorrowEntryDate.add(1, 'd');
  }

  const showRequestAdvanceButton = report.reportNumber &&
    report.resultIsVisible &&
    report.status === ReportStatus.IN_PROGRESS &&
    tomorrowEntryDate &&
    now >= tomorrowEntryDate &&
    (!report.advanceDate || now.diff(moment(report.advanceDate), 'hours') >= 5)

    && report.published;

  return showRequestAdvanceButton;*/

  return report.canRequestAdvance;
};
