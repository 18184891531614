import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { DialogComponent } from '@aitex/tsx-extranet-dialogs';

import { IDeactivateEntityDialogProps } from '../../../interfaces/props/IDialogsProps';
import { IErrors } from '../../../common/model/errors.model';
import * as dialogUtils from '../../../utils/dialog.utils';

type DeactivateEntityDialogPropsType = IDeactivateEntityDialogProps & WithTranslation;

type DeactivateEntityDialogStateType = IDeactivateEntityDialogState;

class DeactivateEntityDialog extends Component<DeactivateEntityDialogPropsType, DeactivateEntityDialogStateType> {
  private readonly CANCEL_BUTTON_ID = 'deactivateEntityDialogCancel';
  private readonly OK_BUTTON_ID = 'deactivateEntityDialogOk';

  private readonly CANNOT_DEACTIVATE_ENTITY_ERROR: IErrors = { UnknownError: ['dialogs.deactivateEntityDialog.error'] };

  private initialState: IDeactivateEntityDialogState = {
    isLoading: false
  };

  public constructor(props: DeactivateEntityDialogPropsType) {
    super(props);

    this.state = this.initialState;
  }

  public componentDidUpdate(prevProps: DeactivateEntityDialogPropsType) {
    const { visible } = this.props;

    if (prevProps.visible && !visible) {
      this.setState(this.initialState);
    }

    dialogUtils.manageButtonsClick(this.OK_BUTTON_ID, () => this.deactivateEntity(), this.CANCEL_BUTTON_ID, () => this.dismiss());
  }

  public async deactivateEntity() {
    const { entityId, deactivate } = this.props;

    try {
      this.setState({ isLoading: true });

      if (!this.props.entityId) {
        throw this.CANNOT_DEACTIVATE_ENTITY_ERROR;
      }

      await deactivate(entityId);
      this.close();
    } catch (error) {
      console.log(error);
      let errors = error as IErrors;
      if (errors.UnknownError || (!errors.UnknownError && !errors.ControlledError)) {
        errors = this.CANNOT_DEACTIVATE_ENTITY_ERROR;
      }
      this.dismiss(errors);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  public close() {
    this.props.onClose();
  }

  public dismiss(reason?: IErrors) {
    this.props.onDismiss(reason);
  }

  public render() {
    const { visible, entityName, i18n } = this.props;

    return (
      <DialogComponent
        header={i18n.t('dialogs.deactivateEntityDialog.title', { entityName })}
        visible={visible}
        footerTemplate={dialogUtils.computeFooterTemplate(
          this.OK_BUTTON_ID,
          i18n.t('actions.ok'),
          this.CANCEL_BUTTON_ID,
          i18n.t('actions.cancel'),
          this.state.isLoading
        )}
        onDismiss={() => this.dismiss()}
      >
        <div>{i18n.t('dialogs.deactivateEntityDialog.text', { entityName })}</div>
      </DialogComponent>
    );
  }
}

interface IDeactivateEntityDialogState {
  isLoading: boolean;
}

export default withTranslation()(DeactivateEntityDialog);
