import { URL } from '../common/constants';
import * as services from '.';

interface IPostData {
  userEnteredCaptchaCode: string;
  captchaId: string;
}
export interface IcaptchaService {
  validateCaptcha(postData: IPostData): Promise<any>;
}

class CaptchaService implements IcaptchaService {
  public constructor(private httpClient: services.IHttpClient) {

  }

  public async validateCaptcha(postData: IPostData) {
    const validatedCaptcha = await this.httpClient.post(`${services.settings.web}${URL.WEB.BASE}${URL.WEB.CAPTCHA}`, postData);
    return validatedCaptcha.data;
  }
}

export const captchaService = new CaptchaService(services.httpClient);
