import { Action, Reducer, ActionCreator, combineReducers } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { RootState } from '..';
import { INotificationsSidebarState } from '../../interfaces/states/INotificationsSidebarState';
import { INotificationWithViewInfo } from '../../common/model/notification.model';
import { INotificationsService } from '../../services';

enum Type {
  RESET = '@@notificationsSidebar/RESET',
  SET_NOTIFICATIONS = '@@notificationsSidebar/SET_NOTIFICATIONS',
  MARK_NOTIFICATION_AS_SEEN_LOCAL = '@@notificationsSidebar/MARK_NOTIFICATION_AS_SEEN_LOCAL',
  SET_VIEW_ALL = '@@notificationsSidebar/SET_VIEW_ALL',
  SET_IS_LOADING = '@@notificationsSidebar/SET_IS_LOADING'
}

const initialState: INotificationsSidebarState = {
  notifications: [],
  viewAll: true,
  isLoading: false
};

// Actions

export type Actions = ResetAction | SetNotificationsAction | MarkNotificationAsSeenLocalAction | SetViewAllAction | SetIsLoadingAction;

export type ResetAction = Action<Type.RESET>

export interface SetNotificationsAction extends Action<Type.SET_NOTIFICATIONS> {
  notifications: INotificationWithViewInfo[];
}

export interface MarkNotificationAsSeenLocalAction extends Action<Type.MARK_NOTIFICATION_AS_SEEN_LOCAL> {
  notificationId: string;
}

export interface SetViewAllAction extends Action<Type.SET_VIEW_ALL> {
  viewAll: boolean;
}

export interface SetIsLoadingAction extends Action<Type.SET_IS_LOADING> {
  isLoading: boolean;
}

export const reset: ActionCreator<ResetAction> = () => ({
  type: Type.RESET
});

export const setNotifications: ActionCreator<SetNotificationsAction> = (notificationsLocal: INotificationWithViewInfo[]) => ({
  type: Type.SET_NOTIFICATIONS,
  notifications: notificationsLocal
});

export const markNotificationAsSeenLocal: ActionCreator<MarkNotificationAsSeenLocalAction> = (notificationId: string) => ({
  type: Type.MARK_NOTIFICATION_AS_SEEN_LOCAL,
  notificationId
});

export const setViewAll: ActionCreator<SetViewAllAction> = (viewAllLocal: boolean) => ({
  type: Type.SET_VIEW_ALL,
  viewAll: viewAllLocal
});

export const setIsLoading: ActionCreator<SetIsLoadingAction> = (isLoadingLocal: boolean) => ({
  type: Type.SET_IS_LOADING,
  isLoading: isLoadingLocal
});

export const fetchNotifications: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (notificationsService: INotificationsService) => (
  async (dispatch, getState) => {
    dispatch(setIsLoading(true));

    const notificationsLocal = await notificationsService.fetchMine(getState().notificationsSidebarStore.viewAll);

    dispatch(setNotifications(notificationsLocal));
    dispatch(setIsLoading(false));
  }
);

export const markNotificationAsSeen: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (notificationsService: INotificationsService, notificationId: string) => (
  async (dispatch) => {
    try {
      dispatch(setIsLoading(true));

      await notificationsService.markAsSeen(notificationId);
      dispatch(markNotificationAsSeenLocal(notificationId));
    } catch (error) {
      // TODO: manejar error
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

// Reducers

const notifications: Reducer<INotificationWithViewInfo[]> = (state = initialState.notifications, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.notifications;
    case Type.SET_NOTIFICATIONS:
      return (action as SetNotificationsAction).notifications;
    case Type.MARK_NOTIFICATION_AS_SEEN_LOCAL:
      return state.map((notification) => {
        if (notification.id === (action as MarkNotificationAsSeenLocalAction).notificationId) {
          return {
            ...notification,
            seen: true
          };
        }
        return notification;
      });
    default:
      return state;
  }
};

const viewAll: Reducer<boolean> = (state = initialState.viewAll, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.viewAll;
    case Type.SET_VIEW_ALL:
      return (action as SetViewAllAction).viewAll;
    default:
      return state;
  }
};

const isLoading: Reducer<boolean> = (state = initialState.isLoading, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.isLoading;
    case Type.SET_IS_LOADING:
      return (action as SetIsLoadingAction).isLoading;
    default:
      return state;
  }
};

export const notificationsSidebarStore = combineReducers({ notifications, viewAll, isLoading });
