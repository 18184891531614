import i18next from 'i18next';

import { ServiceStatus } from '../common/model/enumerations/serviceStatus.model';

export const getServiceStatusById = (statusId: string): ServiceStatus => {
  switch (statusId.toUpperCase()) {
    case '001DA5B3-D488-4B42-AC0F-6B709769D387':
      return ServiceStatus.ANALYZED;
    case '2F445AD8-A2E7-4171-B5ED-A3C3064C07CC':
      return ServiceStatus.PENDING;
    case '161B0B80-0008-498A-B562-E16549E1DEFA':
      return ServiceStatus.CANCELLED;
  }
};

export const getLocalizedText = (i18n: i18next.i18n, status: string): string => {
  return i18n.t(`enums.serviceStatus.${status.toUpperCase()}`);
};

export const getLocalizedTextById = (i18n: i18next.i18n, statusId: string): string => {
  let status: string;
  switch (statusId.toUpperCase()) {
    case '001DA5B3-D488-4B42-AC0F-6B709769D387':
      status = ServiceStatus.ANALYZED;
      break;
    case '2F445AD8-A2E7-4171-B5ED-A3C3064C07CC':
      status = ServiceStatus.PENDING;
      break;
    case '161B0B80-0008-498A-B562-E16549E1DEFA':
      status = ServiceStatus.CANCELLED;
      break;
  }

  return getLocalizedText(i18n, status);
};

export const getIconCss = (status: string): string => {
  switch (status) {
    case ServiceStatus.ANALYZED:
      return 'icon icon-report-state-finalized';
    case ServiceStatus.PENDING:
      return 'icon icon-state-in-progress';
    case ServiceStatus.CANCELLED:
      return 'icon icon-app-item-cancel';
    default:
      return '';
  }
};
