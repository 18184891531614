import moment from 'moment';

import { IRequestWithoutCustomFields, IRequest, IRequestWithFlattenedCustomFields } from '../common/model/request.model';
import { IRequestCustomFields } from '../common/model/requestCustomFields.model';

const initDatesCommon = (request: IRequestWithoutCustomFields): IRequestWithoutCustomFields => {
  return {
    ...request,
    createdDate: request.createdDate ? moment(request.createdDate) : request.createdDate,
    sampleDate: request.sampleDate ? moment(request.sampleDate) : request.sampleDate,
    sendDate: request.sendDate ? moment(request.sendDate) : request.sendDate
  };
};

export const initDates = (request: IRequest): IRequest => {
  let customFields: IRequestCustomFields = null;
  if (request.customFields) {
    customFields = {
      ...request.customFields,
      requiredDate: request.customFields.requiredDate ? moment(request.customFields.requiredDate) : request.customFields.requiredDate
    };
  }
  return {
    ...initDatesCommon(request),
    customFields
  };
};

export const initDatesFlattened = (request: IRequestWithFlattenedCustomFields): IRequestWithFlattenedCustomFields => {
  return {
    ...request,
    ...initDatesCommon(request),
    requiredDate: request.requiredDate ? moment(request.requiredDate) : request.requiredDate
  };
};
