export enum RuleOperator {
  STARTS_WITH = 'startswith',
  ENDS_WITH = 'endswith',
  CONTAINS = 'contains',
  IS_EMPTY = 'isempty',
  IS_NOT_EMPTY = 'isnotempty',
  EQUAL = 'equal',
  NOT_EQUAL = 'notequal',
  GREATER_THAN = 'greaterthan',
  GREATER_THAN_OR_EQUAL = 'greaterthanorequal',
  LESS_THAN = 'lessthan',
  LESS_THAN_OR_EQUAL = 'lessthanorequal',
  BETWEEN = 'between',
  NOT_BETWEEN = 'notbetween',
  IN = 'in',
  NOT_IN = 'notin',
  TODAY = 'today',
  GREATER_THAN_TODAY = 'greaterthantoday',
  GREATER_THAN_OR_EQUAL_TODAY = 'greaterthanorequaltoday',
  LESS_THAN_TODAY = 'lessthantoday',
  LESS_THAN_OR_EQUAL_TODAY = 'lessthanorequaltoday'
}
