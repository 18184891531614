import React, { Component, ComponentType } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { UnregisterCallback } from 'history';
import { Tabs, Tab } from 'react-bootstrap';
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, Page, Sort } from '@syncfusion/ej2-react-grids';
import { DataManager } from '@syncfusion/ej2-data';
import queryString from 'query-string';
import { dialogsService } from '@aitex/tsx-extranet-dialogs';
import { NotificationsAccordionComponent } from '@aitex/tsx-extranet-notifications-accordion';

import { RootState } from '../../store';
import { IReportDetailContainerStateProps, IReportDetailContainerDispatchProps } from '../../interfaces/props/IReportDetailProps';
import { IReportDetailContainerRouteParams } from '../../interfaces/routeParams/IReportDetailRouteParams';
import * as reportDetailStore from '../../store/modules/reportDetail.store';
import * as notificationsSidebarStore from '../../store/modules/notificationsSidebar.store';
import * as dialogsStore from '../../store/modules/dialogs.store';
import { IReport } from '../../common/model/report.model';
import { IReportCustomFields } from '../../common/model/reportCustomFields.model';
import { IAnnex } from '../../common/model/annex.model';
import { ICertificate } from '../../common/model/certificate.model';
import { IErrors } from '../../common/model/errors.model';
import { ReportDetailTab } from '../../common/model/enumerations/reportDetailTab.model';
import { SourceType } from '../../common/model/enumerations/sourceType.model';
import { DialogId } from '../../common/model/enumerations/dialogId.model';
import { UserType } from '../../common/model/enumerations/userType.model';
import { servicesService, certificatesService, reportsService, annexesService, notificationsService, notesService } from '../../services';
import * as pageModules from '../../store/modules/page';
import withPageTracking from '../../hoc/withPageTracking';
import * as currentUserUtils from '../../utils/currentUser.utils';
import { notifyError, notifySuccess } from '../../utils/toast.utils';
import * as serviceStatusUtils from '../../utils/serviceStatus.utils';
import * as dateHelpers from '../../helpers/date.helpers';
import InfoCard from './infoCard/infoCard';
import CertificatesTab from './tabs/certificatesTab/certificatesTab';
import ReferencesTab from '../../components/tabs/referencesTab/referencesTab';
import ReportChangesHistoryTab from './tabs/reportChangesHistoryTab/reportChangesHistoryTab';
import AnnexesTab from '../../components/tabs/annexesTab/annexesTab';
import NotesTab from '../../components/tabs/notesTab/notesTab';

type ReportDetailContainerPropsType = PropsFromRedux & RouteComponentProps<IReportDetailContainerRouteParams> & WithTranslation;

type ReportDetailContainerStateType = IReportDetailContainerState;

class ReportDetailContainer extends Component<ReportDetailContainerPropsType, ReportDetailContainerStateType> {
  public annexesGridComponent: GridComponent = null;
  public certificatesGridComponent: GridComponent = null;

  public data: DataManager;

  private unregisterCallback: UnregisterCallback = null;

  private initialState: ReportDetailContainerStateType = {
    selectedTab: ReportDetailTab.SERVICES,
    isSaving: false,
    services: null,
    userNotificationSeen: false
  }

  public constructor(props: ReportDetailContainerPropsType) {
    super(props);

    props.reset();

    const newState = this.initialState;
    const parsedUrl = queryString.parse(this.props.location.search);
    if (parsedUrl.tab) {
      const selectedTab: string = parsedUrl.tab as string;
      if (Object.values(ReportDetailTab).includes(selectedTab as ReportDetailTab)) {
        newState.selectedTab = selectedTab;
      }
    }

    this.state = newState;
  }

  public componentDidMount() {
    this.init();
  }

  public componentDidUpdate(prevProps: ReportDetailContainerPropsType) {
    if (!this.props.isReadyForRendering && prevProps.isReadyForRendering) {
      this.init();
    }

    if (this.props.isReadyForRendering && prevProps.location.pathname !== this.props.location.pathname) {
      const isView = this.computeIsView();
      if (isView !== this.props.isView) {
        this.props.setIsView(isView);
      }
      if (this.props.report.id && this.props.report.id !== this.props.match.params.id) {
        this.props.reset();
      }
    }

    if (!this.state.userNotificationSeen) {
      let currentNotificationId: string = null;
      if (this.props.match.params.notificationId) {
        currentNotificationId = this.props.match.params.notificationId;
      } else {
        const parsedUrl = queryString.parse(this.props.location.search);
        if (parsedUrl.notificationId) {
          currentNotificationId = parsedUrl.notificationId as string;
        }
      }

      if (currentNotificationId !== undefined && currentNotificationId !== null && currentNotificationId.trim() !== '') {
        this.props.markNotificationAsSeenLocal(currentNotificationId);
        this.props.markNotificationAsSeen(notificationsService, currentNotificationId);

        this.setState({ userNotificationSeen: true });
      }
    }

    if (!prevProps.report && this.props.report) {
      this.getData();
      if (!this.props.isCurrentUserInternal && this.state.selectedTab !== ReportDetailTab.NOTIFICATIONS) {
        this.fetchNotifications();
      }
    }

    if (prevProps.isView !== this.props.isView && this.props.isView === false) {
      this.unregisterCallback = this.props.history.block((blocker) => {
        dialogsService.openDialog(DialogId.DISCARD_CHANGES)
          .then(() => {
            if (this.unregisterCallback) {
              this.unregisterCallback();
              this.unregisterCallback = null;
            }
            this.props.resetReport();
            setTimeout(() => {
              if (blocker.pathname.includes('/report')) {
                this.props.history.replace(blocker.pathname);
              } else {
                this.props.history.push(blocker.pathname);
              }
            });
          })
          .catch(() => {
            // DO nothing
          });
        return false;
      });

      if (!this.hasPermissionsToEdit()) {
        this.cancel();
      }

      if (this.props.report && this.props.report.customFields && typeof this.props.report.customFields.automaticGeneration !== 'boolean') {
        this.onCustomFieldsChange({ ...this.props.report.customFields, automaticGeneration: true });
      }
    }

    if (this.props.isUnauthorized) {
      this.props.history.push('/reports-list/all');
    }
  }

  public componentWillUnmount() {
    const { isReadyForRendering, report, isCurrentUserInternal, reset, cleanPageInfo, displayCompany } = this.props;

    if (isReadyForRendering) {
      reset();
    }

    cleanPageInfo();
    if (report && isCurrentUserInternal) {
      displayCompany(report.clientId);
    }
  }

  public onCustomFieldsChange(customFields: IReportCustomFields) {
    this.props.setReportCustomFields(customFields);
  }

  public edit() {
    this.props.history.replace(this.props.location.pathname.replace('/view', '/edit'));
  }

  public async save() {
    const { report, i18n } = this.props;

    this.setState({ isSaving: true });
    await this.saveCommon(report, i18n.t('reportDetail.messages.saveSuccess'), i18n.t('reportDetail.errors.cannotSaveReport'));
    this.setState({ isSaving: false });
  }

  public async close() {
    const { report, i18n } = this.props;

    const reportToSave = { ...report, customFields: { ...report.customFields, closed: true } };
    await this.saveCommon(reportToSave, i18n.t('reportDetail.messages.closeSuccess'), i18n.t('reportDetail.errors.cannotCloseReport'));
  }

  public async cancelReport(buyerId: string) {
    const { report, i18n } = this.props;

    const reportToSave = { ...report, customFields: { ...report.customFields, buyerId } };
    await this.saveCommon(reportToSave, i18n.t('reportDetail.messages.cancelReportSuccess'), i18n.t('reportDetail.errors.cannotCancelReport'));
  }

  public cancel() {
    this.props.history.replace(this.props.location.pathname.replace('/edit', '/view'));
  }

  public async downloadReport() {
    try {
      if (this.certificatesGridComponent) {
        this.certificatesGridComponent.showSpinner();
      }

      await reportsService.downloadFile(this.props.report);
    } catch (error) {
      notifyError(this.props.i18n.t('reportDetail.errors.cannotDownloadReport'));
    } finally {
      if (this.certificatesGridComponent) {
        this.certificatesGridComponent.hideSpinner();
      }
    }
  }

  public sendEmail() {
    const { report, prepareSendReportEmailDialog, i18n } = this.props;

    prepareSendReportEmailDialog(report);
    dialogsService.openDialog(DialogId.SEND_REPORT_EMAIL)
      .then(() => notifySuccess(i18n.t('reportDetail.messages.sendEmailSuccess')))
      .catch((reason?: IErrors) => {
        this.manageDialogDismissed(reason);
      });
  }

  public uploadAnnex() {
    dialogsService.openDialog(DialogId.UPLOAD_ANNEX)
      .then(() => this.fetchAnnexes())
      .catch((reason?: IErrors) => {
        this.manageDialogDismissed(reason);
      });
  }

  public async downloadAnnex(annex: IAnnex) {
    try {
      if (this.annexesGridComponent) {
        this.annexesGridComponent.showSpinner();
      }

      await annexesService.downloadFile(annex.id, annex.description, annex.annexedCategoryValue);
    } catch (error) {
      notifyError(this.props.i18n.t('reportDetail.errors.cannotDownloadAnnex'));
    } finally {
      if (this.annexesGridComponent) {
        this.annexesGridComponent.hideSpinner();
      }
    }
  }

  public editAnnexState(annex: IAnnex) {
    this.props.prepareEditAnnexStateDialog(annex);
    dialogsService.openDialog(DialogId.EDIT_ANNEX_STATE)
      .then(() => this.fetchAnnexes())
      .catch((reason?: IErrors) => {
        this.manageDialogDismissed(reason);
      });
  }

  public editAnnex(annex: IAnnex) {
    this.props.prepareEditAnnexDialog(annex);
    dialogsService.openDialog(DialogId.EDIT_ANNEX)
      .then(() => this.fetchAnnexes())
      .catch((reason?: IErrors) => {
        this.manageDialogDismissed(reason);
      });
  }

  public deactivateAnnex(annexId: string) {
    this.deactivateEntity(
      annexId,
      this.props.i18n.t('dialogs.deactivateEntityDialog.entityName.annex'),
      (entityId: string) => annexesService.deactivate(entityId),
      () => this.fetchAnnexes()
    );
  }

  public async downloadCertificate(certificate: ICertificate) {
    try {
      if (this.certificatesGridComponent) {
        this.certificatesGridComponent.showSpinner();
      }

      await certificatesService.downloadFile(certificate);
    } catch (error) {
      notifyError(this.props.i18n.t('reportDetail.errors.cannotDownloadCertificate'));
    } finally {
      if (this.certificatesGridComponent) {
        this.certificatesGridComponent.hideSpinner();
      }
    }
  }

  public prepareShareCertificate(certificate: ICertificate): void {
    const { prepareShareCertificateEmailDialog } = this.props;
    const manageDialogDismissed = (reason: IErrors) => this.manageDialogDismissed(reason);

    prepareShareCertificateEmailDialog(certificate);
    dialogsService.openDialog(DialogId.SHARE_CERTIFICATE_EMAIL)
      //.then(() => )
      .catch((reason?: IErrors) => {
        manageDialogDismissed(reason);
      });
  }

  public getPendingNotificationsCount(): number {
    return this.props.notifications.filter((notification) => notification.seen === false).length;
  }

  public onNotificationExpanded(id: string, seen: boolean) {
    const { isInSimulationMode, markNotificationAsSeenLocal, markNotificationAsSeen } = this.props;

    if (!isInSimulationMode && !seen) {
      markNotificationAsSeenLocal(id);
      markNotificationAsSeen(notificationsService, id);
    }
  }

  public createNote() {
    dialogsService.openDialog(DialogId.CREATE_NOTE)
      .then(() => this.fetchNotes())
      .catch((reason?: IErrors) => {
        this.manageDialogDismissed(reason);
      });
  }

  public deactivateNote(noteId: string) {
    this.deactivateEntity(
      noteId,
      this.props.i18n.t('dialogs.deactivateEntityDialog.entityName.note'),
      (entityId: string) => notesService.deactivate(entityId),
      () => this.fetchNotes()
    );
  }

  public getStateServiceColumnTemplate(service: any): JSX.Element {
    const { i18n } = this.props;
    const status = serviceStatusUtils.getServiceStatusById(service.serviceStateId);

    return <span title={serviceStatusUtils.getLocalizedText(i18n, status)} className={serviceStatusUtils.getIconCss(status)} />;
  }

  public render() {
    const {
      isReadyForRendering,
      report,
      isView,
      customFieldPermissions,
      qualityControlOptions,
      buyerOptions,
      annexes,
      certificates,
      references,
      isFetchingReferences,
      notifications,
      notes,
      reportChangesHistory,
      isCurrentUserInternal,
      currentUserId,
      currentUserEmail,
      isEciUser,
      i18n
    } = this.props;

    if (!isReadyForRendering) {
      return null;
    }

    if (!report) {
      return null; // TODO: loading
    }

    const pendingNotifications = this.getPendingNotificationsCount();

    return (
      <div className='row'>
        <div className='col'>
          <InfoCard
            report={report}
            isView={isView}
            customFieldPermissions={customFieldPermissions}
            qualityControlOptions={qualityControlOptions}
            buyerOptions={buyerOptions}
            isSaving={this.state.isSaving}
            isCurrentUserInternal={isCurrentUserInternal}
            currentUserEmail={currentUserEmail}
            isEciUser={isEciUser}
            onCustomFieldsChange={(customFields) => this.onCustomFieldsChange(customFields)}
            onEditButtonClick={() => this.edit()}
            onSaveButtonClick={() => this.save()}
            onCancelButtonClick={() => this.cancel()}
            onCloseButtonClick={() => this.close()}
            onCancelReportButtonClick={(buyerId) => this.cancelReport(buyerId)}
            onDownloadButtonClick={() => this.downloadReport()}
            onSendEmailButtonClick={() => this.sendEmail()}
          />
          <Tabs
            activeKey={this.state.selectedTab}
            onSelect={(selectedTab: string) => {
              this.setState({ selectedTab }, () => this.getData());
            }}
            id='detail-tabs'
            className='mb-3'
          >
            <Tab
              eventKey={ReportDetailTab.SERVICES}
              title={(
                <div className='d-flex align-items-center'>
                  <span className='icon icon-service mr-2' />
                  <span>{i18n.t(`enums.reportDetailTab.${ReportDetailTab.SERVICES}`)}</span>
                </div>
              )}
            >
              <GridComponent
                dataSource={this.state.services}
                allowSorting={true}
                allowPaging={true}
                allowResizing={true}
                locale={i18n.language}
                height='100%'
                width='100%'
                sortSettings={{ columns: [{ field: 'order', direction: 'Ascending' }] }}
              >
                <ColumnsDirective>
                  <ColumnDirective
                    headerText={i18n.t('detail.table.servicesHeaders.description')}
                    field='description'
                    disableHtmlEncode={false}
                  />
                  {/* <ColumnDirective
                    headerText={i18n.t('detail.table.servicesHeaders.referenceName')}
                    field='referenceName'
                    disableHtmlEncode={false}
                  /> */}
                  <ColumnDirective
                    headerText={i18n.t('detail.table.servicesHeaders.standardName')}
                    field='standardName'
                    disableHtmlEncode={false}
                  />
                  <ColumnDirective
                    headerText={i18n.t('detail.table.servicesHeaders.state')}
                    field='state'
                    template={(service: any) => this.getStateServiceColumnTemplate(service)}
                  />
                  <ColumnDirective
                    headerText={'order'}
                    field='order'
                    template={(service: any) => service.order}
                    visible={false}
                  />
                </ColumnsDirective>
                <Inject services={[Sort, Page]} />
              </GridComponent>
            </Tab>
            <Tab
              eventKey={ReportDetailTab.ANNEXES}
              title={(
                <div className='d-flex align-items-center'>
                  <span className='icon icon-annex mr-2' />
                  <span>{i18n.t(`enums.reportDetailTab.${ReportDetailTab.ANNEXES}`)}</span>
                </div>
              )}
            >
              <AnnexesTab
                annexes={annexes}
                sourceType={SourceType.REPORT}
                isCurrentUserInternal={isCurrentUserInternal}
                currentUserId={currentUserId}
                gridRef={(grid) => this.annexesGridComponent = grid}
                onUploadAnnexButtonClick={() => this.uploadAnnex()}
                onDownloadAnnexButtonClick={(annex) => this.downloadAnnex(annex)}
                onEditAnnexStateButtonClick={(annex) => this.editAnnexState(annex)}
                onEditAnnexButtonClick={(annex) => this.editAnnex(annex)}
                onDeactivateAnnexButtonClick={(annexId) => this.deactivateAnnex(annexId)}
              />
            </Tab>
            <Tab
              eventKey={ReportDetailTab.CERTIFICATES}
              title={(
                <div className='d-flex align-items-center'>
                  <span className='icon icon-certificate mr-2' />
                  <span>{i18n.t(`enums.reportDetailTab.${ReportDetailTab.CERTIFICATES}`)}</span>
                </div>
              )}
            >
              <CertificatesTab
                buttonsAreVisible={report.resultIsVisible && report.published}
                certificates={certificates}
                gridRef={(grid) => this.certificatesGridComponent = grid}
                onDownloadCertificateButtonClick={(certificate) => this.downloadCertificate(certificate)}
                onShareCertificateButtonClick={(certificate) => this.prepareShareCertificate(certificate)}
              />
            </Tab>
            <Tab
              eventKey={ReportDetailTab.REFERENCES}
              title={(
                <div className='d-flex align-items-center'>
                  <span className='icon icon-reference mr-2' />
                  <span>{i18n.t(`enums.reportDetailTab.${ReportDetailTab.REFERENCES}`)}</span>
                </div>
              )}
            >
              <ReferencesTab
                references={references}
                isFetching={isFetchingReferences}
              />
            </Tab>
            <Tab
              eventKey={ReportDetailTab.NOTIFICATIONS}
              title={(
                <div className='d-flex align-items-center'>
                  <span className='icon icon-notification mr-2' />
                  <span>{i18n.t(`enums.reportDetailTab.${ReportDetailTab.NOTIFICATIONS}`)}</span>
                  {pendingNotifications > 0 && <div className='ml-2 e-badge e-badge-primary'>{pendingNotifications}</div>}
                </div>
              )}
            >
              <NotificationsAccordionComponent
                notifications={notifications.map((n) => ({ ...n, createdDate: dateHelpers.toShortDate(n.createdDate, true) }))}
                onExpanded={(notification) => this.onNotificationExpanded(notification.id, notification.seen)}
              />
            </Tab>
            <Tab
              eventKey={ReportDetailTab.NOTES}
              title={(
                <div className='d-flex align-items-center'>
                  <span className='icon icon-comment mr-2' />
                  <span>{i18n.t(`enums.reportDetailTab.${ReportDetailTab.NOTES}`)}</span>
                </div>
              )}
            >
              <NotesTab
                notes={notes}
                currentUserId={currentUserId}
                onCreateNoteButtonClick={() => this.createNote()}
                onDeactivateNoteButtonClick={(noteId) => this.deactivateNote(noteId)}
              />
            </Tab>
            {isCurrentUserInternal && <Tab
              eventKey={ReportDetailTab.REPORT_CHANGES_HISTORY}
              title={(
                <div className='d-flex align-items-center'>
                  <span className='icon icon-report-change-history mr-2' />
                  <span>{i18n.t(`enums.reportDetailTab.${ReportDetailTab.REPORT_CHANGES_HISTORY}`)}</span>
                </div>
              )}
            >
              <ReportChangesHistoryTab
                reportChangesHistory={reportChangesHistory}
              />
            </Tab>}
          </Tabs>
        </div>
      </div>
    );
  }

  private computeIsView(): boolean {
    return this.props.location.pathname.includes('/view');
  }

  private init() {
    const { makeReadyForRendering, fetchReport, location, match } = this.props;

    const isView = this.computeIsView();
    makeReadyForRendering(isView);
    fetchReport(match.params.id, reportsService);

    const parsedUrl = queryString.parse(location.search);
    if (parsedUrl.tab && parsedUrl.tab !== this.state.selectedTab) {
      this.setState({ selectedTab: parsedUrl.tab as string });
    }
  }

  private hasPermissionsToEdit(): boolean {
    return this.props.customFieldPermissions.some((p) => p.canWrite);
  }

  private async saveCommon(report: IReport, successMessage: string, errorMessage: string) {
    await dialogsService.openDialog(DialogId.GENERIC_CONFIRMATION)
      .then(async () => {
        try {
          const updatedReport = await reportsService.updateCustomFields(report.id, report.customFields);
          notifySuccess(successMessage);

          this.props.saveCustomFields(updatedReport.customFields);

          if (this.unregisterCallback) {
            this.unregisterCallback();
          }
          this.cancel();
        } catch (error) {
          notifyError(errorMessage);
        }
      })
      .catch(() => {
        // DO nothing
      });
  }

  private async getData() {
    const reportId = this.props.match.params.id;

    if (this.state.selectedTab === ReportDetailTab.SERVICES) {
      const services = await servicesService.getServicesByReportId(reportId);
      this.setState({ services });
    } else if (this.state.selectedTab === ReportDetailTab.ANNEXES) {
      this.fetchAnnexes();
    } else if (this.state.selectedTab === ReportDetailTab.CERTIFICATES) {
      this.fetchCertificates();
    } else if (this.state.selectedTab === ReportDetailTab.REFERENCES) {
      this.fetchReferences();
    } else if (this.state.selectedTab === ReportDetailTab.NOTIFICATIONS) {
      this.fetchNotifications();
    } else if (this.state.selectedTab === ReportDetailTab.NOTES) {
      this.fetchNotes();
    } else if (this.state.selectedTab === ReportDetailTab.REPORT_CHANGES_HISTORY) {
      this.fetchReportChangesHistory();
    }
  }

  private fetchAnnexes() {
    const { fetchAnnexes, match } = this.props;

    fetchAnnexes(match.params.id, reportsService);
  }

  private fetchCertificates() {
    const { fetchCertificates, match } = this.props;

    fetchCertificates(match.params.id, reportsService);
  }

  private fetchReferences() {
    const { fetchReferences, match } = this.props;

    fetchReferences(match.params.id, reportsService);
  }

  private fetchNotifications() {
    const { fetchNotifications, match } = this.props;

    fetchNotifications(match.params.id, reportsService);
  }

  private fetchNotes() {
    const { fetchNotes, match } = this.props;

    fetchNotes(match.params.id, reportsService);
  }

  private fetchReportChangesHistory() {
    const { fetchReportChangesHistory, match } = this.props;

    fetchReportChangesHistory(match.params.id, reportsService);
  }

  private deactivateEntity(
    entityId: string,
    entityName: string,
    deactivate: (entityId: string) => Promise<void>,
    successCallback: () => void
  ) {
    this.props.prepareDeactivateEntityDialog(entityId, entityName, deactivate);
    dialogsService.openDialog(DialogId.DEACTIVATE_ENTITY)
      .then(() => successCallback())
      .catch((reason?: IErrors) => {
        this.manageDialogDismissed(reason);
      });
  }

  private manageDialogDismissed(reason?: IErrors) {
    if (reason) {
      if (reason.ControlledError) {
        notifyError(reason.ControlledError[0]);
      }
      if (reason.UnknownError) {
        notifyError(this.props.i18n.t(reason.UnknownError[0]));
      }
    }
  }
}

const mapStateToProps = (state: RootState): IReportDetailContainerStateProps => ({
  isReadyForRendering: state.reportDetailStore.isReadyForRendering,
  report: state.reportDetailStore.report,
  isView: state.reportDetailStore.isView,
  customFieldPermissions: state.reportDetailStore.customFieldPermissions,
  qualityControlOptions: state.reportDetailStore.qualityControlOptions,
  buyerOptions: state.reportDetailStore.buyerOptions,
  annexes: state.reportDetailStore.annexes,
  certificates: state.reportDetailStore.certificates,
  references: state.reportDetailStore.references,
  isFetchingReferences: state.reportDetailStore.isFetchingReferences,
  notifications: state.reportDetailStore.notifications,
  notes: state.reportDetailStore.notes,
  reportChangesHistory: state.reportDetailStore.reportChangesHistory,
  isCurrentUserInternal: state.currentUserStore.user.type === UserType.INTERNAL,
  currentUserId: state.currentUserStore.user.id,
  currentUserEmail: state.currentUserStore.user.email,
  isInSimulationMode: state.currentUserStore.jwtInfo.isInSimulationMode === 'true',
  isEciUser: currentUserUtils.isEciUser(state.currentUserStore.company, currentUserUtils.getCurrentUserChainName(state.currentUserStore.user, state.currentUserStore.jwtInfo)),
  isUnauthorized: state.reportDetailStore.isUnauthorized
});

const mapDispatchToProps: IReportDetailContainerDispatchProps = {
  reset: reportDetailStore.reset,
  makeReadyForRendering: reportDetailStore.makeReadyForRendering,
  setReportCustomFields: reportDetailStore.setReportCustomFields,
  setIsView: reportDetailStore.setIsView,
  markNotificationAsSeenLocal: reportDetailStore.markNotificationAsSeenLocal,
  fetchReport: reportDetailStore.fetchReport,
  resetReport: reportDetailStore.resetReport,
  saveCustomFields: reportDetailStore.saveCustomFields,
  fetchAnnexes: reportDetailStore.fetchAnnexes,
  fetchCertificates: reportDetailStore.fetchCertificates,
  fetchReferences: reportDetailStore.fetchReferences,
  fetchNotifications: reportDetailStore.fetchNotifications,
  fetchNotes: reportDetailStore.fetchNotes,
  fetchReportChangesHistory: reportDetailStore.fetchReportChangesHistory,
  markNotificationAsSeen: notificationsSidebarStore.markNotificationAsSeen,
  displayPageName: pageModules.displayPageName,
  displayReport: pageModules.displayReport,
  displayCompany: pageModules.displayCompany,
  cleanPageInfo: pageModules.cleanPageInfo,
  prepareSendReportEmailDialog: dialogsStore.prepareSendReportEmailDialog,
  prepareEditAnnexStateDialog: dialogsStore.prepareEditAnnexStateDialog,
  prepareEditAnnexDialog: dialogsStore.prepareEditAnnexDialog,
  prepareDeactivateEntityDialog: dialogsStore.prepareDeactivateEntityDialog,
  prepareShareCertificateEmailDialog: dialogsStore.prepareShareCertificateEmailDialog
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface IReportDetailContainerState {
  selectedTab: string;
  isSaving: boolean;
  services: any[];
  userNotificationSeen: boolean;
}

export default compose(
  withRouter,
  withTranslation(),
  connector,
  withPageTracking,
)(ReportDetailContainer) as ComponentType;
