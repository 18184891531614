import React, { Component, ComponentType } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { compose } from 'redux';
import { Tabs, Tab } from 'react-bootstrap';
import { DataManager, Query } from '@syncfusion/ej2-data';
import { SortDirection } from '@syncfusion/ej2-grids';

import { RootState } from '../../store';
import { IClientsListContainerStateProps, IClientsListContainerDispatchProps } from '../../interfaces/props/IClientsListProps';
import * as clientsListStore from '../../store/modules/clientsList.store';
import * as listGridStore from '../../store/modules/listGrid.store';
import { URL } from '../../common/constants';
import { IMyRule } from '../../common/model/myRule.model';
import { RuleCondition } from '../../common/model/enumerations/ruleCondition.model';
import { RuleOperator } from '../../common/model/enumerations/ruleOperator.model';
import { FilterMode } from '../../common/model/enumerations/filterMode.model';
import { queryBuilderService, oDataService, dataManagerService } from '../../services';
import ClientsListFilter from './clientsListFilter/clientsListFilter';
import ClientsGrid from './clientsGrid/clientsGrid';

type ClientsListContainerPropsType = PropsFromRedux & RouteComponentProps & WithTranslation;

class ClientsListContainer extends Component<ClientsListContainerPropsType> {
  public clientsDataManager: DataManager = null;
  public clientsQuery: Query = new Query().select(['id', 'code', 'name', 'city', 'province', 'country']);

  public constructor(props: ClientsListContainerPropsType) {
    super(props);

    const { reset } = this.props;

    this.clientsDataManager = dataManagerService.buildDataManager(URL.ODATA.COMPANY_FILTERS);

    reset();
  }

  public componentWillUnmount() {
    this.props.reset();
  }

  public onFilterButtonClick() {
    this.props.setCurrentPage(clientsListStore.Type.SET_CURRENT_PAGE, 1);
    this.fetchClients();
  }

  public onCurrentPageChange(currentPage: number) {
    this.props.setCurrentPage(clientsListStore.Type.SET_CURRENT_PAGE, currentPage);
    this.fetchClients();
  }

  public onPageSizeChange(pageSize: number) {
    this.props.setPageSize(clientsListStore.Type.SET_PAGE_SIZE, pageSize);
    this.fetchClients();
  }

  public onSortingChange(field: string, direction: SortDirection) {
    this.props.setSorting(clientsListStore.Type.SET_SORTING, field, direction);
    this.fetchClients();
  }

  public render() {
    const { clients, filter, isFetching, searchDate, currentPage, pageSize, totalCount, setFilterCode, setFilterName, sortField, sortDirection, i18n } = this.props;

    return (
      <div>
        <div className='row'>
          <div className='col'>
            <h2>{i18n.t('clientsList.title')}</h2>
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <div className='mb-3'>
              <Tabs id='clientsListTabs' className='mb-3'>
                <Tab eventKey={FilterMode.SIMPLE} title={i18n.t('enums.filterMode.SIMPLE')}>
                  <ClientsListFilter
                    filter={filter}
                    isFetching={isFetching}
                    onCodeChange={setFilterCode}
                    onNameChange={setFilterName}
                    onFilterButtonClick={() => this.onFilterButtonClick()}
                  />
                </Tab>
              </Tabs>
            </div>
            <ClientsGrid
              clients={clients}
              isFetching={isFetching}
              searchDate={searchDate}
              currentPage={currentPage}
              pageSize={pageSize}
              totalCount={totalCount}
              sortField={sortField}
              sortDirection={sortDirection}
              onCurrentPageChange={(cp) => this.onCurrentPageChange(cp)}
              onPageSizeChange={(ps) => this.onPageSizeChange(ps)}
              onSortingChange={(field, direction) => this.onSortingChange(field, direction)}
            />
          </div>
        </div>
      </div>
    );
  }

  private fetchClients() {
    const { isFetching, fetchClients } = this.props;

    if (!isFetching) {
      fetchClients(oDataService, this.clientsDataManager, this.buildQuery());
    }
  }

  private buildQuery(): Query {
    const { filter } = this.props;

    const myRule: IMyRule = {
      condition: RuleCondition.AND,
      myRules: []
    };
    if (filter.code) {
      myRule.myRules.push({
        field: 'code',
        operator: RuleOperator.CONTAINS,
        value: filter.code,
        ignoreCase: true
      });
    }
    if (filter.name) {
      myRule.myRules.push({
        field: 'name',
        operator: RuleOperator.CONTAINS,
        value: filter.name,
        ignoreCase: true
      });
    }

    return queryBuilderService.buildQuery(this.clientsDataManager, myRule, this.clientsQuery.clone());
  }
}

const mapStateToProps = (state: RootState): IClientsListContainerStateProps => ({
  clients: state.clientsListStore.clients,
  filter: state.clientsListStore.filter,
  isFetching: state.clientsListStore.grid.isFetching,
  searchDate: state.clientsListStore.grid.searchDate,
  currentPage: state.clientsListStore.grid.currentPage,
  pageSize: state.clientsListStore.grid.pageSize,
  totalCount: state.clientsListStore.grid.totalCount,
  sortField: state.clientsListStore.grid.sortField,
  sortDirection: state.clientsListStore.grid.sortDirection
});

const mapDispatchToProps: IClientsListContainerDispatchProps = {
  reset: clientsListStore.reset,
  setFilterCode: clientsListStore.setFilterCode,
  setFilterName: clientsListStore.setFilterName,
  setCurrentPage: listGridStore.setCurrentPage,
  setPageSize: listGridStore.setPageSize,
  setSorting: listGridStore.setSorting,
  fetchClients: clientsListStore.fetchClients
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default compose(
  withRouter,
  withTranslation(),
  connector
)(ClientsListContainer) as ComponentType;
