import { URL } from '../common/constants';
import { ICustomFieldSettings } from '../common/model/customFieldSettings.model';
import * as services from '.';

export interface IReportCustomFieldSettingsService {
  create(customFieldSettings: ICustomFieldSettings): Promise<ICustomFieldSettings>;
  update(customFieldSettings: ICustomFieldSettings): Promise<ICustomFieldSettings>;
  fetchCodes(): Promise<string[]>;
}

class ReportCustomFieldSettingsService implements IReportCustomFieldSettingsService {
  public constructor(private httpClient: services.IHttpClient) {

  }

  public create(customFieldSettings: ICustomFieldSettings): Promise<ICustomFieldSettings> {
    return this.httpClient.postCustom(`${URL.WEB.BASE}${URL.WEB.REPORT_CUSTOM_FIELD_SETTINGS}`, customFieldSettings);
  }

  public update(customFieldSettings: ICustomFieldSettings): Promise<ICustomFieldSettings> {
    return this.httpClient.putCustom(`${URL.WEB.BASE}${URL.WEB.REPORT_CUSTOM_FIELD_SETTINGS}/${customFieldSettings.id}`, customFieldSettings);
  }

  public async fetchCodes(): Promise<string[]> {
    try {
      return await this.httpClient.getCustom(`${URL.WEB.BASE}${URL.WEB.REPORT_CUSTOM_FIELD_SETTINGS}/codes`);
    } catch (error) {
      return [];
    }
  }
}

export const reportCustomFieldSettingsService = new ReportCustomFieldSettingsService(services.httpClient);
