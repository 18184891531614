import i18next from 'i18next';

export const getLocalizedYesNoIndiferentText = (i18n: i18next.i18n, value: string): string => {
  return i18n.t(`enums.checkBoxState.YesNoIndeferent_${value}`);
};

export const getLocalizedTrueFalseIndiferentText = (i18n: i18next.i18n, value: string): string => {
  return i18n.t(`enums.checkBoxState.TrueFalseIndeferent_${value}`);
};

export const getIconCss = (value: number): string => {
  switch (value) {
    case -1:
      return 'icon icon-request-acceptance-pending';
    case 0:
      return 'icon icon-request-acceptance-ko';
    case 1:
      return 'icon icon-request-acceptance-ok';
    default:
      return '';
  }
};

export const convertIntToBoolean = (value: number): boolean =>  {
  switch (value) {
    case 0:
      return false;
    case 1:
      return true;
    default:
      return null;
  }
}

export const convertBooleanToInt = (value: boolean): number => {
  switch (value) {
    case false:
      return 0;
    case true:
      return 1;
    default:
      return -1;
  }
}
