// tslint:disable: no-bitwise
import { ICustomFieldSettings, IRequestCustomFieldSettingsWithCheckboxes } from '../common/model/customFieldSettings.model';
import { RequestCustomFieldSettingsOption } from '../common/model/enumerations/requestCustomFieldSettingsOption.model';

export const toWithCheckboxes = (customFieldSettings: ICustomFieldSettings): IRequestCustomFieldSettingsWithCheckboxes => {
  return {
    ...customFieldSettings,
    listAll: (customFieldSettings.settings & RequestCustomFieldSettingsOption.LIST_ALL) !== 0,
    listOpen: (customFieldSettings.settings & RequestCustomFieldSettingsOption.LIST_OPEN) !== 0,
    listInProgress: (customFieldSettings.settings & RequestCustomFieldSettingsOption.LIST_IN_PROGRESS) !== 0,
    filterAll: (customFieldSettings.settings & RequestCustomFieldSettingsOption.FILTER_ALL) !== 0,
    filterOpen: (customFieldSettings.settings & RequestCustomFieldSettingsOption.FILTER_OPEN) !== 0,
    filterInProgress: (customFieldSettings.settings & RequestCustomFieldSettingsOption.FILTER_IN_PROGRESS) !== 0,
    form: (customFieldSettings.settings & RequestCustomFieldSettingsOption.FORM) !== 0
  };
};

export const fromWithCheckboxes = (customFieldSettingsWithCheckboxes: IRequestCustomFieldSettingsWithCheckboxes): ICustomFieldSettings => {
  let settings = 0;
  if (customFieldSettingsWithCheckboxes.listAll) {
    settings += RequestCustomFieldSettingsOption.LIST_ALL;
  }
  if (customFieldSettingsWithCheckboxes.listOpen) {
    settings += RequestCustomFieldSettingsOption.LIST_OPEN;
  }
  if (customFieldSettingsWithCheckboxes.listInProgress) {
    settings += RequestCustomFieldSettingsOption.LIST_IN_PROGRESS;
  }
  if (customFieldSettingsWithCheckboxes.filterAll) {
    settings += RequestCustomFieldSettingsOption.FILTER_ALL;
  }
  if (customFieldSettingsWithCheckboxes.filterOpen) {
    settings += RequestCustomFieldSettingsOption.FILTER_OPEN;
  }
  if (customFieldSettingsWithCheckboxes.filterInProgress) {
    settings += RequestCustomFieldSettingsOption.FILTER_IN_PROGRESS;
  }
  if (customFieldSettingsWithCheckboxes.form) {
    settings += RequestCustomFieldSettingsOption.FORM;
  }

  return {
    id: customFieldSettingsWithCheckboxes.id,
    customFieldCode: customFieldSettingsWithCheckboxes.customFieldCode,
    settings,
    sourceType: customFieldSettingsWithCheckboxes.sourceType,
    companyId: customFieldSettingsWithCheckboxes.companyId
  };
};
