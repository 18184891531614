import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
  GridComponent,
  ToolbarItems,
  PageSettingsModel,
  EditSettingsModel,
  ActionEventArgs,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Sort,
  Page,
  Toolbar,
  Resize,
  Edit,
  ColumnModel
} from '@syncfusion/ej2-react-grids';
import { DataManager, Query } from '@syncfusion/ej2-data';
import { DropDownListModel } from '@syncfusion/ej2-dropdowns';
import { DialogComponent } from '@aitex/tsx-extranet-dialogs';

import { IEditCompanySupplierPurchaseProductsDialogProps } from '../../../interfaces/props/IDialogsProps';
import { ISupplierProduct } from '../../../common/model/supplierProduct.model';
import { RuleOperator } from '../../../common/model/enumerations/ruleOperator.model';
import { ProductType } from '../../../common/model/enumerations/productType.model';
import * as dialogUtils from '../../../utils/dialog.utils';
import * as gridUtils from '../../../utils/grid.utils';
import * as pagerUtils from '../../../utils/pager.utils';
import './editCompanySupplierPurchaseProductsDialog.scss';

type EditCompanySupplierPurchaseProductsDialogPropsType = IEditCompanySupplierPurchaseProductsDialogProps & WithTranslation;

class EditCompanySupplierPurchaseProductsDialog extends Component<EditCompanySupplierPurchaseProductsDialogPropsType> {
  public readonly ACTIVE_DEFAULT_VALUE = true;

  public gridComponent: GridComponent = null;
  public toolbarOptions: ToolbarItems[] = ['Add', 'Edit', 'Update', 'Cancel'];
  public pageSettings: PageSettingsModel = {
    pageSize: pagerUtils.getMaxPageSize(),
    pageCount: pagerUtils.getPageCount(),
    pageSizes: pagerUtils.getPageSizes()
  };
  public editSettings: EditSettingsModel = { allowEditing: true, allowEditOnDblClick: false, allowAdding: true, newRowPosition: 'Top' };

  private readonly CLOSE_BUTTON_ID = 'editCompanySupplierPurchaseProductsDialogClose';

  public componentDidUpdate(prevProps: EditCompanySupplierPurchaseProductsDialogPropsType) {
    gridUtils.manageSpinner(this.gridComponent, prevProps.isFetching, this.props.isFetching);
    gridUtils.manageDataSource(this.gridComponent, () => this.updateGridComponentDataSource(), prevProps.isFetching, this.props.isFetching, prevProps.visible, this.props.visible);

    dialogUtils.manageButtonsClick(null, null, this.CLOSE_BUTTON_ID, () => this.props.onDismiss());
  }

  public updateGridComponentDataSource() {
    this.gridComponent.dataSource = new DataManager(JSON.parse(JSON.stringify(this.props.supplierProducts))); // Clone by value
  }

  public onActionBegin(args: ActionEventArgs) {
    if (args.requestType === 'add' || args.requestType === 'beginEdit') {
      ((this.gridComponent.columns as ColumnModel[])[0].edit.params as DropDownListModel).query = new Query()
        .select(['id', 'name'])
        .where('active', RuleOperator.EQUAL, true)
        .where('type', RuleOperator.EQUAL, ProductType.PURCHASE)
        .where('companyId', RuleOperator.EQUAL, this.props.companyId)
        .take(10);
    }
  }

  public onActionComplete(args: ActionEventArgs) {
    const { supplierId, onSaveSupplierProduct } = this.props;

    if (args.requestType === 'save') {
      const data = args.data as any;
      const companySupplier: ISupplierProduct = {
        ...data,
        productId: data.productName ? data.productName.id : null,
        productName: data.productName ? data.productName.name : null,
        supplierId
      };
      setTimeout(() => onSaveSupplierProduct(companySupplier));
    }
  }

  public render() {
    const { visible, purchaseProductsParams, onDismiss, i18n } = this.props;

    return (
      <DialogComponent
        header={i18n.t('dialogs.editCompanySupplierPurchaseProductsDialog.title')}
        visible={visible}
        width='60%'
        footerTemplate={dialogUtils.computeFooterTemplate(
          null,
          null,
          this.CLOSE_BUTTON_ID,
          i18n.t('actions.close')
        )}
        onDismiss={() => onDismiss()}
      >
        <div className='edit-company-supplier-purhcase-products-grid'>
          <GridComponent
            toolbar={this.toolbarOptions}
            allowSorting={true}
            allowPaging={true}
            allowResizing={true}
            allowFiltering={true}
            pageSettings={this.pageSettings}
            editSettings={this.editSettings}
            locale={i18n.language}
            ref={(grid) => this.gridComponent = grid}
            created={() => this.updateGridComponentDataSource()}
            actionBegin={(args) => this.onActionBegin(args as ActionEventArgs)}
            actionComplete={(args) => this.onActionComplete(args as ActionEventArgs)}
          >
            <ColumnsDirective>
              <ColumnDirective
                field='productName'
                headerText={i18n.t('supplierProduct.purchaseProduct')}
                width='200'
                type='string'
                editType='dropdownedit'
                edit={purchaseProductsParams}
                validationRules={{ required: true }}
              />
              <ColumnDirective
                field='active'
                headerText={i18n.t('supplierProduct.active')}
                width='100'
                type='boolean'
                defaultValue={this.ACTIVE_DEFAULT_VALUE + ''}
                editType='booleanedit'
                displayAsCheckBox={true}
              />
            </ColumnsDirective>
            <Inject services={[Sort, Page, Toolbar, Resize, Edit]} />
          </GridComponent>
        </div>
      </DialogComponent>
    );
  }
}

export default withTranslation()(EditCompanySupplierPurchaseProductsDialog);
