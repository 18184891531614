import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { DataManager, Query, Predicate } from '@syncfusion/ej2-data';
import { SortOrder } from '@syncfusion/ej2-lists';
import { NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DatePickerComponent, ChangedEventArgs } from '@syncfusion/ej2-react-calendars';
import { DropDownListComponent, MultiSelectComponent, Inject as DropdownInject, CheckBoxSelection, FieldSettingsModel, FilteringEventArgs, ChangeEventArgs, MultiSelectChangeEventArgs } from '@syncfusion/ej2-react-dropdowns';
import { SwitchComponent } from '@syncfusion/ej2-react-buttons';
import { Button } from 'react-bootstrap';
import moment from 'moment';
import { ActionBarComponent, IButton } from '@aitex/tsx-extranet-action-bar';
import { FormValidator, FormEventArgs } from '@syncfusion/ej2-inputs';
import * as formValidatorUtils from '../../../utils/formValidator.utils';
import FormError from '../../../components/formError/formError';
import { IErrors } from '../../../common/model/errors.model';
import { Validator } from '../../../common/model/enumerations/validator.model';
import ValidationError from '../../../components/validationError/validationError';
import { IInfoCardProps } from '../../../interfaces/props/IRequestDetailProps';
import { URL } from '../../../common/constants';
import { ICustomFieldPermission } from '../../../common/model/customFieldPermission.model';
import { IUser } from '../../../common/model/user.model';
import { IManagerGroup } from '../../../common/model/managerGroup.model';
import { RequestStatus } from '../../../common/model/enumerations/requestStatus.model';
import { RequestAcceptanceStatus } from '../../../common/model/enumerations/requestAcceptanceStatus.model';
import { RuleCondition } from '../../../common/model/enumerations/ruleCondition.model';
import { RuleOperator } from '../../../common/model/enumerations/ruleOperator.model';
import { dataManagerService, queryBuilderService } from '../../../services';
import * as requestStatusUtils from '../../../utils/requestStatus.utils';
import * as requestAcceptanceStatusUtils from '../../../utils/requestAcceptanceStatus.utils';
import * as customFieldPermissionUtils from '../../../utils/customFieldPermission.utils';
import { enumToArray } from '../../../utils/enum.utils';
import { debounce } from '../../../utils/common.utils';
import * as dateHelpers from '../../../helpers/date.helpers';
import TextBox from '../../../components/filters/textBox';
import './infoCard.scss';

type InfoCardPropsType = IInfoCardProps & WithTranslation;

type InfoCardStateType = IInfoCardState;

class ClientDataTab extends Component<InfoCardPropsType, InfoCardStateType> {
  public readonly REQUEST_DETAIL_FORM_ID = 'requestDetailForm';

  public statusDropDownListComponent: DropDownListComponent = null;
  public statusOptions: Record<string, string>[] = [];
  public statusFields: FieldSettingsModel = { text: 'text', value: 'value', iconCss: 'iconCss' };

  public acceptanceStatusDropDownListComponent: DropDownListComponent = null;
  public acceptanceStatusOptions: Record<string, string | number>[] = [];
  public acceptanceStatusFields: FieldSettingsModel = { text: 'text', value: 'value', iconCss: 'iconCss' };

  public usersDropDownListComponent: DropDownListComponent = null;
  public usersDataManager: DataManager = null;
  public usersQuery = new Query()
    .select(['users'])
    .expand('users')
    .where('id', RuleOperator.EQUAL, this.props.request.managerGroupId);
  public usersFields: any = {
    value: 'id',
    text: 'fullName',
    itemCreated: (e: any) => {
      if (e.curData.disabled !== undefined && e.curData.disabled != null && e.curData.disabled === true) {
        e.item.classList.add('e-disabled');
        e.item.classList.add('e-overlay');
      }
    }
  };
  public usersSortOrder: SortOrder = 'Ascending';

  public managerGroupsDropDownListComponent: DropDownListComponent = null;
  public managerGroupsDataManager: DataManager = null;
  public managerGroupsQuery = new Query()
    .select(['id', 'value'])
    .where('active', RuleOperator.EQUAL, true);
  public managerGroupsFields: FieldSettingsModel = { value: 'id', text: 'value' };
  public managerGroupsSortOrder: SortOrder = 'Ascending';
  public managerGroupsFilteringEvent: FilteringEventArgs = null;
  public managerGroupsFilteringDebounced = debounce(
    () => {
      if (this.managerGroupsFilteringEvent) {
        let query = this.managerGroupsDropDownListComponent.query.clone();
        if (this.managerGroupsFilteringEvent.text.trim().length >= 3) {
          query = queryBuilderService.buildQuery(this.managerGroupsDataManager, {
            condition: RuleCondition.AND,
            myRules: this.managerGroupsFilteringEvent.text.split(' ').filter((t) => !!t.trim()).map((t) => ({
              condition: RuleCondition.OR,
              myRules: [{
                field: 'value',
                operator: RuleOperator.CONTAINS,
                value: t,
                ignoreCase: true
              }]
            }))
          }, query);
        }
        this.managerGroupsDropDownListComponent.filter(this.managerGroupsDropDownListComponent.dataSource, query);
      }
    },
    250
  );

  public delegateManagerGroupsDropDownListComponent: DropDownListComponent = null;
  public delegateManagerGroupsDataManager: DataManager = null;
  public delegateManagerGroupsQuery = new Query()
    .select(['userId', 'firstName', 'lastName'])
    .where('countryId', RuleOperator.EQUAL, this.props.request.countryId)
    .where('managerGroupId', RuleOperator.EQUAL, this.props.request.managerGroupId);
  public delegateManagerGroupsFields: FieldSettingsModel = { value: 'userId', text: 'delegateFullName' };
  public delegateManagerGroupsSortOrder: SortOrder = 'Ascending';
  public delegateManagerGroupsFilteringEvent: FilteringEventArgs = null;
  public delegateManagerGroupsFilteringDebounced = debounce(
    () => {
      if (this.delegateManagerGroupsFilteringEvent) {
        let query = this.delegateManagerGroupsDropDownListComponent.query.clone();
        if (this.delegateManagerGroupsFilteringEvent.text.trim().length >= 3) {
          query = queryBuilderService.buildQuery(this.delegateManagerGroupsDataManager, {
            condition: RuleCondition.AND,
            myRules: this.delegateManagerGroupsFilteringEvent.text.split(' ').filter((t) => !!t.trim()).map((t) => ({
              condition: RuleCondition.OR,
              myRules: [{
                field: 'firstName',
                operator: RuleOperator.CONTAINS,
                value: t,
                ignoreCase: true
              }, {
                field: 'lastName',
                operator: RuleOperator.CONTAINS,
                value: t,
                ignoreCase: true
              }]
            }))
          }, query);
        }
        this.delegateManagerGroupsDropDownListComponent.filter(this.delegateManagerGroupsDropDownListComponent.dataSource, query);
      }
    },
    250
  );

  public applicantCcMultiSelectComponent: MultiSelectComponent = null;
  public applicantCcFields: FieldSettingsModel = { text: 'email', value: 'email' };
  public applicantCcSortOrder: SortOrder = 'Ascending';

  public bidderSortOrder: SortOrder = 'Ascending';

  public sampleDateDatePickerComponent: DatePickerComponent = null;
  public endDateDatePickerComponent: DatePickerComponent = null;
  public requiredDateDatePickerComponent: DatePickerComponent = null;

  public customFieldFields: FieldSettingsModel = { text: 'name', value: 'id' };

  private formValidator: FormValidator = null;
  private isValidatingAll = false;
  private auxErrors: IErrors = {};

  private initialState: InfoCardStateType = {
    acceptButtonLoading: false,
    rejectButtonLoading: false,
    disableManagerUserEmailId: false
  };

  public constructor(props: InfoCardPropsType) {
    super(props);

    this.statusOptions = enumToArray(RequestStatus, 'string', 'text', 'value')
      .filter((item) => item.value !== RequestStatus.ALL)
      .map((item) => ({
        ...item,
        text: requestStatusUtils.getLocalizedText(this.props.i18n, item.value as string),
        iconCss: requestStatusUtils.getIconCss(item.value as string)
      }));

    this.acceptanceStatusOptions = enumToArray(RequestAcceptanceStatus, 'number', 'text', 'value').map((item) => ({
      ...item,
      text: requestAcceptanceStatusUtils.getLocalizedTextByValue(this.props.i18n, item.value as number),
      iconCss: requestAcceptanceStatusUtils.getIconCss(item.value as number)
    }));

    if (!props.isCurrentUserInternal) {
      this.managerGroupsQuery = new Query()
        .select(['id', 'value'])
        .where('active', RuleOperator.EQUAL, true)
        .where('showToExternalUser', RuleOperator.EQUAL, true);
    }

    this.managerGroupsDataManager = dataManagerService.buildDataManager(URL.ODATA.MANAGER_GROUPS_FILTERS);
    this.usersDataManager = dataManagerService.buildDataManager(URL.ODATA.MANAGER_GROUPS_FILTERS);
    this.delegateManagerGroupsDataManager = dataManagerService.buildDataManager(URL.ODATA.DELEGATES_MANAGER_GROUPS_FILTERS);

    this.state = this.initialState;
  }

  public updateStatusDropDownListComponentValueTemplate(): void {
    if (this.statusDropDownListComponent) {
      const selectedStatusOption = this.statusOptions.find((statusOption) => statusOption.value === this.props.request.status);
      if (selectedStatusOption) {
        this.statusDropDownListComponent.valueTemplate = `
          <div class="row no-gutters pl-3 pr-3 h-100">
            <div class="col align-self-center">
              <span class="${`mr-2 ${selectedStatusOption.iconCss}`}" ></span>
              <span>${selectedStatusOption.text}</span>
            </div>
          </div>
        `;
      }
    }
  }

  public updateAcceptanceStatusDropDownListComponentValueTemplate(): void {
    if (this.acceptanceStatusDropDownListComponent) {
      const selectedAcceptanceStatusOption = this.acceptanceStatusOptions.find((acceptanceStatusOption) => acceptanceStatusOption.value === this.props.request.acceptanceStatus);
      if (selectedAcceptanceStatusOption) {
        this.acceptanceStatusDropDownListComponent.valueTemplate = `
          <div class="row no-gutters pl-3 pr-3 h-100">
            <div class="col align-self-center">
              ${selectedAcceptanceStatusOption.iconCss ? `<span class="${`mr-2 ${selectedAcceptanceStatusOption.iconCss}`}" ></span>` : ''}
              <span>${selectedAcceptanceStatusOption.text}</span>
            </div>
          </div>
        `;
      }
    }
  }

  public getCustomFieldPermissionByCode(code: string): ICustomFieldPermission {
    return customFieldPermissionUtils.getCustomFieldPermissionByCode(this.props.customFieldPermissions, code);
  }

  public disableManagerUserEmailId(): boolean {
    return this.props.request.managerGroupId === undefined || this.props.request.managerGroupId === null;
  }

  public disableAutoAssignManagerUserEmailId(): boolean {
    return this.disableManagerUserEmailId() || this.state.disableManagerUserEmailId;
  }

  public reviewDisableAutoAssignManagerUserEmailId(users: IUser[]): void {
    const { userId } = this.props;

    if (users.some((user: IUser) => user.id === userId)) {
      this.setState({ disableManagerUserEmailId: false });
    } else {
      this.setState({ disableManagerUserEmailId: true });
    }
  }

  public reviewCurrentManagerUserEmailId(users: IUser[]): void {
    if (this.usersDropDownListComponent.value !== null && !users.some((user: IUser) => user.id === this.usersDropDownListComponent.value)) {
      this.usersDropDownListComponent.value = null;
    }
  }

  public validateOfferNumber(args: { [key: string]: string }): boolean {
    const navisionRegexExpression = /OF.[2-9]{1}[0-9]{1}-[0-9]{5}$/;
    const limsRegexExpression = /[2-9]{1}[0-9]{1}-[0-9]{6}$/;
    let isValid = true;

    const value = args['value'];
    if (value !== undefined && value !== null && value.trim() !== '') {
      const splitValues = value.split('&');
      splitValues.forEach((splitValue) => {
        if (!isValid) {
          return;
        }

        const newSplitValue = splitValue.trim();
        if (newSplitValue !== '') {
          if (!navisionRegexExpression.test(newSplitValue) && !limsRegexExpression.test(newSplitValue)) {
            isValid = false;
          }
        }
      });
    }

    return isValid;
  }

  public configureFormValidator(): void {
    if (!this.props.isView) {
      if (!this.formValidator) {
        this.formValidator = formValidatorUtils.configureFormValidator(this.REQUEST_DETAIL_FORM_ID, {
          ManagerGroupId: { required: [true, Validator.NOT_EMPTY] },
          Subject: { required: [true, Validator.NOT_EMPTY] }
        });

        if (this.props.isCurrentUserInternal) {
          this.formValidator.addRules('ManagerEmailUser', { required: [true, Validator.NOT_EMPTY] });
        }

        if (!this.props.isNew) {
          this.formValidator.addRules('OfferNumber', { required: [this.validateOfferNumber, Validator.OFFER_NUMBER] });
        }

        this.formValidator.validationComplete = (args): void => {
          formValidatorUtils.validationComplete(args as FormEventArgs, this.isValidatingAll, this.props.errors, this.auxErrors, (errors) => this.props.setErrors(errors));
        };
      }
    } else {
      this.formValidator = null;
    }
  }

  public render(): JSX.Element {
    const {
      request,
      isView,
      isNew,
      isSaving,
      bidderOptions,
      centerOptions,
      unecoOptions,
      delegationOptions,
      isCurrentUserInternal,
      userId,
      onEditButtonClick,
      onSaveButtonClick,
      onCancelButtonClick,
      onAcceptButtonClick,
      onRejectButtonClick,
      onSampleDateChange,
      onStatusChange,
      onOfferNumberChange,
      onAmountChange,
      onManagerGroupIdChange,
      onDelegateUserManagerIdChange,
      onSubjectChange,
      onManagerEmailUserIdChange,
      onAcceptanceStatusChange,
      onOrderChange,
      onSendDateChange,
      onAnalysisToPerformChange,
      onCustomFieldsChange,
      i18n,
      errors,
      applicantCc
    } = this.props;

    const bidderIdPermission = this.getCustomFieldPermissionByCode('bidderId');
    const contestPermission = this.getCustomFieldPermissionByCode('contest');
    const quantityPermission = this.getCustomFieldPermissionByCode('quantity');
    const sampleSealedPermission = this.getCustomFieldPermissionByCode('sampleSealed');
    const returnSurplusSamplePermission = this.getCustomFieldPermissionByCode('returnSurplusSample');
    const requiredDatePermission = this.getCustomFieldPermissionByCode('requiredDate');
    const eciRequestPermission = this.getCustomFieldPermissionByCode('eciRequest');
    const centerIdPermission = this.getCustomFieldPermissionByCode('centerId');
    const unecoIdPermission = this.getCustomFieldPermissionByCode('unecoId');
    const delegationIdPermission = this.getCustomFieldPermissionByCode('delegationId');
    const supplierPermission = this.getCustomFieldPermissionByCode('supplier');

    const actionBarButtons: IButton[] = [];
    if (!isNew && !isCurrentUserInternal) {
      actionBarButtons.push(
      {
        text: i18n.t('requestDetail.actions.accept'),
        iconCss: 'icon icon-check-square-o',
        disabled: isView === false,
        loading: this.state.acceptButtonLoading,
        onClick: () => {
          this.setState({ acceptButtonLoading: true });
          onAcceptButtonClick().finally(() => this.setState({ acceptButtonLoading: false }));
        }
      },
      {
        text: i18n.t('requestDetail.actions.reject'),
        iconCss: 'icon icon-app-item-cancel',
        disabled: isView === false,
        loading: this.state.rejectButtonLoading,
        onClick: () => {
          this.setState({ rejectButtonLoading: true });
          onRejectButtonClick().finally(() => this.setState({ rejectButtonLoading: false }));
        }
      });
    }

    const disableManagerUserEmailId = this.disableManagerUserEmailId();
    const disableAutoAssignManagerUserEmailId = this.disableAutoAssignManagerUserEmailId();
    const reviewDisableAutoAssignManagerUserEmailId = (users: IUser[]) => { this.reviewDisableAutoAssignManagerUserEmailId(users) };
    const reviewCurrentManagerUserEmailId = (users: IUser[]) => { this.reviewCurrentManagerUserEmailId(users) };
    const usersQuery = this.usersQuery;

    return (
      <div className='mb-3'>
        <div className='request-detail-info-card'>
          <form id={this.REQUEST_DETAIL_FORM_ID} ref={(): void => this.configureFormValidator()}>
            <FormError errors={errors}/>
            <div className='row'>
              <div className='col-12 col-md-6'>
                <div className='form-group'>
                  <div className='autocomplete-input'>
                    <div className='autocomplete-label'>{i18n.t('request.managerGroup')}</div>
                    <DropDownListComponent
                      id='managerGroupId'
                      name='ManagerGroupId'
                      query={this.managerGroupsQuery}
                      dataSource={this.managerGroupsDataManager}
                      fields={this.managerGroupsFields}
                      sortOrder={this.managerGroupsSortOrder}
                      allowFiltering={true}
                      delayUpdate={true}
                      showClearButton={true}
                      value={request.managerGroupId}
                      enabled={!isView}
                      ref={(dropDownListComponent: DropDownListComponent): DropDownListComponent => this.managerGroupsDropDownListComponent = dropDownListComponent}
                      change={(e: ChangeEventArgs) => {
                        onManagerGroupIdChange(e.itemData ? (e.itemData as IManagerGroup).id : null);

                        if (this.formValidator) {
                          this.formValidator.validate('ManagerGroupId');

                          if (isCurrentUserInternal) {
                            const query = usersQuery.clone();
                            const currentQuery = query.queries[query.queries.length - 1];
                            query.queries = query.queries.filter((q) => {
                              return q.fn !== "onWhere";
                            });
                            currentQuery.e.value = e.value;
                            query.queries.push(currentQuery);
                            this.usersDropDownListComponent.query = query;
                          }
                        }
                      }}
                      filtering={(e: FilteringEventArgs) => {
                        this.managerGroupsFilteringEvent = e;
                        this.managerGroupsFilteringEvent.cancel = true;
                        this.managerGroupsFilteringDebounced();
                      }}/>
                  </div>
                  <ValidationError errors={errors} errorKey={'ManagerGroupId'}/>
                </div>
              </div>
              <div className='col-12 col-md-6'>
                <div className='form-group'>
                  <div className='autocomplete-input'>
                    <div className='autocomplete-label'>{i18n.t('request.subject')}</div>
                    <TextBox
                      id='subject'
                      name='Subject'
                      value={request.subject}
                      readonly={isView}
                      onChange={(e: string) => {
                        onSubjectChange(e);

                        if (this.formValidator) {
                          this.formValidator.validate('Subject');
                        }
                      }}/>
                  </div>
                  <ValidationError errors={errors} errorKey={'Subject'}/>
                </div>
              </div>
            </div>
            {!isNew && <div className='row'>
              <div className='col-12 col-md-6'>
                <div className='form-group'>
                  <div className='autocomplete-input'>
                    <div className='autocomplete-label'>{i18n.t('request.code')}</div>
                    <TextBox
                      name='code'
                      value={request.code}
                      readonly={true}
                    />
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6'>
                <div className='form-group'>
                  <div className='autocomplete-input'>
                    <div className='autocomplete-label'>{i18n.t('request.createdDate')}</div>
                    <TextBox
                      name='createdDate'
                      value={request.createdDate && dateHelpers.toShortDate(request.createdDate, true)}
                      readonly={true}
                    />
                  </div>
                </div>
              </div>
            </div>}
            <div className='row'>
              {!isNew && <div className='col-12 col-md-6'>
                <div className='form-group'>
                  <div className='autocomplete-input'>
                    <div className='autocomplete-label'>{i18n.t('request.sampleDate')}</div>
                    <DatePickerComponent
                      readonly={isView || !isCurrentUserInternal}
                      locale={i18n.language}
                      ref={(datePicker: DatePickerComponent): DatePickerComponent => this.sampleDateDatePickerComponent = datePicker}
                      created={() => this.sampleDateDatePickerComponent.value = request.sampleDate && request.sampleDate.toDate()}
                      change={(e: ChangedEventArgs) => onSampleDateChange(e.value && moment(e.value).isValid() ? moment(e.value) : null)}
                      firstDayOfWeek={moment().isoWeekday()}
                    />
                  </div>
                </div>
              </div>}
              {!isNew &&<div className='col-12 col-md-6'>
                <div className='form-group'>
                  <div className='autocomplete-input'>
                    <div className='autocomplete-label'>{i18n.t('request.status')}</div>
                    <DropDownListComponent
                      fields={this.statusFields}
                      dataSource={this.statusOptions}
                      value={request.status}
                      enabled={!isView && isCurrentUserInternal}
                      ref={(dropDownListComponent: DropDownListComponent): void => {
                        this.statusDropDownListComponent = dropDownListComponent;
                        this.updateStatusDropDownListComponentValueTemplate();
                      }}
                      change={(e: ChangeEventArgs) => onStatusChange(e.value as string || null)}/>
                  </div>
                </div>
              </div>}
              {(!isNew && isCurrentUserInternal) && <div className='col-12 col-md-6'>
                <div className='form-group'>
                  <div className='autocomplete-input'>
                    <div className='autocomplete-label'>{i18n.t('request.company')}</div>
                    <TextBox
                      name='company'
                      value={request.companyName}
                      readonly={true}
                    />
                  </div>
                </div>
              </div>}
              {!isNew && <div className='col-12 col-md-6'>
                <div className='form-group'>
                  <div className='autocomplete-input'>
                    <div className='autocomplete-label'>{i18n.t('request.offer')}</div>
                    <TextBox
                      id='offerNumber'
                      name='OfferNumber'
                      value={request.offerNumber}
                      readonly={isView || !isCurrentUserInternal}
                      onChange={(e: string) => {
                        onOfferNumberChange(e);

                        if (this.formValidator) {
                          this.formValidator.validate('OfferNumber');
                        }
                      }}
                    />
                  </div>
                  <ValidationError errors={errors} errorKey={'OfferNumber'} />
                </div>
              </div>}
              {(!isNew && request.customFields) && <div className='col-12 col-md-6'>
                <div className='form-group'>
                  <div className='autocomplete-input'>
                    <div className='autocomplete-label'>{i18n.t('requestCustomFields.initialAmount')}</div>
                    <NumericTextBoxComponent
                      name='initialAmount'
                      value={request.customFields.initialAmount}
                      readonly={isView || !isCurrentUserInternal}
                      format='c2'
                      currency='EUR'
                      min={0}
                      locale={i18n.language}
                      change={(e: any) => onCustomFieldsChange({ ...request.customFields, initialAmount: typeof e.value === 'number' ? e.value : null })}
                    />
                  </div>
                </div>
              </div>}
              {!isNew && <div className='col-12 col-md-6'>
                <div className='form-group'>
                  <div className='autocomplete-input'>
                    <div className='autocomplete-label'>{i18n.t('request.amount')}</div>
                    <NumericTextBoxComponent
                      name='amount'
                      value={request.amount}
                      readonly={isView || !isCurrentUserInternal}
                      format='c2'
                      currency='EUR'
                      min={0}
                      locale={i18n.language}
                      change={(e: any) => onAmountChange(typeof e.value === 'number' ? e.value : null)}
                    />
                  </div>
                </div>
              </div>}
              {isCurrentUserInternal && <div className='col-12 col-md-6'>
                <div className='form-group'>
                  <div className='autocomplete-input'>
                    <div className='autocomplete-label'>{i18n.t('request.applicant')}</div>
                    <TextBox
                      name='applicantEmail'
                      value={request.applicantEmail}
                      readonly={true}
                    />
                  </div>
                </div>
              </div>}
              {isCurrentUserInternal && <div className='col-12 col-md-6'>
                <div className='form-group'>
                  <div className='autocomplete-input'>
                    <div className='autocomplete-label'>{i18n.t('request.managerEmailUser')}</div>
                    <DropDownListComponent
                      id='managerEmailUser'
                      name='ManagerEmailUser'
                      query={this.usersQuery}
                      dataSource={this.usersDataManager}
                      fields={this.usersFields}
                      sortOrder={this.usersSortOrder}
                      allowFiltering={false}
                      delayUpdate={true}
                      showClearButton={true}
                      value={request.managerEmailUserId}
                      enabled={!isView && isCurrentUserInternal && !disableManagerUserEmailId}
                      ref={(dropDownListComponent: DropDownListComponent): DropDownListComponent => this.usersDropDownListComponent = dropDownListComponent}
                      actionComplete={(e: any) => {
                        if (Array.isArray(e.result) && e.result.length > 0) {
                          e.result = (e.result[0].users as IUser[]).map((r) => ({ ...r, fullName: `${r.firstName} ${r.lastName}` }));

                          reviewDisableAutoAssignManagerUserEmailId(e.result);
                          reviewCurrentManagerUserEmailId(e.result);
                        }
                      }}
                      change={(e: ChangeEventArgs) => {
                        onManagerEmailUserIdChange(e.value as string);
                        if (this.formValidator) {
                          this.formValidator.validate('ManagerEmailUser');
                        }
                      }}
                    />
                    <Button
                      title={i18n.t('requestDetail.actions.autoAssign')}
                      disabled={isView || disableAutoAssignManagerUserEmailId}
                      variant='secondary'
                      onClick={(e: any) => {
                        e.value = userId;
                        this.usersDropDownListComponent.change(e);
                      }}
                    >
                      <i className='icon icon-user-internal' />
                    </Button>
                  </div>
                  <ValidationError errors={errors} errorKey={'ManagerEmailUser'} />
                </div>
              </div>}
              {!isNew && <div className='col-12 col-md-6'>
                <div className='form-group'>
                  <div className='autocomplete-input'>
                    <div className='autocomplete-label'>{i18n.t('request.acceptanceStatus')}</div>
                    <DropDownListComponent
                      fields={this.acceptanceStatusFields}
                      dataSource={this.acceptanceStatusOptions}
                      value={request.acceptanceStatus}
                      enabled={false}
                      ref={(dropDownListComponent: DropDownListComponent): void => {
                        this.acceptanceStatusDropDownListComponent = dropDownListComponent;
                        this.updateAcceptanceStatusDropDownListComponentValueTemplate();
                      }}
                      change={(e: ChangeEventArgs) => onAcceptanceStatusChange(typeof e.value === 'number' ? e.value : null)}
                    />
                  </div>
                </div>
              </div>}
              <div className='col-12 col-md-6'>
                <div className='form-group'>
                  <div className='autocomplete-input'>
                    <div className='autocomplete-label'>{i18n.t('request.order')}</div>
                    <TextBox
                      name='order'
                      value={request.order}
                      readonly={isView || isCurrentUserInternal}
                      onChange={onOrderChange}/>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6'>
                <div className='form-group'>
                  <div className='autocomplete-input'>
                    <div className='autocomplete-label'>{i18n.t('request.sendDate')}</div>
                    <DatePickerComponent
                      readonly={isView || isCurrentUserInternal}
                      locale={i18n.language}
                      ref={(datePicker: DatePickerComponent): DatePickerComponent => this.endDateDatePickerComponent = datePicker}
                      created={() => {
                        if (request.sendDate && request.sendDate.year() !== 0) {
                          this.endDateDatePickerComponent.value = request.sendDate.toDate();
                        }
                      }}
                      change={(e: ChangedEventArgs) => onSendDateChange(e.value && moment(e.value).isValid() ? moment(e.value) : null)}
                      firstDayOfWeek={moment().isoWeekday()}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col'>
                <div className='form-group'>
                  <label htmlFor='analysisToPerform'>{i18n.t('request.analysisToPerform')}</label>
                  <textarea
                    className='form-control'
                    id='analysisToPerform'
                    rows={5}
                    value={request.analysisToPerform}
                    readOnly={isView || isCurrentUserInternal}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => onAnalysisToPerformChange(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col'>
                <div className='form-group'>
                  <label htmlFor='analysisToPerform'>{i18n.t('request.applicantCc')}</label>
                  <MultiSelectComponent
                    id='applicantCc'
                    name='ApplicantCc'
                    dataSource={applicantCc}
                    fields={this.applicantCcFields}
                    sortOrder={this.applicantCcSortOrder}
                    value={request.applicantCc && request.applicantCc.split(';')}
                    mode='CheckBox'
                    ref={(multiSelect: MultiSelectComponent): MultiSelectComponent => this.applicantCcMultiSelectComponent = multiSelect}
                    enabled={!isView}
                    change={(e: MultiSelectChangeEventArgs): void => this.onApplicantMultiSelectComponentChange(e)}
                    filtering={(e: FilteringEventArgs) => {
                      let query = new Query();
                      const predicate = new Predicate('email', RuleOperator.CONTAINS, e.text, true);
                      query = (e.text !== '') ? query.where(predicate) : query;
                      e.updateData(applicantCc, query);
                    }}
                  >
                    <DropdownInject services={[CheckBoxSelection]} />
                  </MultiSelectComponent>
                </div>
              </div>
            </div>
            {request.customFields && <div className='row'>
              {(isCurrentUserInternal || (!isCurrentUserInternal && bidderIdPermission)) && 
              <div className='col-12 col-md-6'>
                <div className='form-group'>
                  <div className='autocomplete-input'>
                    <div className='autocomplete-label'>{i18n.t('requestCustomFields.bidderId')}</div>
                    {isCurrentUserInternal && <TextBox
                      name='bidderId'
                      value={request.customFields.bidderName}
                      readonly={true}
                    />}
                    {(!isCurrentUserInternal && bidderIdPermission) && <DropDownListComponent
                      fields={this.customFieldFields}
                      dataSource={JSON.parse(JSON.stringify(bidderOptions))}
                      value={request.customFields.bidderId}
                      enabled={!isView && !isCurrentUserInternal && bidderIdPermission.canWrite}
                      showClearButton={true}
                      change={(e: ChangeEventArgs) => onCustomFieldsChange({ ...request.customFields, bidderId: e.value as string || null })}
                      sortOrder={this.bidderSortOrder}
                    />}
                  </div>
                </div>
              </div>}
              {(isCurrentUserInternal || contestPermission) && (
                <div className='col-12 col-md-6'>
                  <div className='form-group'>
                    <div className='autocomplete-input'>
                      <div className='autocomplete-label'>{i18n.t('requestCustomFields.contest')}</div>
                      <TextBox
                        name='contest'
                        value={request.customFields.contest}
                        readonly={isView || isCurrentUserInternal || !contestPermission.canWrite}
                        onChange={(value: string) => onCustomFieldsChange({ ...request.customFields, contest: value })}
                      />
                    </div>
                  </div>
                </div>
              )}
              {(isCurrentUserInternal || quantityPermission) && (
                <div className='col-12 col-md-6'>
                  <div className='form-group'>
                    <div className='autocomplete-input'>
                      <div className='autocomplete-label'>{i18n.t('requestCustomFields.quantity')}</div>
                      <NumericTextBoxComponent
                        name='quantity'
                        value={request.customFields.quantity}
                        readonly={isView || isCurrentUserInternal || !quantityPermission.canWrite}
                        format='n0'
                        min={0}
                        locale={i18n.language}
                        change={(e: any) => onCustomFieldsChange({ ...request.customFields, quantity: typeof e.value === 'number' ? e.value : null })}
                      />
                    </div>
                  </div>
                </div>
              )}
              {(isCurrentUserInternal || sampleSealedPermission) && (
                <div className='col-12 col-md-6'>
                  <div className='form-group d-flex' style={{ marginTop: '7px' }}>
                    <label htmlFor='sampleSealed' className='mr-2'>{i18n.t('requestCustomFields.sampleSealed')}</label>
                    <div style={{ marginTop: '0.15rem' }}>
                      <SwitchComponent
                        id='sampleSealed'
                        checked={request.customFields.sampleSealed}
                        disabled={isView || isCurrentUserInternal || !sampleSealedPermission.canWrite}
                        change={(e: any) => onCustomFieldsChange({ ...request.customFields, sampleSealed: e.checked })}
                      />
                    </div>
                  </div>
                </div>
              )}
              {(isCurrentUserInternal || returnSurplusSamplePermission) && (
                <div className='col-12 col-md-6'>
                  <div className='form-group d-flex' style={{ marginTop: '7px' }}>
                    <label htmlFor='returnSurplusSample' className='mr-2'>{i18n.t('requestCustomFields.returnSurplusSample')}</label>
                    <div style={{ marginTop: '0.15rem' }}>
                      <SwitchComponent
                        id='returnSurplusSample'
                        checked={request.customFields.returnSurplusSample}
                        disabled={isView || isCurrentUserInternal || !returnSurplusSamplePermission.canWrite}
                        change={(e: any) => onCustomFieldsChange({ ...request.customFields, returnSurplusSample: e.checked })}
                      />
                    </div>
                  </div>
                </div>
              )}
              {(isCurrentUserInternal || quantityPermission) && (
                <div className='col-12 col-md-6'>
                  <div className='form-group'>
                    <div className='autocomplete-input'>
                      <div className='autocomplete-label'>{i18n.t('requestCustomFields.requiredDate')}</div>
                      <DatePickerComponent
                        readonly={isView || isCurrentUserInternal || !requiredDatePermission.canWrite}
                        locale={i18n.language}
                        ref={(datePicker: DatePickerComponent): DatePickerComponent => this.requiredDateDatePickerComponent = datePicker}
                        created={() => this.requiredDateDatePickerComponent.value = request.customFields.requiredDate && request.customFields.requiredDate.toDate()}
                        change={(e: ChangedEventArgs) => onCustomFieldsChange({ ...request.customFields, requiredDate: e.value && moment(e.value).isValid() ? moment(e.value) : null })}
                        firstDayOfWeek={moment().isoWeekday()}
                      />
                    </div>
                  </div>
                </div>
              )}
              {(isCurrentUserInternal || eciRequestPermission) && (
                <div className='col-12 col-md-6'>
                  <div className='form-group'>
                    <div className='autocomplete-input'>
                      <div className='autocomplete-label'>{i18n.t('requestCustomFields.eciRequest')}</div>
                      <TextBox
                        name='eciRequest'
                        value={request.customFields.eciRequest}
                        readonly={isView || isCurrentUserInternal || !eciRequestPermission.canWrite}
                        onChange={(value: string) => onCustomFieldsChange({ ...request.customFields, eciRequest: value })}
                      />
                    </div>
                  </div>
                </div>
              )}
              {(isCurrentUserInternal || (!isCurrentUserInternal && centerIdPermission)) && <div className='col-12 col-md-6'>
                <div className='form-group'>
                  <div className='autocomplete-input'>
                    <div className='autocomplete-label'>{i18n.t('requestCustomFields.centerId')}</div>
                    {isCurrentUserInternal && <TextBox
                      name='centerId'
                      value={request.customFields.centerName}
                      readonly={true}
                    />}
                    {(!isCurrentUserInternal && centerIdPermission) && <DropDownListComponent
                      fields={this.customFieldFields}
                      dataSource={JSON.parse(JSON.stringify(centerOptions))}
                      value={request.customFields.centerId}
                      enabled={!isView && !isCurrentUserInternal && centerIdPermission.canWrite}
                      showClearButton={true}
                      change={(e: ChangeEventArgs) => onCustomFieldsChange({ ...request.customFields, centerId: e.value as string || null })}
                    />}
                  </div>
                </div>
              </div>}
              {(isCurrentUserInternal || (!isCurrentUserInternal && unecoIdPermission)) && <div className='col-12 col-md-6'>
                <div className='form-group'>
                  <div className='autocomplete-input'>
                    <div className='autocomplete-label'>{i18n.t('requestCustomFields.unecoId')}</div>
                    {isCurrentUserInternal && <TextBox
                      name='unecoId'
                      value={request.customFields.unecoName}
                      readonly={true}
                    />}
                    {(!isCurrentUserInternal && unecoIdPermission) && <DropDownListComponent
                      fields={this.customFieldFields}
                      dataSource={JSON.parse(JSON.stringify(unecoOptions))}
                      value={request.customFields.unecoId}
                      enabled={!isView && !isCurrentUserInternal && unecoIdPermission.canWrite}
                      showClearButton={true}
                      change={(e: ChangeEventArgs) => onCustomFieldsChange({ ...request.customFields, unecoId: e.value as string || null })}
                    />}
                  </div>
                </div>
              </div>}
              {(isCurrentUserInternal || (!isCurrentUserInternal && delegationIdPermission)) && <div className='col-12 col-md-6'>
                <div className='form-group'>
                  <div className='autocomplete-input'>
                    <div className='autocomplete-label'>{i18n.t('requestCustomFields.delegationId')}</div>
                    {isCurrentUserInternal && <TextBox
                      name='delegationId'
                      value={request.customFields.delegationName}
                      readonly={true}
                    />}
                    {(!isCurrentUserInternal && delegationIdPermission) && <DropDownListComponent
                      fields={this.customFieldFields}
                      dataSource={JSON.parse(JSON.stringify(delegationOptions))}
                      value={request.customFields.delegationId}
                      enabled={!isView && !isCurrentUserInternal && delegationIdPermission.canWrite}
                      showClearButton={true}
                      change={(e: ChangeEventArgs) => onCustomFieldsChange({ ...request.customFields, delegationId: e.value as string || null })}
                    />}
                  </div>
                </div>
              </div>}
              {(isCurrentUserInternal || supplierPermission) && (
                <div className='col-12 col-md-6'>
                  <div className='form-group'>
                    <div className='autocomplete-input'>
                      <div className='autocomplete-label'>{i18n.t('requestCustomFields.supplier')}</div>
                      <TextBox
                        name='supplier'
                        value={request.customFields.supplier}
                        readonly={isView || isCurrentUserInternal || !supplierPermission.canWrite}
                        onChange={(value: string) => onCustomFieldsChange({ ...request.customFields, supplier: value })}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>}
            {(isCurrentUserInternal && request.delegateUserManagerId) && (
              <div className='row'>
                <div className='col-12 col-md-6'>
                  <div className='form-group'>
                    <div className='autocomplete-input'>
                      <div className='autocomplete-label'>{i18n.t('request.managerGroupUserName')}</div>
                      <DropDownListComponent
                        id='delegateUserManager'
                        name='DelegateUserManager'
                        query={this.delegateManagerGroupsQuery}
                        dataSource={this.delegateManagerGroupsDataManager}
                        fields={this.delegateManagerGroupsFields}
                        sortOrder={this.delegateManagerGroupsSortOrder}
                        allowFiltering={true}
                        delayUpdate={true}
                        showClearButton={true}
                        value={request.delegateUserManagerId}
                        enabled={false}
                        ref={(dropDownListComponent: DropDownListComponent): DropDownListComponent => this.delegateManagerGroupsDropDownListComponent = dropDownListComponent}
                        actionComplete={(e: any) => {
                          e.result = (e.result as IUser[]).map((r) => ({ ...r, delegateFullName: `${r.firstName} ${r.lastName}` }));
                        }}
                        change={(e: ChangeEventArgs) => {onDelegateUserManagerIdChange(e.value as string)}}
                        filtering={(e: FilteringEventArgs) => {
                          this.delegateManagerGroupsFilteringEvent = e;
                          this.delegateManagerGroupsFilteringEvent.cancel = true;
                          this.delegateManagerGroupsFilteringDebounced();
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>)}
          </form>
        </div>
        <ActionBarComponent
          disabled={false}
          editing={isView === false}
          loading={isSaving}
          withoutTopBorder={true}
          buttons={actionBarButtons}
          onEditButtonClick={isNew ? undefined : onEditButtonClick}
          onSaveButtonClick={() => {
            if (this.validateForm()) {
              onSaveButtonClick();
              this.formValidator = null;
            }
          }}
          onCancelButtonClick={() => {
            this.props.setErrors({});
            formValidatorUtils.clearErrors(this.formValidator);
            this.formValidator = null;
            onCancelButtonClick();
          }}
        />
      </div>
    );
  }

  public onApplicantMultiSelectComponentChange(e: MultiSelectChangeEventArgs): void {
    const applicantCc = e.value as string[];
    this.props.onApplicantCcChange(applicantCc ? applicantCc.join(';') : '');
  }

  private validateForm(): boolean {
    if (!this.formValidator) {
      return true;
    }

    this.isValidatingAll = true;
    const isFormValid = this.formValidator.validate();
    this.isValidatingAll = false;
    if (!isFormValid) {
      this.props.setErrors(this.auxErrors);
      this.auxErrors = {};
    }
    return isFormValid;
  }
}

interface IInfoCardState {
  acceptButtonLoading: boolean;
  rejectButtonLoading: boolean;
  disableManagerUserEmailId: boolean;
}

export default withTranslation()(ClientDataTab);
