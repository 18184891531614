import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { FormValidator, FormEventArgs } from '@syncfusion/ej2-react-inputs';
import { RichTextEditorComponent, Inject, Toolbar, QuickToolbar, Link, HtmlEditor } from '@syncfusion/ej2-react-richtexteditor';
import { DialogComponent } from '@aitex/tsx-extranet-dialogs';

import { ISendReportEmailDialogProps } from '../../../interfaces/props/IDialogsProps';
import { IEmail } from '../../../common/model/email.model';
import { IErrors } from '../../../common/model/errors.model';
import { Validator } from '../../../common/model/enumerations/validator.model';
import { reportsService } from '../../../services';
import * as dialogUtils from '../../../utils/dialog.utils';
import * as formValidatorUtils from '../../../utils/formValidator.utils';
import ValidationError from '../../validationError/validationError';
import TextBox from '../../filters/textBox';

type SendReportEmailDialogPropsType = ISendReportEmailDialogProps & WithTranslation;

type SendReportEmailDialogStateType = ISendReportEmailDialogState;

class SendReportEmailDialog extends Component<SendReportEmailDialogPropsType, SendReportEmailDialogStateType> {
  public readonly SEND_REPORT_EMAIL_DIALOG_FORM_ID = 'sendReportEmailDialogForm';

  public richTextEditorComponent: RichTextEditorComponent = null;

  private readonly CANCEL_BUTTON_ID = 'sendReportEmailDialogCancel';
  private readonly SEND_BUTTON_ID = 'sendReportEmailDialogSend';

  private readonly CANNOT_SEND_REPORT_EMAIL_ERROR: IErrors = { UnknownError: ['dialogs.sendReportEmailDialog.error'] };

  private formValidator: FormValidator = null;
  private isValidatingAll = false;
  private auxErrors: IErrors = {};

  private initialState: SendReportEmailDialogStateType = {
    showForm: false,
    email: {
      addresses: null,
      subject: null,
      body: null
    },
    isSending: false,
    errors: {}
  };

  public constructor(props: SendReportEmailDialogPropsType) {
    super(props);

    this.state = this.initialState;
  }

  private async getAddressesToSendEmail(id: string): Promise<string> {
    const emails = await reportsService.getAddressesToSendEmail(id);

    return emails;
  }

  public async componentDidUpdate(prevProps: SendReportEmailDialogPropsType): Promise<void> {
    const { visible, report } = this.props;

    if (!prevProps.visible && visible) {
      if (report) {
        let subject = this.props.i18n.t('dialogs.sendReportEmailDialog.subjectDefault', { reportNumber: report.reportNumber, version: report.version });
        if (report.customFields) {
          if (report.customFields.aitexResultName) {
            subject += ` - RESULT: [${report.customFields.aitexResultName}]`;
          }
          if (report.customFields.uneco) {
            subject += ` - UNECO: [${report.customFields.uneco}]`;
          }
        }
        if (report.reference) {
          subject += ` - REF: [${report.reference}]`;
        }

        const emails = await this.getAddressesToSendEmail(report.id);

        this.setState({
          showForm: true,
          email: {
            addresses: emails,
            subject,
            body: this.props.i18n.t('dialogs.sendReportEmailDialog.bodyDefault', { reportNumber: report.reportNumber }),
          }
        });
      }
    }
    if (prevProps.visible && !visible) {
      this.setState(this.initialState);
      this.resetFormValidator();
    }

    dialogUtils.manageButtonsClick(this.SEND_BUTTON_ID, (): Promise<void> => this.sendReportEmail(), this.CANCEL_BUTTON_ID, (): void => this.dismiss());
  }

  public async sendReportEmail(): Promise<void> {
    try {
      if (!this.validateForm()) {
        return;
      }

      this.setState({ isSending: true });

      if (!this.props.report) {
        throw this.CANNOT_SEND_REPORT_EMAIL_ERROR;
      }

      await reportsService.sendEmail(this.props.report.id, { ...this.state.email, body: this.richTextEditorComponent ? this.richTextEditorComponent.getHtml() : null });
      this.close();
    } catch (error) {
      let errors = error as IErrors;
      if (errors.Addresses) {
        this.setState({ errors });
        return;
      }
      if (errors.UnknownError || (!errors.UnknownError && !errors.ControlledError)) {
        errors = this.CANNOT_SEND_REPORT_EMAIL_ERROR;
      }
      this.dismiss(errors);
    } finally {
      this.setState({ isSending: false });
    }
  }

  public close(): void {
    this.formValidator = null;
    this.props.onClose();
  }

  public dismiss(reason?: IErrors): void {
    this.props.onDismiss(reason);
  }

  public validateEmails(args: { [key: string]: string }): boolean {
    //https://www.regextester.com/115911
    //General Email Regex (RFC 5322 Official Standard)
    // eslint-disable-next-line no-control-regex
    const regexExpression = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
    let isValid = false;

    const value = args['value'];
    if (value !== undefined && value !== null && value.trim() !== '') {
      isValid = regexExpression.test(value.toLowerCase());
    }

    return isValid;
  }

  public configureFormValidator(): void {
    if (this.props.visible) {
      if (!this.formValidator) {
        this.formValidator = formValidatorUtils.configureFormValidator(this.SEND_REPORT_EMAIL_DIALOG_FORM_ID, {
          Addresses: {
            required: [true, Validator.NOT_EMPTY],
            // eslint-disable-next-line no-control-regex
            regex: [/^(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])(;[ ]{0,1}(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))*$/, Validator.EMAILS]
            //validEmails: [this.validateEmails, Validator.EMAILS]
          }
        });
        this.formValidator.validationComplete = (args) => {
          formValidatorUtils.validationComplete(args as FormEventArgs, this.isValidatingAll, this.state.errors, this.auxErrors, (errors): void => this.setState({ errors }));
        };
      }
    } else {
      this.formValidator = null;
    }
  }

  public onAddressesChange(addresses: string): void {
    this.setState({ email: { ...this.state.email, addresses } });
  }

  public onSubjectChange(subject: string): void {
    this.setState({ email: { ...this.state.email, subject } });
  }

  public render(): JSX.Element {
    const { visible, i18n } = this.props;

    return (
      <DialogComponent
        header={i18n.t('dialogs.sendReportEmailDialog.title')}
        visible={visible}
        width='80%'
        footerTemplate={dialogUtils.computeFooterTemplate(
          this.SEND_BUTTON_ID,
          i18n.t('actions.send'),
          this.CANCEL_BUTTON_ID,
          i18n.t('actions.cancel'),
          this.state.isSending,
          this.isSaveButtonDisabled()
        )}
        onDismiss={() => this.dismiss()}
      >
        <div>
          {this.state.showForm && <form id={this.SEND_REPORT_EMAIL_DIALOG_FORM_ID} ref={(): void => this.configureFormValidator()}>
            <div className='row'>
              <div className='col'>
                <div className='form-group'>
                  <div className='autocomplete-input'>
                    <div className='autocomplete-label'>{i18n.t('email.addresses')}</div>
                    <TextBox
                      name='Addresses'
                      value={this.state.email.addresses}
                      onChange={(value): void => this.onAddressesChange(value)}
                    />
                  </div>
                  <ValidationError errors={this.state.errors} errorKey={'Addresses'} />
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col'>
                <div className='form-group'>
                  <div className='autocomplete-input'>
                    <div className='autocomplete-label'>{i18n.t('email.subject')}</div>
                    <TextBox
                      name='Subject'
                      value={this.state.email.subject}
                      onChange={(value): void => this.onSubjectChange(value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col'>
                <div className='form-group'>
                  <label htmlFor='body'>{i18n.t('email.body')}</label>
                  <RichTextEditorComponent
                    name='Body'
                    id='body'
                    value={this.state.email.body}
                    ref={(richTextEditor: RichTextEditorComponent) : RichTextEditorComponent => this.richTextEditorComponent = richTextEditor}
                  >
                    <Inject services={[Toolbar, QuickToolbar, Link, HtmlEditor]} />
                  </RichTextEditorComponent>
                </div>
              </div>
            </div>
          </form>}
        </div>
      </DialogComponent>
    );
  }

  private isSaveButtonDisabled(): boolean {
    return !!this.state.errors.Addresses || this.state.isSending;
  }

  private resetFormValidator(): void {
    if (!this.formValidator) {
      return;
    }

    if (this.formValidator.getInputElement('Addresses')) {
      this.formValidator.getInputElement('Addresses').parentElement.className = this.formValidator.getInputElement('Addresses').parentElement.className.replace('e-error', '');
    }
    this.formValidator.removeRules();
    this.formValidator.validationComplete = undefined;
  }

  private validateForm(): boolean {
    if (!this.formValidator) {
      return true;
    }

    this.isValidatingAll = true;
    const isFormValid = this.formValidator.validate();
    this.isValidatingAll = false;
    if (!isFormValid) {
      this.setState({ errors: this.auxErrors });
      this.auxErrors = {};
    }
    return isFormValid;
  }
}

interface ISendReportEmailDialogState {
  showForm: boolean;
  email: IEmail;
  isSending: boolean;
  errors: IErrors;
}

export default withTranslation()(SendReportEmailDialog);
