import { URL } from '../common/constants';
import { IForm } from '../interfaces/states/ILoginFormState';
import { ISeasons } from '../interfaces/states/IReportStatusState';
import IReportStatus from '../interfaces/IReportStatus';
import * as services from '.';

export interface IReportStatusService {
  getAllSeasons(): Promise<ISeasons[]>;
  getReportStatus(formData: IForm): Promise<any>;
}

class ReportStatusService implements IReportStatusService {
  public constructor(private httpClient: services.IHttpClient) {

  }

  public getAllSeasons(): Promise<ISeasons[]> {
    return this.httpClient.getCustom(`${URL.WEB.BASE}${URL.WEB.SEASONS}`);
  }

  public async getReportStatus(formData: IForm): Promise<IReportStatus> {
    try {
      const reportStatus = await this.httpClient.getCustom<IReportStatus>(`${URL.WEB.BASE}${URL.WEB.GARMENTRATINGS}/${encodeURIComponent(formData.model)}/${formData.provider}/${formData.season}`);
      await services.telemetryService.trackReportStatus(formData, reportStatus);
      return reportStatus;
    } catch (error: any) {
      await services.telemetryService.trackReportStatus(formData, error);
      throw error;
    }
  }
}

export const reportStatusService = new ReportStatusService(services.httpClient);
