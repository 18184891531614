import React, { Component, Fragment, ComponentType, Dispatch } from 'react';
import { withRouter } from 'react-router-dom';
import { referencesService, settings } from '../../services';
import { withTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { toShortDate, pageNames } from '../../helpers';
import { UserType } from '../../common/model/enumerations/userType.model';
import { IReferenceDetailState } from './iReferenceDetail';
import { connect } from 'react-redux';
import { compose, Action } from 'redux';
import { displayReportAndReference, cleanPageInfo, displayCompany, displayPageName } from '../../store/modules/page';
import { RootState } from '../../store';
import { UserState } from '../../store/modules/user';
import { DataManager, Query } from '@syncfusion/ej2-data';
import { CustomDetailAdaptor } from '../../interceptors';
import { TYPE_FILTER, OPERATOR_EQ, URL } from '../../common/constants';
import withPageTracking from '../../hoc/withPageTracking';

class ReferenceDetail extends Component<any, IReferenceDetailState> {
  public data: DataManager;

  public constructor(props: any) {
    super(props);
    this.state = {
      referenceDetail: {},
    };
  }

  public componentDidMount = async () => {
    referencesService.getReferenceByReferenceId(this.props.match.params.referenceId)
      .then((referenceDetail) => {
        this.setState({ referenceDetail });
      })
      .catch(() => { (this.props as any).history.push(`/notfound`); })
      .finally(() => {
        this.setPageConfiguration();
      });
  }

  public setPageConfiguration = async () => {
    if (this.props.user.userType === UserType.EXTERNAL) {
      this.props.displayCompany(this.props.user.companyId);
    } else {
      this.data = new DataManager({
        adaptor: new CustomDetailAdaptor(),
        headers: [{ Authorization: `Bearer ${this.props.user.access_token}` }],
        url: `${settings.api}${URL.ODATA.REPORT_FILTERS}?${TYPE_FILTER}=id ${OPERATOR_EQ} ${this.props.match.params.id}`,
      });
      const query = new Query();
      await this.data.executeQuery(query).then((result: any) => {
        this.props.displayCompany(result.result[0].clientId);
      });
    }

    this.props.displayReportAndReference(this.props.match.params.id, this.props.match.params.referenceId, this.state.referenceDetail.name);
    this.props.displayPageName(pageNames.referencePage);
  }

  public componentWillUnmount = () => {
    this.props.cleanPageInfo();
  }

  public render() {
    const { referenceDetail } = this.state;

    return (
      <Fragment>
        <div className='meta-container'>
          <Row>
            <Col>
              <div className='meta-header m-auto'>
                <span className='meta-label'>
                  {this.props.i18n.t(`referenceDetail.title`)}
                </span>
              </div>
            </Col>
          </Row>
          <Row className='mt-3 mb-3'>
            <Col>
              <div className='meta-list_item ml-auto pt-1 pb-1'>
                <span className='meta-label'>
                  {this.props.i18n.t(`referenceDetail.name`)}
                </span>
                <span>{referenceDetail.name}</span>
              </div>
            </Col>
            <Col>
              <div className='meta-list_item pt-1 pb-1'>
                <span className='meta-label'>
                  {this.props.i18n.t(`referenceDetail.sendDate`)}
                </span>
                <span>{toShortDate(referenceDetail.sendDate)}</span>
              </div>
            </Col>
          </Row>
        </div>
        <Row>
          <Col>
            <p className='meta-label font-weight-bold border-bottom'>
              {this.props.i18n.t(`referenceDetail.description`)}
            </p>
            <p>
              {referenceDetail.description}
            </p>
          </Col>
        </Row>
      </Fragment>

    );
  }
}

interface DispatchProps {
  displayReportAndReference: (reportId: string, referenceId: string, referenceName: string) => void;
  displayPageName: (pageName: string) => void;
  displayCompany: (companyId: string, companyName?: string) => void;
  cleanPageInfo: () => void;
}

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => ({
  displayPageName: (pageName: string) =>
    dispatch(displayPageName(pageName)),
  displayCompany: (companyId: string) =>
    dispatch(displayCompany(companyId)),
  displayReportAndReference: (reportId: string, referenceId: string, referenceName: string) =>
    dispatch(displayReportAndReference(reportId, referenceId, referenceName)),
  cleanPageInfo: () =>
    dispatch(cleanPageInfo()),
});

const mapStateToProps = (states: RootState): UserState => ({
  user: states.userStore.user
});

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
  withPageTracking
)(ReferenceDetail) as ComponentType;
