import { Action, Reducer, ActionCreator, combineReducers } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { DataManager, Query } from '@syncfusion/ej2-data';

import { RootState } from '..';
import { IChainsListState } from '../../interfaces/states/IChainsListState';
import { IChain } from '../../common/model/chain.model';
import { IErrors } from '../../common/model/errors.model';
import { IODataService, IChainsService } from '../../services';

enum Type {
  RESET = '@@chainsList/RESET',
  SET_CHAINS = '@@chainsList/SET_CHAINS',
  SET_IS_FETCHING = '@@chainsList/SET_IS_FETCHING',
  SET_ERRORS = '@@chainsList/SET_ERRORS'
}

const initialState: IChainsListState = {
  chains: [],
  isFetching: false,
  errors: {}
};

// Actions

export type Actions = ResetAction | SetChainsAction | SetIsFetchingAction | SetErrorsAction;

export type ResetAction = Action<Type.RESET>

export interface SetChainsAction extends Action<Type.SET_CHAINS> {
  chains: IChain[];
}

export interface SetIsFetchingAction extends Action<Type.SET_IS_FETCHING> {
  isFetching: boolean;
}

export interface SetErrorsAction extends Action<Type.SET_ERRORS> {
  errors: IErrors;
}

export const reset: ActionCreator<ResetAction> = () => ({
  type: Type.RESET
});

export const setChains: ActionCreator<SetChainsAction> = (chainsLocal: IChain[]) => ({
  type: Type.SET_CHAINS,
  chains: chainsLocal
});

export const setIsFetching: ActionCreator<SetIsFetchingAction> = (isFetchingLocal: boolean) => ({
  type: Type.SET_IS_FETCHING,
  isFetching: isFetchingLocal
});

export const setErrors: ActionCreator<SetErrorsAction> = (errorsLocal: IErrors) => ({
  type: Type.SET_ERRORS,
  errors: errorsLocal
});

export const fetchChains: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (
  oDataService: IODataService,
  chainsDataManager: DataManager,
  chainsQuery: Query
) => (
    async (dispatch) => {
      dispatch(setIsFetching(true));

      const chainsLocal = await oDataService.executeQuery<IChain>(chainsDataManager, chainsQuery);
      dispatch(setChains(chainsLocal));
      dispatch(setIsFetching(false));
    }
  );

export const saveChain: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (
  chain: IChain,
  chainsService: IChainsService,
  oDataService: IODataService,
  chainsDataManager: DataManager,
  chainsQuery: Query
) => (
  async (dispatch) => {
    try {
      dispatch(setErrors(initialState.errors));

      dispatch(setIsFetching(true));

      chain.id ? await chainsService.update(chain) : await chainsService.create(chain);
    } catch (error) {
      dispatch(setErrors(error as IErrors));
    } finally {
      const chainsLocal = await oDataService.executeQuery<IChain>(chainsDataManager, chainsQuery);
      dispatch(setChains(chainsLocal));

      dispatch(setIsFetching(false));
    }
  }
);

// Reducers

const chains: Reducer<IChain[]> = (state = initialState.chains, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.chains;
    case Type.SET_CHAINS:
      return (action as SetChainsAction).chains;
    default:
      return state;
  }
};

const isFetching: Reducer<boolean> = (state = initialState.isFetching, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.isFetching;
    case Type.SET_IS_FETCHING:
      return (action as SetIsFetchingAction).isFetching;
    default:
      return state;
  }
};

const errors: Reducer<IErrors> = (state = initialState.errors, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.errors;
    case Type.SET_ERRORS:
      return (action as SetErrorsAction).errors;
    default:
      return state;
  }
};

export const chainsListStore = combineReducers({ chains, isFetching, errors });
