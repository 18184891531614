import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Query, Predicate, ReturnOption } from '@syncfusion/ej2-data';
import { SortOrder } from '@syncfusion/ej2-lists';
import { DropDownListComponent, MultiSelectComponent, CheckBoxSelection, Inject, ChangeEventArgs, MultiSelectChangeEventArgs, FilteringEventArgs, FieldSettingsModel } from '@syncfusion/ej2-react-dropdowns';

import { IRequestsListFilterProps } from '../../../interfaces/props/IRequestsListProps';
import { IMyRule } from '../../../common/model/myRule.model';
import { RequestStatus } from '../../../common/model/enumerations/requestStatus.model';
import { RequestsListMode } from '../../../common/model/enumerations/requestsListMode.model';
import { RuleCondition } from '../../../common/model/enumerations/ruleCondition.model';
import { RuleOperator } from '../../../common/model/enumerations/ruleOperator.model';
import { IUser } from '../../../common/model/user.model';
import { queryBuilderService } from '../../../services';
import { enumToArray } from '../../../utils/enum.utils';
import { debounce } from '../../../utils/common.utils';
import * as requestStatusUtils from '../../../utils/requestStatus.utils';
import TextBox from '../../../components/filters/textBox';
import DateRange from '../../../components/filters/dateRange';

interface IDelegateUserManagers {
  userId: string;
  firstName: string;
  lastName: string;
}

type RequestsListFilterPropsType = IRequestsListFilterProps & WithTranslation;

class RequestsListFilter extends Component<RequestsListFilterPropsType> {
  public statusDropDownListComponent: DropDownListComponent = null;
  public statusOptions: Record<string, string | number>[] = [];
  public statusFields: FieldSettingsModel = { text: 'text', value: 'value', iconCss: 'iconCss' };

  public companiesMultiSelectComponent: MultiSelectComponent = null;
  public companiesQuery = new Query().select(['Id', 'Name']).take(10);
  public companiesFields: FieldSettingsModel = { text: 'name', value: 'id' };
  public companiesSortOrder: SortOrder = 'Ascending';
  public companiesFilteringEvent: FilteringEventArgs = null;
  public companiesFilteringDebounced = debounce(
    () => {
      if (this.companiesFilteringEvent) {
        if (this.companiesFilteringEvent.text.length >= 3) {
          this.companiesMultiSelectComponent.filter(
            this.companiesMultiSelectComponent.dataSource,
            this.companiesMultiSelectComponent.query.clone()
            .where('name', RuleOperator.CONTAINS, this.companiesFilteringEvent.text, true)
          );
        } else {
          return;
        }
      }
    },
    250
  );

  public usersMultiSelectComponent: MultiSelectComponent = null;
  public usersQuery = new Query().select(['Id', 'Email']).take(10);
  public usersFields: FieldSettingsModel = { text: 'email', value: 'id' };
  public usersSortOrder: SortOrder = 'Ascending';
  public usersFilteringEvent: FilteringEventArgs = null;
  public usersFilteringDebounced = debounce(
    () => {
      if (this.usersFilteringEvent) {
        if (this.usersFilteringEvent.text.length >= 3) {
          this.usersMultiSelectComponent.filter(
            this.usersMultiSelectComponent.dataSource,
            this.usersMultiSelectComponent.query.clone()
            .where('email', RuleOperator.CONTAINS, this.usersFilteringEvent.text, true)
          );
        } else {
          return;
        }
      }
    },
    250
  );

  public managerGroupsMultiSelectComponent: MultiSelectComponent = null;
  public managerGroupsQuery = new Query().select(['Id', 'Value']).where('active', RuleOperator.EQUAL, true);
  public managerGroupsFields: FieldSettingsModel = { text: 'value', value: 'id' };
  public managerGroupsSortOrder: SortOrder = 'Ascending';
  public managerGroupsFilteringEvent: FilteringEventArgs = null;
  public managerGroupsFilteringDebounced = debounce(
    () => {
      if (this.managerGroupsFilteringEvent) {
        if (this.managerGroupsFilteringEvent.text.length >= 3) {
          this.managerGroupsMultiSelectComponent.filter(
            this.managerGroupsMultiSelectComponent.dataSource,
            this.managerGroupsMultiSelectComponent.query.clone().where('value',
              RuleOperator.CONTAINS,
              this.managerGroupsFilteringEvent.text,
              true)
          );
        } else {
          return;
        }
      }
    },
    250
  );

  public managerEmailUsersMultiSelectComponent: MultiSelectComponent = null;
  public managerEmailUsersQuery = new Query().select(['Id', 'FirstName', 'LastName']).where('Type', RuleOperator.EQUAL, 1).take(10);
  public managerEmailUsersFields: FieldSettingsModel = { text: 'fullName', value: 'id' };
  public managerEmailUsersSortOrder: SortOrder = 'Ascending';
  public managerEmailUsersFilteringEvent: FilteringEventArgs = null;
  public managerEmailUsersFilteringDebounced = debounce(
    () => {
      if (this.managerEmailUsersFilteringEvent) {
        let query = this.managerEmailUsersMultiSelectComponent.query.clone();
        if (this.managerEmailUsersFilteringEvent.text.trim().length >= 3) {
          query = queryBuilderService.buildQuery(this.props.managerEmailUsersDataManager, {
            condition: RuleCondition.AND,
            myRules: this.managerEmailUsersFilteringEvent.text.split(' ').filter((t) => !!t.trim()).map((t) => ({
              condition: RuleCondition.OR,
              myRules: [{
                field: 'firstName',
                operator: RuleOperator.CONTAINS,
                value: t,
                ignoreCase: true
              }, {
                field: 'lastName',
                operator: RuleOperator.CONTAINS,
                value: t,
                ignoreCase: true
              }]
            }))
          }, query);
        }
        this.managerEmailUsersMultiSelectComponent.filter(this.managerEmailUsersMultiSelectComponent.dataSource, query);
      }
    },
    250
  );

  public delegateUserManagersMap: { [key: string]: unknown }[] = [];
  public delegateUserManagersMultiSelectComponent: MultiSelectComponent = null;
  public delegateUserManagersQuery = new Query().select(['userId', 'firstName', 'lastName']);
  public delegateUserManagersFields: FieldSettingsModel = { text: 'delegateFullName', value: 'userId' };
  public delegateUserManagersSortOrder: SortOrder = 'Ascending';
  public delegateUserManagersFilteringEvent: FilteringEventArgs = null;
  public delegateUserManagersFilteringDebounced = debounce(
    () => {
      if (this.delegateUserManagersFilteringEvent) {
        let query = this.delegateUserManagersMultiSelectComponent.query.clone();
        if (this.delegateUserManagersFilteringEvent.text.trim().length >= 3) {
          query = queryBuilderService.buildQuery(this.props.delegateManagerUsersDataManager, {
            condition: RuleCondition.AND,
            myRules: this.delegateUserManagersFilteringEvent.text.split(' ').filter((t) => !!t.trim()).map((t) => ({
              condition: RuleCondition.OR,
              myRules: [{
                field: 'firstName',
                operator: RuleOperator.CONTAINS,
                value: t,
                ignoreCase: true
              }, {
                field: 'lastName',
                operator: RuleOperator.CONTAINS,
                value: t,
                ignoreCase: true
              }]
            }))
          }, query);
        }
        this.delegateUserManagersMultiSelectComponent.filter(this.delegateUserManagersMultiSelectComponent.dataSource, query);
      }
    },
    250
  );

  public requestCustomFieldOptionsFields: FieldSettingsModel = { text: 'name', value: 'id' };

  public constructor(props: RequestsListFilterPropsType) {
    super(props);

    const { currentUserCompany, i18n, delegateManagerUsersDataManager } = this.props;

    this.statusOptions = enumToArray(RequestStatus, 'string', 'text', 'value').map((item) => ({
      ...item,
      text: requestStatusUtils.getLocalizedText(i18n, item.value as string),
      iconCss: requestStatusUtils.getIconCss(item.value as string)
    }));

    if (currentUserCompany) {
      const myRule: IMyRule = {
        condition: RuleCondition.ANY,
        field: 'companies/companyId',
        operator: RuleOperator.EQUAL,
        value: currentUserCompany.id
      };
      this.usersQuery = queryBuilderService.buildQuery(this.props.usersDataManager, myRule, this.usersQuery);
    }

    delegateManagerUsersDataManager.executeQuery(this.delegateUserManagersQuery).then((e: ReturnOption) => {
      (e.result as IDelegateUserManagers[]).forEach((delegateUserManager: IDelegateUserManagers) => {
        if (!this.delegateUserManagersMap.some(d => d.userId === delegateUserManager.userId)) {
          this.delegateUserManagersMap.push(
            { userId: delegateUserManager.userId, firstName: delegateUserManager.firstName, lastName: delegateUserManager.lastName }
          );
        }
      });
    });
  }

  public isStatusDropDownListComponentEnabled(): boolean {
    return this.props.listMode === RequestsListMode.ALL;
  }

  public onStatusChange(e: ChangeEventArgs) {
    const status = e.value as string;
    this.props.onStatusChange(status);
  }

  public onCompaniesIdsChange(e: MultiSelectChangeEventArgs) {
    this.props.onCompaniesIdsChange(e.value as string[]);
  }

  public onApplicantsIdsChange(e: MultiSelectChangeEventArgs) {
    this.props.onApplicantsIdsChange(e.value as string[]);
  }

  public onBidderIdChange(e: ChangeEventArgs) {
    const bidderId = e.value as string;
    this.props.onBidderIdChange(bidderId);
  }

  public onBidderNameChange(value: string) {
    this.props.onBidderNameChange(value);
  }

  public onCenterIdChange(e: ChangeEventArgs) {
    const centerId = e.value as string;
    this.props.onCenterIdChange(centerId);
  }

  public onCenterNameChange(value: string) {
    this.props.onCenterNameChange(value);
  }

  public onUnecoIdChange(e: ChangeEventArgs) {
    const unecoId = e.value as string;
    this.props.onUnecoIdChange(unecoId);
  }

  public onUnecoNameChange(value: string) {
    this.props.onUnecoNameChange(value);
  }

  public onOrderChange(value: string) {
    this.props.onOrderChange(value);
  }

  public onManagerGroupsIdsChange(e: MultiSelectChangeEventArgs) {
    this.props.onManagerGroupsIdsChange(e.value as string[]);
  }

  public onManagerEmailUsersIdsChange(e: MultiSelectChangeEventArgs) {
    this.props.onManagerEmailUsersIdsChange(e.value as string[]);
  }

  public onDelegateManagerUsersIdsChange(e: MultiSelectChangeEventArgs) {
    this.props.onDelegateManagerUsersIdsChange(e.value as string[]);
  }

  public updateStatusDropDownListComponentValueTemplate() {
    if (this.statusDropDownListComponent) {
      const selectedStatusOption = this.statusOptions.find((statusOption) => statusOption.value === this.props.filter.status);
      if (selectedStatusOption) {
        this.statusDropDownListComponent.valueTemplate = `
          <div class="row no-gutters pl-3 pr-3 h-100">
            <div class="col align-self-center">
              <span class="${`mr-2 ${selectedStatusOption.iconCss}`}" ></span>
              <span>${selectedStatusOption.text}</span>
            </div>
          </div>
        `;
      }
    }
  }

  public shouldShowCustomFieldFilter(customFieldCode: string): boolean {
    const { listMode, requestCustomFieldSettings, isCurrentUserInternal } = this.props;

    if (isCurrentUserInternal) {
      return true;
    }

    const settings = requestCustomFieldSettings.find((s) => s.customFieldCode === customFieldCode);

    if (!settings) {
      return false;
    }

    switch (listMode) {
      case RequestsListMode.ALL:
        return settings.filterAll;
      case RequestsListMode.OPEN:
        return settings.filterOpen;
      case RequestsListMode.IN_PROGRESS:
        return settings.filterInProgress;
      default:
        return false;
    }
  }

  public render() {
    const {
      filter,
      bidderOptions,
      centerOptions,
      unecoOptions,
      isFetching,
      currentUserCompany,
      companiesDataManager,
      managerGroupsDataManager,
      managerEmailUsersDataManager,
      usersDataManager,
      onSubjectChange,
      onCodeChange,
      onCreatedDateChange,
      onReferenceNamesChange,
      onEciRequestChange,
      onOrderChange,
      onFilterButtonClick,
      i18n,
      isCurrentUserInternal
    } = this.props;

    return (
      <form onSubmit={(e: React.FormEvent) => { e.preventDefault(); onFilterButtonClick(); }}>
        <div className='row'>
          <div className='col-12 col-md-6'>
            <div className='form-group'>
              <div className='autocomplete-input'>
                <div className='autocomplete-label'>{i18n.t('request.subject')}</div>
                <TextBox
                  name='subject'
                  value={filter.subject}
                  showClearButton={true}
                  onChange={(value) => onSubjectChange(value)} />
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <div className='form-group'>
              <div className='autocomplete-input'>
                <div className='autocomplete-label'>{i18n.t('request.code')}</div>
                <TextBox
                  name='code'
                  value={filter.code}
                  showClearButton={true}
                  onChange={(value) => onCodeChange(value)} />
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <div className='form-group'>
              <div className='autocomplete-input'>
                <div className='autocomplete-label'>{i18n.t('request.status')}</div>
                <DropDownListComponent
                  fields={this.statusFields}
                  dataSource={this.statusOptions}
                  value={filter.status}
                  enabled={this.isStatusDropDownListComponentEnabled()}
                  ref={(dropDownListComponent: DropDownListComponent) => {
                    this.statusDropDownListComponent = dropDownListComponent;
                    this.updateStatusDropDownListComponentValueTemplate();
                  }}
                  change={(e) => this.onStatusChange(e)} />
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <div className='form-group'>
              <div className='autocomplete-input'>
                <div className='autocomplete-label' style={{ minWidth: 'auto' }}>
                  <span className='icon icon-company' title={i18n.t('request.company')} />
                </div>
                {currentUserCompany
                  ? (
                    <TextBox
                      name='company'
                      value={currentUserCompany.name}
                      readonly={true} />
                  )
                  : (
                    <MultiSelectComponent
                      query={this.companiesQuery}
                      dataSource={companiesDataManager}
                      fields={this.companiesFields}
                      sortOrder={this.companiesSortOrder}
                      mode='CheckBox'
                      filterType='Contains'
                      showSelectAll={true}
                      selectAllText={i18n.t('actions.selectAll')}
                      unSelectAllText={i18n.t('actions.unselectAll')}
                      value={filter.companiesIds}
                      ref={(multiSelectComponent: MultiSelectComponent): MultiSelectComponent => this
                        .companiesMultiSelectComponent = multiSelectComponent}
                      change={(e) => this.onCompaniesIdsChange(e)}
                      filtering={(e) => {
                        this.companiesFilteringEvent = e;
                        this.companiesFilteringEvent.cancel = true;
                        this.companiesFilteringDebounced();
                      }}>
                      <Inject services={[CheckBoxSelection]} />
                    </MultiSelectComponent>
                  )}
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <div className='form-group'>
              <div className='autocomplete-input'>
                <div className='autocomplete-label' style={{ minWidth: 'auto' }}>
                  <span className='icon icon-user-external' title={i18n.t('request.applicant')} />
                </div>
                <MultiSelectComponent
                  query={this.usersQuery}
                  dataSource={usersDataManager}
                  fields={this.usersFields}
                  sortOrder={this.usersSortOrder}
                  mode='CheckBox'
                  filterType='Contains'
                  showSelectAll={true}
                  selectAllText={i18n.t('actions.selectAll')}
                  unSelectAllText={i18n.t('actions.unselectAll')}
                  value={filter.applicantsIds}
                  ref={(multiSelectComponent: MultiSelectComponent): MultiSelectComponent => this.usersMultiSelectComponent = multiSelectComponent}
                  change={(e) => this.onApplicantsIdsChange(e)}
                  filtering={(e) => {
                    this.usersFilteringEvent = e;
                    this.usersFilteringEvent.cancel = true;
                    this.usersFilteringDebounced();
                  }}>
                  <Inject services={[CheckBoxSelection]} />
                </MultiSelectComponent>
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <div className='form-group'>
              <DateRange
                name={i18n.t('request.createdDate')}
                value={filter.createdDate}
                i18n={i18n}
                onChangeInput={(value) => onCreatedDateChange(value)} />
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <div className='form-group'>
              <div className='autocomplete-input'>
                <div className='autocomplete-label'>{i18n.t('request.reference')}</div>
                <TextBox
                  name='reference'
                  value={filter.referenceNames}
                  showClearButton={true}
                  onChange={(value) => onReferenceNamesChange(value)} />
              </div>
            </div>
          </div>
          {this.shouldShowCustomFieldFilter('eciRequest') &&
            <div className='col-12 col-md-6'>
              <div className='form-group'>
                <div className='autocomplete-input'>
                  <div className='autocomplete-label'>{i18n.t('requestCustomFields.eciRequest')}</div>
                  <TextBox
                    name='eciRequest'
                    value={filter.eciRequest}
                    showClearButton={true}
                    onChange={(value) => onEciRequestChange(value)} />
                </div>
              </div>
            </div>}
          {this.shouldShowCustomFieldFilter('bidderId') &&
            <div className='col-12 col-md-6'>
              <div className='form-group'>
                <div className='autocomplete-input'>
                  <div className='autocomplete-label'>{i18n.t('requestCustomFields.bidderId')}</div>
                  {isCurrentUserInternal && <TextBox
                    name='bidderName'
                    value={filter.bidderName}
                    showClearButton={true}
                    onChange={(value) => this.onBidderNameChange(value)} />}
                  {!isCurrentUserInternal && <DropDownListComponent
                    fields={this.requestCustomFieldOptionsFields}
                    dataSource={bidderOptions as unknown as Record<string, string>[]}
                    value={filter.bidderId}
                    showClearButton={true}
                    change={(e) => this.onBidderIdChange(e)} />}
                </div>
              </div>
            </div>}
          {this.shouldShowCustomFieldFilter('centerId') &&
            <div className='col-12 col-md-6'>
              <div className='form-group'>
                <div className='autocomplete-input'>
                  <div className='autocomplete-label'>{i18n.t('requestCustomFields.centerId')}</div>
                  {isCurrentUserInternal && <TextBox
                    name='centerName'
                    value={filter.centerName}
                    showClearButton={true}
                    onChange={(value) => this.onCenterNameChange(value)} />}
                  {!isCurrentUserInternal && <DropDownListComponent
                    fields={this.requestCustomFieldOptionsFields}
                    dataSource={centerOptions as unknown as Record<string, string>[]}
                    value={filter.centerId}
                    showClearButton={true}
                    change={(e) => this.onCenterIdChange(e)} />}
                </div>
              </div>
            </div>}
          {this.shouldShowCustomFieldFilter('unecoId') &&
            <div className='col-12 col-md-6'>
              <div className='form-group'>
                <div className='autocomplete-input'>
                  <div className='autocomplete-label'>{i18n.t('requestCustomFields.unecoId')}</div>
                  {isCurrentUserInternal && <TextBox
                    name='unecoName'
                    value={filter.unecoName}
                    showClearButton={true}
                    onChange={(value) => this.onUnecoNameChange(value)} />}
                  {!isCurrentUserInternal && <DropDownListComponent
                    fields={this.requestCustomFieldOptionsFields}
                    dataSource={unecoOptions as unknown as Record<string, string>[]}
                    value={filter.unecoId}
                    showClearButton={true}
                    change={(e) => this.onUnecoIdChange(e)} />}
                </div>
              </div>
            </div>}
          <div className='col-12 col-md-6'>
            <div className='form-group'>
              <div className='autocomplete-input'>
                <div className='autocomplete-label'>{i18n.t('request.managerGroup')}</div>
                <MultiSelectComponent
                  query={this.managerGroupsQuery}
                  dataSource={managerGroupsDataManager}
                  fields={this.managerGroupsFields}
                  sortOrder={this.managerGroupsSortOrder}
                  mode='CheckBox'
                  filterType='Contains'
                  showSelectAll={true}
                  selectAllText={i18n.t('actions.selectAll')}
                  unSelectAllText={i18n.t('actions.unselectAll')}
                  value={filter.managerGroupsIds}
                  ref={(multiSelectComponent: MultiSelectComponent): MultiSelectComponent => this.managerGroupsMultiSelectComponent = multiSelectComponent}
                  change={(e) => this.onManagerGroupsIdsChange(e)}
                  filtering={(e) => {
                    this.managerGroupsFilteringEvent = e;
                    this.managerGroupsFilteringEvent.cancel = true;
                    this.managerGroupsFilteringDebounced();
                  }}>
                  <Inject services={[CheckBoxSelection]} />
                </MultiSelectComponent>
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <div className='form-group'>
              <div className='autocomplete-input'>
                <div className='autocomplete-label'>{i18n.t('request.managerEmailUser')}</div>
                <MultiSelectComponent
                  query={this.managerEmailUsersQuery}
                  dataSource={managerEmailUsersDataManager}
                  fields={this.managerEmailUsersFields}
                  sortOrder={this.managerEmailUsersSortOrder}
                  mode='CheckBox'
                  showSelectAll={true}
                  selectAllText={i18n.t('actions.selectAll')}
                  unSelectAllText={i18n.t('actions.unselectAll')}
                  value={filter.managerEmailUsersIds}
                  ref={(multiSelectComponent: MultiSelectComponent): MultiSelectComponent => this.managerEmailUsersMultiSelectComponent = multiSelectComponent}
                  actionComplete={(e: any) => {
                    e.result = (e.result as IUser[]).map((r) => ({ ...r, fullName: `${r.firstName} ${r.lastName}` }));
                  }}
                  change={(e) => this.onManagerEmailUsersIdsChange(e)}
                  filtering={(e) => {
                    this.managerEmailUsersFilteringEvent = e;
                    this.managerEmailUsersFilteringEvent.cancel = true;
                    this.managerEmailUsersFilteringDebounced();
                  }}>
                  <Inject services={[CheckBoxSelection]} />
                </MultiSelectComponent>
              </div>
            </div>
          </div>
          {isCurrentUserInternal &&
            <div className='col-12 col-md-6'>
              <div className='form-group'>
                <div className='autocomplete-input'>
                  <div className='autocomplete-label'>{i18n.t('request.managerGroupUserName')}</div>
                  <MultiSelectComponent
                    query={this.delegateUserManagersQuery}
                    dataSource={this.delegateUserManagersMap}
                    fields={this.delegateUserManagersFields}
                    sortOrder={this.delegateUserManagersSortOrder}
                    mode='CheckBox'
                    filterType='Contains'
                    showSelectAll={true}
                    selectAllText={i18n.t('actions.selectAll')}
                    unSelectAllText={i18n.t('actions.unselectAll')}
                    value={filter.delegateUserManagersIds}
                    ref={(multiSelectComponent: MultiSelectComponent): MultiSelectComponent => this.delegateUserManagersMultiSelectComponent = multiSelectComponent}
                    actionComplete={(e: any) => {
                      e.result = (e.result as { [key: string]: unknown }[]).map((r) => ({ ...r, delegateFullName: `${r.firstName} ${r.lastName}` }));
                    }}
                    change={(e) => this.onDelegateManagerUsersIdsChange(e)}
                    filtering={(e) => {
                      let query = new Query();
                      const predicateFirstName = new Predicate('firstName', RuleOperator.CONTAINS, e.text, true, true);
                      const predicateLastName = new Predicate('lastName', RuleOperator.CONTAINS, e.text, true, true);
                      const fullPredicate = predicateFirstName.or(predicateLastName);

                      query = (e.text !== '') ? query.where(fullPredicate) : query;
                      e.updateData(this.delegateUserManagersMap, query);
                    }}>
                    <Inject services={[CheckBoxSelection]} />
                  </MultiSelectComponent>
                </div>
              </div>
            </div>}
          <div className='col-12 col-md-6'>
            <div className='form-group'>
              <div className='autocomplete-input'>
                <div className='autocomplete-label'>{i18n.t('request.order')}</div>
                <TextBox
                  name='order'
                  value={filter.order}
                  showClearButton={true}
                  onChange={(value) => onOrderChange(value)} />
              </div>
            </div>
          </div>
        </div>
        <div className='row justify-content-end'>
          <div className='col-auto'>
            <button type='submit' className='btn btn-secondary' title={i18n.t('actions.filter')} disabled={isFetching}>
              {isFetching
                ? <span className='spinner-border spinner-border-sm' />
                : <span className='icon icon-app-item-search' />
              }
            </button>
          </div>
        </div>
      </form>
    );
  }
}

export default withTranslation()(RequestsListFilter);
