export interface IValuesToScape {
  valuesToUnescape: string[];
  unescapedValue: string;
}

export interface IPropertiesToScape {
  propertyName: string;
  valuesToScape: IValuesToScape[];
}

export const htmlValuesToScope: IValuesToScape[] = [
  {
    valuesToUnescape: ["&quot;"],
    unescapedValue: "\""
  },
  {
    valuesToUnescape: ["&amp;"],
    unescapedValue: "&"
  },
  {
    valuesToUnescape: ["&lt;"],
    unescapedValue: "<"
  },
  {
    valuesToUnescape: ["&gt;"],
    unescapedValue: ">"
  },
  {
    valuesToUnescape: ["&OElig;"],
    unescapedValue: "Œ"
  },
  {
    valuesToUnescape: ["&oelig;"],
    unescapedValue: "œ"
  },
  {
    valuesToUnescape: ["&Scaron;"],
    unescapedValue: "Š"
  },
  {
    valuesToUnescape: ["&scaron;"],
    unescapedValue: "š"
  },
  {
    valuesToUnescape: ["&Yuml;"],
    unescapedValue: "Ÿ"
  },
  {
    valuesToUnescape: ["&circ;"],
    unescapedValue: "ˆ"
  },
  {
    valuesToUnescape: ["&tilde;"],
    unescapedValue: "˜"
  },
  {
    valuesToUnescape: ["&euro;"],
    unescapedValue: "€"
  }
];

/**
 * Emula el método hasOwnProperty de un elemento de Tipo Object para el Tipo unknown
 * @param obj
 * @param prop
 */
// eslint-disable-next-line @typescript-eslint/ban-types
function hasOwnProperty<TX extends {}, TY extends PropertyKey>(obj: TX, prop: TY): obj is TX & Record<TY, unknown> {
  // eslint-disable-next-line no-prototype-builtins
  return obj.hasOwnProperty(prop);
}

/**
 * Remplaza todas las ocurrencias de un valor por otro valor de la cadena de texto
 * @param value
 * @param searchValue
 * @param replaceValue
 */
export function replaceAllOccurrences(value: string, searchValue: string, replaceValue: string): string {
  if (value !== undefined && value !== null && value.trim() !== '' &&
    searchValue !== undefined && searchValue !== null &&
    replaceValue !== undefined && replaceValue !== null) {
    return value.split(searchValue).join(replaceValue);
  }

  return value;
}

/**
 * Escapa los valores que se le indiquen de la/s propiedad/es de la cadena de texto
 * @param value
 * @param valuesToEscape
 */
export function unescapeValuesInString(value: string, valuesToEscape: IValuesToScape[]): string {
  let newValue = value;
  if (newValue !== undefined && newValue !== null && newValue.trim() !== '') {
    valuesToEscape.forEach((valueToScape: IValuesToScape) => {
      if (valueToScape.valuesToUnescape !== null && valueToScape.valuesToUnescape.length > 0) {
        valueToScape.valuesToUnescape.forEach((value: string) => {
          newValue = replaceAllOccurrences(newValue, value, valueToScape.unescapedValue);
        });
      }
    });
  }

  return newValue;
}
  
/**
 * Escapa los valores que se le indiquen de la/s propiedad/es del objeto
 * @param item
 * @param elementsToEscape
 */
export function unescapeValuesInObjectProperty(item: unknown, elementsToEscape: IPropertiesToScape[]): void {
  let newValue = '';

  elementsToEscape.forEach((element: IPropertiesToScape) => {
    if (element.propertyName !== null && element.propertyName.trim() !== '') {
      if (typeof item === 'object' && hasOwnProperty(item, element.propertyName)) {
        newValue = item[element.propertyName] !== undefined && item[element.propertyName] !== null ? item[element.propertyName].toString() : null;
        item[element.propertyName] = unescapeValuesInString(newValue, element.valuesToScape);
      }
    }
  });
}

/**
 * Remplaza mediante expresión regular todos los {i} por el valor array[i]
 * @param string
 * @param params
 */
export function format(string: string, params: string[]): string {
  for (let i = 0; i < params.length; i++) {
    const reg = new RegExp("\\{" + i + "\\}", "gm");
    string = string.replace(reg, params[i]);
  }
  return string;
}
