import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig } from 'axios';

import { configureInterceptors } from './httpClientInterceptors';
import store from '../../store';
import { ISettings } from '../../common/model/settings.model';
import * as fileUtils from '../../utils/file.utils';

export interface IHttpClient {
  client: AxiosInstance;
  init(settings: ISettings): void;
  get(url: string, queryParams?: any): Promise<any>;
  getCustom<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
  post(url: string, body: any, headers?: any): Promise<any>;
  postCustom<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
  delete(url: string, headers?: any): Promise<any>;
  deleteCustom<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
  getFile(url: string): Promise<any>;
  postFile(url: string, body: any): Promise<any>;
  put(url: string, body: any, headers?: any): Promise<any>;
  putCustom<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
  patchCustom<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
  downloadFile(url: string, filenameWithoutExtension?: string, config?: AxiosRequestConfig): Promise<void>;
}

class HttpClient implements IHttpClient {
  public client: AxiosInstance;

  public init(settings: ISettings) {
    this.client = axios.create({
      baseURL: settings.api
    });

    configureInterceptors(this.client);
  }

  public async get(url: string, queryParams = {}): Promise<AxiosResponse<any>> {
    const headers = this.generateAuthenticationHeaders();

    const response = await this.client.get(url, { params: queryParams, headers });
    return response.data;
  }

  public async getCustom<T = any>(url: string, config: AxiosRequestConfig = {}): Promise<T> {
    config.headers = { ...config.headers, ...this.generateAuthenticationHeaders() };
    return (await this.client.get<T>(url, config)).data;
  }

  public async getFile(url: string, queryParams = {}): Promise<AxiosResponse<any>> {
    const headers = this.generateAuthenticationHeaders();

    const response = await this.client.get(url, { params: queryParams, headers, responseType: 'blob' });

    return response;
  }

  public async postFile(url: string, body: any, queryParams = {}): Promise<AxiosResponse<any>> {
    const headers = this.generateAuthenticationHeaders();
    const response = await this.client.post(url, body, { params: queryParams, headers, responseType: 'blob' });
    return response;
  }

  public async post(url: string, body: any, additionalHeaders?: any): Promise<AxiosResponse<any>> {
    const headers = { ...this.generateAuthenticationHeaders(), ...additionalHeaders };
    const parsedBody = typeof body === 'string' ? '"' + body + '"' : body;
    const response = await this.client.post(url, parsedBody, { headers });
    return response;
  }

  public async postCustom<T = any>(url: string, data?: any, config: AxiosRequestConfig = {}): Promise<T> {
    config.headers = { ...config.headers, ...this.generateAuthenticationHeaders() };
    return (await this.client.post<T>(url, data, config)).data;
  }

  public async delete(url: string): Promise<AxiosResponse<any>> {
    const headers = this.generateAuthenticationHeaders();

    const response = await this.client.delete(url, { headers });
    return response;
  }

  public async deleteCustom<T = any>(url: string, config: AxiosRequestConfig = {}): Promise<T> {
    config.headers = { ...config.headers, ...this.generateAuthenticationHeaders() };
    return (await this.client.delete<T>(url, config)).data;
  }

  public async put(url: string, body: any, additionalHeaders?: any): Promise<AxiosResponse<any>> {
    const headers = { ...this.generateAuthenticationHeaders(), ...additionalHeaders };
    const parsedBody = typeof body === 'string' ? '"' + body + '"' : body;

    const response = await this.client.put(url, parsedBody, { headers });
    return response;
  }

  public async putCustom<T = any>(url: string, data?: any, config: AxiosRequestConfig = {}): Promise<T> {
    config.headers = { ...config.headers, ...this.generateAuthenticationHeaders() };
    return (await this.client.put<T>(url, data, config)).data;
  }

  public async patchCustom<T = any>(url: string, data?: any, config: AxiosRequestConfig = {}): Promise<T> {
    config.headers = { ...config.headers, ...this.generateAuthenticationHeaders() };
    return (await this.client.patch<T>(url, data, config)).data;
  }

  public async downloadFile(url: string, filenameWithoutExtension?: string, config: AxiosRequestConfig = {}): Promise<void> {
    config.headers = { ...config.headers, ...this.generateAuthenticationHeaders() };
    config.responseType = 'blob';

    const res = await this.client.get<Blob>(url, config);
    let filename = fileUtils.getFilenameFromContentDisposition(res.headers);

    if (filenameWithoutExtension) {
      filename = filename.replace(filename.substring(0, filename.lastIndexOf('.')), filenameWithoutExtension);
    }

    fileUtils.saveFile(res.data, filename);
  }

  private generateAuthenticationHeaders(): any {
    const headers: any = {};

    const accessToken = store.getState().currentUserStore.accessToken;

    if (accessToken) {
      headers.Authorization = `Bearer ${accessToken}`;
    }

    return headers;
  }
}

export const httpClient: IHttpClient = new HttpClient();
