import { URL } from '../common/constants';
import { ICertificate, IShareCertificate } from '../common/model/certificate.model';
import { ISharedCertificateState } from '../interfaces/states/ISharedCertificateState';
import * as services from '.';

export interface ICertificatesService {
  downloadFile(certificate: ICertificate): Promise<void>;
  checkExpiration(): Promise<void>;
  requestRenewal(ids: string[]): Promise<void>;
  requestRenewalCancellation(ids: string[]): Promise<void>;
  shareCertification(certificate: IShareCertificate): Promise<void>;
  getSharedCertification(id: string): Promise<ISharedCertificateState>;
  getByQrCertification(reportNumber: string, reversion: number, lenguage: string): Promise<ISharedCertificateState>;
}

class CertificatesService implements ICertificatesService {
  public constructor(private httpClient: services.IHttpClient) {

  }

  public downloadFile(certificate: ICertificate): Promise<void> {
    const filenameWithoutExtension = certificate.identificationCode;
    return this.httpClient.downloadFile(`${URL.WEB.BASE}${URL.WEB.CERTIFICATES}/${certificate.id}/file`, filenameWithoutExtension);
  }

  public checkExpiration(): Promise<void> {
    return this.httpClient.postCustom(`${URL.WEB.BASE}${URL.WEB.CERTIFICATES}/checkExpiration`);
  }

  public requestRenewal(ids: string[]): Promise<void> {
    return this.httpClient.postCustom(`${URL.WEB.BASE}${URL.WEB.CERTIFICATES}/renewal`, ids);
  }

  public requestRenewalCancellation(ids: string[]): Promise<void> {
    return this.httpClient.postCustom(`${URL.WEB.BASE}${URL.WEB.CERTIFICATES}/cancelation`, ids);
  }

  public shareCertification(certificate: IShareCertificate): Promise<void> {
    return this.httpClient.postCustom(`${URL.WEB.BASE}${URL.WEB.CERTIFICATES}/share`, certificate);
  }

  public getSharedCertification(id: string): Promise<ISharedCertificateState> {
    return this.httpClient.get(`${URL.WEB.BASE}${URL.WEB.CERTIFICATES}/shared/${id}`);
  }

  public getByQrCertification(reportNumber: string, reversion: number, lenguage: string): Promise<ISharedCertificateState> {
    return this.httpClient.get(`${URL.WEB.BASE}${URL.WEB.CERTIFICATES}/byQr/${reportNumber}/${reversion}/${lenguage}`);
  }
}

export const certificatesService = new CertificatesService(services.httpClient);
