import { URL } from '../common/constants';
import { INote } from '../common/model/note.model';
import { SourceType } from '../common/model/enumerations/sourceType.model';
import * as services from '.';

export interface INotesService {
  create(note: INote): Promise<INote>;
  deactivate(id: string): Promise<void>;
}

class NotesService implements INotesService {
  public constructor(private httpClient: services.IHttpClient) {

  }

  public create(note: INote): Promise<INote> {
    switch (note.sourceType) {
      case SourceType.REPORT:
        return this.httpClient.postCustom(`${URL.WEB.BASE}${URL.WEB.REPORTS}/${note.sourceId}/notes`, note);
      case SourceType.REQUEST:
        return this.httpClient.postCustom(`${URL.WEB.BASE}${URL.WEB.REQUESTS}/${note.sourceId}/notes`, note);
      default:
        throw new Error();
    }
  }

  public deactivate(id: string): Promise<void> {
    return this.httpClient.deleteCustom(`${URL.WEB.BASE}${URL.WEB.NOTES}/${id}`);
  }
}

export const notesService = new NotesService(services.httpClient);
