import { Action, Reducer, ActionCreator, combineReducers } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { batch } from 'react-redux';

import { RootState } from '..';
import { IReportDetailState } from '../../interfaces/states/IReportDetailState';
import { IReport } from '../../common/model/report.model';
import { ICustomFieldPermission } from '../../common/model/customFieldPermission.model';
import { ICustomFieldOptions } from '../../common/model/customFieldOptions.model';
import { IReportCustomFields } from '../../common/model/reportCustomFields.model';
import { IAnnex } from '../../common/model/annex.model';
import { ICertificate } from '../../common/model/certificate.model';
import { IReference } from '../../common/model/reference.model';
import { INotificationWithViewInfo } from '../../common/model/notification.model';
import { INote } from '../../common/model/note.model';
import { IReportChangeHistory } from '../../common/model/reportChangeHistory.model';
import { UserType } from '../../common/model/enumerations/userType.model';
import { IReportsService } from '../../services';

enum Type {
  RESET = '@@reportDetail/RESET',
  MAKE_READY_FOR_RENDERING = '@@reportDetail/MAKE_READY_FOR_RENDERING',
  SET_REPORT = '@@reportDetail/SET_REPORT',
  SET_REPORT_CUSTOM_FIELDS = '@@reportDetail/SET_REPORT_CUSTOM_FIELDS',
  SET_REPORT_INITIAL = '@@reportDetail/SET_REPORT_INITIAL',
  SET_IS_VIEW = '@@reportDetail/SET_IS_VIEW',
  SET_CUSTOM_FIELD_PERMISSIONS = '@@reportDetail/SET_CUSTOM_FIELD_PERMISSIONS',
  SET_QUALITY_CONTROL_OPTIONS = '@@reportDetail/SET_QUALITY_CONTROL_OPTIONS',
  SET_BUYER_OPTIONS = '@@reportDetail/SET_BUYER_OPTIONS',
  SET_ANNEXES = '@@reportDetail/SET_ANNEXES',
  SET_CERTIFICATES = '@@reportDetail/SET_CERTIFICATES',
  SET_REFERENCES = '@@reportDetail/SET_REFERENCES',
  SET_IS_FETCHING_REFERENCES = '@@reportDetail/SET_IS_FETCHING_REFERENCES',
  SET_NOTIFICATIONS = '@@reportDetail/SET_NOTIFICATIONS',
  MARK_NOTIFICATION_AS_SEEN_LOCAL = '@@reportDetail/MARK_NOTIFICATION_AS_SEEN_LOCAL',
  SET_NOTES = '@@reportDetail/SET_NOTES',
  SET_REPORT_CHANGES_HISTORY = '@@reportDetail/SET_REPORT_CHANGES_HISTORY',
  SET_IS_UNAUTHORIZED = '@@reportDetail/SET_IS_UNAUTHORIZED'
}

const initialState: IReportDetailState = {
  isReadyForRendering: false,
  report: null,
  reportInitial: null,
  isView: null, // must be !== null when isReadyForRendering === true
  customFieldPermissions: [],
  qualityControlOptions: [],
  buyerOptions: [],
  annexes: [],
  certificates: [],
  references: [],
  isFetchingReferences: false,
  notifications: [],
  notes: [],
  reportChangesHistory: [],
  isUnauthorized: false
};

// Actions

export type Actions = ResetAction | MakeReadyForRenderingAction | SetReportAction | SetReportCustomFieldsAction | SetReportInitialAction | SetIsViewAction | SetCustomFieldPermissionsAction
  | SetQualityControlOptionsAction | SetBuyerOptionsAction | SetAnnexesAction | SetCertificatesAction | SetIsFetchingReferencesAction | SetReferencesAction | SetNotificationsAction
  | MarkNotificationAsSeenLocalAction | SetNotesAction | SetReportChangesHistoryAction | SetIsUnauthorizedAction;

export type ResetAction = Action<Type.RESET>

export interface MakeReadyForRenderingAction extends Action<Type.MAKE_READY_FOR_RENDERING> {
  isView: boolean;
}

export interface SetReportAction extends Action<Type.SET_REPORT> {
  report: IReport;
}

export interface SetReportCustomFieldsAction extends Action<Type.SET_REPORT_CUSTOM_FIELDS> {
  customFields: IReportCustomFields;
}

export interface SetReportInitialAction extends Action<Type.SET_REPORT_INITIAL> {
  reportInitial: IReport;
}

export interface SetIsViewAction extends Action<Type.SET_IS_VIEW> {
  isView: boolean;
}

export interface SetCustomFieldPermissionsAction extends Action<Type.SET_CUSTOM_FIELD_PERMISSIONS> {
  customFieldPermissions: ICustomFieldPermission[];
}

export interface SetQualityControlOptionsAction extends Action<Type.SET_QUALITY_CONTROL_OPTIONS> {
  qualityControlOptions: ICustomFieldOptions[];
}

export interface SetBuyerOptionsAction extends Action<Type.SET_BUYER_OPTIONS> {
  buyerOptions: ICustomFieldOptions[];
}

export interface SetAnnexesAction extends Action<Type.SET_ANNEXES> {
  annexes: IAnnex[];
}

export interface SetCertificatesAction extends Action<Type.SET_CERTIFICATES> {
  certificates: ICertificate[];
}

export interface SetReferencesAction extends Action<Type.SET_REFERENCES> {
  references: IReference[];
}

export interface SetIsFetchingReferencesAction extends Action<Type.SET_IS_FETCHING_REFERENCES> {
  isFetchingReferences: boolean;
}

export interface SetNotificationsAction extends Action<Type.SET_NOTIFICATIONS> {
  notifications: INotificationWithViewInfo[];
}

export interface MarkNotificationAsSeenLocalAction extends Action<Type.MARK_NOTIFICATION_AS_SEEN_LOCAL> {
  notificationId: string;
}

export interface SetNotesAction extends Action<Type.SET_NOTES> {
  notes: INote[];
}

export interface SetReportChangesHistoryAction extends Action<Type.SET_REPORT_CHANGES_HISTORY> {
  reportChangesHistory: IReportChangeHistory[];
}

export interface SetIsUnauthorizedAction extends Action<Type.SET_IS_UNAUTHORIZED> {
  isUnauthorized: boolean;
}

export const reset: ActionCreator<ResetAction> = () => ({
  type: Type.RESET
});

export const makeReadyForRendering: ActionCreator<MakeReadyForRenderingAction> = (isViewLocal: boolean) => ({
  type: Type.MAKE_READY_FOR_RENDERING,
  isView: isViewLocal
});

export const setReport: ActionCreator<SetReportAction> = (reportLocal: IReport) => ({
  type: Type.SET_REPORT,
  report: reportLocal
});

export const setReportCustomFields: ActionCreator<SetReportCustomFieldsAction> = (customFields: IReportCustomFields) => ({
  type: Type.SET_REPORT_CUSTOM_FIELDS,
  customFields
});

export const setReportInitial: ActionCreator<SetReportInitialAction> = (reportInitialLocal: IReport) => ({
  type: Type.SET_REPORT_INITIAL,
  reportInitial: reportInitialLocal
});

export const setIsView: ActionCreator<SetIsViewAction> = (isViewLocal: boolean) => ({
  type: Type.SET_IS_VIEW,
  isView: isViewLocal
});

export const setCustomFieldPermissions: ActionCreator<SetCustomFieldPermissionsAction> = (customFieldPermissionsLocal: ICustomFieldPermission[]) => ({
  type: Type.SET_CUSTOM_FIELD_PERMISSIONS,
  customFieldPermissions: customFieldPermissionsLocal
});

export const setQualityControlOptions: ActionCreator<SetQualityControlOptionsAction> = (qualityControlOptionsLocal: ICustomFieldOptions[]) => ({
  type: Type.SET_QUALITY_CONTROL_OPTIONS,
  qualityControlOptions: qualityControlOptionsLocal
});

export const setBuyerOptions: ActionCreator<SetBuyerOptionsAction> = (buyerOptionsLocal: ICustomFieldOptions[]) => ({
  type: Type.SET_BUYER_OPTIONS,
  buyerOptions: buyerOptionsLocal
});

export const setAnnexes: ActionCreator<SetAnnexesAction> = (annexesLocal: IAnnex[]) => ({
  type: Type.SET_ANNEXES,
  annexes: annexesLocal
});

export const setCertificates: ActionCreator<SetCertificatesAction> = (certificatesLocal: ICertificate[]) => ({
  type: Type.SET_CERTIFICATES,
  certificates: certificatesLocal
});

export const setReferences: ActionCreator<SetReferencesAction> = (referencesLocal: IReference[]) => ({
  type: Type.SET_REFERENCES,
  references: referencesLocal
});

export const setIsFetchingReferences: ActionCreator<SetIsFetchingReferencesAction> = (isFetchingReferencesLocal: boolean) => ({
  type: Type.SET_IS_FETCHING_REFERENCES,
  isFetchingReferences: isFetchingReferencesLocal
});

export const setNotifications: ActionCreator<SetNotificationsAction> = (notificationsLocal: INotificationWithViewInfo[]) => ({
  type: Type.SET_NOTIFICATIONS,
  notifications: notificationsLocal
});

export const markNotificationAsSeenLocal: ActionCreator<MarkNotificationAsSeenLocalAction> = (notificationId: string) => ({
  type: Type.MARK_NOTIFICATION_AS_SEEN_LOCAL,
  notificationId
});

export const setNotes: ActionCreator<SetNotesAction> = (notesLocal: INote[]) => ({
  type: Type.SET_NOTES,
  notes: notesLocal
});

export const setReportChangesHistory: ActionCreator<SetReportChangesHistoryAction> = (reportChangesHistoryLocal: IReportChangeHistory[]) => ({
  type: Type.SET_REPORT_CHANGES_HISTORY,
  reportChangesHistory: reportChangesHistoryLocal
});

export const setIsUnauthorized: ActionCreator<SetIsUnauthorizedAction> = (isUnauthorized: boolean) => ({
  type: Type.SET_IS_UNAUTHORIZED,
  isUnauthorized: isUnauthorized
});

export const fetchReport: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (id: string, reportsService: IReportsService) => (
  async (dispatch, getState) => {
    try {
      const isCurrentUserInternal = getState().currentUserStore.user.type === UserType.INTERNAL;

      let customFieldPermissionsLocal = initialState.customFieldPermissions;
      let qualityControlOptionsLocal = initialState.qualityControlOptions;
      let buyerOptionsLocal = initialState.buyerOptions;

      const reportLocal = await reportsService.getReportDetail(id);

      if (!isCurrentUserInternal) {
        customFieldPermissionsLocal = await reportsService.fetchCustomFieldPermissions(id);
        qualityControlOptionsLocal = await reportsService.fetchCustomFieldOptions('qualityControlId');
        buyerOptionsLocal = await reportsService.fetchCustomFieldOptions('buyerId');
      }

      batch(() => {
        dispatch(setReport(reportLocal));
        dispatch(setReportInitial(reportLocal));
        dispatch(setCustomFieldPermissions(customFieldPermissionsLocal));
      });

      dispatch(setQualityControlOptions(qualityControlOptionsLocal));
      dispatch(setBuyerOptions(buyerOptionsLocal));
    } catch (error: any) {
      if (error['403']) {
        dispatch(setIsUnauthorized(true));
      }
    }
  }
);

export const resetReport: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = () => (
  async (dispatch, getState) => {
    dispatch(setReport(getState().reportDetailStore.reportInitial));
  }
);

export const saveCustomFields: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (customFields: IReportCustomFields) => (
  async (dispatch, getState) => {
    dispatch(setReport({ ...getState().reportDetailStore.reportInitial, customFields }));
    dispatch(setReportInitial({ ...getState().reportDetailStore.reportInitial, customFields }));
  }
);

export const fetchAnnexes: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (reportId: string, reportsService: IReportsService) => (
  async (dispatch) => {
    dispatch(setAnnexes(await reportsService.fetchAnnexes(reportId)));
  }
);

export const fetchCertificates: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (reportId: string, reportsService: IReportsService) => (
  async (dispatch) => {
    dispatch(setCertificates(await reportsService.fetchCertificates(reportId)));
  }
);

export const fetchReferences: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (reportId: string, reportsService: IReportsService) => (
  async (dispatch) => {
    dispatch(setIsFetchingReferences(true));

    const referencesLocal = await reportsService.fetchReferences(reportId);
    batch(() => {
      dispatch(setReferences(referencesLocal));
      dispatch(setIsFetchingReferences(false));
    });
  }
);

export const fetchNotifications: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (reportId: string, reportsService: IReportsService) => (
  async (dispatch, getState) => {
    const isCurrentUserInternal = getState().currentUserStore.user.type === UserType.INTERNAL;
    let notificationsLocal = await reportsService.fetchNotifications(reportId);
    if (isCurrentUserInternal) {
      notificationsLocal = notificationsLocal.map((notification) => ({ ...notification, seen: true }));
    }
    dispatch(setNotifications(notificationsLocal));
  }
);

export const fetchNotes: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (reportId: string, reportsService: IReportsService) => (
  async (dispatch) => {
    dispatch(setNotes(await reportsService.fetchNotes(reportId)));
  }
);

export const fetchReportChangesHistory: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (reportId: string, reportsService: IReportsService) => (
  async (dispatch) => {
    dispatch(setReportChangesHistory(await reportsService.fetchReportChangesHistory(reportId)));
  }
);

// Reducers

const isReadyForRendering: Reducer<boolean> = (state = initialState.isReadyForRendering, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.isReadyForRendering;
    case Type.MAKE_READY_FOR_RENDERING:
      return true;
    default:
      return state;
  }
};

const report: Reducer<IReport> = (state = initialState.report, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.report;
    case Type.SET_REPORT:
      return (action as SetReportAction).report;
    case Type.SET_REPORT_CUSTOM_FIELDS:
      return { ...state, customFields: (action as SetReportCustomFieldsAction).customFields };
    default:
      return state;
  }
};

const reportInitial: Reducer<IReport> = (state = initialState.reportInitial, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.reportInitial;
    case Type.SET_REPORT_INITIAL:
      return (action as SetReportInitialAction).reportInitial;
    default:
      return state;
  }
};

const isView: Reducer<boolean> = (state = initialState.isView, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.isView;
    case Type.MAKE_READY_FOR_RENDERING:
      return (action as MakeReadyForRenderingAction).isView;
    case Type.SET_IS_VIEW:
      return (action as SetIsViewAction).isView;
    default:
      return state;
  }
};

const customFieldPermissions: Reducer<ICustomFieldPermission[]> = (state = initialState.customFieldPermissions, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.customFieldPermissions;
    case Type.SET_CUSTOM_FIELD_PERMISSIONS:
      return (action as SetCustomFieldPermissionsAction).customFieldPermissions;
    default:
      return state;
  }
};

const qualityControlOptions: Reducer<ICustomFieldOptions[]> = (state = initialState.qualityControlOptions, action) => {
  switch (action.type) {
    case Type.RESET:
      return state;
    case Type.SET_QUALITY_CONTROL_OPTIONS:
      return (action as SetQualityControlOptionsAction).qualityControlOptions;
    default:
      return state;
  }
};

const buyerOptions: Reducer<ICustomFieldOptions[]> = (state = initialState.buyerOptions, action) => {
  switch (action.type) {
    case Type.RESET:
      return state;
    case Type.SET_BUYER_OPTIONS:
      return (action as SetBuyerOptionsAction).buyerOptions;
    default:
      return state;
  }
};

const annexes: Reducer<IAnnex[]> = (state = initialState.annexes, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.annexes;
    case Type.SET_ANNEXES:
      return (action as SetAnnexesAction).annexes;
    default:
      return state;
  }
};

const certificates: Reducer<ICertificate[]> = (state = initialState.certificates, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.certificates;
    case Type.SET_CERTIFICATES:
      return (action as SetCertificatesAction).certificates;
    default:
      return state;
  }
};

const references: Reducer<IReference[]> = (state = initialState.references, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.references;
    case Type.SET_REFERENCES:
      return (action as SetReferencesAction).references;
    default:
      return state;
  }
};

const isFetchingReferences: Reducer<boolean> = (state = initialState.isFetchingReferences, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.isFetchingReferences;
    case Type.SET_IS_FETCHING_REFERENCES:
      return (action as SetIsFetchingReferencesAction).isFetchingReferences;
    default:
      return state;
  }
};

const notifications: Reducer<INotificationWithViewInfo[]> = (state = initialState.notifications, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.notifications;
    case Type.SET_NOTIFICATIONS:
      return (action as SetNotificationsAction).notifications;
    case Type.MARK_NOTIFICATION_AS_SEEN_LOCAL:
      return state.map((notification) => {
        if (notification.id === (action as MarkNotificationAsSeenLocalAction).notificationId) {
          return {
            ...notification,
            seen: true
          };
        }
        return notification;
      });
    default:
      return state;
  }
};

const notes: Reducer<INote[]> = (state = initialState.notes, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.notes;
    case Type.SET_NOTES:
      return (action as SetNotesAction).notes;
    default:
      return state;
  }
};

const reportChangesHistory: Reducer<IReportChangeHistory[]> = (state = initialState.reportChangesHistory, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.reportChangesHistory;
    case Type.SET_REPORT_CHANGES_HISTORY:
      return (action as SetReportChangesHistoryAction).reportChangesHistory;
    default:
      return state;
  }
};

const isUnauthorized: Reducer<boolean> = (state = initialState.isUnauthorized, action) => {
  switch (action.type) {
    case Type.RESET:
      return initialState.isUnauthorized;
    case Type.SET_IS_UNAUTHORIZED:
      return (action as SetIsUnauthorizedAction).isUnauthorized;
    default:
      return state;
  }
};

export const reportDetailStore = combineReducers({
  isReadyForRendering,
  report,
  reportInitial,
  isView,
  customFieldPermissions,
  qualityControlOptions,
  buyerOptions,
  annexes,
  certificates,
  isFetchingReferences,
  references,
  notifications,
  notes,
  reportChangesHistory,
  isUnauthorized
});
