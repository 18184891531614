import React, { Component, createRef } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { DropDownListComponent, AutoCompleteComponent, ChangeEventArgs as DropDownChangeEventArgs, FieldSettingsModel } from '@syncfusion/ej2-react-dropdowns';
import { SwitchComponent, ChangeEventArgs as ButtonChangeEventArgs } from '@syncfusion/ej2-react-buttons';
import { Query } from '@syncfusion/ej2-data';
import { SortOrder } from '@syncfusion/ej2-navigations';

import { IAdvancedFilterSaveFormProps } from '../../interfaces/props/IAdvancedFilterSaveFormProps';
import { ICompanyCombo } from '../../common/model/company.model';
import { IStringToAnyDictionary } from '../../common/model/stringToAnyDictionary.model';
import { AdvancedFilterType } from '../../common/model/enumerations/advancedFilterType.model';
import { Language } from '../../common/model/enumerations/language.model';
import * as advancedFilterUtils from '../../utils/advancedFilter.utils';
import { enumToArray } from '../../utils/enum.utils';
import { notifySuccess } from '../../utils/toast.utils';
import ValidationError from '../validationError/validationError';
import TextBox from '../filters/textBox';

type AdvancedFilterSaveFormPropsType = IAdvancedFilterSaveFormProps & WithTranslation;

class AdvancedFilterSaveForm extends Component<AdvancedFilterSaveFormPropsType> {
  public typeOptions: IStringToAnyDictionary[] = [];
  public typeFields: FieldSettingsModel = { text: 'text', value: 'value' };

  public companiesQuery = new Query().select(['id', 'name']).take(10);
  public companiesFields: FieldSettingsModel = { value: 'name' };
  public initialCompaniesFields: FieldSettingsModel = { text: 'name', value: 'id' };
  public companiesSortOrder: SortOrder = 'Ascending';
  public companiesAutoCompleteComponent: AutoCompleteComponent;

  private shareUrlInput: React.RefObject<HTMLInputElement>;

  public languages = [Language.ENGLISH, Language.SPANISH];

  public constructor(props: AdvancedFilterSaveFormPropsType) {
    super(props);

    this.typeOptions = enumToArray(AdvancedFilterType, 'number', 'text', 'value')
      .filter((item) => !(!this.props.isCurrentUserInternal && item.value === AdvancedFilterType.GLOBAL))
      .map((item) => ({ ...item, text: advancedFilterUtils.getTypeLocalizedTextByKey(this.props.i18n, item.text as string, this.props.isCurrentUserInternal) }));

    this.shareUrlInput = createRef();
  }

  public onNameChange(name: string) {
    this.props.onTranslationChange({ codeIso: this.props.currentLanguage, name });
  }

  public onTypeChange(e: DropDownChangeEventArgs) {
    const type = e.value as number;
    this.props.onTypeChange(type);
  }

  public onCompanyChange(e: DropDownChangeEventArgs) {
    const company = e.itemData ? e.itemData as ICompanyCombo : null;
    this.props.onCompanyChange(company);
  }

  public onCompanyAutoCompleteComponentFocus() {
    this.companiesAutoCompleteComponent.fields = this.companiesFields;
  }

  public shouldShowCompanyAutoCompleteComponent(): boolean {
    return this.props.advancedFilter.type === AdvancedFilterType.COMPANY;
  }

  public onSaveInOtherLanguagesChange(e: ButtonChangeEventArgs) {
    const { onAddAdditionalTranslations, onRemoveAdditionalTranslations } = this.props;

    if (e.checked) {
      onAddAdditionalTranslations(this.languages);
    } else {
      onRemoveAdditionalTranslations();
    }
  }

  public onTranslationChange(name: string, codeIso: string) {
    this.props.onTranslationChange({ codeIso, name });
  }

  public onIsFavouriteChange(e: ButtonChangeEventArgs) {
    this.props.onIsFavouriteChange(e.checked);
  }

  public copyShareUrlToClipboard(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    if (this.shareUrlInput.current) {
      this.shareUrlInput.current.select();
      document.execCommand('copy');
      e.currentTarget.focus();
      notifySuccess(this.props.i18n.t('advancedFilterSaveForm.messages.shareUrlCopied'));
    }
  }

  public render() {
    const { advancedFilter, isCurrentUserInternal, currentLanguage, formId, companiesDataManager, errors, onFormRef, i18n } = this.props;

    return (
      <form id={formId} ref={() => onFormRef()}>
        <div className='row'>
          <div className='col'>
            <div className='form-group'>
              <div className='autocomplete-input'>
                <div className='autocomplete-label'>{i18n.t('advancedFilter.name')}</div>
                <TextBox
                  name='Name'
                  value={advancedFilterUtils.getName(advancedFilter, currentLanguage)}
                  onChange={(value) => this.onNameChange(value)}
                />
              </div>
              <ValidationError errors={errors} errorKey={'Name'} />
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <div className='form-group'>
              <div className='autocomplete-input'>
                <div className='autocomplete-label'>{i18n.t('advancedFilter.type')}</div>
                <DropDownListComponent
                  dataSource={this.typeOptions}
                  fields={this.typeFields}
                  value={advancedFilter.type}
                  change={(e: DropDownChangeEventArgs) => this.onTypeChange(e)}
                />
              </div>
            </div>
          </div>
        </div>
        {this.shouldShowCompanyAutoCompleteComponent() && <div className='row'>
          <div className='col'>
            <div className='form-group'>
              <div className='autocomplete-input'>
                <div className='autocomplete-label'>{i18n.t('advancedFilter.company')}</div>
                {isCurrentUserInternal ?
                  <AutoCompleteComponent
                    query={this.companiesQuery}
                    dataSource={companiesDataManager}
                    fields={this.initialCompaniesFields}
                    sortOrder={this.companiesSortOrder}
                    filterType='Contains'
                    allowCustom={false}
                    value={advancedFilter.companyName}
                    ref={(autoComplete: AutoCompleteComponent) => this.companiesAutoCompleteComponent = autoComplete}
                    focus={() => this.onCompanyAutoCompleteComponentFocus()}
                    change={(e) => this.onCompanyChange(e)}
                  />
                  :
                  <TextBox
                    name='company'
                    value={advancedFilter.companyName}
                    readonly={true}
                  />
                }

              </div>
            </div>
          </div>
        </div>}
        <div className='row'>
          <div className='col'>
            <div className='form-group d-flex' style={{ marginTop: '7px' }}>
              <label htmlFor='saveInOtherLanguages' className='mr-2'>{i18n.t('advancedFilterComponent.actions.saveInOtherLanguages')}</label>
              <div style={{ marginTop: '0.15rem' }}>
                <SwitchComponent
                  id='saveInOtherLanguages'
                  checked={advancedFilter.translations.length > 1}
                  change={(e: ButtonChangeEventArgs) => this.onSaveInOtherLanguagesChange(e)}
                />
              </div>
            </div>
          </div>
        </div>
        {advancedFilter.translations.length > 1 && this.languages.filter((language) => language !== currentLanguage).map((language) => (
          <div key={language} className='row'>
            <div className='col'>
              <div className='form-group'>
                <div className='autocomplete-input'>
                  <div className='autocomplete-label'>{i18n.t(`enums.language.${language}`)}</div>
                  <TextBox
                    name={`Name${language}`}
                    value={advancedFilterUtils.getName(advancedFilter, language)}
                    onChange={(value) => this.onTranslationChange(value, language)}
                  />
                </div>
                <ValidationError errors={errors} errorKey={`Name${language}`} />
              </div>
            </div>
          </div>
        ))}
        <div className='row'>
          <div className='col'>
            <div className='form-group d-flex' style={{ marginTop: '7px' }}>
              <label htmlFor='isFavourite' className='mr-2'>{i18n.t('advancedFilter.isFavourite')}</label>
              <div style={{ marginTop: '0.15rem' }}>
                <SwitchComponent
                  id='isFavourite'
                  checked={advancedFilter.isFavourite}
                  change={(e: ButtonChangeEventArgs) => this.onIsFavouriteChange(e)}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default withTranslation()(AdvancedFilterSaveForm);
