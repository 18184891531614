import { DataManager, Query, Predicate } from '@syncfusion/ej2-data';
import { QueryBuilder, RuleModel } from '@syncfusion/ej2-querybuilder';
import moment from 'moment';
import queryString from 'query-string';

import { DATE_MOMENT_FORMAT } from '../common/constants/advancedFilter.constants';
import { IMyRule } from '../common/model/myRule.model';
import { IAdvancedFilterColumn } from '../common/model/advancedFilterColumn.model';
import { RuleCondition } from '../common/model/enumerations/ruleCondition.model';
import { RuleOperator } from '../common/model/enumerations/ruleOperator.model';
import { AdvancedFilterColumnType } from '../common/model/enumerations/advancedFilterColumnType.model';
import * as dateHelpers from '../helpers/date.helpers';

export interface IQueryBuilderService {
  buildQuery(dataManager: DataManager, myRule: IMyRule, query: Query): Query;
  isRuleValid(myRule: IMyRule): boolean;
  mapRule(rule: RuleModel, columns: IAdvancedFilterColumn[]): IMyRule;
}

class QueryBuilderService implements IQueryBuilderService {
  public buildQuery(dataManager: DataManager, myRule: IMyRule, query: Query = new Query()): Query {
    const queryBuilder = new QueryBuilder();

    const predicate = this.buildPredicate(queryBuilder, dataManager, myRule, null, query);

    if (predicate) {
      query = query.where(predicate);
    }

    return query;
  }

  public isRuleValid(myRule: IMyRule): boolean {
    if (!myRule) {
      return false;
    }

    if (myRule.myRules) {
      if (!myRule.myRules.length) {
        return false;
      }
      if (myRule.condition !== RuleCondition.AND && myRule.condition !== RuleCondition.OR) {
        return false;
      }

      for (const childRule of myRule.myRules) {
        if (!this.isRuleValid(childRule)) {
          return false;
        }
      }
    } else {
      if (!myRule.field || !myRule.operator) {
        return false;
      }
      if (
        (myRule.operator === RuleOperator.BETWEEN || myRule.operator === RuleOperator.NOT_BETWEEN || myRule.operator === RuleOperator.IN || myRule.operator === RuleOperator.NOT_IN)
        && !Array.isArray(myRule.value)
      ) {
        return false;
      }
      if (
        myRule.value === null
        && (myRule.operator !== RuleOperator.EQUAL && myRule.operator !== RuleOperator.NOT_EQUAL && myRule.operator !== RuleOperator.IS_EMPTY && myRule.operator !== RuleOperator.IS_NOT_EMPTY)
      ) {
        return false;
      }
      if ((
        myRule.operator === RuleOperator.TODAY
        || myRule.operator === RuleOperator.GREATER_THAN_TODAY
        || myRule.operator === RuleOperator.GREATER_THAN_OR_EQUAL_TODAY
        || myRule.operator === RuleOperator.LESS_THAN_TODAY
        || myRule.operator === RuleOperator.LESS_THAN_OR_EQUAL_TODAY
        ) && !myRule.isDate) {
        return false;
      }
    }

    return true;
  }

  public mapRule(rule: RuleModel, columns: IAdvancedFilterColumn[]): IMyRule {
    return this.mapRuleInternal(rule, columns, null);
  }

  private buildPredicate(queryBuilder: QueryBuilder, dataManager: DataManager, myRule: IMyRule, parentPredicate: Predicate, query: Query): Predicate {
    let predicate: Predicate = null;

    if (myRule.myRules) {
      if (myRule.condition === RuleCondition.AND) {
        predicate = Predicate.and([]);
      }
      if (myRule.condition === RuleCondition.OR) {
        predicate = Predicate.or([]);
      }
      predicate.predicates = [];
      for (const childRule of myRule.myRules) {
        predicate = this.buildPredicate(queryBuilder, dataManager, childRule, predicate, query);
      }
    } else {
      const today = dateHelpers.toDateWithoutZones(moment(), DATE_MOMENT_FORMAT);
      if (myRule.operator === RuleOperator.TODAY) {
        myRule.operator = RuleOperator.EQUAL;
        myRule.value = today;
      } else if (myRule.operator === RuleOperator.GREATER_THAN_TODAY) {
        myRule.operator = RuleOperator.GREATER_THAN;
        myRule.value = today;
      } else if (myRule.operator === RuleOperator.GREATER_THAN_OR_EQUAL_TODAY) {
        myRule.operator = RuleOperator.GREATER_THAN_OR_EQUAL;
        myRule.value = today;
      } else if (myRule.operator === RuleOperator.LESS_THAN_TODAY) {
        myRule.operator = RuleOperator.LESS_THAN;
        myRule.value = today;
      } else if (myRule.operator === RuleOperator.LESS_THAN_OR_EQUAL_TODAY) {
        myRule.operator = RuleOperator.LESS_THAN_OR_EQUAL;
        myRule.value = today;
      }

      const auxRule: RuleModel = {
        condition: RuleCondition.OR,
        rules: [myRule]
      };
      if (queryBuilder.columns.findIndex((column) => column.field === myRule.field) < 0) {
        queryBuilder.columns.push({ field: myRule.field });
      }
      predicate = queryBuilder.getPredicate(auxRule);

      if (myRule.condition === RuleCondition.ANY) {
        const auxPredicate = predicate;
        query.addParams(RuleCondition.ANY + myRule.field, () => {
          const url = dataManager.adaptor.processQuery(dataManager, new Query().where(auxPredicate)).url;
          const parsedUrl = queryString.parse(url.substring(url.indexOf('?')));
          return parsedUrl.$filter;
        });
        predicate = null;
      }
    }

    if (predicate) {
      if (myRule.ignoreCase) {
        predicate.ignoreCase = true;
      }
      if (myRule.isDate) {
        predicate.value = moment(predicate.value as string, DATE_MOMENT_FORMAT).toDate();
      }

      if (parentPredicate) {
        parentPredicate.predicates.push(predicate);
      } else {
        return predicate;
      }
    }

    return parentPredicate;
  }

  private mapRuleInternal(rule: RuleModel, columns: IAdvancedFilterColumn[], parentMyRule: IMyRule): IMyRule {
    let myRule: IMyRule = { ...rule };

    if (rule.rules) {
      for (let i = 0; i < rule.rules.length; i++) {
        if (rule.rules[i].operator === RuleOperator.IS_EMPTY && rule.operator !== RuleOperator.IS_EMPTY) {
          rule.rules[i] = {
            condition: RuleCondition.OR,
            operator: RuleOperator.IS_EMPTY, // used to stop recursivity
            rules: [{
              ...rule.rules[i]
            }, {
              ...rule.rules[i],
              operator: RuleOperator.EQUAL,
              value: null
            }]
          };
        }
        if (rule.rules[i].operator === RuleOperator.IS_NOT_EMPTY && rule.operator !== RuleOperator.IS_NOT_EMPTY) {
          rule.rules[i] = {
            condition: RuleCondition.AND,
            operator: RuleOperator.IS_NOT_EMPTY, // used to stop recursivity
            rules: [{
              ...rule.rules[i]
            }, {
              ...rule.rules[i],
              operator: RuleOperator.NOT_EQUAL,
              value: null
            }]
          };
        }
        if (rule.rules[i].field) {
          const nestedPropertySeparatorIndex = rule.rules[i].field.indexOf('/');
          if (nestedPropertySeparatorIndex > -1) {
            const propertyName = rule.rules[i].field.substring(0, nestedPropertySeparatorIndex);
            if (!rule.rules[i - 1] || rule.rules[i - 1].field !== propertyName) {
              rule.rules[i] = {
                condition: RuleCondition.AND,
                rules: [{
                  field: propertyName,
                  operator: RuleOperator.NOT_EQUAL,
                  value: null
                }, {
                  ...rule.rules[i]
                }]
              };
            }
          }
        }
      }
      myRule = { ...rule };
      myRule.myRules = [];
      for (const childRule of rule.rules) {
        myRule = this.mapRuleInternal(childRule, columns, myRule);
      }
    } else {
      const associatedColumn = columns.find((column) => column.field === rule.field);
      if (associatedColumn) {
        if (associatedColumn.isAny) {
          myRule.condition = RuleCondition.ANY;
        }
        if (associatedColumn.ignoreCase) {
          myRule.ignoreCase = true;
        }
        if (associatedColumn.type === AdvancedFilterColumnType.DATE) {
          myRule.type = AdvancedFilterColumnType.STRING;
          myRule.isDate = true;
        }
      }
    }

    if (parentMyRule) {
      parentMyRule.myRules.push(myRule);
    } else {
      return myRule;
    }

    return parentMyRule;
  }
}

export const queryBuilderService = new QueryBuilderService();
