import React, { createRef } from 'react';
import { InputGroup, FormControl, Button } from 'react-bootstrap';
import { Filter, FilterProps, FilterState } from './filter';
import { DateRangePickerComponent, RangeEventArgs, PresetsModel } from '@syncfusion/ej2-react-calendars';
import moment from 'moment';
import { toShortDate } from '../../helpers';
import './dateRangeBase.scss';

export enum DateRangeLabel {
  End = 'end',
  Entry = 'entry',
  Expected = 'expected',
  Expiration = 'expiration'
}

export interface IDateRangeFilterProps {
  label?: DateRangeLabel;
  value?: Date[];
  i18n: any;
  placeholder?: string;
  onChangeInput?: (value: Date[]) => void;
}

export interface IDateRangeBaseFilterProps extends IDateRangeFilterProps {
  options: PresetsModel[];
}

export interface IDateRangeFilterState {
  isOpen: boolean;
}

class DateRangeBase extends Filter<IDateRangeBaseFilterProps, IDateRangeFilterState> {
  private picker = createRef<DateRangePickerComponent>();

  public constructor(props: FilterProps<IDateRangeBaseFilterProps>) {
    super(props);

    this.state = {
      isOpen: false,
      value: this.props.value || []
    };
  }

  public shouldComponentUpdate(nextProps: FilterProps<IDateRangeBaseFilterProps>, nextState: FilterState<IDateRangeFilterState>): boolean {
    const result = JSON.stringify(this.props.value) !== JSON.stringify(nextProps.value)
      || JSON.stringify(this.props.options) !== JSON.stringify(nextProps.options)
      || this.state.isOpen !== nextState.isOpen
      || JSON.stringify(this.state.value) !== JSON.stringify(nextState.value)
    ;

    return result;
  }

  public render(): JSX.Element {
    let sd: Date = null;
    let ed: Date = null;

    const myStartDate = (this.props.value && Array.isArray(this.props.value) && this.props.value.length > 0) ? this.props.value[0] : null;
    const myEndDate = (this.props.value && Array.isArray(this.props.value) && this.props.value.length === 2) ? this.props.value[1] : null;

    if (myStartDate !== null) {
      if (typeof (myStartDate) === 'string') {
        sd = new Date(myStartDate);
      }
      if (typeof (myStartDate) === 'object') {
        sd = myStartDate;
      }
    }

    if (myEndDate !== null) {
      if (typeof (myEndDate) === 'string') {
        ed = new Date(myEndDate);
      }
      if (typeof (myEndDate) === 'object') {
        ed = myEndDate;
      }
    }

    return (
      <InputGroup>
        <InputGroup.Prepend className='date-range-prepend'>
          <InputGroup.Text title={this.props.name}>{this.props.name}</InputGroup.Text>
        </InputGroup.Prepend>

        <FormControl
          onClick={!this.state.isOpen ? this.openDateRangeSelector : null}
          value={this.getStringValue(this.state.value as [Date, Date])}
          readOnly={true}
        />

        <InputGroup.Append>
          <Button
            className={Array.isArray(this.state.value) && this.state.value.length > 0 ? '' : 'is-hidden'}
            variant='outline-light'
            onClick={!this.state.isOpen ? this.onClickClean : null}
          >
            <i className='icon icon-app-item-close' />
          </Button>
          <Button
            variant='secondary'
            onClick={!this.state.isOpen ? this.openDateRangeSelector : null}
            disabled={this.state.isOpen}
          >
            <i className='icon icon-app-input-date' />
          </Button>
        </InputGroup.Append>

        <DateRangePickerComponent
          cssClass='date-range-filter'
          ref={this.picker}
          locale={this.props.i18n.language}
          change={this.onChangeInput}
          close={this.onCloseCalendar}
          firstDayOfWeek={moment().isoWeekday()}
          startDate={sd}
          endDate={ed}
          placeholder={this.props.placeholder || ''}
          presets={this.props.options}
        />
      </InputGroup>
    );
  }

  private getStringValue(value: Date[]): string {
    let stringValue = '';

    if (value && value.length > 0) {
      stringValue = `${toShortDate(value[0])}`;
    }

    if (value && value.length > 1) {
      stringValue += ` - ${toShortDate(value[1])}`;
    }

    return stringValue;
  }

  private openDateRangeSelector = () => {
    if (!this.state.isOpen) {
      this.picker.current.show();
      this.setState({ isOpen: true });
    }
  }

  private onClickClean = () => {
    const value: Date[] = [];
    this.setState({ value });

    if (!this.props.onChangeInput) {
      return;
    }

    this.props.onChangeInput(value);
  }

  private onChangeInput = (e: RangeEventArgs) => {
    const value = e && e.value != null ? e.value : [];
    this.setState({ isOpen: false, value });

    if (!this.props.onChangeInput) {
      return;
    }

    this.props.onChangeInput(value as Date[]);
  }

  private onCloseCalendar = () => {
    this.setState({ isOpen: false });
  }
}

export default DateRangeBase;
