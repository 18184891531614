import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { DialogComponent } from '@aitex/tsx-extranet-dialogs';

import { IDiscardChangesDialogProps } from '../../../interfaces/props/IDialogsProps';
import * as dialogUtils from '../../../utils/dialog.utils';

type DiscardChangesDialogPropsType = IDiscardChangesDialogProps & WithTranslation;

class DiscardChangesDialog extends Component<DiscardChangesDialogPropsType> {
  private readonly KEEP_EDITING_BUTTON_ID = 'discardChangesDialogKeepEditing';
  private readonly DISCARD_BUTTON_ID = 'discardChangesDialogDiscard';

  public componentDidUpdate() {
    dialogUtils.manageButtonsClick(this.DISCARD_BUTTON_ID, () => this.props.onClose(), this.KEEP_EDITING_BUTTON_ID, () => this.props.onDismiss());
  }

  public render() {
    const { visible, onDismiss, i18n } = this.props;

    return (
      <DialogComponent
        header={i18n.t('dialogs.discardChangesDialog.title')}
        visible={visible}
        footerTemplate={dialogUtils.computeFooterTemplate(
          this.KEEP_EDITING_BUTTON_ID,
          i18n.t('actions.keepEditing'),
          this.DISCARD_BUTTON_ID,
          i18n.t('actions.discard')
        )}
        onDismiss={() => onDismiss()}
      >
        <div>{i18n.t('dialogs.discardChangesDialog.text')}</div>
      </DialogComponent>
    );
  }
}

export default withTranslation()(DiscardChangesDialog);
