import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, Resize } from '@syncfusion/ej2-react-grids';
import { DataManager } from '@syncfusion/ej2-data';
import { DialogComponent } from '@aitex/tsx-extranet-dialogs';

import { IReportVersionsDialogProps } from '../../../interfaces/props/IDialogsProps';
import { IReport } from '../../../common/model/report.model';
import { ReportStatus } from '../../../common/model/enumerations/reportStatus.model';
import { reportsService } from '../../../services';
import * as dialogUtils from '../../../utils/dialog.utils';
import * as reportStatusUtils from '../../../utils/reportStatus.utils';
import * as gridUtils from '../../../utils/grid.utils';
import { notifyError } from '../../../utils/toast.utils';
import * as dateHelpers from '../../../helpers/date.helpers';
import './reportVersionsDialog.scss';

type ReportVersionsDialogPropsType = IReportVersionsDialogProps & WithTranslation;

class EditCompanySupplierPurchaseProductsDialog extends Component<ReportVersionsDialogPropsType> {
  public gridComponent: GridComponent = null;

  private readonly CLOSE_BUTTON_ID = 'reportVersionsDialogClose';

  public componentDidUpdate(prevProps: ReportVersionsDialogPropsType) {
    gridUtils.manageSpinner(this.gridComponent, prevProps.isFetching, this.props.isFetching);
    gridUtils.manageDataSource(this.gridComponent, () => this.updateGridComponentDataSource(), prevProps.isFetching, this.props.isFetching, prevProps.visible, this.props.visible);

    dialogUtils.manageButtonsClick(null, null, this.CLOSE_BUTTON_ID, () => this.props.onDismiss());
  }

  public updateGridComponentDataSource() {
    this.gridComponent.dataSource = new DataManager(JSON.parse(JSON.stringify(this.props.reports))); // Clone by value
  }

  public getVersionColumnTemplate(report: IReport): JSX.Element {
    const canDownload = report.status === ReportStatus.FINALIZED && report.fileName && report.resultIsVisible && report.published;
    return (
      <span
        className={canDownload ? 'e-link' : ''}
        onClick={canDownload ? () => this.downloadReport(report) : undefined}
      >
        {report.version}
      </span>
    );
  }

  public getStatusColumnTemplate(report: IReport): JSX.Element {
    return <span title={reportStatusUtils.getLocalizedText(this.props.i18n, report.status)} className={reportStatusUtils.getIconCss(report.status)} />;
  }

  public getAitexResultColumnTemplate(report: IReport): JSX.Element {
    return <span>{report.customFields.aitexResultName}</span>;
  }

  public getIncidenceColumnTemplate(report: IReport): JSX.Element {
    return <span>{report.customFields.incidence}</span>;
  }

  public getEndDateColumnTemplate(report: IReport): JSX.Element {
    return <span>{dateHelpers.toShortDate(report.endDate, true)}</span>;
  }

  public render() {
    const { visible, reportCombo, onDismiss, i18n } = this.props;

    return (
      <DialogComponent
        header={i18n.t('dialogs.reportVersionsDialog.title', { reportNumber: reportCombo ? reportCombo.reportNumber : '' })}
        visible={visible}
        width='60%'
        footerTemplate={dialogUtils.computeFooterTemplate(
          null,
          null,
          this.CLOSE_BUTTON_ID,
          i18n.t('actions.close')
        )}
        onDismiss={() => onDismiss()}
      >
        <div className='report-versions-grid'>
          <GridComponent
            allowResizing={true}
            locale={i18n.language}
            ref={(grid: GridComponent) => this.gridComponent = grid}
            created={() => this.updateGridComponentDataSource()}
          >
            <ColumnsDirective>
              <ColumnDirective
                headerText={this.props.i18n.t('report.version')}
                field='version'
                isPrimaryKey={true}
                width='100'
                template={(report: IReport) => this.getVersionColumnTemplate(report)}
              />
              {
                reportCombo && !reportCombo.isEciUser && <ColumnDirective
                  headerText={this.props.i18n.t('report.requerimentStandard')}
                  field='requerimentStandard'
                  width='200'
                />
              }
              {
                reportCombo && reportCombo.isEciUser && <ColumnDirective
                  headerText={this.props.i18n.t('reportCustomFields.aitexResultId')}
                  field='aitexResult'
                  width='100'
                  template={(report: IReport) => this.getAitexResultColumnTemplate(report)}
                />
              }
              {
                reportCombo && reportCombo.isEciUser && <ColumnDirective
                  headerText={this.props.i18n.t('reportCustomFields.incidence')}
                  field='incidence'
                  width='200'
                  template={(report: IReport) => this.getIncidenceColumnTemplate(report)}
                />
              }
              {
                reportCombo && reportCombo.isEciUser && <ColumnDirective
                  headerText={this.props.i18n.t('report.endDate')}
                  field='endDate'
                  width='100'
                  template={(report: IReport) => this.getEndDateColumnTemplate(report)}
                />
              }
              {
                reportCombo && !reportCombo.isEciUser && <ColumnDirective
                headerText={this.props.i18n.t('report.status')}
                field='status'
                width='100'
                template={(report: IReport) => this.getStatusColumnTemplate(report)}
                />
              }
            </ColumnsDirective>
            <Inject services={[Resize]} />
          </GridComponent>
        </div>
      </DialogComponent>
    );
  }

  private async downloadReport(report: IReport) {
    try {
      if (this.gridComponent) {
        this.gridComponent.showSpinner();
      }

      await reportsService.downloadFile(report);
    } catch (error) {
      notifyError(this.props.i18n.t('dialogs.reportVersionsDialog.errors.cannotDownloadReport'));
    } finally {
      if (this.gridComponent) {
        this.gridComponent.hideSpinner();
      }
    }
  }
}

export default withTranslation()(EditCompanySupplierPurchaseProductsDialog);
