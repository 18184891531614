import { Action, Reducer, ActionCreator, combineReducers } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { batch } from 'react-redux';
import { DataManager, Query } from '@syncfusion/ej2-data';
import moment from 'moment';

import { RootState } from '..';
import * as listGridStore from './listGrid.store';
import { IUnecosListState, IUnecosListFilterState } from '../../interfaces/states/IUnecosListState';
import { IUneco } from '../../common/model/uneco.model';
import { IODataService } from '../../services';

export enum Type {
  RESET = '@@unecosList/RESET',
  RESET_HARD = '@@unecosList/RESET_HARD',
  SET_UNECOS = '@@unecosList/SET_UNECOS',
  SET_MODE = '@@unecosList/SET_MODE',
  SET_IS_CHANGING_MODE = '@@unecosList/SET_IS_CHANGING_MODE',
  SET_FILTER = '@@unecosList/SET_FILTER',
  SET_FILTER_ACTIVE = '@@unecosList/SET_FILTER_ACTIVE',
  SET_FILTER_CODE = '@@unecosList/SET_FILTER_CODE',
  SET_FILTER_CATEGORY_NAME = '@@unecosList/SET_FILTER_CATEGORY_NAME',
  SET_FILTER_EMAILS = '@@unecosList/SET_FILTER_EMAILS',
  SET_FILTER_EMAIL_COORDINATOR = '@@unecosList/SET_FILTER_EMAIL_COORDINATOR',
  SET_IS_FETCHING = '@@unecosList/SET_IS_FETCHING',
  SET_SEARCH_DATE = '@@unecosList/SET_SEARCH_DATE',
  SET_CURRENT_PAGE = '@@unecosList/SET_CURRENT_PAGE',
  SET_PAGE_SIZE = '@@unecosList/SET_PAGE_SIZE',
  SET_TOTAL_COUNT = '@@unecosList/SET_TOTAL_COUNT',
  SET_SORTING = '@@unecosList/SET_SORTING',
  SET_UNECO = '@@unecosDetail/SET_UNECO'
}

const initialState: IUnecosListState = {
  unecos: [],
  mode: null,
  isChangingMode: true,
  filter: {
    active: true,
    code: null,
    categoryName: 'TODOS',
    emails: null,
    emailCoordinator: null
  },
  grid: listGridStore.initialState
};


/////////////
// Actions //
/////////////

export type Actions = ResetAction | ResetHardAction | SetUnecosAction | SetFilterAction | SetFilterActiveAction | SetFilterCodeAction
  | SetFilterCategoryNameAction | SetFilterEmailsAction | SetFilterEmailCoordinatorAction | listGridStore.SetIsFetchingAction
  | listGridStore.SetSearchDateAction | listGridStore.SetCurrentPageAction | listGridStore.SetPageSizeAction | listGridStore.SetTotalCountAction | listGridStore.SetSortingAction
  | SetUnecoAction;

export type ResetAction = Action<Type.RESET>

export type ResetHardAction = Action<Type.RESET_HARD>

export interface SetUnecosAction extends Action<Type.SET_UNECOS> {
  unecos: IUneco[];
}

export interface SetFilterAction extends Action<Type.SET_FILTER> {
  filter: IUnecosListFilterState;
}

export interface SetFilterActiveAction extends Action<Type.SET_FILTER_ACTIVE> {
  active?: boolean;
}

export interface SetFilterCodeAction extends Action<Type.SET_FILTER_CODE> {
  code: string;
}

export interface SetFilterCategoryNameAction extends Action<Type.SET_FILTER_CATEGORY_NAME> {
  categoryName: string;
}

export interface SetFilterEmailsAction extends Action<Type.SET_FILTER_EMAILS> {
  emails: string;
}

export interface SetFilterEmailCoordinatorAction extends Action<Type.SET_FILTER_EMAIL_COORDINATOR> {
  emailCoordinator: string;
}

export interface SetUnecoAction extends Action<Type.SET_UNECO> {
  uneco: IUneco;
}

export const reset: ActionCreator<ResetAction> = () => ({
  type: Type.RESET
});

export const resetHard: ActionCreator<ResetHardAction> = () => ({
  type: Type.RESET_HARD
});

export const setUnecos: ActionCreator<SetUnecosAction> = (unecosLocal: IUneco[]) => ({
  type: Type.SET_UNECOS,
  unecos: unecosLocal
});

export const setFilter: ActionCreator<SetFilterAction> = (filterLocal: IUnecosListFilterState) => ({
  type: Type.SET_FILTER,
  filter: filterLocal
});

export const setFilterActive: ActionCreator<SetFilterActiveAction> = (active?: boolean) => ({
  type: Type.SET_FILTER_ACTIVE,
  active
});

export const setFilterCode: ActionCreator<SetFilterCodeAction> = (code: string) => ({
  type: Type.SET_FILTER_CODE,
  code
});

export const setFilterCategoryName: ActionCreator<SetFilterCategoryNameAction> = (categoryName: string) => ({
  type: Type.SET_FILTER_CATEGORY_NAME,
  categoryName
});

export const setFilterEmails: ActionCreator<SetFilterEmailsAction> = (emails: string) => ({
  type: Type.SET_FILTER_EMAILS,
  emails
});

export const setFilterEmailCoordinator: ActionCreator<SetFilterEmailCoordinatorAction> = (emailCoordinator: string) => ({
  type: Type.SET_FILTER_EMAIL_COORDINATOR,
  emailCoordinator
});

export const fetchUnecos: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (
  oDataService: IODataService,
  unecosDataManager: DataManager,
  unecosQuery: Query
) => (
    async (dispatch, getState) => {
      dispatch(listGridStore.setIsFetching(Type.SET_IS_FETCHING, true));

      const currentPageLocal = getState().unecosListStore.grid.currentPage;
      const pageSizeLocal = getState().unecosListStore.grid.pageSize;
      const sortFieldLocal = getState().unecosListStore.grid.sortField;
      const sortDirectionLocal = getState().unecosListStore.grid.sortDirection;

      let query = unecosQuery.page(currentPageLocal, pageSizeLocal);
      if (sortFieldLocal && sortDirectionLocal) {
        query = sortDirectionLocal === 'Ascending' ? query.sortBy(sortFieldLocal) : query.sortByDesc(sortFieldLocal);
      }
      const unecosResponse = await oDataService.executeQueryWithCount<IUneco>(unecosDataManager, query);
      const unecosLocal = unecosResponse.result.map((uneco) => uneco);
      batch(() => {
        dispatch(setUnecos(unecosLocal));
        dispatch(listGridStore.setSearchDate(Type.SET_SEARCH_DATE, moment()));
        dispatch(listGridStore.setTotalCount(Type.SET_TOTAL_COUNT, unecosResponse.count));
      });
      dispatch(listGridStore.setIsFetching(Type.SET_IS_FETCHING, false));
    }
);

export const setUneco: ActionCreator<SetUnecoAction> = (unecoLocal: IUneco) => ({
  type: Type.SET_UNECO,
  uneco: unecoLocal
});

export const saveUneco: ActionCreator<ThunkAction<void, RootState, unknown, Actions>> = (
  uneco: IUneco
) => (
  async (dispatch) => {
    dispatch(listGridStore.setIsFetching(Type.SET_IS_FETCHING, true));

    dispatch(setUneco(uneco));

    dispatch(listGridStore.setIsFetching(Type.SET_IS_FETCHING, false));
  }
);

//////////////
// Reducers //
//////////////

const unecos: Reducer<IUneco[]> = (state = initialState.unecos, action) => {
  switch (action.type) {
    case Type.RESET:
      return state;
    case Type.SET_MODE:
      return initialState.unecos;
    case Type.SET_UNECOS:
      return (action as SetUnecosAction).unecos;
    case Type.SET_UNECO: {
      const uneco = (action as SetUnecoAction).uneco;
      let unecos = state.filter(value => (value as any).uneco === undefined || (value as any).uneco == null);
      if (unecos.some(u => u.id === uneco.id)) {
        const newUnecos = unecos.map((item: IUneco) => {
          if (item.id == uneco.id) {
            return uneco;
          } else {
            return item;
          }
        });

        unecos = newUnecos;
      } else {
        unecos.unshift(uneco);
      }

      return unecos;
    }
    default:
      return state;
  }
};

const mode: Reducer<string> = (state = initialState.mode, action) => {
  switch (action.type) {
    case Type.RESET:
      return state;
    case Type.RESET_HARD:
      return initialState.mode;
    default:
      return state;
  }
};

const isChangingMode: Reducer<boolean> = (state = initialState.isChangingMode, action) => {
  switch (action.type) {
    case Type.RESET:
    case Type.RESET_HARD:
      return initialState.isChangingMode;
    case Type.SET_MODE:
      return false;
    default:
      return state;
  }
}

const filter: Reducer<IUnecosListFilterState> = (state = initialState.filter, action) => {
  switch (action.type) {
    case Type.RESET:
      return state;
    case Type.SET_FILTER:
      return (action as SetFilterAction).filter;
    case Type.SET_FILTER_ACTIVE:
      return { ...state, active: (action as SetFilterActiveAction).active };
    case Type.SET_FILTER_CODE:
      return { ...state, code: (action as SetFilterCodeAction).code };
    case Type.SET_FILTER_CATEGORY_NAME:
      return { ...state, categoryName: (action as SetFilterCategoryNameAction).categoryName };
    case Type.SET_FILTER_EMAILS:
      return { ...state, emails: (action as SetFilterEmailsAction).emails };
    case Type.SET_FILTER_EMAIL_COORDINATOR:
      return { ...state, emailCoordinator: (action as SetFilterEmailCoordinatorAction).emailCoordinator };
    default:
      return state;
  }
};

export const unecosListStore = combineReducers({
  unecos,
  mode,
  isChangingMode,
  filter,
  grid: listGridStore.createListGridStore(Type, 'code', 'Ascending')
});
