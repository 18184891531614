import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { WithTranslation, withTranslation } from 'react-i18next';
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, Sort, Page, Toolbar, ExcelExport, Resize, SortSettingsModel, PagerComponent, SortEventArgs } from '@syncfusion/ej2-react-grids';
import { ItemModel, ClickEventArgs } from '@syncfusion/ej2-navigations';

import { IClientsGridProps } from '../../../interfaces/props/IClientsListProps';
import { EXCEL_EXPORT_ACTION_ID } from '../../../common/constants/gridActionId.constants';
import { ICompany } from '../../../common/model/company.model';
import * as gridUtils from '../../../utils/grid.utils';
import * as pagerUtils from '../../../utils/pager.utils';
import * as sortUtils from '../../../utils/sort.utils';
import './clientsGrid.scss';

type ClientsGridPropsType = IClientsGridProps & RouteComponentProps & WithTranslation;

class ClientsGrid extends Component<ClientsGridPropsType> {
  public gridComponent: GridComponent = null;
  public toolbarOptions: ItemModel[] = [];
  public sortOptions: SortSettingsModel = null;

  public pagerComponent: PagerComponent = null;

  public constructor(props: ClientsGridPropsType) {
    super(props);

    const { sortField, sortDirection, i18n } = this.props;

    this.toolbarOptions = [{
      tooltipText: i18n.t('syncfusion.grid.Excelexport'),
      id: EXCEL_EXPORT_ACTION_ID,
      prefixIcon: 'icon icon-file-excel-o'
    }];

    this.sortOptions = {
      columns: [{ field: sortField, direction: sortDirection }]
    };
  }

  public componentDidUpdate(prevProps: ClientsGridPropsType) {
    gridUtils.manageSpinner(this.gridComponent, prevProps.isFetching, this.props.isFetching);
    gridUtils.manageSorting(this.gridComponent, prevProps.isFetching, this.props.isFetching, this.props.sortField, this.props.sortDirection);
  }

  public onToolbarClick(args: ClickEventArgs) {
    if (args.item.id === EXCEL_EXPORT_ACTION_ID) {
      this.gridComponent.excelExport();
    }
  }

  public onGridComponentDataBound() {
    const { currentPage, pageSize, totalCount, searchDate, i18n } = this.props;

    pagerUtils.refreshPager(this.pagerComponent, currentPage, pageSize, totalCount, searchDate, i18n);
  }

  public onGridComponentActionBegin(args: SortEventArgs) {
    if (args.requestType === 'sorting') {
      this.gridComponent.getColumnByField(args.columnName).sortComparer = (reference, comparer) =>
        sortUtils.gridSortComparer(reference, comparer, args.direction);

      gridUtils.manageSortingChange(args, this.props.sortField, this.props.sortDirection, this.props.onSortingChange);
    }
  }

  public getCodeColumnTemplate(client: ICompany): JSX.Element {
    return <span className='e-link' onClick={() => this.props.history.push(`/client/${client.id}/view`)}>{client.code}</span>;
  }

  public onPagerComponentClick(e: any) {
    if (e.currentPage !== this.props.currentPage) {
      this.props.onCurrentPageChange(e.currentPage);
    }
  }

  public onPageSizesDropdownChange(e: any) {
    if (e.pageSize !== this.props.pageSize) {
      this.props.onPageSizeChange(e.pageSize);
    }
  }

  public render() {
    const { clients, i18n } = this.props;

    return (
      <div className='clients-grid'>
        <GridComponent
          dataSource={clients}
          toolbar={this.toolbarOptions}
          allowSorting={true}
          allowPaging={true}   /* Si no se incluye este paginador (oculto por css), no se muestra el dropdown en el customizado */
          allowExcelExport={true}
          allowResizing={true}
          sortSettings={this.sortOptions}
          pageSettings={{ pageSize: pagerUtils.getMaxPageSize() }}
          locale={i18n.language}
          ref={(grid) => this.gridComponent = grid}
          toolbarClick={(args) => this.onToolbarClick(args)}
          dataBound={() => this.onGridComponentDataBound()}
          actionBegin={(args) => this.onGridComponentActionBegin(args as SortEventArgs)}
        >
          <ColumnsDirective>
            <ColumnDirective
              headerText={this.props.i18n.t('company.code')}
              field='code'
              width='150'
              template={(client: ICompany) => this.getCodeColumnTemplate(client)}
            />
            <ColumnDirective
              headerText={this.props.i18n.t('company.name')}
              field='name'
              width='250'
            />
            <ColumnDirective
              headerText={this.props.i18n.t('company.city')}
              field='city'
              width='150'
            />
            <ColumnDirective
              headerText={this.props.i18n.t('company.province')}
              field='province'
              width='150'
            />
            <ColumnDirective
              headerText={this.props.i18n.t('company.country')}
              field='country'
              width='150'
            />
          </ColumnsDirective>
          <Inject services={[Sort, Page, Toolbar, ExcelExport, Resize]} />
        </GridComponent>
        {clients.length ?
          <PagerComponent
            pageSize={pagerUtils.getDefaultPageSize()}
            pageCount={pagerUtils.getPageCount()}
            pageSizes={pagerUtils.getPageSizes()}
            ref={(pagerComponent) => this.pagerComponent = pagerComponent}
            click={(e) => this.onPagerComponentClick(e)}
            dropDownChanged={(e) => this.onPageSizesDropdownChange(e)}
          />
          :
          null
        }
      </div>
    );
  }
}

export default withRouter(withTranslation()(ClientsGrid));
