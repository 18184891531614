import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { AutoCompleteComponent, DropDownListComponent, ChangeEventArgs, FilteringEventArgs, FieldSettingsModel } from '@syncfusion/ej2-react-dropdowns';
import { Query } from '@syncfusion/ej2-data';
import { SortOrder } from '@syncfusion/ej2-navigations';
import { DialogComponent } from '@aitex/tsx-extranet-dialogs';

import { ISimulateExternalUserDialogProps } from '../../../interfaces/props/IDialogsProps';
import { ICompanyCombo } from '../../../common/model/company.model';
import { IUser } from '../../../common/model/user.model';
import { ISimulateExternalUser } from '../../../common/model/simulateExternalUser.model';
import { IMyRule } from '../../../common/model/myRule.model';
import { IErrors } from '../../../common/model/errors.model';
import { UserType } from '../../../common/model/enumerations/userType.model';
import { RuleCondition } from '../../../common/model/enumerations/ruleCondition.model';
import { RuleOperator } from '../../../common/model/enumerations/ruleOperator.model';
import { queryBuilderService } from '../../../services';
import { debounce } from '../../../utils/common.utils';
import * as dialogUtils from '../../../utils/dialog.utils';

type SimulateExternalUserDialogPropsType = ISimulateExternalUserDialogProps & WithTranslation;

type SimulateExternalUserDialogStateType = ISimulateExternalUserDialogState;

class SimulateExternalUserDialog extends Component<SimulateExternalUserDialogPropsType, SimulateExternalUserDialogStateType> {
  public readonly SIMULATE_EXTERNAL_USER_DIALOG_FORM_ID = 'simulateExternalUserDialogForm';

  public companiesAutoCompleteComponent: AutoCompleteComponent = null;
  public companiesQuery = new Query()
    .select(['id', 'name'])
    .where('active', RuleOperator.EQUAL, true)
    .where('isLocked', RuleOperator.EQUAL, false)
    .take(10);
  public companiesFields: FieldSettingsModel = { value: 'name' };
  public companiesSortOrder: SortOrder = 'Ascending';
  public companiesFilteringEvent: FilteringEventArgs = null;
  public companiesFilteringDebounced = debounce(
    () => {
      if (this.companiesFilteringEvent) {
        if (this.companiesFilteringEvent.text.length >= 3) {
          this.companiesAutoCompleteComponent.filter(
            this.companiesAutoCompleteComponent.dataSource,
            this.companiesAutoCompleteComponent.query.clone()
            .where('name', RuleOperator.CONTAINS, this.companiesFilteringEvent.text, true)
          );
        } else {
          return;
        }
      }
    },
    250
  );

  public usersDropDownListComponent: DropDownListComponent = null;
  public usersQuery = new Query()
    .select(['id', 'email', 'type', 'companies'])
    .where('type', RuleOperator.EQUAL, UserType.EXTERNAL); // take() will be set dynamically
  public usersFields: FieldSettingsModel = { value: 'email' };
  public usersSortOrder: SortOrder = 'Ascending';
  public usersFilteringEvent: FilteringEventArgs = null;
  public usersFilteringDebounced = debounce(
    () => {
      if (this.usersFilteringEvent) {
        if (this.usersFilteringEvent.text.length >= 3) {
          this.usersDropDownListComponent.filter(
            this.usersDropDownListComponent.dataSource,
            this.computeUsersDropDownListComponentQuery()
            .where('email', RuleOperator.CONTAINS, this.usersFilteringEvent.text, true)
          );
        } else {
          return;
        }
      }
    },
    250
  );

  private readonly CANCEL_BUTTON_ID = 'simulateExternalUserDialogCancel';
  private readonly OK_BUTTON_ID = 'simulateExternalUserDialogOk';

  private readonly CANNOT_SIMULATE_EXTERNAL_USER_ERROR: IErrors = { UnknownError: ['dialogs.simulateExternalUserDialog.error'] };

  private initialState: SimulateExternalUserDialogStateType = {
    selectedCompany: null,
    selectedUser: null,
    isLoading: false
  };

  public constructor(props: SimulateExternalUserDialogPropsType) {
    super(props);

    this.state = this.initialState;
  }

  public componentDidUpdate(prevProps: SimulateExternalUserDialogPropsType) {
    const { visible } = this.props;

    if (prevProps.visible && !visible) {
      this.setState(this.initialState);
    }

    dialogUtils.manageButtonsClick(this.OK_BUTTON_ID, () => this.simulateExternalUser(), this.CANCEL_BUTTON_ID, () => this.dismiss());
  }

  public simulateExternalUser() {
    try {
      this.setState({ isLoading: true });

      if (!this.state.selectedCompany || !this.state.selectedUser) {
        throw this.CANNOT_SIMULATE_EXTERNAL_USER_ERROR;
      }

      this.close({ companyId: this.state.selectedCompany.id, userId: this.state.selectedUser.id });
    } catch (error) {
      let errors = error as IErrors;
      if (errors.UnknownError || (!errors.UnknownError && !errors.ControlledError)) {
        errors = this.CANNOT_SIMULATE_EXTERNAL_USER_ERROR;
      }
      this.dismiss(errors);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  public close(simulateExternalUser: ISimulateExternalUser) {
    this.props.onClose(simulateExternalUser);
  }

  public dismiss(reason?: IErrors) {
    this.props.onDismiss(reason);
  }

  public onCompanyChange(e: ChangeEventArgs) {
    const selectedCompany = e.itemData ? (e.itemData as ICompanyCombo) : null;
    this.setState({ selectedCompany, selectedUser: this.initialState.selectedUser });

    if (this.usersDropDownListComponent) {
      this.usersDropDownListComponent.query = this.computeUsersDropDownListComponentQuery();
    }
  }

  public isUsersDropDownListComponentEnabled(): boolean {
    return this.state.selectedCompany !== null;
  }

  public getUsersDropDownListComponentPlaceholder(): string {
    return this.isUsersDropDownListComponentEnabled() ? '' : this.props.i18n.t('dialogs.simulateExternalUserDialog.selectACompanyFirst');
  }

  public onUsersDropDownListComponentCreated() {
    this.usersDropDownListComponent.query = this.computeUsersDropDownListComponentQuery();
  }

  public onUserChange(e: ChangeEventArgs) {
    const selectedUser = e.itemData ? (e.itemData as IUser) : null;
    this.setState({ selectedUser });
  }

  public render() {
    const { visible, companiesDataManager, usersDataManager, i18n } = this.props;

    return (
      <DialogComponent
        header={i18n.t('dialogs.simulateExternalUserDialog.title')}
        visible={visible}
        width='480px'
        footerTemplate={dialogUtils.computeFooterTemplate(
          this.OK_BUTTON_ID,
          i18n.t('actions.ok'),
          this.CANCEL_BUTTON_ID,
          i18n.t('actions.cancel'),
          this.state.isLoading,
          this.isSimulationDisabled()
        )}
        onDismiss={() => this.dismiss()}
      >
        <form>
          <div className='row'>
            <div className='col'>
              <div className='form-group'>
                <span>{i18n.t('dialogs.simulateExternalUserDialog.text')}</span>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              <div className='form-group'>
                <div className='autocomplete-input'>
                  <div className='autocomplete-label' style={{ minWidth: 'auto' }}>
                    <span className='icon icon-company' title={i18n.t('dialogs.simulateExternalUserDialog.company')} />
                  </div>
                  <AutoCompleteComponent
                    query={this.companiesQuery}
                    dataSource={companiesDataManager}
                    fields={this.companiesFields}
                    sortOrder={this.companiesSortOrder}
                    allowCustom={false}
                    value={this.state.selectedCompany && this.state.selectedCompany.name}
                    ref={(autoCompleteComponent) => this.companiesAutoCompleteComponent = autoCompleteComponent}
                    change={(e) => this.onCompanyChange(e)}
                    filtering={(e) => {
                      this.companiesFilteringEvent = e;
                      this.companiesFilteringEvent.cancel = true;
                      this.companiesFilteringDebounced();
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              <div className='form-group'>
                <div className='autocomplete-input'>
                  <div className='autocomplete-label' style={{ minWidth: 'auto' }}>
                    <span className='icon icon-user-external' title={i18n.t('simulateExternalUser.user')} />
                  </div>
                  <DropDownListComponent
                    query={null} /* query will be set dynamically */
                    dataSource={usersDataManager}
                    fields={this.usersFields}
                    sortOrder={this.usersSortOrder}
                    allowFiltering={true}
                    showClearButton={true}
                    value={this.state.selectedUser && this.state.selectedUser.email}
                    enabled={this.isUsersDropDownListComponentEnabled()}
                    placeholder={this.getUsersDropDownListComponentPlaceholder()}
                    ref={(dropDownListComponent) => this.usersDropDownListComponent = dropDownListComponent}
                    created={() => this.onUsersDropDownListComponentCreated()}
                    change={(e) => this.onUserChange(e)}
                    filtering={(e) => {
                      this.usersFilteringEvent = e;
                      this.usersFilteringEvent.cancel = true;
                      this.usersFilteringDebounced();
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </DialogComponent>
    );
  }

  private isSimulationDisabled(): boolean {
    return !this.state.selectedCompany || !this.state.selectedUser || this.state.isLoading;
  }

  private computeUsersDropDownListComponentQuery(): Query {
    let query = this.usersQuery.clone();
    if (this.isUsersDropDownListComponentEnabled()) {
      let myRule: IMyRule = {
        condition: RuleCondition.ANY,
        field: 'companies/companyId',
        operator: RuleOperator.EQUAL,
        value: this.state.selectedCompany.id
      };
      query = queryBuilderService.buildQuery(this.props.usersDataManager, myRule, query);
      myRule = {
        condition: RuleCondition.ANY,
        field: 'companies/disabled',
        operator: RuleOperator.EQUAL,
        value: false
      };
      query = queryBuilderService.buildQuery(this.props.usersDataManager, myRule, query);
      myRule = {
        condition: RuleCondition.AND,
        field: 'disabled',
        operator: RuleOperator.EQUAL,
        value: false
      };
      query = queryBuilderService.buildQuery(this.props.usersDataManager, myRule, query);
      query = query.take(10);
    } else {
      query = query.take(0);
    }
    return query;
  }
}

interface ISimulateExternalUserDialogState {
  selectedCompany: ICompanyCombo;
  selectedUser: IUser;
  isLoading: boolean;
}

export default withTranslation()(SimulateExternalUserDialog);
